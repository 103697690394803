
	<div class="col-12 text-right">
		<mat-form-field appearance="standard" class="text-right">
			<mat-label>Vendor Lookup</mat-label>
			<input matInput #vendorsearch type="text" (input)="onInput()" placeholder="Vendor Search..." [formControl]="vendorSearch"/>
			<span matSuffix>
				<button tabindex="-1" mat-icon-button color="white">
					<mat-icon (click)="onInput()">search</mat-icon>
				</button>
			</span>
		</mat-form-field>
	</div>

<div class="table">
	<table mat-table  [dataSource]="filteredItems" matSort (matSortChange)="announceSortChange($event)" [ngClass]="allResults.length ? '' : 'd-none'">
		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef ></th>
			<td mat-cell *matCellDef="let vendor">
				<a [routerLink]="''+vendor.supplierid" mat-stroked-button class="mt-1">View</a></td>
		</ng-container>
		<ng-container matColumnDef="supplierid">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by vendor">
			  Vendor#
			</th>
			<td mat-cell *matCellDef="let vendor"> {{vendor.supplierid}} </td>
		  </ng-container>
		<ng-container matColumnDef="suppname">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
			<td mat-cell *matCellDef="let vendor"> {{ vendor.suppname}} </td>
		</ng-container>
		<ng-container matColumnDef="address1">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Address</th>
			<td mat-cell *matCellDef="let vendor" [innerHTML]= "vendor.address1"></td>
		</ng-container>
		<ng-container matColumnDef="telephone">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Phone#</th>
			<td mat-cell *matCellDef="let vendor" [innerHTML]= "vendor.telephone"></td>
		</ng-container>
		<ng-container matColumnDef="address4">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> City</th>
			<td mat-cell *matCellDef="let vendor" [innerHTML]= "vendor.address4"></td>
		</ng-container>
		<tr mat-header-row  *matHeaderRowDef="headercolumns;sticky: true;"></tr>
		<tr mat-row *matRowDef="let row; columns: headercolumns;" (click)="selectVendor(row)"></tr>
	</table>
	<mat-paginator [pageSizeOptions]="[25, 50, 100]"
					 showFirstLastButtons
					 aria-label="select page"
					 [ngClass]="allResults.length ? '' : 'd-none'"
					 >
	  </mat-paginator>

</div>
