import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';
@Injectable({
	providedIn: 'root'
})

export class LoginService {
	key: string = 'UET6DFMPAXW7BKCB';
	config: any;
	package: any = '';

	constructor(private globalSearchService:GlobalSearchService,private http: HttpClient, private httpClient: HttpClient, private _configSvc: RuntimeConfigLoaderService) {
		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		})

	}
	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};
	public login(data) {

		var method = 'login';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('login', data))
		);
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId;
	}

	public getKey() {
		return '&apikey=' + this.key;
	}

	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}

	private handleError < T > (operation = 'operation', result ? : T) {
		return (error: any): Observable < T > => {
			console.error(error);
			return error;
		};
	}
}
