<ng-template #orderView let-modal>
	<div class="modal-header">
		<div class="modal-title row m-0 p-0" id="modal-title">
			<h4>Order# {{orderdetails.header.orderno}}</h4>
		</div>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="orderdetails">
			<div class="row border-bottom">
				<div class="col-md-3 text-center">
					<h5>Ship Via: {{ orderdetails.header.shippername }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Date Wanted: {{ orderdetails.header.datewanted }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Entered By: {{ orderdetails.header.enteredby }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Created: {{ orderdetails.header.date_created }}</h5>
				</div>
			</div>
			<div class="row mt-2 mb-2 border-bottom">
				<div class="col-md-3 " >
					<h5 class="mb-0">Ship To:</h5>
					<span [innerHTML]="orderdetails.header.shipto"></span>
				</div>
				<div class="col-md-3" >
					<h5 class="mb-0">Phone:</h5>
					{{orderdetails.header.contactphone}}
					<ng-container *ngIf="orderdetails.header.contactemail && orderdetails.header.contactemail != ''">
					<h5 class="mb-0">Email:</h5>
						{{orderdetails.header.contactemail}}
					</ng-container>
				</div>
				<div class="col-md-3" >
					<h5>Notes:</h5>
					{{orderdetails.header.comments}}
				</div>
				<div class="col-md-3" >
				<h5>History: </h5>
					<ng-container *ngIf="orderdetails.dispatch_details.dispatch_id">
						<ul>
						<li *ngIf="orderdetails.dispatch_details.truck_name">ShipVia: {{ orderdetails.dispatch_details.truck_name }}</li>
						<li *ngIf="orderdetails.dispatch_details.datecreated !='0000-00-00 00:00:00'">Dispatched:
							{{ orderdetails.dispatch_details.datecreated | date:'shortDate' }}
							{{ orderdetails.dispatch_details.datecreated | date:'shortTime' }}

						</li>
						<li *ngIf="orderdetails.dispatch_details.delivery_date !='0000-00-00 00:00:00'">Delivered:
							{{ orderdetails.dispatch_details.delivery_date | date:'shortDate' }}
							{{ orderdetails.dispatch_details.delivery_date | date:'shortTime' }}
							</li>
						</ul>
					</ng-container>
					<ng-container *ngIf="!orderdetails.dispatch_details.dispatch_id">
						N/A
					</ng-container>
				</div>
			</div>
			<div class="table">
				<table class="table table-sm">
					<tr>
						<th>ITEM</th>
						<th>DESC</th>
						<th class="text-center">ORDERED</th>
						<th class="text-center">INVOICED</th>
						<th class="text-center">OUTSTANDING</th>
						<th class="text-right">PRICE</th>
						<th class="text-right">DISCOUNT</th>
						<th class="text-right">TOTAL</th>
					</tr>
					<tr *ngFor="let item of orderdetails.details">

						<td>{{item.stkcode}}</td>
						<td>{{item.description}}</td>
						<td class="text-center">{{item.ordered}}</td>
						<td class="text-center">{{item.qtyinvoiced}}</td>
						<td class="text-center">{{item.ordered - item.qtyinvoiced}}</td>
						<td class="text-right">{{item.unitprice | currency}}</td>
						<td class="text-right">{{item.discountpercent | percent }}</td>
						<td class="text-right">{{item.discountprice * item.ordered | currency}}</td>
					</tr>
					<tr *ngFor="let t of orderdetails.saved_totals">
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center"></td>
						<td class="text-center"></td>
						<td class="text-center"></td>
						<td class="text-right">{{ t.title }}:</td>
						<td class="text-right">{{t.text | currency}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Open Order Items Inquiry
						</h4>
						<p class="card-category text-right">Open Order Lines</p>
					</div>
					<div class="card-body">
						<form class="navbar-form">
							<div class="row">
								<div class="col-md-2 mt-3">
								<mat-form-field appearance="standard">
									<mat-label>Branch</mat-label>
									<mat-select [formControl]="branchRef">
										<mat-option *ngFor="let v of branches" [value]="v.loccode">
											{{v.locationname}}
										</mat-option>
									</mat-select>
								</mat-form-field>
								</div>
								<div class="col-md-8 mt-3">

									<mat-form-field appearance="standard" class="">
										<span matPrefix *ngIf="orderSearch.length">
											<button  mat-icon-button class="p-0" (click)="clearForm()">
												<mat-icon class="p-0" >clear</mat-icon>
											</button>
										</span>
										
										<input matInput class="text-right mt-0 mb-0 pt-0 pb-0 " value="" [formControl]="orderSearchRef" placeholder="Search For an Item..." (keydown.enter)="$event.preventDefault();searchOrders()">
										
										<div class="ripple-container"></div>
									</mat-form-field>
								</div>
								<div class="col-md-2 mt-3">
								<button mat-raised-button color="accent" class="ml-2 mt-2" (click)="searchOrders()" *ngIf="!sending">
									<mat-icon>refresh</mat-icon>
								</button>
								<span *ngIf="sending">
								<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
								</span>
								</div>
							</div>

						</form>
						<div *ngIf="orderResults">
						&nbsp;&nbsp; <button class="btn btn-success btn-sm" (click)="$event.preventDefault();exportXls()" *ngIf="orderResults">
							<i class="fa fa-file-excel-o"></i> XLS
						</button>
						&nbsp;&nbsp;
						<button class="btn btn-danger btn-sm" (click)="$event.preventDefault();exportPdf()" *ngIf="orderResults">
							<i class="fa fa-file-pdf-o"></i> PDF
						</button>
						<div #print_table>
						<h3> Open Order Items Report {{ currdate | date: "short" }}</h3>
						<table class="table">
								
							<ng-container *ngFor="let o of orderResults | keyvalue">		
								<tr><th colspan="7"> Item: {{o.key}} - {{o.value.desc }} </th></tr>
								<tr>
									<th>BR</th>
									<th>Order No</th>
									<th>Customer</th>
									<th>Date</th>
									<th>Qty</th>
									<th>Price</th>
									<th>Total</th>
								</tr>
								<ng-container *ngFor="let line of o.value.lines">
								
								<tr >
									<td>
									{{ line.branch }}
								</td>
									<td>{{ line.orderno }}</td>
									<td>{{ line.debtorno }}.{{ line.branchcode }}</td>
									<td>{{ line.orddate | date:"short" }}</td>
									<td>{{ line.qty | number }}
									<td>{{ line.price | currency }}</td>
									<td>{{ line.ext | currency }}</td>									
								</tr>
								</ng-container>
								<tr>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th>{{o.value.totalqty}}</th>
									<th></th>
									<th>{{o.value.total | currency}}</th>
								</tr>
							</ng-container>
						</table>
						</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
