import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { UsersComponent } from './users.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatListModule } from '@angular/material/list';
import { MatTreeModule } from '@angular/material/tree';
import { MatTabsModule } from '@angular/material/tabs';
import {MatCardModule} from '@angular/material/card';

import { UserGroupsComponent } from './user-groups/user-groups.component';
import { UserGroupsEditComponent } from './user-groups-edit/user-groups-edit.component';
import { UserEditComponent } from './user-edit/user-edit.component';

import { UserLocationsComponent } from './user-locations/user-locations.component';
import { UserCreateComponent } from './user-create/user-create.component';


@NgModule({
  declarations: [
	UsersComponent,
    UserProfileComponent,
    UserSettingsComponent,
    UserGroupsComponent,
    UserGroupsEditComponent,
	UserEditComponent,
    UserLocationsComponent,
    UserCreateComponent
  ],
  imports: [
	MatCardModule,
    CommonModule,
	RouterModule,
	ReactiveFormsModule,
	FormsModule,
	MatToolbarModule,
	MatButtonModule,
	MatDialogModule,
	MatDividerModule,
	MatInputModule,
	MatTableModule,
	MatCheckboxModule,
	MatSortModule,
	MatPaginatorModule,
	MatFormFieldModule,
	MatAutocompleteModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatExpansionModule,
	MatSelectModule,
	MatMenuModule,
	MatIconModule,
	MatSlideToggleModule,
	MatStepperModule,
	MatListModule,
	MatTreeModule,
	MatTabsModule
  ]
})
export class UsersModule { }
