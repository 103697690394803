import { Component, SimpleChanges, OnChanges, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, EventEmitter, Pipe, PipeTransform, AfterContentChecked } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Directive, HostListener } from "@angular/core";
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { CustomerService } from '../../../services/customer.service';
import { OrdersService } from '../../../services/orders.service';
import { InventoryService } from '../../../services/inventory.service';
import { PurchasingService } from '../../../services/purchasing.service';

import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { UsersService } from '../../../services/users.service';

import { OmsService } from '../../../services/oms.service';

@Component({
	selector: 'app-po-review',
	templateUrl: './po-review.component.html',
	styleUrls: ['./po-review.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('250ms ease-in')),
			transition('active => inactive', animate('250ms ease-out')),
		]),
		trigger('openClose', [
			state(':enter', style({ height: '*' })),
			state(':leave', style({ height: '0px' })),
			transition('false <=> true', animate(500))
		]),
		trigger('filterAnimation', [
			transition(':enter, * => 0, * => -1', []),
			transition(':increment', [
				query(':enter', [
					style({ opacity: 0, width: 0 }),
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 1, width: '*' })),
					]),
				], { optional: true })
			]),
			transition(':decrement', [
				query(':leave', [
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 0, width: 0 })),
					]),
				])
			]),
		]),
		trigger("grow", [ // Note the trigger name
			transition(":enter", [
				// :enter is alias to 'void => *'
				style({ height: "0", width: "0" }),
				animate(200, style({ height: "*", width: "*" })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(":leave", [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0,})),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class PoReviewComponent implements OnInit {
	@Input() vendor: any = false;
	@Input() cart_items: any = false;
	@Input() cart_totals: any = false;

	@Output() updateVendorData = new EventEmitter < any > ();
	@Output() updateCart = new EventEmitter < any > ();
	@Output() removeItem = new EventEmitter < any > ();
	@Output() freight_charge = new EventEmitter < any > ();
	@Output() update_note = new EventEmitter < any > ();

	editbilling: boolean = false
	editshipping: boolean = false
	editsettings: boolean = false
	vendorForm: UntypedFormGroup;
	vendorEditAllowed = [
		'suppname',
		'address1',
		'address2',
		'address3',
		'address4',
		'address5',
		'location',
		'deladd1',
		'deladd2',
		'deladd3',
		'deladd4',
		'deladd5',
		'deladd6',

		//'address6',
		//'contactname'
	]

	addonfields: [
		'datewanted',
		'enteredby',
		'takenby',
		'freightcost',
	];

	user: any = [];
	today = new Date();
	shipvia: any = false;
	taxgroups: any = [];
	salespeople: any = [];
	terms: any = [];
	zones: any = false;
	locations: any = [];
	holdreasons: any = [];
	salestypes: any = [];
	quote = [
		{ label: 'Order', value: '0' },
		{ label: 'Quote', value: '1' }
	]
	config: any = [];

	loccode: any = false;
	location_selected: any = false;
	statuses: any = [];
	initiator: any = '';
	comments: any;
	changeDetector: any;


	constructor(private purchasingService: PurchasingService, private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, public omsService: OmsService, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal, public usersService: UsersService) {
		this.globalSearchService.user.subscribe((results: any) => {
			if (results) {
				this.user = results.user;
				this.initiator = results.user.userid;
			}
		});
		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		});
	}

	ngOnInit(): void {

		//globals

		// alert(this.cart_items);
		this.globalSearchService.user.subscribe((results: any) => {
			if(results) {
				this.user = results.user;

				this.purchasingService.getLocations('').subscribe((results:any) => {
					this.locations = results;

					this.location_selected = results.filter( (loc: any) => {
						return loc.loccode == this.user.defaultlocation.loccode
					})[0];

					this.globalsService.getDefaultZones().subscribe(details => {
						this.zones = details;
					});

					this.purchasingService.getStatus().subscribe(details => {
						this.statuses = details;
					});

					this.loccode = this.user.defaultlocation.loccode

					var future = new Date();
					future.setDate(future.getDate() + 3);
					//form build here

					var ref = '';

					this.initiator = this.user.userid;
					//reset form based on existing order details
					if (this.vendor.editingorder) {
						ref = this.vendor.editingorder.requisitionno;
						this.vendor.braddress1 = this.vendor.editingorder.deladd1
						this.vendor.braddress2 = this.vendor.editingorder.deladd2
						this.vendor.braddress3 = this.vendor.editingorder.deladd3
						this.vendor.braddress4 = this.vendor.editingorder.deladd4
						this.vendor.braddress5 = this.vendor.editingorder.deladd5
						this.vendor.braddress6 = this.vendor.editingorder.deladd6
						this.initiator = this.vendor.editingorder.initiator
					}


					this.vendorForm = this.fb.group({
						suppname: [this.vendor.suppname, [Validators.required]],
						address1: [this.vendor.address1, [Validators.required]],
						address2: [this.vendor.address2, []],
						address3: [this.vendor.address3, [Validators.required]],
						address4: [this.vendor.address4, [Validators.required]],
						address5: [this.vendor.address5, [Validators.required]],
						address6: [this.vendor.address6, [Validators.required]],
						deladd1: [this.location_selected.deladd1],
						deladd2: [this.location_selected.deladd2],
						deladd3: [this.location_selected.deladd3],
						deladd4: [this.location_selected.deladd4],
						deladd5: [this.location_selected.deladd5],
						deladd6: [this.location_selected.deladd6],
						warehouse: [this.loccode,[Validators.required]],
						deliverydate: [future.toISOString(),[Validators.required]],
						currcode:  [this.vendor.currcode,[Validators.required]],
						initiator: [this.initiator,[Validators.required]],
						refrenceno: [ref],
						comments:[this.comments]
					});

					this.updateVendorData.emit(this.vendorForm.value);

				});
			}
		});
	}

	loadData() {


		var dnote = '';
		var inote = '';
		//note already set re apply it

		if (this.vendorForm) {
			if (this.vendorForm.get('comments').value != '') {
				dnote = this.vendorForm.get('comments').value;
			} else {
				dnote = this.vendor.dispatchnote;
			}
			// if (this.vendorForm.get('notes').value != '') {
			// 	inote = this.vendorForm.get('notes').value;
			// 	//this.vendorForm.get('notes').setValue(this.vendor.dispatchnote);
			// }
		}

		var ref = '';
		//reset form based on existing order details

		this.initiator = this.user.userid;

		if (this.vendor.editingorder) {
			//dnote = this.vendor.editingorder.notes;
			inote = this.vendor.editingorder.comments;
			ref = this.vendor.editingorder.requisitionno;

			this.vendor.braddress1 = this.vendor.editingorder.deladd1
			this.vendor.braddress2 = this.vendor.editingorder.deladd2
			this.vendor.braddress3 = this.vendor.editingorder.deladd3
			this.vendor.braddress4 = this.vendor.editingorder.deladd4
			this.vendor.braddress5 = this.vendor.editingorder.deladd5
			this.vendor.braddress6 = this.vendor.editingorder.deladd6
			this.initiator = this.vendor.editingorder.initiator
			this.comments = this.vendor.editingorder.comments
		}

		var future = new Date();
		future.setDate(future.getDate() + 3);

		this.vendorForm = this.fb.group({
				suppname: [this.vendor.suppname, [Validators.required]],
				address1: [this.vendor.address1, [Validators.required]],
				address2: [this.vendor.address2, []],
				address3: [this.vendor.address3, [Validators.required]],
				address4: [this.vendor.address4, [Validators.required]],
				address5: [this.vendor.address5, [Validators.required]],
				address6: [this.vendor.address6, [Validators.required]],
				deladd1: [this.location_selected.deladd1],
				deladd2: [this.location_selected.deladd2],
				deladd3: [this.location_selected.deladd3],
				deladd4: [this.location_selected.deladd4],
				deladd5: [this.location_selected.deladd5],
				deladd6: [this.location_selected.deladd6],
				warehouse: [this.loccode,[Validators.required]],
				deliverydate: [future.toISOString(),[Validators.required]],
				currcode:  [this.vendor.currcode,[Validators.required]],
				initiator: [this.initiator,[Validators.required]],
				refrenceno: [ref],
				comments: [this.comments]
			});

			this.updateVendorData.emit(this.vendorForm.value);

		if (this.vendor.customerpoline === true || this.vendor.customerpoline === '1') {
			this.vendorForm.get('refrenceno').setValidators([Validators.required])
			this.vendorForm.get('refrenceno').updateValueAndValidity();
		} else {

		}
		if(ref != '') {
			this.vendorForm.get('refrenceno').setValue(ref)
		}

		this.vendorForm.updateValueAndValidity();

		this.updateVendorData.emit(this.vendorForm);

	}

	repriceCart(event: any) {
		if (event.value != this.vendor.salestype) {
			this.vendor.salestype = event.value
			let data = {
				debtorno: this.vendor.debtorno,
				branchcode: this.vendor.branchcode,
				salestype: event.value,
			}
			this.orderService.repriceCart(data).subscribe((result: any) => {
				this.sendCartUpdate();
			});
		}
	}
	toggleEditShipping() {
		this.editshipping = (this.editshipping) ? false : true;
	}

	toggleEditBilling() {
		this.editbilling = (this.editbilling) ? false : true;
	}

	toggleEditSettings() {
		this.editsettings = (this.editsettings) ? false : true;
	}

	updatePrice(event: any, item: any) {
		if (event.target.value != '') {
			let index = this.cart_items.indexOf(item);
			this.cart_items[index].price = event.target.value;
			//this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
			this.updateCartItem(this.cart_items[index]);
		}
	}

	updateQuantity(event: any, item: any) {
		if (event.target.value != '') {
			let index = this.cart_items.indexOf(item);
			this.cart_items[index].quantity = event.target.value;
			//this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
			this.updateCartItem(this.cart_items[index]);
		}
	}

	getDiscounPrice(item: any) {
		var discountprice = this.financial((item.price * (item.discount / 100)));
		var total = this.financial(item.price - discountprice);
		if(total < 0) {
			total = 0;
		}
		return total;
	}

	financial(x) {
		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	getDiscountTotal(item: any) {
		var discounted = (item.price * (item.discount / 100)).toFixed(2);
		var discountprice = (item.price - parseFloat(discounted))
		var total = item.quantity *  discountprice;
		if(total < 0) {
			total = 0;
		}

		return total;
	}

	updateDiscount(event: any, item: any) {
		if (parseFloat(event.target.value) < 100) {

		} else {
			event.target.value = 100;
		}

		let index = this.cart_items.indexOf(item);
		this.cart_items[index].discount = event.target.value;
		this.updateCartItem(this.cart_items[index]);
	}

	updateNote(event: any, item: any) {
		let index = this.cart_items.indexOf(item);
		this.cart_items[index].notes = event.target.value;
		this.updateCartItem(this.cart_items[index]);

		this.update_note.emit({event: event, item: item});
	}

	updateCartItem(item: any) {

		let index = this.cart_items.indexOf(item);


		// alert("update cart po-review: " + JSON.stringify(this.cart_items));

		let data = {
			customer: this.vendor.debtorno,
			branch: this.vendor.branchcode,
			cartid: item.cart_id,
			cart_id: item.cart_id,
			price: item.price,
			option: item.options,
			quantity: item.quantity,
			discount: item.discount,
			notes: item.notes,
			//editing_order: this.editing_order,
			//order_details: this.order_details,
		};


		this.updateCart.emit(data);
	}



	removeFromOrder(cart_id) {
		this.removeItem.emit(cart_id)
	}

	sendCartUpdate() {
		this.updateCart.emit(true);
	}

	ngOnChanges(changes: any) {
		if (this.vendor.editingorder) {
			//tick timeout to proc a change detection cycle for the view to be updated
			setTimeout(()=>{this.loadData()}, 0);
		}
		this.updateVendorData.emit(this.vendorForm);
	}


}
