import { Component,NgZone, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { ViewportScroller } from "@angular/common";

import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';

import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { OmsService } from '../../services/oms.service';
import { DispatchService } from '../../services/dispatch.service';
import { PrintService } from '../../services/print.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { FormControl } from '@angular/forms';

import { BluetoothService} from '../../services/bluetooth.service'

declare const google: any;

interface scanner {
	value: string;
	viewValue: string;
}


@Component({
	selector: 'app-warehouse-checking',
	templateUrl: './warehouse-checking.component.html',
	styleUrls: ['./warehouse-checking.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(179deg)'
			})),
			transition('active => inactive', animate('300ms ease-out')),
			transition('inactive => active', animate('300ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		])
	]
})
export class WarehouseCheckingComponent implements OnInit {
	device: any;
	batteryLevel: any = '';
	color: any = 'blue';
	user: any = false;
	config: any = false;
	sortcolumns: any = [];
	scantoadd1 = new UntypedFormControl('');

	constructor(  public _zone: NgZone,private printService: PrintService, private fb: UntypedFormBuilder, private dispatchService: DispatchService, public omsService: OmsService, public location: Location, public ordersService: OrdersService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, public formBuilder: UntypedFormBuilder, public inventoryService: InventoryService, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();

		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
		});

		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		})


	}

	ngOnInit(): void {

	}

	//------------------------------bluetooth------------------------------//
// 	discover() {
// 		this.bluetoothService.discover();
// 	}
//
// 	getDeviceStatus() {
// 	  this.bluetoothService.getDevice().subscribe(
// 		(device) => {
//
// 		  if(device) {
// 			this.device = device;
// 		  }
// 		  else {
// 			// device not connected or disconnected
// 			this.device = null;
// 			this.batteryLevel = '--';
// 		  }
// 		}
// 	  );
// 	}
//
// 	getBatteryLevel() {
// 	  return this.bluetoothService.value().subscribe(this.showBatteryLevel.bind(this));
// 	}
//
// 	showBatteryLevel(value: number) {
// 	  // force change detection
// 	  this._zone.run( () =>  {
// 		this.batteryLevel = ''+value;
// 	  });
// 	}
}
