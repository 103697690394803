import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, ViewEncapsulation} from '@angular/core';
import { InventoryService } from '../../services/inventory.service';
import { PurchasingService } from '../../services/purchasing.service';
import { OrdersService } from '../../services/orders.service';
import { OmsService } from '../../services/oms.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { FormBuilder, FormControl, FormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Location } from '@angular/common'
import { PrintService } from '../../services/print.service'

@Component({
	selector: 'app-warehouse-stock-counts',
	templateUrl: './warehouse-stock-counts.component.html',
	styleUrls: ['./warehouse-stock-counts.component.scss'],
	animations: [
		trigger('flipState', [
			  state('active', style({
				  transform: 'rotate3d(0, 1, 0, 179deg)',
			  })),
			  state('inactive', style({
				  transform: 'rotateY(0)'
			  })),
			  state('orderlookup', style({
				  transform: 'rotateY(179deg)'
			  })),
			  transition('active => inactive', animate('500ms ease-out')),
			  transition('inactive => active', animate('500ms ease-in')),
			  transition('inactive => orderlookup', animate('500ms ease-out')),
			  transition('orderlookup => inactive', animate('500ms ease-in')),
		  ]),
	]
})
export class WarehouseStockCountsComponent implements OnInit {
	today = new Date();
	@ViewChild('count_table') printtable: ElementRef;
	color: any = 'blue';

	location: any = '';
	locations: any = [];
	suppliers: any = false;
	user: any = [];

	sheetForm: FormGroup;
	vendor_selected: any = false;
	sending: boolean = false;
	exporting: boolean = false;
	counting_items: any =false;
	fetching: boolean = false;
	nil = new FormControl(false);
	flip: string = 'inactive';
	filename: string = 'StockCounts';
	stockCountsLink: any = false;
	config: any = false;
	constructor(public printService: PrintService, public lLoaction: Location, private fb: FormBuilder,private route: ActivatedRoute, public router: Router, private modalService: NgbModal, private omsService: OmsService, private globalSearchService: GlobalSearchService, private inventoryService: InventoryService, public ordersService: OrdersService, public purchasingService: PurchasingService) {

		this.color = this.globalSearchService.getColor();

		this.globalSearchService.user.subscribe((u: any) => {
			this.user = u;
			this.location = u.user.defaultlocation.loccode
		});
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config =  r;
		})
	}

	ngOnInit(): void {

		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
		});

		this.purchasingService.getSuppliers('').subscribe(async (results: any) => {
			this.suppliers = results;
			this.sheetForm = this.fb.group({
				keywords: [''],
				location: [this.locations[0].loccode, Validators.required],
				vendor: [this.vendor_selected, Validators.required],
				category: [''],
				lineid: [''],
				stockid: [''],
			});

			this.inventoryService.getCurrentCounts(this.sheetForm.value).subscribe(r=> {
				this.fetching = false;
				if(r.success) {
					this.counting_items = r.items;
				} else {
					this.globalSearchService.showNotification(r.message, 'danger', 'bottom', 'right');
				}
			})
		})
	}

	resetSheets(event: any) {
		if(event) {
			this.counting_items = false;
			this.fetching = false;
			this.flip = 'inactive';
		}
	}

	pdfLink() {
		return this.inventoryService.printCountSheets(this.sheetForm.value)
	}

	exportXls() {
		this.exporting = true;
		let encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.exporting = false;
		});
	}

	keywordItemSearch() {

	}

	keywordSearch() {

	}

	selectVendor(supplier: any) {
		this.vendor_selected = supplier.supplierid
		this.sheetForm.get('vendor').setValue(supplier.supplierid)
	}

	selectCategory(value: any) {
		this.sheetForm.get('category').setValue(value.categoryid)
	}

	selectProductLine(value: any) {
		this.sheetForm.get('lineid').setValue(value.line_field)
	}

	createLoadSheets() {
		this.fetching = true;
		this.inventoryService.createLoadSheets(this.sheetForm.value).subscribe( (r:any)=> {
			this.fetching = false;
			if(r.success) {
				this.counting_items = r.items;
			} else {
				this.globalSearchService.showNotification(r.message, 'danger', 'bottom', 'right');
			}
		});
	}

	setQty(event: any, item: any) {

		var val = parseFloat(event.target.value);
		if(isNaN(val)) {
			val = 0;
		}

		item.counted = val;
	}

	saveCounts() {

		this.inventoryService.saveCounts(this.counting_items).subscribe(r=> {

			if(r.success) {
				this.globalSearchService.showNotification(r.msg, 'success', 'bottom', 'left');
				/*
				$('#floater-container').html('')

				r.update.forEach(sub => {

					$('#floater-container').append('<div class="alert alert-success"> '+sub.msg+'<button type="button" class="close" data-dismiss="alert">&times;</button></div>');

					if(sub.ignore == 1) {
						sub.background = 'bg-success text-white'
					} else {
						sub.background = 'bg-danger text-white'
					}
					setTimeout(function () {
						$('.alert, .text-danger').fadeOut();
					}, 500);
				})
				*/
			}
		})
	}



	identify(index, item) {
		return item.line;
	 }

	toggleFlip() {
		this.flip = (this.flip =='inactive') ? 'active' : 'inactive';
		if(this.flip == 'active') {

		}
	}

	back(): void {
		this.lLoaction.back()
	}

}
