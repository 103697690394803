import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit} from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { interval, Subscription} from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router} from '@angular/router';

import { InventoryService } from '../../services/inventory.service'
import { QantelService } from '../../services/qantel.service'
import { GlobalsService } from '../../services/globals.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';

import { Location } from '@angular/common'
import { Alert } from 'selenium-webdriver';


@Component({
  selector: 'app-qantel-item-barcode-add',
  templateUrl: './qantel-item-barcode-add.component.html',
  styleUrls: ['./qantel-item-barcode-add.component.scss']
})
export class QantelItemBarcodeAddComponent implements OnInit {
	@Input() searchedfor;
	@Output() barcode_added = new EventEmitter<any>();
	itemSearchForm = new UntypedFormControl('');
	barcodeForm = new UntypedFormControl('');
	inventorysearching: any = false;
	sending: boolean = false;
	searching: boolean = false;
	allitems: any = [];
	filteredItems: any = [];
	selecteditem: any = false;

	constructor(private inventoryService: InventoryService, private qantelService: QantelService) { }

	ngOnInit(): void {

		this.barcodeForm.setValue(this.searchedfor);
		this.itemSearchForm.valueChanges.subscribe(newValue=>{
			this.itemSearch(newValue)
		})

	}

	selectItem(item: any) {
		this.selecteditem = item;
		this.itemSearchForm.setValue("");
		this.barcodeForm.setValue(this.searchedfor);
		this.allitems = [];
		this.filteredItems = [];
	}

	itemSearch(event: any) {

		this.searching = true;
		if(this.inventorysearching) {
			this.inventorysearching.unsubscribe()
		}

		this.inventorysearching = this.qantelService.rawSearch(event).subscribe((items: any) => {

			this.allitems = items
			this.filteredItems = items

			//if(this.onhandonly) {
			//	this.filtered_items = items.filter((item:any) => {
			//		return parseFloat(item.qoh) > 0
			//	});
			//}
			this.searching = false;
		});
	}

	saveBarcode() {
		if(confirm("Add Barcode "+ this.searchedfor+" to "+ this.selecteditem.stockid)) {
			let data = { stockid: this.selecteditem.stockid, barcode: this.barcodeForm.value };
			this.qantelService.addBarcode(data).subscribe( (result: any) =>{
				if(result.success) {
					this.barcode_added.emit(this.selecteditem);
				}
			});
		} else {

		}
	}

}
