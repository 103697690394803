import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit} from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

import * as FileSaver from 'file-saver';

import { CustomerService } from '../../../services/customer.service';
import { OrdersService } from '../../../services/orders.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { PurchasingService } from '../../../services/purchasing.service';

import { Directive, HostListener } from "@angular/core";
import { trigger, state, style, transition, animate } from '@angular/animations';
import { saveAs } from "file-saver";

@Component({
	selector: 'app-order-success',
	templateUrl: './order-success.component.html',
	styleUrls: ['./order-success.component.scss']
	})
export class OrderSuccessComponent implements OnInit {
	transactionid: any = '';
	invoice: any = [];
	reprintlink: any = '';
	printed: boolean = false;
	config: any =[];
	color: any = 'blue';

	baselink: string = '';

	constructor(private route: ActivatedRoute,private customerService: CustomerService, private orderService: OrdersService, private location: Location,public router: Router,private globalSearchService: GlobalSearchService, private purchasingService: PurchasingService) { }

	ngOnInit(): void {
		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		});
		this.color = this.globalSearchService.getColor();

		this.route.params.subscribe(params => {
			this.transactionid = params.id
			this.orderService.getTransactionById(params.id).subscribe((details: any) => {
				this.invoice = details
				this.reprintlink = this.config.apiServer.baseUrl+this.config.apiServer.invoiceLink + details.id
				if(!this.printed) {
					this.printed = true;
					//this.goToLink(this.reprintlink);
				}
			})
		})
	}



	goToLink(url: string){
		window.open(url);
	}

}
