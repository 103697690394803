import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UsersService } from '../../services/users.service'
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { GlobalsService } from '../../services/globals.service'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { Location } from '@angular/common'

@Component({
	selector: 'app-user-edit',
	templateUrl: './user-edit.component.html',
	styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
	@Input() user: any;
	@Output() updated = new EventEmitter < any > ();
	title = 'edit users';

	userForm: UntypedFormGroup;
	userdetails: any = [];
	locations: any = [];
	userlocations: any = [];
	usergroups: any = [];
	alllocations: any = [];
	yesno: any = [
		{ value: '0', name: 'No' },
		{ value: '1', name: 'Yes' }
	];

	formEditAllowed = [
		'userid',
		'password',
		'realname',
		'phone',
		'email',
		'defaultlocation',
		'fullaccess',
		'cancreatetender',
		'editinv',
		'dispatchadmin',
		'customeradmin',
		'user_group',
		'blocked',
		'empcode',
		'department',
		'salesman',
		//'phoneno',
	]

	userid: any = '';
	color: any = 'blue';

	constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, private userService: UsersService, public router: Router, private location: Location, public globalSearchService: GlobalSearchService, private globalsService: GlobalsService) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			if (params['id']) {
				this.userid = params['id'];
				this.loadUser()
			} else {
				this.userid = this.user.userid;
				this.loadUser();
			}
		});
	}

	loadUser() {
		this.userService.getUser(this.userid).subscribe((user: any) => {
			this.user = user;
			this.userdetails = user;
			var controls = [];
			var details = user;
			const formGroup: UntypedFormGroup = new UntypedFormGroup({});
			Object.keys(details).forEach(key => {
				if (this.formEditAllowed.includes(key)) {
					let value = (details[key]) ? details[key] : '';
					controls.push({ name: key, control: new UntypedFormControl(value, []) })
				}
			});
			controls.forEach(f => {
				formGroup.addControl(f.name, f.control, {})
			});

			this.userForm = formGroup;
			this.userForm.controls['userid'].disable();
			this.userForm.controls['password'].setValue('');
		});
	}

	back(): void {
		this.location.back()
	}

	onSave($event) {}

	updateUser() {

		this.userService.updateUser(this.user.userid, this.userForm.value).subscribe(async (results) => {
			this.user.user = results;
			this.globalSearchService.showNotification("Updated", 'success', 'bottom', 'right');
			this.updated.emit(results);

		});

	}

	ngAfterViewInit() {

		this.globalsService.getAllLocations().subscribe(async details => {
			this.locations = details;
			this.alllocations = details;
		});

		this.userService.getUserGroups().subscribe(async groups => {
			this.usergroups = groups;
		});

	}

	updateLocations() {
		let data = {
			userid: this.userid,
			locations: this.user.locations
		};

		this.userService.updateUserLocations(data).subscribe(async (user: any) => {
			this.user = user;
			this.globalSearchService.showNotification('Modifed', 'success', 'bottom', 'left')
		});
	}

	hasLocation(location: any) {

		//this.user becomes false when modalService closes
		let access = []

		if(this.user) {
			access = this.user.locations.filter(loc => {
				return loc.loccode == location;
			})
		};

		return (access.length > 0) ? true : false;
	}

	addUserLocation(loc: any) {
		let index = this.locations.indexOf(loc)
		this.locations.splice(index, 1);
		this.user.locations.push(loc)
	}

	addAllLocation(loc: any) {
		this.user.locations = this.locations;
	}

	removeUserLocation(loc: any) {
		let index = this.user.locations.indexOf(loc);
		this.user.locations.splice(index, 1);
	}
	onSubmit() {

	}
}
