import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UsersService } from '../../services/users.service'
import { ActivatedRoute, Router } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { Location } from '@angular/common'
import { ROUTES, AdminLayoutRoutes } from '../../classes/routes'
import * as jq from "jquery";
import { BehaviorSubject } from 'rxjs';

import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { GlobalsService } from '../../services/globals.service'
import { SocketService } from '../../services/socket.service'


declare var $: any;

export interface Access {
	title: string;
	authorized: boolean;
	color: ThemePalette;
	children ? : Access[];
}

export interface Modify {
	title: string;
	authorized: boolean;
	color ? : ThemePalette;
	children ? : Modify[];
}

@Component({
	selector: 'app-user-groups-edit',
	templateUrl: './user-groups-edit.component.html',
	styleUrls: ['./user-groups-edit.component.scss']
})

export class UserGroupsEditComponent implements OnInit {

	title = 'User Group';
	group: any = []
	yesno: any = [
		{ value: '0', name: 'No' },
		{ value: '1', name: 'Yes' }
	];
	ROUTES = ROUTES;
	userForm: UntypedFormGroup;
	groupname = new UntypedFormControl('');
	group_id: any = '';
	permissions: any = [];
	allAccess: boolean = false;
	task: any = [];
	access: any = [];
	labelPosition: 'before' | 'after' = 'after';
	access_list: any = [];
	CHAT_ROOM = "Users";
	color: string = 'blue';
	group_access : any = [];

	constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, private userService: UsersService, public router: Router, private location: Location, public globalSearchService: GlobalSearchService, private globalsService: GlobalsService, public socketService: SocketService) {
		let accesss = [];
		this.color = this.globalSearchService.getColor();

		ROUTES.forEach(location => {
			let link = {
				title: location.title,
				authorized: false,
				children: [],
				path: location.path,
				group: location.anchor,
			};

			if (location.children) {
				location.children.forEach(child => {
					let childauth = {
						title: child.title,
						authorized: false,
						path: child.path,
						children: [],
						group: location.anchor,
					};
					link.children.push(childauth);
				});
			}
			this.access_list.push(link);
		})

	}


	ngOnInit(): void {
		this.route.params.subscribe(params => {
			this.group_id = params['id'];
			this.loadData();
		});
	}

	loadData() {
		this.userService.getUserGroup(this.group_id).subscribe((group: any) => {
			this.groupname.setValue(group.name);
			var auth = this.access_list.map(bc => bc.path);

			this.group_access = group.access
			this.access_list.forEach( (a , index) => {
				this.access_list[index].authorized = this.allowed(a.path)
				if(a.children) {
					a.children.forEach(child => {
						let allowed = this.allowed(child.path)
						child.authorized = allowed
						if(allowed) {
							//parent access permitted if child is
							this.access_list[index].authorized = true;
						}
					})
				}
			})
			this.group = group
		});
	}

	allowed(path: any ): boolean {
		let a = this.group_access.indexOf(path);
		return (a < 0) ? false : true;
	}

	back(): void {
		this.location.back()
	}

	updateAccessComplete(parent: any) {
		let parent_index = this.access_list.indexOf(parent);

		if (!this.access_list[parent_index].children.length) {
			return;
		}

		this.access_list[parent_index].authorized = this.access_list[parent_index].children.length && this.access_list[parent_index].children.every(t => t.authorized);

		//this.allAccessComplete[parent_index] = this.access_list[parent_index].children != null && this.access_list[parent_index].children.every(t => t.authorized);
	}

	updateAccess(completed: boolean, parent: any, child: any) {
		let parent_index = this.access_list.indexOf(parent);
		let child_index = this.access_list[parent_index].children.indexOf(child)

		if (!completed) {
			this.allAccess = false;
		}
		if (this.access_list[parent_index].children[child_index] == null) {
			return false;
		}

		this.access_list[parent_index].children[child_index].authorized = completed
		return this.access_list[parent_index].children[child_index].authorized

	}

	setChildAccessAuthorized(completed: boolean, parent: any) {

		let index = this.access_list.indexOf(parent);
		if (!completed) {
			this.allAccess = false;
		}

		if (this.access_list[index].children == null) {
			return;
		}

		this.access_list[index].children.forEach(t => (t.authorized = completed));
	}

	setAllAccess(completed: boolean) {
		this.allAccess = completed

		this.access_list.forEach(t => {
			t.authorized = completed;
			t.children.forEach((child: any) => (child.authorized = completed))
		});
	}

	saveAccess() {
		let data = { access: this.access_list, group: this.group, name: this.groupname.value, groupid: this.group_id }
		this.userService.updateGroup(data).subscribe((result: any) => {
			data = result;
			this.socketService.sendUserGroupUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
			this.loadData();
			this.globalSearchService.showNotification('Modifed', 'success', 'bottom', 'left')
		})
	}

}
