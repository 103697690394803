import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform,AfterViewInit} from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';

import { DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { ActivatedRoute, Router} from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { OmsService } from '../../services/oms.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { PurchasingService } from '../../services/purchasing.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { Directive, HostListener,ViewEncapsulation } from "@angular/core";
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
selector: 'app-purchasing-dashboard',
templateUrl: './purchasing-dashboard.component.html',
styleUrls: ['./purchasing-dashboard.component.scss'],
encapsulation: ViewEncapsulation.None,
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(179deg)'
			})),
			transition('active => inactive', animate('500ms ease-out')),
			transition('inactive => active', animate('500ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		])
	]
})
export class PurchasingDashboardComponent implements OnInit {
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	flip: string = 'inactive';
	viewapp: string ='pos';
	flip_text: string= '';

	purchaseorders: any = [];
	allpurchaseorders: any = [];
	invoice_issues: any = [];
	partials: any = [];
	invoice: any = [];
	statuses: any = [];
	totals: any = {
			open: 0,
		};
	viewingdata: boolean = true;
	hassubscription: any = true;
	viewdata: any = [];
	returns: any = [];
	inbound: any = [];
	backorders: any = [];
	color: string = 'blue;'
	subtitle: string = '';
	viewing_data: any =false;
	viewing_title: any ='';

	constructor(private omsService: OmsService , private route: ActivatedRoute,private customerService: CustomerService, private orderService: OrdersService, private location: Location,public router: Router,public cdr: ChangeDetectorRef,private globalSearchService: GlobalSearchService,private modalService: NgbModal,private purchasingService :PurchasingService) {
		this.color = this.globalSearchService.getColor()
	}

	ngOnInit(): void {
		const token = this.globalSearchService.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
		if (token) {
			this.omsService.setupSocketConnection(token);
			this.omsService.subscribeToPuts((err, results) => {
				if(results.name != '') {
					if(this.hassubscription) {
						this.hassubscription.unsubscribe();
					}
					this.getData()
				}
			});
		}
		this.getData()
	}

	getData() {

		this.hassubscription = this.purchasingService.getPurchaseOrders().subscribe((results: any) => {
			//this.purchaseorders= new MatTableDataSource(results.pos);
			this.purchaseorders= results.pos;
			this.totals.open = results.pos.length;

			this.partials = results.pos.filter( i => {
				return parseFloat(i.ordered) > parseFloat(i.rec) && parseFloat(i.rec) > 0
			})

			this.invoice = results.grnready;

			this.invoice_issues = results.grnready.filter( i => {
				return parseFloat(i.quantityord) != parseFloat(i.qtyrecd) && parseFloat(i.qtyrecd) > 0
			})

			this.returns = results.pos.filter( i => {
				return parseFloat(i.ordered) < 0
			})

			this.inbound = results.inbound;
			this.statuses = results.statuses
			this.allpurchaseorders= results;

			this.purchasingService.getBackOrders('').subscribe(result => this.backorders = result)
		});


	}

	showData(dataset: any, app: string, title: string) {
		this.viewingdata = true;
		this.flip = 'active';
		this.viewapp = app;
		this.subtitle = title;
		switch(app) {
			case 'backorders':
				this.viewdata = dataset;
			break;
			case 'grns':
				this.viewdata = dataset;
			break;
			case 'inbound':
				this.viewdata = dataset;
			break;
			default:
				this.viewdata = dataset;
			break;
		}

	}
	back(): void {
		this.location.back()
	}

	toggleFlipTest(viewing: any) {

		this.viewing_data = viewing.data
		this.viewing_title = viewing.title
		//this.flip = (this.flip == 'active') ? 'inactive':'active';
	}

	toggleFlip() {
		this.flip = (this.flip == 'active') ? 'inactive':'active';
		if(this.flip =='inactive') {
			this.viewingdata = false;
		}
	}

}
