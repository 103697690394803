import { Component, SimpleChanges, OnChanges, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, EventEmitter, Pipe, PipeTransform } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Directive, HostListener } from "@angular/core";
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { CustomerService } from '../../../services/customer.service';
import { OrdersService } from '../../../services/orders.service';
import { InventoryService } from '../../../services/inventory.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { UsersService } from '../../../services/users.service';
import { OmsService } from '../../../services/oms.service';

@Component({
  selector: 'app-credit-review',
  templateUrl: './credit-review.component.html',
  styleUrls: ['./credit-review.component.scss'],
  animations: [
	  trigger('flipState', [
		  state('active', style({
			  transform: 'rotateY(-179deg)'
		  })),
		  state('inactive', style({
			  transform: 'rotateY(0)'
		  })),
		  transition('inactive => active', animate('250ms ease-in')),
		  transition('active => inactive', animate('250ms ease-out')),
	  ]),
	  trigger('openClose', [
		  state(':enter', style({ height: '*' })),
		  state(':leave', style({ height: '0px' })),
		  transition('false <=> true', animate(500))
	  ]),
	  trigger('filterAnimation', [
		  transition(':enter, * => 0, * => -1', []),
		  transition(':increment', [
			  query(':enter', [
				  style({ opacity: 0, width: 0 }),
				  stagger(50, [
					  animate('300ms ease-out', style({ opacity: 1, width: '*' })),
				  ]),
			  ], { optional: true })
		  ]),
		  transition(':decrement', [
			  query(':leave', [
				  stagger(50, [
					  animate('300ms ease-out', style({ opacity: 0, width: 0 })),
				  ]),
			  ])
		  ]),
	  ]),
	  trigger("grow", [ // Note the trigger name
		  transition(":enter", [
			  // :enter is alias to 'void => *'
			  style({ height: "0", width: "0" }),
			  animate(200, style({ height: "*", width: "*" })),
			  animate('200ms', style({ opacity: 1 })),
		  ]),
		  transition(":leave", [
			  // :leave is alias to '* => void'
			  animate(100, style({ height: 0, width: 0,})),
			  animate('100ms', style({ opacity: 0 }))
		  ])
	  ]),
  ]
})
export class CreditReviewComponent implements OnInit {
	@Input() customer: any = false;
	@Input() cart_items: any = false;
	@Input() cart_totals: any = false;
	@Input() original_order: any = false;

	@Output() updateCustomer = new EventEmitter <any> ();
	@Output() updateCart = new EventEmitter <any> ();
	@Output() removeItem = new EventEmitter <any> ();
	@Output() freight_charge = new EventEmitter <any> ();

	original_order_details: any = false;
	editbilling: boolean =false
	editshipping: boolean =false
	editsettings: boolean = false
	customerForm: UntypedFormGroup;

	customerEditAllowed = [
		'debtorno',
		'branchcode',
		'name',
		'address1',
		'address2',
		'address3',
		'address4',
		'address5',
		'address6',
		'contactname',
		'brname',
		'braddress1',
		'braddress2',
		'braddress3',
		'braddress4',
		'braddress5',
		'braddress6',
		'email',
		'salesman',
		'salestype',
		'defaultshipvia',
		'phoneno',
		'faxno',
		'specialinstructions',
		'defaultlocation',
		'paymentterms',
		'tax_ref',
		'taxgroupid',
		'area',
		'enteredby',
		'takenby',
	]

	addonfields: [
		//'datewanted',
		'enteredby',
		'takenby',
		'freightcost',
	];

	user: any = [];
	today = new Date();
	shipvia: any = false;
	taxgroups: any = [];
	salespeople: any = [];
	terms: any = [];
	zones: any = false;
	locations: any = [];
	holdreasons: any = [];
	salestypes: any = [];
	quote = [
		{label: 'Order', value: '0'},
		{label: 'Quote', value: '1'}
	]
	config: any =[];

	credit_types = [
		{label:'Return To Stock', value:'return'},
		{label:'Goods Written Off', value:'writeoff'},
		{label:'Reverse Overcharge', value:'reverse'},
	];

	glaccounts: any = false;
	allglaccounts: any = false;
	glaccount = new UntypedFormControl('');

	constructor(private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, public omsService: OmsService, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal, public usersService:UsersService) {

		this.globalSearchService.user.subscribe((results: any) => {
			if (results) {
				this.user = results.user;
			}
		});

		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		});

		this.globalsService.getGLAccounts().subscribe((results: any) => {
			this.glaccounts = results
			this.allglaccounts = results
		});
	}

	ngOnInit(): void {

		//globals
		this.globalSearchService.taxgroups.subscribe(async (results: any) => {
			this.taxgroups = results;
		});

		this.globalSearchService.salespeople.subscribe(async (results: any) => {
			this.salespeople = results;
		});

		this.globalSearchService.terms.subscribe(async (results: any) => {
			this.terms = results;
		});

		this.globalSearchService.holdreasons.subscribe(async (results: any) => {
			this.holdreasons = results;
		});

		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
		});
		this.globalSearchService.zones.subscribe(async (results: any) => {
			this.zones = results;
		});

		this.globalSearchService.salestypes.subscribe(async (results: any) => {
			this.salestypes = results;
		});

		this.customerService.getBranches(this.customer.debtorno).subscribe((results)=>{


			let shiptos = Object.entries(results);

			this.customer.branches = results;
			this.customer['shiptocount'] = shiptos.length;


		});

	}

	loadData() {


		var dnote = '';
		var inote = '';
		var ref = '';
		//note already set re apply it
		if(this.customerForm) {
			if(this.customerForm.get('comments').value != '') {
				dnote = this.customerForm.get('comments').value;
			} else {
				dnote = this.customer.dispatchnote;
			}
			if(this.customerForm.get('notes').value != '') {
				inote = this.customerForm.get('notes').value;
				//this.customerForm.get('notes').setValue(this.customer.dispatchnote);
			}

			if(this.customerForm.get('reference').value != '') {
				ref = this.customerForm.get('reference').value;
				//this.customerForm.get('notes').setValue(this.customer.dispatchnote);
			}
		}

		//reset form based on existing order details

		if(this.customer.editingorder) {
			dnote = this.customer.editingorder.notes;
			inote = this.customer.editingorder.comments;
			this.customer.braddress1 = this.customer.editingorder.deladd1
			this.customer.braddress2 = this.customer.editingorder.deladd2
			this.customer.braddress3 = this.customer.editingorder.deladd3
			this.customer.braddress4 = this.customer.editingorder.deladd4
			this.customer.braddress5 = this.customer.editingorder.deladd5
			this.customer.braddress6 = this.customer.editingorder.deladd6
		}


		//reset form based on existing order details
		if(this.customer.editingorder) {
			dnote = this.customer.editingorder.notes;
			inote = this.customer.editingorder.comments;
			ref = this.customer.editingorder.customerref;
		}

		var location = this.customer.defaultlocation;
		//var location = this.user.user.defaultlocation.loccode;
		if(this.user) {
			this.customer.defaultlocation = this.user.defaultlocation.loccode;
			location = this.user.defaultlocation.loccode;
		}

		this.customerForm  = this.fb.group({
			debtorno: [this.customer.debtorno, Validators.required],
			branchcode: [this.customer.branchcode, Validators.required],
			name: [this.customer.name, Validators.required],
			address1: [this.customer.address1, Validators.required],
			address2: [this.customer.address2],
			address3: [this.customer.address3, Validators.required],
			address4: [this.customer.address4, Validators.required],
			address5: [this.customer.address5, Validators.required],
			address6: [this.customer.address6],
			brname: [this.customer.brname, Validators.required],
			braddress1: [this.customer.braddress1, Validators.required],
			braddress2: [this.customer.braddress2],
			braddress3: [this.customer.braddress3, Validators.required],
			braddress4: [this.customer.braddress4, Validators.required],
			braddress5: [this.customer.braddress5, Validators.required],
			braddress6: [this.customer.braddress6],
			paymentterms: [this.customer.paymentterms, Validators.required],
			email: [this.customer.email],
			defaultlocation: [location, Validators.required],
			salesman: [this.customer.salesman, Validators.required],
			phoneno: [this.customer.phoneno],
			enteredby: [this.user.userid, Validators.required],
			takenby: [this.user.userid, Validators.required],
			defaultshipvia: [this.customer.defaultshipvia, Validators.required],
			salestype: [this.customer.salestype, Validators.required],
			freightcost: [0.00],
			comments: [dnote],
			notes: [inote],
			reference: [ref],
			credit_type: ['return',Validators.required],
			validated: [false],
			glaccount: [],
			print: [true]
			//quote: ['0', Validators.required]
		});



		if (this.customer.customerpoline === true || this.customer.customerpoline === '1' ) {
			//this.customerForm.get('reference').setValidators([Validators.required])
			//this.customerForm.get('reference').updateValueAndValidity();
		} else {

		}

		//this.customerForm.markAllAsTouched();
		this.globalSearchService.shipvia.subscribe((results: any) => {
			if(results !== null) {
				this.shipvia = results;
				let shipvia = results.filter(v => {
					v.shipper_id == this.customer.defaultshipvia
				})[0]
				if(shipvia) {
					this.customerForm.get('freightcost').setValue(shipvia.mincharge);
				}
			}
		});

		this.customerForm.get('name').disable();
		this.customerForm.get('address1').disable();
		this.customerForm.get('address2').disable();
		this.customerForm.get('address3').disable();
		this.customerForm.get('address4').disable();
		this.customerForm.get('address5').disable();
		this.customerForm.get('address6').disable();
		this.customerForm.updateValueAndValidity();

		this.customerForm.valueChanges.subscribe(selectedValue  => {
			 this.updateCustomer.emit(this.customerForm);
		})

		this.customerForm.get('freightcost').valueChanges.subscribe(newValue  => {
			 this.freight_charge.emit(newValue);
			 this.updateCustomer.emit(this.customerForm);
		})

		this.customerForm.get('glaccount').valueChanges.subscribe(newValue => {
			if(newValue != '') {
				this.glaccounts = this.globalSearchService.filterItem(this.allglaccounts, newValue, 'accountcode,accountname' )
			} else {
				this.glaccounts = this.allglaccounts;
			}
		});


	}

	repriceCart(event: any) {
		if(event.value != this.customer.salestype) {
			this.customer.salestype = event.value
			let data = {
				debtorno: this.customer.debtorno,
				branchcode: this.customer.branchcode,
				salestype: event.value,
			}
			this.orderService.repriceCart(data).subscribe((result: any) => {
				this.sendCartUpdate();
			});
		}
	}
	toggleEditShipping() {
		this.editshipping = (this.editshipping) ? false: true;
	}

	toggleEditBilling() {
		this.editbilling = (this.editbilling) ? false: true;
	}

	toggleEditSettings() {
		this.editsettings = (this.editsettings) ? false: true;
	}

	updatePrice(event: any, item: any) {
		if(event.target.value != '') {
			let index = this.cart_items.indexOf(item);
			this.cart_items[index].price = event.target.value;
			//this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
			this.updateCartItem(this.cart_items[index]);
		}
	}

	updateQuantity(event: any, item: any) {
		if(event.target.value != '') {
			let index = this.cart_items.indexOf(item);
			this.cart_items[index].quantity = event.target.value;
			//this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
			this.updateCartItem(this.cart_items[index]);
		}
	}

	getDiscounPrice(item: any) {
		var discountprice = this.financial((item.price * (item.discount / 100)));
		var total = this.financial(item.price - discountprice);
		if(total < 0) {
			total = 0;
		}
		return total;
	}

	financial(x) {
		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	getDiscountTotal(item: any) {
		var discounted = (item.price * (item.discount / 100)).toFixed(2);
		var discountprice = (item.price - parseFloat(discounted))
		var total = item.quantity *  discountprice;
		if(total < 0) {
			total = 0;
		}

		return total;
	}


	updateDiscount(event: any, item: any) {
		if(parseFloat(event.target.value) < 100) {

		} else {
			event.target.value = 100;
		}

		let index = this.cart_items.indexOf(item);
		this.cart_items[index].discount = event.target.value;
		this.updateCartItem(this.cart_items[index]);
	}

	updateNote(event: any, item: any) {
		let index = this.cart_items.indexOf(item);
		this.cart_items[index].notes = event.target.value;
		this.updateCartItem(this.cart_items[index]);
	}

	updateCartItem(item: any) {

		let index = this.cart_items.indexOf(item);

		let data = {
			customer: this.customer.debtorno,
			branch: this.customer.branchcode,
			cartid: item.cart_id,
			cart_id: item.cart_id,
			price: item.price,
			option: item.options,
			quantity: item.quantity,
			discount: item.discount,
			notes: item.notes,
			//editing_order: this.editing_order,
			//order_details: this.order_details,
		};

		this.updateCart.emit(data);
	}



	removeFromOrder(cart_id) {
		this.removeItem.emit(cart_id)
	}

	sendCartUpdate() {
		this.updateCart.emit(true);
	}

	ngOnChanges(changes: any) {

		if(changes.customer) {
			this.loadData();
		}

		if(this.customer.editingorder) {

			if(this.customer.editingorder.options) {
				this.customerForm.get('credit_type').setValue(this.customer.editingorder.options.credit_type);
				this.customerForm.get('glaccount').setValue(this.customer.editingorder.options.glaccount);
			}

			if(this.customer.editingorder.customerref) {
				this.customerForm.get('reference').setValue(this.customer.editingorder.customerref);
			}
			if(this.customer.editingorder.notes) {
				this.customerForm.get('notes').setValue(this.customer.editingorder.notes);
			}

			this.customerForm.get('branchcode').setValue(this.customer.editingorder.branchcode)
			this.customerForm.get('comments').setValue(this.customer.editingorder.notes);
			this.customerForm.get('notes').setValue(this.customer.editingorder.comments);

			this.customer.branchcode = this.customer.editingorder.branchcode;
			this.customer.brname = this.customer.editingorder.deliverto;
			this.customer.braddress1 = this.customer.editingorder.deladd1;
			this.customer.braddress2 = this.customer.editingorder.deladd2;
			this.customer.braddress3 = this.customer.editingorder.deladd3;
			this.customer.braddress4 = this.customer.editingorder.deladd4;
			this.customer.braddress5 = this.customer.editingorder.deladd5;
			this.customer.braddress6 = this.customer.editingorder.deladd6;
			this.customer.contactname = this.customer.editingorder.deliverto;
			this.customer.shipto = this.customer.editingorder.shipto;

			this.customerForm.get('brname').setValue(this.customer.editingorder.deliverto)
			this.customerForm.get('braddress1').setValue(this.customer.editingorder.deladd1)
			this.customerForm.get('braddress2').setValue(this.customer.editingorder.deladd2)
			this.customerForm.get('braddress3').setValue(this.customer.editingorder.deladd3)
			this.customerForm.get('braddress4').setValue(this.customer.editingorder.deladd4)
			this.customerForm.get('braddress5').setValue(this.customer.editingorder.deladd5)
			this.customerForm.get('braddress6').setValue(this.customer.editingorder.deladd5);

			this.customerForm.get('email').setValue(this.customer.editingorder.contactemail);
			this.customerForm.get('phoneno').setValue(this.customer.editingorder.contactphone);


			if(this.customer.preselected) {
				this.customerForm.get('payment_method').setValue(this.customer.preselected);
			}

			if (this.user) {
				if(this.customer.editingorder.fromstkloc != this.user.defaultlocation.loccode && this.customer.editingorder.fromstkloc ) {
					this.customerForm.get('defaultlocation').setValue(this.customer.editingorder.fromstkloc );
				}
			}

		}



		if(changes.original_order) {
			this.original_order_details = changes.original_order.currentValue;
			let shortcut = (changes.original_order.currentValue.header)



			if(shortcut) {

				this.customerForm.get('reference').setValue(shortcut.customerref);
				this.customerForm.get('notes').setValue(shortcut.orderno);
				if(this.customer) {
					this.customer.branchcode = shortcut.branchcode;
					this.customer.brname = shortcut.deliverto;
					this.customer.braddress1 = shortcut.deladd1;
					this.customer.braddress2 = shortcut.deladd2;
					this.customer.braddress3 = shortcut.deladd3;
					this.customer.braddress4 = shortcut.deladd4;
					this.customer.braddress5 = shortcut.deladd5;
					this.customer.braddress6 = shortcut.deladd6;
				}

				this.customerForm.get('branchcode').setValue(shortcut.branchcode)
				this.customerForm.get('comments').setValue(shortcut.notes);
				this.customerForm.get('notes').setValue(shortcut.comments);
				this.customerForm.get('brname').setValue(shortcut.deliverto)
				this.customerForm.get('braddress1').setValue(shortcut.deladd1)
				this.customerForm.get('braddress2').setValue(shortcut.deladd2)
				this.customerForm.get('braddress3').setValue(shortcut.deladd3)
				this.customerForm.get('braddress4').setValue(shortcut.deladd4)
				this.customerForm.get('braddress5').setValue(shortcut.deladd5)
				this.customerForm.get('braddress6').setValue(shortcut.deladd5);
			}
		}

		var shipto = this.customer.brname + '</br>';
			shipto +=this.customer.braddress1+ '</br>';;
			if(this.customer.braddress2 != '') {
				shipto +=this.customer.braddress2 + '</br>'
			}

			shipto +=this.customer.braddress3 + ', '+this.customer.braddress4 + ' ' + this.customer.braddress5+ ' ' + this.customer.braddress6

		this.customer.shipto = shipto
		this.updateCustomer.emit(this.customerForm);
	}

	changeBranch(branchcode: any) {
		let originalBranch = this.customer.branchcode;

		this.customerService.getDebtorWithBranch(this.customer.debtorno, branchcode).subscribe(async (results: any) => {
			let branch = results.branches.filter( (br) => br.branchcode == branchcode)[0];

			this.customerForm.get('branchcode').setValue(branch.branchcode)
			this.customerForm.get('brname').setValue(branch.brname);
			this.customerForm.get('braddress1').setValue(branch.braddress1)
			this.customerForm.get('braddress2').setValue(branch.braddress2)
			this.customerForm.get('braddress3').setValue(branch.braddress3)
			this.customerForm.get('braddress4').setValue(branch.braddress4)
			this.customerForm.get('braddress5').setValue(branch.braddress5)
			this.customerForm.get('braddress6').setValue(branch.braddress5)

			var shipto = branch.brname + '</br>';
			shipto += branch.braddress1 + '</br>';;
			if(branch.braddress2 != '') {
				shipto += branch.braddress2 + '</br>'
			}

			shipto +=branch.braddress3 + ', '+branch.braddress4 + ' ' + branch.braddress5+ ' ' + branch.braddress6
			this.customer.shipto = shipto;

			this.customer.branchcode = branch.branchcode;



			this.orderService.updateCreditCartShipto(this.customer.debtorno, originalBranch, this.customer.branchcode).subscribe(async (branchrresults: any) => {
				this.updateCustomer.emit(this.customerForm);
			});


		});

	}

	displayFn(account: any): string {
		if(!account) {
			return '';
		}
		return account.accountname
	}

	ngAfterViewInit() {

	}

}
