import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Location } from '@angular/common'
import { InventoryService } from '../../services/inventory.service';
import { QantelService } from '../../services/qantel.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { BarcodeScannerLivestreamComponent } from "ngx-barcode-scanner";
import { OrdersService } from '../../services/orders.service';
import { VendorService } from '../../services/vendor.service';
import { PurchasingService } from '../../services/purchasing.service';
import { reduce } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { E } from '@angular/cdk/keycodes';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
	selector: 'app-warehouse-item-scan-two',
	templateUrl: './warehouse-item-scan-two.component.html',
	styleUrls: ['./warehouse-item-scan-two.component.scss'],
	animations: [
		trigger("grow", [ // Note the trigger name
			transition(":enter", [
				// :enter is alias to 'void => *'
				style({ maxheight: "0", maxwidth: "0", visiblility: "visible" }),
				animate(200, style({ maxheight: "*", maxwidth: "*" })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(":leave", [
				// :leave is alias to '* => void'
				animate(100, style({ maxheight: 0, maxwidth: 0, })),
				animate('100ms', style({ opacity: 0, visibility: 'collapse' }))
			])
		]),
	]

})
export class WarehouseItemScanTwoComponent implements OnInit {
	[x: string]: any;

	@ViewChild("stockid") stockidField: ElementRef;
	@ViewChild("itemrec") itemrecField: ElementRef;
	// @ViewChild('stepper') private stepperRef: MatStepper;
	@ViewChild("detailsModal") detailsModalEle: ElementRef;
	// @Output() selectedIndexChange: EventEmitter<number>

	itemForm: UntypedFormGroup;
	itemlist: any = false;
	mstlist: any = false;
	recitem = new UntypedFormControl('');
	vendor: UntypedFormControl = new UntypedFormControl('');
	item: any = false;
	ordertitle: string = '';
	orderdetails: any = [];
	searching: boolean;
	showlist: boolean;
	historyloading: boolean;
	showorder: boolean;
	starting: boolean;
	lastsearch: string = '';
	inbound: number = 0;
	noresults: boolean = false;
	addingbarcode: boolean = false;
	searchsub: any = false;
	barcodeScanner: BarcodeScannerLivestreamComponent;
	barcodeValue;
	color: any = '';
	viewingitem: any = false;
	itemio: any = false;
	vendors: any = [];
	vendor_openpos: any = false;
	vendor_openrgas: any = false;
	scanorrec: string = 'rec';
	selectedIndex: any = 0;
	firstFormGroup: FormGroup = null;
	secondFormGroup: FormGroup = null;
	stepper: MatStepper;
	last_item: any = false;
	selected_vendor: any = false;
	active_select: any = 0;
	show_confirm: any = false;
	toggle: FormControl = new FormControl(false, []);
	mstdetails: any = [];

	constructor(private purchasingService: PurchasingService, private globalSearchService: GlobalSearchService, private _formBuilder: FormBuilder, private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, public router: Router, private location: Location, public inventoryService: InventoryService, public orderService: OrdersService, private qantelService: QantelService, private vendorService: VendorService, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
		this.firstFormGroup = this._formBuilder.group({ firstCtrl: [''] });
		this.secondFormGroup = this._formBuilder.group({ secondCtrl: [''] });
	}

	updateVendor(event: any) {
		this.selected_vendor = event.value;
	}

	ngOnInit(): void {

		this.vendorService.getVendors().subscribe((result: any) => {

			// let vendors = result.filter((r: any) => {
			// 	return r.bankref != '' && r.bankref != 'DWI';
			// })

			// let sortedVendors = vendors.sort((n1, n2) => {
			// 	return this.naturalCompare(n1.bankref, n2.bankref)
			// })

			// this.vendors = sortedVendors;
			// let defaultValue = sortedVendors.filter(r => {
			// 	return r.bankref == 'DWI-11';
			// })
			// this.selected_vendor = defaultValue[0]
			// this.stepperRef.next()



			let vendors = result.filter((r: any) => {
				return r.bankref != '' && r.bankref != 'DWI';
			})

			let sortedVendors = vendors.sort((n1, n2) => {
				return this.naturalCompare(n1.bankref, n2.bankref)
			})

			this.vendors = sortedVendors;
			let defaultValue = sortedVendors.filter(r => {
				return r.bankref == 'DWI-11';
			})
			this.selected_vendor = defaultValue[0]
			// this.stepperRef.next()


		})

		this.itemForm = this.formBuilder.group({
			stockid: ['', Validators.required],
		});

	}

	focusSearch(event: any){
		if(event.searching){
			this.itemlist = false;
			this.itemrecField.nativeElement.focus();
		}
	}

	toggleVendors(selection: boolean) {
		this.active_select == 0 ? this.active_select = 1 : this.active_select = 0;
		if (selection) {
			this.active_select = 0;
		}
	}

	showConfirm(event: any) {
		// alert(JSON.stringify(event.viewing));

		// if(!event.viewing){
		// 	this.show_confirm = false;
		// }else{
		// 	this.show_confirm = true;
		// }

	}

	naturalCompare(a, b) {
		var ax = [],
			bx = [];

		a.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
		b.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });

		while (ax.length && bx.length) {
			var an = ax.shift();
			var bn = bx.shift();
			var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
			if (nn) return nn;
		}

		return ax.length - bx.length;
	}


	// last_success(event: any) {

	// 	this.last_item = {
	// 		item: event.item,
	// 		qtyRec: event.qtyRec,
	// 	}
	// }

	preloadItem(item: any) {
		let stockidsearch = item.stockid != undefined ? item.stockid : item.stkcode != undefined ? item.stkcode: item.itemcode;

		this.itemForm.markAllAsTouched();
		this.inventoryService.loadItem(stockidsearch).subscribe((result: any) => {

			this.viewingitem = true;
			this.item = result
			this.itemlist = false;
			this.itemio = result;




			let search = {
				stockid: item.stockid != undefined ? item.stockid : item.itemcode ,
				vendor: this.selected_vendor.supplierid
			}

			if(search.stockid === 'MST'){
				let data = {
					orderno: item.orderno
				}
				this.purchasingService.getMstPo(data).subscribe(results => {
					this.vendor_openpos = results;

				})
			} else{
				this.purchasingService.getPurchasesForItemByVendor(search).subscribe(results => {
					this.vendor_openpos = results;

				})
			}




			this.purchasingService.getRgasForItemByVendor(search).subscribe(results => {
				this.vendor_openrgas = results;
			})

			if (this.scanorrec == 'rec') {
				if (result.reserved_orders.length) {
					this.itemForm.get('stockid').setValue('')
					this.audioPlay();
				} else {
					if (result.openpos) {
						this.itemForm.get('stockid').setValue('')
						this.audioPlayBad();
						this.vibrate()
					} else {
						this.itemForm.get('stockid').setValue('')
						this.vibrateLong();
						this.audioPlayFree();
					}
				}
			}
			// this.detailsModalEle.close();
			this.showConfirm({ viewing: true });
			this.focusInput();
		});
	}

	//dep'd?
	// onNext(stepper: MatStepper) {
	// 	stepper.next();
	// }

	// tabChange(number: any) {

	// }

	searchOrReceiveToggle(event: any) {
		switch (event) {
			case 1:
				this.scanorrec = 'scan';
				break;
			default:
				this.scanorrec = 'rec';
				break;
		}
		// alert(this.scanorrec);
	}

	updatePos(event: any) {
		//this.viewingitem = false;
		let search = {
			stockid: (this.item) ? this.item.item.stockid : '',
			vendor: this.selected_vendor.supplierid
		}
		//GSA-407562374
		this.purchasingService.getPurchasesForItemByVendor(search).subscribe((results: any) => {
			this.vendor_openpos = results;
		})
	}

	focusInput() {
		//this.stockidField.nativeElement.focus();
	}

	ngAfterViewInit() {

	}


	colorSwap(event: any) {
		this.selectedIndex = event;
	}



	back(): void {
		this.location.back()
	}


	lookupVersionEight() {
		this.searching = true;
		this.lastsearch = this.recitem.value;

		if (this.searchsub) {
			this.searchsub.unsubscribe();
		}

		if (this.recitem.value != '') {
			let data = { stockid: this.recitem.value, vendor: this.vendor.value.supplierid }
			this.searchsub = this.inventoryService.itemSearch(data).subscribe((results: any) => {
				this.itemlist = results
				if (results.length == 1) {
					this.itemio = results;

					this.itemForm.get('stockid').setValue('')
					this.loadErpItem(results[0]);
					this.viewingitem = true;
					this.itemlist = false;
				} else {
					this.viewingitem = false;
				}

				this.searching = false;
				this.recitem.setValue('');
				this.focusInput();
			});
		} else {
			this.lastsearch = '';
			this.searching = false;
			this.showlist = false;
			this.noresults = false;
			this.itemlist = false;
			this.focusInput();
		}
	}

	lookup() {


		this.searching = true;
		this.lastsearch = this.itemForm.value.stockid;

		if (this.searchsub) {
			this.searchsub.unsubscribe();
		}

		if (this.itemForm.value.stockid != '') {

			this.searchsub = this.qantelService.itemSearch(this.itemForm.value).subscribe((results: any) => {
				this.itemlist = results
				if (results.length == 1) {
					this.loadItem(results[0]);
				} else {
					this.viewingitem = false;
				}

				this.searching = false;
			});
		} else {
			this.focusInput();
			this.lastsearch = '';
			this.searching = false;
			this.showlist = false;
			this.noresults = false;
		}
	}

	loadErpItem(item) {
		this.showConfirm({ viewing: true })
		this.itemio = item;
		this.preloadItem(item);
		// this.router.navigate(['/warehouse/item-scan/' + item.stockid]);
	}

	loadItemByStockID(item) {
		this.router.navigate(['/warehouse/item-scan/' + item]);
		this.focusInput();
	}

	loadItem(item) {

		if (this.item) {
			if (this.item.item.stockid != item.stockid) {
				this.recitem.setValue('');
				this.showConfirm({ viewing: true })
				// this.router.navigate(['/warehouse/item-scan/' + item.stockid]);
				this.itemio = item;

				this.focusInput();
			} else {
				this.viewingitem = true;
				this.searching = false;
				this.showlist = false;
				this.noresults = false;
				this.itemlist = false;
				this.recitem.setValue('');
				this.focusInput();
			}
		} else {

			this.recitem.setValue('');

			this.itemio = item;
			// this.router.navigate(['/warehouse/item-scan/' + item.stockid]);
			this.focusInput();
		}
	}

	dspBarcodeAdd(event: any) {
		event.preventDefault();
		this.addingbarcode = true;
	}

	addedBarcode(event: any) {
		this.addingbarcode = false;
		this.itemForm.setValue({ stockid: event.stockid });
		// this.lookup();
		this.lookupVersionEight();
		this.itemrecField.nativeElement.focus();
	}

	getHistory(item) {
		this.inventoryService.getHistory(item).subscribe((history: any[]) => {
			this.item.history = history;
			this.historyloading = false;
		});
	}

	loadOrder(order) {
		this.starting = false;
		this.noresults = false;
		this.orderService.getOrder(order).subscribe((results: any) => {
			this.showorder = true;
			this.orderdetails = results;
			this.ordertitle = 'Order: ' + order + '';
		});

	}

	billingAddressFormatted(obj: any) {

		var address = obj.CUST_NAME + " (" + obj.CUST_NUMBER + ")<br>" + obj.ADDRESS_1 + " " + obj.ADDRESS_2 + "<br>" + obj.CITY + ", " + obj.STATE + ' ' + obj.ZIP_CODE;

		return address;
	}

	shippingAddressFormatted(obj: any) {
		var address = obj.SHIP_NAME + "<br>" + obj.SHIP_ADDR_1 + " " + obj.SHIP_ADDR_2 + "<br>" + obj.SHIP_CITY + ", " + obj.SHIP_STATE + ' ' + obj.SHIP_ZIP;
		return address;
	}

	onValueChanges(result) {
		this.barcodeValue = result.codeResult.code;
	}

	startCamera() {
		this.barcodeScanner.start();
	}

	onStarted(started) {

	}

	audioPlay() {
		var audio = new Audio("/assets/pristine-609.mp3");
		let play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	audioPlayBad() {
		var audio = new Audio("/assets/glitch.mp3");
		let play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	audioPlayFree() {
		var audio = new Audio("/assets/money.wav");
		let play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	vibrate() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(2000);
		} else {
			// Not supported
		}
	}

	vibrateLong() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(5000);
		} else {
			// Not supported
		}
	}


	openMSTmodal(){
		if(this.toggle.value){
			this.mstlist = '';
			this.orderService.getMstOnOpenSalesOrders().subscribe((results: any) => {
				this.mstlist = results;
			});
		} else{
			let data = {vendor: this.selected_vendor.supplierid};
			this.mstlist = '';

			this.orderService.getMstOnOpenPurchOrders(data).subscribe((results: any) => {
				this.mstlist = results;
			});
		}


		this.modalService.open(this.detailsModalEle, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
		}, (reason) => {
			//modal close callback

		});
	}

	setSelectedMst(mst: any = ''){
		// alert(JSON.stringify(mst))
		this.mstdetails = mst;
		// this.itemio['mstdetails'] = mst;
		// let temp = this.itemio;
		// this.itemio = '';
		// this.itemio = temp;
		// alert(JSON.stringify(mst))
		// alert(this.mstdetails);
	}

}
