<div class="main-content">
	<div class="container-fluid">
		<div class="row tp-wrapper m-0 p-0">
			<div class="col-md-12 ">
				<div class="card ">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title" (click)="back()">
							<i class="material-icons">arrow_left</i>
							{{ title }}
						</h4>
					</div>
					<div class="card-body">

						<div class="row">
							<div class="col-4">
								<mat-form-field appearance="standard">
									<mat-label>DRI Date</mat-label>
									<input matInput (dateChange)="updateDayPayMents($event.value)" [formControl]="datefrom" [matDatepicker]="payfrom">
									<mat-datepicker-toggle matSuffix [for]="payfrom"></mat-datepicker-toggle>
									<mat-datepicker #payfrom></mat-datepicker>
								</mat-form-field>
							</div>
							<div class="col-md-4">
							<mat-form-field appearance="fill">
							  <mat-label>Location</mat-label>
							  <mat-select [formControl]="branch">
											<mat-option *ngFor="let v of branches" [value]="v.loccode">
												{{v.locationname}}
											</mat-option>
										</mat-select>
							</mat-form-field>
						</div>
							<div class="col-md-4">

								<button class="mt-3"
								 mat-raised-button (click)="loadData()">Run</button>

								&nbsp;&nbsp; <button mat-raised-button (click)="$event.preventDefault();exportXls()" *ngIf="payments.summary">
									 <i class="fa fa-file-excel-o"></i> XLS
								 </button>
								 &nbsp;&nbsp;
								 <button mat-raised-button (click)="$event.preventDefault();exportPdf()" *ngIf="payments.summary">
									 <i class="fa fa-file-pdf-o"></i> PDF
								 </button>
							</div>
						</div>
						<!--left-->
						<div class="row">

							<span *ngIf="running"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</span>

							<div class="col-md-12" id="payrep" *ngIf="!running" >
								<ng-container *ngIf="payments.transactions">
									<div  class="table" #print_table >
									<h3 class="mt-2 mb-4">{{ title }}</h3>
									<h4 class="mt-2 mb-4">{{ title }} Summary</h4>
									<div class="bg-white mt-2 rounded">
										<div id="receipts">
											<div class="row">
											<div class="col-md-6">
										    <table class="table table-fixed compact table-sm w-50 collapsed" >
												<tbody>
												<tr>
													<td> Gross Amt: </td>
													<td> {{ totals.gross | currency }} </td>
												</tr>
												<tr>
													<td> + Tax: </td>
													<td> {{ totals.taxtotal | currency }} </td>
												</tr>
												<tr>
													<td> - Disc: </td>
													<td> {{ totals.disctotal | currency }} </td>
												</tr>
												<tr>
													<td> + Misc: </td>
													<td> {{ totals.misctotal | currency }} </td>
												</tr>
												<tr>
													<th> Net: </th>
													<td> {{ totals.net | currency }} </td>
												</tr>
												
												<tr>
													<th>Total</th>
													<th class="text-right"> {{ totals.totalsales | currency }}</th>
												</tr>
												<tr>
													<th> Total Invoices: </th>
													<td> {{ totals.invcount }} </td>
												</tr>
												<tr>
													<th> Total Credits: </th>
													<td> {{ totals.credcount }} </td>
												</tr>
												<tr>
													<th> Customers: </th>
													<td> {{ totals.custcount }} </td>
												</tr>
												</tbody>
											</table>
											</div>
											<div class="col-md-6">
											<table class="table table-fixed compact table-sm w-50 collapsed" >
												<thead>
												<tr>
													<th>Type</th>
													<th class="text-right">Amount</th>
												</tr>
												</thead>
												<tbody>
												<tr *ngFor="let p of payments.summary">
													<td>{{ p.type }}</td>
													<td class="text-right"> {{ p.amount | currency }}</td>
												</tr>
												<tr>
													<th>Payments:</th>
													<th class="text-right"> {{ total_pay | currency }}</th>
												</tr>
												<tr>
													<th>Taxable Sales</th>
													<th class="text-right"> {{ totals.taxablesales | currency }}</th>
												</tr>
												</tbody>
											</table>
											</div>
											</div>

											<h4>Total Trans {{ total_trans | currency }}</h4>

											<h4 class="mt-2 mb-4">{{ title }}</h4>
											<table id="rcptTable" name="rcptTable" class="table compact table-sm table-fixed collapsed">
												<thead>
													<tr>
														<th class="text-center">Date</th>
														<th class="text-center">Order#</th>
														<th class="text-center">Document#</th>
														<th class="text-center">Type#</th>
														<th class="text-center">Terms#</th>
														<th class="text-right">Customer#</th>
														<th class="text-left">Name</th>
														<th class="text-right">Amount</th>
														<th class="text-right">Freight</th>
														<th class="text-right">Discount</th>
														<th class="text-right">Tax</th>
														<th class="text-right"><b>Total</b></th>
														<th class="text-right"><b>Payment</b></th>
														<th class="text-center">Settled</th>
														<th class="text-center">Reference</th>
														<th class="text-center">Payments</th>
													</tr>
												</thead>
												<tbody>
													<ng-container *ngFor="let pay of payments.transactions">
													<tr>
														<td class="text-center"> {{ pay.trandate }}</td>
														<td class="text-center"> {{ pay.order_ }} </td>
														<td class="text-center"> {{ pay.transno }} </td>
														<td class="text-center"> {{ pay.typename }} </td>
														<td class="text-center"> {{ pay.payterms }} </td>

														<td class="text-right text-nowrap">
															<b>{{ pay.debtorno }}.{{ pay.branchcode }}</b>
														</td>
														<td class="text-left text-nowrap">{{ pay.name }}</td>

														<td class="text-right"> {{ pay.ovamount | currency }}</td>
														<td class="text-right"> {{ pay.ovfreight | currency }}</td>
														<td class="text-right"> {{ pay.ovdiscount | currency }}</td>
														<td class="text-right"> {{ pay.ovgst | currency }}</td>
														<td class="text-right"><b> {{ pay.total | currency }}</b></td>
														<td class="text-right" [ngClass]="{'text-success
														': pay.settled == 'Yes', 'text-danger' : pay.settled =='No'}"><b>{{ pay.alloc | currency }}</b></td>

														<td class="text-center"> {{ pay.settled }}</td>
														<td class="text-center"> {{ pay.reference}}</td>
														<td class="text-center"> {{ pay.paytext}}</td>


													</tr>
													</ng-container>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								</ng-container>
								<ng-container *ngIf="!payments.transactions">
									<h4>No Transactions Found</h4>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
