import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit} from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { interval, Subscription} from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router} from '@angular/router';

import { CustomerService } from '../../../services/customer.service';
import { GlobalsService } from '../../../services/globals.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { Customer } from '../../../classes/customer';
import { Location } from '@angular/common'

@Component({
	selector: 'app-customer-notes',
	templateUrl: './customer-notes.component.html',
	styleUrls: ['./customer-notes.component.scss'],
	animations: [
		trigger("grow", [ // Note the trigger name
			transition(":enter", [
				// :enter is alias to 'void => *'
				style({ height: "0",width: "0" }),
				animate(200, style({ height: "*" , width: "*"})),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(":leave", [
				// :leave is alias to '* => void'
				animate(300, style({ height: 0, width: 0  })),
				animate('300ms', style({ opacity: 0 }))
			])
			]),
	]
})
export class CustomerNotesComponent implements OnInit {
	@Input() customer;
	@Output() editing = new EventEmitter<boolean>();
	@Output() newcustomerdata = new EventEmitter<boolean>();
	user: any = []
	noteFrom: UntypedFormGroup;
	notes: any = []
	newnote_id: number = 0;
	default_type ='ORDER';
	notetypes = ['ORDER','AR','INTERNAL','DISPATCH'];
	removals: any = [];
	editingnote: any = false;
	constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder,private customerService: CustomerService, public router: Router, private location: Location, private globalsService: GlobalsService, public globalSearchService: GlobalSearchService) {

	}

	addNote() {
		this.editingnote = true;
		this.newnote_id = this.newnote_id - 1;
		let newNote ={
			debtorno: this.customer.debtorno,
			noteid: this.newnote_id,
			note: '',
			note_code: this.notetypes[0],
		}

		this.customer.notes.push(newNote)
	}

	updateNote(event:any, note: any) {
		let index = this.customer.notes.indexOf(note);
		this.customer.notes[index].note = event.target.value;
	}

	removeNote(id: any) {
		this.removals.push(id)
		this.customer.notes = this.customer.notes.filter( (note) => {
			return note.noteid != id;
		});
	}

	onSubmit() {
		var isok = true;
		this.customer.notes.forEach( note => {
			if(note.note == '') {
				isok = false;
			}
		})
		if(isok) {
			this.customerService.updateDebtorNotes({ notes: this.customer.notes, removals: this.removals}).subscribe( (results) => {
				this.customer.notes = results.notes;
				this.newcustomerdata.emit(this.customer);
				this.editing.emit(false);
			})
		} else {
			alert('Note is empty')
		}
	}

	setForm() {

		this.newnote_id = this.newnote_id - 1;

		this.noteFrom = this.fb.group({
			debtorno: [this.customer.debtorno, Validators.required],
			noteid: [this.newnote_id, Validators.required],
			note: ['', Validators.required],
			note_code: [this.notetypes[0], Validators.required],
		});
	}

	back(): void {
		this.editing.emit(false);
	}

	updateTopic(event:any, note:any) {
		note.note_code =event.value
	}

	ngOnInit(): void {

		this.notes = this.customer.notes;
		if(!this.notes) {
			this.customer.notes = [];
			this.addNote();
		}
	}
	ngAfterViewInit() {
	}
}
