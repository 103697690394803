import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective } from '@angular/forms';

import { FormGroup, FormControl,ValidationErrors } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { VendorService } from '../../services/vendor.service';
import { OrdersService } from '../../services/orders.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';



@Component({
	selector: 'app-vendor-create',
	templateUrl: './vendor-create.component.html',
	styleUrls: ['./vendor-create.component.scss']
})
export class VendorCreateComponent implements OnInit {

	adressType: string = '';
	@ViewChild('addresstext') addresstext: any;
	autocompleteInput: string;

	vendorid: any = '';
	vendorselected: boolean = false;
	vd: any = [];
	term: any = '';

	taxgroup: any = '';
	taxes: any = [];
	currencies: any = [];
	bankaccounts: any = [];
	types: any = [];
	zones: any = [];
	vendorForm: any = false;
	//todo move this to config
	defaults = {
		country: 'United States',
		region: 'NY',
		holdreason: '1',
		currency: 'USD',
		terms: 'N30',
		salestype: 'A1',
		customertype: '1',
		discount: 0,
		credit: 1000,
		email_invoice: false,
		requirepo: false,
		email_statement: false,
		interest: 3,
		warehouse: '01',
		shipvia: '1',
		area: '01',
		territory: '01',
		salesman: '01',
		disabled: false,
		tirefee: true,
		taxauth: '119',
		branchcode: '00',
		bank: '1010',
		remit: '1',
		suppliertype: '2',
	};

	color: string = '';
	address: any = '';

	constructor(private route: ActivatedRoute, private vendorService: VendorService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder, public globalsService: GlobalsService) {

		this.color = this.globalSearchService.getColor();

	}

	paymentterms: any = [];

	ngOnInit(): void {

		this.vendorForm = this.fb.group({
			supplierid: ['', [Validators.required]],
			suppname: ['', [Validators.required]],
			email: ['', []],
			address1: ['', [Validators.required]],
			address2: ['', []],
			address3: ['', [Validators.required]],
			address4: [this.defaults.region, [Validators.required]],
			address5: ['', [Validators.required]],
			address6: [this.defaults.country, [Validators.required]],
			supptype: [this.defaults.suppliertype, [Validators.required]],
			currcode: [this.defaults.currency, [Validators.required]],
			//suppliersince: ['', [Validators.required]],
			paymentterms: [this.defaults.terms, [Validators.required]],
			bankact: [this.defaults.bank, [Validators.required]],
			remittance: [this.defaults.remit, [Validators.required]],
			taxgroupid: [this.defaults.taxauth, [Validators.required]],
			factorcompanyid: [''],
			telephone: ['', [Validators.required]],
			url: ['']
		});
	}

	updateVendor() {
		if (this.vendorForm.valid) {
			this.vendorService.saveVendor(this.vendorForm.value).subscribe(details => {
				this.router.navigate(['vendors/' + this.vendorForm.controls.supplierid.value]);
			});
		} else {
			this.getFormValidationErrors();
		}
	}

	back(): void {
		this.location.back()
	}

	ngAfterViewInit() {
		this.globalsService.getTypes().subscribe(async details => {
			this.types = details;
		});

		this.globalsService.getBankAccounts().subscribe(async details => {
			this.bankaccounts = details;
		});

		this.globalsService.getTerms().subscribe(async details => {
			this.paymentterms = details;
		});

		this.globalsService.getTaxes().subscribe(async details => {
			this.taxes = details;
		});

		this.globalsService.getCurrencies().subscribe(async details => {
			this.currencies = details;
		});

		this.globalsService.getDefaultZones().subscribe(async details => {
			this.zones = details;
		});

	}

	getAddress(place: object) {

		this.address = place['formatted_address'];
		let componenets = place['address_components'];
		let name = place['name'];
		let phone = place['formatted_phone_number'];
		let website= place['website'];

		let address1 = this.parseComponentShortName(componenets, ['street_number','route']);
		let address2 = this.parseComponentShortName(componenets, ['subpremise']);
		let address3 = this.parseComponentShortName(componenets, ['locality']);
		let address4 = this.parseComponentShortName(componenets, ['administrative_area_level_1']);
		let address5 = this.parseComponentShortName(componenets, ['postal_code']);
		let address6 = this.parseComponentLongName(componenets, ['country']);

		this.vendorForm.get('address1').setValue(address1)
		this.vendorForm.get('address2').setValue(address2)
		this.vendorForm.get('address3').setValue(address3)
		this.vendorForm.get('address4').setValue(address4)
		this.vendorForm.get('address5').setValue(address5)
		this.vendorForm.get('address6').setValue(address6)

		if(name) {
			this.vendorForm.get('suppname').setValue(name)
		}

		if(phone) {
			this.vendorForm.get('telephone').setValue(phone)
		}

		if(website) {
			this.vendorForm.get('url').setValue(website)
		}

	}

	private parseComponentShortName(componenets: any , types: any) {

		let result= [];
		componenets.forEach( (comp: any) => {
			let compa = comp.types.map((local: any) => {
				if(types.includes(local)) {
					let adda = (comp.short_name) ? comp.short_name : '';
					result.push(adda);
				}
			});
		})
		return result.join(' ');
	}

	private parseComponentLongName(componenets: any , types: any) {
		let result= [];
		componenets.forEach( (comp: any) => {
			let compa = comp.types.map((local: any) => {
				if(types.includes(local)) {
					let adda = (comp.long_name) ? comp.long_name : '';
					result.push(adda);
				}
			});
		})
		return result.join(' ');
	}

	getFormValidationErrors() {
		Object.keys(this.vendorForm.controls).forEach((key, index) => {
			const controlErrors: ValidationErrors = this.vendorForm.get(key).errors;
			if (controlErrors != null) {
				Object.keys(controlErrors).forEach(keyError => {
					//this.customerForm.controls[index].markAsTouched()
					this.vendorForm.markAllAsTouched();
					this.globalSearchService.showNotification(key + ' ' + keyError + ' ' + controlErrors[keyError], 'danger', 'bottom', 'right')

				});
			}
		});
	}

}
