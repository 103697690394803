import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { OmsService } from '../../services/oms.service';
import { DispatchService } from '../../services/dispatch.service';
import { MatAccordion } from '@angular/material/expansion';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

declare const google: any;

@Component({
	selector: 'app-dispatches-active-dispatch',
	templateUrl: './dispatches-active-dispatch.component.html',
	styleUrls: ['./dispatches-active-dispatch.component.scss']
})

export class DispatchesActiveDispatchComponent implements OnInit {
	@ViewChild(MatAccordion) accordion: MatAccordion;

	@ViewChild("orderdetails") orderdetails: ElementRef;
	@ViewChild("runsheet") runsheet: ElementRef;

	CHAT_ROOM = "OrderBoard";
	user: any = [];
	color: string = 'blue';

	config: any = [];
	token: string = '';
	dispatches: any = [];
	selectedorder: any = false;
	trucks: any = [];
	allpoints: any = [];
	map: any = false;
	zoomed: boolean = false;
	zoom: number = 11;
	pathlines: any = [];
	markers: any = false;
	zoomed_details_truck: any = false;
	seleccted_alt: any = false;
	transfertruck =  new UntypedFormControl('')
	viewing_dispatches: any = []
	delsheetlink: string = '';
	droppedlist: any = false;
	//'transfertruck'
	map_animation: any = google.maps.Animation.DROP;
	constructor(private modalService: NgbModal, private dispatchService: DispatchService, public omsService: OmsService, public location: Location, public ordersService: OrdersService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, public formBuilder: UntypedFormBuilder, public inventoryService: InventoryService) {

		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
		});

		this.token = this.globalSearchService.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
		this.omsService.setupSocketConnection(this.token);
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		});
		this.delsheetlink = this.config.apiServer.baseUrl+this.config.apiServer.runSheet
	}


	ngOnInit(): void {
		this.loadData();
		this.omsService.subscribeToCards((err, data) => {
			switch (data.type) {
				case 'dispatch':
					this.loadData();
				break;
			}
		})
	}

	toggleLock(truck: any) {
		truck.unlocked = (truck.unlocked) ? false : true;
	}

	cancel(dispatch: any) {
		this.dispatchService.cancelDispatch(dispatch.did).subscribe(results => {
			this.loadData();
			this.modalService.dismissAll();
		})
	}

	removeDispatch(dispatch) {
		this.dispatchService.removeDispatch(dispatch.did).subscribe(results => {
			this.loadData();
			this.modalService.dismissAll();
		})
	}

	delivered(dispatch: any) {

		this.dispatchService.deliverDispatch(dispatch.did).subscribe(results => {
			this.loadData();
			this.modalService.dismissAll();
		})
	}

	altLocation() {

		if(this.transfertruck.value) {
			this.dispatchService.transferDispatch(this.selectedorder.did, this.transfertruck.value).subscribe(results => {
				this.loadData();
				this.modalService.dismissAll();
				this.transfertruck.setValue('');
				this.seleccted_alt = false;
			})
		}
	}

	loadData() {

		this.dispatchService.getActiveDispatches().subscribe((results: any) => {
			this.dispatches = results.dispatches;
			this.trucks = results.trucks;
			this.allpoints = results.allpoints;
			if (results.allpoints.length) {
				if (this.zoomed) {
					this.zoomTo(this.zoomed_details_truck)
				} else {
					this.drawMap(results.allpoints, results.trucks)
				}
			}
		});
	}

	back(): void {
		this.location.back()
	}

	viewOrder(order: any) {
		this.selectedorder = order
		this.modalService.open(this.orderdetails, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {
			this.selectedorder = false;
		});
	}

	viewRunPdf(run) {

	}

	viewRun(run: any) {
		this.viewing_dispatches = run
		this.modalService.open(this.runsheet, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {
			this.selectedorder = false;
		});
	}

	removeLines() {
		let i = 0;
		for (i = 0; i < this.pathlines.length; i++) {
			this.pathlines[i].setMap(null); //or line[i].setVisible(false);
		}
		this.pathlines = [];
	}

	//, list: string
	drop(event: CdkDragDrop < string[] > , list: any) {
		this.droppedlist = list
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			this.zoomTo(list.truck)

			transferArrayItem(event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex);
		}

		this.submitMessage();

	}

	getRecalc(column: any) {

		let proceed = confirm("Optimize Route?");
		if(proceed) {
			//if(column.unlocked) {
				this.dispatchService.getDispatchRecalc(column.truck).subscribe((results: any) => {
					this.dispatches = results.dispatches;
					this.trucks = results.trucks;
					this.allpoints = results.allpoints;
					if (results.allpoints.length) {

						if (this.zoomed) {
							this.zoomTo(this.zoomed_details_truck)
						} else {
							this.drawMap(results.allpoints, results.trucks)
						}
					}
				});
			//}
		}
	}

	submitMessage() {

		//data has been re-arranged already by cdk drop
		//remove points from dispatches

		let thesedispatches = Object.create(this.dispatches);
		thesedispatches.forEach((d, index) => {
			thesedispatches[index] = d;
			thesedispatches[index].dispatched = d.dispatched.filter(a => { return a.ispoint == '0' });;
		})

		const data = { data: thesedispatches, user: this.user, droplist: this.droppedlist };

		if (data) {
			this.omsService.sendDispatchPositionUpdate({ data, roomName: this.CHAT_ROOM }, cb => {
				if(this.droppedlist) {

					const thisdispatched = this.dispatches.filter( d=> {
						return d.truck == this.droppedlist.truck
					})[0];

					if(thisdispatched.dispatched) {
						this.dispatchService.getActiveRun(this.droppedlist.truck).subscribe(async (r:any)=> {
							r.dispatched.forEach(ab => {
								let object = thisdispatched.dispatched.filter(a => {
									if(a.did == ab.did) {
										//a.shortesttime = ab.shortesttime
										a.timedsp = ab.timedsp
									}
								});
							})

							this.droppedlist = false;
						})
					}
				}


			});
		}
	}

	identify(index, item) {
		return item.truck;
	}

	zoomToPoint(point: any) {

		let lat = (point.clat !== NaN) ? point.clat : 0;
		let lng = (point.clng !== NaN) ? point.clng : 0;;
		var ltlng = new google.maps.LatLng(lat, lng);

		this.map.setZoom(12);
		this.map.setCenter(ltlng);
	}

	resetZoom() {
		this.map = false;
		this.drawMap(this.allpoints, this.trucks)
	}

	zoomTo(truckid: any) {
		this.zoom = 13;
		this.zoomed = true;
		this.zoomed_details_truck = truckid;
		this.map_animation = null;

		let dispatches = this.dispatches.filter(d => {
			return d.truck == truckid;
		})[0].allpoints;

		let truck = this.trucks.filter(d => {
			return d.id == truckid;
		});


		this.drawMap(dispatches, truck)
		var ltlng = new google.maps.LatLng(truck.lat, truck.lng);
		this.map.setCenter(ltlng);
	}

	drawMap(points: any, trucks: any) {

		this.markers = [];
		this.removeLines();
		//todo make this part of company settings
		let home = { lat: 42.928455044944165, lng: -78.82142723259965 }
		let lines = [home];
		let columndata = points;
		let shortlines = [];
		let colors = [];

		if (this.map) {
			while (this.markers.length) { this.markers.pop().setMap(null); }
			this.map_animation = null;
		}

		trucks.forEach((item: any) => {

			var ltlng = new google.maps.LatLng(item.lat, item.lng);

			let m = new google.maps.Marker({
				icon: 'assets/img/truckicon.png',
				size: '100,100',
				position: ltlng,
				title: item.name,
				optimized: true,
				//icon: svgMarker,
				animation: this.map_animation,
				//fillColor: item.color,
				//strokeColor: item.color,
				label: {
					text: item.name,
					color: '#000',
					fontSize: "14px",
					fontWeight: "bold",
				}
			});

			this.markers.push(m);
		});
		shortlines = [];
		let i = 0;
		columndata.forEach((item: any) => {
			//lines.push({lat: parseFloat(item.lat), lng: parseFloat(item.lng)})
			if (!shortlines[item.truck.id]) {
				shortlines[item.truck.id] = [];
			}

			if (!colors[item.truck.id]) {
				colors[item.truck.id] = [];
			}

			shortlines[item.truck.id].push({ lat: parseFloat(item.lat), lng: parseFloat(item.lng) });
			colors[item.truck.id].push(item.color);

			var ltlng = new google.maps.LatLng(item.lat, item.lng);
			let itemsort = +item.sort;

			var pinColor = item.color;

			//#00c853

			var pinSVGHole = "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
			var labelOriginHole = new google.maps.Point(12, 15);
			var pinSVGFilled = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";

			var labelOriginFilled = new google.maps.Point(12, 9);

			var markerImage = { // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
				path: pinSVGFilled,
				anchor: new google.maps.Point(12, 17),
				fillOpacity: 1,
				fillColor: pinColor,
				strokeWeight: 1,
				strokeColor: "white",
				scale: 1,
				labelOrigin: labelOriginFilled
			};
			var label = item.itemsort;
			if (item.itemsort == '-1' || item.itemsort == '9999999') {
				label = 'ORIGIN';
			}

			let m = new google.maps.Marker({
				icon: markerImage,
				position: ltlng,
				title: '(' + label + ') ' + item.invoice,
				optimized: true,
				//icon: svgMarker,
				animation: this.map_animation,
				//fillColor: item.color,
				//strokeColor: item.color,
				label: {
					text: '(' + label + ')',
					color: '#fff',
					fontSize: "14px",
					fontWeight: "bold",
				}
			});
			i++;
			this.markers.push(m);
		});


		//lines.push(home);
		//this.markers.push(home);

		var myLatlng = new google.maps.LatLng(42.928455044944165, -78.82142723259965);
		var mapOptions = {
			zoom: this.zoom,
			center: myLatlng,
			scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
			styles: [{
				"featureType": "water",
				"stylers": [{
					"saturation": 43
				}, {
					"lightness": -11
				}, {
					"hue": "#0088ff"
				}]
			}, {
				"featureType": "road",
				"elementType": "geometry.fill",
				"stylers": [{
					"hue": "#ff0000"
				}, {
					"saturation": -100
				}, {
					"lightness": 99
				}]
			}, {
				"featureType": "road",
				"elementType": "geometry.stroke",
				"stylers": [{
					"color": "#808080"
				}, {
					"lightness": 54
				}]
			}, {
				"featureType": "landscape.man_made",
				"elementType": "geometry.fill",
				"stylers": [{
					"color": "#ece2d9"
				}]
			}, {
				"featureType": "poi.park",
				"elementType": "geometry.fill",
				"stylers": [{
					"color": "#ccdca1"
				}]
			}, {
				"featureType": "road",
				"elementType": "labels.text.fill",
				"stylers": [{
					"color": "#767676"
				}]
			}, {
				"featureType": "road",
				"elementType": "labels.text.stroke",
				"stylers": [{
					"color": "#ffffff"
				}]
			}, {
				"featureType": "poi",
				"stylers": [{
					"visibility": "off"
				}]
			}, {
				"featureType": "landscape.natural",
				"elementType": "geometry.fill",
				"stylers": [{
					"visibility": "on"
				}, {
					"color": "#b8cb93"
				}]
			}, {
				"featureType": "poi.park",
				"stylers": [{
					"visibility": "on"
				}]
			}, {
				"featureType": "poi.sports_complex",
				"stylers": [{
					"visibility": "on"
				}]
			}, {
				"featureType": "poi.medical",
				"stylers": [{
					"visibility": "on"
				}]
			}, {
				"featureType": "poi.business",
				"stylers": [{
					"visibility": "simplified"
				}]
			}]

		};


		if (!this.map) {
			this.map = new google.maps.Map(document.getElementById("map"), mapOptions);
		}

		var marker = new google.maps.Marker({
			position: myLatlng,
			title: "Home"
		});

		marker.setMap(this.map);

		this.markers.forEach((item: any) => {
			item.setMap(this.map);
		});

		const lineSymbol = {
			path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
		};

		shortlines.forEach((line, index) => {
			const lines = new google.maps.Polyline({
				path: line,
				geodesic: true,
				strokeColor: colors[index][0],
				strokeOpacity: 1.0,
				strokeWeight: 2,
				icons: [{
					icon: lineSymbol,
					offset: '100%'
				}],
			});

			this.pathlines.push(lines)
			//lines.setMap(this.map);
		})

		for (i = 0; i < this.pathlines.length; i++) {
			this.pathlines[i].setMap(this.map);
		}


		// To add the marker to the map, call setMap();

	}

}
