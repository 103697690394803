<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-container *ngIf="transactions">

	<app-vendor-aging [aged]="vendor.aged" class="w-100"></app-vendor-aging>

	<nav class="navbar navbar-expand navbar-light bg-light w-100 no-shadow">
		<div class="collapse navbar-collapse" id="navbarNavDropdown">

			<div class="nav-item">
				<mat-form-field appearance="standard" *ngIf="type_filters">
					<mat-label>Type</mat-label>
					<mat-select [(ngModel)]="type_selected" multiple>
						<mat-option *ngFor="let v of type_filters" [value]="v.value">
							{{v.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<ul class="navbar-nav">
				<li class="nav-item">
					<mat-slide-toggle class="nav-link" [formControl]="opentrans">
						<mat-label>Show open docs only</mat-label>
					</mat-slide-toggle>
				</li>
			</ul>
			<div class="nav-item ml-auto mr-0 nav-link form-inline">
				<mat-form-field appearance="standard">
					<mat-label class="text-right">Search By Items/Order</mat-label>
					<input tabindex="0" matInput value="" [formControl]="keywords" class="text-right" (input)="searchTransaction($event.target.value)">
					<mat-progress-bar mode="indeterminate" color="accent" *ngIf="searching"></mat-progress-bar>
					<span matPrefix *ngIf="searching" class="ml-auto mr-auto">
						<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>
					<mat-hint>Search for an Item or Order</mat-hint>
				</mat-form-field>
			</div>
			<div class="nav-item form-inline">
				<form class="form-inline my-2 my-lg-0">
					<mat-form-field appearance="standard">
						<mat-label>Transaction Dates</mat-label>
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
							<input matEndDate [formControl]="todateCtrl" placeholder="End date">
						</mat-date-range-input>
						<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>
					<button mat-raised-button color="accent" class="ml-2" (click)="updateTransactions()" *ngIf="!sending">
						<mat-icon>refresh</mat-icon>
					</button>
					<span *ngIf="sending">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>

				</form>
			</div>
		</div>
	</nav>

	Results: {{ alltransactions.length }}
	<div class="container-fluid tp-box" [@flipState]="flip">

		<div class="tp-box__side tp-box__back" [ngClass]="flip == 'inactive' ? '' : ''">
			<ng-container *ngIf="transid">
				<div class="text-left mt-3">
					<button mat-raised-button color="primary" (click)="flip = 'inactive';transid=false;updateTransactions()">Back</button>
				</div>
				<app-allocations [transid]="transid" [type]="transidtype"></app-allocations>
			</ng-container>
		</div>

		<div id="frontside" class="row tp-box__side tp-box__front" [ngClass]="flip == 'active' ? '' : ''">
			<table mat-table [dataSource]="transactions" matSort>
				<ng-container matColumnDef="transno">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Document#</th>
					<td mat-cell *matCellDef="let tran">
						<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More">
							<mat-icon>more_vert</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<button mat-menu-item *ngIf="tran.type == '20' || tran.type == '21'" (click)="openDocument(tran,details,'html')">
								<mat-icon>visibility</mat-icon>
								<span>View HTML</span>
							</button>
							<button mat-menu-item *ngIf="tran.type == '20' || tran.type == '21'" (click)="openDocument(tran,details,'pdf')">
								<mat-icon>visibility</mat-icon>
								<span>View PDF</span>
							</button>
							<button mat-menu-item *ngIf="tran.type == '20' || tran.type == '21'" (click)="sendEmail(tran)">
								<mat-icon>email</mat-icon>
								<span>Email</span>
							</button>

							<mat-divider></mat-divider>
							<button mat-menu-item (click)="loadAllocations(tran.id,tran.type)" *ngIf="(tran.type == '21' || tran.type == '22') ">
								<mat-icon>view_list</mat-icon>
								<span>Allocate</span>
							</button>
							<button mat-menu-item (click)="viewPayments(tran,details)" *ngIf="tran.type == '20' ">
								<mat-icon>credit_card</mat-icon>
								<span>Payments</span>
							</button>
							<mat-divider></mat-divider>
							<button mat-menu-item (click)="openGl(tran, details)">
								<mat-icon>view_list</mat-icon>
								<span>GL's</span>
							</button>
							<button mat-menu-item (click)="viewAllocations(tran, details)" *ngIf="(tran.type == '21' || tran.type == '22') ">
								<mat-icon>view_list</mat-icon>
								<span>Allocations</span>
							</button>
						</mat-menu>
						{{ tran.transno }}
					</td>
				</ng-container>

				<ng-container matColumnDef="typename">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Type</th>
					<td mat-cell *matCellDef="let tran" [innerHTML]="tran.typename"></td>
				</ng-container>
				<ng-container matColumnDef="trandate">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Date</th>
					<td mat-cell *matCellDef="let tran">
						{{tran.trandate }}
					</td>
				</ng-container>
				<ng-container matColumnDef="order_">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Reference#</th>
					<td mat-cell *matCellDef="let tran">
						{{tran.suppreference }}
					</td>
				</ng-container>
				<ng-container matColumnDef="totalamount">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Total</th>
					<td mat-cell *matCellDef="let tran">
						{{tran.totalamount | currency }}
					</td>
				</ng-container>
				<ng-container matColumnDef="allocated">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Allocated</th>
					<td mat-cell *matCellDef="let tran">
						{{tran.allocated | currency }}
					</td>
				</ng-container>
				<ng-container matColumnDef="balance">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Balance</th>
					<td mat-cell *matCellDef="let tran">
						{{tran.totalamount - tran.allocated | currency }}
					</td>
				</ng-container>
				<ng-container matColumnDef="settled">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Settled</th>
					<td mat-cell *matCellDef="let tran">
						<span *ngIf="tran.settled == '1'" class="text-success bolder">Yes</span>
						<span *ngIf="tran.settled =='0'" class=" bolder">No</span>
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="headercolumns;sticky:true;"></tr>
				<tr mat-row *matRowDef="let row; columns: headercolumns;"></tr>
			</table>
			<mat-paginator [pageSizeOptions]="[50, 100, 1000]" showFirstLastButtons aria-label="select page">
			</mat-paginator>
		</div>
	</div>
</ng-container>
