import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef,Input, SimpleChanges, OnChanges,HostListener } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { OmsService } from '../../services/oms.service';
import { PaymentsService } from '../../services/payments.service';
import { DispatchService } from '../../services/dispatch.service';
import { MatAccordion } from '@angular/material/expansion';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
	selector: 'app-dispatches-delivered-notinvoiced',
	templateUrl: './dispatches-delivered-notinvoiced.component.html',
	styleUrls: ['./dispatches-delivered-notinvoiced.component.scss']
})
export class DispatchesDeliveredNotinvoicedComponent implements OnInit {



	@ViewChild(MatAccordion) accordion: MatAccordion;
	@ViewChild("orderdetails") orderdetails: ElementRef;
	@ViewChild("runsheet") runsheet: ElementRef;
	@Input() selectedId: any = false;
	CHAT_ROOM = "OrderBoard";
	user: any = [];
	color: string = 'blue';
	current_truck : any = false;
	config: any = [];
	token: string = '';
	dispatches: any = [];
	alldispatches: any = [];
	selectedorder: any = false;
	trucks: any = [];
	allpoints: any = [];
	map: any = false;
	zoomed: boolean = false;
	zoom: number = 11;
	pathlines: any = [];
	markers: any = false;
	sending: any = false;
	zoomed_details_truck: any = false;
	seleccted_alt: any = false;
	transfertruck = new UntypedFormControl('')
	truck = new UntypedFormControl('');
	mustbedelivered = new UntypedFormControl(false);
	disabled: boolean = false;
	payment_methods: any = [];
	taxdatatotal: any = 0.00

	invoicesCreated: any = [];
	today: number = Date.now();
	//'transfertruck'
	focused: any = false;


	constructor(private modalService: NgbModal, private dispatchService: DispatchService, public omsService: OmsService, public location: Location, public ordersService: OrdersService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, public formBuilder: UntypedFormBuilder, public inventoryService: InventoryService, public paymentsService: PaymentsService) {

		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
		});

		this.token = this.globalSearchService.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
		this.omsService.setupSocketConnection(this.token);
		this.color = this.globalSearchService.getColor();

	}

	ngOnInit(): void {
		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		});

		this.loadData();

		this.route.params.subscribe(params => {
			if (params['id']) {
				this.truck = new UntypedFormControl({ value: params['id'], disabled: true });
			}
		});

		this.globalSearchService.payment_methods.subscribe(result => this.payment_methods = result);

		this.dispatchService.getTrucks().subscribe((result) => {
			this.trucks = result;
		});

		this.mustbedelivered.valueChanges.subscribe(newValue => {
			this.loadRun(this.truck.value);
		})
	}

	getActiveOrders(truck: any) {

		if(this.alldispatches && this.alldispatches.length) {
			let activetruck = this.alldispatches.filter(r=> {
				return r.truckid == truck;
			})

			if(activetruck.length) {
				return activetruck.length
			}
		}
		return 0;
	}

	exportXlS() {
		this.globalSearchService.exportJsonToExcel(this.invoicesCreated, 'InvoicesCreated')
	}
	printPdf() {
		this.globalSearchService.exportPDF('paymentresults');
	}

	updateDispatchQty(event: any, item: any) {

		if (parseFloat(item.ordered) >= parseFloat(event.target.value)) {
			item.dispatch = this.santizeNumberValue(event.target.value);
		} else {

			event.target.value = parseFloat(item.ordered);
			item.dispatch = parseFloat(item.ordered)
		}

	}

	updateSelected(event: any) {
		this.selectedorder.details = event.order
		this.selectedorder.details.totals = event.totals
		//this.selectedorder.details = event.order
		//this.selectedorder.totals = event.totals
		this.modalService.dismissAll();
	}

	santizeNumberValue(input: any) {
		return (Number.isNaN(input) || input == '') ? 0 : parseFloat(input);
	}

	removeInvoice(inv: any) {
		let index = this.dispatches.indexOf(inv);
		this.dispatches.splice(index, 1)
	}

	showDetails(order: any) {

		order.showdetails = (!order.showdetails) ? true : false;

		this.selectedorder = order
		this.modalService.open(this.orderdetails, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result) => {}, (reason) => {
			this.selectedorder = false;
		});

	}

	createPayments() {

		var message = '';
		var ok = true;

		this.dispatches.forEach((i: any) => {
			if (i.mustpay) {
				let total = parseFloat(i.details.total)
				let totalpayments = i.details.payments.reduce((accumulator, obj) => { return accumulator + parseFloat(obj.payment) }, 0);

				if (totalpayments < total) {
					ok = false;
					message += ' Order# ' + i.invoice + " Requires a total Payment of $" + total + "\n";
				}
			}
		})
		//loop for empty payment types
		this.dispatches.forEach((i: any) => {
			i.details.payments.forEach( (p:any) => {
				if(p.payment > 0 && (!p.payment_method || p.payment_method  == '')) {
					ok = false;
					message += ' Order# ' + i.invoice + " Has No Payment Type\n";
				}
			})
		})

		if (ok) {
			this.sending = true;
			this.dispatchService.createInvoiceAndPay(this.dispatches).subscribe((results: any) => {
				this.sending = false;
				this.invoicesCreated = [];

				//results.data only will get set if invoices are created from createInvoiceAndPay()
				//single order confirmation that happens to be a duplicate needs undefined check to reload data and not error out

				if(results.data !== undefined){

					for (let x = 0; x < results.data.length; x++) {
						if (results.data[x].type == '11') {
							results.data[x].reprintlink = this.config.apiServer.baseUrl + this.config.apiServer.creditLink + results.data[x].id;
						} else {
							results.data[x].reprintlink = this.config.apiServer.baseUrl + this.config.apiServer.invoiceLink + results.data[x].id;
						}
						this.invoicesCreated.push(results.data[x]);
					}
					this.loadData();
				}else{
					this.loadData();
				}



				// if(this.current_truck) {
				// this.loadRun(this.current_truck.id);
				// } else {

				// }
			})
		} else {
			alert(message);
		}

	}

	pTotal(payments) {
		return payments.reduce((accumulator, obj) => { return accumulator + parseFloat(obj.payment) }, 0);
	}

	removePayment(payments: any = [], payment) {
		let index = payments.indexOf(payment);
		payments.splice(index, 1)
	}

	addPayment(da: any, payments: any = []) {
		let addon = {
			payment: da.details.totals.total - this.pTotal(da.details.payments),
			payment_method: '',
			checknumber: '',
		}
		payments.push(addon)
	}

	setCheck(event: any, item: any) {
		item.checknumber = event;
	}
	setPayment(event: any, item: any) {
		item.payment = event;
	}

	setPaymentMethod(event: any, item: any) {
		item.payment_method = event;
	}

	getTruckDetails(truckid:any) {

		if(truckid != 'ALL') {
			this.current_truck = this.trucks.filter((r:any) => {
				return r.id == truckid;
			})[0];
		} else {
			this.current_truck = {
				name: 'ALL',
				driver: '',
			}
		}
		return this.current_truck;
	}

	loadRun(event: any) {
		this.invoicesCreated = [];
		this.getTruckDetails(event);

		if (this.mustbedelivered.value) {
			this.dispatchService.getNeedInvoiced().subscribe((results: any) => {
				this.dispatches = results;
				this.alldispatches = results;
				if (event != '' && event != 'ALL') {
					this.dispatches = results.filter(r => r.truckid == event);
				}
				if(event == 'ALL') {
					this.dispatches = results;
				}
			});
		} else {
			this.dispatchService.getNeedInvoicedAll().subscribe((results: any) => {
				this.dispatches = results;
				this.alldispatches = results;
				if (event != '' && event != 'ALL') {
					this.dispatches = results.filter(r => r.truckid == event);
				}
				if(event == 'ALL') {
					this.dispatches = results;
				}
			});
		}
	}

	toggleDeliverOnly(event) {
		this.mustbedelivered = event.checked
		this.loadData();
	}

	loadData() {
		if (this.mustbedelivered.value) {
			this.dispatchService.getNeedInvoiced().subscribe((results: any) => {
				this.alldispatches = results;
				this.dispatches = results.filter(r => r.truckid == this.truck.value);

				//after default payment method
				// if(results) {
				// 	if(this.dispatches) {
				// 		this.dispatches.forEach( d=> {
				// 			if(d) {
				// 				d.details.forEach( det => {
				// 					if(det) {
				// 						det.payments.forEach(p => {
				// 							this.setPaymentMethod(p.payment_method, p);
				// 						});
				// 					}
				// 				});
				// 			}
				// 		});
				// 	}
				// }
			});
		} else {
			this.dispatchService.getNeedInvoicedAll().subscribe((results: any) => {
				//this.dispatches = results
				this.alldispatches = results;
				this.dispatches = results.filter(r => r.truckid == this.truck.value);
			});
		}
	}

	back(): void {
		this.location.back()
	}

	viewOrder(order: any) {
		this.selectedorder = order
		this.modalService.open(this.orderdetails, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result) => {}, (reason) => {
			this.selectedorder = false;
		});
	}

	removeLines() {
		let i = 0;
		for (i = 0; i < this.pathlines.length; i++) {
			this.pathlines[i].setMap(null); //or line[i].setVisible(false);
		}
		this.pathlines = [];
	}

	submitMessage() {

		//data has been re-arranged already by cdk drop
		//remove points from dispatches

		let thesedispatches = Object.create(this.dispatches);
		thesedispatches.forEach((d, index) => {

			thesedispatches[index] = d;
			thesedispatches[index].dispatched = d.dispatched.filter(a => { return a.ispoint == '0' });;
		})

		const data = { data: thesedispatches, user: this.user };

		if (data) {
			this.omsService.sendDispatchPositionUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
		}
	}

	identify(index, item) {
		return item.did;
	}

}
