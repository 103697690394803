import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UsersService } from '../../services/users.service'
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { GlobalsService } from '../../services/globals.service'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { Location } from '@angular/common'
import {MatCardModule} from '@angular/material/card';


@Component({
	selector: 'app-user-create',
	templateUrl: './user-create.component.html',
	styleUrls: ['./user-create.component.scss']
})




export class UserCreateComponent implements OnInit {
	title = 'New User';
	user: any = [];
	users: any = [];
	userForm: UntypedFormGroup;
	systemForm: UntypedFormGroup;
	userdetails: any = [];
	locations: any = [];
	userlocations: any = [];
	usergroups: any = [];
	yesno: any = [
		{ value: '0', name: 'No' },
		{ value: '1', name: 'Yes' }
	];

	formEditAllowed = [
		'userid',
		'password',
		'realname',
		'phone',
		'email',
		'defaultlocation',
		'fullaccess',
		'cancreatetender',
		'editinv',
		'dispatchadmin',
		'customeradmin',
		'user_group',
		//'phoneno',
	]

	userid: any = '';
	color: any = 'blue';
	defaultlocation: any;
	recs: any = [];
	activeStep: any;
	emailRegex: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
	phoneRegex: string = "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$";



	constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, private userService: UsersService, public router: Router, private location: Location, public globalSearchService: GlobalSearchService, private globalsService: GlobalsService) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.userForm = this.formBuilder.group({
			userid: ['',[Validators.required, Validators.pattern('[-_a-zA-Z0-9]*')]],
			password: ['', [Validators.required]],
			realname: ['', [Validators.required]],
			phone: ['', Validators.pattern(this.phoneRegex)],
			email: ['', Validators.pattern(this.emailRegex)],

		});
		this.systemForm = this.formBuilder.group({
			fullaccess: ['', [Validators.required]],
			cancreatetender: ['', [Validators.required]],
			editinv: ['', [Validators.required]],
			dispatchadmin: ['', [Validators.required]],
			defaultlocation: ['', [Validators.required]],
			customeradmin: ['', [Validators.required]],
			user_group: ['', [Validators.required]],
		});

		this.user['locations'] = [];
	}

	createUser() {
		if(this.userForm.valid && this.systemForm.valid) {
			let formData = {...this.userForm.value, ...this.systemForm.value};
			this.userService.createUser(this.user.userid, formData).subscribe((results) => {
				this.user.user = results;
				this.globalSearchService.showNotification("Updated", 'success', 'bottom', 'right');
				this.updateLocations();
				this.location.back()
			});
		}
	}

	ngAfterViewInit() {
		this.globalsService.getLocations().subscribe(details => {
			this.locations = details;

		});

		this.globalsService.getAllLocations().subscribe(details => {
			this.recs = details;
		});

		this.userService.getUserGroups().subscribe(groups => {
			this.usergroups = groups;
		});
	}

	updateLocations() {

		let data = {
			userid: this.userid == '' ? this.userForm.get("userid").value : this.userid,
			locations: this.user.locations
		};


		this.userService.updateUserLocations(data).subscribe((user: any) => {
			this.user = user;
		});
	}

	addUserLocation(loc: any) {
		let index = this.locations.indexOf(loc)
		this.locations.splice(index, 1);
		this.user.locations.push(loc)

		this.sortlocations();
	}

	addAllLocation(loc: any) {
		this.user.locations = this.locations;
	}

	removeUserLocation(loc: any) {

		if(loc == this.defaultlocation){
			alert("Can not remove default location from the list.");
			return;
		}
		let index = this.user.locations.indexOf(loc)
		this.user.locations.splice(index, 1);
		this.locations.push(loc)
		this.sortlocations();

	}

	sortlocations(){
		this.locations.sort((n1,n2) => n1.loccode - n2.loccode);
		this.user.locations.sort((n1,n2) => n1.loccode - n2.loccode);
	}

	defaultLocation(event : any){
		if(this.defaultLocation !== undefined && this.locations.length == 0){
			this.defaultlocation = this.user.locations.filter(loc=>(loc.loccode == event.value))[0];
			return;
		}
		if(this.defaultlocation !== undefined){
			let index = this.user.locations.indexOf(this.defaultlocation);
			this.locations.push(this.defaultlocation);
			this.user.locations.splice(index, 1);
		}

		this.defaultlocation = this.locations.filter(loc=>(loc.loccode == event.value))[0];

		//remove from locations
		let index = this.locations.indexOf(this.defaultlocation);
		this.locations.splice(index, 1);

		//push to user locations
		this.user.locations.push(this.defaultlocation);

		//sort
		this.sortlocations();
	}

	onSubmit() {

	}

	back(): void {
		this.location.back()
	}

	translate_yn(input: any){
		if(input == 0 || input == 1){
			return input == 0 ? 'No' : 'Yes';
		}else{
			return "?";
		}
	}

	translate_usergroup(input: any){
		if(input != ''){
			return this.usergroups.filter(group => (group['user_group_id'] == input))[0]['name'];
		}else{
			return '';
		}
	}

	logStep(event: any){
		this.activeStep = event.selectedIndex;
	}

	format_phone(input) {
		var digits = input.value.replace(/\D/g, '');
		var number_f = digits; //formatted output
		if (digits.length >= 6){
			number_f = '(' + digits.substring(0, 3) + ')'; //(###) wrapper
			number_f += digits.substring(3, 6) + '-';
			number_f += digits.substring(6);
		} else if (digits.length >= 3){
			number_f = '(' + digits.substring(0, 3) + ')'; //(###) wrapper
			number_f += digits.substring(3);
		}
		input.value = number_f;
	}


}
