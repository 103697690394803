import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit} from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Location } from '@angular/common'
import { interval, Subscription} from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router} from '@angular/router';

import { InventoryService } from '../../../services/inventory.service';
import { BinLabelsService } from '../../../services/bin-labels.service';

import { GlobalsService } from '../../../services/globals.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';



@Component({
  selector: 'app-bin-labels',
  templateUrl: './bin-labels.component.html',
  styleUrls: ['./bin-labels.component.scss']
})
export class BinLabelsComponent implements OnInit {

	@Input() bins;

	allbins: any = [];
	startingOptions: any = [];
	endingOptions: any = [];

	singleLabel = new UntypedFormControl('');

	selectedItemSearch = new UntypedFormControl('');
	startingBin = new UntypedFormControl('');
	endingBin = new UntypedFormControl('');

	fromToBin: UntypedFormGroup;
	storageLabel: UntypedFormGroup;
	tireLabel: UntypedFormGroup;

	today = new Date();
	nextid: any;
	neworcurrent: string = '';
	bintext: string = '';
	filteredOptions: any = [];
	searchResults: any = false;
	itemsearching: any = false
	storagebin: any = '';
	sending: any = false;

	constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, public router: Router, public binLabelsService: BinLabelsService , public inventoryService: InventoryService) { }

	ngOnInit(): void {
		this.allbins = this.bins;
		this.startingOptions = this.bins;
		this.filteredOptions = this.bins
		this.endingOptions = this.bins;

		this.fromToBin = this.formBuilder.group({
			bin_from: ['', Validators.required],
			bin_to: ['', Validators.required],
		});

		this.binLabelsService.getNextTag().subscribe((result: any) => {
			this.storageLabel = this.formBuilder.group({
				storage_id: [result.id],
				daterec: [this.today, Validators.required],
				customer: ['', Validators.required],
				parcels: ['', Validators.required],
				description: ['', Validators.required],
				barcode: ['', Validators.required],
				print: [true, Validators.required],
				bin: ['', Validators.required],
			});
		});

		this.tireLabel = this.formBuilder.group({
			partno: ['', Validators.required],
			description: ['', Validators.required],
			size: ['', Validators.required],
		});

		this.singleLabel.valueChanges.subscribe(newValue=>{
			this.filteredOptions = this.filterItem(this.bins, newValue, 'location');
		})

		this.fromToBin.get('bin_from').valueChanges.subscribe(newValue=>{

			if(!this.startingOptions) {
				this.startingOptions = this.bins
			}
			this.startingOptions = this.filterItem(this.bins, newValue, 'location');
		});

		this.fromToBin.get('bin_to').valueChanges.subscribe(newValue=>{
			if(!this.endingOptions) {
				this.startingOptions = this.bins
			}
			//this.startingOptions = this.filterItem(this.bins, newValue, 'location');
		});

		this.singleLabel.valueChanges.subscribe(newValue=>{
			this.newOrCurrentBin();
		});

	}
	setValue(i: any) {

		this.tireLabel = this.formBuilder.group({
			partno: [i.stockid, Validators.required],
			description: [i.description, Validators.required],
			size: [i.size, Validators.required],
		});

		this.searchResults = false;

		/*
		"stockid": "CON-03589800000",
		"lineid": "CON",
		"price": "282.24",
		"supplierno": "03589800000",
		"bin": "DWI-12",
		"description": "255\/40ZR20XL ContiSportContact 5 101Y",
		"qoh": 0,
		"size": "2554020"
		*/
	}

	itemSearch() {
		if (this.itemsearching) {
			this.itemsearching.unsubscribe()
		}

		if (this.tireLabel.get('partno').value != '') {
			this.sending = true;
			this.itemsearching = this.inventoryService.itemCleanSearch(this.tireLabel.get('partno').value).subscribe(r => {
				this.searchResults = r;
				this.sending = false;
			});
		}
	}
	newOrCurrentBin() {
		let exists = this.allbins.filter(bin =>{
			return bin.location == this.singleLabel.value
		})

		if(this.singleLabel.value =='') {
			this.neworcurrent = '';
		} else {
			this.neworcurrent = (exists.length) ? '<b>PRINT CURRENT BIN</b>: ' +this.singleLabel.value : '<b>PRINTING NEW BIN:</b> '+this.singleLabel.value
		}
	}
	getBinRangeAffected() {

		let affeccted = [];
		if(this.fromToBin.valid) {
			affeccted = this.allbins.filter((bins: any) => {
				return bins.location >= this.fromToBin.get('bin_from').value && this.bins.location <= this.fromToBin.get('bin_to').value;
			});
		}

		return affeccted;
	}

	printSingleLabel() {
		if(this.singleLabel.value != '' ) {

			let exists = this.allbins.filter(bin =>{
				return bin.location == this.singleLabel.value
			})
			var pass = true;
			if(!(exists.length)) {
				pass = confirm('Print a new Bin Label : ' + this.singleLabel.value + '?')
			}

			if(pass) {
				this.binLabelsService.printSingle(this.singleLabel.value).subscribe( (result: any) => {
					if(!result.success) {
						alert(result.message);
					} else {
						alert('Label Range '+ this.singleLabel.value +' Sent')
					}
				});
			}
		}
	}

	printBinRange() {
		if(this.fromToBin.valid) {
			this.binLabelsService.printRange(this.fromToBin.get('bin_from').value, this.fromToBin.get('bin_to').value ).subscribe( (result: any) => {
				if(!result.success) {
					alert(result.message);
				} else {
					alert('Bin Range '+ this.fromToBin.get('bin_from').value + ' - '+ this.fromToBin.get('bin_to').value +' Sent')
				}
			});
		}
	}

	printStorageLabel() {
		if(this.storageLabel.valid) {

			var pass = true;

			if(this.storageLabel.get('print').value) {
				let total_print = parseFloat(this.storageLabel.get('parcels').value)
				if(total_print > 10) {
					pass = confirm('You are about to print ' + total_print + ' Labels, are you sure?')
				}
			}

			if(pass) {
				this.binLabelsService.printStorageLabel(this.storageLabel.value).subscribe( (result: any) => {
					if(!result.success) {
						alert(result.message);
					} else {
						alert('Storage Label Sent')
					}
				});
			}
		}
	}

	printTireLabel() {
		if (this.tireLabel.valid) {
			var pass = true;
			if (pass) {
				this.binLabelsService.printTireLabel(this.tireLabel.value).subscribe((result: any) => {
					if (!result.success) {
						alert(result.message);

						this.tireLabel = this.formBuilder.group({
							partno: ['', Validators.required],
							description: ['', Validators.required],
							size: ['', Validators.required],
						});


					} else {
						alert('Tire Label Sent')
					}
				});
			}
		}
	}

	filterItem(input: any, term: any , keys: any ){
		var result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) &&	new RegExp(term, 'gi').test(item[key])));
		return result
	}

}
