import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform,AfterViewInit} from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common'

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { merge, fromEvent } from "rxjs";
import {debounceTime, distinctUntilChanged, startWith, tap, delay} from 'rxjs/operators';

import { MatSnackBar,MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition,} from '@angular/material/snack-bar';
import { PurchasingService } from '../../services/purchasing.service';
import { OrdersService } from '../../services/orders.service';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { OmsService } from '../../services/oms.service';

@Component({
  selector: 'app-purchase-order-view',
  templateUrl: './purchase-order-view.component.html',
  styleUrls: ['./purchase-order-view.component.scss']
})
export class PurchaseOrderViewComponent implements OnInit {

	user: any;
	config: any;

	total_qty: number = 0;
	totals: any = [];
	CHAT_ROOM = "OrderBoard";
	taxgroups: any = [];
	salespeople: any = [];
	shipvia: any = [];
	terms: any = [];
	zones: any = [];
	locations: any = [];
	holdreasons: any = [];

	itemSearchForm: UntypedFormGroup;
	vendorFormGroup: UntypedFormGroup;

	vendor: any = [];

	limits = [
		{name: '25', value: '25'},
		{name: '50', value: '50'},
		{name: '100', value: '100'},
		{name: '150', value: '150'},
		{name: '250', value: '200'},
		{name: '500', value: '500'},
		{name: 'No Limit', value: false},
	];

	vendorinfo: boolean = true;
	searching: boolean = false;
	dspcost: boolean = true;
	complete:boolean = false;
	editing_order:boolean = false;

	inventorysearching: any = false;

	search_items: any= [];
	filtered_items: any= [];
	cart_items: any = [];
	total_cart_qty: number = 0;
	cart_totals: any = [];
	summary: any = [];
	color: any = 'blue'
	baselink: any = [];
	ponumber: any =  false;
	recdoc: any = false;
	purchaseorder: any = [];
	constructor(private omsService: OmsService, private router: Router,private fb: UntypedFormBuilder,private location: Location,private route: ActivatedRoute,private purchasingService:PurchasingService,private globalSearchService:GlobalSearchService, private ordersService: OrdersService ,private globalsService: GlobalsService ) {
		this.config = this.globalsService.getApiConfig()
		this.color = this.globalSearchService.getColor();
		this.baselink = this.config.apiServer.baseUrl + this.config.apiServer.poLink;
		this.recdoc = this.config.apiServer.baseUrl + this.config.apiServer.recLink;
	}

	ngOnInit(): void {

		this.itemSearchForm = this.fb.group({
			keywords: ['', Validators.required],
			limit: [this.limits[0].value, Validators.required],
		});

		this.itemSearchForm.get("keywords").valueChanges.subscribe(newValue=>{
			this.itemSearch(newValue)
		})

		this.route.params.subscribe(params => {
			if(params['id']) {
				const orderno = params['id'];

				this.purchasingService.getPurchaseOrder(orderno).subscribe((result:any) => {
					this.purchaseorder = result
				});
			}
		});
	}

	toggleVendorInfo() {
		this.vendorinfo = (this.vendorinfo) ? false : true;
	}

	itemSearch(keywords: any) {
		this.searching = true;
		this.vendorinfo = false;
		if(this.inventorysearching) {
			this.inventorysearching.unsubscribe()
		}

		let data = { keywords: keywords, limit: this.itemSearchForm.get('limit').value, vendor: this.vendorFormGroup.value};

		this.inventorysearching = this.purchasingService.getItemSearch(data).subscribe((items: any) => {

			this.search_items = items
			this.filtered_items = items
			this.searching = false;
		});
	}

	stopProp(event: any): void {
		event.stopPropagation();
	}

	decrement(item: any) {
		let index = this.filtered_items.indexOf(item);
		if(this.filtered_items[index].quantity > 0){
			this.filtered_items[index].quantity -=1;
		}
	}

	increment(item: any) {
		let index = this.filtered_items.indexOf(item);
		this.filtered_items[index].quantity +=1;
	}

	//todo - move these to order service
	updateSearchPrice(event:any, item:any ) {
		let index = this.filtered_items.indexOf(item) ;
		this.filtered_items[index].price = event.target.value;
	}

	updateSearchQuantity(event:any, item:any ) {
		let index = this.filtered_items.indexOf(item) ;
		this.filtered_items[index].quantity = event.target.value;
	}

	updatePrice(event:any, item:any ) {
		let index = this.cart_items.indexOf(item) ;
		this.cart_items[index].price = event.target.value;
		this.globalSearchService.showNotification(item.description +' x '+item.quantity+' Updated','warning','bottom','right');
		this.updateCartItem(this.cart_items[index]);
	}

	updateQuantity(event:any, item:any ) {
		let index = this.cart_items.indexOf(item) ;
		this.cart_items[index].quantity = event.target.value;
		this.globalSearchService.showNotification(item.description +' x '+item.quantity+' Updated','warning','bottom','right');
		this.updateCartItem(this.cart_items[index]);
	}

	updateDiscount(event:any, item:any ) {
		let index = this.cart_items.indexOf(item) ;
		this.cart_items[index].discount = event.target.value;
		this.updateCartItem(this.cart_items[index]);
	}

	updateNote(event:any, item:any ) {
		let index = this.cart_items.indexOf(item) ;
		this.cart_items[index].line_notes = event.target.value;
		this.updateCartItem(this.cart_items[index]);
	}

	updateCartItem(item:any) {

		let index = this.cart_items.indexOf(item);

		let data = {
			supplierid: this.vendor.supplierid,
			cartid: item.cart_id,
			description: item.description,
			price: item.price,
			quantity: item.quantity,
			discount: item.discount,
			line_notes: item.line_notes,
		};

		this.purchasingService.updateOrder(data).subscribe((results: any ) => {

			this.total_cart_qty = this.cart_items.reduce(function (accumulator: number, items: any) {
				return accumulator + parseFloat(items.quantity);
			}, 0);

			this.purchasingService.getTotals(this.vendor.supplierid).subscribe((results: any) => {
				 this.totals = results;
			});
		});
	}

	removeFromOrder(cart_id: any) {
		this.purchasingService.removeFromOrder(cart_id, this.vendor.supplierid).subscribe((results: any) => {
			this.cart_items = results;
			this.total_cart_qty = results.reduce(function (accumulator, items) {
				return accumulator + parseFloat(items.quantity);
			}, 0);

		});
	}

	addToOrder(item: any) {

		let this_index = this.filtered_items.indexOf(item)
		if(!this.filtered_items[this_index].quantity) {
			this.filtered_items[this_index].quantity = 1;
		}

		let add_items = [];

		//all qtys to add
		this.filtered_items.forEach((item, index) => {
			//allow negatives? hrmm
			if(parseFloat(item.quantity) != 0) {
				add_items.push(item);
			}
		});

		if(add_items.length) {
			this.purchasingService.addMultipleToOrder(add_items, this.vendor.supplierid).subscribe((results: any) => {

				this.cart_items = results;
				this.updateTotals();

				add_items.forEach((item, index) => {
					//reset qtys and show notification
					if(parseFloat(item.quantity) != 0) {
						this.globalSearchService.showNotification(item.description +' x '+item.quantity+' Added','success','bottom','right');
						let index = this.filtered_items.indexOf(item);
						this.filtered_items[index].quantity = 0;
					}
				});
			});
		}
	}

	updateTotals() {
		this.purchasingService.getTotals(this.vendor.supplierid).subscribe((results: any) => {
			this.cart_totals = results;
			this.total_cart_qty = this.cart_items.reduce(function (accumulator: number, items: any) {
				return accumulator + parseFloat(items.quantity);
			}, 0);
		});
	}

	init() {

		//forcing cascade - not loaded properly during testing.
		this.globalSearchService.user.subscribe((user: any)=> {
			this.user = user;
			if(user) {
				this.globalSearchService.zones.subscribe( (zones: any) => {
					this.zones = zones;
					if(zones) {
						this.globalSearchService.locations.subscribe( (locations: any) => {
							this.locations = locations;
								if(user.user) {
									if(this.vendor) {
									var future = new Date();
									future.setDate(future.getDate() + 3);

									this.vendorFormGroup = this.fb.group({
										supplierid: [this.vendor.supplierid, [Validators.required]],
										suppname: [this.vendor.suppname, [Validators.required]],
										address1: [this.vendor.address1, [Validators.required]],
										address2: [this.vendor.address2, []],
										address3: [this.vendor.address3, [Validators.required]],
										address4: [this.vendor.address4, [Validators.required]],
										address5: [this.vendor.address5, [Validators.required]],
										address6: [this.vendor.address6, [Validators.required]],
										deladd1: [user.user.defaultlocation.deladd1],
										deladd2: [user.user.defaultlocation.deladd2],
										deladd3: [user.user.defaultlocation.deladd3],
										deladd4: [user.user.defaultlocation.deladd4],
										deladd5: [user.user.defaultlocation.deladd5],
										deladd6: [user.user.defaultlocation.deladd6],
										warehouse: [user.user.defaultlocation.loccode,[Validators.required]],
										deliverydate: [future.toISOString(),[Validators.required]],
										initiator: [this.user.user.userid,[Validators.required]],
										refrenceno: [''],
									});
								}
							}
						});
					}
				});
			}
		});
	}

	createOrder() {
		this.purchasingService.createPurchaseOrder(this.vendor, this.vendorFormGroup.value, this.cart_items, this.user).subscribe((results:any) => {
			if(results.success) {
				this.router.navigate(['/purchasing/'+results.header.supplierno+'/'+results.header.orderno])
				this.clearOrder();
				this.complete = true;
				this.summary = results;
				this.omsService.sendPutUpdate(results,this.CHAT_ROOM);

			} else {
				alert(results.message);
			}
		});
	}

	clearOrder() {
		this.purchasingService.clearOrder(this.vendor.supplierid).subscribe((results: any[]) => {
			 this.cart_items = [];
			 this.search_items = [];
			 this.filtered_items = [];
			 this.searching = false;
			 this.total_cart_qty = 0;
		});
	}

	back(): void {
		this.location.back()
	}

	ngAfterViewInit(): void {

		this.globalSearchService.user.subscribe(async user => this.user = user);
		this.globalSearchService.locations.subscribe(async user => this.user = user);

		this.globalSearchService.taxgroups.subscribe( async (results: any)=> {
			this.taxgroups = results;
		});

		this.globalSearchService.salespeople.subscribe(async (results: any)=> {
			this.salespeople = results;
		});

		this.globalSearchService.shipvia.subscribe(async (results: any)=> {
			this.shipvia = results;
		});

		this.globalSearchService.terms.subscribe( async (results: any) => {
			this.terms = results;
		});

		this.globalSearchService.holdreasons.subscribe(async (results: any) => {
			this.holdreasons = results;
		});
	}

}
