import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit} from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup,ReactiveFormsModule } from '@angular/forms';
import { interval, Subscription} from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router} from '@angular/router';
import { TaxesService } from '../../../services/taxes.service';

@Component({
  selector: 'app-tax-groups',
  templateUrl: './tax-groups.component.html',
  styleUrls: ['./tax-groups.component.scss']
})
export class TaxGroupsComponent implements OnInit {

	taxgroups: any = []
	constructor(private taxesService: TaxesService) { }

	ngOnInit(): void {
		this.taxesService.getTaxGroups().subscribe((results: any) => {
			this.taxgroups = results;
		})
	}

}
