<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<!--
		<mat-chip-set role="list">
			<mat-chip role="listitem" *ngFor="let l of viewedtransactions">
				<button mat-menu-item (click)="viewPayments(l,details)">
					<mat-icon>credit_card</mat-icon>
					<span>Payments</span>
				</button>
			</mat-chip>
		</mat-chip-set>
		-->

		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true" [filenameForDownload]="displaydetails.title"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #orderView let-modal>
	<div class="modal-header">
		<div class="modal-title row m-0 p-0" id="modal-title">
			<h4>Items</h4>
		</div>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="orderdetails">
			<div class="table">
				<table class="table table-sm">
					<tr>
						<th>Item #</th>
						<th>Description</th>
						<th class="text-center">Qty</th>
						<th class="text-center">Sales</th>
						<th class="text-center">Cost</th>
					</tr>
					<tr *ngFor="let item of orderdetails">

						<td>
							{{item.stockid}}
						</td>
						<td>{{item.description}}</td>
						<td class="text-center">{{item.Qty}}</td>
						<td class="text-center">{{item.Sales | currency}}</td>
						<td class="text-center">{{item.Cost | currency}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>


<div class="row m-0 p-0" *ngIf="!menuapp">
	<div class="col-md-3 mt-3">
		<mat-form-field appearance="standard">
			<mat-label>Transaction Dates</mat-label>
			<mat-date-range-input [rangePicker]="picker">
				<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
				<input matEndDate [formControl]="todateCtrl" placeholder="End date">
			</mat-date-range-input>
			<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
			<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
			<mat-date-range-picker #picker></mat-date-range-picker>
		</mat-form-field>
	</div>
	<div class="col-md-3 mt-3">
		<mat-form-field appearance="standard" >
			<mat-label>Brand</mat-label>
			<mat-select [formControl]="brands" multiple #brandFilter>
				<div class="select-all" (click)="selectAllToggle('brand')">
					<button mat-icon-button>
						<div ml="1">Select All/None</div>
					</button>
				</div>
				<mat-option *ngFor="let k of properties.brands" [value]="k.manufacturer_id">{{k['name']}}</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="col-md-2 mt-3">
		<mat-form-field appearance="standard" >
			<mat-label>Branches</mat-label>
			<mat-select [formControl]="branchRef" multiple #branchFilter>
				<div class="select-all" (click)="selectAllToggle('branch')">
					<button mat-icon-button>
						<div ml="1">Select All/None</div>
					</button>
				</div>
				<mat-option *ngFor="let k of branches" [value]="k.loccode">{{k.locationname}}</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="col-md-2 mt-3">
		<mat-form-field appearance="standard">
			<mat-label>Consolidated or Branch?</mat-label>
			<mat-select [formControl]="conBrRef">
				<mat-option *ngFor="let v of conbr" [value]="v.value">
					{{v.label}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="col-md-2 mt-3">
		<button mat-raised-button color="accent" class="ml-2 mt-2" (click)="getCPSLS()" *ngIf="!sending">
			<mat-icon>refresh</mat-icon>
		</button>
		<span *ngIf="sending">
			<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</span>
	</div>
</div>
<ng-container *ngIf="orderResults && !menuapp">
	<hr>
	&nbsp;&nbsp; <button class="btn btn-success btn-sm" (click)="$event.preventDefault();exportXls()" *ngIf="orderResults">
		<i class="fa fa-file-excel-o"></i> XLS
	</button>
	&nbsp;&nbsp;
	<button class="btn btn-danger btn-sm" (click)="$event.preventDefault();exportPdf()" *ngIf="orderResults">
		<i class="fa fa-file-pdf-o"></i> PDF
	</button>
	<h3>{{customer.name}} ({{customer.debtorno}}) Product Line Sales Report {{reportCriteria.fromdate | date:'MM/d/yy'}} - {{reportCriteria.todate | date: 'MM/d/yy'}}</h3>
	<h4 class="noprint">
		{{ getTitle() }}
	</h4>
	<div #print_table>

		<div class="table">
			<table class="table table-fixed table-hover" *ngIf="orderResults">
				<thead>
				<tr>
					<th>BR #</th>
					<th>BR Name</th>
					<th>Line ID</th>
					<th>Line Name</th>
					<th>Source</th>
					<th>Quantity</th>
					<th>Web Quantity</th>
					<th>Sales</th>
					<th>Web Sales</th>
				</tr>
				</thead>
				<tbody>
				<ng-container *ngFor="let o of orderResults.lines">
					<tr>
						<td>
							{{ o.BRANCH }}
						</td>
						<td>
							{{ o.BRANCH_NAME }}
						</td>
						<td><a class="link" (click)="viewCustomer(o.LINEID, o.BRANCH)">{{ o.LINEID }}</a></td>
						<td>{{ o.PRDLINE }}</td>
						<td>{{ o.placedfrom }}</td>
						<td>{{ o.OTHER_QTY}}</td>
						<td>{{ o.WEB_QTY }}</td>
						<td>{{ o.OTHER_SALES | currency }}</td>
						<td>{{ o.WEB_SALES | currency }}</td>
					</tr>
				</ng-container>
				<tr>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th>Count : {{ orderResults.totalrecords }}</th>
					<th></th>
					<th>Avg: {{ orderResults.avg | currency }}</th>
					<th>Total: {{ orderResults.total | currency}}</th>
					<th>Web Total: {{ orderResults.total_web | currency}}</th>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</ng-container>

<div class="main card" *ngIf="menuapp">
	<div class="m-auto main-width">
	<div class="row d-flex justify-content-between dotted-divider" *ngIf="!customer">
		<h3> Customer Product Line Sales History Report</h3>&nbsp;<h4 class="padding-t-25">Select a customer</h4>
	</div>
	<ng-container *ngIf="!customer || displayLookup">
		<app-customer-lookup (customer_selected)="selectCustomer($event)" [lookupOnly]="true" [ngClass]="customer ? 'mt-10 dblock' : 'dblock'"></app-customer-lookup>
	</ng-container>
	<div class="row m-0 p-0" *ngIf="customer">
		<div class="col-md-3 mt-3">
			<mat-form-field appearance="standard">
				<mat-label>Transaction Dates</mat-label>
				<mat-date-range-input [rangePicker]="picker">
					<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
					<input matEndDate [formControl]="todateCtrl" placeholder="End date">
				</mat-date-range-input>
				<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-date-range-picker #picker></mat-date-range-picker>
			</mat-form-field>
		</div>
		<div class="col-md-2 mt-3">
			<mat-form-field appearance="standard" >
				<mat-label>Brand</mat-label>
				<mat-select [formControl]="brands" multiple #brandFilter>
					<div class="select-all" (click)="selectAllToggle('brand')">
						<button mat-icon-button>
							<div ml="1">Select All/None</div>
						</button>
					</div>
					<mat-option *ngFor="let k of properties.brands" [value]="k.manufacturer_id">{{k['name']}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-2 mt-3">
			<mat-form-field appearance="standard" >
				<mat-label>Branches</mat-label>
				<mat-select [formControl]="branchRef" multiple #branchFilter>
					<div class="select-all" (click)="selectAllToggle('branch')">
						<button mat-icon-button>
							<div ml="1">Select All/None</div>
						</button>
					</div>
					<mat-option *ngFor="let k of branches" [value]="k.loccode">{{k.locationname}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-2 mt-3">
			<mat-form-field appearance="standard">
				<mat-label>Consolidated or Branch?</mat-label>
				<mat-select [formControl]="conBrRef">
					<mat-option *ngFor="let v of conbr" [value]="v.value">
						{{v.label}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-3 mt-3">
			<span *ngIf="sending">
				<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
					<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			</span>
			<button mat-raised-button class="btn-success btn-height" (click)="$event.preventDefault();exportXls()" *ngIf="orderResults">
				<i class="fa fa-file-excel-o"></i> XLS
			</button>
			&nbsp;
			<button mat-raised-button class="btn-danger btn-height" (click)="$event.preventDefault();exportPdf()" *ngIf="orderResults">
				<i class="fa fa-file-pdf-o"></i> PDF
			</button>
			&nbsp;
			<button mat-raised-button color="primary"  (click)="showCustomerLookup()">{{customer.debtorno}}<mat-icon>person</mat-icon></button>
			&nbsp;
			<button mat-raised-button color="accent" class="mt-2" (click)="getCPSLS()" *ngIf="!sending">
				<mat-icon>refresh</mat-icon>
			</button>
		</div>
	</div>
	<ng-container *ngIf="orderResults">
		<hr>
		<h3>{{customer.name}} (<a href="#/customers/view/{{customer.debtorno}}">{{customer.debtorno}}</a>) Product Line Sales Report {{reportCriteria.fromdate | date:'MM/d/yy'}} - {{reportCriteria.todate | date: 'MM/d/yy'}}</h3>
			<h4>
				{{getTitle()}}
			</h4>
		<div>
			<div class="table">
				<table class="table table-sm table-hover" *ngIf="orderResults">
					<thead>
						<tr>
							<th>BR #</th>
							<th>BR Name</th>
							<th>Line ID</th>
							<th>Line Name</th>
							<th>Source</th>
							<th>Quantity</th>
							<th>Web Quantity</th>
							<th>Sales</th>
							<th>Web Sales</th>
					</thead>
					<tbody>
						<ng-container *ngFor="let o of orderResults.lines">
							<tr>
								<td>
									{{ o.BRANCH }}
								</td>
								<td>
									{{ o.BRANCH_NAME }}
								</td>
								<td><a class="link" (click)="viewCustomer(o.LINEID, o.BRANCH)">{{ o.LINEID }}</a></td>
								<td>{{ o.PRDLINE }}</td>
								<td>{{ o.placedfrom }}</td>
								<td>{{ o.OTHER_QTY}}</td>
								<td>{{ o.WEB_QTY }}</td>
								<td>{{ o.OTHER_SALES | currency }}</td>
								<td>{{ o.WEB_SALES | currency }}</td>
							</tr>
						</ng-container>
						<tr>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th>Count : {{ orderResults.totalrecords }}</th>
							<th></th>
							<th>Avg: {{ orderResults.avg | currency }}</th>
							<th>Total: {{ orderResults.total | currency}}</th>
							<th>Web Total: {{ orderResults.total_web | currency}}</th>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div #print_table style="display: none">
			<div class="table">
				<table class="table table-sm table-hover" *ngIf="orderResults" style="width: 100%;">
					<thead>
						<tr>
							<th style="text-align: center;">BR #</th>
							<th style="text-align: center;">BR Name</th>
							<th style="text-align: center;">Line ID</th>
							<th style="text-align: center;">Line Name</th>
							<th style="text-align: center;">Source</th>
							<th style="text-align: center;">Quantity</th>
							<th style="text-align: center;">Web Quantity</th>
							<th style="text-align: right;">Sales</th>
							<th style="text-align: right;">Web Sales</th>
					</thead>
					<tbody>
						<ng-container *ngFor="let o of orderResults.lines">
							<tr>
								<td style="text-align: center;">
									{{ o.BRANCH }}
								</td>
								<td style="text-align: center;">
									{{ o.BRANCH_NAME }}
								</td>
								<td style="text-align: center;">{{ o.LINEID }}</td>
								<td style="text-align: center;">{{ o.PRDLINE }}</td>
								<td style="text-align: center;">{{ o.placedfrom }}</td>
								<td style="text-align: center;">{{ o.OTHER_QTY}}</td>
								<td style="text-align: center;">{{ o.WEB_QTY }}</td>
								<td style="text-align: right;">{{ o.OTHER_SALES | currency }}</td>
								<td style="text-align: right;">{{ o.WEB_SALES | currency }}</td>
							</tr>
						</ng-container>
						<tr>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th>Count : {{ orderResults.totalrecords }}</th>
							<th></th>
							<th>Avg: {{ orderResults.avg | currency }}</th>
							<th style="text-align: right;">Total: {{ orderResults.total | currency}}</th>
							<th style="text-align: right;">Web Total: {{ orderResults.total_web | currency}}</th>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</ng-container>
	</div>
</div>
