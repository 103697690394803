<div *ngIf="discrepancy == 'inactive'">
	<div class="container-fluid">
		<form [formGroup]="sheetForm" *ngIf="sheetForm" class="no-overflow" (ngSubmit)="createLoadSheets()">
			<div class="row">
				<div class="col-md-3">
					<mat-form-field appearance="standard">
						<mat-label>Warehouse</mat-label>
						<mat-select formControlName="location">
							<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
								{{ loc.locationname }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-3">
					<app-vendor-auto-complete (vendor_selected)="selectVendor($event)"></app-vendor-auto-complete>
				</div>
				<div class="col-md-6">

				</div>
				<div class="col-md-6">
				</div>
			</div>
			<div class="row">

			</div>
		</form>


		<!-- <div class="row" *ngIf="unique_vendors" >
			<div class="col-3" *ngFor="let vendor of unique_vendors | keyvalue">
				{{vendor.value}} : {{ vendorTotals(vendor.value) }}
			</div>
		</div> -->
		<div class="text-right" *ngIf="fetching">
			<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</div>
		<div class="table mt-3" *ngIf="recitems">
			<table class="table table-bordered table-fixed table-repsonsive table-fluid w-100">
				<thead>
					<tr>
						<!-- <th class="text-center">LOCATION</th> -->
						<th>INDEX</th>
						<th>ITEM</th>
						<!-- <th class="text-center">DATE</th> -->
						<th class="text-center">VENDOR</th>
						<th class="text-center">BIN</th>
						<!-- <th class="text-center">QTY RECEIVED</th> -->
						<th class="text-center max-200">QTY RECEIVED</th>
						<th class="text-center">MIN</th>
						<th class="text-center">MAX</th>
						<th class="text-center">QOH</th>
						<!-- <th class="text-center">#PO's</th> -->
						<th class="text-center">QOO</th>
						<th class="text-center">QOR</th>
						<th class="text-center">COMMITED</th>
						<th class="text-center">STATUS</th>
						<th class="text-center max-100"></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngIf="!recitems.length">
						<td colspan="13" class="text-center">No Items Scanned</td>
					</tr>
					<tr *ngFor="let i of recitems; let itemIndex = index;">
						<td class="text-center">{{itemIndex + 1}}</td>
						<!-- <td class="text-center">{{ i.loccode }}</td> -->
						<td class="text-bigger"><b>{{ i.stockid }}</b> {{ i.description }} <span *ngIf="i.stockid === 'MST'"><br> #{{i.ponumber}}</span></td>
						<!-- <td class="text-center">{{ i.date_rec | date }}</td> -->
						<td class="text-center">{{ i.vendor }} </td>
						<td class="text-center">
							<input matInput type="text" value="{{ i.bin }}" class="input border-1 text-right qtyinput" (input)="updateBin($event.target.value, i )">
						</td>
						<!-- <td class="text-center bolder">{{ i.qty }}</td> -->
						<td class="text-center max-200">
							<input matInput type="text" value="{{ i.qty }}" class="input border-1 text-right qtyinput bolder" (keyup)="updateRec($event.target.value, i )">
						</td>
						<td class="text-center">{{ i.minmax.minimum }}</td>
						<td class="text-center">{{ i.minmax.maximum }}</td>
						<td class="text-center">{{ i.qoh }}</td>
						<!-- <td class="text-center">{{ i.total_pos }}</td> -->
						<td class="text-center" *ngIf="i.in_out == 0" [ngClass]="{
						'bg-success white bolder': i.qty == i.qop,
						'bg-warning bolder text-dark': i.qty < i.qop && i.qty > 0,
						'bg-danger white bolder': i.qty > i.qop && i.qty > 0,
						'rga_color white' : i.qty != i.qop && i.qty < 0}">
							<span *ngIf="i.qty == i.qop" class="white bolder">{{ i.qop }}</span>
							<span *ngIf="i.qty != i.qop">
								<!-- <span *ngIf="i.qty < 0" class="white">RGA</span> -->
								<span *ngIf="i.qty > 0 && i.qty < i.qop">{{ i.qop }}</span>
								<span *ngIf="i.qty > 0 && i.qty > i.qop" class="white">{{ i.qop }}</span>
							</span>
						</td>
						<td class="text-center" *ngIf="i.in_out == 1">
							{{ i.qop }}
						</td>
						<td class="text-center">
							<span *ngIf="i.qor != ''">{{i.qor}}</span>
							<span *ngIf="i.qor == ''">0</span>
						</td>
						<td class="text-center">{{i.qos}}</td>
						<td class="text-center" *ngIf="i.in_out == 0" [ngClass]="{
						'bg-success white bolder': i.total_rec == i.qop || (i.total_rec == i.qos && i.total_rec < i.qop) ,
						'bg-warning bolder': i.total_rec < i.qop && i.total_rec > 0 && i.total_rec != i.qos,
						'bg-danger white bolder': i.total_rec > i.qop && i.total_rec > 0,
						'rga_color white' : i.total_rec != i.qop && i.total_rec < 0}">
							<span *ngIf="i.total_rec == i.qop" class="white bolder">OK</span>
							<span *ngIf="i.total_rec != i.qop">
								<!-- <span *ngIf="i.qty < 0" class="white">RGA</span> -->
								<span *ngIf="i.total_rec > 0 && i.total_rec < i.qop">{{ i.qty }} / {{ i.qop }}</span>
								<span *ngIf="i.total_rec > 0 && i.total_rec > i.qop" class="white">{{ i.total_rec }} / {{ i.qop }}</span>
							</span>
						</td>

						<td class="text-center" *ngIf="i.in_out == 1" [ngClass]="{
							'rga_color': i.qty > 0,
							'success-border white bolder': i.qty == i.qor,
							'warning-border bolder': i.qty < i.qor && i.qty > 0,
							'danger-border white bolder': i.qty > i.qor && i.qty > 0}">
							<!-- 'rga_color white' : i.qty != i.qop && i.qty < 0}"> -->
							<span *ngIf="i.qty == i.qor" class="white bolder">OK</span>
							<span *ngIf="i.qty != i.qor">
								<!-- <span *ngIf="i.qor > 0" class="white">RGA</span> -->
								<span *ngIf="i.qty > 0 && i.qty <= i.qor"> <span class="warning-text">{{ i.qty }}</span>
									<span class="white"> / {{ i.qor }}</span></span>
								<span *ngIf="i.qty > 0 && i.qty > i.qor"><span class="danger-text">{{ i.qty }}</span>
									<span class="white"> / {{ i.qor }}</span></span>
							</span>
						</td>

						<td class="text-center max-100">

							<button class="btn btn-sm btn-danger" (click)="$event.preventDefault();removeItem(i);"><i class="fa fa-remove"></i></button>
							<!-- &nbsp;&nbsp;
							<button class="btn btn-sm" (click)="$event.preventDefault();saveRec(i);">
								<i class="fa fa-save"></i>
							</button> -->
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>



<div *ngIf="discrepancy == 'active' && discrepancy_vendors" style="margin-top: 80px; margin-left: 5px ;">
	<div class="container-fluid">
		<div class="main-content main">
			<div class="mat-elevation-z2 report-div">
				<div class="row">
					<div class="table mt-3" *ngIf="validScans" id="reviewTable">
						<span class="vendor-label">VENDORS TO FINALIZE: </span>
						<div *ngIf="discrepancy_vendors.length > 1" class="vendor-container" [formGroup]="vendor_fg">
							<div *ngFor="let vendor of discrepancy_vendors">
								<mat-checkbox formControlName="{{vendor}}">{{vendor}}</mat-checkbox>
							</div>
						</div>
						<div class="display: flex; flex-direction: column">
							<div class="validHeader">
								<div style="display: flex; flex-direction: row; justify-content: space-between;">
									<h2 class="discrepancyH2">&nbsp;Issues</h2>
								</div>
							</div>
							<!-- invalid scan discrepancy table -->
							<div class="invalidTable">
								<table class="table table-bordered table-fixed table-repsonsive table-fluid w-100 discrepancyTable" *ngIf="invalidScans.length">
									<thead>
										<tr>
											<th>ITEM</th>
											<th class="text-center">VENDOR</th>
											<th class="text-center max-200">QTY RECEIVED</th>
											<th class="text-center">MIN</th>
											<th class="text-center">MAX</th>
											<th class="text-center">QOH</th>
											<th class="text-center">QOO</th>
											<th class="text-center">QOR</th>
											<th class="text-center">COMMITED</th>
											<th class="text-center">STATUS</th>
											<th class="text-center max-100"></th>
										</tr>
									</thead>
									<tbody>
										<tr *ngIf="!invalidScans.length">
											<td colspan="13" class="text-center">No Items Scanned</td>
										</tr>
										<tr *ngFor="let i of invalidScans; let itemIndex = index;">
											<ng-container *ngIf="(i.qty == i.qop || i.qty == i.qor || i.total_need_filled == itemTotalReceived(i)) ? false : true">
												<td class="text-bigger"><b>{{ i.stockid }}</b> {{ i.description }} <span *ngIf="i.stockid === 'MST'"><br> #{{i.ponumber}}</span></td>
												<td class="text-center">{{ i.vendor }} </td>
												<td class="text-center max-200"><input matInput type="text" value="{{ i.qty }}" class="input border-1 text-right qtyinput bolder" (keyUpCheck)="updateRec($event.target.value, i ); filterScans()" appWaitType></td>
												<td class="text-center">{{ i.minmax.minimum }}</td>
												<td class="text-center">{{ i.minmax.maximum }}</td>
												<td class="text-center">{{ i.qoh }}</td>

												<td class="text-center" *ngIf="i.in_out == 0" [ngClass]="{
												'bg-success white bolder': i.qty == i.qop,
												'bg-warning bolder text-dark': i.qty < i.qop && i.qty > 0,
												'bg-danger white bolder': i.qty > i.qop && i.qty > 0,
												'rga_color white' : i.qty != i.qop && i.qty < 0}">
													<span *ngIf="i.qty == i.qop" class="white bolder">{{ i.qop }}</span>
													<span *ngIf="i.qty != i.qop">
														<span *ngIf="i.qty > 0 && i.qty < i.qop">{{ i.qop }}</span>
														<span *ngIf="i.qty > 0 && i.qty > i.qop" class="white">{{ i.qop }}</span>
													</span>
												</td>

												<td class="text-center" *ngIf="i.in_out == 1">
													{{ i.qop }}
												</td>

												<td class="text-center">
													<span *ngIf="i.qor != ''">{{i.qor}}</span>
													<span *ngIf="i.qor == ''">0</span>
												</td>

												<td class="text-center">{{i.qos}}</td>

												<td class="text-center" *ngIf="i.in_out == 0" [ngClass]="{
												'bg-success white bolder': i.qty == i.qop,
												'bg-warning bolder': i.qty < i.qop && i.qty > 0,
												'bg-danger white bolder': i.qty > i.qop && i.qty > 0,
												'rga_color white' : i.qty != i.qop && i.qty < 0}">
													<span *ngIf="i.qty == i.qop" class="white bolder">OK</span>
													<span *ngIf="i.qty != i.qop">
														<span *ngIf="i.qty > 0 && i.qty < i.qop">{{ i.qty }} / {{ i.qop }}</span>
														<span *ngIf="i.qty > 0 && i.qty > i.qop" class="white">{{ i.qty }} / {{ i.qop }}</span>
													</span>
												</td>

												<td class="text-center" *ngIf="i.in_out == 1" [ngClass]="{
												'rga_color': i.qty > 0,
												'success-border white bolder': i.qty == i.qor,
												'warning-border bolder': i.qty < i.qor && i.qty > 0,
												'danger-border white bolder': i.qty > i.qor && i.qty > 0}">
													<span *ngIf="i.qty == i.qor" class="white bolder">OK</span>
													<span *ngIf="i.qty != i.qor">
														<span *ngIf="i.qty > 0 && i.qty < i.qor">
															<span class="warning-text">{{ i.qty }}</span>
															<span class="white"> / {{ i.qor }}</span>
														</span>
														<span *ngIf="i.qty > 0 && i.qty > i.qor">
															<span class="danger-text">{{ i.qty }}</span>
															<span class="white"> / {{ i.qor }}</span>
														</span>

													</span>
												</td>

												<td class="text-center max-100">

													<button class="btn btn-sm btn-danger" (click)="$event.preventDefault();removeItem(i);"><i class="fa fa-remove"></i></button>
												</td>
											</ng-container>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<!-- valid scans discrepancy table -->
						<ng-container *ngIf="validScans.length > 0">

							<div class="display: flex; flex-direction: column">

								<div class="validHeader">
									<div style="display: flex; flex-direction: row; justify-content: space-between;">
										<h2 class="discrepancyH2">&nbsp;Valid Scans</h2>
										<a mat-raised-button (click)="toggleValidTable()" *ngIf="showValidTable">Hide</a>
										<a mat-raised-button (click)="toggleValidTable()" *ngIf="!showValidTable">Show</a>
									</div>
								</div>

								<div class="validTable">

									<table *ngIf="showValidTable" class="table table-bordered table-fixed table-repsonsive table-fluid w-100 discrepancyTable">
										<thead>
											<tr>
												<th>ITEM</th>
												<th class="text-center">VENDOR</th>
												<th class="text-center max-200">QTY RECEIVED</th>
												<th class="text-center">MIN</th>
												<th class="text-center">MAX</th>
												<th class="text-center">QOH</th>
												<th class="text-center">QOO</th>
												<th class="text-center">QOR</th>
												<th class="text-center">COMMITED</th>
												<th class="text-center">STATUS</th>
												<th class="text-center max-100"></th>
											</tr>
										</thead>
										<tbody>
											<tr *ngIf="!validScans.length">
												<td colspan="13" class="text-center">No Valid Scanned Items</td>
											</tr>
											<tr *ngFor="let i of validScans; let itemIndex = index;">
												<ng-container *ngIf="(i.qty == i.qop || i.qty == i.qor || i.total_need_filled == itemTotalReceived(i));">
													<td class="text-bigger">
														<b>{{ i.stockid }}</b> {{ i.description }}<span *ngIf="i.stockid === 'MST'">
															<br> #{{i.ponumber}}</span>
													</td>
													<td class="text-center">{{ i.vendor }} </td>
													<td class="text-center max-200"><input matInput type="text" value="{{ i.qty }}" class="input border-1 text-right qtyinput bolder" (keyUpCheck)="updateRec($event.target.value, i ); filterScans()" appWaitType></td>
													<td class="text-center">{{ i.minmax.minimum }}</td>
													<td class="text-center">{{ i.minmax.maximum }}</td>
													<td class="text-center">{{ i.qoh }}</td>

													<td class="text-center" *ngIf="i.in_out == 0" [ngClass]="{
																'bg-success white bolder': i.qty == i.qop,
																'bg-warning bolder text-dark': i.qty < i.qop && i.qty > 0,
																'bg-danger white bolder': i.qty > i.qop && i.qty > 0,
																'rga_color white' : i.qty != i.qop && i.qty < 0}">
														<span *ngIf="i.qty == i.qop" class="white bolder">{{ i.qop }}</span>
														<span *ngIf="i.qty != i.qop">
															<span *ngIf="i.qty > 0 && i.qty < i.qop">{{ i.qop }}</span>
															<span *ngIf="i.qty > 0 && i.qty > i.qop" class="white">{{ i.qop }}</span>
														</span>
													</td>

													<td class="text-center" *ngIf="i.in_out == 1">
														{{ i.qop }}
													</td>

													<td class="text-center">
														<span *ngIf="i.qor != ''">{{i.qor}}</span>
														<span *ngIf="i.qor == ''">0</span>
													</td>

													<td class="text-center">{{i.qos}}</td>

													<td class="text-center" *ngIf="i.in_out == 0" [ngClass]="{
																'bg-success white bolder': ((i.qty == i.qop) || (i.total_rec == i.total_need_filled)),
																'bg-warning bolder': i.qty < i.qop && i.qty > 0 ,
																'bg-danger white bolder': i.qty > i.qop && i.qty > 0,
																'rga_color white' : i.qty != i.qop && i.qty < 0}">
														<span *ngIf="i.qty == i.qop || i.total_rec == i.total_need_filled" class="white bolder">OK</span>
														<span *ngIf="i.qty != i.qop">
															<span *ngIf="i.total_rec > 0 && i.total_rec < i.qop">{{ i.qty }} / {{ i.qop }}</span>
															<span *ngIf="i.total_rec > 0 && i.total_rec > i.qop" class="white">{{ i.total_rec }} / {{ i.qop }}</span>
														</span>
													</td>

													<td class="text-center" *ngIf="i.in_out == 1" [ngClass]="{
																	'rga_color': i.qty > 0,
																	'success-border white bolder': i.qty == i.qor,
																	'warning-border bolder': i.qty < i.qor && i.qty > 0,
																	'danger-border white bolder': i.qty > i.qor && i.qty > 0}">
														<span *ngIf="i.qty == i.qor" class="white bolder">OK</span>
														<span *ngIf="i.qty != i.qor">
															<span *ngIf="i.qty > 0 && i.qty < i.qor"> <span class="warning-text">{{ i.qty }}</span><span class="white"> / {{ i.qor }}</span></span>
															<span *ngIf="i.qty > 0 && i.qty > i.qor"><span class="danger-text">{{ i.qty }}</span><span class="white"> / {{ i.qor }}</span></span>


														</span>
													</td>

													<td class="text-center max-100">
														<button class="btn btn-sm btn-danger" (click)="$event.preventDefault();removeItem(i); "><i class="fa fa-remove"></i></button>
													</td>
												</ng-container>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</ng-container>


					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<nav class="navbar fixed-bottom navbar-dark bg-dark justify-content-right">
	<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%;">
		<a mat-raised-button *ngIf="discrepancy == 'active'" (click)="discrepancyFlip()" id="complete-buttons" style="margin-left: 5%; height: 30px; line-height: 30px;">Back</a>
		<div class="ml-auto nav-item" id="complete-buttons">
			Committed: <span *ngIf="recitems">{{ totalCommited() }}</span>
			<span *ngIf="!recitems">0</span>
		</div>
		<div class="ml-auto nav-item" id="complete-buttons">
			Receiving: <span *ngIf="recitems">{{ totalScannedRec() }}</span>
			<span *ngIf="!recitems">0</span>
		</div>
		<div class="ml-auto nav-item" id="complete-buttons">
			On PO: <span *ngIf="recitems">{{ totalForPo() }}</span>
			<span *ngIf="!recitems">0</span>
		</div>
		<div class="ml-auto nav-item" id="complete-buttons">
			Require PO: <span *ngIf="recitems">{{ totalReqPo() }}</span>
			<span *ngIf="!recitems">0</span>
		</div>
		<div class="ml-auto nav-item" id="complete-buttons">
			Sending: <span *ngIf="recitems">{{ totalScannedSend() }}</span>
			<span *ngIf="!recitems">0</span>
		</div>
		<div class="ml-auto nav-item" id="complete-buttons">
			On RGA: <span *ngIf="recitems">{{ totalForRga() }}</span>
			<span *ngIf="!recitems">0</span>
		</div>
		<div class="ml-auto nav-item" id="complete-buttons">
			Require RGA: <span *ngIf="recitems">{{ totalReqRga() }}</span>
			<span *ngIf="!recitems">0</span>
		</div>
		<div class="ml-auto nav-item" *ngIf="discrepancy == 'inactive'">
			<button class="btn btn-danger btn-sm" n-submit (click)="$event.preventDefault();discrepancyFlip();" *ngIf="!submiting">
				<!--took out finalizeRec() -> move to end of discrepancy screen-->
				Start Discrepancy
			</button>
		</div>

		<a mat-raised-button *ngIf="discrepancy == 'active' && !submiting" (click)=" finalizeRec(); mailDiscrepancy();" style="float: right; margin-right: 1%; background: rgb(94, 85, 218); color: white;margin-left: 5%; height: 30px; line-height: 30px;">Finalize</a>
		<!--mailDiscrepancy();-->

		<span *ngIf="submiting">
			<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</span>

	</div>
</nav>
