import { Component, OnDestroy, OnInit,ViewChild,ChangeDetectorRef ,AfterViewInit,ElementRef} from '@angular/core';
import {NestedTreeControl, CdkTreeModule} from '@angular/cdk/tree';
import {ArrayDataSource} from '@angular/cdk/collections';
import {Injectable} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {BehaviorSubject, Observable, of as observableOf} from 'rxjs';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import { SelectionModel } from '@angular/cdk/collections';
import { NestableModule} from 'ngx-nestable';
import * as FileSaver from 'file-saver';
import { SystemService } from '../../../services/system.service'
import { InventoryService } from '../../../services/inventory.service'
import { UntypedFormBuilder, Validators, UntypedFormControl, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { DropzoneComponent, DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})

export class CategoriesComponent implements OnInit {

	@ViewChild("subCatModal") subCatRef: ElementRef;
	@ViewChild("assignModal") assignRef: ElementRef;
	options: any = {
    fixedDepth: true
	};
	catname: any = new UntypedFormControl('');
	mfgs: any = new UntypedFormControl('');
	pls: any = new UntypedFormControl('');
	cats: any = new UntypedFormControl('');
	prodsrch: any = new UntypedFormControl('');
	manus: any = [];
	stockcats: any = [];
	prodlines: any = [];
	exclusions: any = [];
	items: any = [];
	resultitems: any = [];
	adding: any = false;
	list: any = [];
	editcat: any = '';
	dropevent: any = '';
	files: File[] = [];
	uploadresults: any = [];

   constructor(private fb: UntypedFormBuilder, private systemService: SystemService, private modalService: NgbModal, private inventoryService: InventoryService) {
  }


  ngOnInit(): void {
  this.systemService.getWebCategories().subscribe(v => { this.list = v;

  });
  this.inventoryService.getItemProductlines().subscribe(v => { this.prodlines = v; });
  this.inventoryService.getItemManufacturer().subscribe(v => { this.manus =  v; });
  this.inventoryService.getItemCategories().subscribe(v => { this.stockcats =  v; });
  }

  addSubCat(cat){
  this.editcat = cat;
  this.modalService.open(this.subCatRef, { ariaLabelledBy: 'modal-title', size: 'md' }).result.then((result) => {

		}, (reason) => {

		});
  }
  addCat(){
	this.adding = true;
	if(this.editcat != []){
	let data = { parent_id: this.editcat.id, name: this.catname.value };
	this.systemService.addWebCategory(data).subscribe(v => { this.list = v.cats; });
	} else {
	let data = {name: this.catname.value};
	this.systemService.addWebCategory(data).subscribe(v => { this.list = v.cats; });
	}
	this.modalService.dismissAll();
	this.editcat = [];

  }

  assign(cat){
  this.editcat = cat;
	this.systemService.getCategoryItems(this.editcat).subscribe( (result) => {

		this.items = result.items;
		this.resultitems = [];
		this.exclusions = [];
	this.modalService.open(this.assignRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {
			this.editcat = [];
		});

	});
  }
  removeItem(item,stockid){
	 let data = {catid: this.editcat.id, stockid: stockid };
	 this.systemService.removeCategoryItems(data).subscribe( (result) => {
	 if(result.success){
     delete this.items[item];
	 } });

  }

  exclude(item){
	this.exclusions.push(item);
	let index = this.resultitems.indexOf(item);

	this.resultitems.splice(index, 1);
  }
  productSearch(){

	let data = { manufacturers: this.mfgs.value, productlines: this.pls.value, categories: this.cats.value, exlusions: this.exclusions, keywords: this.prodsrch.value};
	this.systemService.getCategoryItemSearch(data).subscribe( (result) => {
	this.resultitems = result;
	});
  }
  saveProducts(){
	let stockids = [];
	this.resultitems.forEach((i) => {

	stockids.push(i.stockid);
	});
	let data = {stockids: stockids, catid: this.editcat.id };

	this.systemService.addCategoryItems(data).subscribe( (result) => {

		this.items = result.items;
		this.resultitems = [];
		this.exclusions = [];
	});
  }
  changeArray(e: any){
		if(this.adding){
		this.adding = false;
		} else {
	 this.systemService.updateCategoryList({data: this.list}).subscribe( (result) => {
	 });
		}
  }

  changeAllowed(item: any, e: any){
  var found = 0;
  this.list.forEach((k) => {

	if(k.id == item){
		if (e.checked == true)
		{
		k.isallowed = 1;
		} else {
		k.isallowed = 0;
		}
		found = 1;
	  }
	if (found == 0 && k.children.length > 0){
		this.changeAllowedHelper(item,e,k.children);
	}
  });

  this.changeArray(e);
  }

  changeAllowedHelper(item: any, e: any, list: any){
  var found = 0;
  list.forEach((k) => {

	  if(k.id == item){
		if (e.checked == true)
		{
		k.isallowed = 1;
		} else {
		k.isallowed = 0;
		}
		found = 1;
	  }
	  if(k.children){
	  if (found == 0 && k.children.length > 0){
		this.changeAllowedHelper(item,e,k.children);
	  }
	  }
  });
  }

  onUploadError(event: any) {

	}

	onUploadSuccess(event: any) {

	}

	onSelect(cat: any, event: any) {
		this.files.push(...event.addedFiles);
		for (var i = 0; i < this.files.length; i++) {
			this.readFile(this.files[i]).then(fileContents => {
				//stuff

				let upload = {
					content: fileContents,
					name: event.addedFiles[0].name,
					mime: event.addedFiles[0].type,
				}

				this.systemService.uploadCatFile(upload, cat).subscribe((results: any) => {
					this.files = [];
					this.systemService.getWebCategories().subscribe(v => { this.list = v;
					});
				});

			});
		}
	}

	private async readFile(file: File): Promise < string | ArrayBuffer > {
		return new Promise < string | ArrayBuffer > ((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = e => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = e => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				return reject(null);
			}
			reader.readAsDataURL(file);
		});

		this.files = [];
	}
}
