<ng-template #confirmPdf>
	<h2 matDialogTitle>CLOSE JOUNRAL?</h2>
	<mat-dialog-content>
		<p>Close Journal <code>{{journal_review.value.journal_no }} {{ this.journal_review.value.desc1}} </code>?</p>
	</mat-dialog-content>
	<mat-dialog-actions align="end">

		<button mat-button matDialogClose color="accent" (click)="pdfJournal()">NO</button>
		<button mat-button matDialogClose color="primary" (click)="pdfJournalClose()">YES</button>

	</mat-dialog-actions>
</ng-template>


<ng-template #refundablesForm let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Refundable Transactions</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="card">
		<div class="row">
			<div class="card-body">
				<div class="card-header text-center">
					<h4 class="card-title text center">Refundable Transactions</h4>
				</div>

				<div class="col-md-12">
					<div class="row">
						<div class="col-md-8">
							<label>Click on a row to select it</label>
						</div>
						<div class="col-md-4 ml-auto mr-0">
							<mat-form-field appearance="standard" class="text-right">
								<mat-label>Search</mat-label>
								<input tabindex="1" matInput value="" (input)="onRefundableSearchInput()" [formControl]="refundablePaymentSearch" class="text-right" autofocus (keydown.enter)="$event.preventDefault(); onRefundableSearchInput()">
								<span matSuffix>
									<button tabindex="-1" mat-icon-button color="white">
										<mat-icon (click)="onRefundableSearchInput()">search</mat-icon>
									</button>
								</span>
								<mat-progress-bar mode="indeterminate" color="accent" *ngIf="isSearching"></mat-progress-bar>
							</mat-form-field>
						</div>
					</div>
				</div>

				<table mat-table [dataSource]="filteredRefundablePayments">
					<ng-container matColumnDef="response_id">
						<th mat-header-cell *matHeaderCellDef>ID</th>
						<td mat-cell *matCellDef="let refundable"> {{ refundable.response_id }}</td>
					</ng-container>
					<ng-container matColumnDef="transno">
						<th mat-header-cell *matHeaderCellDef>Invoice#</th>
						<td mat-cell *matCellDef="let refundable"> {{ refundable.transno }}</td>
					</ng-container>
					<ng-container matColumnDef="card">
						<th mat-header-cell *matHeaderCellDef>Card</th>
						<td mat-cell *matCellDef="let refundable"> {{ refundable.card }}</td>
					</ng-container>
					<ng-container matColumnDef="trandate">
						<th mat-header-cell *matHeaderCellDef>Date</th>
						<td mat-cell *matCellDef="let refundable"> {{ refundable.trandate | date:'MM/dd/Y' }}</td>
					</ng-container>
					<ng-container matColumnDef="response_approved.total">
						<th mat-header-cell *matHeaderCellDef>Total</th>
						<td mat-cell *matCellDef="let refundable"> {{ refundable.response_approved.total | number:'1.2-2'}}</td>
					</ng-container>
					<ng-container matColumnDef="response_approved.status">
						<th mat-header-cell *matHeaderCellDef>Status</th>
						<td mat-cell *matCellDef="let refundable"> {{ refundable.response_approved.status }}</td>
					</ng-container>
					<ng-container matColumnDef="age">
						<th mat-header-cell *matHeaderCellDef>Age</th>
						<td mat-cell *matCellDef="let refundable" class="text-right" [ngClass]="{
						'bg-danger text-white' : !refundable.canprocess,
						'bg-warning text-dark': refundable.age > 50 && refundable.canprocess
						}"> {{ refundable.age }}</td>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="refundableColumns; sticky: true;"></tr>
					<tr mat-row *matRowDef="let row; let i = index; columns: refundableColumns" (click)="selectRefundable(row, i)" [ngClass]="{'highlight': refundableSelectIndex == i}"></tr>
				</table>
			</div>
		</div>
	</div>
</ng-template>


<form [formGroup]="paymentForm" *ngIf="paymentForm" class="m-0 p-0">
	<div class="row text-dark m-0 p-0 " *ngIf="customer">
		<ng-container>
			<div class="col-md-3">
				<mat-form-field appearance="standard">
					<mat-label>Posting Date</mat-label>
					<input matInput [value]="today" (dateChange)="updateDayPayMents($event.value)" formControlName="datepaid" [matDatepicker]="paydatepicker">
					<mat-datepicker-toggle matSuffix [for]="paydatepicker"></mat-datepicker-toggle>
					<mat-datepicker #paydatepicker></mat-datepicker>
				</mat-form-field>
			</div>
			<div class="col-md-3">
				<mat-form-field appearance="standard">
					<mat-label>Hold Status</mat-label>
					<mat-select [formControl]="holdReason" (selectionChange)="updateHoldReason()">
						<mat-option *ngFor="let h of holdreasons" [value]="h.reasoncode">
							{{ h.reasondescription }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-3">
				<mat-form-field appearance="standard">
					<mat-label>Memo</mat-label>
					<input type="text" class="text-right mt-0 pt-0" placeholder="Reference" formControlName="description" aria-label="Reference" matInput (keydown.enter)="$event.preventDefault();" (keyup.enter)="$event.preventDefault();" />
				</mat-form-field>
			</div>
			<div class="col-md-3">
				<mat-form-field appearance="standard">
					<mat-label>Discount</mat-label>
					<span matPrefix>$</span>
					<input type="text" formControlName="discount" class="text-right mt-0 pt-0" placeholder="Discount" aria-label="Discount" matInput />
				</mat-form-field>
			</div>

			<div class="col-12">
				<app-customer-aging [aged]="customer.aged" class="w-100"></app-customer-aging>
			</div>
			<mat-divider></mat-divider>

			<div class="col-md-3">
				<mat-slide-toggle class="mt-4 slide" [formControl]="selectInvoiceToPay">Select Invoices To Pay</mat-slide-toggle>
			</div>
			<div class="col-md-3 mt-3 text-center">

			</div>
			<div class="col-md-2 mt-3 text-right">
				<button mat-raised-button n-submit color="accent" (click)="$event.preventDefault();openNewJournal($event)">
					<mat-icon>add</mat-icon> New Journal
				</button>
			</div>
			<div class="col-md-2">
				<mat-form-field appearance="standard">
					<mat-label>Journal Selected</mat-label>
					<mat-select formControlName="journalno">
						<mat-option [value]="''">NO JOURNAL</mat-option>
						<mat-option *ngFor="let cur of openjournals" [value]="cur">
							{{ cur.userid }}:# {{ cur.journal_no }} {{ cur.desc1 }} ({{ cur.journal_date }})
						</mat-option>
					</mat-select>
					<span matSuffix *ngIf="journalaction">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>
				</mat-form-field>
			</div>
			<div class="col-md-2 mt-3 text-left">
				<button mat-raised-button n-submit color="primary" (click)="closeJournal($event)" [disabled]="paymentForm.get('journalno').value == ''">
					<mat-icon>close</mat-icon>
					Close Journal
				</button>
			</div>
			<mat-divider></mat-divider>
			<div class="col-12">
				<mat-drawer-container class="payment-container p-0 w-100" [hasBackdrop]="true" autosize>
					<mat-drawer #drawer [mode]="'side'" class="payment-sidenav">
						<ng-container>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field appearance="standard">
										<mat-label>Discount</mat-label>
										<span matPrefix>$</span>
										<input type="text" formControlName="discount" class="text-right mt-0 pt-0" placeholder="Reference" aria-label="Reference" matInput />
									</mat-form-field>
									<mat-form-field appearance="standard">
										<mat-label>Bank Account</mat-label>
										<mat-select formControlName="bankaccount">
											<mat-option *ngFor="let method of bankaccounts" [value]="method.accountcode">
												{{method.bankaccountname }}
											</mat-option>
										</mat-select>
									</mat-form-field>
									<mat-form-field appearance="standard">
										<mat-label>Currency</mat-label>
										<mat-select formControlName="currency">
											<mat-option *ngFor="let cur of currencies" [value]="cur.currabrev">
												{{cur.currency }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>
						</ng-container>
					</mat-drawer>
					<mat-drawer-content>
						<mat-divider></mat-divider>
						<div class="row  m-0 p-0 ">
							<div class="col-md-12 text-center">
								<h4 class="mt-2 mb-2">Payment Method</h4>
								<mat-button-toggle-group #charge="matButtonToggleGroup" aria-label="Charge" (change)="setChargeType($event)" formControlName="payment_type" color="accent">
									<mat-button-toggle [value]="method.paymentid" [ngClass]="method.paymentid == charge.value ? 'text-light btn-success' : 'text-dark btn-light'" *ngFor="let method of payment_methods" (click)="getPaymentTypeJournal(method)">{{ method.paymentname }}</mat-button-toggle>

									<button mat-raised-button color="accent" (click)="$event.preventDefault();openRefundables();" *ngIf="card_required && config.creditcards">
										Refundable Transactions
									</button>

								</mat-button-toggle-group>
								<div class="alert alert-danger w-80" *ngIf="!paymentForm.get('payment_type').valid">SELECT A PAYMENT TYPE</div>

								<ng-container *ngIf="card_required">

									<ng-container *ngIf="config.creditcards">
										<div class="row text-center">
											<mat-button-toggle-group #chargecard="matButtonToggleGroup" formControlName="process_transaction" aria-label="Charge" class="ml-auto mr-auto" (change)="setChargeCardType($event)">
												<mat-button-toggle value="no_charge" [ngClass]="chargecardtype == 'no_charge' ? 'text-white btn-secondary' : 'text-dark btn-light'">
													Do Not Charge
												</mat-button-toggle>

												<mat-button-toggle value="existing_card" [ngClass]="chargecardtype == 'existing_card' ? 'text-white btn-secondary' : 'text-dark btn-light'">Existing Card</mat-button-toggle>
												<mat-button-toggle value="new_card" [ngClass]="chargecardtype == 'new_card' ? 'text-white btn-secondary' : 'text-dark btn-light'">New Card</mat-button-toggle>

											</mat-button-toggle-group>
										</div>
									</ng-container>
									<div class="row" [ngSwitch]="chargecardtype">
										<div class="col-12 text-center ml-auto mr-auto" *ngIf="sending">
											<mat-progress-bar mode="buffer" class="mt-3"></mat-progress-bar>
										</div>
										<ng-container *ngSwitchDefault>
											<ng-container *ngIf="paymentForm.get('trigger_refund').value">
												<h4 class="text-center ml-auto mr-auto"> Original Card used will be refunded</h4>
											</ng-container>
											<ng-container *ngIf="editingcard && !sending && !paymentForm.get('trigger_refund').value ">
												<form [formGroup]="cardForm" *ngIf="cardForm" (ngSubmit)="addPaymentMethod()" class="col-12 mb-2" autocomplete="off">
													<mat-form-field class="full-width text-right" appearance="standard" color="accent">
														<mat-label>Card Name</mat-label>
														<input matInput placeholder="Card Name" type="text" formControlName="name">
													</mat-form-field>
													<mat-form-field class="full-width text-right" appearance="standard" color="accent">
														<mat-label>Card Number</mat-label>
														<input matInput placeholder="Card Number" type="tel" ccNumber #ccNumber="ccNumber" formControlName="ccnumber">
														<span matSuffix class="scheme">
															&nbsp;<i class="fa fa-cc-{{ccNumber.resolvedScheme$ | async}}"></i>&nbsp;
														</span>
													</mat-form-field>
													<mat-form-field class="full-width text-right" appearance="standard" color="accent">
														<mat-label>Expiration</mat-label>
														<input matInput id="cc-exp-date" type="tel" autocomplete="cc-exp" formControlName="expiry" ccExp>
													</mat-form-field>
													<mat-form-field class="full-width text-right" appearance="standard" color="accent">
														<mat-label>CVV</mat-label>
														<input matInput id="cc-cvc" type="tel" autocomplete="off" formControlName="cvv" ccCVC>
													</mat-form-field>
													<div class="row">
														<div class="col-md-4">
															<button mat-flat-button color="primary" class="mr-2" (click)="editingcard = false;">Cancel & Close</button>
														</div>
														<div class="col-md-4">
															<button mat-flat-button color="warn" class="mr-2" (click)="removeCard($event)">Remove Card</button>
														</div>
														<div class="col-md-4">
															<button mat-flat-button color="accent" class="ml-2" (click)="updateCard($event)">Update Card</button>
														</div>
													</div>
												</form>
											</ng-container>
											<ng-container *ngIf="!editingcard && !paymentForm.get('trigger_refund').value ">
												<div class="col-12 mt-2 text-center">
													<mat-radio-group aria-label="Select a card on file" class="text-left w-100" [formControl]="selected_card">

														<mat-radio-button *ngFor="let card of profile_cards" [value]="card" [checked]="card.default" [color]="'accent'" (change)="updatePaymentType(card.payment_method)" class="ml-2 mr-2">

															<div class="card m-0 p-0" [ngClass]="selected_card.value == card ? 'highlighted' : 'highlight' ">
																<div class="col-12 mb-1 mt-1">
																	<button tabindex="-1" mat-icon-button color="accent" class="m-0 p-0" (click)="editCard($event,card)">
																		<mat-icon class="small-icon m-0 p-0">edit</mat-icon>
																	</button>
																	<b><span [innerHTML]="card.name"></span></b><br>{{ card.number }} Exp: {{ card.expiry_month}}/{{ card.expiry_year}}
																</div>
															</div>
														</mat-radio-button>
													</mat-radio-group>
												</div>
											</ng-container>

										</ng-container>
										<ng-container *ngSwitchCase="'new_card'">
											<div class="col-12 mt-2">
												<form [formGroup]="cardForm" *ngIf="!sending" autocomplete="off">
													<!-- single use card -->
													<mat-slide-toggle [color]="accent" formControlName="savetoprofile" (change)="singleFormSet($event)"> Save To Profile </mat-slide-toggle>
													<mat-form-field class="full-width text-right" appearance="standard" color="accent">
														<mat-label>Card Name</mat-label>
														<input matInput placeholder="Card Name" type="text" formControlName="name">
													</mat-form-field>
													<mat-form-field class="full-width text-right" appearance="standard" color="accent">
														<mat-label>Card Number</mat-label>
														<input matInput placeholder="Card Number" type="tel" ccNumber #ccNumber="ccNumber" formControlName="ccnumber">
														<span matSuffix class="scheme">
															<i class="fa fa-cc-{{ccNumber.resolvedScheme$ | async}}"></i>
														</span>
													</mat-form-field>
													<mat-form-field class="full-width text-right" appearance="standard" color="accent">
														<mat-label>Expiration</mat-label>
														<input matInput id="cc-exp-date" type="tel" autocomplete="cc-exp" formControlName="expiry" ccExp>
													</mat-form-field>
													<mat-form-field class="full-width text-right" appearance="standard" color="accent">
														<mat-label>CVV</mat-label>
														<input matInput id="cc-cvc" type="tel" autocomplete="off" formControlName="cvv" ccCVC>
													</mat-form-field>

													<button tabindex="-1" mat-raised-button color="accent" class="m-0 p-0" (click)="saveCard($event,card)" *ngIf="this.cardForm.get('savetoprofile').value">
														<mat-icon class="">save</mat-icon> Save To Profile
													</button>
												</form>
											</div>
										</ng-container>
										<ng-container *ngSwitchCase="'no_charge'">
											<div class="col-12 text-center">
												<h5 class="mt-2">Do Not Charge</h5>
											</div>
										</ng-container>
									</div>
								</ng-container>
							</div>
						</div>
						<mat-divider></mat-divider>
					</mat-drawer-content>
				</mat-drawer-container>
			</div>
			<mat-divider></mat-divider>
		</ng-container>
		<ng-container *ngIf="!customer">
			<div class="col-12">
				<h5 class="text-center">Select a customer or search by amount / invoice</h5>
			</div>
		</ng-container>
	</div>
	<nav class="navbar fixed-bottom text-dark navbar-dark bg-light border-top justify-content-right mat-elevation-z24">
		<div class="ml-auto nav-item">
			<mat-form-field appearance="standard">
				<mat-label>Check# / Reference#</mat-label>
				<input type="text" class="text-right mt-0 pt-0" placeholder="Reference" formControlName="checkno" aria-label="Reference" matInput (keydown.enter)="$event.preventDefault();" (keyup.enter)="$event.preventDefault();" autofocus #checkno />
			</mat-form-field>
		</div>
		<div class="ml-auto nav-item">
			<mat-form-field appearance="standard" class="text-right">
				<mat-label>Amount</mat-label>
				<span matPrefix>$&nbsp;</span>
				<input type="text" formControlName="amount" class="text-right mt-0 pt-0" placeholder="Reference" aria-label="Reference" matInput moneyPipe appSelectOnFocus (keydown.enter)="$event.preventDefault();" (keyup.enter)="$event.preventDefault();addPayment($event);" />
			</mat-form-field>
		</div>
		<div class="ml-auto mr-auto nav-item">

			<button mat-raised-button n-submit color="accent" class="w-100" [disabled]="!paymentForm.valid" *ngIf="!submitting && paymentForm.get('amount').value" (click)="addPayment($event);">Pay {{ getPaymentTypeName() }}</button>
			<span *ngIf="submitting">
				<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
					<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			</span>
			<mat-progress-bar mode="buffer" class="mt-3" *ngIf="submitting"></mat-progress-bar>
			<button mat-stroked-button n-submit color="accent" class="w-100" (click)="$event.preventDefault();addAllocations()" *ngIf="allocations_from.length && !paymentForm.get('amount').value">Apply</button>
		</div>
		<div class="ml-auto mr-auto nav-item max-right">
			<ul class="entry-list p-0 m-0">
				<li>
					<div class="spec-label">PAYMENT:</div>
					<div class="spec-value">
						<span *ngIf="allocation_from_total">{{ allocation_from_total * -1 | currency }}</span>
						<span *ngIf="!allocation_from_total">$0.00</span>
					</div>
					<div class="spec-label">SELECTED:</div>
					<div class="spec-value">
						{{ allocation_to_total | currency }}
					</div>
				</li>
				<li>
					<div class="spec-label">PAY:</div>
					<div class="spec-value">
						<span [ngClass]="{'text-danger': (paymentTotals() >= 0.00),'text-success': (paymentTotals() < 0.00) }">{{ paymentTotals() * -1 | currency }}</span>
					</div>
					<div class="spec-label">DUE:</div>
					<div class="spec-value">
						{{ customerBalanceReduce() | currency }}
					</div>
				</li>
			</ul>
			<!--
			<ul class="entry-list p-0 m-0">
				 <li>
					<div class="spec-label">APPLY AMT:</div>
					<div class="spec-value">
						<span *ngIf="allocation_from_total">{{ allocation_from_total * -1 | currency }}</span>
						<span *ngIf="!allocation_from_total">$0.00</span>
					</div>

					<div class="spec-label">+/-:</div>
					<div class="spec-value" [ngClass]="{'text-danger': (overShort() >= 0.00),'text-success': (overShort() < 0.00) }">
						{{ overShort() | currency }}
					</div>

				</li>


				<li class="w-100">
					<div class="spec-label">INVOICE AMT:</div>
					<div class="spec-value">
						{{ allocation_to_total | currency }}
					</div>
				</li>

				<li class="w-100">
					<div class="spec-label">APPLY AMT:</div>
					<div class="spec-value">
						<span [ngClass]="{'text-danger': (paymentTotals() >= 0.00),'text-success': (paymentTotals() < 0.00) }">
						{{ allocation_from_total * -1 | currency }}</span>
					</div>
				</li>
				<li class="w-100">
					<div class="spec-label">DUE:</div>
					<div class="spec-value">
						{{ customerBalanceReduce() | currency }}
					</div>
				</li>
			</ul>
			-->
		</div>
	</nav>

	<!-- <ng-container>
		<div class="fixed-plugin mat-elevation-z24" [ngClass]="flip != 'inactive' ? 'd-none' : ''">
			<div class="dropdown show-dropdown text-center cart-fixed">
				<div class="bg-white  smaller p-2">
					<mat-slide-toggle [formControl]="selectInvoiceToPay">Select Invoice To Pay</mat-slide-toggle>
					<mat-form-field appearance="standard">
						<mat-label>Check# / Reference#</mat-label>
						<input type="text" class="text-right mt-0 pt-0" placeholder="Reference" formControlName="checkno" aria-label="Reference" matInput (keydown.enter)="$event.preventDefault();" (keyup.enter)="$event.preventDefault();" autofocus #checkno />
					</mat-form-field>
					<mat-form-field appearance="standard">
						<mat-label>Amount</mat-label>
						<span matPrefix>$&nbsp;</span>
						<input type="text" formControlName="amount" class="text-right mt-0 pt-0" placeholder="Reference" aria-label="Reference" matInput moneyPipe appSelectOnFocus (keydown.enter)="$event.preventDefault();" (keyup.enter)="$event.preventDefault();addPayment($event);" />
					</mat-form-field>


					<ul class="entry-list p-0 m-0">
						<li>
							<div class="spec-label">PAYMENT:</div>
							<div class="spec-value">
								<span *ngIf="allocation_from_total">{{ allocation_from_total * -1 | currency }}</span>
								<span *ngIf="!allocation_from_total">$0.00</span>
							</div>
						</li>
						<li>
							<div class="spec-label">SELECTED:</div>
							<div class="spec-value">
								{{ allocation_to_total | currency }}
							</div>
						</li>
						<li>
							<div class="spec-label">PAY BALANCE:</div>
							<div class="spec-value">
								<span [ngClass]="{'text-danger': (paymentTotals() >= 0.00),'text-success': (paymentTotals() < 0.00) }">{{ paymentTotals() * -1 | currency }}</span>
							</div>
						</li>
						<li>
							<div class="spec-label">AR BALANCE:</div>
							<div class="spec-value">
								{{ customerBalanceReduce() | currency }}
							</div>
						</li>
					</ul>

					<button mat-raised-button color="accent" class="w-100" *ngIf="!submitting && paymentForm.get('amount').value">Create Payment</button>
					<mat-spinner class="ml-auto mr-auto smaller" *ngIf="submitting"></mat-spinner>

					<button mat-stroked-button color="accent" class="w-100" (click)="addAllocations()" *ngIf="allocations_from.length && !paymentForm.get('amount').value">Apply Payments</button>
				</div>
				<a href="#" data-toggle="dropdown" aria-expanded="false" class="text-white">
				</a>



				<ul class="dropdown-menu cart-fixed-content" x-placement="bottom-start" id="rightcart">
					<li class="header-title">Current Order

					</li>
					<ng-container>


						<div class="container text-right" id="rightcart-content">
							<ng-container *ngIf="showrefunds">
								<div class="col-12">
									List of refundable payments
								</div>
							</ng-container>
						</div>
						<div class="container text-right" id="rightcart-content">


						</div>
					</ng-container>
				</ul>
			</div>
		</div>
	</ng-container> -->
</form>
<div class="row m-0 p-0">
	<ng-container>
		<div class="col-lg-12 col-md-12 col-sm-12">
			<div class="card card-stats">
				<div class="card-header card-header-primary card-header-icon pointer">
					<div class="card-icon pointer" (click)="toggleFlip()">
						<i class="material-icons">receipt_long</i>
					</div>
					<h4 class="card-title" *ngIf="flip == 'inactive'"></h4>
					<h4 class="card-title" *ngIf="flip == 'active'">Payments</h4>
				</div>
				<div class="container-fluid tp-box" [@flipState]="flip">
					<div class="backside tp-box__side tp-box__back" [ngClass]="flip != 'inactive' ? '' : 'd-none'">
						<span *ngIf="running"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
						</span>
						<div class="row  m-0 p-0 ">
							<div class="col-8">
								<mat-form-field appearance="standard">
									<mat-label>Open Journal#</mat-label>
									<mat-select [formControl]="journal_review" (selectionChange)="getSelectedJournal($event)">
										<mat-option [value]="''">All</mat-option>
										<mat-option *ngFor="let cur of allopenjournals" [value]="cur">
											{{ cur.userid }}:# {{ cur.journal_no }} {{ cur.desc1 }} ({{ cur.journal_date }})
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-4">
								<button (click)="pdfJournalClose()" class="btn btn-danger"><i class="fa fa-file-pdf-o"></i></button>
								&nbsp;&nbsp;
								<button (click)="xlsJournal()" class="btn btn-success"><i class="fa fa-file-excel-o"></i></button>
							</div>

							<span matSuffix *ngIf="loadingjournal">
								<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</span>
						</div>



						<div #journal_details id="journal_details" *ngIf="journal_payments">
							<div class="row m-0 p-0" *ngIf="journal_payments.journal_no ">
								<div class="col-md-12 text-left m-0 p-0">
									<h5 class="mr-0">JOURNAL# {{ journal_payments.journal_no }} {{ journal_payments.desc1 }} &nbsp;&nbsp;&nbsp;DATE {{ journal_payments.journal_date }}</h5>
									<h5 class="ml-0">POSTED {{ journal_payments.total_posted | currency }}</h5>
								</div>
								<div class="col-12">
									<table class="table">
										<ng-container *ngFor="let j of journal_payments.details;">
											<tr>
												<th>Payment#</th>
												<th>Customer#</th>
												<th>Ref#</th>
												<th>Name</th>
												<th>Type</th>
												<th>Total</th>
												<th>Paid</th>
												<!-- <th></th>
												<th>Balance</th> -->
											</tr>
											<tr>
												<td>#{{ j.transno }}</td>
												<td><a [routerLink]="''">{{ j.debtorno }}</a></td>
												<td>{{ j.ref }}</td>
												<td>{{ j.brname }}</td>
												<td>{{ j.banktranstype }}</td>
												<td>{{ j.amount | currency }}</td>
												<td>{{ j.alloc | currency }}</td>
												<!-- <td></td>
												<td>{{ j.balance | currency }}</td> -->
											</tr>
											<ng-container *ngIf="j.allocations">
												<!-- <tr *ngIf="i == 0">
														<th>Allocations</th>
														<th>Date</th>
														<th>Document#</th>
														<th>Total</th>
														<th>Allocation</th>
													</tr> -->
												<ng-container *ngFor="let d of j.allocations; let i = index">
													<tr *ngIf="i == 0">
														<th>Document</th>
														<th></th>
														<th></th>
														<th></th>
														<th></th>
														<th>Total</th>
														<th>Payment</th>
														<!-- <th>Due</th>
														<th>Balance</th> -->
													</tr>
													<tr>
														<td>#{{ d.transno }}</td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td>{{ d.total }}</td>
														<td>{{ d.amt | currency }}</td>
														<!-- <td>{{ d.balance | currency }}</td>
														<td>{{ d.rolling | currency }}</td> -->
													</tr>
												</ng-container>
											</ng-container>
										</ng-container>
										<ng-container *ngFor="let j of journal_payments.credits;">
											<tr>
												<th>Credit#</th>
												<th>Customer#</th>
												<th>Name</th>
												<th></th>
												<th></th>
												<th>Total</th>
												<th>Credited</th>
												<!-- <th></th>
												<th>Balance</th> -->
											</tr>
											<tr>
												<td>#{{ j.transno }}</td>
												<td><a [routerLink]="''">{{ j.debtorno }}</a></td>
												<td>{{ j.brname }}</td>
												<td></td>
												<td></td>
												<td>{{ j.total | currency }}</td>
												<td>{{ j.alloc | currency }}</td>
												<!-- <td></td>
												<td>{{ j.balance | currency }}</td> -->
											</tr>
											<ng-container *ngIf="j.allocations">
												<!-- <tr *ngIf="i == 0">
														<th>Allocations</th>
														<th>Date</th>
														<th>Document#</th>
														<th>Total</th>
														<th>Allocation</th>
													</tr> -->
												<ng-container *ngFor="let d of j.allocations; let i = index">
													<tr *ngIf="i == 0">
														<th>Applied to Invoice</th>
														<th></th>
														<th></th>
														<th></th>
														<th></th>
														<th></th>
														<th>Applied</th>
														<!-- <th>Due</th>
														<th>Balance</th> -->
													</tr>
													<tr>
														<td>#{{ d.transno }}</td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td>{{ d.amt | currency }}</td>
														<!-- <td>{{ d.balance | currency }}</td>
														<td>{{ d.rolling | currency }}</td> -->
													</tr>
												</ng-container>
											</ng-container>
										</ng-container>
									</table>
								</div>
							</div>
							<div class="row m-0 p-0" *ngIf="!journal_payments.details">
								<h4 *ngIf="journal_review != 'NO JOURNAL'">No Payments Posted</h4>
							</div>
						</div>
						<div class="col-md-12" id="payrep" *ngIf="!running">
							<ng-container *ngIf="payments.transactions">
								<h4 class="mt-4 mb-4">Payment Summary {{ paymentForm.get('datepaid').value | date }}</h4>
								<div class="bg-white mt-2 rounded">
									<div id="receipts">
										<table class="table compact table-sm w-50 collapsed">
											<tr>
												<th>Type</th>
												<th>Amount</th>
											<tr *ngFor="let p of payments.summary">
												<td>{{ p.type }}</td>
												<td> {{ p.amount | currency }}</td>
											</tr>
											<tr>
												<th>Total</th>
												<th> {{ total_pay | currency }}</th>
											</tr>
										</table>
										<h4 class="mt-2 mb-4">Payment Details</h4>
										<table id="rcptTable" name="rcptTable" class="table compact table-sm collapsed">
											<thead>
												<tr>
													<th class="text-left">Date</th>
													<th class="text-left">Transaction#</th>
													<th class="text-left">Customer#</th>
													<th class="text-left">Name</th>
													<th class="text-center">Amount</th>
													<th class="text-center">Discount</th>
													<th class="text-center">Remaining</th>
													<th class="text-center">Settled</th>
													<th class="text-center">Reference</th>
													<th class="text-center"></th>
												</tr>
											</thead>
											<tbody id="resultbody">
												<ng-container *ngFor="let pay of payments.transactions">
													<tr>
														<td class="text-left"> {{ pay.trandate }}</td>
														<td class="text-left"> <a (click)="setExapanded(pay)">{{ pay.transno }}</a></td>
														<td class="text-left text-nowrap">
															{{ pay.debtorno }}{{ pay.branchcode }}
														</td>
														<td class="text-left text-nowrap">
															{{ pay.name }}
														</td>
														<td class="text-center" [ngClass]="( pay.ovamount > 0 ) ? 'text-danger bold': 'bold'"> {{ pay.ovamount | currency }}</td>
														<td class="text-center"> {{ pay.ovdiscount | currency }}</td>
														<td class="text-center"> {{ pay.leftto | currency }}</td>
														<td class="text-center" [ngClass]="(pay.settled =='Yes') ? 'text-success bold': 'text-danger bold'"> {{ pay.settled }}</td>
														<td class="text-center"> {{ pay.reference}}
															<span *ngIf="pay.cc_data">
																&nbsp; {{ pay.cc_data.card_last_four }}
															</span>
														</td>
														<td>
															<span *ngIf="pay.cc_data">
																<!-- {{ pay.cc_data.card_uuid }}
																{{ pay.cc_data.order_number }} -->

																<a (click)="voidPayment(pay)">VOID</a>
															</span>
														</td>

													</tr>
													<tr *ngIf="pay === expandedElement">
														<td colspan="10">
															<div class="alloc-element-description">
																<app-allocations [transid]="pay.id" [type]="pay.type"></app-allocations>
															</div>
														</td>
													</tr>
												</ng-container>
											</tbody>
										</table>
									</div>
								</div>
							</ng-container>
							<ng-container *ngIf="!payments.transactions">
								<h4 class="mt-2">No Payments Found</h4>
							</ng-container>
						</div>
					</div>
					<div class="row  m-0 p-0  tp-box__side tp-box__front" id="frontside" [ngClass]="flip != 'active' ? '' : 'd-none'">
						<div class="card-body" *ngIf="customer">


							<div class="row mt-3 " *ngIf="customer.payments">
								<div class="col-md-6 text-left border-rounded light-border" *ngIf="!hidepay">
									<div class="flex-row">
										<h5 class="mt-2">Payments & Credits</h5>
										&nbsp;<span class="lh-40">(&nbsp;{{allocations_from.length}}&nbsp;)</span>
									</div>
									<nav class="navbar navbar-expand navbar-light bg-light ">
										<div class="navbar-nav">
											<mat-form-field class="full-width text-right col-md-5 mr-2 ml-0" appearance="standard" color="accent">
												<mat-label>Search Payment Date#</mat-label>
												<input type="text" placeholder="Search" aria-label="Text" matInput [formControl]="searchByCurrentPaymentsDate" (input)="currentPaymentsSearchDate($event.target.value)">
											</mat-form-field>
											<mat-form-field class="full-width text-right col-md-5 mr-auto ml-0 " appearance="standard" color="accent">
												<mat-label>Search Payment#</mat-label>
												<input type="text" placeholder="Search" aria-label="Text" matInput [formControl]="searchByCurrentPayments" (input)="currentPaymentsSearch($event.target.value)">
											</mat-form-field>
											<mat-checkbox class="nav-item col-md-2 mt-3 ml-auto mr-0 " [formControl]="selectAllPayments" #checkallfrom (change)="checkAllFrom($event.checked)"></mat-checkbox>
										</div>

									</nav>
									<div class="text-left border-rounded light-border scrolling-left">
										<div class="table table-sm">
											<h5 class="mt-2 mb-2 ml-auto mr-auto text-center" *ngIf="!customer.payments.length">No Open Payments</h5>
											<div class=" border-bottom p-1 smaller" *ngFor="let pay of customer.payments">
												<!-- [ngClass]="creditsSelected.includes(pay) ? 'highlighted' : 'highlight'"
												(click)="togglePaySelect(pay);" -->
												<div class="row">
													<div class="col-md-3 mt-3">
														{{ pay.trandate }}
														<br>
														{{ pay.typename }}
														{{ pay.orderno }}<br>
														{{ pay.transno }}
													</div>
													<div class="col-md-2 mt-3">
														ORIG AMT:<br>
														{{ pay.total | abs | currency}}
													</div>
													<div class="col-md-2 mt-3">
														AMT AVAIL<br>
														{{ pay.leftto | abs | currency}}
													</div>
													<div class="col-md-3 mt-3">
														<mat-form-field appearance="standard">
															<mat-label>PAYMENT</mat-label>
															<span matPrefix>$</span>
															<input type="text" (input)="updateAllocationFrom($event.target.value, pay)" [(value)]="pay.allocation" class="text-right mt-0 pt-0" placeholder="Allocation Amount" aria-label="Allocation Amount" matInput appSelectOnFocus />
														</mat-form-field>
													</div>
													<div class="col-md-1 mt-3 ml-2">
														<mat-checkbox class="mt-3" (checked)="pay.selected" (change)="allocationFrom($event.checked, pay)" [indeterminate]="checkallfrom.checked"></mat-checkbox>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="text-left border-rounded light-border" *ngIf="customer.transactions" [ngClass]="hidepay ? 'col-md-12' : 'col-md-6'">


									<div class="flex-row">
										<h5 class="mt-2" style="display: contents;">
											<mat-icon class="mt-2 pointer" (click)="hidePayments()">menu_open</mat-icon>
											<div class="lh-40">Invoices</div>
										</h5>
										&nbsp;<span class="lh-40">(&nbsp;{{allocations_to.length}}&nbsp;)</span>
									</div>
									<nav class="navbar navbar-expand navbar-light bg-light">
										<mat-checkbox class="mt-3 col-2" #checkallto [formControl]="selectAllInvoices" (change)="checkAllTo($event.checked)"></mat-checkbox>
										<div class="navbar-nav">
											<mat-form-field class="full-width text-right col-md-5" appearance="standard" color="accent">
												<mat-label>Search Invoice Date</mat-label>
												<input type="text" placeholder="Search" aria-label="Text" matInput [formControl]="searchByCurrentInvoicesDate" (input)="currentInvoiceSearchDate($event.target.value)">
											</mat-form-field>
											<mat-form-field class="full-width text-right col-md-5" appearance="standard" color="accent">
												<mat-label>Search Invoice#</mat-label>
												<input type="text" placeholder="Search" aria-label="Text" matInput [formControl]="searchByCurrentInvoices" (input)="currentInvoiceSearch($event.target.value)">
											</mat-form-field>
										</div>
									</nav>
									<div class="text-left border-rounded light-border scrolling-left">
										<div class="table table-sm">
											<div class="row border-bottom p-1" *ngIf="!customer.transactions.length ">
												<h5 class="mt-2 mb-2 text-center ml-auto mr-auto">No Open Transactions</h5>
											</div>
											<div class="row border-bottom p-1 smaller" *ngFor="let pay of customer.transactions">
												<div class="col-md-1 mt-3 ml-2">
													<mat-checkbox class="mt-3" (checked)="pay.selected" (change)="allocationTo($event.checked, pay)" [indeterminate]="checkallto.checked"></mat-checkbox>
												</div>
												<div class="col-md-3 mt-3">
													{{ pay.trandate }}
													<br>
													{{ pay.typename }}
													{{ pay.orderno }}
												</div>
												<div class="col-md-2 mt-3">
													ORIG AMT:<br>
													{{ pay.total | abs | currency }}
												</div>
												<div class="col-md-2 mt-3">
													AMT DUE<br>
													{{ pay.leftto | abs | currency}}
												</div>
												<div class="col-md-3 mt-2">
													<mat-form-field appearance="standard">
														<mat-label>PAYMENT</mat-label>
														<span matPrefix>$</span>
														<input type="text" (input)="updateAllocationTo($event.target.value, pay)" [(value)]="pay.allocation" class="text-right mt-0 pt-0" placeholder="Allocation Amount" aria-label="Allocation Amount" matInput />
													</mat-form-field>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>
