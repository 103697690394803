<div class="row">
	<div class="col-6">
		<h4 class="title mt-3">Dealer Codes</h4>
	</div>
	<div class="col-6 text-right">
		<button mat-raised-button color="accent" class="mt-2 mb-2" type="button" aria-label="Add" (click)="addDC()"><mat-icon>add</mat-icon> Add </button>
	</div>
</div>
<div class="table">
	<table class="table mt-2 mb-2">
		<tr>
			<th class="w-30">Branch Code </th>
			<th class="w-30">Manufacturer</th>
			<th class="w-30">Dealer Code</th>
			<th class="w-30">Dealer Type</th>
			<th></th>
			<th></th>
		</tr>
		<tr *ngFor="let dcode of dcodes">
			<ng-container *ngIf="dcode.editing === 1; then editdcode else showdcode"></ng-container>
			<ng-template #editdcode>
				<td class="w-30">
					<mat-form-field appearance="standard">
						<mat-label>Branch</mat-label>
						<mat-select [(value)]="dcode.branchcode">
							<mat-option *ngFor="let branch of branches" [value]="branch.branchcode">
								{{branch.branchcode}} {{branch.brname}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
				<td class="w-30">
					<mat-form-field appearance="standard">
						<mat-label>Mfg</mat-label>
						<mat-select [(value)]="dcode.manufacturer_id">
							<mat-option *ngFor="let mfg of mfgs" [value]="mfg.manufacturer_id">
								{{mfg.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
				<td class="w-30">
					<mat-form-field appearance="standard">
						<mat-label>Code</mat-label>
						<input matInput [(ngModel)]="dcode.dealercode">
					</mat-form-field>
				</td>
				<td class="w-30">
					<mat-form-field appearance="standard">
						<mat-label>Dealer Type</mat-label>
						<mat-select [(value)]="dcode.dealertype">
							<mat-option value="1">
								Chevy Dealer
							</mat-option>
							<mat-option value="2">
								Ford Dealer
							</mat-option>
							<mat-option value="3">
								Chrysler Dealer
							</mat-option>
							<mat-option value="4">
								Government Entity
							</mat-option>
							<mat-option value="5">
								Fleet / National Account
							</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
				<td class="text-right"><button mat-icon-button color="primary"><i class="material-icons" (click)="saveDC(dcode)">save</i></button>
				</td>
				<td class="text-right"><button mat-icon-button color="warn"><i class="material-icons" (click)="cancelDC()">cancel</i></button></td>
			</ng-template>
			<ng-template #showdcode>
				<td class="w-30">{{dcode.branchcode}} {{dcode.brname}}</td>
				<td class="w-30">{{dcode.name}}</td>
				<td class="w-30">{{dcode.dealercode}}</td>
				<td class="w-30">{{dealertypenames[dcode.dealertype]}}</td>
				<td class="text-right"><button mat-icon-button color="primary"><i class="material-icons" (click)="dcode.editing=1">edit</i></button></td>
				<td class="text-right"><button mat-icon-button color="warn"><i class="material-icons" (click)="removeDC(dcode.autoid)">delete</i></button></td>
			</ng-template>
		</tr>
	</table>
</div>
