import { Component, OnDestroy, OnInit,ViewChild,ChangeDetectorRef ,AfterViewInit,ElementRef} from '@angular/core';
import { Location } from '@angular/common'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective,UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import * as FileSaver from 'file-saver';
import { GlobalsService } from '../../services/globals.service';
import { SystemService } from '../../services/system.service';
import { merge, fromEvent } from "rxjs";
import {debounceTime, distinctUntilChanged, startWith, tap, delay} from 'rxjs/operators';

import { GlobalSearchService } from '../../services/globalsearchservice.service';

@Component({
selector: 'app-company-setup',
templateUrl: './company-setup.component.html',
styleUrls: ['./company-setup.component.scss']
})
export class CompanySetupComponent implements OnInit {
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	company: any = [];
	companyForm: UntypedFormGroup = this.fb.group({});
	controls: any = [];
	color: string = 'blue';
	//,

	constructor( private globalSearchService: GlobalSearchService,private systemService: SystemService, private location: Location,public cdr: ChangeDetectorRef, private globalsService:GlobalsService, private fb: UntypedFormBuilder) {

		this.color = this.globalSearchService.getColor();

		this.globalsService.getCompany().subscribe(details => {
			 this.company = details;
			   var controls = [];
			   const formGroup: UntypedFormGroup = new UntypedFormGroup({});
			   Object.keys(details).forEach(key => {
				 let value = (details[key]) ? details[key] : '';
				   controls.push({ name: key, control: new UntypedFormControl(value, []) })
			   });
			   controls.forEach(f => {
				   formGroup.addControl(f.name, f.control, {})
			   });
			   this.companyForm = formGroup;
		});

	}

	properCase(str) {
	  return str.split(' ').map(function(val){
		return val.charAt(0).toUpperCase() + val.substr(1).toLowerCase();
	  }).join(' ');
	}

	updateCompany() {

	}
	back(): void {
		this.location.back()
	}

	ngOnInit(): void {
	}

	update() {
		this.globalsService.updateCompany(this.companyForm.value).subscribe((result)=> {

		});
	}

	ngAfterViewInit() {
		 this.globalsService.getCompany().subscribe(details => {
			  this.company = details;
				var controls = [];
				const formGroup: UntypedFormGroup = new UntypedFormGroup({});
				Object.keys(details).forEach(key => {
				  let value = (details[key]) ? details[key] : '';
				  	this.controls.push(key)
					controls.push({ name: key, control: new UntypedFormControl(value, []) })
				});
				controls.forEach(f => {
					formGroup.addControl(f.name, f.control, {})
				});

				this.companyForm = formGroup;
		 });
	}

}
