<ng-template #orderdetails id="orderdetails" let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Order Details {{ selectedorder.invoice }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="col-12">
				<div class="row">
					<div class="col-9">
					<h5 [innerHTML]="selectedorder.address"></h5>
					</div>
					<div class="col-3 text-right">
						<ul class="entry-list m-0 p-0 bordered">
							<li>
								<div class="spec-label">PO</div>
								<div class="spec-value">{{ selectedorder.po }}</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="row text-center">
					<div class="ml-auto mr-auto col-12 mt-2 mb-2">
						<button mat-raised-button color="warn" (click)="seleccted_alt = true;">Dropped At Alt. Location</button>
						<div *ngIf="seleccted_alt">
							<mat-form-field appearance="standard" class="ml-2 mr-2">
								<mat-label>Transfer To:</mat-label>
								<mat-select [formControl]="transfertruck">
								<mat-option *ngFor="let truck of trucks"
								[value]="truck.id">
								{{truck.name }}</mat-option>
								</mat-select>
							</mat-form-field>
							<button mat-raised-button color="danger" (click)="altLocation()">Submit</button>
						</div>

					</div>
					<div class="ml-auto mr-auto col-12 mt-2 mb-2">
						<button mat-raised-button color="accent" (click)="cancel(selectedorder.did)">Refused Delivery</button>
					</div>
					<div class="ml-auto mr-auto col-12 mt-2 mb-2">
						<div class="row" *ngIf="selectedorder.ordertype=='11'">

						<ng-container *ngFor="let item of selectedorder.details">
							<div class="col-md-12">
								{{ item.stkcode }} {{ item.itemdesc }}
								<mat-form-field appearance="standard" class="ml-2 mr-2">
									<mat-label>Qty Pick Up</mat-label>
									<input matInput
										class="text-right"
										placeholder="Qty Pick Up"
										[(value)]="item.quantity"
									>
								</mat-form-field>
							</div>
						</ng-container>
						</div>

						<button mat-raised-button color="primary"  (click)="delivered(selectedorder.did)" >
							<span *ngIf="selectedorder.ordertype=='11'" >Picked Up</span>
							<span *ngIf="selectedorder.ordertype!='11'" >Delivered</span>
						</button>
					</div>
					<div class="ml-auto mr-auto col-12 mt-2 mb-2" *ngIf="selectedorder.ordertype=='11' && selectedorder.status_id=='31'">

						<button mat-raised-button color="primary"  (click)="dropAtHome(selectedorder.did)" >
							<span >Return To Warehouse</span>
						</button>
					</div>
				</div>
				<table class="table table-sm">
					<tr>
						<th>Item#</th>
						<th>Description</th>
						<th>Ordered</th>
						<th>Price </th>
					</tr>
					<tr *ngFor="let item of selectedorder.details">
						<td>{{ item.stkcode }}</td>
						<td>{{ item.description }}</td>
						<td>{{ item.ordered }}</td>
						<td>{{ item.unitprice | currency}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="col-md-12">
			<div class="card">
				<div class="card-header card-header-{{color}}-5">
					<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Active Runs(s)</h4>
					<p class="card-category"></p>
					<div class="messages">
					</div>
				</div>
				<div class="card-body" >
					<div class="row mb-3">
						<div class="col-3" *ngIf="truck.value">
							<button mat-raised-button color="primary"  (click)="clearRun(truck.value)" >
								<span>Clear Run</span>
							</button>
							&nbsp;&nbsp;&nbsp;
							<button mat-raised-button color="primary"  (click)="deliverRun(truck.value)" >
								<span>Mark All Delivered </span>
							</button>
						</div>
						<div class="col-9" [ngClass]="truck.value ? 'col-9' : 'col-12'">

							<mat-form-field class="full-width text-right" appearance="standard" color="accent">
								<mat-label>Select Truck</mat-label>
								<input type="text" placeholder="Search for an amount" aria-label="Truck" matInput [formControl]="truck" [matAutocomplete]="truckSelect" (input)="truckFilter($event.target.value)">
								<mat-autocomplete #truckSelect="matAutocomplete" (optionSelected)="loadRun($event.option.value)">
									<mat-option *ngFor="let truck of trucks" [value]="truck.id">
										{{truck.name }}
									</mat-option>
								</mat-autocomplete>
							</mat-form-field>
						</div>
					</div>
					<div class="row small-font border-bottom status-{{da.status_id}}" *ngFor="let da of dispatches">
						<ng-container *ngIf="da.ispoint=='0'" >
							<div class="col-1 ml-0 mr-0 bold pr-0">{{ da.sort }}.</div>
							<div class="col-10 col-md-6 ml-0 mr-0 pr-0" [innerHTML]="da.address" (click)="zoomToPoint(da)">
							</div>
							<div class="col-md-4 ml-0 mr-0 pr-0 pl-0">
								<ul class="entry-list m-0 p-0" [ngClass]="{credit: da.ordertype=='11'}">

									<li>
										<div class="spec-label">PHONE#</div>
										<div class="spec-value"><a href="tel://{{ da.phone }}">{{ da.phone }}</a></div>

									</li>
									<li>
										<div class="spec-label">ORDER#</div>
										<div class="spec-value">{{ da.invoice }}</div>

									</li>
									<li>
										<div class="spec-label">PO</div>
										<div class="spec-value">{{ da.po }}</div>
									</li>
									<li>
										<div class="spec-label">Items</div>
										<div class="spec-value">{{ da.qty }}</div>
									</li>
									<li>
										<div class="spec-label">Status</div>
										<div class="spec-value">{{ da.status }}</div>
									</li>
								</ul>
							</div>
							<div class="col-md-1 ml-0 mr-0 text-center mt-4 mb-3 ">
								<button mat-mini-fab color="accent" aria-label="View / Update Status" (click)="viewOrder(da)">
									<mat-icon>sync_alt</mat-icon>
								</button>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
