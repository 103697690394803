import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform,AfterViewInit} from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router} from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { Product } from '../../classes/orders';
import { Directive, HostListener } from "@angular/core";
import { trigger, state, style, transition, animate } from '@angular/animations';

import { OmsService } from '../../services/oms.service';

@Component({
  selector: 'app-order-load',
  templateUrl: './order-load.component.html',
  styleUrls: ['./order-load.component.scss']
})
export class OrderLoadComponent implements OnInit {
	title = 'customers';
	CHAT_ROOM = "OrderBoard";
	customers: any;
	customerdata: any;
	customernumber: any;
	ready: boolean;
	dtOptions: DataTables.Settings = {};
	dspcost: boolean = true;
	header: any;
	branch: any;
	price = []
	quantity = []
	discount = []
	notes = [];
	user: any = [];
	total_qty: number  = 0;
	customerselected: boolean = false;
	items: any = [];
	carttotals: any = [];
	orderResults: any = [];
	searchitems: any = [];
	flip: string = 'inactive';
	flip_button_txt: string = 'Create Invoice';
	messages: any = [];
	// todo - finish this
	constructor(private route: ActivatedRoute,private customerService: CustomerService, private orderService: OrdersService, private location: Location,public router: Router,public cdr: ChangeDetectorRef,private globalSearchService: GlobalSearchService, public omsService: OmsService) { }

	ngOnInit(): void {
		const token = this.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
		  if (token) {
			  this.omsService.setupSocketConnection(token);
			  this.omsService.subscribeToOpenOrders((err, data) => {
				  this.messages = [...this.messages, data.data];
			  });
		  }

		  this.globalSearchService.user.subscribe((results: any) => {
			  if(results) {
				  this.user = results.user;
			  }
		  });

		  this.header = [
			  { field: 'customernumber', header: 'ID' },
			  { field: 'customername', header: 'Name' },
			  { field: 'phone', header: 'Phone' },
			  { field: 'address', header: 'Address' },
			  { field: 'email', header: 'Email' },
		  ];

		  this.ready = true;

		  this.route.params.subscribe(params => {
			  const customernumber = params['cid'];
			  const branch = params['branch'];
			  const orderno = params['orderno'];
				  if(customernumber) {
					  this.customerselected = true;
					  this.customerService.getCustomer(customernumber, branch).subscribe((results:any) => {
						  this.customerdata = results;
						  this.customernumber = customernumber;
						  this.branch = branch;

						  this.orderService.loadSalesOrder(orderno).subscribe((results:any) => {
							  this.items = results.details

						  })
						  /*
						  this.orderService.getCart(customernumber , branch).subscribe((items: any) => {
							  items.forEach((vals :any) => {
								  this.price[vals.cart_id] = vals.price;
								  this.quantity[vals.cart_id] = vals.quantity;
								  this.discount[vals.cart_id] = vals.discount;
								  this.notes[vals.cart_id] = vals.notes
								  this.total_qty += vals.quantity;
							  });

							  this.items = items;
						  });


						  this.orderService.getTotals(customernumber,branch).subscribe((results: any[]) => {
							   this.carttotals = results;
							});
						*/
					  });
				  } else {
					  //no customer selected show search and select
					  this.customerselected = false;
				  }
		});
	}

	back(): void {
		this.location.back()
	}

	randomString(length, chars) {
  		var result = '';
  		for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  		return result;
	}

}
