import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../../../services/customer.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DispatchService } from '../../../services/dispatch.service';
import { GlobalsService } from '../../../services/globals.service';

import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
declare var $: any;

@Component({
	selector: 'app-customer-shipto-add',
	templateUrl: './customer-shipto-add.component.html',
	styleUrls: ['./customer-shipto-add.component.scss'],
	animations: [
		trigger("grow", [ // Note the trigger name
			transition(":enter", [
				// :enter is alias to 'void => *'
				style({ height: "0", width: "0", overflow: "hidden" }),
				animate(200, style({ height: "*", width: "*" })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(":leave", [
				// :leave is alias to '* => void'
				animate(300, style({ height: 0, width: 0, overflow: "hidden" })),
				animate('300ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class CustomerShiptoAddComponent implements OnInit {
	@Input() customerdata;
	@Output() editing = new EventEmitter < boolean > ();
	@Output() newcustomerdata = new EventEmitter < boolean > ();

	branchForm: any = false;
	taxes: any = [];
	alltaxes: any = [];
	currencies: any = [];
	locations: any = [];
	salesman: any = [];

	shipvia: any = [];
	zones: any = [];
	areas: any;
	status_msg: string = '';
	disabledbranch: boolean = false;
	tirefee: boolean = false;
	defaultbranchcustomer: boolean = true;
	paymentmethods: any = [];

	defaults = {
		country: 'United States',
		region: 'NY',
		holdreason: '1',
		currency: 'USD',
		terms: '1',
		salestype: 'A1',
		customertype: '1',
		discount: 0,
		credit: 1000,
		email_invoice: false,
		requirepo: false,
		email_statement: false,
		interest: 3,
		warehouse: '01',
		shipvia: '1',
		area: '01',
		territory: '01',
		salesman: '01',
		disabled: false,
		tirefee: true,
		taxauth: '119',
		branchcode: '00',
	};

	formEditAllowed = [
		'debtorno',
		'branchcode',
		'brname',
		'contactname',
		'braddress1',
		'braddress2',
		'braddress3',
		'braddress4',
		'braddress5',
		'braddress6',
		'email',
		'phoneno',
		'faxno',
		'specialinstructions',
		'defaultlocation',
		'defaultshipvia',
		'tax_ref',
		'taxgroupid',
		'area',
		'salesman',
		'disabletrans',
		'tirefee',
		'default_paymentmehod',
		'route'
		//'contactname'
	]
	routes: any = [];
	constructor(private globalSearchService: GlobalSearchService, private dispatchService: DispatchService, private fb: UntypedFormBuilder, public globalsService: GlobalsService, public customerService: CustomerService) {

		this.dispatchService.getRoutes().subscribe((r: any) => {
			this.routes = r
		});


		this.globalsService.getTaxes().subscribe(details => {
			this.taxes = details;
			this.alltaxes = details;
		});

		this.globalsService.getLocations().subscribe(details => {
			this.locations = details;
		});

		this.globalsService.getDefaultZones().subscribe(details => {
			this.zones = details;
		});

		this.globalsService.getAreas().subscribe(details => {
			this.areas = details;
		});

		this.globalsService.getSalesman().subscribe(details => {
			this.salesman = details;
		});

		this.globalsService.getShipVia().subscribe(details => {
			this.shipvia = details;
		});

		this.globalsService.getPaymentMethods().subscribe(details => {
			this.paymentmethods = details;
		});

	}

	ngOnInit(): void {

		this.branchForm = this.fb.group({
			debtorno: [this.customerdata.customer.debtorno, [Validators.required]],
			branchcode: [this.nextBranch(this.customerdata.branches.length), [Validators.required]],
			brname: [this.customerdata.customer.name, [Validators.required]],
			contactname: [this.customerdata.customer.contactname, [Validators.required]],
			braddress1: ['', [Validators.required]],
			braddress2: [''],
			braddress3: ['', [Validators.required]],
			braddress4: [this.customerdata.customer.address4, [Validators.required]],
			braddress5: ['', [Validators.required]],
			braddress6: [this.customerdata.customer.address6, [Validators.required]],
			//estdeliverydays: [''],
			phoneno: [this.customerdata.customer.phoneno],
			email: [this.customerdata.customer.email, [Validators.email]],
			faxno: [this.customerdata.customer.faxno],
			disabletrans: [this.defaults.disabled],
			tirefee: [this.defaults.tirefee, [Validators.required]],
			defaultlocation: [this.defaults.warehouse, [Validators.required]],
			defaultshipvia: [this.defaults.shipvia, [Validators.required]],
			area: [this.defaults.area, [Validators.required]],
			salesman: [this.defaults.salesman, [Validators.required]],
			tax_ref: [this.customerdata.customer.tax_ref,  [Validators.minLength(9), Validators.maxLength(20)]],
			taxgroupid: [this.defaults.taxauth, [Validators.required]],
			specialinstructions: [this.customerdata.customer.specialinstructions],
			default_paymentmehod: [this.customerdata.customer.default_paymentmehod],
			route: [this.customerdata.customer.route, [Validators.required]],
		});

		this.branchForm.markAllAsTouched();

		this.branchForm.get('tax_ref').valueChanges.subscribe(newvalue => {
			if (newvalue != '') {
				var currentValue = this.alltaxes.filter(v => {
					return v.taxgroupid == this.branchForm.get('taxgroupid').value
				})[0];

				if (currentValue) {
					currentValue = currentValue.taxgroupdescription
				} else {
					currentValue = '';
				}

				this.filterTaxAuths(true, currentValue);
			} else {
				this.filterTaxAuths(false, '');
			}
		});
	}

	filterTaxAuths(filter: any, current: any) {

		if (filter) {

			const results = this.taxes.filter((r: any) => {
				var newvalue = '';

				if (current != '') {
					newvalue = current.replace('1', '2');
				}

				if (r.taxgroupdescription.startsWith('2')) {
					if (newvalue != '' && newvalue == r.taxgroupdescription) {
						this.branchForm.get('taxgroupid').setValue(r.taxgroupid);
						//this.globalSearchService.showNotification('Tax Group Updated to Non Taxable', 'warn', 'bottom', 'right');
					}
					return r;
				}
			})
			this.taxes = results;
		} else {
			this.taxes = this.alltaxes;
		}
	}

	nextBranch(count: any) {
		//count = count+1; - no need qantel started at zero
		let stringbranch = ''
		if (parseFloat(count) < 10) {
			stringbranch += '0' + count
		} else {
			stringbranch = count;
		}

		return stringbranch;
	}

	ngAfterViewInit() {


	}

	createCustomer() {
		if (this.branchForm.valid) {
			this.customerService.updateBranch(this.branchForm.value).subscribe(details => {
				if (details.success) {
					this.newcustomerdata.emit(this.branchForm.value);
					//this.showNotification(this.status_msg,'success','bottom','right');
				} else {
					//this.showNotification(this.status_msg,'danger','bottom','right');
				}
			});
		}
	}
	updateZones(input: any) {

	}

}
