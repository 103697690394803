import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { InventoryService } from '../../services/inventory.service'
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-gl-classes',
	templateUrl: './gl-classes.component.html',
	styleUrls: ['./gl-classes.component.scss']
})
export class GlClassesComponent implements OnInit {

	searchingglclass: any = false;
	@ViewChild("glEdit") glEditRef: ElementRef;
	@ViewChild("glc") glcRef: ElementRef;

	filteredglclass: any = [];
	glclasses: any = [];
	glclasssearch = new FormControl('');
	glClassForm: UntypedFormGroup;
	viewingglc: any = false;

	constructor(private fb: UntypedFormBuilder, private inventoryService: InventoryService, private globalSearchService: GlobalSearchService, private modalService: NgbModal) {}

	ngOnInit(): void {

		this.glclasssearch.valueChanges.subscribe(newValue => {
			this.filterGLclass();
		});
		this.loadGLClasses();
	}

	filterGLclass() {

		if (this.glclasssearch.value == '') {
			this.searchingglclass = this.glclasses;
		} else {
			this.searchingglclass = this.globalSearchService.filterItem(this.glclasses, this.glclasssearch.value, 'code,description')
		}

	}

	addGLClass() {

		this.glClassForm = this.fb.group({
			code: ['', Validators.required],
			name: ['', Validators.required],
		});
		this.modalService.open(this.glEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});

	}
	saveGLClass() {
		if (this.glClassForm.valid) {
			this.glClassForm.get('code').enable()
			this.inventoryService.saveGLClass(this.glClassForm.value).subscribe(result => {
				this.loadGLClasses();
				this.modalService.dismissAll();
			})
		}
	}
	loadGLClasses() {
		this.inventoryService.getGLClasses().subscribe(results => {
			this.glclasses = results;
			this.filteredglclass = results;
			this.searchingglclass = results;
		});
	}
	editGLC(glc: any) {
		this.glClassForm = this.fb.group({
			code: [glc.code],
			name: [glc.name, Validators.required],
		});

		this.glClassForm.get('code').disable();
		this.modalService.open(this.glEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});

	}
	removeGLC(glc: any) {
		this.inventoryService.removeGLClass(glc).subscribe((result: any) => {
			if (result.success) {
				this.loadGLClasses();
				this.modalService.dismissAll();
			} else {
				alert(result.message);
			}
		})
	}
	viewProducts(glc: any) {
		this.viewingglc = glc;

		this.modalService.open(this.glcRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}
}
