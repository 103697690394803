import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UsersService } from '../../services/users.service'
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { GlobalsService } from '../../services/globals.service'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { Location } from '@angular/common'
import * as jq from "jquery";
declare var $: any;

@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
	user: any = [];
	userForm: UntypedFormGroup;
	userdetails: any = [];
	locations: any = [];
	formEditAllowed = [
		'userid',
		'password',
		'realname',
		'phone',
		'email',
		'defaultlocation',
		'fullaccess',
		'cancreatetender',
		'editinv',
		'dispatchadmin',
		'customeradmin',
		//'phoneno',
	]
	yesno: any = [
		{ value: '0', name: 'No' },
		{ value: '1', name: 'Yes' }
	];

	color: string = 'blue'

	constructor(public usersService: UsersService, public globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder, public location: Location, public globalsService: GlobalsService) {

		this.color = this.globalSearchService.color


	}

	ngOnInit(): void {

		this.globalSearchService.user.subscribe((result: any) => {

			this.user = result.user;

			this.usersService.getUser(this.user.userid).subscribe((result: any) => {
				this.userdetails = result
				var details = this.userdetails
				var controls = [];
				const formGroup: UntypedFormGroup = new UntypedFormGroup({});
				Object.keys(details).forEach(key => {
					if (this.formEditAllowed.includes(key)) {
						let value = (details[key]) ? details[key] : '';
						controls.push({ name: key, control: new UntypedFormControl(value, []) })
					}
				});
				controls.forEach(f => {
					formGroup.addControl(f.name, f.control, {})
				});
				this.userForm = formGroup;
				this.userForm.controls['userid'].disable();
				this.userForm.controls['password'].setValue('');

			})
		});

		const window_width = $(window).width();
		let $sidebar = $('.sidebar');
		let $sidebar_responsive = $('body > .navbar-collapse');
		let $sidebar_img_container = $sidebar.find('.sidebar-background');

		jq('.badge').click(function() {

			let $full_page_background = jq('.full-page-background');

			jq(this).siblings().removeClass('active');
			jq(this).addClass('active');

			var new_color = $(this).data('color');

			if ($sidebar.length !== 0) {
				$sidebar.attr('data-color', new_color);
			}

			if ($sidebar_responsive.length != 0) {
				$sidebar_responsive.attr('data-color', new_color);
			}
		});

	}

	changColor(new_color: any) {

		//localStorage.setItem('color', JSON.stringify(new_color))
		this.globalSearchService.setColor(new_color)
		this.globalSearchService.color = new_color;
		this.color = new_color;

		let $sidebar = $('.sidebar');
		let $sidebar_responsive = $('body > .navbar-collapse');
		let $navbar_responsive = $('.navbar-top');
		let $sidebar_img_container = $sidebar.find('.sidebar-background');

		let $cards = $('body > .card-header');


		if ($sidebar.length !== 0) {
			$sidebar.attr('data-color', new_color);
			$sidebar.attr('data-background-color', new_color);
			$navbar_responsive.attr('data-background-color', new_color);
		}

		if ($cards.length !== 0) {
			$cards.attr('data-color', new_color);
			$cards.attr('color', new_color);
		}

		if ($sidebar_responsive.length != 0) {
			$sidebar_responsive.attr('data-color', new_color);
			$sidebar_responsive.attr('data-background-color', new_color);
			$navbar_responsive.attr('data-background-color', new_color);
		}

	}
	back(): void {
		this.location.back()
	}

	updateUser() {

		this.usersService.updateUser(this.userdetails.userid, this.userForm.value).subscribe((results) => {
			this.user.user = results;
		//	this.globalSearchService.user.next(this.user);
		//	localStorage.setItem('user', JSON.stringify(this.user))

			this.showNotification("Updated", 'success', 'bottom', 'right');
		});

	}
	ngAfterViewInit() {
		this.globalsService.getLocations().subscribe(details => {
			this.locations = details;
		});
	}
	showNotification(message, type, from, align) {
		//  const type = ['','info','success','warning','danger'];
		// const color = Math.floor((Math.random() * 4) + 1);

		$.notify({
			icon: "notifications",
			message: message

		}, {
			type: type,
			timer: 2500,
			placement: {
				from: from,
				align: align
			},
			template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
				'<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
				'<i class="material-icons" data-notify="icon">notifications</i> ' +
				'<span data-notify="title">{1}</span> ' +
				'<span data-notify="message">{2}</span>' +
				'<div class="progress" data-notify="progressbar">' +
				'<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
				'</div>' +
				'<a href="{3}" target="{4}" data-notify="url"></a>' +
				'</div>'
		});
	}
}
