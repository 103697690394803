<ng-template #emailData let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Confirm Email Address</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3">
            <mat-form-field appearance="standard">
 <mat-label>Email Address</mat-label>
<input matInput [(ngModel)] ="sendemail"/>
</mat-form-field>
		</div>
	</div>
	<div class="modal-footer">
    <button mat-raised-button color="primary" (click)="pushEmail()"><mat-icon>email</mat-icon> Send</button>
	</div>
</ng-template>

<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-5">
						<h4 class="card-title" id="headof" (click)="back()"><i class="material-icons">arrow_left</i> Credit Created</h4>
						<p class="card-category">
							<b></b>
						</p>
					</div>
					<div class="card-body">
                        <div class="row">
						<div class="col-12 text-center">
							<h3 class="bold">Credit#: {{ invoice.transno }} Dispatched</h3>
						</div>
                        </div>
                        <div class="row">
                                <div class="col-6 text-center">
							<a href="{{ reprintlink }}" mat-raised-button color="warn">Print Credit Note</a>
						</div>
                        <div class="col-6 text-center">
							 <button (click)="sendEmail(invoice)" mat-raised-button color="warn">Email Credit Note</button>
						</div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
