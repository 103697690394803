<ng-template #monthDetails let-modal>
	<div class="modal-header">
		<h4> {{ monthtitle }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="table mt-3" *ngIf="results">
			<div class="table">
				<table class="table table-striped table-hover" *ngIf="monthdetails">
					<tr>
						<th>DOCUMENT#</th>
						<th>TYPE</th>
						<th>DATE</th>
						<th>ITEM#</th>
						<th>DESC</th>
						<th class="text-center">DISCOUNT</th>
						<th class="text-center">QTY</th>
						<th class="text-right">PRICE</th>
						<th class="text-right">TOTAL</th>
					</tr>
					<tr *ngFor="let item of monthdetails">
						<td>
							<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More" class="donotprint">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item *ngIf="item.type == '10' || item.type == '11'" (click)="openDocument(item,details,'html')">
									<mat-icon>visibility</mat-icon>
									<span>View HTML</span>
								</button>
								<button mat-menu-item *ngIf="item.type == '10' || item.type == '11'" (click)="openDocument(item,details,'pdf')">
									<mat-icon>visibility</mat-icon>
									<span>View PDF</span>
								</button>
								<button mat-menu-item (click)="creditInvoice(item)" *ngIf="item.type == '10'">
									<mat-icon>history</mat-icon>
									<span>Credit</span>
								</button>
							</mat-menu>
							{{ item.transno }}
						</td>
						<td>{{ item.typename }}</td>
						<td>{{ item.trandate }}</td>
						<td><a [routerLink]="'/inventory/view/'+item.stockid">{{ item.stockid }}</a></td>
						<td>{{item.description}}</td>
						<td class="text-center">{{item.discountpercent | percent }}</td>
						<td class="text-center">{{ item.qty }}</td>
						<td class="text-right">{{item.price | currency }}</td>
						<td class="text-right">{{ item.discountedlinetotal | currency }}</td>
					</tr>
				</table>
				<h4 class="text-center" *ngIf="!results.items">
					No Results
				</h4>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<div class="main card">
    <div class="m-auto main-width" >
    <div class="row d-flex justify-content-between dotted-divider" *ngIf="!customerdata" >
		<h3>Customer Monthly History Report: Qty / Sales / Cost</h3>&nbsp;<h4 class="padding-t-25">Select a customer</h4>
	</div>
    <ng-container *ngIf="!customerdata || displayLookup">
		<app-customer-lookup (customer_selected)="selectCustomer($event)" [lookupOnly]="true" [ngClass]="!customerdata ? 'lookup' : 'lookupPopulated'"></app-customer-lookup>
	</ng-container>
    <ng-container *ngIf="customerdata">
		<div [ngClass]="!displayLookup ? 'reportFilters' : 'reportFiltersNoTop'">
            <div class="row m-0 p-0">
                <div class="col-md-auto">
                    <mat-form-field appearance="standard">
                        <mat-label>Warehouse</mat-label>
                        <mat-select [formControl]="defaultlocation" multiple>
                            <mat-option *ngFor="let loc of locations" [value]="loc.loccode">
                                {{ loc.locationname }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-auto">
                    <mat-form-field appearance="standard" class="">
                        <mat-label>Brand</mat-label>
                        <mat-select [formControl]="brands" multiple>
                            <mat-option *ngFor="let k of properties.brands" [value]="k.manufacturer_id">
                                {{ k.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-auto">
                    <mat-form-field appearance="standard" class="">
                        <mat-label>Category</mat-label>
                        <mat-select [formControl]="categoryid" multiple>
                            <mat-option *ngFor="let k of properties.categories" [value]="k.value">
                                {{ k.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-auto">
                    <mat-form-field appearance="standard" class="">
                        <mat-label>Product Line</mat-label>
                        <mat-select [formControl]="lineid" multiple>
                            <mat-option *ngFor="let k of properties.productlines" [value]="k.value">
                                {{ k.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-auto ml-auto mr-0 ">
                    <ng-container *ngIf="!sending">
                        <button mat-raised-button class="btn btn-success export mt15" (click)="exportMXls();"><i class="fa fa-file-excel-o"></i></button>&nbsp;
                        <button mat-raised-button class="btn btn-danger export mt15" (click)="exportMPdf();"><i class="fa fa-file-pdf-o"></i></button>&nbsp;
                        <button mat-raised-button color="primary" class="export" (click)="showCustomerLookup()">{{customerdata.debtorno}}<mat-icon>person</mat-icon></button>&nbsp;
                    </ng-container>
                    <button mat-raised-button class="export" (click)="loadData()" *ngIf="!sending" color="accent"><mat-icon>refresh</mat-icon></button>
                </div>
            </div>
            <div *ngIf="customerdata">
				<div class="row customerDataRow">
                    <div class="float-right ">
                        <h3><b>Customer Monthly History Report</b>: <span class="custno">(<a href="#/customers/view/{{customerdata.debtorno}}">{{customerdata.debtorno}}</a>) {{customerdata.customer.name}}</span></h3>
                    </div>
                    <div class="float-left ">
                        <div class="col-md-auto text-right mt-2 pt-10">
                            <mat-slide-toggle class="nav-link" [formControl]="consolidated">
                                <mat-label>Consolidated Ship To's</mat-label>
                            </mat-slide-toggle>
                            <mat-checkbox [formControl]="hideshowunits">Units&nbsp;</mat-checkbox>
                            <mat-checkbox [formControl]="hideshowsales">Sales&nbsp;</mat-checkbox>
                            <mat-checkbox [formControl]="hideshowcost" *ngIf="user.user.viewcost">Cost&nbsp;</mat-checkbox>
                        </div>
                    </div>

				</div>
			</div>
            <br>
            <hr>
        </div>
        </ng-container>
        <div *ngIf='results' [ngClass]="results.years.length > 0 ? 'contentTable' : ''">

            <div class="table mt-3" *ngIf="results" #print_history >
                <ng-container *ngIf="results.years.length > 0">
                    <table class="table table-bordered" *ngFor="let year of results.years">
                        <tr>
                            <th></th>
                            <th *ngFor="let month of year.sales" class="text-center">
                                {{ month.label }} {{ month.year }}
                            </th>
                            <th class="text-center">Total</th>
                        </tr>

                        <tr *ngIf="hideshowunits.value">
                            <td class="text-center">Qty</td>
                            <td class="text-center" *ngFor="let month of year.qty">
                                <button mat-flat-button (click)="viewHistory( month.monthnumber, month.year)">{{ month.value | number:'1.0-0' }}</button>
                            </td>
                            <td class="text-center">{{ getQtyTotalYear( year ) }}</td>
                        </tr>
                        <tr *ngIf="hideshowsales.value">
                            <td class="text-center">Sales </td>
                            <td class="text-center" *ngFor="let month of year.sales">
                                {{ month.value | currency }}
                            </td>
                            <td class="text-center">{{ getSaleTotalYear( year ) | currency }}</td>
                        </tr>
                        <tr *ngIf="hideshowcost.value">
                            <td class="text-center">Cost </td>
                            <td class="text-center" *ngFor="let month of year.cost">
                                {{ month.value | currency }}
                            </td>
                            <td class="text-center">{{ getCostTotalYear( year ) | currency }}</td>
                        </tr>
                    </table>
                </ng-container>
                <h4 class='text-center' *ngIf="results.years.length == 0">
                    No Results
                </h4>
            </div>
        </div>
        </div>
</div>
