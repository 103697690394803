import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup,UntypedFormControl } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { CustomerService } from '../../services/customer.service';
import { PaymentsService } from '../../services/payments.service';

@Component({
	selector: 'app-payment-entry',
	templateUrl: './payment-entry.component.html',
	styleUrls: ['./payment-entry.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(179deg)'
			})),
			transition('active => inactive', animate('500ms ease-out')),
			transition('inactive => active', animate('500ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		])
	]
})
export class PaymentEntryComponent implements OnInit {
	@ViewChild("thirdparty") thirdpartyRef: ElementRef;
	flip: string = 'inactive';
	payment_methods: any = [];
	bankaccounts: any = [];
	today = new Date();
	day_payments: any = [];
	paymentForm: UntypedFormGroup;
	cardForm: UntypedFormGroup;
	selectedCard = new UntypedFormControl('');
	searchByAmount = new UntypedFormControl('');
	searchByInvoice = new UntypedFormControl('');
	searchString = new UntypedFormControl('');
	customerSearchInline = new UntypedFormControl('');
	customerSearchResultsTwo: any = [];
	searchByCurrentPayments= new UntypedFormControl('');
	searchByCurrentInvoices= new UntypedFormControl('');

	searchByCurrentPaymentsDate= new UntypedFormControl('');
	searchByCurrentInvoicesDate = new UntypedFormControl('');

	alldebits: any = false;
	allcredits: any = false;

	//reversed
	singleusecard = new UntypedFormControl(true);
	currency: any = '';
	currencies: any = [];
	company: any = [];

	//paymentss
	datefrom = new Date()
	dateto = new Date()
	running: boolean = false;
	total_pay: any = '';
	payments: any = [];

	customersearching: any = false;
	customerSearchResults: any = [];
	amountResults: any = false;
	invoiceResults: any = false;
	customer: any = false;
	customertransactions: any = [];

	hasprofile: boolean = false;
	payment_added: any = false;
	addingcard: any = false;

	allocations_from: any = [];
	allocations_to: any = [];

	allocation_from_total: number = 0.00;
	allocation_to_total: number = 0.00;
	profile_cards: any = [];

	payment_card: any = false;
	single_use: any = false;
	editingcard: any = false;
	sending: any = false;
	color: string = 'blue';
	charge_card: boolean = true;
	submitting: boolean = false;
	chargetype: string = 'existing_card';
	chargecardtype: string = 'no_charge';
	card_required: boolean = false;
	thirdpartylinks: any = false;
	showrefunds: boolean = false;
	urlSafe: SafeResourceUrl;
	creditsSelected: any = [];
	debitsSelected: any = [];

	constructor(private modalService: NgbModal,public sanitizer: DomSanitizer,private ordersService: OrdersService, private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private paymentsService: PaymentsService, private fb: UntypedFormBuilder, private customerService: CustomerService, private route: ActivatedRoute, public router: Router) {

		this.color = this.globalSearchService.getColor();

	}

	ngOnInit(): void {

		this.route.params.subscribe(params => {
			const debtorno = params['id'];
			const branchcode = params['branch'];
			if(params['id'] != '' && params['id']) {
				this.paymentsService.getCustomerAccountReceivables(debtorno, branchcode).subscribe((results: any) => {
					this.customer = results;
					this.searchString.setValue(results.name);
					this.flip = 'inactive';
				});
			} else {
				this.flip = 'active';
			}

		});
	}

	toggleFlip() {
		this.flip = (this.flip == 'active') ? 'inactive': 'active';
	}



	selectCustomer(customer: any) {
		//todo dont do this

		this.customersearching = false;

		this.searchString.setValue(customer.name);
		this.customerSearchInline.setValue(customer.name);
		this.searchByAmount.setValue('');
		this.searchByInvoice.setValue('');
		this.customer = customer;
		//this.router.navigate(['/receivables/payment-entry/' + customer.debtorno + '/' + customer.branchcode]);
	}

	amountSearch(event: any) {

		if (this.customersearching) {
			this.customersearching.unsubscribe()
		}

		this.customersearching = this.paymentsService.getAmountSearch(event).subscribe(async (results: any) => {

				this.amountResults = results;

		});
	}

	currentInvoiceSearchDate(event:any) {
		if(event != '') {
			this.customer.transactions = this.globalSearchService.filterItem(this.alldebits, event, 'trandate');
		} else {
			this.customer.transactions = this.alldebits
		}
	}

	currentInvoiceSearch(event:any) {
		if(event != '') {
			this.customer.transactions = this.globalSearchService.filterItem(this.alldebits, event, 'trandate,total,transno');
		} else {
			this.customer.transactions = this.alldebits
		}
	}

	currentPaymentsSearchDate(event:any) {
		if(event != '') {
			this.customer.payments = this.globalSearchService.filterItem(this.allcredits, event, 'trandate');
		} else {
			this.customer.payments = this.allcredits
		}
	}

	currentPaymentsSearch(event:any) {
		if(event != '') {
			this.customer.payments = this.globalSearchService.filterItem(this.allcredits, event, 'trandate,total,transno');
		} else {
			this.customer.payments = this.allcredits
		}
	}


	invoiceSearch(event: any) {

		if (this.customersearching) {
			this.customersearching.unsubscribe()
		}

		this.customersearching = this.paymentsService.getInvoiceSearch(event).subscribe(async (results: any) => {
			this.invoiceResults = results;
		});
	}

	customerSearch(event: any) {

		if (this.customersearching) {
			this.customersearching.unsubscribe()
		}

		let search = {
			keywords: event
		}

		this.customersearching = this.customerService.getCustomerSearch(search).subscribe(async (results: any) => {
			this.customerSearchResults = results;

			if(results.length == 1) {
				this.selectCustomer(results[0])
				this.customerSearchResults = false;
				this.customerSearchResultsTwo = false;
			}

		});
	}

	customerSearchtwo(event: any) {

		if (this.customersearching) {
			this.customersearching.unsubscribe()
		}

		let search = {
			keywords: event
		}

		this.customersearching = this.customerService.getCustomerSearch(search).subscribe(async (results: any) => {
			this.customerSearchResultsTwo = results;

			if(results.length == 1) {
				this.selectCustomer(results[0])
				this.customerSearchResults = false;
				this.customerSearchResultsTwo = false;
			}

		});
	}

	ngAfterViewInit(): void {

	}


	back() {
		//this.location.back()
	}
}
