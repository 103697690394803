import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, Injectable ,ElementRef} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { DataService } from '../../../data.service';
import { ChatService } from '../../../services/chat.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';

import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { InventoryService } from '../../../services/inventory.service';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-transfers-create',
  templateUrl: './transfers-create.component.html',
  styleUrls: ['./transfers-create.component.scss']
})
export class TransfersCreateComponent implements OnInit {

	@Output() transferComplete = new EventEmitter<any>();
   @ViewChild("binSelect") binSelectRef: ElementRef;
   
  locations: any = [];
  items: any = [];
  needbin: any = [];
  fromaddress: any = '';
  toaddress: any = '';
  transferForm: UntypedFormGroup;
  transferData: any = {};
  searchVal: any = '';
  
  constructor(private fb: UntypedFormBuilder,private globalSearchService: GlobalSearchService, private globalsService: GlobalsService,private inventoryService: InventoryService, private modalService: NgbModal) { }

  ngOnInit(): void {
	  this.globalsService.getUserLocations().subscribe((results: any) => {
				if (results) {
					this.locations = results;
					this.updateHeader();
				}
			});
	  this.transferForm = this.fb.group({ 'searchVal': '','transno': '','fromstkloc': '00','tostkloc':'00','inputdate':'2023-12-31','notes':'','status':0,
										  'lines':[]});
	  this.transferData = { 'header': {'transno': '','fromstkloc': '00','tostkloc':'00','inputdate':'2023-12-31','notes':'','status':0},
										  'lines':[]};
	  
	  }
  
  updateHeader(){
	  this.transferData.header.fromstkloc = this.transferForm.get('fromstkloc').value;
	  this.transferData.header.tostkloc = this.transferForm.get('tostkloc').value;
	  
	  this.inventoryService.getLocationAddress({'loccode': this.transferData.header.fromstkloc}).subscribe((results: any) => {
				if (results) {
					this.fromaddress = results.locationname +'\n'+results.deladd1+'\n'+results.deladd3+' '+results.deladd4+', '+results.deladd5;
				}
			});
	   this.inventoryService.getLocationAddress({'loccode': this.transferData.header.tostkloc}).subscribe((results: any) => {
				if (results) {
					this.toaddress = results.locationname +'\n'+results.deladd1+'\n'+results.deladd3+' '+results.deladd4+', '+results.deladd5;
				}
			});
  }
  decrement(item: any) {
		let index = this.items.indexOf(item);
		if (this.items[index].quantity > 0) {
			this.items[index].quantity -= 1;
		}
	}

	increment(item: any) {
		let index = this.items.indexOf(item);
		this.items[index].quantity += 1;
	}
	updateSearchQuantity(event: any, item: any) {
		let index = this.items.indexOf(item);
		this.items[index].quantity = event.target.value;
	}
	updateBinQuantity(event: any, item: any) {
		let index = this.needbin.indexOf(item);
		this.needbin[index].quantity = event.target.value;
	}
  addItems(event){
	  this.needbin = [];
	  event.forEach((line:any, index) => {
	  
	  if(line.bins.length > 1){
		  line.bins.forEach((bin:any, index2) => {
		  this.needbin.push({'stockid': line.stockid, 'description': line.description, 'needquantity': line.quantity, 'quantity': 0, 'bin': bin.bin, 'binmax': bin.maxqty});
		  });
	  } else {
			if(line.quantity > 0){
				this.transferData.lines.push({'stockid': line.stockid, description: line.description, 'bincode': line.bins[0].bin, 'transferqty': line.quantity, 'releasedqty': 0, 'receivedqty': 0, 
										'shipdate': '0000-00-00', 'recdate': '0000-00-00', 'narrative': '', 'completed':0});
			}
	  }
	  });
	  if(this.needbin.length > 0){
		  this.modalService.open(this.binSelectRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

			}, (reason) => {

			});
	  } 
	  this.items = [];
  }
  addBins(event){
	  event.forEach((line:any, index) => {
		if(line.quantity > 0){
			this.transferData.lines.push({'stockid': line.stockid, 'description': line.description, 'bincode': line.bin, 'transferqty': line.quantity, 'releasedqty': 0, 'receivedqty': 0, 
									'shipdate': '0000-00-00', 'recdate': '0000-00-00', 'narrative': '', 'completed':0});
		}							
	  });
	  this.needbin = [];
  }
  
  removeItem(item: any){
     let index = this.transferData.lines.indexOf(item);
		this.transferData.lines.splice(index, 1)
  }

  itemSearch(){
	  let data = { 'keywords': this.transferForm.get('searchVal').value, 'fromstkloc': this.transferData.header.fromstkloc, 'tostkloc': this.transferData.header.tostkloc};
	  this.inventoryService.getTransferItems(data).subscribe((results: any) => {
				if (results) {
					this.items = results;
				}
			});
  }
  
  
  submitTransfer(){
  
	if(this.transferData.header.fromstkloc == this.transferData.header.tostkloc){
		this.globalSearchService.showNotification('Must Transfer to Different Location', 'warning', 'bottom', 'left');
	} else if (this.transferData.lines.length == 0){
		this.globalSearchService.showNotification('Must Transfer at least One Item', 'warning', 'bottom', 'left');
	} else {
		let data = {'fromstkloc': this.transferData.header.fromstkloc, 'tostkloc': this.transferData.header.tostkloc, 'notes': ''};
		this.inventoryService.createTransfer(data).subscribe((results: any) => {
		
			if(results.transno != ''){
				this.transferData.header.transno = results.transno;
				
				
				this.transferData.lines.forEach((line:any, index) => {
					line.transno = results.transno;
					line.transferlineno = index;
					this.inventoryService.addTransferLine(line).subscribe((results: any) => {});
				});
				this.transferForm = this.fb.group({ 'searchVal': '','transno': '','fromstkloc': '00','tostkloc':'00','inputdate':'2023-12-31','notes':'','status':0,
									  'lines':[]});
				this.transferData = { 'header': {'transno': '','fromstkloc': '00','tostkloc':'00','inputdate':'2023-12-31','notes':'','status':0},
									  'lines':[]};
				this.items = [];
				this.transferComplete.emit(results.transno);
				
			}
		});
	}
  }
}
