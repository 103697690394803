
import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit} from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-purchasing-grns',
  templateUrl: './purchasing-grns.component.html',
  styleUrls: ['./purchasing-grns.component.scss']
})
export class PurchasingGrnsComponent implements OnInit {
	@Input() dataset: any = [];
	@Input() title: any = '';
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	data: any = [];
	headercolumns: string[] = [
		//'assetid',
		//'decimalplaces',
		//'deliverydate',
		'orderno',
		//'fetcharge',
		//'glcode',
		//'grnbatch',
		'grnno',
		'itemcode',
		'itemdescription',
		'unitprice',
		//'jobref',
		//'podetailitem',
		'qtyrecd',
		//'quantityinv',
		'quantityord',
		//'shiptref',
		//'stdcostunit',
		'toinvoice',

	];
	statuses: any = [];
	constructor() { }

	ngOnInit(): void {
	}

	ngAfterViewInit() {

		this.data = new MatTableDataSource(this.dataset);
		this.data.sort = this.sort;
		this.data.paginator = this.paginator;

	}
	announceSortChange(event: any) {

	}

	getStatus(id: any) {
		return this.statuses.filter((st)=> {
			return st.status_id = id
		})[0];
	}

}
