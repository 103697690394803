<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{ color }}-1">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Move Transactions Between Customers</h4>
						<p class="card-category text-right">Move Transactions Between Customers</p>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-md-6">
								<mat-form-field appearance="standard">
									<mat-label>FROM CUSTOMER</mat-label>
									<input type="text" class="form-control text-right" matInput [formControl]="fromcustomer" (input)="customerSearch($event.target.value, 'from')" [matAutocomplete]="autoFrom" />
									<mat-autocomplete #autoFrom="matAutocomplete" [displayWith]="displayFn" (optionSelected)="setFrom($event.option.value)">
										<mat-option *ngFor="let option of fromresults" [value]="option">
											{{option.debtorno}} {{option.name}}
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>
							</div>
							<div class="col-md-6">
								<mat-form-field appearance="standard">
									<mat-label>TO CUSTOMER</mat-label>
									<input type="text" class="form-control text-right" matInput [formControl]="tocustomer" (input)="customerSearch($event.target.value, 'to')" [matAutocomplete]="autoTo" />
									<mat-autocomplete #autoTo="matAutocomplete" [displayWith]="displayFn" (optionSelected)="setTo($event.option.value)">
										<mat-option *ngFor="let option of toresults" [value]="option">
											<b>{{option.debtorno}}:</b> {{option.name}}
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>
							</div>
							<div class="col-md-12">
								<ng-container *ngIf="!transactions">
									Please select both a Customer From, and a Customer To.<br />
									Please Note this will only move the transactions. This will NOT remove allocations.
								</ng-container>
								<ng-container *ngIf="transactions">
									<div class="col-12 text-right" *ngIf="from && to">
										<button mat-raised-button color="accent" (click)="moveTransactions()" class="mt-2 mb-2" (disabled)="!selected.length"><i class="fa fa-send"></i> MOVE TRANSACTIONS</button>
										<div *ngIf="!selected.length" class="alert alert-info w-10">Select Transactions to move below.</div>
									</div>
									<div class="table fixTableHead" #print_table>
										<table class="table table-fixed table-hover">
											<tr>
												<th><input type="checkbox" (click)="checkAll($event)"></th>
												<th>Type</th>
												<th>Date</th>
												<th>Document#</th>
												<th>Amount</th>
											</tr>
											<tbody>
												<tr *ngFor="let t of transactions.transactions ">
													<td>
														<input class="checkbox" [checked]="(this.selected.indexOf(t)) >= 0" type="checkbox" (click)="addRemove($event, t)">
													</td>
													<td>{{ t.typename}}</td>
													<td>{{ t.trandate }}</td>
													<td>{{ t.transno }}</td>
													<td>{{ t.totalamount | currency}}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
