import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

import * as FileSaver from 'file-saver';

import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PurchasingService } from '../../services/purchasing.service';
import { PaymentsService } from '../../services/payments.service';

import { Directive, HostListener } from "@angular/core";
import { trigger, state, style, transition, animate } from '@angular/animations';
import { saveAs } from "file-saver";

@Component({
	selector: 'app-dispatches-adjust-dispatch',
	templateUrl: './dispatches-adjust-dispatch.component.html',
	styleUrls: ['./dispatches-adjust-dispatch.component.scss']
})

export class DispatchesAdjustDispatchComponent implements OnInit {
	@Input() order: any = []
	@Output() orderupdated = new EventEmitter <any> ();
	/* = {
		header: [],
		details: [],
		//porequired: '',
		//freight_tax: [],
		tax_data: {},
	};
	*/
	orderno: any = '';
	debtorno: any = '';
	branchcode: any = '';
	//not being sent in the header. on time
	reprintlink: any = '';
	dispatchlink: any = '';
	dispatching: boolean = false;
	dispatch_details: any = [];
	//container for specifics about taxes.
	taxes: any = [];

	freight_tax_total: number = 0;
	freight_charge: number = 0.00;
	payment_total: number = 0.00
	freight_taxes: any = []
	display_total: any = '';

	payments_added: any = [];

	headercolumns = [
		'stockid',
		'quantity',
		'units'
	];

	totals = {
		subtotal: 0.00,
		freight: 0.00,
		tax: 0.00,
		discount: 0.00,
		total: 0.00,
	};
	taxdatatotal: number = 0;

	prefered_vendor: any = [];
	payments: any = [];


	constructor(private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, private globalSearchService: GlobalSearchService, private purchasingService: PurchasingService, private paymentsService: PaymentsService) {}

	ngOnInit(): void {

		this.orderno = this.order.orderno


		this.order = this.order
		this.freight_charge = parseFloat(this.order.header.freightcost)
		this.debtorno = this.order.header.debtorno
		this.branchcode = this.order.header.branchcode
		this.orderno = this.order.header.orderno

		let data = {
			orderno: this.order.header.orderno,
			debtorno: this.order.header.debtorno,
			branchcode: this.order.header.branchcode,
		}

		this.paymentsService.getOrderPayments(data).subscribe((results: any) => {
			this.payments = results;
		})

		this.updateTotals();
		/*
		this.orderService.getOrder(this.orderno).subscribe((details) => {

			this.order = details
			this.freight_charge = parseFloat(this.order.header.freightcost)
			this.debtorno = this.order.header.debtorno
			this.branchcode = this.order.header.branchcode
			this.orderno = this.order.header.orderno

			let data = {
				orderno: this.order.header.orderno,
				debtorno: this.order.header.debtorno,
				branchcode: this.order.header.branchcode,
			}

			this.paymentsService.getOrderPayments(data).subscribe((results: any) => {
				this.payments = results;
			})

			this.updateTotals();
		})
		*/
	}

	updateSelected(event: any) {

	}

	updateInvoice() {
		let emit ={ order: this.order, totals: this.totals }
		this.orderupdated.emit(emit);
	}

	createInvoice() {
		if (this.order) {
			let data = {
				order: this.order,
				totals: this.totals,
				payments: this.payments_added,
				taxes: this.taxes
			}

			this.orderService.createInvoice(data).subscribe((results: any) => {
				if (results.success) {
					this.router.navigate(['/orders/success/' + results.id]);
				} else {
					alert('Error');
				}
			});
		}
	}



	updateDispatchQty(event: any, item: any) {

		if (parseFloat(item.ordered) >= parseFloat(event.target.value)) {
			item.dispatch = this.santizeNumberValue(event.target.value);
		} else {

			event.target.value = parseFloat(item.ordered);
			item.dispatch = parseFloat(item.ordered)
		}

		this.updateTotals()
	}

	setQty(event: any, item: any) {
		if (parseFloat(item.ordered) < parseFloat(event.target.value)) {
			item.dispatch = this.santizeNumberValue(event.target.value);
		} else {
			item.dispatch = item.ordered;
		}
		this.updateTotals()
		//this.getTotal();
	}

	santizeNumberValue(input: any) {
		return (parseFloat(input) == NaN || input == '') ? 0 : parseFloat(input);
	}

	updatePayments(event: any) {
		this.payments_added = event;
		this.payment_total = this.payments_added.reduce(function(accumulator, item) {
			return parseFloat(accumulator) + parseFloat(item.amount);
		}, 0);
	}

	setFreightCharge(event: any) {
		this.order.header.freightcost = this.santizeNumberValue(event.target.value);
		this.freight_charge = this.order.header.freightcost;
		this.updateTotals();
	}

	setFreightTax(event: any, tax: any) {
		tax.taxrate = this.santizeNumberValue(event.target.value);
		this.updateTotals();
	}

	setTax(event: any, item: any, taxinndex: any) {
		item.taxes[taxinndex].taxrate = this.santizeNumberValue(event.target.value);
		this.updateTotals();
	}

	getFreightTax() {
		let total = 0
		this.freight_tax_total = 0;
		let freight = this.freight_charge
		var freighttax = 0;
		if (this.order) {
			let taxauths = [];
			this.freight_taxes = this.order.freight_tax;

			//by auth
			this.order.freight_tax.forEach((tax, index) => {
				taxauths.push({ taxauthid: tax.taxauthid, value: (tax.taxrate * freight) })
			});

			this.freight_taxes = taxauths;
			//total freight tax
			freighttax = this.order.freight_tax.reduce(function(accumulator, tax) {

				return parseFloat(accumulator) + (tax.taxrate * freight);
			}, 0);
		}

		this.freight_tax_total = freighttax;
		return freighttax;
	}

	updateTotals() {
		//todo clean this up. requires two containers..
		//parse items subtotals / taxes first.
		this.totals.total = 0;
		let totaltax = 0;
		//zero out tax containers
		this.order.tax_data.authorities.forEach((item, index) => {
			this.order.tax_data.authorities[index].total = 0;
		});

		this.order.details.forEach((item, index) => {
			//subtotals
			let subtotal = (item.unitprice - (item.discountpercent * item.unitprice)) * item.dispatch;

			this.order.details[index].linesubtotal = subtotal;
			this.order.details[index].taxtotal = 0;

			//item tax breakdown
			item.taxes.forEach((tax, subindex) => {
				this.order.tax_data.authorities.filter(all => all.taxauthid == tax.taxauthid)[0].total = (tax.taxrate * subtotal);

				this.order.details[index].taxes[subindex].total = (tax.taxrate * subtotal)
			});

			let total_linetax = item.taxes.reduce(function(accumulator, line) {
				return accumulator + (line.taxrate * subtotal);
			}, 0);

			this.order.details[index].taxtotal = total_linetax;
			this.order.details[index].linetotal = total_linetax + subtotal;

			totaltax += total_linetax;
		});

		this.totals.subtotal = this.order.details.reduce(function(accumulator, line) {
			return accumulator + (line.unitprice * line.dispatch);
		}, 0);

		let freighttax = this.getFreightTax()
		//add freight tax to tax total container

		this.freight_taxes.forEach((fghtax) => {
			this.order.tax_data.authorities.forEach((tax, index) => {
				if (tax.taxauthid == fghtax.taxauthid) {
					this.order.tax_data.authorities[index].total += fghtax.value;
				}
			})
		})

		this.taxdatatotal = this.order.tax_data.authorities.reduce(function(accumulator, line) {
			return accumulator + (line.total);
		}, 0);
		//total tax includes freight tax
		this.totals.tax = totaltax + freighttax;
		this.totals.freight = this.freight_charge;
		this.totals.total = this.totals.subtotal + this.totals.freight + this.totals.tax + this.totals.discount;

		//this.display_total = (this.totals.total - this.payment_total).toFixed(2);

	}

	back(): void {
		this.location.back()
	}

	addPoItems(supplier: any) {

		let item = [{
			stockid: '',
			description: '',
			line_notes: '',
			qty: '',
			price: '',
		}];

		this.purchasingService.addToOrder(item, supplier).subscribe((result) => {

		})
	}

	ngAfterViewInit(): void {

	}

}
