<ng-template #orderdetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Order Details {{ selectedorder.invoice }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark" *ngIf="selectedorder">
		<div class="row">
			<div class="col-12">
				<div class="row">
					<div class="col-9">
						<h5 [innerHTML]="selectedorder.address"></h5>
					</div>
					<div class="col-3 text-right">
						<ul class="entry-list m-0 p-0 bordered">
							<li>
								<div class="spec-label">PO</div>
								<div class="spec-value">{{ selectedorder.po }}</div>
							</li>
						</ul>
					</div>
				</div>
				<ng-container>
					<app-dispatches-adjust-dispatch [order]="selectedorder.details" (orderupdated)="updateSelected($event)">
					</app-dispatches-adjust-dispatch>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<div class="row">
	<div class="col-12">
		<mat-form-field appearance="standard" class="ml-2 mr-2">
			<mat-label>Select Run:</mat-label>
			<mat-select [formControl]="truck" (selectionChange)="loadRun($event.value)">
				<!-- <mat-option [value]="''">All</mat-option> -->
				<mat-option *ngFor="let truck of trucks" [value]="truck.id">
					{{ truck.name }}: <b>{{ truck.driver }}</b> <span *ngIf="getActiveOrders(truck.id) > 0" class="text-danger">&nbsp;<b>({{ getActiveOrders(truck.id) }})</b></span>
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
</div>
<ng-container *ngIf="invoicesCreated.length">
	<mat-divider></mat-divider>

	<div class="row mt-2 mb-2">
		<div class="col-12 text-right">
			<button mat-raised-button color="accent" (click)="exportXlS()">XLS</button>
			&nbsp;&nbsp;&nbsp;&nbsp;
			<button mat-raised-button color="accent" (click)="printPdf()">PDF</button>
		</div>
	</div>
	<div class="table" id="paymentresults" #paymentresults>
		<h4>Orders Confirmed {{ today | date:'medium' }} </h4>
		<table class="table table-sm">
			<thead>
				<tr>
					<th>Order#</th>
					<th>Invoice#</th>
					<th>Customer</th>
					<th>Name</th>
					<th>Type</th>
					<th class="text-right">Amount</th>
					<th class="text-right">Payment</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let inv of invoicesCreated">
					<td>{{ inv.orderno }}</td>
					<td><a href="{{inv.reprintlink}}">{{ inv.transno }}</a></td>
					<td>{{inv.debtorno}}.{{inv.branchcode}}</td>
					<td [innerHTML]="inv.deliverto"></td>
					<td>
						<span *ngIf="!inv.payment_type">
							ON ACCOUNT
						</span>
						<span *ngIf="inv.payment_type">
							{{ inv?.payment_type }}
						</span>
					</td>
					<td class="text-right">{{ inv.total | currency }}</td>
					<td class="text-right">{{ inv.total_pay | currency }}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<mat-divider></mat-divider>
</ng-container>
<div class="row">
	<div class="col-12 text-right mt-3" *ngIf="dispatches.length && truck.value != '' ">
		<button mat-raised-button [disabled]="clicked" color="accent" (click)="clicked=true;createPayments();">Confirm Invoices & Clear Run</button>
	</div>
	<!-- <div class="col-12 text-center mt-3" *ngIf="!dispatches.length">
							<h5 class="ml-2">No Outstanding Orders</h5>
						</div> -->
	<div class="col-12 text-center mt-3" *ngIf=" truck.value == ''">
		<h5 class="ml-2">Select a Truck</h5>
	</div>
</div>

<ng-container *ngIf="truck.value != ''">
	<div class="row border-bottom " *ngFor="let da of dispatches;trackBy: identify">
		<ng-container>
			<div class="col-12 text-left">
				<button mat-icon-button color="accent" (click)="removeInvoice(da)">
					<mat-icon>remove</mat-icon>
				</button>
				<b>#{{ da.details.header.orderno }}</b> ({{ da.details.header.debtorno }}.{{ da.details.header.branchcode }}) {{ da.details.header.name }}
				<mat-icon class="pointer" (click)="showDetails(da)" class="smaller">tune</mat-icon>&nbsp;&nbsp;

				&nbsp;<mat-checkbox [(ngModel)]="da.sendmail">Email:</mat-checkbox>

				<mat-form-field class="d-inline" appearance="standard" *ngIf="da.sendmail">
					<mat-label>Email Address</mat-label>
					<input matInput [(ngModel)]="da.email" />
				</mat-form-field>

			</div>
			<div class="col-md-2 ml-0 mr-0 pr-0 pl-0">
				<ul class="entry-list m-0 p-0 small-font" [ngClass]="{credit: da.ordertype=='11'}">
					<li>
						<div class="spec-label">ORDER#</div>
						<div class="spec-value">{{ da.invoice }}</div>
					</li>

					<li>
						<div class="spec-label">Subtotal</div>
						<div class="spec-value">{{ da.details.totals.subtotal | currency }}</div>
					</li>
					<li>
						<div class="spec-label">Freight</div>
						<div class="spec-value">{{ da.details.totals.freight | currency }}</div>
					</li>
				</ul>
			</div>
			<div class="col-md-2 ml-0 mr-0 pr-0 pl-0">
				<ul class="entry-list m-0 p-0 small-font" [ngClass]="{credit: da.ordertype=='11'}">
					<li>
						<div class="spec-label">PO</div>
						<div class="spec-value" *ngIf="da.details.header.customerref != ''">{{ da.details.header.customerref }}</div>
						<div class="spec-value" *ngIf="da.details.header.customerref == ''">N/A</div>
					</li>
					<li>
						<div class="spec-label">Fee</div>
						<div class="spec-value">{{ da.details.totals.fet | currency }}</div>
					</li>
					<li>
						<div class="spec-label">Tax</div>
						<div class="spec-value">{{ da.details.totals.tax | currency }}</div>
					</li>
				</ul>
			</div>

			<ng-container>
				<div class="col-5 ml-0 mr-0">
					<ng-container *ngIf="da.paid && da.ordertype!='11'">
						<div class="text-right w-100">
							<h5><b>**PAID**</b></h5>
						</div>
					</ng-container>
					<ng-container *ngIf="da.ordertype!='11' && !da.paid">
						<div class="row" *ngFor="let p of da.details.payments">
							<div class="col-md-4 small-font">
								<mat-form-field appearance="standard">
									<span matPrefix>
										<mat-icon (click)="removePayment(da.details.payments, p)">remove</mat-icon>
									</span>
									<mat-label>Payment Type</mat-label>
									<mat-select required (selectionChange)="setPaymentMethod($event.value, p)" [value]="da.default_paymentmehod">
										<mat-option [value]="'0'">ON ACCOUNT</mat-option>
										<mat-option *ngFor="let method of payment_methods" [value]="method.paymentid">
											{{method.paymentname }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-md-4 ml-0 small-font">
								<mat-form-field appearance="standard">
									<mat-label>Check#</mat-label>
									<input matInput class="text-right " [value]="p.checknumber" (input)="setCheck($event.target.value, p)">
								</mat-form-field>
							</div>
							<div class="col-md-4 ml-0 mr-0 pr-0 pl-0 small-font">
								<mat-form-field appearance="standard">
									<mat-label>Payment</mat-label>
									<input matInput required class="text-right " [value]="p.payment" (input)="setPayment($event.target.value, p)" appSelectOnFocus>
									<mat-hint>Due: {{ da.details.totals.total - pTotal(da.details.payments) | currency }}</mat-hint>
								</mat-form-field>
							</div>
						</div>
					</ng-container>
				</div>
				<div class="col-md-1 ml-0 mr-0 text-center mt-3 mb-3 ">
					<button mat-mini-fab color="primary" aria-label="Invoice" (click)="addPayment(da, da.details.payments)" *ngIf="!da.paid">
						<mat-icon>call_split</mat-icon>
					</button>
				</div>
				<div class="col-2 text-right mt-0" [ngClass]="da.mustpay ? 'text-danger bolder' : '' ">
					<b>{{ da.details.header.terms }}</b><br>
					DueDate: {{ da.details.due_date }}<br>
					<b>Total:</b> {{ da.details.totals.total | currency }}
				</div>
			</ng-container>
		</ng-container>
	</div>
</ng-container>
