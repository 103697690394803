import { Component, OnInit,ElementRef,ViewChild , Input, Output, EventEmitter} from '@angular/core';
import { InventoryService } from '../../services/inventory.service';
import { UntypedFormControl } from '@angular/forms';
@Component({
	selector: 'app-purchasing-bin-select',
	templateUrl: './purchasing-bin-select.component.html',
	styleUrls: ['./purchasing-bin-select.component.scss']
})
export class PurchasingBinSelectComponent implements OnInit {
	@ViewChild("stockid") stockidField: ElementRef;
	@Input() bin : any = '';
	@Output() updateBin = new EventEmitter<any>();
	bins: any = [];
	newBin = new UntypedFormControl(this.bin);
	filteredOptions: any = [];
	constructor(private inventoryService: InventoryService) {}

	ngOnInit(): void {
		this.inventoryService.getBins().subscribe((bins: any) => {
			this.bins = bins;
			this.filteredOptions = bins
		});

		this.newBin.valueChanges.subscribe(newValue=>{
			if(!this.filteredOptions) {
				this.filteredOptions = this.bins
			}
			this.filteredOptions = this.filterItem(this.bins, newValue, 'location');
		});

	}

	changeBin(value: any) {
		this.updateBin.emit(value)
	}

	filterItem(input: any, term: any , keys: any ){
		var result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) &&	new RegExp(term, 'gi').test(item[key])));
		return result
	}
}
