import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { Location } from '@angular/common'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormGroup, FormControl } from '@angular/forms';
import { SystemService } from '../../services/system.service'
import { GlobalSearchService } from '../../services/globalsearchservice.service'
@Component({
	selector: 'app-mail',
	templateUrl: './mail.component.html',
	styleUrls: ['./mail.component.scss']
})
export class MailComponent implements OnInit {
	emailForm: UntypedFormGroup;
	color: string = 'blue';
	constructor(private globalSearchService: GlobalSearchService, private systemService: SystemService, private location: Location,private fb: UntypedFormBuilder) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {

		this.emailForm = this.fb.group({
			config_mail_parameter: [],
			config_mail_smtp_hostname: [],
			config_mail_smtp_username: [],
			config_mail_smtp_password: [],
			config_mail_smtp_port: [],
			config_mail_smtp_timeout: [],
			config_mail_engine: [],
			config_mail_alert_email: [],

		});

		this.loadForm();

	}

	loadForm() {
		this.systemService.getEmailSettings().subscribe( (result: any) => {

			this.emailForm = this.fb.group({
				config_mail_parameter: [result.config_mail_parameter],
				config_mail_smtp_hostname: [result.config_mail_smtp_hostname],
				config_mail_smtp_username: [result.config_mail_smtp_username],
				config_mail_smtp_password: [result.config_mail_smtp_password],
				config_mail_smtp_port: [result.config_mail_smtp_port],
				config_mail_smtp_timeout: [result.config_mail_smtp_timeout],
				config_mail_alert_email:  [result.config_mail_alert_email],
				config_mail_engine: [result.config_mail_engine]
			});
		});
	}

	saveEmailSettings() {
		if(this.emailForm.valid) {
			this.systemService.saveEmailSettings(this.emailForm.value).subscribe(result => {
				this.loadForm();
			});
		}
	}

	back(): void {
		this.location.back()
	}

	update() {

	}

}
