import { Component, OnDestroy, OnInit,ViewChild,ChangeDetectorRef ,AfterViewInit,ElementRef} from '@angular/core';

import { Location } from '@angular/common'

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router} from '@angular/router';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GeneralLedgerService } from '../../services/general-ledger.service';

@Component({
	selector: 'app-profit-loss',
	templateUrl: './profit-loss.component.html',
	styleUrls: ['./profit-loss.component.scss']
})
export class ProfitLossComponent implements OnInit {
	periods: any = [];
	default_period: any = [];
	default_toperiod: any = '';
	default_fromperiod: any = '';
	formGroup: UntypedFormGroup;
	loading: boolean = false;
	trial_balance: any = false;
	title: string = '';
	from_period_options: any = [];
	to_period_options: any = [];
	trial_balance_totals: any = [];
	pdflink: any = '';
	excellink: any = '';
	color: any ='';
	constructor(private location: Location, private generalLedgerService: GeneralLedgerService, private globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder) { }

	ngOnInit(): void {

		this.color = this.globalSearchService.getColor();
		this.generalLedgerService.getPeriods().subscribe(async (results: any) => {
			this.periods = results.periods
			this.from_period_options = results.periods
			this.to_period_options = results.periods

			this.formGroup = this.fb.group({
				from_period: [results.default_fromperiod, [Validators.required]],
				to_period: [results.default_toperiod, [Validators.required]],
			});
		});

	}

	updateToOptions(value: any) {

		this.to_period_options = this.periods.filter((period) => {
			return parseInt(period.periodno) >= parseInt(value)
		});
	}

	updateFromOptions(value: any) {
		this.from_period_options = this.periods.filter((period) => {
			return parseInt(period.periodno) <= parseInt(value)
		});
	}

	back() {
		this.location.back();
	}

	getTrialBalance() {

		if(this.formGroup.valid) {
			this.loading= true;
			this.generalLedgerService.getProfitAndLoss(this.formGroup.value).subscribe(async (results: any) => {
				this.loading= false;
				this.title = results.title
				this.trial_balance = results.data;
				this.trial_balance_totals = results.total;
				this.pdflink = results.pdflink;
				this.excellink = results.excellink;

			});
		}

	}

}
