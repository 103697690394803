import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, Injectable ,ElementRef} from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { DataService } from '../../../data.service';
import { ChatService } from '../../../services/chat.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';

import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

import { Message } from '../../../classes/message';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { UsersService } from '../../../services/users.service';
import { InventoryService } from '../../../services/inventory.service';
import { PrintService } from '../../../services/print.service'
import { trigger, state, style, transition, animate } from '@angular/animations';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-transfers-release',
  templateUrl: './transfers-release.component.html',
  styleUrls: ['./transfers-release.component.scss']
})
export class TransfersReleaseComponent implements OnInit {

  @ViewChild("binSelect") releaseRef: ElementRef;
  transferlist: any =  [];
  color: string = 'blue';
  locations: any = [];
  user: any = [];
  transferData: any = {};
  items: any = [];
  toaddress: any = '';
  fromaddress: any = '';
  
  statuses: any = [{'code': '0','description': ' Entered '},
				   {'code': '1','description': ' Released '},
				   {'code': '2','description': ' Received '},
				   {'code': '3','description': ' Canceled '}]
  searchForm: UntypedFormGroup;
  constructor(private fb: UntypedFormBuilder, private globalsService: GlobalsService,private globalSearchService: GlobalSearchService,private inventoryService: InventoryService, private modalService: NgbModal) { }

  ngOnInit(): void {
  
  this.globalsService.getUserLocations().subscribe((results: any) => {
			if (results) {
				this.locations = results;
			}
		});
  
  this.globalSearchService.user.subscribe( result => {
			this.user = result;
		});
  this.searchForm = this.fb.group({'fromloc' : this.user.user.defaultlocation.loccode,
										 'toloc' : '',
										 'startdate':'',
										 'enddate': '',
										 'status': '0'});
										 
	this.getTransfers();
  }
  
  updateBinQuantity(event: any, item: any) {
		let index = this.items.indexOf(item);
		this.items[index].quantity = event.target.value;
	}
  getTransfers(){
  
		this.inventoryService.getTransfers(this.searchForm.value).subscribe((results: any) => {
			if (results) {
				this.transferlist = results;
			}
		});
  }
  
  getTransferData(transno){
  
		this.inventoryService.getTransferData({'transno':transno}).subscribe((results: any) => {
			if (results) {
				this.transferData = results;
				this.items = results.lines;
				this.toaddress = results.transferheader.locationname +'\n'+results.transferheader.deladd1+'\n'+results.transferheader.deladd3+' '+results.transferheader.deladd4+', '+results.transferheader.deladd5;
				this.fromaddress = results.transferheader.shipname +'\n'+results.transferheader.shipadd1+'\n'+results.transferheader.shipcity+' '+results.transferheader.shipstate+', '+results.transferheader.shipzip;		
		
				this.modalService.open(this.releaseRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {
				
		}, (reason) => {

		});
			}
		});
  }
	releaseItems(items){
	
	let data = {'transno': this.transferData.transferheader.transno, 'fromstkloc': this.transferData.transferheader.fromstkloc, lines: items};
	this.inventoryService.releaseTransfer(data).subscribe((results: any) => {
			if (results.success == true) {
				this.globalSearchService.showNotification('Transfer Released', 'success', 'bottom', 'left');
				this.modalService.dismissAll();
				this.getTransfers();
			}
		});
	}
}
