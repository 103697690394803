import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common'
import { GlobalsService } from '../../services/globals.service'
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-store-maintenance',
  templateUrl: './store-maintenance.component.html',
  styleUrls: ['./store-maintenance.component.scss']
})
export class StoreMaintenanceComponent implements OnInit {

  @ViewChild("storeEdit") storeEditRef: ElementRef;
  stores:any = [];
  taxgroups:any = [];
  zones: any = [];
  locations: any = [];
  areas: any = [];
  salestypes: any = [];
  bankaccounts: any = [];
  
  storeForm: UntypedFormGroup;
  
  constructor(private fb: UntypedFormBuilder, private globalsService: GlobalsService, private globalSearchService: GlobalSearchService, private modalService: NgbModal) { }

  ngOnInit(): void {
  }
  
  addStore(){
  this.storeForm = this.fb.group({
			store_id: [''],
			loccode: [''],
			locationname: ['',Validators.required],
			deladd1: ['',Validators.required],
			deladd2: [''],
			city: ['',Validators.required],
			state: ['',Validators.required],
			zip: ['',Validators.required],
			phone_num: ['',Validators.required],
			email: [''],
			store_code: ['',Validators.required],
			rcpt_mess1: [''],
			rcpt_mess2: [''],
			rcpt_mess3: [''],
			rcpt_mess4: [''],
			rcpt_mess5: [''],
			rcpt_mess6: [''],
			drawfrom: ['00'],
			areacode: ['00'],
			taxgroupid: ['1'],
			taxcertid: ['1'],
			taxnonid: ['1'],
			tax_id: ['1'],
			tax_rate_ts: ['0.00'],
			tax_rate_lux: ['0.00'],
			tax_rate_fre: ['0.00'],
			cc_merchant_id: [''],
			cc_account_id: [''],
			overridesalestype: [''],
			enabled: ['1'],
			bankaccount: [''],
			
		});

		this.modalService.open(this.storeEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
  }
  
  saveStore(){
  if (this.storeForm.valid) {
			this.globalsService.saveStore(this.storeForm.value).subscribe(result => {
				this.stores = result
				this.modalService.dismissAll();
			})
		}
  
  }
  
  editStore(store: any){
	this.storeForm = this.fb.group({
			store_id: [store.store_id],
			loccode: [store.loccode],
			locationname: [store.locationname,Validators.required],
			deladd1: [store.deladd1,Validators.required],
			deladd2: [store.deladd2],
			city: [store.city,Validators.required],
			state: [store.state,Validators.required],
			zip: [store.zip,Validators.required],
			phone_num: [store.phone_num,Validators.required],
			email: [store.email],
			store_code: [store.store_code,Validators.required],
			rcpt_mess1: [store.rcpt_mess1],
			rcpt_mess2: [store.rcpt_mess2],
			rcpt_mess3: [store.rcpt_mess3],
			rcpt_mess4: [store.rcpt_mess4],
			rcpt_mess5: [store.rcpt_mess5],
			rcpt_mess6: [store.rcpt_mess6],
			drawfrom: [store.drawfrom],
			areacode: [store.areacode],
			taxgroupid: [store.taxgroupid],
			taxcertid: [store.taxcertid],
			taxnonid: [store.taxnonid],
			tax_id: [store.tax_id],
			tax_rate_ts: [store.tax_rate_ts],
			tax_rate_lux: [store.tax_rate_lux],
			tax_rate_fre: [store.tax_rate_fre],
			cc_merchant_id: [store.cc_merchant_id],
			cc_account_id: [store.cc_account_id],
			overridesalestype: [store.overridesalestype],
			enabled: [store.enabled],
			bankaccount: [store.bankaccount],
			
		});

		this.modalService.open(this.storeEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
  }
  
  ngAfterViewInit() {

		this.globalsService.getStores().subscribe(results => {
			this.stores = results;
		});
		this.globalsService.getTaxes().subscribe(details => {
			this.taxgroups = details;
		});
		this.globalSearchService.zones.subscribe((results: any) => {
			this.zones = results;
		});
		this.globalSearchService.salestypes.subscribe(details => {
			this.salestypes = details;
		});

		this.globalSearchService.locations.subscribe(details => {
			this.locations = details;
		});

		this.globalSearchService.areas.subscribe(details => {
			this.areas = details;
		});
		this.globalSearchService.bankaccounts.subscribe(details => {
			this.bankaccounts = details;
		});
  }
}
