<div class="main-content" *ngIf="glaccounts">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{ color }}-1">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Journal Entry</h4>
						<p class="card-category text-right">GL Journal Entry</p>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-md-4">
								<form [formGroup]="accountForm" class="text-dark" (submit)="addValue()">
									<div class="col-md-12">
										<mat-form-field appearance="standard">
											<mat-label>Journal Type</mat-label>
											<mat-select formControlName="type">
												<mat-option *ngFor="let type of journal_types" [value]="type">{{type}}</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-12">
										<mat-form-field class="full-width" appearance="standard">
											<mat-label>Transaction Date</mat-label>
											<input matInput [matDatepicker]="picker" formControlName="trandate">
											<mat-hint>MM/DD/YYYY</mat-hint>
											<mat-datepicker-toggle matSuffix [for]="picker">
												<mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
											</mat-datepicker-toggle>
											<mat-datepicker #picker></mat-datepicker>
										</mat-form-field>
									</div>
									<div class="col-md-12">
										<app-gl-account-lookup (glselected)="addGl($event)"></app-gl-account-lookup>
									</div>
									<div class="col-md-12">
										<mat-form-field class="full-width" appearance="standard">
											<mat-label>Description</mat-label>
											<input matInput required formControlName="description" placeholder="Description" appSelectOnFocus>
										</mat-form-field>
									</div>
									<div class="col-md-12">
										<mat-form-field class="full-width" appearance="standard">
											<mat-label>Value</mat-label>
											<input matInput required type="number" formControlName="debitcredit" placeholder="Debit" >
										</mat-form-field>
									</div>
									<div class="row">
										<div class="col-md-12 text-left">
											<button mat-raised-button matInput color="accent">Add</button>
										</div>
									</div>
								</form>
							</div>
							<div class="col-md-8 ">
								<ng-container *ngIf="debits_credits.length">
									<h4>Journal Summary</h4>
									<div class="row">
										<ng-container *ngIf="!sending">
											<div class="col-md-8">
												<button class="btn btn-sm btn-success" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></button>
												&nbsp;&nbsp;
												<button class="btn btn-sm btn-danger" (click)="exportPdf();"><i class="fa fa-file-pdf-o"></i></button>
											</div>
										</ng-container>
										<ng-container *ngIf="sending">
											<svg class="spinner" width="30px" height="30px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
												<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
											</svg>
										</ng-container>
										<div class="col-md-4 text-right mt-2 mb-2 " *ngIf="requiredToBalance() == 0">
											<button mat-raised-button matInput color="accent" (click)="postJournal()">Post Journal</button>
										</div>
									</div>
									<div class="scrolling-wrapper">
										<h5 *ngIf="requiredToBalance() != 0" class="bg-danger text-white text-center">Required to Balance: {{ requiredToBalance() | currency }} <small><br>**The journal must balance i.e. debits equal credits before it can be processed.</small></h5>
										<h5 *ngIf="requiredToBalance() == 0" class="bg-success text-white text-center">In Balance: {{ requiredToBalance() | currency }}</h5>
										<div class="table scrolling-left" *ngIf="debits_credits.length" #print_table>
											<table class="table table-sm table-fixed">
												<tr>
													<th>Account</th>
													<th>Description</th>
													<th class="text-right">Debit </th>
													<th class="text-right">Credit </th>
													<th></th>
												</tr>
												<tr *ngFor="let d of debits_credits">
													<td>{{d.account.accountcode}}-{{ d.account.accountname}}</td>
													<td>{{d.description}}</td>
													<td class="text-right">
														<span *ngIf="d.debitcredit >= 0" class="text-dark bold">
															{{d.debitcredit | currency}}
														</span>
													</td>
													<td class="text-right">
														<span *ngIf="d.debitcredit < 0" class="text-danger bold">
															{{ d.debitcredit | currency}}
														</span>
													</td>
													<td (click)="removeDC(d)">
														<mat-icon color="accent">remove</mat-icon>
													</td>
												</tr>
												<ng-container *ngIf="debits_credits.length">
													<tr>
														<td><b>Total</b></td>
														<td></td>
														<td class="text-right"><b>{{ getDebitTotal() | currency }}</b></td>
														<td class="text-right"><b>{{ getCreditTotal() | currency }}</b></td>
														<td></td>
													</tr>
												</ng-container>
											</table>
										</div>
									</div>
								</ng-container>
								<ng-container *ngIf="!debits_credits.length">
									<h5>General Ledger Journal Entry</h5>
									<p>
										**The journal must balance i.e. debits equal credits before it can be processed.
									</p>
									<p>
										Values will display here as you enter them.
									</p>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
