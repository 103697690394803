import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit} from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

import * as FileSaver from 'file-saver';

import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { CreditService } from '../../services/credit.service';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PurchasingService } from '../../services/purchasing.service';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { Directive, HostListener } from "@angular/core";
import { trigger, state, style, transition, animate } from '@angular/animations';
import { saveAs } from "file-saver";


@Component({
  selector: 'app-credit-success',
  templateUrl: './credit-success.component.html',
  styleUrls: ['./credit-success.component.scss']
})
export class CreditSuccessComponent implements OnInit {
transactionid: any = '';
	invoice: any = [];
	reprintlink: any = '';
    sendemail: any = '';
    customerdata: any = [];
	printed: boolean = false;
	config: any =[];
	color: any = 'blue';
    @ViewChild("emailData") emailRef: ElementRef;
	baselink: string = '';

	constructor(private route: ActivatedRoute,public customerService: CustomerService, private creditService: CreditService, private modalService: NgbModal, private location: Location,public router: Router,private globalsService: GlobalsService, private globalSearchService: GlobalSearchService, private purchasingService: PurchasingService) { }

	ngOnInit(): void {
		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		});
		this.color = this.globalSearchService.getColor();


		this.route.params.subscribe(params => {
			this.transactionid = params.transno
			this.creditService.getTransactionById(params.transno).subscribe((details: any) => {
				this.invoice = details
				this.reprintlink = this.config.apiServer.baseUrl+this.config.apiServer.creditLink + details.id
				if(!this.printed) {
					this.printed = true;
					//this.goToLink(this.reprintlink);
				}
                this.customerService.getDebtorWithBranch(this.invoice.debtorno, this.invoice.branchcode).subscribe((results: any) => {
                    this.customerdata = results;
                })
			})
		})
	}

	back(): void {
		this.location.back()
	}

	goToLink(url: string){
		window.open(url);
	}
    sendEmail(transaction: any){
            this.sendemail = '';
            if(this.sendemail == ''){
                for(let x = 0; x < this.customerdata.branches.length; x++){
               if(this.customerdata.branches[x].branchcode == transaction.branchcode){
                    this.sendemail = this.customerdata.branches[x].email;
                    }
                }
            }

            this.modalService.dismissAll();
            this.modalService.open(this.emailRef, {ariaLabelledBy: 'modal-title', size: 'md' }).result.then((result)=>{},(reason)=>{

            });

    }
    pushEmail(){
    if(!this.isEmail(this.sendemail)){
            this.sendemail = '';
            alert('Please Enter a Valid Email Address');
            } else {
                    this.globalsService.emailCreditNote(this.invoice.id, 'email', this.sendemail).subscribe((result: any)=> {
                    })
                    this.modalService.dismissAll();
            }
    }
    isEmail(search: string): boolean {
		var serchfind: boolean;
		let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		serchfind = regexp.test(search);
		return serchfind
	}
}
