import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { ReportsService } from '../../services/reports.service';
import { CardLists, Card } from '../../classes/orders';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2CompleterModule } from 'ng2-completer';
import { Location } from '@angular/common'
import { PrintService } from '../../services/print.service'
import * as FileSaver from 'file-saver';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'app/services/customer.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
	selector: 'app-cpsls',
	templateUrl: './cpsls.component.html',
	styleUrls: ['./cpsls.component.scss']
})

export class CpslsComponent implements OnInit {
	@Input() customer: any = false;
	@Input() menuapp = true;
	@ViewChild("orderView") orderStatusViewRef: ElementRef;
	@ViewChild("details") detailsRef: ElementRef;
	@ViewChild('print_table') printtable: ElementRef;
	@ViewChild('brandFilter') brandsRef: MatSelect;
	@ViewChild('branchFilter') branchesRef: MatSelect;

	itemSearchRef = new FormControl('');
	orderSearchRef = new FormControl('');
	conBrRef = new UntypedFormControl('C');
	placedFromRef = new UntypedFormControl('');
	branchRef = new UntypedFormControl('');
	salespersonRef = new UntypedFormControl('');
	filterresults: any = [];
	filteredItems: any = [];
	orderData: CardLists;
	customerData: any = [];
	openOrderData: any = [];
	orders: any = [];
	orderSearch: any = [];
	orderResults: any = false;
	ordersFound: any = [];
	todate = new Date();
	fromdate = new Date();
	reportCriteria = {
		fromdate: '',
		debtorno: '',
		todate: '',
		locs: [],
		conbr: '',
		brands: []
	};

	@Input() name: string;

	color: any = 'blue';
	config: any = [];
	pickinglink: any = '';
	filename: any = 'Customer_productline_sales_';
	invoicelink: any = '';
	displaydetails: any = false;
	orderdetails: any = false;
	fromdateCtrl = new UntypedFormControl('');
	todateCtrl = new UntypedFormControl('');
	brands = new UntypedFormControl('');
	sending: any = false;
	branches: any = [];
	salespeople: any = [];
	properties: any = [];

	conbr: any = [{ label: 'Consolidated', value: 'C' }, { label: 'Branch', value: 'B' }];
	placedfrom: any = [{ label: 'Included', value: '' }, { label: 'Excluded', value: '1' }, { label: 'Only', value: '2' }];
	displayLookup: boolean;
	ref_location: any = false;
	user: any = false;

	ngOnInit(): void {}
	constructor(private customerService: CustomerService, private activatedRoute: ActivatedRoute, private globalSearchService: GlobalSearchService, private printService: PrintService, private globalsService: GlobalsService, public router: Router, private reportsService: ReportsService, private location: Location, private modalService: NgbModal) {

		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});

		this.globalSearchService.user.subscribe(results => {
			//only run if user is definied
			if (this.user) {
				if (results.user.defaultlocation.loccode != this.ref_location) {
					this.branchRef.setValue([this.user.user.defaultlocation.loccode]);
					this.ref_location = false;
				}
			}

			this.user = results

			if (!this.ref_location) {
				this.ref_location = this.user.user.defaultlocation.loccode;

				this.branchRef.setValue([this.user.user.defaultlocation.loccode]);
			}
		});

		this.globalSearchService.mesagesallowed.next(false);

		this.reportsService.getBranches().subscribe((results: any) => {
			this.branches = results;
		});

		this.globalsService.getItemSetup().subscribe( (results: any) => {
			this.properties = results
		});

		this.reportsService.getSalesPeople().subscribe((results: any) => {
			this.salespeople = results;
		});

		var today = new Date();
		var priorDate = new Date(today.getFullYear(), today.getMonth(), 1);
		this.fromdate = priorDate;

		this.fromdateCtrl.setValue(this.fromdate);
		this.todateCtrl.setValue(this.todate);

		if(!this.customer){
			this.activatedRoute.params.subscribe(({ id }) => {
				if (!isNaN(id)) {
					this.orderResults = false;
					this.customerService.getDebtor(id).subscribe((data: any)=>{
						this.customer = data.customer;
						this.getCPSLS();
						this.displayLookup = false;
					})
				}
			});
		} else {
			this.getCPSLS();
		}


	}

	public getCPSLS() {

		this.sending = true;

		this.reportCriteria = {
			fromdate: this.fromdateCtrl.value.toISOString().split('T')[0],
			debtorno: this.customer.debtorno,
			todate: this.todateCtrl.value.toISOString().split('T')[0],
			locs: this.branchRef.value,
			conbr: this.conBrRef.value,
			brands: this.brands.value,
		};

		this.reportsService.getCPSLS(this.reportCriteria).subscribe(r => {
			this.sending = false;
			this.orderResults = r;
		})
	}

	viewCustomer(prodline: any, loccode: any) {
		let drilldown = {
			pl: prodline,
			debtorno: this.reportCriteria.debtorno,
			loc: loccode,
			fromdate: this.reportCriteria.fromdate,
			todate: this.reportCriteria.todate,
			locs: this.reportCriteria.locs,
			conbr: this.reportCriteria.conbr
		};
		this.reportsService.getCPSLSDrill(drilldown).subscribe((order: any) => {
			this.orderdetails = order
			this.modalService.open(this.orderStatusViewRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		});
	}
	openDocument(transaction: any, content: any, display: string) {

		switch (transaction.DocType) {
			case 'Credit':
				this.globalsService.getCreditNote(transaction.id, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
				})
				break;
			case 'Invoice':
				this.globalsService.getInvoice(transaction.id, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
				})

				break;
		}
	}
	exportPdf() {

		let encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const today = new Date();
		let data = {
			content: encoded,
			filename: this.filename + today.getMonth() + today.getDate()+today.getFullYear(),
			title: this.customer.debtorno+'.'+this.customer.branchcode+' ' +this.customer.brname+' Customer Product Line Sales ',
			subtitle: this.getTitle()+' '+this.fromdateCtrl.value.toLocaleDateString()+' - '+this.todateCtrl.value.toLocaleDateString()+' created '+today.toLocaleString()
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	getTitle() {
		var consolidate_branch = (this.reportCriteria.conbr == 'C') ? 'Consolidated Report' : 'Branch Report';
		var locations = (this.reportCriteria.locs.length == this.branches.length || this.reportCriteria.locs.length == 0) ? ' For All Locations' : ' ';
		if(this.reportCriteria.locs.length != this.branches.length && this.reportCriteria.locs.length > 0) {
			locations = " For Locations:";
			this.reportCriteria.locs.forEach(l => {
				locations += ' '+l+ ' ';
			})
		}

		return consolidate_branch + locations;
	}

	exportXls() {
		const today = new Date();
		let encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: this.filename+ today.getMonth() + today.getDate()+today.getFullYear(),
			title: this.customer.debtorno+'.'+this.customer.branchcode+' ' +this.customer.brname+' Customer Product Line Sales ',
			subtitle: this.getTitle()+' '+this.fromdateCtrl.value.toLocaleDateString()+' - '+this.todateCtrl.value.toLocaleDateString()+' created '+today.toLocaleString()
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}
	back(): void {
		this.location.back()
	}

	selectCustomer(event: any){
		this.customer = {};
		this.customer = event;
		this.displayLookup = false;
		this.router.navigate(["/customers/cpsls", event.debtorno]);
		this.getCPSLS();
	}

	showCustomerLookup(){
		this.displayLookup == true ? this.displayLookup = false : this.displayLookup = true;
	}

	selectAllToggle(filter_name: string) {
		let filter;
		switch(filter_name){
			case 'brand':
				filter = this.brandsRef;
			break;
			case 'branch':
				filter = this.branchesRef;
			break;
			default:
				return;
		}
		const selected = filter.options.find(opt => opt.selected);
		if (selected) {
			filter.options.forEach((item: MatOption) => item.deselect());
		} else {
			filter.options.forEach((item: MatOption) => item.select());
		}
	}
}
