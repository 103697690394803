import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../../../services/customer.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DispatchService } from '../../../services/dispatch.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalsService } from '../../../services/globals.service';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';

declare var $: any;


@Component({
	selector: 'app-customer-shipto-edit',
	templateUrl: './customer-shipto-edit.component.html',
	styleUrls: ['./customer-shipto-edit.component.scss'],
	animations: [
		trigger("grow", [ // Note the trigger name
			transition(":enter", [
				// :enter is alias to 'void => *'
				style({ height: "0", width: "0", overflow: "hidden" }),
				animate(200, style({ height: "*", width: "*" })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(":leave", [
				// :leave is alias to '* => void'
				animate(300, style({ height: 0, width: 0, overflow: "hidden" })),
				animate('300ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class CustomerShiptoEditComponent implements OnInit {
	@Input() customerdata;
	@Output() editing = new EventEmitter < boolean > ();
	@Output() newcustomerdata = new EventEmitter < boolean > ();

	branchForm: any = false;
	taxes: any = [];
	alltaxes: any = [];
	currencies: any = [];
	locations: any = [];
	salesman: any = [];
	shipvia: any = [];
	zones: any = [];
	areas: any;
	status_msg: string = '';
	disabledbranch: boolean = false;
	tirefee: boolean = false;
	defaultbranchcustomer: boolean = true;
	paymentmethods: any = [];
	routes: any = [];
	//todo make this dynamic

	formEditAllowed = [
		'debtorno',
		'branchcode',
		'brname',
		'contactname',
		'braddress1',
		'braddress2',
		'braddress3',
		'braddress4',
		'braddress5',
		'braddress6',
		'email',
		'phoneno',
		'faxno',
		'salesman',
		'specialinstructions',
		'defaultlocation',
		'defaultshipvia',
		'tax_ref',
		'taxgroupid',
		'area',
		'default_paymentmehod',
		'disabletrans',
		'route'
		//'contactname'
	]

	constructor(public globalSearchService: GlobalSearchService, private dispatchService: DispatchService, private fb: UntypedFormBuilder, public globalsService: GlobalsService, public customerService: CustomerService) {

	}

	ngOnInit(): void {

		this.dispatchService.getRoutes().subscribe((r: any) => {
			this.routes = r
		});


		var details = this.customerdata
		var controls = [];
		const formGroup: UntypedFormGroup = new UntypedFormGroup({});
		Object.keys(details).forEach(key => {
			if (this.formEditAllowed.includes(key)) {
				let value = (details[key]) ? details[key] : '';
				var options = [];
				if (key == 'disabletrans' && value == '1') {
					value = true;
				}

				if (key == 'disabletrans' && value == '0') {
					value = false;
				}

				if(key == 'tax_ref') {
					options = [Validators.minLength(9), Validators.maxLength(20)];
				}

				controls.push({ name: key, control: new UntypedFormControl(value, options) })
			}
		});
		controls.forEach(f => {
			formGroup.addControl(f.name, f.control, {})
		});

		this.branchForm = formGroup;
		this.branchForm.markAllAsTouched();
		this.branchForm.get('tax_ref').valueChanges.subscribe(newvalue => {
			if (newvalue != '') {
				var currentValue = this.alltaxes.filter(v => {
					return v.taxgroupid == this.branchForm.get('taxgroupid').value
				})[0];

				if (currentValue) {
					currentValue = currentValue.taxgroupdescription
				} else {
					currentValue = '';
				}

				this.filterTaxAuths(true, currentValue);
			} else {
				this.filterTaxAuths(false, '');
			}
		});
	}

	filterTaxAuths(filter: any, current: any) {

		if (filter) {

			const results = this.taxes.filter((r: any) => {
				var newvalue = '';

				if (current != '') {
					newvalue = current.replace('1', '2');
				}

				if (r.taxgroupdescription.startsWith('2') ) {
					if (newvalue != '' && newvalue == r.taxgroupdescription) {
						this.branchForm.get('taxgroupid').setValue(r.taxgroupid);
						//this.globalSearchService.showNotification('Tax Group Updated to Non Taxable', 'warn', 'bottom', 'right');
					}
					return r;
				}
			})
			this.taxes = results;
		} else {
			this.taxes = this.alltaxes;
		}
	}

	ngAfterViewInit() {

		this.globalsService.getTaxes().subscribe(details => {
			this.taxes = details;
			this.alltaxes = details;
		});

		this.globalsService.getLocations().subscribe(details => {
			this.locations = details;
		});

		this.globalsService.getDefaultZones().subscribe(details => {
			this.zones = details;
		});

		this.globalsService.getAreas().subscribe(details => {
			this.areas = details;
		});

		this.globalsService.getSalesman().subscribe(details => {
			this.salesman = details;
		});

		this.globalsService.getShipVia().subscribe(details => {
			this.shipvia = details;
		});

		this.globalsService.getPaymentMethods().subscribe(details => {
			this.paymentmethods = details;
		});
	}

	cancel() {
		this.editing.emit(false);
	}

	updateBranch() {

		if (this.branchForm.valid) {
			this.customerService.updateBranch(this.branchForm.value).subscribe(details => {
				if (details.success) {
					//changing to pull direct. and select the branch - slower but more correct
					this.customerService.getDebtorWithBranch(this.branchForm.controls['debtorno'].value, this.branchForm.controls['branchcode'].value).subscribe((results: any) => {
						this.customerdata = results;
						this.newcustomerdata.emit(results);
					});

				} else {

				}
			});
		} else {
			this.globalSearchService.getFormValidationErrors(this.branchForm);
		}
	}
	updateZones(input: any) {

	}
}
