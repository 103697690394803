import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit} from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { interval, Subscription} from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router} from '@angular/router';

import { VendorService } from '../../../services/vendor.service';
import { GlobalsService } from '../../../services/globals.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { Customer } from '../../../classes/customer';
import { Location } from '@angular/common'

@Component({
  selector: 'app-vendor-settings',
  templateUrl: './vendor-settings.component.html',
  styleUrls: ['./vendor-settings.component.scss'],
  animations: [
	  trigger("grow", [ // Note the trigger name
		transition(":enter", [
		  // :enter is alias to 'void => *'
		  style({ height: "0",width: "0"}),
		  animate(200, style({ height: "*" , width: "*"})),
		  animate('200ms', style({ opacity: 1 })),
		]),
		transition(":leave", [
		  // :leave is alias to '* => void'
		  animate(100, style({ height: 0, width: 0 })),
		  animate('100ms', style({ opacity: 0 }))
		])
	  ]),
  ]
})
export class VendorSettingsComponent implements OnInit {
	@Input() vendor;
	@Output() editing = new EventEmitter<boolean>();
	@Output() newvendordata = new EventEmitter<boolean>();

	types: any = [];
	settingsForm: any = false;

	formEditAllowed = [
		'supplierid',
		'gp_margin',
		'supptype',
		'remittance',
	]

	constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder,private vendorService: VendorService, public router: Router, private location: Location, private globalsService: GlobalsService) { }

	ngOnInit(): void {

		var details = this.vendor
		var controls = [];
		const formGroup: UntypedFormGroup = new UntypedFormGroup({});
		Object.keys(details).forEach(key => {
			if(this.formEditAllowed.includes(key)) {
				let value = (details[key]) ? details[key] : '';
				controls.push({ name: key, control: new UntypedFormControl(value, []) })
			}
		});
		controls.forEach(f => {
			 formGroup.addControl(f.name, f.control, {})
		});
		this.settingsForm = formGroup;
	}

	back(): void {
		this.editing.emit(false);
	}

	onSubmit() {
		this.vendorService.updateVendor(this.settingsForm.value).subscribe((results: any) => {
			this.newvendordata.emit(results);
			this.editing.emit(false);
		});
	}

	ngAfterViewInit() {
		//bad name - types
		this.globalsService.getTypes().subscribe( (details: any) => {
			this.types = details.supptypes;
		});

	}
}
