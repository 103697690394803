import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common'
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { PrintService } from '../../services/print.service'
import * as jQuery from "jquery";

@Component({
	selector: 'app-inventory-value',
	templateUrl: './inventory-value.component.html',
	styleUrls: ['./inventory-value.component.scss']
})
export class InventoryValueComponent implements OnInit {
	@ViewChild('print_table') printtable: ElementRef;
	color: string = 'blue'
	report: any = false;
	searchForm: UntypedFormGroup;
	brands: any = []
	categories: any = [];
	locations: any = [];
	types = [
		{ value: 'S',label: 'Summary'},
		{ value: 'D',label: 'Detail'}
	];
	running: boolean = false;
	exporting: boolean = false;

	constructor(private globalsService: GlobalsService, private printService: PrintService,private globalSearchService: GlobalSearchService, private route: ActivatedRoute, private fb: UntypedFormBuilder, public router: Router, private location: Location, public inventoryService: InventoryService) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {

		this.globalsService.getLocations().subscribe((result: any) => {

			this.locations = result;
			let values = [];
			result.forEach( i=> {
				values.push(i.loccode);
			})
			this.searchForm = this.fb.group({
				brand: [''],
				categories: [''],
				loccode: [values],
				supplier: [''],
				type: ['S'],
			});

		});
		/*this.running = true;
		this.inventoryService.getInventoryValuation(this.searchForm.value).subscribe(result => {
			this.running = false;
			this.report = result;
		})
		*/
	}

	ngAfterViewInit() {
		this.inventoryService.getMfgs().subscribe(result => this.brands = result);
		this.inventoryService.getItemCategories().subscribe(result => this.categories = result);
	}

	loadData() {
		this.running = true;
		this.inventoryService.getInventoryValuation(this.searchForm.value).subscribe(result => {
			this.running = false;
			this.report = result;
		})
	}

	pdf() {
		this.exporting = true;
		let encoded: string = btoa(this.printtable.nativeElement.innerHTML);

		let data = {
			content: encoded,
			landscape: true,
			filename: 'InventoryValue',
		}

		this.printService.pdf(data).subscribe( (result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.exporting = false;
		});

	}

	getCategoryTotalUnits(category: any) {
		let cdata = this.report.categories.filter(c => {
			return c.categoryid == category
		})[0];

		return cdata.count;
	}

	getCategoryTotal(category: any) {
		let cdata = this.report.categories.filter(c => {
			return c.categoryid == category
		})[0];

		return cdata.value;
	}

	xls() {
		this.exporting = true;
		let encoded: string = btoa(this.printtable.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: 'InventoryValue',
		}

		this.printService.xls(data).subscribe( (result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.exporting = false;
		});
	}
	/*
	xls() {
		this.globalSearchService.exportJsonToExcel(this.report.details, 'InventoryEvaluationDetails');
	}
	*/
	back(): void {
		this.location.back()
	}

}
