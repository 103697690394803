import { Injectable } from '@angular/core';
import { catchError , retry} from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders,HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';
@Injectable({
	providedIn: 'root'
})

export class QantelService {
	key: string = 'UET6DFMPAXW7BKCB';
	config: any;
	user : any = [];

	constructor(private http: HttpClient,private _configSvc: RuntimeConfigLoaderService, public globalSearchService: GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		})

		this.globalSearchService.user.subscribe( (result) => {
			this.user = result;
		});

	}

	public httpOptions = {
			headers: new HttpHeaders({
			'Content-Type':	'application/json',
		})
	};

	public getOptions = {
			headers: new HttpHeaders({
		})
	};
	public addBarcode(data: any) {
		var method = 'qantel/addbarcode';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data))
		);
	}
	public itemSearch(data: any) {
		var method = 'qantel/itemsearch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data))
		);
	}
	public getOrder(oid) {
		  var method = 'qantel/getorder&oid='+oid;
		  return this.http.get(this.setEndPoint(method));
	}

	public rawSearch(data: any) {
		var method = 'qantel/rawsearch&keywords='+data;
		return this.http.get(this.setEndPoint(method));
	}

	public getItem(stockid: any) {
		var method = 'qantel/getitem&stockid='+stockid;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getPurchaseHistory(stockid: any) {
		var method = 'qantel/getitempurchase&stockid='+stockid;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getPicking() {
		var method = 'qantel/oms/fillable';
		return this.http.get(this.setEndPoint(method));
	}

	private setEndPoint(method: any) {
		 return this.getApi()+method+this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
		 return '&key='+this.config.apiCredentials.clientId+'&userid='+this.user.user.userid;
	}

	public getKey() {
		return '&apikey='+this.key;
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			return error;
		};
	}

}
