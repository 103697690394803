<mat-form-field appearance="standard">
	<mat-label>Change Bin {{ bin }}</mat-label>
	<input type="text"
		placeholder="Change Bin"
		aria-label="Change Bin"
		[maxLength]="8"
		matInput
		required
		[formControl]="newBin"
		[matAutocomplete]="transferbin"
		[(value)]="bin"
	>
	<mat-hint>{{ newBin.value.length }} / 8</mat-hint>
	<mat-autocomplete #transferbin="matAutocomplete"
		(optionSelected)="changeBin($event.option.value)"
		>
		<mat-option *ngFor="let bin of filteredOptions" [value]="bin.location">
			{{bin.location }}
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
