<div class="modal fade" id="detailsModal" tabindex="-1" role="dialog" aria-labelledby="detailsModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg" role="document">
		<div class="modal-content">
			<div class="modal-header" >
				<h4 class="modal-title" id="detailsModalLabel" *ngIf="showorder">{{ orderdetails.name }}  <br>( {{ orderdetails.cnumber }} ) </h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="focusInput()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body" id="orderdetailsbody" *ngIf="showorder">
				<div class="row" >
					<div class="col-12">
						<h4>INVOICE: <b>{{ orderdetails.oid  }}</b><br>
							STATUS: <b>{{ orderdetails.status  }}</b> <br>
							PO: <b>{{ orderdetails.po  }}</b></h4>
					</div>
				</div>
				<div class="card">
					<div class="table table-responsive">
					 <table  class="table table-sm">
						<thead>
							<tr>
								<th class="text-left">ITEM</th>
								<th class="text-center">QTY</th>
								<th class="text-center">PRICE</th>
								<th class="text-right">EXT.</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of orderdetails.details">
								<td class="text-left"><b>{{ item.ITEM_NUMBER }}</b><br>{{ item.DESCRIPTION }}</td>
								<td class="text-center">{{ item.ORDERED }} </td>
								<td class="text-center">{{ item.PRICE }} </td>
								<td class="text-right">{{ item.PRICE * item.ORDERED |  number : '1.2-2'}} </td>
							</tr>
						</tbody>
					</table>
					</div>
				</div>
				<div class="modal-footer">
					<div class="row">
						<div class="col-xs-6" id="distance"></div>
						<div class="col-xs-6" id="time"></div>
						<div class="col-xs-12 text-right">
							<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="focusInput()">Close</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<form [formGroup]="itemForm" (ngSubmit)="lookup()" class="needs-validation product-add" novalidate="">

<div class="main-content">
  <div class="container-fluid">
	<div class="card">
		<div class="card-header card-header-{{color}}-6">
			<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Inventory Lookup </h4>
			<p class="card-category">Scan or Type</p>
		</div>
		<div class="card-body">
			<div id="typography">
				<div class="card-title">
					<div class="digital-add needs-validation">
						<div class="col-12 col-md-12">
							<div class="form-group">
								<div class="input-group mb-2">
									<input #stockid formControlName="stockid" class="form-control text-right" id="stockid" type="text" required="" placeholder="* Item Search  ">
								</div>
								<div class="text-right">
									<button type="button" class="btn btn-primary btn-sm mr-1" (click)="lookup()">Search</button>
								</div>
							</div>
							<div class="text-danger" *ngIf="itemForm.get('stockid').touched && itemForm.get('stockid').hasError('required')" autofocus>Required</div>
						</div>
					</div>

					<div class="table table-responsive text-center" *ngIf="searching">
						<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</div>

					<div class="table table-responsive" *ngIf="showlist && !searching">
						<table class="table table-sm ">
							<thead>
								<tr>
									<th class="text-left">DESC</th>
									<th class="text-right">ITEM</th>
								</tr>
							</thead>
							<tbody  *ngFor="let item of itemlist | keyvalue" >
								<tr *ngIf="item.value.ITEM_NUMBER">
									<td class="text-left">{{ item.value.DESCRIPTION }}</td>
									<td class="text-right text-nowrap"><a class="btn btn-primary" (click) ="loadItem(item.value.ITEM_NUMBER)"  >{{ item.value.ITEM_NUMBER }}</a></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="table" *ngIf="!showlist && item && !searching">
					<center *ngIf="lastsearch != ''"><div class="mt-0 mb-0"><small>Searching: {{ lastsearch}}</small></div>
					</center>
					<h3 class="text-center mt-0 mb-0"><b>{{ item.details.ITEM_DESCR }}</b></h3>
					<h5 class="text-center"><small>( {{ item.details.ITEM_NUMBER }} ) </small></h5>
					<div class="card">
						  <div class="card-header card-header-tabs card-header-primary p-0">
							  <div class="nav-tabs-navigation">
								  <div class="nav-tabs-wrapper text-nowrap">
									  <ul class="nav nav-tabs col-12" data-tabs="tabs">
										  <li class="nav-item col-6 mt-0 mb-0 text-center">
											  <a mat-button class="nav-link active" href="#info" data-toggle="tab" (click)="focusInput()">
												  <i class="material-icons">code</i> Info
												  <div class="ripple-container"></div>
											  </a>
										  </li>
										  <li class="nav-item col-6 mt-0 mb-0 text-center">
											  <a mat-button class="nav-link" href="#history" data-toggle="tab" (click)="focusInput()">
												  <i class="material-icons">cloud</i>Purch
												  <div class="ripple-container"></div>
											  </a>
										  </li>
									  </ul>
								  </div>
							  </div>
						  </div>
						  <div class="card-body">
							  <div class="tab-content">
								  <div class="tab-pane active" id="info">
									<div class="row border-bottom">
										<div class="col-6 text-center text-md-left">LOCATION :</div>
										<div class="col-6 text-center text-md-left"><b>{{ item.details.LOCATION }}</b></div>
									</div>
									<div class="row border-bottom">

										<div class="col-6 text-center text-md-left">ON HAND :</div>
										<div class="col-6 text-center text-md-left"><b>{{ item.details.QTY_ON_HAND }}</b></div>
									</div>
									<div class="row border-bottom">
										<div class="col-6 text-center text-md-left">ON ORDER :</div>
										<div class="col-6 text-center text-md-left text-primary"><b>{{ item.details.QTY_ON_ORDER }}</b></div>
									</div>
									<div class="row border-bottom">
										<div class="col-6 text-center text-md-left">COMMITTED :</div>
										<div class="col-6 text-center text-md-left"><b>{{ item.details.QTY_COMMITTED }}</b></div>
									</div>
									<div class="row border-bottom">
										<div class="col-6 text-center text-md-left">AVAILABLE :</div>
										<div class="col-6 text-center text-md-left"><b>{{ item.details.QTY_ON_HAND - item.details.QTY_COMMITTED }}</b></div>
									</div>
									<div class="row border-bottom">
										<div class="col-6 text-center text-md-left">MIN/MAX :</div>
										<div class="col-6 text-center text-md-left"><b>{{ item.details.MINIMUM_QTY}} / {{item.details.MAXIMUM_QTY }}</b></div>
									</div>
									<div class="row mt-2 mb-2">
										<div class="col-12 text-center text-md-left"><h6 class="mt-0 mb-0">ORDERS</h6></div>
										<div class="col-12 text-center text-md-left">
										<span class="ml-1 mr-1" *ngFor="let order of item.orders | keyvalue">
												<a href="#" class="order-details" data-id="{{ order.value}}" class="" data-toggle="modal" data-target="#detailsModal" (click)="loadOrder(order.value)"><b>{{ order.value}}</b></a>
										</span>
										</div>

									</div>
										<div class="row" *ngIf="!item.orders">
										<div class="col-12">
											<div class="alert alert-warning">No orders</div>
										</div>
									</div>

								  </div>
								  <div class="tab-pane" id="history" >
										<div class="table text-center" *ngIf="historyloading">
											<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
												</svg>
										</div>
										<div class="col-12" *ngIf="!item.history.length && !historyloading">
											<div class="alert alert-warning">No Purchase History</div>
										</div>
										<table class="table table-sm table-responsive" *ngIf="item.history.length && !historyloading">
												<tr>
												<th class="text-left">PO</th>
												<th class="text-center">DATE</th>
												<th class="text-center">QTY</th>
												<th class="text-right">COST</th>
												<th class="text-left">SUPP</th>
											</tr>
												<tr *ngFor="let h of item.history">
												<td  class="text-left"> {{h.PO_NUMBER}}</td>
												<td  class="text-center"> {{h.DATE_REQ}}</td>
												<td class="text-center"> {{h.QTY_ORDERED}}</td>
												<td class="text-right"> {{h.UNIT_COST}}</td>
												<td class="text-left"> {{h.VENDOR_NAME}}</td>
											</tr>
										</table>
								  </div>
							  </div>
						  </div>
					  </div>
				</div>
			</div>
		</div>
	</div>
  </div>
</div>
</form>
