<ng-container *ngIf="userForm">
	<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
		<mat-tab label="Profile">
			<form [formGroup]="userForm" class="text-dark m-0 p-0" (submit)="updateUser()">
				<div class="row mt-4">
					<div class="col-md-6">
						<mat-form-field class="full-width">
							<mat-label>User Id</mat-label>
							<input matInput formControlName="userid">
						</mat-form-field>
					</div>
					<div class="col-md-6">
						<mat-form-field class="full-width">
							<mat-label>Password</mat-label>
							<input matInput formControlName="password">
						</mat-form-field>
					</div>
					<div class="col-md-6">
						<mat-form-field class="full-width">
							<mat-label>Name</mat-label>
							<input matInput formControlName="realname">
						</mat-form-field>
					</div>
					<div class="col-md-6">
						<mat-form-field class="full-width">
							<input matInput formControlName="email" placeholder="Email address" type="email">
						</mat-form-field>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4">
						<mat-form-field appearance="standard" class="">
							<mat-label>Default Location</mat-label>
							<mat-select formControlName="defaultlocation">
								<mat-option *ngFor="let loc of alllocations" [value]="loc.loccode">
									{{loc.locationname}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-2">
						<mat-form-field class="full-width" appearance="standard">
							<mat-label>Phone#</mat-label>
							<input matInput placeholder="Phone" type="text" formControlName="phone">
						</mat-form-field>
					</div>
					<div class="col-md-2">
						<mat-form-field class="full-width">
							<mat-label>Salesman Code</mat-label>
							<input matInput placeholder="Salesman Code" formControlName="salesman" type="text">
						</mat-form-field>
					</div>
					<div class="col-md-2">
						<mat-form-field class="full-width">
							<mat-label>Employee Code</mat-label>
							<input matInput placeholder="Employee Code" formControlName="empcode" type="text">
						</mat-form-field>
					</div>
					<div class="col-md-2">
						<mat-form-field class="full-width">
							<mat-label>Department</mat-label>
							<input matInput placeholder="Department" formControlName="department" type="text">
						</mat-form-field>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<mat-form-field class="full-width">
							<input matInput placeholder="Full Access" formControlName="fullaccess" type="text">
						</mat-form-field>
					</div>
					<div class="col-md-6">
						<mat-form-field appearance="standard" class="">
							<mat-label>User Group</mat-label>
							<mat-select formControlName="user_group">
								<mat-option *ngFor="let yn of usergroups" [value]="yn.user_group_id">
									{{yn.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
				<div class="row">
					<div class="col-md-3">
						<mat-form-field appearance="standard" class="">
							<mat-label>Customer Admin</mat-label>
							<mat-select formControlName="customeradmin">
								<mat-option *ngFor="let yn of yesno" [value]="yn.value">
									{{yn.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>

					</div>
					<div class="col-md-3">

						<mat-form-field appearance="standard" class="">
							<mat-label>Create Tender</mat-label>
							<mat-select formControlName="cancreatetender">
								<mat-option *ngFor="let yn of yesno" [value]="yn.value">
									{{yn.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div class="col-md-3">

						<mat-form-field appearance="standard" class="">
							<mat-label>Disabled</mat-label>
							<mat-select formControlName="blocked">
								<mat-option *ngFor="let yn of yesno" [value]="yn.value">
									{{yn.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>


					<div class="col-md-3">

						<mat-form-field appearance="standard" class="">
							<mat-label>Edit Invoice</mat-label>
							<mat-select formControlName="editinv">
								<mat-option *ngFor="let yn of yesno" [value]="yn.value">
									{{yn.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-3">
						<mat-form-field appearance="standard" class="">
							<mat-label>Dispatch Admin</mat-label>
							<mat-select formControlName="dispatchadmin">
								<mat-option *ngFor="let yn of yesno" [value]="yn.value">
									{{yn.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
				<div class="row d-none">
					<div class="col-md-12">
						<label>About Me</label>
						<mat-form-field class="full-width">
							<textarea matInput placeholder=""></textarea>
						</mat-form-field>
					</div>
				</div>



				<a href="javascript:void(0)" class="profile-plugin switch-trigger active-color">
					<div class="ml-auto mr-auto">
						<span class="badge filter badge-purple" data-color="purple" (click)="changColor('purple')"></span>
						<span class="badge filter badge-azure" data-color="azure" (click)="changColor('azure')"></span>
						<span class="badge filter badge-green" data-color="green" (click)="changColor('green')"></span>
						<span class="badge filter badge-orange" data-color="orange" (click)="changColor('orange')"></span>
						<span class="badge filter badge-danger active" data-color="danger" (click)="changColor('danger')"></span>
					</div>
					<div class="clearfix"></div>
					<div class="ripple-container"></div>
				</a>
				<button mat-raised-button type="submit" class="btn btn-danger pull-right">Update Profile</button>
				<div class="clearfix"></div>
			</form>
		</mat-tab>
		<mat-tab label="Location Access">
			<div class="row mt-4">
				<div class="col-md-6">
					<h5>Locations Access</h5>
					<mat-divider></mat-divider>
					<mat-action-list class="borderd perm-box mt-2">
						<ng-container *ngFor="let loc of alllocations">
							<button mat-list-item class="mat-list-item-bordered" *ngIf="!hasLocation(loc.loccode)" (click)="addUserLocation(loc)">
								<span>
									{{ loc.loccode }} - {{ loc.locationname }}
								</span>
								<span class="ml-auto mr-0"><small>
										<mat-icon color="accent" class="small bold">add</mat-icon>
									</small></span>
							</button>
						</ng-container>
					</mat-action-list>
				</div>
				<div class="col-md-6">
					<h5>Access</h5>
					<mat-divider></mat-divider>
					<mat-action-list class="borderd perm-box mt-2">
						<button mat-list-item class="mat-list-item-bordered" *ngFor="let loc of user.locations" (click)="removeUserLocation(loc)">
							<span>
								{{ loc.loccode }} - {{ loc.locationname }}
							</span>
							<span class="ml-auto mr-0"><small>
									<mat-icon color="accent" class="small bold">remove</mat-icon>
								</small></span>
						</button>
					</mat-action-list>
				</div>

				<button mat-raised-button class="btn btn-danger pull-right" (click)="updateLocations()">Update Locations</button>
			</div>
		</mat-tab>
		<!--
								<mat-tab label="Permission">
									group data
								</mat-tab>
							-->
	</mat-tab-group>
	<div class="col-md-4 d-none">
		<div class="card card-profile">
			<div class="card-avatar">
				<a href="javascript:void(0)">
					<img class="img" src="./assets/img/faces/marc.jpg" />
				</a>
			</div>
			<div class="card-body">
				<h6 class="card-category text-gray">CEO / Co-Founder</h6>
				<h4 class="card-title">{{ userdetails.realname }}</h4>
				<p class="card-description">
					Don't be scared of the truth because we need to restart the human foundation in truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is...
				</p>
				<a href="javascript:void(0)" class="btn btn-danger btn-round">Follow</a>
			</div>
		</div>
	</div>
</ng-container>
