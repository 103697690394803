<h4>Editing PLM for Product Line {{line_field}}</h4>
<table class="table table-striped">
	<tr>
		<th class="text-left">Price Level</th>
		<th class="text-left">Price Basis</th>
		<th class="text-center">Operation</th>
		<th class="text-right">Factor</th>
		<th class="text-right">
			Add Markup
			<!-- <button mat-raised-button color="accent" (click)="addPLM()"></button> -->
		</th>
	</tr>
	<tr *ngFor="let plm of plmdata">
		<ng-container *ngIf="plm.editing === 1; then editplm else showplm"></ng-container>

		<ng-template #editplm>
			<td class="text-left">
				<mat-form-field appearance="standard" color="accent">
				<mat-label>Price Level</mat-label>
				<mat-select [(value)]="plm.typeabbrev" >
					<mat-option *ngFor="let plv of plvs" [value]="plv.typeabbrev">
						{{plv.typeabbrev}} - {{plv.sales_type}}
					</mat-option>
				</mat-select>
				</mat-form-field>
			</td>
			<td class="text-left">
				<mat-form-field appearance="standard" color="accent">
					<mat-label>Price Basis</mat-label>
				<mat-select [(value)]="plm.pricebasis">
					<mat-option value="Cost">Cost</mat-option>
					<mat-option *ngFor="let plv of plvs" [value]="plv.typeabbrev">
						{{plv.typeabbrev}} - {{plv.sales_type}}
					</mat-option>
				</mat-select>
				</mat-form-field>
			</td>
			<td class="text-center">
				<mat-form-field appearance="standard" color="accent">
				<mat-label>Operator</mat-label>
				<mat-select [(value)]="plm.operator">
					<mat-option value="1">+</mat-option>
					<mat-option value="2">x</mat-option>
				</mat-select>
				</mat-form-field>
			</td>
			<td class="text-right">
				<input matInput [(ngModel)]="plm.amount" class="input text-right">
			</td>
			<td  class="text-right">
				<button mat-raised-button color="primary" aria-label="" class="mt-2" (click)="savePLM(plm)">
					<i class="material-icons" >save</i>
				</button>
				&nbsp;&nbsp;
				<button mat-raised-button color="accent" aria-label="" class="mt-2" (click)="cancelPLM()">
					<i class="material-icons" >cancel</i>
				</button>

			</td>

		</ng-template>
		<ng-template #showplm>
			<td class="text-left">{{plm.typeabbrev}} - {{ plm.sales_type }}</td>
			<td>{{plm.pricebasis}}</td>
			<td class="text-center">{{plm.operator_label}} </td>
			<td class="text-right">{{plm.amount}}</td>
			<td class="text-right">
			<button mat-raised-button color="primary" (click)="plm.editing=1">
				<i class="material-icons" >edit</i>
			</button>
			<button mat-raised-button (click)="deletePLM(plm)">
				<i class="material-icons" >delete</i>
			</button>
		</td>

		</ng-template>

	</tr>
</table>
