import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { PurchasingService } from '../../../services/purchasing.service';
import { InventoryService } from '../../../services/inventory.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-purchasing-reordering-minmax-two',
  templateUrl: './purchasing-reordering-minmax-two.component.html',
  styleUrls: ['./purchasing-reordering-minmax-two.component.scss'],
  animations: [
	  trigger('flipState', [
		  state('active', style({
			  transform: 'rotateY(180deg)'
		  })),
		  state('inactive', style({
			  transform: 'rotateY(0)'
		  })),
		  state('orderlookup', style({
			  transform: 'rotateY(180deg)'
		  })),
		  transition('active => inactive', animate('500ms ease-out')),
		  transition('inactive => active', animate('500ms ease-in')),
		  transition('inactive => orderlookup', animate('500ms ease-out')),
		  transition('orderlookup => inactive', animate('500ms ease-in')),
	  ])
  ]
})
export class PurchasingReorderingMinmaxTwoComponent implements OnInit {
@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	@ViewChild("purchasedetails") purchasedetails: ElementRef;
	@ViewChild("itemhistoryele") itemhistoryele: ElementRef;
	flip: string = 'inactive';
	locations: any = [];
	reportForm: UntypedFormGroup;
	minControl= new UntypedFormControl('')
	brandsFilter = new UntypedFormControl()
	today = new Date();
	results: any = false;
	loading: boolean = false;

	suppliers: any = [];
	purchase_item: any = [];
	foundvendors: any = [];
	itemhistory: any = false;
	item: any = false;
	purchitem: any = false;
	months: any = false;
	futuremonths: any = false;
	loading_remote: any = false;
	noresults: any = false;
	filters: any = [];
	selected_filters: any = [];
	selected_checks: any = [];
	purchase_items: any = [];
	po_vendors: any = [];
	remoteqtys: any = [];
	allresults: any = [];
	vendors: any = [];
	watch_items: any = [];
	fully_available = new UntypedFormControl(false)
	allitems: any = [];
	allpurchaseitems: any = [];
	po_vendors_all: any = [];
	//
	po_vendors_found: any = false;
	//starts at 1 - preferred vendor
	indexnow: number = 1
	wathchlist: any = [];
	datasets: any = [];
	holditem: any = [];
	sending: boolean = false;
	currentstep: number = 0;
	reviewlabel:string = "Review Primary Recommendations"
	retriving: any = false;
	brands:any = [];
	headercolumns: any = [
		'vendor',
		'stockid',
		'description',
		//'brand_data',
		'qoh',
		//'qop',
		'qos',
		'qot',
		'price',
		'qty_purchase',
		//'minimum',
		//'maximum',
		//'purchdata',
		//'qos',
		//'qop',
		//'qoh',
		//'minimum',
		//'maximum',
	//	'total_available',
		//'price',
	//	'target_purchase',
	];

	winter_values: any = [{"label":"Non Winter Tires","value":"0"},{"label":"Winter Tires","value":"1"},{"label":"3-Peak All-Weather","value":"2"}];

	config: any = [];
	user : any = false;

	constructor(private inventoryService: InventoryService, private purchasingService: PurchasingService, private globalSearchService: GlobalSearchService, private location: Location, private route: ActivatedRoute, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal) {
		this.config = this.globalsService.getApiConfig();
		this.globalSearchService.user.subscribe( results => {
			this.user = results
		});
	}

	ngOnInit(): void {
		this.purchasingService.getFiltersTwo().subscribe(async (result:any) => {
			this.filters = result;
		});

		this.purchasingService.getSuppliers('').subscribe(async (results: any) => {
			this.suppliers = results;
			this.reportForm = this.fb.group({
				startdate: [this.today, Validators.required],
				minimum_sales: [0, Validators.required],
				item_type: [1, Validators.required],
				numberofmonths: [2, Validators.required],
				numberofweeks: [8, Validators.required],
				loccode: [this.user.user.defaultlocation.loccode, Validators.required],
				filters: [],
				brands:[],
				season: [],
				supplier: ['',Validators.required],
			});

			this.inventoryService.getBrands().subscribe( (r:any)=> {
				this.brands = r;

				var selected = [];
				this.brands.forEach( a=> {
					selected.push(a.name)
				})
				this.reportForm.get('brands').setValue(selected)
			})

			//this.getRecomendations()

		})
	}

	updateBrands(event: any) {
		this.reportForm.get('supplier').setValue(event.supplierid)
		var supplierid = event.supplierid;
		let defaultBrands = this.brands.filter( r=> {
			return supplierid == r.a2
		})
		var selected = [];
		defaultBrands.forEach( a=> {
			selected.push(a.name)
		})

		if(!selected.length) {
			// this.brands.forEach( a=> {
			// 	selected.push(a.name)
			// })
		}

		this.reportForm.get('brands').setValue(selected)
	}

	resetItem() {
		this.purchitem = this.holditem;
	}

	getItemHistory(pitem: any) {
		this.purchitem = pitem;

		this.inventoryService.getItem({ stockid: pitem.stockid }).subscribe((item: any) => {
			this.item = item;
			this.inventoryService.getHistory(pitem.stockid).subscribe((results: any) => {
				this.itemhistory = results;
				this.modalService.open(this.itemhistoryele, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

				}, (reason) => {
					this.itemhistory = {};
				});

			})
		})
	}

	updateChecks(event: any, filter_id: any) {

		if (!this.selected_checks[filter_id]) {
			this.selected_checks[filter_id] = [];
		}

		let data = { value: event.source.value };

		if (event.checked) {
			this.selected_checks[filter_id].push(data)
		} else {

			let index = this.selected_checks[filter_id].indexOf(data);

			this.selected_checks[filter_id] = this.selected_checks[filter_id].filter((selected, index) => {
				return selected.value != event.source.value
			});
		}
		//prevent empty send
		if (this.selected_checks[filter_id].length) {
			this.selected_filters[filter_id] = { filter_id: filter_id, value: this.selected_checks[filter_id] };
		} else {
			this.selected_filters[filter_id] = {};
		}
	}

	createBatchBo() {
		//this.flip = (this.flip == 'inactive') ? 'active': 'inactive';
	}

	creteMultiplePos() {

	}

	filterResults(event: any) {

		this.fully_available.setValue(event.checked)
		if (event.checked) {
			this.results = this.allitems.filter((r: any) => {
				return r.total_available >= r.qty_purchase
			})

			this.purchase_items = this.purchase_items.filter((r: any) => {
				return r.canfill;
			})


			this.po_vendors = []
			this.po_vendors_all.forEach((vendor: any, index) => {

				let lines = vendor.lines.filter( (l:any) => {
					return l.canfill
				})
				vendor.lines = lines;
				this.po_vendors[index] = vendor;
			})

		} else {
			this.results = this.allitems;
			this.purchase_items = this.allpurchaseitems;
			this.po_vendors = this.po_vendors_all
		}

	}

	updateSearchSelect(event: any, filter_id: any) {
		this.selected_filters[filter_id] = { filter_id: filter_id, value: event.value };
	}

	updateSearchSelectMulti(event: any, filter_id: any) {
		this.selected_filters[filter_id] = { filter_id: filter_id, value: event.value };
	}

	editBin(item: any) {
		item.editing = (item.editing) ? false : true;
	}

	updateQuantity(event: any, item: any) {
		item.qty_purchase = event;
	}

	updatePrice(event: any, item: any) {
		item.selected_option.price = event
	//	this.reduceItems();
	}


	updateBinItem(event: any, item: any) {
		let index = this.results.indexOf(item);
		item.bin = event;
		item.editing = false;
	}

	setPurchOrderOptions(item: any, pdata: any) {



		 let currentItem = this.results[item.vendor_index].indexOf(item);
		 let index= pdata.supplierid+': '+pdata.suppname;

		 let thisalreadythere = this.po_vendors_found.indexOf(index);

		 if(thisalreadythere < 0) {
			 this.po_vendors_found.push(index)
		 }

		 item.vendor = index
		 item.selected_option = pdata
		 item.total_available = pdata.total_available
		 item.qty_purchase = (pdata.total_available >= item.target_purchase) ?  item.target_purchase : pdata.total_available;

		 if(!this.results[index]){
			 this.results[index] = [];
		 }
		 this.results[index].push(item)

		 if(currentItem >= 0) {
			 this.results[item.vendor_index].splice(currentItem,1);
			 item.vendor_index = index;
		 }

		this.modalService.dismissAll();
	}


	nonStockItem(purchase_item:any) {


		if(purchase_item.qoh <= 0) {

			let proceed = confirm(" Zero Min/Max and update bin?");
			if(proceed) {
				purchase_item.bin = 'NONSTOCK';
				purchase_item.minimum = 0;
				purchase_item.maximum = 0;
			}
		} else {
			alert("Item still has QOH. Canceled")
		}
	}

	setMax(event: any , purchase_item: any) {

		//if(parseFloat(event) >= parseFloat(purchase_item.minumim)) {
			purchase_item.maximum = parseFloat(event);
		//} else {
		//	purchase_item.maximum = parseFloat(purchase_item.minumim);
		//}
	}

	setBin(event: any , purchase_item: any) {
		purchase_item.bin = event;
	}

	setMin(event: any , purchase_item: any) {
		purchase_item.minumim = parseFloat(event);
	}

	updateItem(purchase_item:any) {

		let data = {
			stockid: purchase_item.stockid,
			minimum: purchase_item.minimum,
			maximum: purchase_item.maximum,
			bin: purchase_item.bin,
			vendor: purchase_item.vendor,
			qoh: purchase_item.qoh,
			loccode: this.reportForm.get('loccode').value
		};

		this.purchasingService.updateItem(data).subscribe( (result:any)=> {
			this.modalService.dismissAll();
		})
	}

	viewPurchData(item: any) {
		this.purchase_item = item;
		//copy
		this.holditem = (JSON.parse(JSON.stringify(item)));
		this.holditem = item;

		this.modalService.open(this.purchasedetails, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

			if(reason && reason == 'cancel') {
				item.minimum = this.holditem.minimum;
				item.maximum = this.holditem.maximum;
				item.bin = this.holditem.bin;
			}
		});
	}

	exportXls() {

		let alldata = []
		this.po_vendors_found.forEach(p => {
			this.results[p].forEach(i => {
				alldata.push(i)
			})
		})

		this.globalSearchService.exportJsonToExcel(alldata, 'Recomendations');
	}

	exportTemplate() {
		let outputdata = [];
		this.po_vendors_found.forEach(p => {
			this.results[p].forEach(i => {
				//i.port
				outputdata.push({ ShipTo:i.selected_option.port, ProductID:i.selected_option.suppliers_partno,	Quantity:i.target_purchase, PO: '90190'  });
			})

		});

		this.globalSearchService.exportCSV('REORDER-TREADNET.csv', outputdata)

		const items = outputdata
		const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
		const header = Object.keys(items[0])
		const csv = [
		  header.join(','), // header row first
		  ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
		].join('\r\n')


	}


	removeItem(item: any, vendor: any) {
		let index = this.results[vendor].indexOf(item);
		this.results[vendor].splice(index, 1);
	}

	getRecomendations() {

		if (this.reportForm.valid) {
			this.indexnow = 1;

			this.reviewlabel= "Review Primary Recommendations"
			this.reportForm.get('filters').setValue(this.selected_filters);
			this.loading = true;

			if(this.retriving) {
				this.retriving.unsubscribe();
			}

			this.retriving = this.purchasingService.geMinMaxRecomendationsTwo(this.reportForm.value).subscribe((results: any) => {

				this.loading = false;
				this.allresults = results;
				this.results = results.data
				this.allitems = results.data
				this.po_vendors_found = results.vendors;
				this.watch_items  = results.watch_items;
				this.retriving = false;

			});
		}
	}

	removingItem(item: any, po: any) {

	}


	printResults(po: any) {

		let vendor = this.suppliers.filter( (s:any) => {
			var findindex = s.supplierid+': '+s.suppname;
			return po == findindex
		})[0];

		vendor.loccode = this.reportForm.get('loccode').value;
		vendor.lines = this.results[po];

		let data = vendor

		this.purchasingService.printPreviewPo(data).subscribe( (results:any) => {
			this.goToLink(results.url);
		});
	}

	goToLink(url: string) {
		window.open(url, "_blank");
	}

	vendorHasFillable(po: any) {
		let openlines = this.results[po].filter( (line:any) => {
			return line.qty_purchase >0
		})
		return (openlines.length) ? true : false;
	}

	discontinueItems() {


		let proceed = confirm("Discontinue " + this.watch_items.length +' Items?')
		if(proceed) {
			this.purchasingService.discontinueItems(this.watch_items).subscribe((result: any) => {
				this.watch_items = this.watch_items.filter( (r:any )=> {
					return (!r.watchlist);
				});
			});
		}

	}

	submitPo(po: any) {

		var proceed = confirm('You are about to create a purchase order. Continue?');

		if(proceed) {

			this.sending = true;
			let vendor = this.suppliers.filter( (s:any) => {
				var findindex = s.supplierid+': '+s.suppname;
				return po == findindex
			})[0];

			let remove_vendor = po;

			vendor.loccode = this.reportForm.get('loccode').value;
			vendor.lines = this.results[po].filter( (i:any) => {return i.qty_purchase > 0 });

			if(vendor.lines.length) {
				let data = [
					{ vendor: vendor }
				]

				this.purchasingService.createPurchaseOrdersFromRecomend(data).subscribe((result: any) => {
					this.sending = false;
					if (result.success) {

						this.globalSearchService.showNotification(result.message, 'success', 'bottom', 'right');

						this.indexnow++;

						switch(this.indexnow) {
							case 2:
								this.reviewlabel= "Review Secondary Recommendations"
							break;
							case 3:
								this.reviewlabel= "Review Tertiary Recommendations"
							break;
						}
						let newresults = this.results[po].filter( (ro:any) =>{
							return ((ro.qty_purchase <= ro.target_purchase) && ro.purchdata.length);
						});

						let watchlist = this.results[po].filter( (ro:any) =>{
							return ((ro.qty_purchase > ro.target_purchase) && ro.purchdata.length);
						});

						let next_set = [];
						var suppindex = '';
						this.results = [];


						newresults.forEach( (i:any, index) => {
							//secondary supplier?
							let newpdata = i.purchdata.filter( (p:any) => {
								return parseInt(p.preferred_sort) == this.indexnow;
							})[0]

							if(newpdata) {
								var suppindex = newpdata.supplierno+': '+newpdata.suppname;
								let thisalreadythere = this.po_vendors_found.indexOf(suppindex);

								if(thisalreadythere < 0) {
									this.po_vendors_found.push(suppindex)
								}

								i.vendor = suppindex
								i.selected_option = newpdata
								i.qty_purchase = i.target_purchase - i.qty_purchase;
								i.total_available = newpdata.total_available

								if(newpdata.total_available < i.qty_purchase) {
									i.qty_purchase = newpdata.total_available;
								}

								if(!this.results[suppindex]) {
									this.results[suppindex] = [];
								}

								this.results[suppindex].push(i)

							} else {

							}
						});

						watchlist.forEach((i:any, index) => {
							this.wathchlist.push(i)
						});


						let remove = this.po_vendors_found.indexOf(po)
						this.po_vendors_found.splice(remove,1)

						if(this.po_vendors_found.length) {
							this.currentstep = 0;
						}
						this.sending = false;
					} else {
						alert(result.message)
						this.sending = false;
					}


				});
			} else {
				this.sending = false;
			}
		}
	}
	updatePurchaseData(event: any, item: any) {
		item.purchdata = event.purchdata
	}

	ngAfterViewInit() {

		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
		});
	}

}
