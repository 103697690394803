import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, AfterViewInit } from '@angular/core';
import { ViewportScroller } from "@angular/common";

import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';

import { ActivatedRoute, Router, Params } from '@angular/router';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, FormControl } from '@angular/forms';
import { DataService } from '../../data.service';
import { OmsService } from '../../services/oms.service';
import { OrdersService } from '../../services/orders.service';
import { DispatchService } from '../../services/dispatch.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import * as XLSX from 'xlsx';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalsService } from 'app/services/globals.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { map, startWith } from 'rxjs/operators';

@Component({
	selector: 'app-order-pick',
	templateUrl: './order-pick.component.html',
	styleUrls: ['./order-pick.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('250ms ease-in')),
			transition('active => inactive', animate('250ms ease-out')),
		]),
		trigger('openClose', [
			state(':enter', style({ height: '*' })),
			state(':leave', style({ height: '0px' })),
			transition('false <=> true', animate(500))
		]),
		trigger('filterAnimation', [
			transition(':enter, * => 0, * => -1', []),
			transition(':increment', [
				query(':enter', [
					style({ opacity: 0, width: 0 }),
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 1, width: '*' })),
					]),
				], { optional: true })
			]),
			transition(':decrement', [
				query(':leave', [
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 0, width: 0 })),
					]),
				])
			]),
		]),
		trigger("grow", [ // Note the trigger name
			transition(":enter", [
				// :enter is alias to 'void => *'
				style({ height: "0", width: "0", overflow: "hidden" }),
				animate(200, style({ height: "*", width: "*" })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(":leave", [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0, overflow: "hidden" })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	]
})

export class OrderPickComponent implements OnInit {

	@ViewChild("stockidsearchtwo") stockidSearchField: ElementRef;
	@ViewChild("stockidsearchtwo_order") stockidSearchField_order: ElementRef;
	@ViewChild("detailsModal") detailsModalEle: ElementRef;
	@ViewChild('paginator') paginator: MatPaginator;
	@ViewChild("scanPickingV") scanPickingRef: ElementRef;

	itemScanForm: UntypedFormGroup;
	search = new UntypedFormControl('')
	itemfiltertype = new UntypedFormControl(1);
	filterOrdersControl = new UntypedFormControl('');
	filterCitiessControl = new UntypedFormControl('');
	filterBinsControl = new UntypedFormControl('');
	routesControl = new UntypedFormControl('');
	binsearch = new UntypedFormControl('');
	actionControl = new UntypedFormControl(true);
	packing_order_search = new UntypedFormControl('');
	ordersearch = new UntypedFormControl('');
	scanPicking = new UntypedFormControl('');

	SENDER: any;
	messages = [];
	message: any;
	CHAT_ROOM = "OrderBoard";
	searching: any = false;

	status: any = [];
	pickable: any = [];
	carddata: any = [];
	sortcolumns: any = [];
	selectedColumn: any = [];
	selectedCity: any = [];
	selectedBin: any = [];
	removeitem: any = false;
	gettinguserpicks: any = false;

	picks: any = [];
	allpicks: any = [];

	filteredResults: any = []
	filteredItems: any = [];

	completed_items: any = [];
	mustcomplete: any = [];

	linecomplete = {};
	itemcount = {};
	errors = [];
	error: any = [];
	address: any = [];
	picks_in_hand: any = [];
	linedetails = {};
	forcesearch: boolean = true;

	filterson: boolean = false;
	column: any = false;
	groupbins: any = false;
	openOrderData: any = [];
	ordersFound: any = [];

	user: any = [];

	color: string = 'blue';
	config: any = [];
	orders: any = [];
	orders_filtered: Observable < any[] > ;
	citys: any = [];
	bins: any = [];
	fillableonly: any = [
		{ value: 1, name: 'Fillable Order Items' },
		{ value: 2, name: 'Partially Fillable Order Items' },
		{ value: 3, name: 'All Order Items' },
	]

	pickedFilter = [
		{ label: 'Fillable', value: 'is_fillable' },
		{ label: 'Is Not Fillable', value: 'is_not_fillable' },
		//{ label: 'Inbound', value: 'inbound' },
		{ label: 'Picked', value: 'picked_only' },
		{ label: 'Partially Picked', value: 'partial_pick' },
		//	{ label: 'Credit', value: 'credit' },
		{ label: 'Not Due', value: 'not_due_only' },
		//	{ label: 'Not Due & Picked', value: 'not_due_and_picked' },
	]

	displayedColumns: string[] = [
		"orderno",
		"items",
		"picks",
		"printedpackingslip",
		"orddate"
	];

	routes: any = [];
	selectedTab: string = 'pick';
	gettingorderstatus: any;
	orderStatus: any;
	modalActive: boolean = false;
	pl_datasource: any = false;
	pl_loading: any = false;

	startdate: Date;
	enddate: Date;
	pl_datasource_filtered: any;
	active_order: any;
	order_modal_ready: boolean = false;
	neworders: any = [];

	socketconnection : any = false;
	//OMS send to socket : orderupdate
	//OMS method : oms/picking/update
	loccode = new FormControl(['01']);
	locations: any = [];

	pickingorders: any = [];
	pickingordersAll: any = [];
	pickingorder: any = false;
	pickingorderOrderno: any = false;

	flip: string = 'inactive';

	constructor(private activatedRoute: ActivatedRoute,public omsService: OmsService, public location: Location, private globalsService: GlobalsService, public ordersService: OrdersService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, public formBuilder: UntypedFormBuilder, public inventoryService: InventoryService, public dispatchService: DispatchService, private modalService: NgbModal) {

		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
			this.globalSearchService.locations.subscribe((l) => {
				this.locations = l;
				this.loccode.setValue(this.user.user.defaultlocation.loccode)
			});
		});

		this.dispatchService.getRoutes().subscribe(async (r: any) => {
			this.routes = r;
		});

		this.config = this.globalsService.getApiConfig();

		this.color = this.globalSearchService.getColor();
		const token = this.globalSearchService.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');

		if (token) {
			let data = [];

			this.socketconnection = this.omsService.setupSocketConnection(token);
			this.omsService.subscribeToOrderPicks((err, data) => {
				if (data.type == 'pick') {
					if (data.data.length) {

						if(data.editing && !data.neworder) {
							//remove order on update an re-add
							let orderpickneworremoval = this.allpicks.filter( f=> {
								return f.orderno == data.orderno;
							});
							orderpickneworremoval.forEach(async (r:any) => {
								let index = this.picks.indexOf(r);
								this.picks.splice(index,1);

								let indextwo = this.allpicks.indexOf(r);
								this.allpicks.splice(indextwo,1);
							})
						}


						data.data.forEach(p => {

							let exists = this.allpicks.filter(ra => {
								return ra.pick_id == p.pick_id
							})[0];

							//updated order - check for existing picks
// 							if(data.editing && !data.neworder) {
// 								let existingpicks = this.allpicks.filter( f=> {
// 									return f.pick_id == p.pick_id && p.orderno == data.orderno;
// 								});
//
//
//
// 								//new split bin or remove a pick that no longer exists
// 								if(orderpickneworremoval.length) {
// 									//make a copy of it ?
//
// 								}
// 							}
							//removalas are both new and removing not working
// 							removals.forEach(  r => {
//
// 								let index = this.picks.indexOf(r);
// 								this.picks.splice(index,1);
//
// 								let indextwo = this.allpicks.indexOf(r);
// 								this.allpicks.splice(indextwo,1);
// 							})

							if (!exists) {
								// not adding new lines need to update
								//if(data.editing && !data.neworder) {
									this.picks.push(p);
									this.allpicks.push(p);
									this.audioPlayNewOrder();
									this.vibrateNewOrder();
								//}
							} else {

								let existstwo = this.picks.filter(ra => {
									return ra.pick_id == p.pick_id
								})[0];

								let indexof = this.allpicks.indexOf(exists);
								this.allpicks[indexof] = p
								if(existstwo) {
									let indexoftwo = this.picks.indexOf(existstwo);
									this.picks[indexoftwo] = p
								}
							}
						})
					}

					if(data.complete) {
						let exists = this.allpicks.filter(ra => {
							return ra.orderno == data.orderno;
						})[0];

						let index = this.picks.indexOf(exists);
						this.picks.splice(index,1);

						let indextwo = this.allpicks.indexOf(exists);
						this.allpicks.splice(indextwo,1);
					}
					this.filterPicked();

				}

				if (data.type == 'claim') {

					let exists = this.allpicks.filter(ra => {
						return ra.orderno == data.orderno;
					})[0];

					if (exists) {

						let index = this.picks.indexOf(exists);
						let indextwo = this.allpicks.indexOf(exists);
						if(index >= 0) {
							this.picks[index].claimed = (data.claimstatus) ? '1': '0';
							this.allpicks[indextwo].claimed = (data.claimstatus) ? '1': '0';
							this.allpicks[indextwo].claimed_user  = data.user

						}
					}
				}

				if (data.type == 'cancel') {

					let exists = this.allpicks.filter(ra => {
						return ra.orderno == data.orderno;
					})[0];

					let index = this.picks.indexOf(exists);
					this.picks.splice(index,1);

					let indextwo = this.allpicks.indexOf(exists);
					this.allpicks.splice(indextwo,1);
				}

			});
		}
	}

	ngOnInit(): void {

		var default_status = ["is_fillable"];
		//holdover: should be a setting
		if(this.config && this.config.env.package == 'beauty') {
			default_status = ["is_fillable","is_not_fillable"];
		}
		this.itemScanForm = this.formBuilder.group({
			stockidsearch: ['', Validators.required],
			stockidsearch_order: ['', Validators.required],
			itemfiltertypeselect: [default_status],
		});

		this.packing_order_search.valueChanges.subscribe((newV) => {
			this.pl_datasource_filtered = this.pl_datasource.filter(i => (i.orderno.startsWith(newV) || i == newV));
			if (this.pl_datasource_filtered.length == 0) {
				this.pl_datasource_filtered = this.pl_datasource;
			} else {
				this.pl_datasource_filtered = new MatTableDataSource(this.pl_datasource_filtered);
				this.pl_datasource_filtered.paginator = this.paginator;
			}
		});

		let d = new Date();
		this.startdate = (new Date(d.setDate(d.getDate() - 30)));
		this.enddate = new Date();

		this.prepData();

		if(this.loccode) {
			this.loccode.valueChanges.subscribe(newValue => {
				this.prepData();
			})
		}

		this.ordersearch.valueChanges.subscribe(newValue => {
			this.searchOrdersTable()
		})
	}

	tabChanged(tabChangeEvent: MatTabChangeEvent): void {
		if (tabChangeEvent.index == 0) { this.selectedTab = 'pick' }
		if (tabChangeEvent.index == 1) { this.selectedTab = 'pickorder' }
		if (tabChangeEvent.index == 2) { this.selectedTab = 'packing' }

		this.picks = [];
		this.allpicks = []

		if (this.selectedTab == "pickorder" && this.filterOrdersControl.value != '') {
			this.prepData();
		}

		if (this.selectedTab == 'pick') {
			this.prepData();
		}

		if (this.selectedTab == 'packing') {
			this.getPackingLists(this.startdate, this.enddate);
		}
	}

	toggleFilters() {
		this.filterson = (this.filterson) ? false : true;
	}

	noNewLines(input:any) {

		if(input) {
			return input.replace(/[^\x20-\x7E]/gmi, " ").trim().replace(/\\n/g," ");
		}

		return '';
	}

	prepData() {

		this.ordersService.getLocationPicks(this.loccode.value).subscribe((result: any) => {
			this.orders = [];
			this.allpicks = result;


			if (this.allpicks == undefined || '') {
				return;
			}

			this.filterPicked()

			//unique values
			this.citys = result.map(i => i.city).filter((value, index, self) => self.indexOf(value) === index)
			this.citys.sort();
			//this.citys.unshift('ALL');

			this.bins = result.map(i => i.bin).filter((value, index, self) => self.indexOf(value) === index)
			this.bins.sort();
			//this.bins.unshift('ALL');

			if (this.selectedTab === 'pickorder') {
				this.filterOrders();
			}

			if (this.itemScanForm.get('stockidsearch').value != '') {
				this.searchItems();
			}

			this.route.params.subscribe(params => {
				if (params['id'] && params['id'] != 'ALL' && params['id'] != '') {
					this.picks = this.allpicks;
					this.filterOrdersControl.setValue(params['id']);
					this.filterPicked()
					this.setQueryParams()
					this.filterOrdersControl.setValue('');
				}
			});

		});

		this.getPickingOrders();
	}

	getPickingOrder(orderno: any) {
		this.ordersService.getNeedPicked(this.loccode.value,orderno).subscribe((result: any) => {
			this.pickingorder = result;
		});
	}

	getPickingOrders() {
		// this.ordersService.getNeedPicked(this.loccode.value).subscribe((result: any) => {
		// 	this.pickingorders = result;
		// });

		let search = {
			loccode: this.loccode.value,
			debtorno: false,
			filtered: [],
		}

		this.ordersService.getCustomerOpenOrdersFiltered(search).subscribe((results: any) => {
			this.pickingorders = results;
			this.pickingordersAll = results;
		});

	}

	setQueryParams(){
		const qParams: Params = {};
		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: {},
			queryParamsHandling: ''
		});
	}

	loadFiltered(type: any) {

		this.ordersService.getFilteredPicks(type).subscribe((result: any) => {
			this.picks = result;
			this.orders = [];
			this.allpicks = result;

			//unique values

			this.orders = result.map(i => i.orderno).filter((value, index, self) => self.indexOf(value) === index)
			this.orders.sort();
			//this.orders.unshift('ALL');

			this.citys = result.map(i => i.city).filter((value, index, self) => self.indexOf(value) === index)
			this.citys.sort();
			//this.citys.unshift('ALL');

			this.bins = result.map(i => i.bin).filter((value, index, self) => self.indexOf(value) === index)
			this.bins.sort();
			if (this.filterOrdersControl.value != '' && this.filterOrdersControl.value != 'ALL') {
				if(this.allpicks) {
					this.picks = this.allpicks.filter(i => i.orderno == this.filterOrdersControl.value)
				}
			}
			//this.bins.unshift('ALL');
		});
	}

	removeItemToPlace(dispatch: any, item: any) {

		let request = {
			dispatch: dispatch,
			item: item,
		}

		this.dispatchService.removeItemToPlace(request).subscribe(results => {
			if (results.success) {
				dispatch.loaded = true;
				this.prepData();
			}
		})
	}

	itemToPlace(item: any) {

		let request = {
			item: item,
		}

		this.dispatchService.loadItemToPlace(request).subscribe(results => {
			if (results.success) {
				this.prepData();
			}
		})
	}

	routeName(rid: any): string {
		//route name function not really a good idea
		if (!rid) {
			return 'N/A';
		} else {
			let r = this.routes.filter(r => { return r.route_Id == rid; })[0];
			if (typeof r === 'object') {
				return r.route_Id + '-' + r.route_description;
			} else {
				return 'N/A';
			}
		}

	}

	filterPicked() {

		//these can have crossovers. not ok for multiple
		var results = [];
		let filters = this.itemScanForm.get('itemfiltertypeselect').value;
		let count = 0;
		let ordermodel = [];
		Object.entries(this.picks).forEach(([key, value]) => {
			ordermodel.push(value['pick_id']);
		});


		filters.forEach(filter => {
			switch (filter) {
				case 'credit': {
					const filtered_results = this.allpicks.filter((card: any) => {
						return card.ordertype == "11" && !card.order_checked && !this.alreadyInList(card, results);
					})
					results = [...results, ...filtered_results];
					break;
				}
				case 'checked': {
					const filtered_results = this.allpicks.filter((card: any) => {
						return (card.order_checked && !this.alreadyInList(card, results));
					});
					results = [...results, ...filtered_results];
					break;
				}
				case '': {
					let filtered = this.globalSearchService.filterItem(this.allpicks, filters, 'pick_complete');
					results = filtered;
					break
				}
				case 'inbound': {
					break;
				}
				case 'picked_only': {
					const filtered_results = this.allpicks.filter((card: any) => {
						return !(card.info.daystilldue && card.info.daystilldue > 0) && card.ordertype != '11' && !card.order_checked && card.pick_complete === true && !this.alreadyInList(card, results)
					})
					results = [...results, ...filtered_results];
					break;
				}
				case 'partial_pick': {
					const filtered_results = this.allpicks.filter((card: any) => {
						return card.partial_pick === true && card.ordertype != '11' && !card.order_checked && !this.alreadyInList(card, results)
					});
					results = [...results, ...filtered_results];
					break;
				}

				case 'not_due_only': {
					const filtered_results = this.allpicks.filter((card: any) => {
						return card.info.daystilldue > 0 && card.pick_complete === false && !this.alreadyInList(card,results)
					});
					results = [...results,...filtered_results];
					break;
				}
				case 'not_due_and_picked': {
					const filtered_results= this.allpicks.filter((card: any) => {
						return card.info.daystilldue  > 0 && card.pick_complete === true && !this.alreadyInList(card,results)
					});
					results = [...results,...filtered_results];
					break;
				}
				case 'is_fillable': {
					//fillable and not picked
					const filtered_results = this.allpicks.filter((card: any) => {
						return (card.info.daystilldue <= 0 && card.partial_pick === false && card.ordertype != '11' && card.pick_complete === false && !card.order_checked && card.notfillable === false && !this.alreadyInList(card, results));
					});
					results = [...results, ...filtered_results];
					break;
				}

				case 'is_not_fillable': {
					const filtered_results = this.allpicks.filter((card: any) => {
						return (card.notfillable && !card.order_checked && card.ordertype != '11' && !this.alreadyInList(card, results));
					});
					results = [...results, ...filtered_results];
					break;
				}
				default: {
					const filtered_results = this.allpicks;
					results = filtered_results;
					break;
				}
			}


			count++;
		})

		if(filters.length == 0) {
			const filtered_results = this.allpicks;
			results = filtered_results;
		}

		if (this.routesControl.value != '' && this.routesControl.value) {
			results = results.filter(i => i.route == this.routesControl.value)
		}

		if (this.binsearch.value != '' && this.binsearch.value) {
			results = this.globalSearchService.filterItem(results, this.binsearch.value, 'bin');
		}

		this.picks = results.sort((a, b) => {
			let indexA = ordermodel.findIndex(type => a.pick_id === type);
			let indexB = ordermodel.findIndex(type => b.pick_id === type);
			return indexA - indexB;
		});

		let search = this.filterOrdersControl.value;
		if (search != '') {
			//on completeling let it not filter by search
			if (this.picks.length > 0) {
				this.picks = this.globalSearchService.filterItem(this.allpicks, search, 'orderno');
			}
		}

	}

	alreadyInList(item: any, list: any) {
		return list.indexOf(item) >= 0 ? true : false;
	}


	addScanPick(item: any) {

		var proceed = true;
		if ((item.picked + 1) > item.pick) {
			proceed = confirm(item.stockid + ': already picked' + item.picked + '/' + item.pick + '. Continue?')
		}

		if (this.actionControl.value) {
			if (proceed) {
				var pickitem = {
					item: item,
					count: 1,
					stockid: item.stockid,
					orderno: item.orderno,
					lineno: item.linenumber,
					pick_id: item.pick_id,
					user: this.user.user.userid,
				};

				item.picked = item.picked + 1;
				item.complete = (item.picked == item.ordered);

				if (item.picked >= 0 && item.picked <= item.pick) {
					if (item.complete) {
						this.audioPlayComplete();
						// alert(this.allpicks.length);
						this.removeitem = item
						// this.filterPicked();
						this.route.params.subscribe(params => {
							if (params['id'] && params['id'] != 'ALL' && params['id'] != '') {
								this.router.navigate(['/orders/pick/']);
							}
						});
					} else {
						this.audioPlay();
						this.searchItems();
					}

					this.sendOrderUpdate(pickitem);
					this.globalSearchService.showNotification("Picked " + item.stockid + " x " + item.picked, 'success', 'bottom', 'left')
				}
			}
		}
	}



	addPickFromList(item: any) {

		var proceed = true;

		if ((Number(item.picked) + 1) > parseFloat(item.qoh)) {
			alert('NO QTY AVAILABLE, SEE SUPERVISOR');
			//proceed = false;
			//return false;
		}

		if(proceed) {

			var pickitem = {
				item: item,
				count: 1,
				stockid: item.stockid,
				orderno: item.orderno,
				lineno: item.linenumber,
				pick_id: item.pick_id,
				user: this.user.user.userid,
			};

			item.picked = Number(item.picked) + 1;
			item.complete = (item.picked == item.pick);

			if (item.picked >= 0 && item.picked <= item.pick) {
				if (item.complete) {
					this.audioPlayComplete();
					this.removeitem = item;
					//reset value of search on complete
					this.filterOrdersControl.setValue('');

					this.route.params.subscribe(params => {
						if (params['id'] && params['id'] != 'ALL' && params['id'] != '') {
							this.router.navigate(['/orders/pick/']);
						}
					});

				} else {
					//this.audioPlay();
				}
				this.sendOrderUpdate(pickitem);
			}
		}
	}

	addPick(item: any) {

		var proceed = true;

		if ((Number(item.picked) + 1) > parseFloat(item.qoh)) {
			alert('NO QTY AVAILABLE, SEE SUPERVISOR');
			//proceed = false;
			//return false;
		}

		if(proceed) {

			var pickitem = {
				item: item,
				count: 1,
				stockid: item.stockid,
				orderno: item.orderno,
				lineno: item.linenumber,
				pick_id: item.pick_id,
				user: this.user.user.userid,
			};

			item.picked = Number(item.picked) + 1;
			item.complete = (item.picked == item.pick);

			if (item.picked >= 0 && item.picked <= item.pick) {
				if (item.complete) {
					this.audioPlayComplete();
					this.removeitem = item;
					//reset value of search on complete
					this.filterOrdersControl.setValue('');

					this.route.params.subscribe(params => {
						if (params['id'] && params['id'] != 'ALL' && params['id'] != '') {
							this.router.navigate(['/orders/pick/']);
						}
					});

				} else {
					//this.audioPlay();
				}
				this.sendOrderUpdate(pickitem);
			}
		}
	}

	removePick(item: any) {
		// alert("removePick()");
		var pickitem = {
			item: item,
			count: -1,
			stockid: item.stockid,
			orderno: item.orderno,
			lineno: item.linenumber,
			pick_id: item.pick_id,
			user: this.user.user.userid,
		};

		item.picked = item.picked - 1;
		item.complete = (item.picked == item.ordered);
		if (item.picked < 0) {
			item.picked = 0;
		}
		if (item.picked >= 0 && item.picked <= item.ordered) {

			this.sendOrderUpdate(pickitem);
		}
	}


	setQty(value: any, item: any) {

		//NONE ON HAND SEE SUPERVISOR
		let movement = parseFloat(value) - parseFloat(item.picked);

		var proceed =true;
		if (parseFloat(value) > parseFloat(item.qoh)) {
			alert('NO QTY AVAILABLE, SEE SUPERVISOR');
			proceed = false;
			value = item.picked;
			return false;
		}

		if(proceed) {
			var pickitem = {
				item: item,
				count: movement,
				stockid: item.stockid,
				orderno: item.orderno,
				lineno: item.linenumber,
				pick_id: item.pick_id,
				user: this.user.user.userid,
			};

			item.picked = item.picked + movement;

			if (item.picked > item.ordered) {
				item.picked = item.ordered;
			}
			if (item.picked < 0) {
				item.picked = 0;
			}


			if (item.picked >= 0 && item.picked <= item.ordered) {
				item.complete = (item.picked == item.ordered);

				if (item.complete) {
					this.removeitem = item
					this.route.params.subscribe(params => {
						if (params['id'] && params['id'] != 'ALL' && params['id'] != '') {
							this.router.navigate(['/orders/pick/']);
						}
					});
				}

				this.sendOrderUpdate(pickitem);
			}
		}
	}

	setCheckValue(event, item) {

		//multibin is issuing total pick not sure if itll be a problem? functionaly its ok. pick 6 one bin 2 on the other total pick is 8 not 2 and 6 for each

		var proceed = true;
		const original_pick = item.picked;
		let movement = 0;
		if (event.checked) {
			movement = parseFloat(item.pick);
		} else {
			movement = -1 * parseFloat(item.pick);
			item.complete = false;
		}

		if (movement > parseFloat(item.qoh)) {
			alert('NO QTY AVAILABLE, SEE SUPERVISOR');
			proceed = false;
			event.checked = false;
			item.complete = false;
			return false;
		}

		if(proceed) {

			item.picked = movement;
			item.complete = event.checked;

			var pickitem = {
				item: item,
				count: movement,
				stockid: item.stockid,
				orderno: item.orderno,
				lineno: item.linenumber,
				pick_id: item.pick_id,
				user: this.user.user.userid,
				type_filter: this.itemfiltertype.value
			};

			if (item.complete) {
				this.audioPlayComplete();
				this.removeitem = item;
				this.route.params.subscribe(params => {
					if (params['id'] && params['id'] != 'ALL' && params['id'] != '') {
						this.router.navigate(['/orders/pick/']);
					}
				});

			} else {
				this.audioPlay();
			}

			if(item.picked < 0) {
				item.picked = original_pick;
			}

			this.sendOrderUpdate(pickitem);
		}
	}

	viewOrder(orderno: any) {
		this.router.navigate(['orders/lookup/' + orderno]);
	}

	filterOrders() {
		if (this.filterOrdersControl.value != 'ALL' && this.selectedTab === 'pickorder') {
			this.picks = this.allpicks.filter(i => i.orderno == this.filterOrdersControl.value)

			let complete = this.picks.filter(i => i.pick !== i.picked);
			let orderno = this.filterOrdersControl.value;

			if (complete.length == 0 && !this.modalActive && this.filterOrdersControl.value != '' && this.orders.indexOf(this.filterOrdersControl.value) > 0) {
				let print = confirm(`Order: ${orderno} has been fully picked.\n\nGoto review and print packinglist?`);
				if (print) {
					this.openModal();
				}
			}

		} else {
			this.picks = this.allpicks;
			this.router.navigate(['/orders/pick/']);
		}
	}

	filterCity() {

		// if (this.filterCitiessControl.value != 'ALL') {
		// 	this.picks = this.allpicks.filter(i => i.city == this.filterCitiessControl.value)
		// } else {
		// 	this.picks = this.allpicks;
		// }
	}

	filterBins() {
		// if (this.filterBinsControl.value != 'ALL') {
		// 	this.picks = this.allpicks.filter(i => i.bin == this.filterBinsControl.value)
		// } else {
		// 	this.picks = this.allpicks;
		// }
	}

	searchOrdersTable() {
		if(this.ordersearch.value == '') {
			this.pickingorders = this.pickingordersAll;
		} else {
			this.pickingorders = this.globalSearchService.filterItem(this.pickingordersAll, this.ordersearch.value, 'orderno,deliverto');
		}
	}

	searchOrders() {
		//ignore status
		let search = this.filterOrdersControl.value;
		if (search != '') {
			this.picks = this.globalSearchService.filterItem(this.allpicks, search, 'orderno');
		} else {
			this.filterOrders()
		}
	}

	searchBins() {

		let search = this.binsearch.value;
		if (search != '') {
			this.picks = this.globalSearchService.filterItem(this.allpicks, search, 'bin');
		} else {
			this.picks = this.allpicks;
		}

	}

	updateColumns() {
		if (this.selectedColumn) {
			if (this.selectedBin != 'ALL') {
				this.router.navigate(['orders/pick/' + this.selectedColumn]);
				this.picks = this.allpicks.filter(i => i.orderno == this.selectedColumn)
			} else {
				this.picks = this.allpicks;
			}
		}
	}

	getPickRun(column: any) {

		this.omsService.getRunPick(column).subscribe((results: any) => {
			this.picks = results.items;
			this.sortcolumns = results.sortcolumns
			this.picks.forEach((keys: any, vals: any) => {
				let arraykey = keys.ITEMKEY;

				if (keys.MUSTPICK) {
					this.mustcomplete.push({
						item: arraykey,
						count: parseFloat(keys.ordered),
						stockid: keys.stockid,
						orderno: keys.orderno,
						lineno: keys.orderlineno,
						user: this.user.user.userid,
					})
				}

				this.linedetails[arraykey] = keys;
				this.itemcount[arraykey] = parseFloat(keys.PICKED);

				var pickitem = {
					item: arraykey,
					count: parseFloat(keys.PICKED),
					stockid: keys.stockid,
					orderno: keys.orderno,
					lineno: keys.orderlineno,
					user: this.user.user.userid,
				};

				if (keys.COMPLETED == '1') {
					this.completed_items.push(pickitem);
					this.linecomplete[arraykey] = true;
				} else {
					this.linecomplete[arraykey] = false;
				}
			});
		});
	}

	onInput(event: any, keys: string = 'orderno') {

		// this pushes the input value into the service's Observable.
		this.globalSearchService.searchTerm.next(event.target.value);
		let term = event.target.value;
		let allresults = [];
		if (this.openOrderData) {
			this.setFilteredLists(term);
			this.globalSearchService.itemSearch.next(this.ordersFound);
		}
	}

	finished() {
		var completed = true;
		if (!this.completed_items) {
			completed = false;
		}
		this.mustcomplete.forEach((keys: any) => {
			//keys item needs to be order+line now
			let lookup = keys.item;
			let counted = keys.count

			if (!this.completed_items.find(arg => arg.item === lookup)) {
				this.error = 'Please Finish Picking.';
				completed = false;
			} else {
				let thiscount = this.completed_items.find(arg => arg.item === lookup);

				if (thiscount.count < counted) {
					this.error = 'Please Finish the Pick.';
					completed = false;
					this.linecomplete[keys.item] = false;
				} else {
					this.linecomplete[keys.item] = true;
					this.error = 'Pick Complete';
				}

				this.itemcount[keys.item] = thiscount.count;
			}
		});

		return completed;
	}

	back(): void {
		this.location.back()
	}

	searchItems() {

		if (this.selectedTab == 'pick') {
			var search = this.itemScanForm.get('stockidsearch').value;
		} else {
			this.filterOrders();
			return;
		}

		if (search != '') {
			//only filter current filters or it ends up grabbing prior pick
			const tempresults = this.globalSearchService.filterItem(this.picks, search, 'bin,customer,description,mfgpart,orderno,stockid');
			var result = [];

			const filtered_results = tempresults.forEach((card: any) => {
				if (result.indexOf(card) < 0) {
					result.push(card);
				}
			});

			this.picks = result;

			if (!this.picks.length) {
				this.filterPicked();
			}
		} else {
			this.filterPicked()
		}
	}

	openModal(orderno ? : any) {
		// alert(orderno);
		orderno !== '' ? this.getOrderStatus(orderno) : this.getOrderStatus();
		this.modalActive = true;
		this.modalService.open(this.detailsModalEle, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {}, (reason) => {
			this.modalActive = false;
			this.selectedTab == 'packing' ? this.getPackingLists() : "";
			this.order_modal_ready = false;

		});
	}

	doNothing() {
		// ta-da
	}

	scanItem() {
		let data = {}
		if (this.selectedTab == 'pick') {
			data['search'] = this.itemScanForm.get('stockidsearch').value;
		} else {
			data['search'] = this.itemScanForm.get('stockidsearch_order').value;
			data['orderno'] = this.filterOrdersControl.value;
		}

		// this.searching = this.inventoryService.lookup({ stockid: search, single: true }).subscribe((item: any) => {
		this.searching = this.inventoryService.lookupBarcode(data).subscribe((item: any) => {


			//is on the list to be completed
			if (item) {
				let found = this.picks.filter(arg => arg.stockid == item.stockid);
				this.picks = this.picks.filter(arg => arg.stockid == item.stockid);


				switch (found.length) {
					case 1:
						// alert("adding scanpick")
						this.addScanPick(found[0]);
						break;
					case 0:
						this.error = 'No results';
						this.audioPlayBad();
						this.vibrate()
						this.filterPicked()
						this.selectedTab == "pickorder" ? this.filterOrders() : "";
						break;
					default:
						this.error = 'Multiple Matches';
						this.vibrateLong();
						break;
				}

				//search
			}
		});

		this.focusInput();
		//this.filterPicked();

	}

	focusInput() {

		setTimeout(() => {
			this.stockidSearchField.nativeElement.focus();
		}, 0);

	}

	setFilteredLists(term: any) {

		let allresults = [];
		let ordersFound = [];
		if (this.openOrderData) {
			var openorders = this.filterItem(this.openOrderData, term, 'ADDRESS_1,CUST_NAME,ORDER_NUMBER,CUST_ORDER_NO_,DATE_ORDERED,DATE_WANTED,CUST_NUMBER,ITEM_NUMBER');
			if (openorders) {
				openorders.forEach(function(value) {
					allresults.push({ content: 'Open Order', details: value, link: '/orders/lookup/' + value.ORDER_NUMBER })
					ordersFound.push(value.ORDER_NUMBER)
				});
			}
		}

		this.ordersFound = ordersFound;
		this.setFiltered(allresults)

	}

	filterItem(input: any, term: any, keys: any) {
		if (!term) {
			this.assignCopy(input);
		}
		var result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
		return result
	}

	getFiltered() {
		return this.filteredItems;
	}

	setFiltered(results: any) {
		this.filteredItems = results;
	}

	assignCopy(input: any) {
		this.filteredItems = Object.assign([], input);
	}

	toggle(event: any, item: any, qty: any) {

		if (event.checked) {
			this.markComplete(item, qty);
		} else {

		}

		this.updatePick();
	}

	markComplete(item: string, qty: number) {

		var pickitem = {
			item: item,
			count: +qty, //as number
			stockid: this.linedetails[item].stockid,
			orderno: this.linedetails[item].orderno,
			lineno: this.linedetails[item].orderlineno,
			user: this.user.user.userid,
		};
	}

	randomString(length: any, chars: any) {
		var result = '';
		for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
		return result;
	}

	updatePick() {
		let pickupdate = {
			required: this.mustcomplete,
			items: this.completed_items,
			picked: this.itemcount,
			complete: this.linecomplete,
			user: this.user.user.userid,
		}
		//letting the socket fire it off and update rooms.
		this.sendOrderUpdate(pickupdate)
	}

	sendOrderUpdate(orderupdate: any) {

		var data = orderupdate
		if (data) {

			this.omsService.sendOrderPickUpdate({ data, roomName: this.CHAT_ROOM }, cb => {

				if (this.removeitem) {
					// alert(this.allpicks.length);
					if (this.allpicks.length == 1) {
						this.picks = [];
						this.allpicks = [];
						// if(this.selectedTab != "packing"){this.prepData()} // uncomment if broken - testing to see
					}

					if (this.selectedTab == "pick") {
						// let index = this.allpicks.indexOf(this.removeitem)
						// this.allpicks.splice(index, 1)

						// let indextwo = this.picks.indexOf(this.removeitem)
						// this.picks.splice(indextwo, 1)
					}

					this.removeitem = false;
					if (this.selectedTab == 'pick') { this.prepData(); }
					if (this.selectedTab == 'pickorder') { this.filterOrders(); }

				}


			});


		}
	}



	audioPlayComplete() {
		var audio = new Audio("/assets/message-ringtone-magic.mp3");
		let play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	audioPlayNewOrder() {

		var audio = new Audio("/assets/out-of-nowhere-message-tone.mp3");
		let play = audio.play();

		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	audioPlay() {
		var audio = new Audio("/assets/pristine-609.mp3");
		let play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	audioPlayBad() {
		var audio = new Audio("/assets/glitch.mp3");
		let play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	audioPlayFree() {
		var audio = new Audio("/assets/money.mp3");
		let play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	vibrate() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(2000);
		} else {
			// Not supported
		}
	}

	vibrateNewOrder() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(4000);
		} else {
			// Not supported
		}
	}

	vibrateLong() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(5000);
		} else {
			// Not supported
		}
	}

	togglePickClaim(item) {
		let data = {
			item: item,
			user: this.user,
			claim: true
		}

		this.dispatchService.claimUserPick(data).subscribe((result: any) => {
			if (result.success) {
				if (result.removed) {
					item.claimed = '0';
					item.claimed_user = false;
				} else {
					item.claimed = '1';
					item.claimed_user = this.user.user.userid
				}

				let data = { claim: true, user: this.user, item: item };

				this.omsService.sendOrderPickUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
			} else {
				alert(result.msg);
			}

		});

	}

	getUserPicks() {

		if (this.gettinguserpicks) {
			this.gettinguserpicks.unsubscribe()
		}

		this.gettinguserpicks = this.dispatchService.getUserPick(this.user.user.userid).subscribe((results: any) => {
			this.picks_in_hand = results;

		})
	}

	getOrderStatus(orderno ? : any) {
		if (['pickorder', 'packing'].includes(this.selectedTab)) {

			if (this.gettingorderstatus) {
				this.gettingorderstatus.unsubscribe()
			}

			let search = '';
			if (orderno != '') {
				search = orderno;
			}

			this.gettingorderstatus = this.dispatchService.getOrderStatus(search).subscribe((results: any) => {
				if (results.length) {
					// this.orderStatus = results;
					let temp = [];

					results.forEach(line => {
						line.pick = Number(line.pick);
						line.picked = Number(line.picked);
						temp.push(line);
					});
					this.orderStatus = temp;
					this.order_modal_ready = true;

				} else {
					this.orderStatus = false;
					this.order_modal_ready = false;
				}

			})
		}
	}

	printPackingList(data) {
		if (this.filterOrdersControl.value == '' || undefined) {
			this.dispatchService.printPackingList(data).subscribe((results: any) => {})
		} else {
			this.dispatchService.printPackingList(this.filterOrdersControl.value).subscribe((results: any) => {})
		}

	}

	getPackingLists(start ? , end ? ) {
		let data = {};

		if (start && end) {
			data['start'] = start;
			data['end'] = end;
		}

		this.pl_loading = this.dispatchService.getPackingLists(data).subscribe((results: any) => {
			this.pl_datasource = results;
			this.pl_datasource_filtered = results;
			this.pl_loading = false;
			this.pl_datasource_filtered = new MatTableDataSource(this.pl_datasource_filtered);
			this.pl_datasource_filtered.paginator = this.paginator;
		})
	}

	exportExcel(datain: any): void {
		this.globalSearchService.exportJsonToExcel(this.picks, 'Picks.xlsx')
	}

	getFilterName() {
		return this.fillableonly.filter(f => {
			return f.value == this.itemfiltertype.value;
		})[0].name;
	}

	filterItemType() {
		this.loadFiltered(this.itemfiltertype.value)
	}

	startRangeAltered(event: any) {
		if (typeof event.start.value !== 'undefined') {
			this.startdate = event.start.value;
		}
	}

	endRangeAltered(event: any) {
		if (typeof event.end.value !== 'undefined') {
			this.enddate = event.end.value;
			this.getPackingLists(this.startdate, this.enddate);
		}
	}

	ngAfterViewInit() {

	}

	loadOrder(orderno: any) {
		this.pickingorder = false;
		this.pickingorderOrderno = orderno;
		this.ordersService.getNeedPicked(this.loccode.value, orderno).subscribe((result: any) => {
			this.flip = 'active';
			this.pickingorder = result;
		});

	}

	getRemainingLines() {
		var total = 0;
		if (this.pickingorder && this.pickingorder.length > 0) {
			this.pickingorder.forEach(r => {
				if (r.picked < r.pick ) {
					total += 1;
				}
			})
		}
		return total;
	}

	getRemainingPickItemsForOrder() {
		var total = 0;
		if (this.pickingorder && this.pickingorder.length > 0) {
			this.pickingorder.forEach(r => {
				if (r.picked < r.pick ) {
					total += r.pick - r.picked;
				}
			})
		}
		return total;
	}

	getRemainingPicks() {
		var total = 0;
		if (this.pickingorders && this.pickingorders.length > 0) {
			this.pickingorders.forEach(r => {
				if (parseInt(r.orderstatusid) < 21 ) {
					total += 1;
				}
			})
		}
		return total;
	}

	resetList() {
		this.flip = 'inactive';
		this.pickingorderOrderno = false;
		this.pickingorder = false;
		this.prepData();
	}
	scanItemToOrderTable() {
		let data = {
			orderno: this.pickingorderOrderno,
			search: this.scanPicking.value
		}

		if (this.searching) {
			this.searching.unsubscribe();
		}

		this.searching = this.inventoryService.lookupBarcode(data).subscribe((item: any) => {
			if (item) {
				//item in list
				let found = this.pickingorder.filter(arg => arg.stockid == item.stockid);

				switch (found.length) {
					case 1:
						this.addScanPick(found[0]);
						this.scanPicking.setValue('');

						setTimeout(() => {
							this.scanPickingRef.nativeElement.focus();
						}, 0);


						break;
					case 0:
						this.error = 'No results';
						this.audioPlayBad();
						this.vibrate()
						break;
					default:
						this.error = 'Multiple Matches';
						this.audioPlayBad();
						this.vibrateLong();
						break;
				}
			}
		});
	}

	scanItemToOrder() {
		let data = {
			orderno: this.pickingorderOrderno,
			search: this.scanPicking.value
		}

		if (this.searching) {
			this.searching.unsubscribe();
		}

		this.searching = this.inventoryService.lookupBarcode(data).subscribe((item: any) => {
			if (item) {
				//item in list
				let found = this.pickingorder.filter(arg => arg.stockid == item.stockid);

				switch (found.length) {
					case 1:
						this.addScanPick(found[0]);
						break;
					case 0:
						this.error = 'No results';
						this.audioPlayBad();
						this.vibrate()
						break;
					default:
						this.error = 'Multiple Matches';
						this.vibrateLong();
						break;
				}
			}
		});

	}

	asInt(value: any) {
		return parseInt(value);
	}

	completeOrderPick() {
		let data = {
			location: this.user.user.defaultlocation.loccode,
			orderno: this.pickingorderOrderno,
		}

		this.ordersService.completeOrderPick(data).subscribe(r=> {

			let data = {
				user: this.user,
			}

			this.omsService.sendOrderPickUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});

			this.pickingorder = false;
			this.pickingorderOrderno = false;
			this.flip = 'inactive';
			this.router.navigate(['orders/pick']);
		})
	}

	private _filter(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.orders.filter(option => option.toLowerCase().includes(filterValue));
	}
}
