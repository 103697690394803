import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { InventoryService } from 'app/services/inventory.service';
import { PrintService } from 'app/services/print.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

@Component({
	selector: 'app-idet-report',
	templateUrl: './idet-report.component.html',
	styleUrls: ['./idet-report.component.scss']
})

//app-igr-report
export class IdetReportComponent implements OnInit, AfterViewInit {
	@Input('stockid') stockid;
	content: boolean = false;
	itemForm: UntypedFormGroup;
	afterdate = new Date();
	todate = new Date(new Date().setDate(this.afterdate.getDate() - 365));
	locationList: any = false;
	results: any = false;
	sending: boolean = false;
	salesmanList: any = false;
	rollupQtys: any = {};
	rollupPrices: any = {};
	rollupExts: any = {};
	prodDescriptions: any = {};
	prodsReady: boolean;

	prodSearch: { datasource: any; displayedColumns: string[]; };
	filtersReady: boolean = false;

	//chips
	visible = true;
	selectable = true;
	removable = true;
	addOnBlur = true;
	separatorKeysCodes: number[] = [ENTER, COMMA];
	prodCtrl = new FormControl();
	filteredProds: Observable<string[]>;
	prods: string[] = [];
	allProds: string[] = [];
	@ViewChild('prodInput', { static: false }) prodInput: ElementRef<HTMLInputElement>;
	@ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
	selectedFromDate: string;
	selectedToDate: string;

	constructor(private inventoryService: InventoryService, private fb: UntypedFormBuilder, private globalSearchService: GlobalSearchService, private printService: PrintService) {
		this.filteredProds = this.prodCtrl.valueChanges.pipe(
			startWith(''),
			debounceTime(400),
			distinctUntilChanged(),
			switchMap(val => {
				if (val != '') {
					return this.filter(val || '')
				} else {
					return [];
				}
			})
		)
		this.globalSearchService.locations.subscribe((r1: any) => {
			if (r1) {
				this.locationList = r1.sort((a, b) => a.locationname > b.locationname ? 1 : 0);
				this.globalSearchService.salespeople.subscribe(async (r2: any) => {
					if (r2) {
						this.salesmanList = r2.filter((person) => person.group_id == 1 && person.current == 1).sort((a, b) => a.salesmanname > b.salesmanname ? 1 : 0);
						this.setForm();
					}
				});
			}
		});
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
	}

	ngOnChanges(): void {
		this.setForm();
	}

	filter(val: string): Observable<any> {
		return this.inventoryService.itemSearch({ search: val, type: ['igr'] })
			.pipe(
				map(response => response.filter(option => {
					return (
						(option.stockid.toLowerCase().indexOf(val.toLowerCase()) === 0 || option.description.toLowerCase().includes(val.toLowerCase())) && !this.prods.includes(option.stockid)
					)
				}))
			)
	}

	add(event: MatChipInputEvent): void {
		if (!this.matAutocomplete.isOpen) {
			const input = event.input;
			const value = event.value;
			if ((value || '').trim()) {
				this.prods.push(value.trim());
			}
			if (input) {
				input.value = '';
			}
			this.prodCtrl.setValue(null);
		}
	}

	remove(prod: string): void {
		const index = this.prods.indexOf(prod);
		if (index >= 0) {
			this.prods.splice(index, 1);
		}
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		let input = (event.option.viewValue).toString().split(":");
		let stockid = input[0].trim();
		let description = input[1].trim();

		this.prodDescriptions[stockid] = description;

		this.prods.push(stockid);
		this.prodInput.nativeElement.value = '';
		this.prodCtrl.setValue(null);
	}

	setForm() {
		this.itemForm = this.fb.group({
			locs: [this.locationList.map(i => i.loccode), Validators.required],
			sales: [this.salesmanList.map(i => i.salesmancode), Validators.required],
			fromdate: [this.todate, Validators.required],
			todate: [this.afterdate, Validators.required]
		});
		this.updateReportDates()
	}


	updateReportDates() {
		let date = new Date(this.itemForm.get('fromdate').value);
		this.selectedFromDate = date.getMonth() + "/" + date.getDate() + "/" + date.getFullYear();
		date = new Date(this.itemForm.get('todate').value);
		this.selectedToDate = date.getMonth() + "/" + date.getDate() + "/" + date.getFullYear();
		this.filtersReady = true;
	}

	getIDET() {
		let data = this.itemForm.value;
		data['prods'] = this.prods;

		this.sending = true;
		this.inventoryService.getIdet(data).subscribe((result) => {
			this.results = result;
			this.calculateRollup(result);
			this.sending = false;
		});
	}

	calculateRollup(data: any) {
		Object.entries(data).forEach((key, value) => {
			let stockid = key[0];
			let entries: any = key[1];
			if (entries.length) {
				this.rollupQtys[stockid] = entries.map((i) => Number(i.QTY)).reduce((sum, current) => sum + current);
				this.rollupPrices[stockid] = entries.map((i) => Number(i.Price)).reduce((sum, current) => sum + current);
				this.rollupExts[stockid] = entries.map((i) => Number(i.Extension)).reduce((sum, current) => sum + current);
			} else {
				this.rollupQtys[stockid] = "";
				this.rollupPrices[stockid] = "";
				this.rollupExts[stockid] = "";
			}
		});
	}

	selectAllToggle(ref: MatSelect) {
		const selected = ref.options.find(opt => opt.selected);
		if (selected) {
			ref.options.forEach((item: MatOption) => item.deselect());
		} else {
			ref.options.forEach((item: MatOption) => item.select());
		}
	}

	exportPdf(ref: any) {
		let now = new Date();
		let filename = 'ITEM_DETAIL_REPORT_';
		let encoded: string = this.globalSearchService.base64encode(ref.innerHTML);
		let data = {
			content: encoded,
			filename: filename,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls(ref: any) {
		let filename = 'ITEM_DETAIL_REPORT';
		let encoded: string = this.globalSearchService.base64encode(ref.innerHTML);
		let data = {
			content: encoded,
			filename: filename,
			title: this.exportHeaderGen('title'),
			subtitle: this.exportHeaderGen('locations') + "  " + this.exportHeaderGen('salesmen')
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	sortedProds() {
		return this.prods.sort((a, b) => a > b ? 1 : 0);
	}
	sortedLocs() {
		return this.itemForm.get('locs').value.sort((a, b) => a > b ? 1 : 0);
	}

	sortedSales() {
		return this.itemForm.get('sales').value.sort((a, b) => a > b ? 1 : 0);
	}

	exportHeaderGen(type: any) {
		let output = '';
		if (type == 'title') {
			let now = new Date();
			output = `Item Detail Report ${this.selectedFromDate} - ${this.selectedToDate} Created @ ${now.toLocaleString()}`;
		}

		if (type == 'locations') {
			output = 'Locations: [ ';
			if (this.sortedLocs().length == Object.entries(this.locationList).length) {
				output += 'ALL'
			} else {
				this.sortedLocs().forEach(loc => {
					output += (loc + ', ');
				});
				output = output.slice(0, output.length - 2);
			}
			output += ' ]';
		}

		if(type == 'salesmen'){
			output += ' Salesmen: [ ';
			if (this.sortedSales().length == Object.entries(this.salesmanList).length) {
				output += 'ALL'
			} else {
				this.sortedSales().forEach(person => {
					output += (person + ', ');
				});
				output = output.slice(0, output.length - 2);
			}
			output += ' ]';
		}
		return output;
	}




}
