<ng-container>
<form @grow (ngSubmit)="onSubmit()" >
	<div class="card-header">
		<div class="col-12 text-right">
			<button class="ml-auto mr-0" mat-button
			mat-raised-button > <i class="material-icons">save</i> Save</button>
		</div>
	</div>
		<div class="row">
			<div class="col-md-12">
				<mat-form-field appearance="standard">
					<mat-label><b>MARKUP COST:</b></mat-label>
					<input matInput
					required
					[formControl]="itemActualCost"
					>
				</mat-form-field>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="standard">
					<mat-label><b>AVG COST:</b></mat-label>
					<input matInput
					required
					[formControl]="itemMaterialCost"
					>
				</mat-form-field>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="standard">
					<mat-label>LAST COST:</mat-label>
					<input matInput
					required [formControl]="itemLastCost" >
				</mat-form-field>
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-md-12" *ngFor="let price of item.prices">
				<mat-form-field appearance="standard">
					<mat-label>{{ price.typeabbrev }} {{ price.sales_type }} Price</mat-label>
					<input matInput
					required
					(input)="updatePrice($event.target.value, price)"
					[(value)]="price.pricedisplay"
					>
				</mat-form-field>
			</div>
		</div>

</form>
</ng-container>
