<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<!--
		<mat-chip-set role="list">
			<mat-chip role="listitem" *ngFor="let l of viewedtransactions">
				<button mat-menu-item (click)="viewPayments(l,details)">
					<mat-icon>credit_card</mat-icon>
					<span>Payments</span>
				</button>
			</mat-chip>
		</mat-chip-set>
		-->

		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true" [filenameForDownload]="displaydetails.title"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #orderView let-modal>
	<div class="modal-header">
		<div class="modal-title row m-0 p-0" id="modal-title">
			<h4>Items</h4>
		</div>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="orderdetails">
			<div class="table">
				<table class="table table-sm">
					<tr>
						<th>Customer</th>
						<th>Salesperson</th>
						<th>Item #</th>
						<th>Description</th>
						<th class="text-center">Qty</th>
						<th class="text-center">Sales</th>
					</tr>
					<tr *ngFor="let item of orderdetails">
						<th>{{item.CUSTOMER}} - {{item.NAME}}</th>
						<th>{{item.SALESMAN}}</th>
						<td>
							{{item.stockid}}
						</td>
						<td>{{item.description}}</td>
						<td class="text-center">{{item.Qty}}</td>
						<td class="text-center">{{item.Sales | currency}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Vendor Product Customer Sales
						</h4>
						<p class="card-category text-right">CVSLS</p>
					</div>
					<div class="card-body">
						<div class="row m-0 p-0">
							<div class="col-md-2 mt-3">
								<mat-form-field appearance="standard">
									<mat-label>Transaction Dates</mat-label>
									<mat-date-range-input [rangePicker]="picker">
										<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
										<input matEndDate [formControl]="todateCtrl" placeholder="End date">
									</mat-date-range-input>
									<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
									<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
									<mat-date-range-picker #picker></mat-date-range-picker>
								</mat-form-field>
							</div>
							<div class="col-md-2 mt-3">
								<mat-form-field appearance="standard">
									<mat-label>Brand</mat-label>
									<mat-select [formControl]="brands" multiple #brandFilter>
										<div class="select-all" (click)="selectAllToggle('brand')">
											<button mat-icon-button>
												<div ml="1">Select All/None</div>
											</button>
										</div>
										<mat-option *ngFor="let k of properties.brands" [value]="k.manufacturer_id">{{k.name}}</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-md-2 mt-3">
								<mat-form-field appearance="standard">
									<mat-label>Branches</mat-label>
									<mat-select [formControl]="branchRef" multiple #branchFilter>
										<div class="select-all" (click)="selectAllToggle('branch')">
											<button mat-icon-button>
												<div ml="1">Select All/None</div>
											</button>
										</div>
										<mat-option *ngFor="let k of branches" [value]="k.loccode">{{k.locationname}}</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-md-2 mt-3">
								<mat-form-field appearance="standard">
									<mat-label>Consolidated or Branch?</mat-label>
									<mat-select [formControl]="conBrRef">
										<mat-option *ngFor="let v of conbr" [value]="v.value">
											{{v.label}}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-md-2 mt-3">
								<mat-form-field appearance="standard">
									<mat-label>Break out Product Lines?</mat-label>
									<mat-select [formControl]="plRef">
										<mat-option *ngFor="let v of plbreak" [value]="v.value">
											{{v.label}}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-md-2 mt-3">

								<button mat-raised-button color="accent" class="ml-2 mt-2" (click)="getCVSLS()" *ngIf="!sending">
									<mat-icon>refresh</mat-icon>
								</button>
								<span *ngIf="sending">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
							</div>
						</div>
						<ng-container *ngIf="orderResults">
							<hr>
							<span *ngIf="dowloading">
								<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</span>
							&nbsp;&nbsp; <button class="btn btn-success btn-sm" (click)="$event.preventDefault();exportXls()" *ngIf="orderResults && !dowloading">
								<i class="fa fa-file-excel-o"></i> XLS
							</button>
							&nbsp;&nbsp;
							<button class="btn btn-danger btn-sm" (click)="$event.preventDefault();exportPdf()" *ngIf="orderResults && !dowloading">
								<i class="fa fa-file-pdf-o"></i> PDF
							</button>
							<div>
								<h3>Vendor Product Customer Sales {{reportCriteria.fromdate | date:'MM/d/Y'}} - {{reportCriteria.todate | date: 'MM/d/Y'}}</h3>
								<h4>
									<span *ngIf="reportCriteria.conbr == 'C'">Consolidated Report</span>
									<span *ngIf="reportCriteria.conbr != 'C'">Branch Report </span>
									<span *ngIf="reportCriteria.locs.length == this.branches.length"> For All Locations</span>
									<span *ngIf="reportCriteria.locs.length != this.branches.length">
										For Locations:
										<span *ngFor="let loc of reportCriteria.locs">{{loc}} </span>
									</span>
									<span *ngIf="reportCriteria.plbreak == '1'"> by Product Line</span>
									<span *ngIf="reportCriteria.plbreak != '1'"> by Customer </span>
								</h4>
							</div>
							<div class="table">
								<table class="table table-sm table-hover table-fixed" *ngIf="orderResults">
									<thead>
										<tr>
											<th>BR</th>
											<th>Customer</th>
											<th>Name</th>
											<th>Salesperson</th>
											<th>Zip Code</th>
											<th>Line</th>
											<th class="text-center">Line Name</th>
											<th class="text-center">Qty</th>
											<th class="text-right">Sales</th>
										</tr>
									</thead>
									<tbody>
										<ng-container *ngFor="let o of orderResults.lines | keyvalue">
											<tr>
												<th colspan="9"> Product Line: {{o.key}} </th>
											</tr>
											<ng-container *ngFor="let line of o.value.l">
												<tr>
													<td>
														{{ line.BRANCH }}
													</td>
													<td>{{ line.CUSTOMER }}</td>
													<td>{{ line.NAME }}</td>
													<td>{{ line.SALESMAN }}</td>
													<td>{{ line.ZIP }}</td>
													<td><a class="link" (click)="viewCustomer(line.CUSTOMER, line.LINEID, line.BRANCH)">{{ line.LINEID }}</a></td>
													<td class="text-center">{{ line.PRDLINE }}</td>
													<td class="text-center">{{ line.QUANTITY }}</td>
													<td class="text-right">{{ line.Sales | currency}}</td>
												</tr>
											</ng-container>
											<tr *ngIf="o.key != 'ALL'" class="pl-rollup">
												<th></th>
												<th></th>
												<th></th>
												<th></th>
												<th></th>
												<th></th>
												<th></th>
												<th class="text-center">{{o.key }} Avg: {{ o.value.avg | currency}}</th>
												<th class="text-right">{{o.key }} Total: {{ o.value.total | currency}}</th>
											</tr>
										</ng-container>
										<tr>
											<th></th>
											<th></th>
											<th></th>
											<th></th>
											<th></th>
											<th></th>
											<th class="text-center">Count : {{ orderResults.totalrecords }}</th>
											<th class="text-center">Avg: {{ orderResults.avg | currency }}</th>
											<th class="text-right">Total: {{ orderResults.total | currency}}</th>
										</tr>
									</tbody>
								</table>
							</div>
							<div *ngIf="orderResults" style="display: block">
								<table class="table table-sm table-hover w-100" style="width: 100%;">
									<tr>
										<th colspan="9">
											<h3>Brands Selected</h3>
										</th>
									</tr>
									<tr>
										<th colspan="9">Name</th>
									</tr>
									<tr *ngFor="let b of reportCriteria.brands">
										<td colspan="9">{{brandData(b, 'name')}}</td>
									</tr>
								</table>
							</div>
							<div #print_tablexlsx class="noshow">
								<div class="table">
									<table class="table table-sm table-hover" *ngIf="orderResults">
										<thead>
											<tr>
												<th>BR</th>
												<th>Customer</th>
												<th>Name</th>
												<th>Salesperson</th>
												<th>Zip Code</th>
												<th>Line</th>
												<th class="text-center">Line Name</th>
												<th class="text-center">Qty</th>
												<th class="text-right">Sales</th>
											</tr>
										</thead>
										<tbody>
											<ng-container *ngFor="let o of orderResults.lines | keyvalue">
												<tr>
													<th colspan="9"> Product Line: {{o.key}} </th>
												</tr>
												<ng-container *ngFor="let line of o.value.l">
													<tr>
														<td>
															<b>{{ line.BRANCH }}</b>
														</td>
														<td><b>{{ line.CUSTOMER }}</b></td>
														<td>{{ line.NAME }}</td>
														<td><b>{{ line.SALESMAN }}</b></td>
														<td><b>{{ line.ZIP }}</b></td>
														<td><a class="link" (click)="viewCustomer(line.CUSTOMER, line.LINEID, line.BRANCH)">{{ line.LINEID }}</a></td>
														<td class="text-center">{{ line.PRDLINE }}</td>
														<td class="text-center">{{ line.QUANTITY }}</td>
														<td class="text-right">{{ line.Sales | currency}}</td>
													</tr>
												</ng-container>
												<tr *ngIf="o.key != 'ALL'" class="pl-rollup">
													<th></th>
													<th></th>
													<th></th>
													<th></th>
													<th></th>
													<th></th>
													<th></th>
													<th class="text-center">{{o.key }} Avg: {{ o.value.avg | currency}}</th>
													<th class="text-right">{{o.key }} Total: {{ o.value.total | currency}}</th>
												</tr>
											</ng-container>
											<tr>
												<th></th>
												<th></th>
												<th></th>
												<th></th>
												<th></th>
												<th></th>
												<th class="text-center">Count : {{ orderResults.totalrecords }}</th>
												<th class="text-center">Avg: {{ orderResults.avg | currency }}</th>
												<th class="text-right">Total: {{ orderResults.total | currency}}</th>
											</tr>
										</tbody>
									</table>
								</div>
								<div *ngIf="orderResults" style="display: block">
									<table class="table table-sm table-hover w-100" style="width: 100%;">
										<tr>
											<th colspan="9">
												<h3>Brands Selected</h3>
											</th>
										</tr>
										<tr>
											<th colspan="9">Name</th>
										</tr>
										<tr *ngFor="let b of reportCriteria.brands">
											<td colspan="9">{{brandData(b, 'name')}}</td>
										</tr>
									</table>
								</div>
							</div>
							<div #print_tablepdf style="display: none;">
								<div class="table">
									<table class="table table-sm table-hover" style="width: 100%;" *ngIf="orderResults">
										<thead>
											<tr>
												<th>BR</th>
												<th>Customer</th>
												<th>Name</th>
												<th>Salesperson</th>
												<th>Zip Code</th>
												<th>Line</th>
												<th class="text-center">Line Name</th>
												<th class="text-center">Qty</th>
												<th class="text-right">Sales</th>
											</tr>
										</thead>
										<tbody>
											<ng-container *ngFor="let o of orderResults.lines | keyvalue">
												<tr>
													<th colspan="9"> Product Line: {{o.key}} </th>
												</tr>
												<ng-container *ngFor="let line of o.value.l">
													<tr>
														<td>
															{{ line.BRANCH }}
														</td>
														<td>{{ line.CUSTOMER }}</td>
														<td>{{ line.NAME }}</td>
														<td>{{ line.SALESMAN }}</td>
														<td>{{ line.ZIP }}</td>
														<td><a class="link" (click)="viewCustomer(line.CUSTOMER, line.LINEID, line.BRANCH)">{{ line.LINEID }}</a></td>
														<td class="text-center">{{ line.PRDLINE }}</td>
														<td class="text-center">{{ line.QUANTITY }}</td>
														<td class="text-right">{{ line.Sales | currency}}</td>
													</tr>
												</ng-container>
												<tr *ngIf="o.key != 'ALL'" class="pl-rollup">
													<th></th>
													<th></th>
													<th></th>
													<th></th>
													<th></th>
													<th></th>
													<th></th>
													<th class="text-center">{{o.key }} Avg: {{ o.value.avg | currency}}</th>
													<th class="text-right">{{o.key }} Total: {{ o.value.total | currency}}</th>
												</tr>
											</ng-container>
											<tr>
												<th></th>
												<th></th>
												<th></th>
												<th></th>
												<th></th>
												<th></th>
												<th class="text-center">Count : {{ orderResults.totalrecords }}</th>
												<th class="text-center">Avg: {{ orderResults.avg | currency }}</th>
												<th class="text-right">Total: {{ orderResults.total | currency}}</th>
											</tr>
										</tbody>
									</table>
								</div>
								<div *ngIf="orderResults" style="display: block">
									<table class="table table-sm table-hover w-100" style="width: 100%;">
										<tr>
											<th colspan="9">
												<h3>Brands Selected</h3>
											</th>
										</tr>
										<tr>
											<th colspan="9">Name</th>
										</tr>
										<tr *ngFor="let b of reportCriteria.brands">
											<td colspan="9">{{brandData(b, 'name')}}</td>
										</tr>
									</table>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
