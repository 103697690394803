<div class="main-content">
	<div class="row m-0">
		<div class="container-fluid tp-box">
			<!-- connect scanners
			<button mat-raised-button type="button" aria-label="Close" (click)="getBatteryLevel();" class="m-0 p-0">
				Connect Device
			</button>
			-->

		<div class="card">
			<div class="card-header card-header-{{color}}-6">
				<div class="row m-0 p-0 ">
					<div class="col-9 text-left">
						<h4 class="card-title ">
							LOADING
						</h4>
					</div>
					<div class="col-3 text-right">



					</div>
				</div>
			</div>
			<div class="card-body" id="columnfocus">

				<mat-form-field appearance="fill">
					<mat-label>SCAN ITEMS</mat-label>
					<input matInput class="text-right" id="scantoadd" [formControl]="scantoadd1" #scantoadd >
				</mat-form-field>

				<div class="w-75 p-0 m-0 rightside" *ngIf="sortcolumns">
					<div class="small" *ngFor="let column of sortcolumns">
						<div class="p-0 m-0 ">
							<div class="row p-0 m-0 table">
								<div class="row p-0 m-0 table">
									<div class="col-12 col-lg-12 pt-0 pb-0 mt-0 mb-0 card-header pl-2 pr-0 mr-0 ml-0 card-header-{{color}}-6 no-radius">
										<div class="row m-0 p-0 ">
											<div class="col-auto smaller-font">


											</div>
											<div class="col-auto ml-auto  smaller-font mt-2">

											</div>
											<div class="col-auto mr-0 smaller-font mt-2">

											</div>
											<div class="col-auto ml-auto mr-0 smaller-font ">
												<ng-container>

												</ng-container>
											</div>
											<div class="col-auto ml-auto mr-0 smaller-font mt-2">

											</div>
										</div>
									</div>
									<div class="col-12 col-lg-12 scrolling-wrapper pl-0 ml-0">
										ONE
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>
