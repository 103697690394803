import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { GlobalSearchService } from '../../../services/globalsearchservice.service'
import { PrintService } from '../../../services/print.service'
@Component({
	selector: 'app-customer-disabled',
	templateUrl: './customer-disabled.component.html',
	styleUrls: ['./customer-disabled.component.scss']
})
export class CustomerDisabledComponent implements OnInit {
	@Input() data: any = [];
	filename: string = 'disabled_customers';
	sending: boolean = false;
	constructor(private globalSearchService: GlobalSearchService, private printService: PrintService) {}
	@ViewChild('print_table') printtable: ElementRef;
	ngOnInit(): void {}
	export () {
		this.globalSearchService.exportJsonToExcel(this.data, 'DisabledCustomers');
	}

	exportPdf() {
		this.sending = true;
		let encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const today = new Date();
		let data = {
			content: encoded,
			filename: this.filename,
			title: 'Customers Out Of Buisness',
			subtitle: 'Created '+today.toLocaleString()
		}

		this.printService.pdf(data).subscribe( (result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.sending = false;

		});
	}
	exportXls() {
		this.sending = true;
		let encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const today = new Date();
		let data = {
			content: encoded,
			filename: this.filename,
			title: 'Customers Out Of Buisness',
			subtitle: 'Created '+today.toLocaleString()
		}

		this.printService.xls(data).subscribe( (result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
		});
	}
}
