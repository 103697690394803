import { Component, OnDestroy, ElementRef, OnInit, ViewChild, OnChanges, SimpleChanges, ChangeDetectorRef, Injectable, ChangeDetectionStrategy } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { TableModule } from 'primeng/table';
import { DailySales } from '../classes/reports';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

import { Message } from '../classes/message';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { GlobalSearchService } from '../services/globalsearchservice.service';
import { GlobalsService } from '../services/globals.service';
import { UsersService } from '../services/users.service';

import { DashboardService } from '../services/dashboard.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as Chartist from 'chartist';
import { Directive, HostListener, ViewEncapsulation } from "@angular/core";
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';

import { Chart, registerables }  from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';


@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css'],
	animations: [
		trigger('flipState', [
			state('active', style({
				// transform: 'rotateY(179deg)',
				//safari does not like rotate Yputting the d
				transform: 'rotate3d(0, 1, 0, 179deg)',
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotate3d(0, 1, 0, 179deg)',
			})),
			transition('active => inactive', animate('400ms ease-out')),
			transition('inactive => active', animate('400ms ease-in')),
			transition('inactive => orderlookup', animate('400ms ease-out')),
			transition('orderlookup => inactive', animate('400ms ease-in')),
		]),
		trigger('openClose', [
			state(':enter', style({ height: '*' })),
			state(':leave', style({ height: '0px' })),
			transition('false <=> true', animate(500))
		]),
		trigger('filterAnimation', [
			transition(':enter, * => 0, * => -1', []),
			transition(':increment', [
				query(':enter', [
					style({ opacity: 0, width: 0 }),
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 1, width: '*' })),
					]),
				], { optional: true })
			]),
			transition(':decrement', [
				query(':leave', [
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 0, width: 0 })),
					]),
				])
			]),
		]),
		trigger("grow", [ // Note the trigger name
			transition(":enter", [
				// :enter is alias to 'void => *'
				style({ height: "0", width: "0", overflow: "hidden" }),
				animate(200, style({ height: "*", width: "*" })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(":leave", [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0, overflow: "hidden" })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class DashboardComponent implements OnInit {
	@ViewChild("monthView") monthViewRef: ElementRef;
	datepicked: any = false;
	public info = [];
	item: any = [];
	public ordersbyday: any = [];
	public max: any = [];
	public percentup: any = [];
	activeReport: any = 'Report 1';
	openorders: any = 'notready';
	needlocation: any = 'notready';
	negativestock: any = 'notready';
	newMessage: string;
	messageList: string[] = [];
	negativelink: any;
	needloclink: any;
	sender: any = [];
	flip: string = 'inactive';
	viewing: any = '';
	flip_text: string = '';
	color: string = 'blue'
	SENDER: any = [];
	messages = [];
	message: any = [];
	CHAT_ROOM = "DashBoard";
	messageForm: any = [];
	carddate: any = [];
	posbyday: any = [];
	orderpweek: any = [];
	orderweek: any = [];
	config: any = false;
	popweek: any = [];
	poweek: any = [];
	popercentup: any = [];
	taxorders: any = [];
	thisoverlast: any = false;
	telegram_reports: string[] = ['Report 1', 'Report 2', 'Report 3', 'Report 4', 'Report 5'];
	datasource: any = false;
	catchart: any = false;
	linechart: any = false;
	public dataDailySalesChart: any = {
		labels: [],
		series: [],
	};

	catandlines: any = false;
	userData;
	hidedash: any = true;
	user: any;
	telegramAllowed: boolean = true;
	category_table_data: any = [];
	line_table_data: any = [];


	constructor(private router: Router, private sanitizer: DomSanitizer, public dashboardService: DashboardService, public formBuilder: UntypedFormBuilder, private globalsService: GlobalsService, private globalSearchService: GlobalSearchService, private modalService: NgbModal, private usersService: UsersService) {
		this.color = this.globalsService.getColor();
		this.userData = this.usersService.getLocalUser();


		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			if (this.userData.user.issalesman || this.config.env.package == 'tires') {
				this.telegramAllowed = false;
			}

			this.loadData();
		});
	}

	ngOnInit() {

		this.globalSearchService.mesagesallowed.next(false);
		this.messageForm = this.formBuilder.group({
			message: '',
		});

	}

	viewHistory(month: any, year: any) {
		if (parseInt(month) < 10) {
			month = '0' + month;
		}

		var date = new Date(year + '-' + month + '-15')

		this.datepicked = date;

		this.modalService.open(this.monthViewRef, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result) => {

		}, (reason) => {

		});

	}

	selectItemLookup(event: any) {

		this.router.navigate(['./orders/entry/' + event.header.debtorno + '/' + event.header.branchcode]);
		//http://localhost:4200/#/orders/entry/107/00
	}

	getMonthYearQty(month: any, year: any) {

		const obj = year.qty.filter((monthyear: any) => {
			return monthyear.label == month;
		})[0];

		switch (obj.monthgrowth) {
			case -1:
				var color = '';
				break;
			default:
				var color = (obj.monthgrowth > 0) ? 'text-success' : 'text-danger'
				break;
		}

		switch (obj.monthpreviousgrowth) {
			case 0:
			case -1:
				var mcolor = '';
				break;
			default:
				var mcolor = (obj.monthpreviousgrowth > 0) ? 'text-success' : 'text-danger'
				break;
		}

		const returnValue = {
			percent_month: obj.monthpreviousgrowth,
			percent_month_color: mcolor,
			percent_month_icon: (obj.monthpreviousgrowth > 0) ? 'fa-long-arrow-up' : 'fa-long-arrow-down',
			percent: (obj.monthgrowth == -1) ? 0 : obj.monthgrowth,
			class: color,
			icon: (obj.monthgrowth > 0) ? 'fa-long-arrow-up' : 'fa-long-arrow-down',
		};

		return returnValue;
	}

	formatData(data = [], title: any, expanded: any= false) {
		let _labels = [];
		let _series = [];
		var datapoints = [];

		if(!expanded) {
			data = data.slice(0,10);
		}

		data.forEach((r, index) => {
			let value = {
				value: r.value,
				meta: r.label,
				name: r.label,
				label: r.label,
				data: r.value
			}
			_labels.push(r.label)

			if(this.config.env.package == 'tires') {
				_series.push(r.qty)
			} else {
				_series.push(r.value)
			}

			datapoints.push(value)
		});

		return {
			labels: _labels,
			datasets: [{
				fill: false,
				label: title,
				data: _series,
			}]
		};
	}

	getCostTotalYear(year: any) {
		let value = year.cost.reduce(function(accumulator: number, items: any) {
			let addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);

		return value;
	}

	yearQtyGrowth(yearin: any) {

		// 		let value  = year.qty.reduce(function(accumulator: number, items: any) {
		// 			let addon = parseFloat(items.value);
		// 			return accumulator + addon;
		// 		}, 0);
		//
		// 		return value;
	}

	getSaleTotalYear(year: any) {
		var value = 0
		if (year) {
			value = year.sales.reduce(function(accumulator: number, items: any) {
				let addon = parseFloat(items.value);
				return accumulator + addon;
			}, 0);
		} else {
			value = 0
		}

		return value;
	}

	getQtyTotalYear(year: any) {
		let value = year.qty.reduce(function(accumulator: number, items: any) {
			let addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);

		return value;
	}

	loadData() {


		this.dashboardService.getOpenCOunt().subscribe((results: any) => {
			this.openorders = results.data;
		});

		this.dashboardService.getNegative().subscribe((results: any) => {
			this.negativestock = results.data;
		});

		this.dashboardService.getNeedLocation().subscribe((results: any) => {
			this.needlocation = results.data;
		});

		if (this.config.env.package == 'tires') {
			this.dashboardService.getThisYearOverLast().subscribe((results: any) => {
				this.thisoverlast = results;
			});
		}
		Chart.register(...registerables);
		Chart.register(ChartDataLabels);

		this.dashboardService.getCatAndLines().subscribe((results: any) => {
			this.catandlines = results;


			const catdata = this.formatData(results.category_sales , 'Category Sales' , false);

			if (this.catchart) {
				this.catchart.destroy();
			}
			if(this.telegramAllowed) {
				this.catchart = new Chart( 'catChartRef', {
					type: 'pie',
					data: catdata,
					options: {
						responsive: true,
						plugins: {
							legend: {
								position: 'bottom',
							},
							title: {
								display: false,
								text: 'Category Sales'
							}
						}
					}
				});
			}

			if (this.linechart) {
				this.linechart.destroy();
			}



			const linedata= this.formatData(results.line_sales , 'Product Line Sales', false);
			this.linechart = new Chart( 'plineChart', {
				type: 'pie',
				data: linedata,
				options: {
					responsive: true,
					plugins: {
						legend: {
							position: 'bottom',
						},
						title: {
							display: false,
							text: 'Product Line Sales'
						}
					}
				}
			});
		});




		//this.dashboardService.getTaxOrders().subscribe((results: any) => {
		//	this.taxorders = results;
		//});


		this.negativelink = this.config.apiServer.baseUrl + 'reports/report/html/?report=samples/221123110123.sql';
		this.needloclink = this.config.apiServer.baseUrl + 'reports/report/html/?report=samples/221122023111.sql';


		this.dashboardService.getReceievedTotalByDay().subscribe((results: DailySales) => {
			this.posbyday = results.data;
			this.poweek = results.week;
			this.popweek = results.pweek;
			this.popercentup = results.percentup;

			let labels = []
			let series = []
			this.posbyday.forEach((keys: any, vals: any) => {
				labels.push(keys.label)
				series.push(keys.count)
			});

			var datawebsiteViewsChart = {
				labels: labels,
				series: [
					series

				]
			};
			var optionswebsiteViewsChart = {
				lineSmooth: Chartist.Interpolation.cardinal({
					tension: 0
				}),
				axisX: {
					showGrid: false
				},
				//showArea: true,
				low: 0,
				high: results.max,
				chartPadding: { top: 0, right: 5, bottom: 0, left: 0 }
			};
			/*
			var responsiveOptions: any[] = [
			  ['screen and (max-width: 640px)', {
				seriesBarDistance: 5,
				axisX: {
				  labelInterpolationFnc: function (value) {
					return value[0];
				  }
				}
			  }]
			];
			*/
			//, responsiveOptions
			var websiteViewsChart = new Chartist.Line('#websiteViewsChart', datawebsiteViewsChart, optionswebsiteViewsChart);

			//start animation for the Emails Subscription Chart
			this.startAnimationForBarChart(websiteViewsChart);

		});


		this.dashboardService.getOrderCountByDay().subscribe((results: DailySales) => {

			this.ordersbyday = results.data;
			this.max = results.max;
			this.orderweek = results.week;
			this.orderpweek = results.pweek;

			this.percentup = results.percentup
			const dataDailySalesChart: any = {
				labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
				series: [
					[12, 17, 7, 17, 23, 18, 38]
				]
			};

			let labels = []
			let series = []
			this.ordersbyday.forEach((keys: any, vals: any) => {
				labels.push(keys.label)
				series.push(keys.count)
			});

			this.dataDailySalesChart.labels = labels;
			this.dataDailySalesChart.series = [series];

			const optionsDailySalesChart: any = {
				lineSmooth: Chartist.Interpolation.cardinal({
					tension: 0
				}),
				low: 0,
				high: this.max,
				chartPadding: { top: 0, right: 0, bottom: 0, left: 0 },
			}

			var dailySalesChart = new Chartist.Line('#dailySalesChart', this.dataDailySalesChart, optionsDailySalesChart);
			this.startAnimationForLineChart(dailySalesChart);

		});
	}

	startAnimationForPie(chart) {

		chart.on('draw', function(data) {

			if (data.type === 'slice') {
				// Get the total path length in order to use for dash array animation
				var pathLength = data.element._node.getTotalLength();

				// Set a dasharray that matches the path length as prerequisite to animate dashoffset
				data.element.attr({
					'stroke-dasharray': pathLength + 'px ' + pathLength + 'px'
				});

				// Create animation definition while also assigning an ID to the animation for later sync usage
				var animationDefinition = {
					'stroke-dashoffset': {
						id: 'anim' + data.index,
						dur: 5000,
						from: -pathLength + 'px',
						to: '0px',
						easing: Chartist.Svg.Easing.easeOutQuint,
						// We need to use `fill: 'freeze'` otherwise our animation will fall back to initial (not visible)
						fill: 'freeze',
						begin: 'anim'+ (data.length - 1)
					}
				};

				// If this was not the first slice, we need to time the animation so that it uses the end sync event of the previous animation
				if (data.index !== 0) {
					animationDefinition['stroke-dashoffset'].begin = 'anim' + (data.index - 1) + '.end';
				}

				// We need to set an initial value before the animation starts as we are not in guided mode which would do that for us
				data.element.attr({
					'stroke-dashoffset': -pathLength + 'px'
				});

				// We can't use guided mode as the animations need to rely on setting begin manually
				// See http://gionkunz.github.io/chartist-js/api-documentation.html#chartistsvg-function-animate
				data.element.animate(animationDefinition, false);
			}
		});
		chart.on('created', function() {
			setTimeout(chart.update.bind(chart), 10000);
		});
	}

	startAnimationForLineChart(chart) {
		let seq: any, delays: any, durations: any;
		seq = 0;
		delays = 80;
		durations = 500;

		chart.on('draw', function(data) {
			if (data.type === 'line' || data.type === 'area') {
				data.element.animate({
					d: {
						begin: 600,
						dur: 700,
						from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
						to: data.path.clone().stringify(),
						easing: Chartist.Svg.Easing.easeOutQuint
					}
				});
			} else if (data.type === 'point') {
				seq++;
				data.element.animate({
					opacity: {
						begin: seq * delays,
						dur: durations,
						from: 0,
						to: 1,
						easing: 'ease'
					}
				});
			}
		});

		seq = 0;
	};

	startAnimationForBarChart(chart) {
		let seq2: any, delays2: any, durations2: any;

		seq2 = 0;
		delays2 = 80;
		durations2 = 500;
		chart.on('draw', function(data) {
			if (data.type === 'bar') {
				seq2++;
				data.element.animate({
					opacity: {
						begin: seq2 * delays2,
						dur: durations2,
						from: 0,
						to: 1,
						easing: 'ease'
					}
				});
			}
		});

		seq2 = 0;
	};

	randomString(length, chars) {
		var result = '';
		for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
		return result;
	}
	toggleFlip(viewing: any) {
		this.viewing = viewing
		this.flip = (this.flip == 'active') ? 'inactive' : 'active';
	}

	nextChar(c) {
		return String.fromCharCode(c.charCodeAt(0) + 1);
	}
	getDataChartPoint(value: any, label: any, letter: any) {
		return {
			value: value,
			//name: label,
			className: 'ct-series-' + letter,
		}
	}

	ngOnDestroy() {

	}
}
