<div class="main-content" *ngIf="!vehForm">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-location">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Customer Vehicles</h4>
						<p class="card-category">Vehicles</p>
					</div>
					<div class="card-body">
						<div class="table table-sm ">
							<div class="table table-responsive text-center" *ngIf="!vehicles">
								<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
   							 	<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</div>
							<div class="row">
								<div class="col-4">
									<a [routerLink]="'edit'"  mat-stroked-button color="danger">New Vehicle</a>
								</div>
							</div>
							<mat-table  [dataSource]="vehicles" matSort (matSortChange)="announceSortChange($event)" >
								<ng-container matColumnDef="actions">
									<mat-header-cell class="d-none d-md-inline" *matHeaderCellDef ></mat-header-cell>
									<mat-cell class="d-none d-md-inline" *matCellDef="let vehicle">
										<a [routerLink]="[vehicle.vehicleid]" mat-stroked-button class="mt-1">Edit</a>
                                        <button class="btn btn-sm btn-danger " (click)="deleteVehicle(vehicle.vehicleid)"><i class="fa fa-remove"></i></button>
                                        </mat-cell>
								</ng-container>

								<ng-container matColumnDef="plate">
									<mat-header-cell  *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Code">
									  Plate #
									</mat-header-cell>
									<mat-cell *matCellDef="let vehicle"> {{vehicle.plate}} </mat-cell>
								  </ng-container>
								<ng-container matColumnDef="year">
									<mat-header-cell *matHeaderCellDef mat-sort-header> Year</mat-header-cell>
									<mat-cell *matCellDef="let vehicle"> {{ vehicle.year}} </mat-cell>
								</ng-container>
                                <ng-container matColumnDef="make">
									<mat-header-cell *matHeaderCellDef mat-sort-header> Make</mat-header-cell>
									<mat-cell *matCellDef="let vehicle"> {{ vehicle.make}} </mat-cell>
								</ng-container>          
                                <ng-container matColumnDef="model">
									<mat-header-cell *matHeaderCellDef mat-sort-header> Model</mat-header-cell>
									<mat-cell *matCellDef="let vehicle"> {{ vehicle.model}} </mat-cell>
								</ng-container>       
                                <ng-container matColumnDef="model_type">
									<mat-header-cell *matHeaderCellDef mat-sort-header> Option</mat-header-cell>
									<mat-cell *matCellDef="let vehicle"> {{ vehicle.model_type}} </mat-cell>
								</ng-container>
								<mat-header-row  *matHeaderRowDef="headercolumns"></mat-header-row>
								<mat-row *matRowDef="let row; columns: headercolumns;"></mat-row>


							</mat-table>
							<mat-paginator [pageSizeOptions]="[25, 50, 100]"
											 showFirstLastButtons
											 aria-label="select page">
							  </mat-paginator>


						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<div class="main-content" *ngIf="vehForm">

<form [formGroup]="vehForm" class="text-dark" (submit)="updateVehicle()" >

  <div class="container-fluid" *ngIf="ld">
	  <div class="row">
		  <div class="col-md-8">
			  <div class="card">
				  <div class="card-header card-header-location" >
					  <h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i>Customer Vehicles</h4>
					  <p class="card-category"></p>
				  </div>
				  <div class="card-body">
				  <div class="row">
					  <div class="col-md-12">
						  <mat-form-field class="full-width"
							  appearance="standard"
							  >
                              <mat-label>Plate #</mat-label>
							  <input matInput required
								  formControlName="Plate #"
								  placeholder="plate"
							  [value]="ld?.plate" >
							</mat-form-field>
						</div>
				  </div>
                  <div class="row">
					  <div class="col-md-3">
						<mat-form-field class="full-width"
							appearance="standard"
							>
							<mat-label>Year</mat-label>
							<mat-select [(ngModel)]="ld.year"
						  	formControlName="year" (selectionChange)="getMakes()">
                            <mat-option value=""></mat-option>
						  	<mat-option *ngFor="let year of years"
						  	[value]="year.cyear">
						  	{{ year.cyear }} </mat-option>
						  	</mat-select>
						  </mat-form-field>
					  </div>
                      <div class="col-md-3">
						<mat-form-field class="full-width"
							appearance="standard"
							>
							<mat-label>Make</mat-label>
							<mat-select [(ngModel)]="ld.make"
						  	formControlName="make" (selectionChange)="getModels()">
                            <mat-option value=""></mat-option>
						  	<mat-option *ngFor="let make of makes"
						  	[value]="make.make1">
						  	{{ make.make1 }} </mat-option>
						  	</mat-select>
						  </mat-form-field>
					  </div>
                      <div class="col-md-3">
						<mat-form-field class="full-width"
							appearance="standard"
							>
							<mat-label>Model</mat-label>
							<mat-select [(ngModel)]="ld.model"
						  	formControlName="model" (selectionChange)="getOpts()">
                            <mat-option value=""></mat-option>
						  	<mat-option *ngFor="let model of models"
						  	[value]="model.model">
						  	{{ model.model }} </mat-option>
						  	</mat-select>
						  </mat-form-field>
					  </div>
                      <div class="col-md-3">
						<mat-form-field class="full-width"
							appearance="standard"
							>
							<mat-label>Option</mat-label>
							<mat-select [(ngModel)]="ld.model_type"
						  	formControlName="model_type">
                            <mat-option value=""></mat-option>
						  	<mat-option *ngFor="let opt of opts"
						  	[value]="opt.option1">
						  	{{ opt.option1 }} </mat-option>
						  	</mat-select>
						  </mat-form-field>
					  </div>
				  </div>
				  <div class="row">
					  <div class="col-md-12">
						<mat-form-field class="full-width"
							appearance="standard"
							>
							<mat-label>Account Name</mat-label>
							<input matInput
								formControlName="accountname"
								placeholder="Account Name"
							[value]="ld?.accountname" >
						  </mat-form-field>
					  </div>
                      
				  </div>
				  <div class="row">
					  <div class="col-md-12">
						<mat-form-field class="full-width"
							appearance="standard"
							>
							<mat-label>Account Group</mat-label>
							<mat-select
						  	formControlName="group">
                            <mat-option value=""></mat-option>
						  	<mat-option *ngFor="let group of groups"
						  	[value]="group.groupname">
						  	{{ group.groupname }} </mat-option>
						  	</mat-select>
						  </mat-form-field>
					  </div>
				  </div>
			
			 </div>
			 <div class="row">
				 <div class="col-6 ml-0 mb-6">
					 <button mat-raised-button type="submit" class="btn btn-danger pull-right">Update Vehicle</button>
				 </div>
			 </div>
				<div class="clearfix"></div>
				</div>
		  </div>
	  </div>
  </div>
  </form>
</div>
