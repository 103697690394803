import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform} from '@angular/core';

import { ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';

import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { PurchasingService } from '../../../services/purchasing.service';


@Component({
	selector: 'app-purchasing-reordering-restock',
	templateUrl: './purchasing-reordering-restock.component.html',
	styleUrls: ['./purchasing-reordering-restock.component.scss']
})
export class PurchasingReorderingRestockComponent implements OnInit {
locations : any = [];
	reportForm: UntypedFormGroup;
	today = new Date();
	results: any = [];
	loading: boolean = false;
	suppliers: any = [];
	constructor(private purchasingService: PurchasingService,private globalSearchService: GlobalSearchService,private location: Location,private route: ActivatedRoute,private globalsService: GlobalsService,private fb: UntypedFormBuilder) { }

	ngOnInit(): void {
		this.purchasingService.getSuppliers('').subscribe((results: any) => {
			this.suppliers = results;
			this.reportForm = this.fb.group({
				loccode: ['01', Validators.required],
				vendorid: ['', Validators.required ]
			});
		})
	}

	getRecomendations() {
		if(this.reportForm.valid) {
			this.loading = true;
			this.purchasingService.getReStock(this.reportForm.value).subscribe((results: any) => {
				this.results = results
				this.loading = false;
			});
		}
	}

	ngAfterViewInit() {

		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
		});
	}

}
