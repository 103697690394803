import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform,AfterViewInit} from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';

import { DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { ActivatedRoute, Router} from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { Product } from '../../classes/orders';
import { Directive, HostListener,ViewEncapsulation } from "@angular/core";
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-receivables-dashboard',
	templateUrl: './receivables-dashboard.component.html',
	styleUrls: ['./receivables-dashboard.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: [
		  trigger('flipState', [
			  state('active', style({
				  transform: 'rotate3d(0, 1, 0, 179deg)',
			  })),
			  state('inactive', style({
				  transform: 'rotateY(0)'
			  })),
			  state('orderlookup', style({
				  transform: 'rotateY(179deg)'
			  })),
			  transition('active => inactive', animate('500ms ease-out')),
			  transition('inactive => active', animate('500ms ease-in')),
			  transition('inactive => orderlookup', animate('500ms ease-out')),
			  transition('orderlookup => inactive', animate('500ms ease-in')),
		  ]),
		 trigger('openClose', [
			  state(':enter', style({ height: '*' })),
			  state(':leave', style({ height: '0px' })),
			  transition('false <=> true', animate(500))
		]),
		trigger('filterAnimation', [
		  transition(':enter, * => 0, * => -1', []),
		  transition(':increment', [
			query(':enter', [
			  style({ opacity: 0, width: 0 }),
			  stagger(50, [
				animate('300ms ease-out', style({ opacity: 1, width: '*' })),
			  ]),
			], { optional: true })
		  ]),
		  transition(':decrement', [
			query(':leave', [
			  stagger(50, [
				animate('300ms ease-out', style({ opacity: 0, width: 0 })),
			  ]),
			])
		  ]),
		]),
		trigger("grow", [ // Note the trigger name
		  transition(":enter", [
			// :enter is alias to 'void => *'
			style({ height: "0",width: "0", overflow: "hidden" }),
			animate(200, style({ height: "*" , width: "*"})),
			animate('200ms', style({ opacity: 1 })),
		  ]),
		  transition(":leave", [
			// :leave is alias to '* => void'
			animate(100, style({ height: 0, width: 0 , overflow: "hidden" })),
			animate('100ms', style({ opacity: 0 }))
		  ])
		]),
		]
	})
export class ReceivablesDashboardComponent implements OnInit {
	flip: string = 'inactive';
	trialbalance: any = false;
	overdueone: any = false;
	overduetwo: any = false;
	original_trialbalance: any = false;
	allcustomers: any = false;
	alltotal: number = 0;
	total_overdue: number = 0;
	currentcarts: any = false;
	online: any = false;
	viewing: any = '';
	total_overdue_details =  {
		one: 0,
		two: 0,
		total: 0,
	}
	shown_list: any = [];
	disabledcustomers: any = []
	disabledtotal: number = 0;
	flip_text: string= '';
	color: string = 'blue';
	cartagebuckets: any = [];
	cartsettings: any = [];
	viewing_title: string = '';

	viewing_data: any = false;

	constructor(private route: ActivatedRoute,private customerService: CustomerService, private orderService: OrdersService, private location: Location,public router: Router,public cdr: ChangeDetectorRef,private globalSearchService: GlobalSearchService,private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {

		this.customerService.getAllCustomers().subscribe(async(results: any)=> {
			this.allcustomers = results;
			this.alltotal = results.length
		});



		this.customerService.getCurrentOnline().subscribe(async(results: any)=> {
			this.online = results;
		});

	}

	back(): void {
		this.location.back()
	}



	toggleFlipTest(viewing: any) {

		this.viewing_data = viewing.data
		this.viewing_title = viewing.title
		//this.flip = (this.flip == 'active') ? 'inactive':'active';
	}

	toggleFlip(viewing: any) {
		this.viewing = viewing
		this.flip = (this.flip == 'active') ? 'inactive':'active';
	}
}
