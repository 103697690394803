import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { InventoryService } from '../../../services/inventory.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, FormControl, ValidationErrors } from '@angular/forms';

@Component({
	selector: 'app-productline-autocomplete',
	templateUrl: './productline-autocomplete.component.html',
	styleUrls: ['./productline-autocomplete.component.scss']
})

export class ProductlineAutocompleteComponent implements OnInit {
	@Output() value_selected = new EventEmitter < any > ();
	searchInput = new FormControl('');
	searching: boolean = false
	searchsubscription: any = false;
	allResults: any = false;
	filteredItems: any = false;
	field_label: string = 'Product Line'
	keys: string = 'line_field,line_description';

	constructor(private globalSearchService: GlobalSearchService, private inventoryService: InventoryService) {}

	ngOnInit(): void {
		this.inventoryService.getItemProductlines().subscribe(c => {
			this.allResults = c;
			this.filteredItems = c;
		})

		this.searchInput.valueChanges.subscribe(newValue => {
			this.search()
		})
	}

	selectValue(value: any) {
		this.value_selected.emit(this.searchInput.value)
	}


	search() {

		if (this.searchsubscription) {
			this.searchsubscription.unsubscribe();
		}

		let searchvalue = this.searchInput.value;
		if (this.searchInput.value == '') {
			this.filteredItems = this.allResults;
		} else {
			this.filteredItems = this.globalSearchService.filterItem(this.allResults, this.searchInput.value, this.keys)
		}
	}

	displayFn(value: any) {

		if (!value) {
			return '';
		}

		return value.line_field + '-' + value.line_description
	}

}
