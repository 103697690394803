<ng-template #partSelect let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Add More Parts</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row" *ngIf="!loading">
			<form [formGroup]="searchForm">
				<div class="col-md-12">
					<mat-form-field appearance="standard" class="searchbar">
						<input matInput #itemrec formControlName="searchVal" id="itemrec" class="margin-top" autocomplete="off" placeholder="* Item Search  " (keyup.enter)="$event.stopPropagation();$event.preventDefault();itemSearch();$event.target.blur();">
						<div matSuffix class="text-right">
							<button mat-icon-button type="button" color="white" (click)="$event.stopPropagation();$event.preventDefault();itemSearch()">
								<mat-icon>search</mat-icon>
							</button>
						</div>
					</mat-form-field>
				</div>
			</form>
		</div>
		<div class="row">
			<table *ngIf="items.length > 0">
				<tr>
					<th>Item #</th>
					<th>Description</th>
					<th>QOH at Warehouse</th>
					<th> QOH at Store</th>
				</tr>
				<tr *ngFor="let part of items">
					<td>
						{{part.stockid}}
					</td>
					<td>
						{{part.description}}
					</td>
					<td>
						{{part.fromqoh}}
					</td>
					<td>
						{{part.toqoh}}
					</td>
					<td>
						<div class="row">
							<div class="col-2 mt-3 ml-0 mr-0 ml-auto">
								<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="decrement(part)">
									<small><i class="material-icons">remove</i></small>
								</button>
							</div>
							<div class="col-6 m-0 ">
								<mat-form-field appearance="standard">
									<mat-label>Qty</mat-label>
									<span matPrefix>#</span>
									<input matInput tabindex="{{ i + 2 }}" type="number" value="{{ part.quantity | number }}" autofocus (input)="updateSearchQuantity($event, part )" class="text-right">
								</mat-form-field>
							</div>
							<div class="col-2 mt-3 ml-0 mr-0 mr-auto ">
								<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="increment(part)">
									<small>
										<i class="material-icons">add</i></small>
								</button>
							</div>
							<div class="col-2 mt-3 ml-0 mr-0 mr-auto">
								<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2" (click)="addItems(items)">
									<small><i class="material-icons">add_box</i></small>

								</button>
							</div>
						</div>
					</td>
				</tr>
			</table>
		</div>
	</div>
</ng-template>

<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Store Reorder Reports</h4>
						<p class="card-category text-right">Reordering</p>
					</div>
					<div class="card-body ">
						<ng-container>
								<div class="row">
								<div class="col-md-4">
									<mat-form-field appearance="fill">
									  <mat-label>Location</mat-label>
									  <mat-select [formControl]="branch" (selectionChange)="resetReorder();">
													<mat-option *ngFor="let v of branches" [value]="v.loccode">
														{{v.locationname}}
													</mat-option>
												</mat-select>
									</mat-form-field>
								</div>
								<div class="col-4">
								<mat-form-field appearance="standard">
									<mat-label>History As Of</mat-label>
									<input matInput (dateChange)="resetReorder();" [formControl]="dateCtrl" [matDatepicker]="payfrom">
									<mat-datepicker-toggle matSuffix [for]="payfrom"></mat-datepicker-toggle>
									<mat-datepicker #payfrom></mat-datepicker>
								</mat-form-field>
								</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<mat-form-field appearance="standard">
											<mat-chip-list #chipList aria-label="Product Line Selection">
												<mat-chip *ngFor="let prod of prods" [selectable]="selectable" [removable]="removable" (removed)="remove(prod)">
													{{prod.line_description}}
													<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
												</mat-chip>
												<input placeholder="Add a product line... (* to add all results)" #prodInput [formControl]="prodCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
											</mat-chip-list>
											<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
												<mat-option *ngFor="let prod of filteredProds" [value]="prod.line_field">
													{{prod.line_description}}
												</mat-option>
											</mat-autocomplete>
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<button mat-stroked-button color="warn" (click)="getRecomendations()">Run</button>
										<button *ngIf="results.length" mat-stroked-button color="warn" (click)="addMore()">Add Items Manually</button>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<div *ngIf="loading">
											<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
												<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
												</circle>
											</svg>
										</div>
										<div class="table" #print_table *ngIf="!loading && results.length">
											<table class="table table-sm">
												<tr>
													<th> Item#</th>
													<th> Desc</th>
													<th class="text-center"> QOH </th>
													<th class="text-center"> 3Mos </th>
													<th class="text-center"> 2Mos </th>
													<th class="text-center"> 1Mos </th>
													<th class="text-center"> MTD </th>
													<th class="text-center"> Available </th>
													<th class="text-center"> Back/Ord </th>
													<th class="text-right noprint">Recommended Request</th>
													<th class="text-right donotshow">Recommended Request</th>
												</tr>
												<tr *ngFor="let item of results">
													<td>{{ item.stockid }}</td>
													<td>{{ item.description }}</td>
													<td class="text-center">{{ item.qoh }}</td>
													<td class="text-center">{{ item.threemo }}</td>
													<td class="text-center">{{ item.twomo }}</td>
													<td class="text-center">{{ item.onemo }}</td>
													<td class="text-center">{{ item.thismo }}</td>
													<td class="text-center">{{ item.avail }}</td>
													<td class="text-center">{{ item.qos }}</td>
													<td class="text-right noprint"><mat-form-field appearance="standard">
															<mat-label>Qty</mat-label>
															<span matPrefix>#</span>
															<input matInput type="number" value="{{ item.qty_purchase | number }}" autofocus (input)="updateBuyQuantity($event, item )" class="text-right">
														</mat-form-field></td>
													<td class="text-center noprint"><mat-icon (click)="removeItem(item)">remove</mat-icon></td>
													<td class="text-right donotshow">{{ item.qty_purchase | number }}</td>
												</tr>
											</table>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12" *ngIf="!loading && results.length">
										<button mat-stroked-button color="success" (click)="createTransfer()">Create Transfer Request</button>
										&nbsp;&nbsp;
										<button mat-raised-button (click)="$event.preventDefault();exportPdf()">
											<i class="fa fa-file-pdf-o"></i> PDF
										</button>
									</div>
								</div>
						</ng-container>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>
