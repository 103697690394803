import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
	selector: '[appSelectAll]',
})

export class MultiselectDirective {

	constructor(private select: MatSelect, private renderer: Renderer2) {
		this.select.openedChange.subscribe(isOpen => {
			if (isOpen) {
				const div = this.renderer.createElement('div');
				this.renderer.addClass(div, 'select-all');
				const button = this.renderer.createElement('button');
				this.renderer.listen(
					button,
					'click',
					() => this.selectAllToggle()
				);
				const text = this.renderer.createText('Select All/None');
				this.renderer.appendChild(button, text);
				this.renderer.appendChild(div, button);
				this.select.panel.nativeElement.parentNode.appendChild(div)
				//this.renderer.insertBefore(, div, this.select.panel.nativeElement.nextSibling);
			}
		})
	}

	ngOnInit() {


	}

	selectAllToggle(): any {
		//tired coming back to
		let currentvalue = this.select.value;
		if (currentvalue.length) {
			this.select.value = [];
		} else {
			let allvalues = this.select.options.map(r => {
				return r.value;
			});
			this.select.value = allvalues;
		}
	}

}
