import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from '../../services/orders.service';
import { GlobalsService } from '../../services/globals.service';
import { CustomerService } from '../../services/customer.service';

import { PrintService } from '../../services/print.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

import { Directive, HostListener } from "@angular/core";
import { trigger, state, style, transition, animate } from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';
import { Location } from '@angular/common'

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-customer-monthly-history',
	templateUrl: './customer-monthly-history.component.html',
	styleUrls: ['./customer-monthly-history.component.scss']
})
export class CustomerMonthlyHistoryComponent implements OnInit {

	@Input() customerdata;
	@Input() pos: any = false;
	displayCustomerLookup: any = false;
	@ViewChild('monthDetails') monthDetailsRef: ElementRef;
	@ViewChild('print_history') print_historyRef: ElementRef;
	@ViewChild('print_items') print_itemsRef: ElementRef;

	config: any = [];
	results: any = false;
	filtered_results: any = [];
	all_results: any = [];
	fromdateCtrl = new UntypedFormControl('');
	todateCtrl = new UntypedFormControl('');
	search = new UntypedFormControl('');
	hideshowunits = new UntypedFormControl(true);
	hideshowsales = new UntypedFormControl(true);
	hideshowcost = new UntypedFormControl(false);
	consolidated = new UntypedFormControl(true);
	brands = new UntypedFormControl('');
	defaultlocation = new UntypedFormControl('');

	categoryid = new UntypedFormControl('');
	lineid = new UntypedFormControl('');

	locations: any = false;

	user: any = false;
	ref_location: any = false;
	loactions: any = false;
	todate = new Date();
	fromdate = new Date();
	today = new Date();
	sending: boolean = false;
	searchsubscripiton: any = false;
	displaydetails: any = false;
	monthdetails: any = false;
	monthtitle: any = '';
	cdetLink: any = '';
	filename: string = 'Customer Item History';

	properties: any = [];
	displayLookup: boolean = true;
	color: string = 'blue';

	constructor(private activatedRoute: ActivatedRoute, public printService: PrintService, public ordersService: OrdersService, public globalSearchService: GlobalSearchService, public router: Router, public customerService: CustomerService, private location: Location, private globalsService: GlobalsService, private modalService: NgbModal) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			this.cdetLink = r.apiServer.baseUrl + r.apiServer.cdetLink
		})

		this.globalsService.getItemSetup().subscribe((results: any) => {
			this.properties = results
		});

		this.color = this.globalSearchService.getColor();

		this.globalSearchService.user.subscribe(results => {
			//only run if user is definied
			if (this.user) {
				if (results.user.defaultlocation.loccode != this.ref_location) {
					this.defaultlocation.setValue(this.user.user.defaultlocation.loccode);
					this.ref_location = false;
				}
			}

			this.user = results
			if (!this.ref_location) {
				this.ref_location = this.user.user.defaultlocation.loccode;
			}
		});

		if (!this.customerdata) {
			this.activatedRoute.params.subscribe(({ id }) => {
				if (!isNaN(id)) {
					this.results = false;
					this.customerService.getDebtor(id).subscribe((data: any) => {
						this.customerdata = data;
						this.loadData();
						this.displayLookup = false;
					});
				}
			});
		}


	}

	ngOnInit(): void {
		this.config = this.globalsService.getApiConfig();
		var today = new Date();
		var priorDate = new Date(new Date().setDate(today.getDate() - 90));
		this.fromdate = priorDate;

		this.fromdateCtrl.setValue(this.fromdate);
		this.todateCtrl.setValue(this.todate);

		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;

			if (results) {
				let val = [
					this.user.user.defaultlocation.loccode
				];
				this.defaultlocation.setValue(val)
				this.loadData();
			}
		});
	}

	loadData() {
		if (this.customerdata) {
			let data = {
				debtorno: this.customerdata.debtorno,
				search: this.search.value,
				from: this.fromdateCtrl.value,
				to: this.todateCtrl.value,
				consolidated: this.consolidated.value,
				location: this.defaultlocation.value,
				brands: this.brands.value,
				categories: this.categoryid.value,
				pline: this.lineid.value
			}

			this.sending = true;

			if (this.searchsubscripiton) {
				this.searchsubscripiton.unsubscribe();
			}

			//forms being used twice force update back
			this.defaultlocation.setValue(this.defaultlocation.value);
			this.brands.setValue(this.brands.value);
			this.categoryid.setValue(this.categoryid.value);
			this.lineid.setValue(this.lineid.value);

			this.searchsubscripiton = this.customerService.searchCustomerHistory(data).subscribe((results: any) => {
				this.results = results;
				this.filtered_results = results;
				this.all_results = results;
				this.sending = false;
			});
		}
	}

	viewHistory(month: any, year: any) {

		let data = {
			debtorno: this.customerdata.debtorno,
			search: this.search.value,
			from: this.fromdateCtrl.value,
			to: this.todateCtrl.value,
			month: month,
			year: year,
			location: this.defaultlocation.value,
			brands: this.brands.value,
			categories: this.categoryid.value,
			pline: this.lineid.value
		}

		this.customerService.searchCustomerHistory(data).subscribe((results: any) => {
			this.monthdetails = results.items;
			this.monthtitle = results.title;
			this.modalService.open(this.monthDetailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		});
	}

	getCostTotalYear(year: any) {
		let value = year.cost.reduce(function(accumulator: number, items: any) {
			let addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);

		return value;
	}

	getSaleTotalYear(year: any) {
		let value = year.sales.reduce(function(accumulator: number, items: any) {
			let addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);

		return value;
	}

	getQtyTotalYear(year: any) {
		let value = year.qty.reduce(function(accumulator: number, items: any) {
			let addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);

		return value;
	}

	openDocument(transaction: any, content: any, display: string) {
		switch (transaction.type) {
			case '11':
				this.globalsService.getCreditNote(transaction.id, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
				})
				break;
			case '10':
				this.globalsService.getInvoice(transaction.id, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
				})

				break;
		}
	}

	creditInvoice(trans: any) {
		this.modalService.dismissAll();
		this.router.navigate(['/receivables/credit-invoice/' + trans.id]);
	}

	exportMPdf() {
		this.sending = true;
		let encoded: string = this.globalSearchService.base64encode(this.print_historyRef.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.sending = false;
		});
	}

	exportMXls() {
		this.sending = true;

		let encoded: string = this.globalSearchService.base64encode(this.print_historyRef.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
		});
	}

	exportPdf() {
		this.sending = true;
		let encoded: string = this.globalSearchService.base64encode(this.print_itemsRef.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.sending = false;
		});
	}

	exportXls() {
		this.sending = true;

		let encoded: string = this.globalSearchService.base64encode(this.print_itemsRef.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
		});
	}

	assignCopy(input) {
		if (input != '') {
			this.filtered_results = Object.assign([], input);
		} else {
			this.filtered_results = this.all_results
		}
	}

	filterWarehouse(input: any) {
		this.loadData();
	}

	filterItem(input, term, keys) {
		if (!term) {
			this.assignCopy(input);
		}
		var result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
		return result
	}

	selectCustomer(event: any) {
		this.customerdata = event;
		this.displayLookup = false;
		this.router.navigate(["/customers/monthlyHistory", event.debtorno]);
		this.loadData();
	}

	showCustomerLookup() {
		this.displayLookup == true ? this.displayLookup = false : this.displayLookup = true;
	}



}
