import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-productlineordertable',
  templateUrl: './productlineordertable.component.html',
  styleUrls: ['./productlineordertable.component.scss']
})
export class ProductlineordertableComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @Input() input: any = [];
  subdisplayedColumns: string[] = ['orderno', 'orddate', 'fromstkloc','salesperson', 'salesmanname', 'payterms','unitprice', 'quantity', 'discountpercent','total'];
  datasource: any;
  constructor() { }

  ngOnInit(): void {
    this.datasource = new MatTableDataSource(this.input);
  }

  ngAfterViewInit(): void{
    this.datasource.sort = this.sort;
  }

}