import { HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalSearchService } from '../services/globalsearchservice.service';

@Injectable()
export class AuthService {

	public userlogged: boolean;

	constructor(public globalSearchService: GlobalSearchService) {}

	public isAuthenticated(): boolean {

		let user = JSON.parse(localStorage.getItem('user'));
		if(user === null ) {
		  return false;
		}
		this.userlogged = true;
		this.globalSearchService.user.next(user);

		return true;
	}
}
