<div class="card main" *ngIf="menuapp">
	<div class="m-auto main-width">
		<div class="row d-flex justify-content-between dotted-divider" *ngIf="!customer">
			<h3> Customer Detail History Report</h3>&nbsp;<h4 class="padding-t-25">Select a customer</h4>
		</div>
		<ng-container *ngIf="!customer || displayLookup">
			<app-customer-lookup (customer_selected)="selectCustomer($event)" [lookupOnly]="true" [ngClass]="displayLookup && !customer ? 'lookup' : 'mt-10 dblock'"></app-customer-lookup>
		</ng-container>
		<div class="row m-0 p-0" *ngIf="customer">
			<div class="col-md-2 mt-3">
				<button mat-raised-button color="accent" class="btn-height margin-t-5" (click)="loadData()" *ngIf="!sending">
					<mat-icon>refresh</mat-icon>
				</button>
				&nbsp;
				<button mat-raised-button color="primary" class="btn-height margin-t-5" (click)="showCustomerLookup()">{{customer.debtorno}}<mat-icon>person</mat-icon></button>
				<span *ngIf="sending">
					<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
						<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
					</svg>
				</span>

			</div>
			<div class="col-md-4 mt-3">
				<div class="row">
				<mat-form-field appearance="outline">
					<mat-label>Transaction Dates</mat-label>
					<mat-date-range-input [formGroup]="range" [rangePicker]="picker">
						<input matStartDate formControlName="fromdateCtrl" placeholder="Start date">
						<input matEndDate formControlName="todateCtrl" placeholder="End date">
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>

					<mat-error *ngIf="range.controls.fromdateCtrl.hasError('matStartDateInvalid')">Invalid start date</mat-error>
					<mat-error *ngIf="range.controls.todateCtrl.hasError('matEndDateInvalid')">Invalid end date</mat-error>
				</mat-form-field>
				</div>
			</div>


			<div class="col-md-4 text-right d-flex ">
				<mat-form-field appearance="standard" class="w-70">
					<mat-label class="text-right">Email Address</mat-label>
					<input tabindex="0" matInput value="" [formControl]="email" class="text-right">
					<mat-hint>Email CDET</mat-hint>
				</mat-form-field>
				<button mat-raised-button color="accent" class="btn-height email-btn" (click)="emailCdet()" *ngIf="!sending">
					<mat-icon>send</mat-icon>
				</button>
			</div>
			<div class="col-md-2 mt-4 d-flex justify-content-end ">
				<a class="btn btn-danger btn-height margin-t-5" href="{{cdetLink}}{{customer.debtorno}}&datefrom={{isoValue(range.get('fromdateCtrl').value)}}&dateto={{isoValue(range.get('todateCtrl').value)}}"><i class="fa fa-file-pdf-o fa-centering"></i></a>
				&nbsp;
				<a class="btn btn-success btn-height margin-t-5" (click)="exportXls();"><i class="fa fa-file-excel-o fa-centering"></i></a>
			</div>
		</div>
		<div *ngIf="customer">
			<div class="row d-flex m-0">
				<h3><b>Customer Detail History Report</b>: <span class="custno">(<a href="#/customers/view/{{customer.debtorno}}">{{customer.debtorno}}</a>) {{customer.customer.name}}</span></h3>
			</div>
		</div>
		<ng-container *ngIf="results && customer">
			<hr>
			<div #printTable class="w-100 p-0 m-0">
				<div class="row m-0 p-0 text-center">
					<div class="col-6 col-md-3">
						<b>SUBTOTAL: ${{ results.gross }}</b>
					</div>
					<div class="col-6 col-md-3">
						<b>SHIPPING: ${{ results.freight }}</b>
					</div>
					<div class="col-6 col-md-3">
						<b>TAX: ${{ results.taxes }}</b>
					</div>
					<div class="col-6 col-md-3">
						<b>TOTAL: ${{ results.sum }}</b>
					</div>
				</div>
				<div class="table">
					<table class="table table-fixed table-hover">
						<thead>
							<ng-container *ngFor="let r of results.transaction">
								<tr>
									<th>DATE</th>
									<th>{{ r.typename }}</th>
									<th>SALESMAN</th>
									<th>SUBTOTAL</th>
									<!--<th style="border: 1px solid rgb(50, 50, 50);border-width: 0 0 0 1px;margin: 0;overflow:visible;"></th>-->
									<th>SHIPPING</th>
									<th>TAX</th>
									<th>TOTAL</th>
								</tr>
								<tr>
									<td>{{ r.trandate }}</td>
									<td>{{ r.transno }}</td>
									<td>
										{{ r.salesperson }}
									</td>
									<td>{{ r.ovamount | currency }}</td>
									<td>{{ r.ovfreight | currency }}</td>
									<td>{{ r.ovgst | currency }}</td>
									<td>
										{{ r.amount | currency }}
									</td>
								</tr>
								<tr>
									<th class="secondary">ITEM</th>
									<th class="secondary">DESC</th>
									<th class="secondary"></th>
									<th class="secondary"></th>

									<th class="secondary">QTY</th>
									<th class="secondary">PRICE</th>
									<th class="secondary">EXT</th>
								</tr>
								<tr *ngFor="let s of r.details">
									<td>{{ s.stockid }}</td>
									<td colspan="2">{{ s.description }}</td>
									<td></td>
									<td>{{ s.qty * -1}}</td>
									<td>{{ s.price | currency }}</td>
									<td>{{ -1 * (s.qty * (s.price - (s.price * s.discountpercent))) | currency }}</td>
								</tr>
							</ng-container>
						</thead>
					</table>
				</div>
			</div>
		</ng-container>
	</div>
</div>



<div class="row m-0 p-0" *ngIf="!menuapp">
	<div class="col-md-3 mt-3">

		<mat-form-field appearance="outline">
			<mat-label>Transaction Dates</mat-label>
			<mat-date-range-input [formGroup]="range" [rangePicker]="picker">
				<input matStartDate formControlName="fromdateCtrl" placeholder="Start date">
				<input matEndDate formControlName="todateCtrl" placeholder="End date">
			</mat-date-range-input>
			<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
			<mat-date-range-picker #picker></mat-date-range-picker>

			<mat-error *ngIf="range.controls.fromdateCtrl.hasError('matStartDateInvalid')">Invalid start date</mat-error>
			<mat-error *ngIf="range.controls.todateCtrl.hasError('matEndDateInvalid')">Invalid end date</mat-error>
		</mat-form-field>
	</div>


	<div class="col-md-3 mt-3">
		<a class="btn btn-danger"  href="{{cdetLink}}{{customer.customer.debtorno}}&datefrom={{isoValue(range.get('fromdateCtrl').value)}}&dateto={{isoValue(range.get('todateCtrl').value)}}"><i class="fa fa-file-pdf-o"></i></a>
		&nbsp;&nbsp;
		<a class="btn btn-success" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></a>
	</div>
	<div class="col-md-2 ml-auto mr-0 mt-3">
		<span *ngIf="sending">
			<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</span>
		<button mat-raised-button color="accent" class="ml-2 mt-2" (click)="loadData()" *ngIf="!sending">
			<mat-icon>refresh</mat-icon>
		</button>
	</div>
	<div class="col-md-3 text-right mt-3">
		<mat-form-field appearance="standard">
			<mat-label class="text-right">Email Address</mat-label>
			<input tabindex="0" matInput value="" [formControl]="email" class="text-right">
			<mat-hint>Email CDET</mat-hint>
		</mat-form-field>
	</div>
	<div class="col-md-1 mt-4">
		<button mat-raised-button color="accent" class="ml-2 mt-2" (click)="emailCdet()" *ngIf="!sending">
			<mat-icon>send</mat-icon>
		</button>
	</div>
</div>

<ng-container *ngIf="results && !menuapp">
	<hr>
	<div #printTable class="w-100 p-0 m-0">
		<div class="row m-0 p-0 text-center">
			<div class="col-6 col-md-3">
				<b>SUBTOTAL: ${{ results.gross }}</b>
			</div>
			<div class="col-6 col-md-3">
				<b>SHIPPING: ${{ results.freight }}</b>
			</div>
			<div class="col-6 col-md-3">
				<b>TAX: ${{ results.taxes }}</b>
			</div>
			<div class="col-6 col-md-3">
				<b>TOTAL: ${{ results.sum }}</b>
			</div>
		</div>
		<div class="table">
			<table class="table table-fixed table-hover">
				<thead>
					<ng-container *ngFor="let r of results.transaction">
						<tr>
							<th>DATE</th>
							<th>{{ r.typename }}</th>
							<th>SALESMAN</th>
							<th>SUBTOTAL</th>
							<!--<th style="border: 1px solid rgb(50, 50, 50);border-width: 0 0 0 1px;margin: 0;overflow:visible;"></th>-->
							<th>SHIPPING</th>
							<th>TAX</th>
							<th>TOTAL</th>
						</tr>
						<tr>
							<td>{{ r.trandate }}</td>
							<td>{{ r.transno }}</td>
							<td>
								{{ r.salesperson }}
							</td>
							<td>{{ r.ovamount | currency }}</td>
							<td>{{ r.ovfreight | currency }}</td>
							<td>{{ r.ovgst | currency }}</td>
							<td>
								{{ r.amount | currency }}
							</td>
						</tr>
						<tr>
							<th class="secondary">ITEM</th>
							<th class="secondary">DESC</th>
							<th class="secondary"></th>
							<th class="secondary"></th>

							<th class="secondary">QTY</th>
							<th class="secondary">PRICE</th>
							<th class="secondary">EXT</th>
						</tr>
						<tr *ngFor="let s of r.details">
							<td>{{ s.stockid }}</td>
							<td colspan="2">{{ s.description }}</td>
							<td></td>
							<td>{{ s.qty * -1}}</td>
							<td>{{ s.price | currency }}</td>
							<td>{{ -1 * (s.qty * (s.price - (s.price * s.discountpercent))) | currency }}</td>
						</tr>
					</ng-container>
				</thead>
			</table>
		</div>
	</div>
</ng-container>
