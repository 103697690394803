import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit} from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';

import { interval, Subscription} from 'rxjs';
import { Subject, Observable } from 'rxjs';
import { debounceTime, map, startWith, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router} from '@angular/router';

import { InventoryService } from '../../../services/inventory.service';
import { GlobalsService } from '../../../services/globals.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { Customer } from '../../../classes/customer';
import { Location } from '@angular/common'


@Component({
  selector: 'app-bin-edit',
  templateUrl: './bin-edit.component.html',
  styleUrls: ['./bin-edit.component.scss']
})
export class BinEditComponent implements OnInit {
	@Input() bins;
	@Output() bin_update;

	transferBin = new UntypedFormControl('');
	binSearch = new UntypedFormControl('');
	itemSearch = new UntypedFormControl('');

	allbins: any = [];
	selectedbin: any = false;
	sending: any = false;
	loadingbin: any = false;
	filteredbins: any = [];
	affected: any = false;
	tobin: any = [];

	selected_items: any = [];
	available_items: any = [];
	filtered_items: any = [];
	filteredOptions: any = [];

	neworcurrent: string = '';

	onhandonly: boolean = true;

	constructor(private inventoryService: InventoryService, private globalsService : GlobalsService) {
	}

	ngOnInit(): void {
		this.allbins = this.bins
		this.filteredbins = this.bins
		this.filteredOptions = this.bins;

		this.transferBin.valueChanges.subscribe(newValue=>{
			this.filteredOptions = this.filterValues(newValue);
			this.newOrCurrentBin();
		})
	}

	addSelectedItem(item: any) {
		let index =this.filtered_items.indexOf(item)
		this.filtered_items.splice(index, 1);
		this.tobin.push(item)

		this.tobin.sort((a, b) => (a.stockid > b.stockid) ? 1 : (a.stockid === b.stockid) ? ((a.description > b.description) ? 1 : -1) : -1 )

	}

	removeSelectedItem(item: any) {
		let index = this.tobin.indexOf(item)
		this.tobin.splice(index, 1);
		this.filtered_items.push(item)
		this.filtered_items.sort((a, b) => (a.stockid > b.stockid) ? 1 : (a.stockid === b.stockid) ? ((a.description > b.description) ? 1 : -1) : -1 )
	}

	selectBin(bin: any) {
		this.loadingbin = true;
		this.selectedbin = bin
		this.inventoryService.getBinItems(bin.location).subscribe((results: any) => {

			this.available_items = results
			this.filtered_items = results;

			if(this.onhandonly) {
				this.filtered_items = results.filter((item:any) => {
					return parseFloat(item.qoh) > 0
				});
			}

			this.loadingbin = false;
		})
	}

	updateItems(qohonly: any) {
		this.filtered_items = this.available_items;
		if(qohonly.checked) {
			this.filtered_items = this.available_items.filter((item:any) => {
				return parseFloat(item.qoh) > 0
			});
		}
	}

	newOrCurrentBin() {
		let exists = this.allbins.filter(bin =>{
			return bin.location == this.transferBin.value
		})

		if(this.transferBin.value =='') {
			this.neworcurrent = '';
		} else {
			this.neworcurrent = (exists.length) ? 'Merge to ' +this.transferBin.value : 'Creating New Bin: '+this.transferBin.value
		}
	}

	moveItems() {
		this.sending = true;
		this.inventoryService.moveItemsToBin(this.tobin, this.transferBin.value).subscribe((results: any) => {

			this.selectedbin = false;
			this.sending = false;

			this.allbins = results.bins
			this.affected = results.affected;

			this.filteredbins = results.bins
			this.filteredOptions = results.bins;
			this.selected_items = [];
			this.available_items = [];
			this.filtered_items = [];

			this.tobin = [];
			this.filtered_items = [];
			this.transferBin.setValue('');
			this.binSearch.setValue('');
			this.itemSearch.setValue('');
		});
	}

	searchItems(event: any) {
		let keys = 'stockid,description'
		this.filtered_items = this.filterItem(this.available_items,event.target.value,keys)
	}

	searchBin(event: any) {
		let keys = 'location'
		this.filteredbins = this.filterItem(this.allbins,event.target.value,keys)
	}

	assignCopy(input: any){
		this.allbins = Object.assign([], input);
	}

	filterItem(input: any, term: any , keys: any ){
		if(!term){
			this.assignCopy(input);
		}
		var result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) &&	new RegExp(term, 'gi').test(item[key])));
		return result
	}

	filterValues(search: string) {
		return this.allbins.filter( (value) => {
			return value.location.toLowerCase().indexOf(search.toLowerCase()) === 0
		});
	}
}
