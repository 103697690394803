<ng-template #modalView let-modal>
	<div class="modal-header">
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center">
						{{ editaddlable }}
					</h4>
				</div>
				<div class="card-body text-center">
					<div class="row">
						<div class="col-12">
							<form [formGroup]="editForm">
								<div class="row">
									<div class="col-md-4">
										<mat-form-field appearance="standard">
											<mat-label>Description</mat-label>
											<input type="text" class="text-left " matInput formControlName="description" />
										</mat-form-field>
									</div>
									<div class="col-md-4">
										<app-gl-account-lookup [preselected]="selectedtaxgl" (glselected)="selectGl($event)"></app-gl-account-lookup>
									</div>
									<div class="col-md-4">
										<mat-form-field appearance="standard">
											<mat-label>Bank Account</mat-label>
											<mat-select formControlName="bankacc">
												<mat-option *ngFor="let v of bankaccounts" [value]="v.accountcode">
													{{ v.currcode }} {{v.accountcode}} {{v.bankaccountname}}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-3">
										<mat-form-field appearance="standard" class="text-right">
											<mat-label>Always Taxable</mat-label>
											<input type="text" class="text-right " matInput formControlName="always_taxable" />
											<span matSuffix>%</span>
										</mat-form-field>
									</div>
									<div class="col-md-3">
										<mat-form-field appearance="standard" class="text-right">
											<mat-label>Exempt With Cert</mat-label>
											<input type="text" class="text-right " matInput formControlName="tax_exempt_cert" />
											<span matSuffix>%</span>
										</mat-form-field>
									</div>
									<div class="col-md-3">
										<mat-form-field appearance="standard" class="text-right">
											<mat-label>Freight%</mat-label>
											<input type="text" class="text-right " matInput formControlName="freight" />
											<span matSuffix>%</span>
										</mat-form-field>
									</div>
									<div class="col-md-3">
										<mat-form-field appearance="standard" class="text-right">
											<mat-label>Always Exempt%</mat-label>
											<input type="text" class="text-right " matInput formControlName="always_exempt" />
											<span matSuffix>%</span>
										</mat-form-field>
									</div>

									<div class="col-md-12">
										<button mat-stroked-button color="success" aria-label="" (click)="saveTax()" class="mt-2">
											<mat-icon>save</mat-icon> Save
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="row">
	<div class="col-md-12">
		<button mat-icon-button (click)="addTax()">
			<mat-icon>add</mat-icon>
		</button>
	</div>
</div>
<div class="table fixTableHead" #print_table>
	<table class="table table-fixed table-hover">
		<thead>
			<tr>
				<th>Name</th>
				<th>Sales GL Code</th>
				<th>Purchase GL Code</th>
				<th>Bank Account</th>
				<th>Always Taxable</th>
				<th>Exempt W/Cert</th>
				<th>Exempt</th>
				<th>Freight</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let auth of taxauthorities">
				<td>{{ auth.description }}</td>
				<td>{{ auth.taxglcode }}</td>
				<td>{{ auth.purchtaxglaccount }}</td>
				<td>{{ auth.bankacc }}</td>
				<td>{{ auth.always_taxable| percent }}</td>
				<td>{{ auth.tax_exempt_cert | percent}}</td>
				<td>{{ auth.always_exempt| percent }}</td>
				<td>{{ auth.freight | percent }}</td>
				<td>
					<button mat-icon-button (click)="editTax(auth)">
						<mat-icon>edit</mat-icon>
					</button>
					<button mat-icon-button (click)="removeTax(auth)">
						<mat-icon>remove</mat-icon>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</div>
