import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit} from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { interval, Subscription} from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router} from '@angular/router';

import { VendorService } from '../../../services/vendor.service';
import { GlobalsService } from '../../../services/globals.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { Customer } from '../../../classes/customer';
import { Location } from '@angular/common'

@Component({
  selector: 'app-vendor-contacts',
  templateUrl: './vendor-contacts.component.html',
  styleUrls: ['./vendor-contacts.component.scss'],
  animations: [
  trigger("grow", [ // Note the trigger name
	  transition(":enter", [
		  // :enter is alias to 'void => *'
		  style({ height: "0",width: "0"}),
		  animate(200, style({ height: "*" , width: "*"})),
		  animate('200ms', style({ opacity: 1 })),
	  ]),
	  transition(":leave", [
		  // :leave is alias to '* => void'
		  animate(300, style({ height: 0, width: 0 })),
		  animate('300ms', style({ opacity: 0 }))
	  ])
	  ]),
  ]
})
export class VendorContactsComponent implements OnInit {

	@Input() vendor;
	@Output() editing = new EventEmitter<boolean>();
	@Output() newvendordata = new EventEmitter<boolean>();

	contacts: any = []
	newcontact_id: number = 0;
	default_type ='';
	//notetypes = ['ORDER','AR'];
	removals: any = [];

	constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder,private vendorService: VendorService, public router: Router, private location: Location, private globalsService: GlobalsService) { }

	ngOnInit(): void {
	}

	updateContactName(event:any, note: any) {
		let index = this.vendor.contacts.indexOf(note);
		this.vendor.contacts[index].contactname = event.target.value;
	}

	updateContactEmail(event:any, note: any) {
		let index = this.vendor.contacts.indexOf(note);
		this.vendor.contacts[index].email = event.target.value;
	}

	updateContactPhone(event:any, note: any) {
		let index = this.vendor.contacts.indexOf(note);
		this.vendor.contacts[index].phoneno = event.target.value;
	}

	updateContactRole(event:any, note: any) {
		let index = this.vendor.contacts.indexOf(note);
		this.vendor.contacts[index].role = event.target.value;
	}

	addContact() {
		this.newcontact_id = this.newcontact_id - 1;
		let newContact ={
			supplierid: this.vendor.supplierid,
			contid: this.newcontact_id,
			contactname: '',
			email: '',
			phoneno: '',
			role: '',
		}

		this.vendor.contacts.push(newContact)
	}

	removeContact(id: any) {
		this.removals.push(id)
		this.vendor.contacts = this.vendor.contacts.filter( (contact) => {
			return contact.contid != id;
		});
	}

	onSubmit() {
		this.vendorService.updateVendorContacts({ contacts: this.vendor.contacts, removals: this.removals, supplierid: this.vendor.supplierid}).subscribe( (results:any) => {
			this.vendor.contacts = results;
			this.newvendordata.emit(this.vendor);
			this.editing.emit(false);
		})
	}

	back(): void {
		//this.editing.emit(false);
	}

	ngAfterViewInit() {
	}


}
