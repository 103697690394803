<div class="main-content">
	<div class="container-fluid">
		<div class="row tp-wrapper m-0 p-0">
			<div class="col-md-12 ">
				<div class="card ">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title" (click)="back()">
							<i class="material-icons">arrow_left</i>
							Receiving Journal Report
						</h4>
					</div>
					<div class="card-body">

						<div class="row">
							<div class="col-4">
								<mat-form-field appearance="standard">
									<mat-label>Journal Date</mat-label>
									<input matInput (dateChange)="updateDayPayMents($event.value)" [formControl]="repdate" [matDatepicker]="jdate">
									<mat-datepicker-toggle matSuffix [for]="jdate"></mat-datepicker-toggle>
									<mat-datepicker #jdate></mat-datepicker>
								</mat-form-field>
							</div>
							<div class="col-4">
								<mat-form-field appearance="standard">
									<mat-label>Closed Journal#</mat-label>
									<mat-select [formControl]="journal_review" (selectionChange)="getSelectedJournal($event)">
										<mat-option [value]="''">--- Select a Journal ---</mat-option>
										<mat-option *ngFor="let cur of closedjournals" [value]="cur">
											{{ cur.userid }}:# {{ cur.journal_no }} ({{ cur.journal_date }})
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-md-4">

								<button (click)="pdfJournal()" class="btn btn-danger"><i class="fa fa-file-pdf-o"></i></button>
								&nbsp;&nbsp;
								<button (click)="xlsJournal()" class="btn btn-success"><i class="fa fa-file-excel-o"></i></button>

							</div>
						</div>

						<span *ngIf="loadingjournal"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg></span>

						<div class="row-md-12" #journal_details id="journal_details" *ngIf="journal_payments && !loadingjournal">
							<div class="row m-0 p-0" *ngIf="journal_payments.journal_no ">
								<div class="col-md-12 text-left m-0 p-0">
									<h5 class="mr-0">JOURNAL #{{ journal_payments.journal_no }}&nbsp;&nbsp;&nbsp;DATE {{ journal_payments.journal_date }}</h5>
									<h5 class="ml-0">POSTED {{ journal_payments.total_posted | currency }}</h5>
								</div>
								<div class="col-12">
									<table class="table">
										<ng-container *ngFor="let j of journal_payments.details;">
											<tr>
												<th>Payment#</th>
												<th>Customer#</th>
												<th>Ref#</th>
												<th>Name</th>
												<th>Type</th>
												<th>Total</th>
												<th>Paid</th>
												<!-- <th></th>
												<th>Balance</th> -->
											</tr>
											<tr>
												<td>#{{ j.transno }}</td>
												<td><a [routerLink]="''">{{ j.debtorno }}</a></td>
												<td>{{ j.ref }}</td>
												<td>{{ j.brname }}</td>
												<td>{{ j.banktranstype }}</td>
												<td>{{ j.amount | currency }}</td>
												<td>{{ j.alloc | currency }}</td>
												<!-- <td></td>
												<td>{{ j.balance | currency }}</td> -->
											</tr>
											<ng-container *ngIf="j.allocations">
												<!-- <tr *ngIf="i == 0">
														<th>Allocations</th>
														<th>Date</th>
														<th>Document#</th>
														<th>Total</th>
														<th>Allocation</th>
													</tr> -->
												<ng-container *ngFor="let d of j.allocations; let i = index">
													<tr *ngIf="i == 0">
														<th>Invoice</th>
														<th></th>
														<th></th>
														<th></th>
														<th>Total</th>
														<th>Payment</th>
														<!-- <th>Due</th>
														<th>Balance</th> -->
													</tr>
													<tr>
														<td>#{{ d.transno }}</td>
														<td></td>
														<td></td>
														<td></td>
														<td>{{ d.total }}</td>
														<td>{{ d.amt | currency }}</td>
														<!-- <td>{{ d.balance | currency }}</td>
														<td>{{ d.rolling | currency }}</td> -->
													</tr>
												</ng-container>
											</ng-container>
										</ng-container>
										<ng-container *ngFor="let j of journal_payments.credits;">
											<tr>
												<th>Credit#</th>
												<th>Customer#</th>
												<th>Name</th>
												<th></th>
												<th>Total</th>
												<th>Credited</th>
												<!-- <th></th>
												<th>Balance</th> -->
											</tr>
											<tr>
												<td>#{{ j.transno }}</td>
												<td><a [routerLink]="''">{{ j.debtorno }}</a></td>
												<td>{{ j.brname }}</td>
												<td></td>
												<td>{{ j.total | currency }}</td>
												<td>{{ j.alloc | currency }}</td>
												<!-- <td></td>
												<td>{{ j.balance | currency }}</td> -->
											</tr>
											<ng-container *ngIf="j.allocations">
												<!-- <tr *ngIf="i == 0">
														<th>Allocations</th>
														<th>Date</th>
														<th>Document#</th>
														<th>Total</th>
														<th>Allocation</th>
													</tr> -->
												<ng-container *ngFor="let d of j.allocations; let i = index">
													<tr *ngIf="i == 0">
														<th>Applied to Invoice</th>
														<th></th>
														<th></th>
														<th></th>
														<th></th>
														<th>Applied</th>
														<!-- <th>Due</th>
														<th>Balance</th> -->
													</tr>
													<tr>
														<td>#{{ d.transno }}</td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td>{{ d.amt | currency }}</td>
														<!-- <td>{{ d.balance | currency }}</td>
														<td>{{ d.rolling | currency }}</td> -->
													</tr>
												</ng-container>
											</ng-container>
										</ng-container>
									</table>
								</div>
							</div>
							<div class="row m-0 p-0" *ngIf="!journal_payments.details">
								<h4 *ngIf="journal_review != 'NO JOURNAL'">No Payments Posted</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
