<ng-template #nonstock let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Non Stock Item</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<form [formGroup]="nonStockForm" *ngIf="nonStockForm">
			<div class="row">
				<div class="col-md-12" *ngIf="config.env.package=='tires'">
					<mat-form-field appearance="outline">
						<mat-label>Library Search</mat-label>
						<input matInput [formControl]="librarysearch">
						<!--
								[matAutocomplete]="auto"
								<mat-autocomplete #auto="matAutocomplete">
							  <mat-option *ngFor="let option of librarySearchResults" [value]="option">
								<div class="row">
									<h4>{{ option.make }} {{ option.model }}</h4>
									<div class="col-2">
										<img src="{{option.imageUrl}}" class="img img-responsive img-fluid" lazy style="max-width:50px;max-height:50px;">
									</div>
									<div class="col-4">
										{{option.itemNumber}}
									</div>
									<div class="col-6">
										{{ option.displayName }}
									</div>
								</div>
							  </mat-option>
							</mat-autocomplete>
						-->
					</mat-form-field>

					<mat-progress-bar mode="indeterminate" color="accent" *ngIf="librarySearching"></mat-progress-bar>

				</div>

				<ng-container *ngIf="librarySearchResults && config.env.package=='tires'">
					<div class="container">
						<ul class="list-group">
							<li class="list-group-item cpdata" *ngFor="let option of librarySearchResults">
								<div class="row" (click)="selectLibraryItem(option)">
									<div class="col-2" *ngIf="option.model">
										<img src="{{option.model.make.imageUrl}}" class="img img-responsive img-fluid nonstocklogo" lazy >
									</div>
									<div class="col-2" *ngIf="option.model">
										<img src="{{option.model.imageUrl}}" class="img img-responsive img-fluid nonstockimage" lazy *ngIf="option.model.imageUrl" >
									</div>
									<div class="col-8">
										{{option.model.make.name}} {{option.model.name}} {{ option.displayName }}
									</div>
									<ng-container *ngIf="loadingItem">
										<svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
											<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
										</svg>
									</ng-container>
								</div>
							</li>
						</ul>
					</div>
				</ng-container>
				<div class="col-md-4">
					<h4 *ngIf="selectedsupplier">
						{{ selectedsupplier.supplierid }} - {{ selectedsupplier.suppname }} <mat-icon class="mt-2" (click)="selectedsupplier = false;">cancel</mat-icon>
					</h4>
					<ng-container *ngIf="!selectedsupplier">
						<app-vendor-lookup (vendor_selected)="selectNonStockVendor($event)" class="w-100"></app-vendor-lookup>
					</ng-container>
				</div>
				<div class="col-md-4">
					<h4 *ngIf="nonStockForm.get('brand').value != ''"> {{ nonStockForm.get('brand').value.mfg_code}}
						<mat-icon class="mt-2" (click)="nonStockForm.get('brand').setValue('')">cancel</mat-icon>
					</h4>
					<mat-form-field appearance="standard" *ngIf="nonStockForm.get('brand').value == '' ">
						<mat-label>Brand</mat-label>
						<mat-select formControlName="brand">
							<mat-option *ngFor="let v of brands" [value]="v">
								{{v.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-4 ">
					<mat-form-field appearance="standard">
						<mat-label>Barcode</mat-label>
						<input matInput  formControlName="barcode">
					</mat-form-field>
				</div>
				<div class="col-md-4">
					<mat-form-field appearance="standard">
						<mat-label>Description</mat-label>
						<input matInput required formControlName="description">
					</mat-form-field>
				</div>
				<div class="col-md-4 ">
					<mat-form-field appearance="standard">
						<mat-label>Part#</mat-label>
						<input matInput  formControlName="partno">
					</mat-form-field>
				</div>
				<div class="col-md-4 ">
					<mat-form-field appearance="standard" class="text-right">
						<mat-label>Cost</mat-label>
						<span matPrefix>$</span>
						<input matInput required formControlName="cost" >
					</mat-form-field>
				</div>
				<div class="ml-auto col-md-4">
					<mat-form-field appearance="standard">
						<mat-label>Qty</mat-label>
						<span matPrefix>#</span>
						<input matInput required formControlName="qty" class="text-center">
					</mat-form-field>
				</div>
				<div class="col-md-4">
					<mat-form-field appearance="standard" class="text-right">
						<mat-label>Price</mat-label>
						<span matPrefix>$</span>
						<input matInput required formControlName="price" >
						<mat-hint *ngIf="estprice" class="text-danger">Suggested Markup: {{ suggestedMarkup | currency }}</mat-hint>
					</mat-form-field>
				</div>

				<div class="col-md-4" *ngIf="config.env.package == 'tires'">
					<mat-form-field appearance="standard" class="text-right" >
						<mat-label>FET</mat-label>
						<span matPrefix>$</span>
						<input matInput formControlName="fet" >
					</mat-form-field>
				</div>


				<div class="col-md-12 text-right">
					<button mat-button-stroked aria-label="Add To Order" color="accent" (click)="addNonStock()" [ngClass]="!nonStockForm.valid ? 'btn btn-danger': 'btn btn-success'">
						<small>
							<mat-icon>add</mat-icon>
						</small> Add To Order
					</button>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<button mat-stroked-button class="mt-2" color="white" (click)="nonStockModal()" *ngIf="config.features.nonstock">Add Non Stock</button>
