import { Component, OnDestroy, OnChanges, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit} from '@angular/core';
import { UntypedFormBuilder, Validators, FormGroup, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { interval, Subscription} from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router} from '@angular/router';

import { CustomerService } from '../../services/customer.service';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';

import { Location } from '@angular/common';


@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss'],
  animations: [
		  trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('250ms ease-in')),
			transition('active => inactive', animate('250ms ease-out')),
		  ]),
		 trigger('openClose', [
			  state('true', style({ height: '*' })),
			  state('false', style({ height: '0px' })),
			  transition('false <=> true', animate(500))
		]),
		trigger('filterAnimation', [
		  transition(':enter, * => 0, * => -1', []),
		  transition(':increment', [
			query(':enter', [
			  style({ opacity: 0, width: 0 }),
			  stagger(50, [
				animate('300ms ease-out', style({ opacity: 1, width: '*' })),
			  ]),
			], { optional: true })
		  ]),
		  transition(':decrement', [
			query(':leave', [
			  stagger(50, [
				animate('300ms ease-out', style({ opacity: 0, width: 0 })),
			  ]),
			])
		  ]),
		]),
		trigger("grow", [ // Note the trigger name
		  transition(":enter", [
			// :enter is alias to 'void => *'
			style({ height: "0",width: "0" }),
			animate(200, style({ height: "*" , width: "*"})),
			animate('200ms', style({ opacity: 1 })),
		  ]),
		  transition(":leave", [
			// :leave is alias to '* => void'
			animate(300, style({ height: 0, width: 0  })),
			animate('300ms', style({ opacity: 0 }))
		  ])
		]),
	]
  })

export class CustomerEditComponent implements OnInit {
	@Input() customerdata;
	@Output() editing = new EventEmitter<any>();
	@Output() newcustomerdata = new EventEmitter<any>();
	@Output() updatebranch = new EventEmitter<any>();

	customerForm: any = new UntypedFormGroup({});
	customertypes: any = [];
	//customerdata: any = [];
	holdreasons: any = [];
	zones: any = [];
	terms: any = [];
	currencies: any = [];
	salestypes: any = [];
	//allowed edit fields - need to add to branch as well
	formEditAllowed = [
		'debtorno',
		'branchcode',
		'name',
		'address1',
		'address2',
		'address3',
		'address4',
		'address5',
		'address6',
		'cosm_license',
		'licExpDate',
		'invoiceemailaddress',
		'phoneno',
		'tax_ref',
		'taxgroupid',
		'signed_mor',
		//'contactname'
	]
	user: any = [];
	taxes: any = [];
	alltaxes: any = [];

	constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder,private customerService: CustomerService, public router: Router, private location: Location, private globalsService: GlobalsService, private globalSearchService: GlobalSearchService) {
		this.globalSearchService.user.subscribe(u => {
			this.user = u
		})
	}

	removeCustomer() {
		if(this.customerdata) {

			if(this.user.user.customeradmin == '1') {
				this.customerService.removeDebtor(this.customerdata).subscribe(async (results: any) => {
					if(results.success) {
						this.router.navigate(['./customers']);
					} else {
						alert('Can Not Remove Customer: \n'+results.reasons);
					}
				});
			}
		}
	}

	ngOnInit(): void {
		this.globalsService.getTaxes().subscribe(details => {
			this.taxes = details;
			this.alltaxes = details;
		});
	}

	setupForm() {
		this.globalSearchService.zones.subscribe(async (results: any) => {
			this.zones = results;
		});

		var details = this.customerdata.customer;

		var controls = [];
			const formGroup: UntypedFormGroup = new UntypedFormGroup({});
			Object.keys(details).forEach(key => {
				if(this.formEditAllowed.includes(key)) {
					var options = [];

					if(key == 'tax_ref') {
						options = [Validators.minLength(9), Validators.maxLength(20)];
					}

					let value = (details[key]) ? details[key] : '';

					if(key == 'signed_mor') {
						if(value == 'Y') {
							value = true;
						} else {
							value = false;
						}
					}


					controls.push({ name: key, control: new UntypedFormControl(value, options) })
				}
			});

			controls.forEach(f => {
				 formGroup.addControl(f.name, f.control, {})
			});

		this.customerForm = formGroup;

		this.customerForm.get('tax_ref').valueChanges.subscribe(newvalue => {
			if (newvalue != '') {
				var currentValue = this.alltaxes.filter(v => {
					return v.taxgroupid == this.customerForm.get('taxgroupid').value
				})[0];

				if (currentValue) {
					currentValue = currentValue.taxgroupdescription
				} else {
					currentValue = '';
				}

				this.filterTaxAuths(true, currentValue);
			} else {
				this.filterTaxAuths(false, '');
			}
		});
	}

	filterTaxAuths(filter: any, current: any) {

		if (filter) {

			const results = this.taxes.filter((r: any) => {
				var newvalue = '';

				if (current != '') {
					newvalue = current.replace('1', '2');
				}

				if (r.taxgroupdescription.startsWith('2') ) {
					if (newvalue != '' && newvalue == r.taxgroupdescription) {
						this.customerForm.get('taxgroupid').setValue(r.taxgroupid);
						//this.globalSearchService.showNotification('Tax Group Updated to Non Taxable', 'warn', 'bottom', 'right');
					}
					return r;
				}
			})
			this.taxes = results;
		} else {
			this.taxes = this.alltaxes;
		}
	}


	onSave($event){

	}

	onSubmit() {

		this.customerService.updateDebtor(this.customerForm.value).subscribe( (results) => {
			this.newcustomerdata.emit(results);
			this.updatebranch.emit(results);
			this.editing.emit(false);
		})
	}

	back(): void {
		this.editing.emit(false);
	}

	ngOnChanges(event:any) {

		if(event.customerdata) {
			this.setupForm()
		}
	}

	ngAfterViewInit() {


		/*
		this.globalsService.getCustomerTypes().subscribe(details => {
			this.customertypes = details;
		});

		this.globalsService.getHoldReasons().subscribe(details => {
			this.holdreasons = details;
		});

		this.globalsService.getTerms().subscribe(details => {
			this.terms = details;
		});

		this.globalsService.getCurrencies().subscribe(details => {
			this.currencies = details;
		});

		this.globalsService.getSalesTypes().subscribe(details => {
			this.salestypes = details;
		});
		*/

	}
}
