import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, EventEmitter, Pipe, PipeTransform, Renderer2,TemplateRef ,HostListener, Directive} from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { trigger, state, style, transition, animate, animation } from '@angular/animations';
import { CustomerService } from '../../../services/customer.service';
import { OrdersService } from '../../../services/orders.service';
import { InventoryService } from '../../../services/inventory.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { UsersService } from '../../../services/users.service';
import { OmsService } from '../../../services/oms.service';

export enum KEY_CODE {
  PLUS = 107,
  MINUS = 109,
  TAB = 9,
  PLUSTWO = 187,
}


@Component({
	selector: 'app-pos-item-search',
	templateUrl: './pos-item-search.component.html',
	styleUrls: ['./pos-item-search.component.scss'],
	animations: [
			trigger('flipState', [
				state('active', style({
					transform: 'rotateY(-179deg)'
				})),
				state('inactive', style({
					transform: 'rotateY(0)'
				})),
				transition('inactive => active', animate('500ms ease-in')),
				transition('active => inactive', animate('500ms ease-out')),
			]),
			trigger('cinfo', [
				transition(':enter', [
					animate('50ms', style({ opacity: 1, height: 100 })),
				]),
				transition(':leave', [
					animate('100ms', style({ opacity: 1, height: 0 }))
				]),
			]),
			trigger('itemSearch', [
				transition(':enter', animation([style({ transform: 'translate(-800px,0)', }),
					animate('0.2s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
						style({ transform: 'translate(0)', })
					),
				])),
				transition(':leave', animation([style({ transform: 'translate(0)' }),
					animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
						style({ transform: 'translate(1200px,0)', })
					),
				])),
			]),
			trigger('addFilters', [
				transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
					animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
						style({ transform: 'translate(0)', })
					),
				])),
				transition(':leave', animation([style({ transform: 'translate(0)' }),
					animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
						style({ transform: 'translate(-200px,0)', })
					),
				])),
			]),
		],
})

export class PosItemSearchComponent implements OnInit {

	@Input() customer: any = false;
	@Input() type: any = '10';
	@Output() addItem = new EventEmitter < any > ();
	@Output() updateCart = new EventEmitter < any > ();
	@Output() hasresults = new EventEmitter < any > ();
	@ViewChild("searchkeywords") searchkeywordsRef: ElementRef;
	@Output() addMix = new EventEmitter < any > ();

	config: any = [];
	itemSearchForm: UntypedFormGroup;
	search_customer_items = new UntypedFormControl(false)
	search_vendor_items = new UntypedFormControl(false)
	filters: any = [];
	screenHeight: number;
	screenWidth: number;

	limits = [
		{ name: '25', value: '25' },
		{ name: '50', value: '50' },
		{ name: '100', value: '100' },
		{ name: '200', value: '200' },
		{ name: '300', value: '300' },
		{ name: '500', value: '500' },
		{ name: 'No Limit', value: false },
	];

	sorts = [
		{ name: 'All Availability, Sell Low To High', value: 'avail.sell|ASC' },
		{ name: 'All Availability, Sell High To Low', value: 'avail.sell|DESC' },
		{ name: 'Local Availability, Sell Low To High', value: 'local.sell|ASC' },
		{ name: 'Local Availability, Sell High To Low', value: 'local.sell|DESC' },
		{ name: 'Sell Low To High', value: 'price|ASC' },
		{ name: 'Sell High To Low', value: 'price|DESC' },
		{ name: 'Name ASC', value: 'description|ASC' },
		{ name: 'Name DESC', value: 'description|DESC' },
	];

	showGuide: boolean = false;
	showfilters: boolean = false;
	searching: boolean = false;
	addingitems: boolean = false;
	inventorysearching: any = false;
	filtered_items: any = [];
	purchase_items: any = [];
	item_details: any = [];
	previous_search: string = '';
	salesman: any = false;
	//modal
	selecteditem: any = false
	itemhistory: any = false;
	editing_order: any = false;
	user: any = [];
	expanded_rows: any = [];
	promo: any =false;

	@ViewChild("purchasedetails") purchasedetails: ElementRef;
	@ViewChild("itemHistoryRef") itemHistoryRef: ElementRef;
	@ViewChild("configproductele") configproductele: ElementRef;
	@ViewChild("nonstock") nonstockref: ElementRef;
	@ViewChild("itemdetails") itemDetails: ElementRef;
	@ViewChild('itemPosResults') itemPosResultsRef = {} as TemplateRef < any > ;
	@ViewChild("mmmodal") mmodalref: ElementRef;

	constructor(private bottomSheet: MatBottomSheet,private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, public omsService: OmsService, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal, public usersService: UsersService, private renderer: Renderer2) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})
		this.user = this.globalSearchService.user;
		this.getScreenSize();
	}

	// @HostListener('window:resize', ['$event'])

	ngOnInit(): void {

		if (this.type == '11') {
			this.search_customer_items.setValue(false);
		}

		if (this.type == '21') {
			this.search_vendor_items.setValue(false);
		}

		if (this.user.value.user.salesman != '') {
			this.salesman = this.user.value.user.salesman;
		}

		switch (this.config.env.package) {
			case 'beauty':
				this.itemSearchForm = this.fb.group({
					keywords: ['', Validators.required],
					limit: [this.limits[2].value, Validators.required],
					sort: [this.sorts[6].value, Validators.required],
				});
			break;
			default:
				this.itemSearchForm = this.fb.group({
					keywords: ['', Validators.required],
					limit: [this.limits[2].value, Validators.required],
					sort: [this.sorts[2].value, Validators.required],
				});
			break;
		}

		//this.itemSearchForm.get('keywords').setValue('5nn');
		 setTimeout(() => {
			if(this.itemSearchForm.get('keywords')) {
				document.getElementById("searchkeywords").focus();
			}
		 },500);


	}

	getScreenSize(event ? ) {
		this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;
	}

	showPurchases(stockidid: any) {

	}
	runFunction(newvalue: any) {
		this.itemSearchForm.get('keywords').setValue(newvalue);
		this.itemSearch();
	}

	addNonStockItem(event: any) {
		//not in use moved to parent
		let item = event;
		let data = { item: item, debtorno: this.customer.debtorno, branchcode: this.customer.branchcode , location: this.customer.defaultlocation}
//
// 		this.orderService.addNonStock(data).subscribe((results: any) => {
// 			this.updateCart.emit(results);
// 			this.globalSearchService.showNotification(item.description + ' x ' + item.qty + ' Added', 'success', 'bottom', 'right');
// 		});
	}


	itemSearch() {
		let keywords = this.itemSearchForm.get('keywords').value;
		if (keywords != '') {

			this.globalSearchService.hideSideBar();

			this.searching = true;
			this.addingitems = true;

			this.filtered_items = [];

			if (this.inventorysearching) {
				this.inventorysearching.unsubscribe()
			}

			let data = {
				keywords: keywords,
				limit: this.itemSearchForm.get('limit').value,
				sort: this.itemSearchForm.get('sort').value,
				customer: this.customer,
				filters: this.filters,
				customer_items_only: this.search_customer_items.value,
				vendor_items_only: this.search_vendor_items.value,
				type: this.type,
				user: this.user._value.user
			};

			this.hasresults.emit(false);
			this.inventorysearching = this.orderService.getItemSearch(data).subscribe((items: any) => {
				this.filtered_items = items
				this.searching = false;
				if (items.length) {

					if(items.length == 1) {
						if(!items[0].ispromo) {
							this.addToOrder(items[0]);
							this.resetSearch();
						} else {
							this.triggerMm(items[0])
						}
					}

					if(items.length > 1) {
						let config = {
							hasBackdrop: true,
							margin: 0,
							padding: 0,
							//panelClass: 'custom-width',
						}

						this.bottomSheet.open(this.itemPosResultsRef, config);
					}

					if (!this.showfilters) {
						// this.itemSearchForm.get('keywords').setValue('');
					}
				} else {
					this.globalSearchService.showNotification('No Results for '+this.itemSearchForm.get('keywords').value , 'danger' , 'bottom', 'right')
				}
			});
		}
	}

	closeBottom() {
		this.bottomSheet.dismiss(this.itemPosResultsRef);
	}

	expand_row(index) {
		this.expanded_rows.includes(index) ? this.expanded_rows.splice(this.expanded_rows.indexOf(index), 1) : this.expanded_rows.push(index);

	}

	isExpanded(index) {
		return this.expanded_rows.includes(index);
	}


	getLineTotal(item: any): number {
		var qty = item.quantity
		if (!qty || item.quantity == 0) {
			qty = 1;
		}

		if (this.config.env.package == 'beauty') {
			var value = this.financial(qty) * (this.financial(item.price) - this.financial(this.customer.discount * item.price))
		} else {
			var value = this.financial(qty) * (this.financial(item.price) - this.financial(this.customer.discount * item.price)) + this.financial(item.fetvalue)
		}

		return value;

	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	updateFilters(event: any) {
		this.filters = event;
		this.itemSearch();
	}

	updateFilteredItems(event: any) {
		this.searching = true;
	}


	updateItem(data: any) {
		let itemdata = this.filtered_items.filter((item: any) => {
			return data.item.stockid == item.stockid
		})[0]

		let index = this.filtered_items.indexOf(itemdata);
		this.filtered_items[index] = itemdata;
	}

	decrement(item: any) {
		let index = this.filtered_items.indexOf(item);
		if (this.filtered_items[index].quantity > 0) {
			this.filtered_items[index].quantity -= 1;
		}
	}

	increment(item: any) {
		let index = this.filtered_items.indexOf(item);
		this.filtered_items[index].quantity += 1;
	}

	updateSearchPrice(event: any, item: any) {
		let index = this.filtered_items.indexOf(item);

		this.filtered_items[index].price = this.financial(event.target.value);
	}

	updateSearchQuantity(event: any, item: any) {
		let index = this.filtered_items.indexOf(item);
		this.filtered_items[index].quantity = event.target.value;
	}

	addToOrder(item: any) {

		if(item.quantity == 0) {
			item.quantity = 1;
		}

		const copyOfItems = Object.assign([], this.filtered_items);
		let this_index = '';

		let add_items = [];
		var allow = true;
		copyOfItems.forEach((items: any, index) => {
			items.allownegative = true;
			items.isnegative = false;

			if (this.type == '21') {

				var qty_request = parseFloat(items.quantity);
				if (qty_request != 0) {
					var qty_min = parseFloat(items.minorderqty);
					var qty_max = parseFloat(items.maxorderqty);

					var qty_max_stock = parseFloat(item.minmax.maximum);
					var qty_min_stock = parseFloat(item.minmax.minimum);

					if (qty_min > 0 && qty_max > 0) {
						if (qty_request > qty_max) {
							// allow = confirm('Attempting to Purchase more than '+qty_max+' for '+items.description+', procced?');
						}

						if (qty_request < qty_min) {
							// allow = confirm('Attempting to Purchase less than '+qty_min+' for '+items.description+', procced?');
						}
					}

					if (allow) {
						if (qty_max_stock > 0 && qty_max_stock > 0) {
							if (qty_request > qty_max_stock) {
								// allow = confirm('Attempting to Purchase more than '+qty_max_stock+' for '+items.description+', procced?');
							}

							if (qty_request < qty_min_stock) {
								// allow = confirm('Attempting to Purchase less than '+qty_min_stock+' for '+items.description+', procced?');
							}
						}
					}
				}
			}
			if (allow) {
				if (parseFloat(items.quantity) != 0) {
					add_items.push(items);
				}
			}
		});


		this.addItem.emit(add_items);
		this.hasresults.emit(false);
		this.filtered_items.forEach(i => { i.quantity = 0; });
		this.resetSearch();
	}

	addMultipleToOrder() {
		this.filtered_items.forEach(element => {
			element.quantity > 0 ? this.addToOrder(element) : "";
		});
		this.resetSearch();
	}

	resetSearch() {
		this.itemSearchForm.get('keywords').setValue('');
		setTimeout(() =>this.searchkeywordsRef.nativeElement.focus(), 0);
		//this.filtered_items = [];
		this.hasresults.emit(false);
		//this.bottomSheet.dismiss(this.itemPosResultsRef);
	}


	setPurchOrderOptions(cart_id: any, selected: any) {
		let data = { cart_id: cart_id, options: selected }
		this.orderService.setPoOptions(data).subscribe((results: any) => {
			this.modalService.dismissAll();
			this.purchase_items = [];
		});

		this.updateCart.emit(true);
	}


	//modals
	viewitemOrderHistory(item: any) {
		this.selecteditem = item;
		this.customerService.getCustomerHistoryItem(this.customer.debtorno, item.stockid).subscribe((result: any) => {
			this.itemhistory = result
			this.inventoryService.getPurchaseHistory(item.stockid).subscribe(async (result: any) => {
				this.itemhistory.purchase = result;
			})

			this.modalService.open(this.itemHistoryRef, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result: any) => {

			}, (reason) => {
				this.itemhistory = [];
				this.selecteditem = false;
			});
		});
	}

	viewPurchOrderOptions(line: any) {
		line.allownegative = true;
		let data = { cart_id: line.cart_id, stockid: line.stockid }
		this.orderService.getPurchDataFromLine(data).subscribe((results: any) => {
			this.purchase_items.push(results);
			this.modalService.open(this.purchasedetails, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result: any) => {

			}, (reason) => {
				this.purchase_items = [];
			});
		});
	}

	openModal(content, item) {

		this.item_details = item;
		this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {
			this.item_details = false;
		});
	}

	// searchAttributes(value: string) {
	// 	this.filteredattributes = this.globalSearchService.filterItem(this.allattributes, value, 'label,value,key');
	// }

	toggleFilters() {
		this.showfilters = (this.showfilters) ? false : true;

		if (!this.showfilters) {
			this.filters = [];
			this.itemSearch();
		}

	}

	triggerMm(item: any) {
		let data = {
			stockid : item.stockid,
			qty : item.quantity,
		}

		this.inventoryService.getPromo(data).subscribe(r => {
			this.promo = r;
			this.promo.itemdesc = item;
			this.promo.qty = r.qty
			this.modalService.open(this.mmodalref, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result: any) => {

			}, (reason) => {
				this.promo = false;
				this.selecteditem = false;
			});
		})
	}

	updateMMQty(mm: any) {
		mm.error = true;
		let items = (mm.items) ? mm.items : []

		const used = items.reduce(function (accumulator, item) {
		  return accumulator + parseFloat(item.quantity);
		}, 0);

		var value = this.financial(mm.floor) - this.financial(used);
		if(value < 0) {
			mm.error = true;
			items.forEach((i:any) => {
				i.quantity = 0;
			});
			value = 0;

			this.globalSearchService.showNotification('Error, Total Qty Adjusted Items Zeroed', 'danger', 'bottom', 'right')
			//reduced down to below. e.g. changed parent to 2 and added 2 then moved back down to 1
			//remove all qtys.

		} else {
			mm.error = true;
			if(value == 0) {
				mm.error = false;
			}
		}

		return value;
	}


	decrementmm(item: any ,intro : any) {
		if (item.quantity > 0) {
			item.quantity -= 1;
			this.updateMMQty(intro);
		}
	}

	incrementmm(item: any,intro : any) {
		if(this.updateMMQty(intro) > 0) {
			item.quantity += 1;
			this.updateMMQty(intro);
		} else {
			this.globalSearchService.showNotification('Promo Requirements Filled', 'danger', 'bottom', 'right')
		}
	}

	qtyMM(event: any, item: any,intro : any) {
		let original_qty = item.quantity;
		var qty_used =  this.financial(event.target.value);
		let test_qty = original_qty + event.target.value;
		let maxqty = this.updateMMQty(intro);

		if(test_qty > maxqty) {
			qty_used = maxqty;
			this.globalSearchService.showNotification('Maximum of '+qty_used+' Allowed', 'danger', 'bottom', 'right')
		}

		item.quantity = qty_used
		this.updateMMQty(intro);

	}

	addMM(intro: any) {
		//test kit first
		this.addMix.emit(intro);
		this.modalService.dismissAll();
	}

	addPromo(item: any) {
		let data = {
			stockid : item.stockid,
			qty : item.quantity,
		}

		this.inventoryService.getPromo(data).subscribe(r => {
			this.promo = r;
			this.promo.itemdesc = item;
			this.promo.qty = r.qty
			this.addMM(this.promo);
		})
	}

	toggleGuide() {
		this.showGuide = (this.showGuide) ? false : true;
	}

	focusQty() {
		let ref = document.getElementById('itemQty');
		setTimeout(()=>{
			ref.focus();
		},0);
	}

}
