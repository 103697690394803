<div class="row m-0 p-0">
	<div @grow class="col-12 mt-2"  class="needs-validation product-add">
		<form [formGroup]="barcodeForm" *ngIf="barcodeForm" (ngSubmit)="addBarcode()">
			<div class="card mt-2">
				<div class="card-body">
					<div class="row">
						<div class="col-md-8">
							<mat-form-field appearance="standard">
								<mat-label>Barcode</mat-label>
								<input type="text" formControlName="barcode" matInput placeholder="Barcode" />
							</mat-form-field>
						</div>
						<div class="col-md-4 text-left">
							<button mat-stroked-button class="mt-3">
								<mat-icon>save</mat-icon>
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
<ng-container *ngIf="barcodes">
	<h4 class="mt-3"> Current Barcodes</h4>
	<div class="row m-0 p-0" *ngFor="let p of barcodes">
		<div class="col-md-2">
			<span class="mt-3" *ngIf="p.isprimary == '1'">
				Primary*
			</span>
		</div>
		<div class="col-md-10">
			<div class="input-group">
				<input class="form-control" value="{{ p.code }}" (input)="p.savevalue=$event.target.value;">
				<div class="input-group-append">
					<button mat-button [disabled]="(!p.savevalue || p.savevalue == '') ? true : false" class="btn btn-primary btn-sm" (click)="saveBarcode(p)" [ngClass]="!p.savevalue || p.savevalue =='' ? 'disabled': 'green-btn'"><i class="fa fa-save "></i></button>
					<button class="btn btn-sm btn-primary" (click)="removeBarcode(p)">
						<mat-icon>remove</mat-icon>
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-container>
