import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit} from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';

import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import { Observable } from 'rxjs';

import { interval, Subscription} from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router} from '@angular/router';
import { VendorService } from '../../../services/vendor.service';
import { PurchasingService } from '../../../services/purchasing.service';
import { GlobalsService } from '../../../services/globals.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { Location } from '@angular/common'

@Component({
	selector: 'app-vendor-invoice-complete',
	templateUrl: './vendor-invoice-complete.component.html',
	styleUrls: ['./vendor-invoice-complete.component.scss']
})
export class VendorInvoiceCompleteComponent implements OnInit {

	user: any = [];
	transno : any = '';
	color : any = '';

	constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder,private vendorService: VendorService, public router: Router, private location: Location, private globalsService: GlobalsService, public purchasingService: PurchasingService, private globalSearchService: GlobalSearchService) {
		this.color = this.globalSearchService.getColor()
		this.globalSearchService.user.subscribe((user: any) => {
			this.user = user
		});

	}

	ngOnInit(): void {

		this.route.params.subscribe(params => {
			this.transno = params['id'];
		});
	}

	back(): void {
		this.location.back()
	}

}
