<ng-template #detailsModal let-modal>
	<div class="modal-header">
		<h3>ORDER #<b>{{orderStatus[0].orderno}}</b> STATUS</h3>
	</div>
	<div class="modal-body">
		<table class="text-center">
			<tr>
				<th>STOCKID</th>
				<th>BIN</th>
				<th>PICKED</th>
			</tr>
			<tr *ngFor="let i of orderStatus" [ngClass]="{'nopick': (i.picked == 0), 'partialpick': (i.picked > 0 && i.picked < i.pick), 'completepick':(i.completed == 1)}">
				<td>{{i.stockid}}</td>
				<td>{{i.bin}}</td>
				<td>{{i.loaded}}/{{i.pick}}</td>
			</tr>
		</table>
	</div>
	<div class="modal-footer">
		<button (click)="printPackingList()" mat-raised-button>
			<mat-icon class="mr-1">print</mat-icon>Packing List
		</button>
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="col-md-12">
			<div class="card pr-0 pl-0">
				<div class="card-header card-header-{{color}}-6">
					<div class="row">
						<div class="col-10">
							<h4 class="card-title">
								<mat-icon (click)="back()">arrow_left</mat-icon>
								Checking
							</h4>
						</div>
						<div class="col-2 text-right" (click)="toggleFilters()">
							<h4 class="card-title">
								<mat-icon [innerHTML]="filterson ? 'unfold_less':'unfold_more'"></mat-icon>
							</h4>
						</div>
					</div>
					<p class="card-category text-white text-right">
						<mat-slide-toggle [formControl]="actionControl" (checked)="actionControl.value">Scan To Check</mat-slide-toggle>
					</p>
					<div class="messages row">

					</div>
				</div>

				<div class="card-body">
					<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent" (selectedTabChange)="tabChanged($event)">
						<mat-tab label="Order Checking ({{ getRemainingChecks() }})" class="no-overflow fill-available">
							<ng-container>
								<div class="row mt-2 mb-2" *ngIf="filterson">
									<div class="col-4 col-md-3">
										<mat-form-field appearance="standard">
											<mat-label>Warehouse</mat-label>
											<mat-select tabindex="-1" aria-hidden="true" [formControl]="loccode">
												<mat-option *ngFor="let k of locations" [value]="k.loccode">
													{{ k.loccode }}: {{ k.locationname }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>
								<div class="row pickitem mt-2">
									<div class="container-fluid tp-box mwidth" [@flipState]="flip">
										<div class="tp-box__side tp-box__back" [ngClass]="flip == 'inactive' ? 'd-none' : ''">
											<div class="row m-0 p-0">
												<div class="col-6 m-0 p-0">
													<h3>CHECKING ORDER #{{ checkingOrderOrderno }}</h3>
												</div>
												<div class="col-6 text-right m-0 p-0 mt-3">
													<button mat-raised-button color="primary" (click)="resetList()">CLOSE</button>&nbsp;&nbsp;
													<button mat-raised-button color="accent" (click)="completeCheck()">COMPLETE</button>
												</div>
											</div>
											<mat-form-field appearance="standard">
												<mat-label>Search/Scan an item</mat-label>
												<input #checkingScan tabindex="0" autofocus autocomplete="off" aria-hidden="false" matInput [formControl]="scanChecking" required value="" class="text-right" (keyup.enter)="$event.preventDefault(); scanItemToOrder()">
												<!--
												<span matSuffix *ngIf="itemScanForm.get('stockidsearch').value.length">
													<button mat-icon-button color="accent" (click)="itemScanForm.get('stockidsearch').setValue('');searchItems();">
														<mat-icon>clear</mat-icon>
													</button>
												</span>
												-->
												<span matSuffix>
													<button mat-icon-button color="white" (click)="scanItemToOrder()">
														<mat-icon>search</mat-icon>
													</button>
												</span>

											</mat-form-field>
											<ng-container *ngIf="checkingOrder">
												<div class="row pickitem mt-2 ml-0 mr-0 pl-0 pr-0" *ngFor="let item of checkingOrder">
													<div class="col-12 col-md-8 m-0 p-0">
														<div class="row m-0 p-0">
															<div class="col-md-6 m-0 p-0">
																<ul class="entry-list m-0 p-0">
																	<li>
																		<div class="spec-label">CUSTOMER</div>
																		<div class="spec-value"><b>({{ item.debtorno }}.{{ item.branchcode }})</b> {{ item.customer }}</div>
																	</li>
																	<li>
																		<div class="spec-label">WANTED</div>
																		<div class="spec-value" [ngClass]="{notdue: item.info.daystilldue > 0}">{{ item.date_wanted }}</div>
																	</li>
																	<li>
																		<div class="spec-label">ORDER#</div>
																		<div class="spec-value">{{item.orderno}} @ x {{item.ordered }} </div>
																	</li>
																	<li>
																		<div class="spec-label">MFGPART#</div>
																		<div class="spec-value">{{ item.mfgpart }}</div>
																	</li>
																</ul>
															</div>
															<div class="col-md-6 m-0 p-0">
																<ul class="entry-list m-0 p-0">
																	<li>
																		<div class="spec-label">ITEM</div>
																		<div class="spec-value"><b>{{ item.stockid }}</b></div>
																	</li>
																	<li>
																		<div class="spec-label">DESC</div>
																		<div class="spec-value">{{ item.description }}</div>
																	</li>
																	<li>
																		<div class="spec-label">CITY</div>
																		<div class="spec-value">{{ item.city }} <b>{{ routeName(item.route) }}</b></div>
																	</li>
																	<li>
																		<div class="spec-label">BIN</div>
																		<div class="spec-value"><b>{{ item.bin }}</b> ({{ item.qoh }})</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
													<div class="col-12 col-md-4 mt-0 mb-0 pt-0 pb-0 text-right">
														<div class="form-group mt-0 mb-0 pt-0 pb-0 ">
															<ng-container *ngIf="(item.claimed == '1' && item.claimed_user == user.user.userid) || item.claimed == '0'">
																<button class=" btn btn-just-icon btn-white btn-fab btn-round btn-sm" (click)="removePick(item)" (keydown.enter)="$event.stopPropogation">
																	<small>
																		<i class="material-icons">remove</i></small>
																</button>
																<span class="mr-2 ml-2" [ngClass]="item.loaded < item.pick ? 'text-danger': 'text-success'">
																	<mat-input>
																		<input inputmode="none" class="text-right col-4 col-md-2 border-top-0 border-left-0 border-right-0" type="text" [(value)]="item.loaded" [ngClass]="item.loaded < item.pick ? 'text-danger': 'text-success'" (input)="setQty($event.target.value, item)">
																		<span matSuffix> / <b>{{ item.pick }}</b></span>
																	</mat-input>
																</span>
																<button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-2" (click)="addPick(item)">
																	<small><i class="material-icons">add</i></small>
																</button>


																&nbsp;
																<mat-checkbox [checked]='item.complete' (change)="setCheckValue($event, item)" class="ml-2 mb-0 mat-checkbox mat-accent mt-4"></mat-checkbox>
															</ng-container>

															<ng-container *ngIf="(item.claimed == '1' && item.claimed_user != user.user.userid)">
																Loaded : {{item.loaded}}/<b>{{ item.pick }}</b>
															</ng-container>
														</div>
													</div>
													<mat-divider></mat-divider>
												</div>
											</ng-container>
										</div>
										<div id="frontside" class="row tp-box__side tp-box__front w-100" [ngClass]="flip == 'active' ? '' : ''">
											<div class="row m-0 p-0 w-100">

												<div class="col-12">
													<h3>{{ getRemainingChecks() }} ORDER(S) TO CHECK </h3>
												</div>
												<div class="col-12">
													<mat-form-field appearance="outline">
														<mat-label>Order Search</mat-label>
														<input matInput #orderSearch tabindex="-1" aria-hidden="true" autocomplete="off" [formControl]="ordersearch" value="" class="text-right">
														<span matSuffix>
															<button tabindex="-1" aria-hidden="true" mat-icon-button color="white" (click)="searchOrders()">
																<mat-icon>search</mat-icon>
															</button>
														</span>
													</mat-form-field>
												</div>
												<div class="col-12">
													<div class="table table-fixed table-hover table-striped">
														<table class="table table-fixed">
															<thead>
																<tr>
																	<th>CUSTOMER#</th>
																	<th>NAME</th>
																	<th>WANTED</th>
																	<th class="text-left">ORDER#</th>
																	<th class="text-right">NET$</th>
																	<th class="text-center">ITEMS#</th>
																	<th></th>
																</tr>
															</thead>
															<tbody *ngIf="pickOrders && pickOrders.length">
																<ng-container *ngFor="let item of pickOrders">
																	<!-- 24 is already checked / loaded -->
																	<tr (click)="loadOrder(item.orderno)" *ngIf="asInt(item.orderstatusid) != 24">
																		<td><b>({{ item.debtorno }}.{{ item.branchcode }})</b></td>
																		<td>{{ item.deliverto }}</td>
																		<td [ngClass]="{notdue: item?.daystilldue > 0}">{{ item.deliverydate }}</td>
																		<td class="text-left">{{item.orderno}}</td>
																		<td *ngIf="item.details" class="text-right">{{ item.details.ordervalue | currency }}</td>
																		<td *ngIf="item.details" class="text-center">{{ item.details.sumquantity }}</td>
																		<td class="text-right"><button mat-raised-button color="accent">CHECK</button></td>
																	</tr>
																</ng-container>
																<ng-container *ngIf="getRemainingChecks() == 0">
																	<tr>
																		<td colspan="7" class="text-center">
																			<h3>NO ORDERS TO CHECK</h3>
																		</td>
																	</tr>
																</ng-container>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>

									<mat-divider></mat-divider>
								</div>
							</ng-container>
						</mat-tab>
						<mat-tab label="Item Checking" class="no-overflow fill-available">
							<ng-container>
								<div class="row mt-2 mb-2" *ngIf="filterson">
									<div class="col-md-3 mt-3">
										<button tabindex="-1" class="btn btn-primary btn-sm d-none d-lg-inline" (click)="exportExcel(picks)">XLS</button>
									</div>
									<div class="col-4 col-md-3">
										<mat-form-field appearance="standard">
											<mat-label>Warehouse</mat-label>
											<mat-select tabindex="-1" aria-hidden="true" [formControl]="loccode">
												<mat-option *ngFor="let k of locations" [value]="k.loccode">
													{{ k.loccode }}: {{ k.locationname }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-4 col-md-3">
										<mat-form-field appearance="standard">
											<mat-label>Route</mat-label>
											<mat-select tabindex="-1" aria-hidden="true" [formControl]="routesControl" (selectionChange)="filterRoute()">
												<mat-option [value]="'ALL'">ALL</mat-option>
												<mat-option *ngFor="let k of routes" [value]="k.route_Id">
													{{ k.route_Id }}: {{ k.route_description }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-4 col-md-3">
										<mat-form-field appearance="standard">
											<mat-label>Order</mat-label>
											<mat-select tabindex="-1" aria-hidden="true" [formControl]="filterOrdersControl" (selectionChange)="filterOrders()">
												<mat-option [value]="'ALL'">All</mat-option>
												<mat-option *ngFor="let v of orders" [value]="v">
													{{ v }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>

								<form [formGroup]="itemScanForm" class="needs-validation product-add" novalidate="" autocomplete="off">
									<div class="row">
										<div class="col-md-3" tabindex="-1" *ngIf="filterson">
											<mat-form-field appearance="standard">
												<mat-label>Bin Search</mat-label>
												<input matInput #binsearcher abindex="-1" aria-hidden="true" autocomplete="off" [formControl]="binsearch" value="" class="text-right" (input)="searchBins()">
												<span matSuffix>
													<button tabindex="-1" aria-hidden="true" mat-icon-button color="white" (click)="searchBins()">
														<mat-icon>search</mat-icon>
													</button>
												</span>
											</mat-form-field>
										</div>
										<div class="col-md-3">
											<mat-form-field appearance="standard">
												<mat-label>Order Status</mat-label>
												<mat-select tabindex="-1" aria-hidden="true" formControlName="itemfiltertypeselect" (selectionChange)="filterPicked()" multiple>
													<mat-option *ngFor="let v of pickedFilter" [value]="v.value">
														{{v.label}}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
										<div class="col-md-9">
											<mat-form-field appearance="standard">
												<mat-label>Search/Scan an item</mat-label>
												<input #stockidsearchtwo tabindex="0" autofocus autocomplete="off" aria-hidden="false" matInput formControlName="stockidsearch" required value="" class="text-right" (keyup.enter)="$event.preventDefault(); scanItem()">
												<!--
												<span matSuffix *ngIf="itemScanForm.get('stockidsearch').value.length">
													<button mat-icon-button color="accent" (click)="itemScanForm.get('stockidsearch').setValue('');searchItems();">
														<mat-icon>clear</mat-icon>
													</button>
												</span>
												-->
												<span matSuffix>
													<button mat-icon-button color="white" (click)="scanItem()">
														<mat-icon>search</mat-icon>
													</button>
												</span>

											</mat-form-field>
										</div>
									</div>
								</form>
								<div class="col-md-12 text-center mr-auto ml-auto mt-4">
									{{ getFilterName() }} {{ picks.length }} Results
									<span *ngIf="!picks.length">
										<br /> {{ picks.length }} Results
									</span>
								</div>
								<ng-container>
									<div class="row pickitem mt-2" *ngFor="let item of picks">
										<div class="col-12 col-md-8 ">
											<div class="row">
												<div class="col-md-6">
													<ul class="entry-list m-0 p-0">
														<li>
															<div class="spec-label">CUSTOMER</div>
															<div class="spec-value"><b>({{ item.debtorno }}.{{ item.branchcode }})</b> {{ item.customer }}</div>
														</li>
														<li>
															<div class="spec-label">WANTED</div>
															<div class="spec-value" [ngClass]="{notdue: item.info.daystilldue > 0}">{{ item.date_wanted }}</div>
														</li>
														<li>
															<div class="spec-label">ORDER#</div>
															<div class="spec-value">{{item.orderno}} @ x {{item.ordered }} </div>
														</li>
														<li>
															<div class="spec-label">MFGPART#</div>
															<div class="spec-value">{{ item.mfgpart }}</div>
														</li>
													</ul>
												</div>
												<div class="col-md-6">
													<ul class="entry-list m-0 p-0">
														<li>
															<div class="spec-label">ITEM</div>
															<div class="spec-value"><b>{{ item.stockid }}</b></div>
														</li>
														<li>
															<div class="spec-label">DESC</div>
															<div class="spec-value">{{ item.description }}</div>
														</li>
														<li>
															<div class="spec-label">CITY</div>
															<div class="spec-value">{{ item.city }} <b>{{ routeName(item.route) }}</b></div>
														</li>
														<li>
															<div class="spec-label">BIN</div>
															<div class="spec-value"><b>{{ item.bin }}</b> ({{ item.qoh }})</div>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div class="col-12 col-md-4 mt-0 mb-0 pt-0 pb-0 text-right">
											<div class="form-group mt-0 mb-0 pt-0 pb-0 ">
												<ng-container *ngIf="(item.claimed == '1' && item.claimed_user == user.user.userid) || item.claimed == '0'">
													<button class=" btn btn-just-icon btn-white btn-fab btn-round btn-sm" (click)="removePick(item)" (keydown.enter)="$event.stopPropogation">
														<small>
															<i class="material-icons">remove</i></small>
													</button>
													<span class="mr-2 ml-2" [ngClass]="item.loaded < item.pick ? 'text-danger': 'text-success'">
														<mat-input>
															<input inputmode="none" class="text-right col-4 col-md-2 border-top-0 border-left-0 border-right-0" type="text" [(value)]="item.loaded" [ngClass]="item.loaded < item.pick ? 'text-danger': 'text-success'" (input)="setQty($event.target.value, item)">
															<span matSuffix> / <b>{{ item.pick }}</b></span>
														</mat-input>
													</span>
													<button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-2" (click)="addPick(item)">
														<small><i class="material-icons">add</i></small>
													</button>


													&nbsp;
													<mat-checkbox [checked]='item.complete' (change)="setCheckValue($event, item)" class="ml-2 mb-0 mat-checkbox mat-accent mt-4"></mat-checkbox>
												</ng-container>

												<ng-container *ngIf="(item.claimed == '1' && item.claimed_user != user.user.userid)">
													Loaded : {{item.loaded}}/<b>{{ item.pick }}</b>
												</ng-container>
											</div>
										</div>
										<mat-divider></mat-divider>
									</div>
								</ng-container>
							</ng-container>
						</mat-tab>

					</mat-tab-group>
				</div>
				<div class="card-body" *ngIf="!picks.length">
					<app-item-short-search></app-item-short-search>
				</div>
			</div>
		</div>
	</div>
</div>