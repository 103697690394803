import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd } from '@angular/router';


import { OrdersService } from '../../services/orders.service';
import { PaymentsService } from '../../services/payments.service';
import { CustomerService } from '../../services/customer.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { Directive, HostListener } from "@angular/core";
import { trigger, state, style, transition, animate } from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


@Component({
	selector: 'app-payment-reversal',
	templateUrl: './payment-reversal.component.html',
	styleUrls: ['./payment-reversal.component.scss']
})
export class PaymentReversalComponent implements OnInit {
	@Input() input;
	@Output() output = new EventEmitter < any > ();
	reversalForm: UntypedFormGroup;

	constructor(private fb: UntypedFormBuilder, public paymentsService: PaymentsService,private modalService: NgbModal) {}

	ngOnInit(): void {
		this.paymentsService.getPaymentById(this.input).subscribe((result:any) => {
			let date = new Date(result.trandate);

			this.reversalForm = this.fb.group({
				reversingid: [this.input, [Validators.required]],
				amount: [result.ovamount, [Validators.required]],
				card: [false, []],
				date: [date, []],
				reason: ['', [Validators.required]],
			});
		})
	}

	createRefund() {

		if(this.reversalForm.valid) {

			this.paymentsService.createRefund(this.reversalForm.value).subscribe( (result:any) => {
				this.modalService.dismissAll();
				this.output.emit(result);

			})
		}
	}

}
