import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { PrintService } from '../../services/print.service'
import { PaymentsService } from '../../services/payments.service'
import { CustomerService } from '../../services/customer.service'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-matchin-documents',
  templateUrl: './matchin-documents.component.html',
  styleUrls: ['./matchin-documents.component.scss']
})
export class MatchinDocumentsComponent implements OnInit {
	@Input() data: any = [];
	filename: string = 'matching_documents';
	sending: boolean = false;
	fetching: any = false;
	user: any = false;
	allowed_allocate: boolean = false;

	@ViewChild("details") detailsRef: ElementRef;

	constructor(public customerService: CustomerService, public paymentsService: PaymentsService, private globalSearchService: GlobalSearchService, private printService: PrintService) {}
	@ViewChild('print_table') printtable: ElementRef;

	ngOnInit(): void {
		this.globalSearchService.user.subscribe(r => {
			this.user = r;
			switch(this.user.user.user_group) {
				case '1':
				case '15':
					this.allowed_allocate = true;
				break
				default:
					this.allowed_allocate = false;
				break;
			}
		});

	}
	export () {
		this.globalSearchService.exportJsonToExcel(this.data, 'DisabledCustomers');

	}

	autoAllocateMatching(trans: any) {
		if(this.fetching) {
			this.fetching.unsubscribe();
		}
 		this.fetching = this.paymentsService.autoAllocMatchingDocument(trans).subscribe(r=>  {
			this.customerService.getMatchingDocs().subscribe( (results: any) => {
				this.data = results;
			});
		})
	}

	exportPdf() {
		this.sending = true;
		let encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const today = new Date();
		let data = {
			content: encoded,
			filename: this.filename,
			title: 'Matching Receivables',
			subtitle:' created '+today.toLocaleString()
		}

		this.printService.pdf(data).subscribe( (result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.sending = false;

		});
	}
	exportXls() {
		this.sending = true;
		const today = new Date();
		let encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: this.filename,
			title: 'Matching Receivables',
			subtitle:' created '+ today.toLocaleString()
		}

		this.printService.xls(data).subscribe( (result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
		});
	}
}
