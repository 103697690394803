<ng-template #plist let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Product Line</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i
				class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="table w-100">
			<table class="table table-sm w-100">
				<tr>
					<th>ITEM</th>
					<th>DESC</th>
					<th class="text-center w-10">QOH</th>
				</tr>
				<tr *ngFor="let i of vieingpline.details">
					<td>{{i.stockid}}</td>
					<td>{{i.description}}</td>
					<td class="text-center w-10">{{i.qoh}}</td>
				</tr>
			</table>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #lineEdit let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i
				class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<div class="row">
						<div class="col-12">
							<form [formGroup]="productLineForm">

								<mat-form-field appearance="standard">
									<mat-label>Line#</mat-label>
									<input type="text" class="text-left " matInput formControlName="line_field" />
								</mat-form-field>

								<mat-form-field appearance="standard">
									<mat-label>Line Description</mat-label>
									<input type="text" class="text-left " matInput formControlName="line_description" />
								</mat-form-field>

								<button mat-stroked-button color="success" aria-label="" (click)="saveProductLine()"
									class="mt-2">
									<mat-icon>save</mat-icon> Save
								</button>

							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #plineMerge let-modal>
	<div class="modal-header">
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i
				class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div *ngIf="productLineMergeForm">

			<form [formGroup]="productLineMergeForm">
				<div class="row">
					<div class="col-4 border-1">
						<h4>FROM<br><mat-checkbox formControlName="removeold" [disabled]="partialmerge.value == true">Remove Product Line</mat-checkbox>
						</h4>

						<mat-form-field class="full-width" appearance="standard">
							<mat-label>Product Line</mat-label>
							<input type="text" #fromLine matInput formControlName="fromlineid"
								[matAutocomplete]="fromLines" appSelectOnFocus>
							<mat-autocomplete #fromLines="matAutocomplete" (optionSelected)="selectPline($event)"
								[displayWith]="displayFn">
								<mat-option *ngFor="let line of filteredproductlines" [value]="line">
									<b>{{line.line_field}}</b> - {{ line.line_description }}
								</mat-option>
							</mat-autocomplete>
						</mat-form-field>
					</div>

					<div class="col-4 border-1">
						<h4>TO<br><mat-checkbox formControlName="renameitems">Rename Items</mat-checkbox>
						</h4>
						<mat-form-field class="full-width" appearance="standard">
							<mat-label>Product Line</mat-label>
							<input type="text" #toLine matInput formControlName="tolineid" [matAutocomplete]="toLines"
								appSelectOnFocus>
							<mat-autocomplete #toLines="matAutocomplete" (optionSelected)="selectPline($event)"
								[displayWith]="displayFn">
								<mat-option *ngFor="let line of filteredproductlines" [value]="line">
									<b>{{line.line_field}}</b> - {{ line.line_description }}
								</mat-option>
							</mat-autocomplete>
						</mat-form-field>
					</div>
					<div class="col-4 border-1">
						<h4>PARTIAL<br><mat-checkbox formControlName="partialmerge">Partially Merge Product Line</mat-checkbox></h4>
					</div>
				</div>
				<ng-container *ngIf="allowmmerge">
					<span *ngIf="allowmmerge.length">
						{{ allowmmerge.length }} ITEMS EXIST
					</span>
				</ng-container>

				<button mat-stroked-button color="success" aria-label="" (click)="mergeProductLines()" class="mt-2"
					*ngIf="!merging">
					<mat-icon>call_merge</mat-icon> merge
				</button>

				<div *ngIf="merging">
					<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66"
						xmlns="http://www.w3.org/2000/svg">
						<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
						</circle>
					</svg>
				</div>

			</form>
			<h4>Items</h4>
			<div class="table w-100" *ngIf="controlsReady == true">
				<table class="table table-sm w-100">
					<tr>
						<th *ngIf="partialmerge.value == true">Action</th>
						<th>ITEM</th>
						<th>DESC</th>
						<th>QOH</th>
						<th>NEW ITEM</th>
						<th></th>
					</tr>
					<tr *ngFor="let i of vieingpline.details" [ngClass]="partialMergeItemsControls[i.stockid].value == true ? 'selected_row': ''">
						<td *ngIf="partialmerge.value == true"><mat-checkbox [formControl]="this.partialMergeItemsControls[i.stockid]" class="partial-check"></mat-checkbox></td>
						<td>{{i.stockid}}</td>
						<td>{{i.description}}</td>
						<td class="text-center w-10">{{i.qoh}}</td>
						<td class="text-center w-10">
							<span *ngIf="productLineMergeForm.get('renameitems').value">
								{{ previewNewStockid(i.stockid, selectedto )}}
							</span>
							<span *ngIf="!productLineMergeForm.get('renameitems').value">
								n/a
							</span>
						</td>
						<td>
							<span *ngIf="productLineMergeForm.get('renameitems').value">
							{{ itemExist(previewNewStockid(i.stockid, selectedto )) }}
							</span>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #plinebatchdis let-modal>
	<div class="modal-header">
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i
				class="fa fa-close"></i></button>
		<h4>Batch {{batchType}}</h4>
	</div>
	<div class="modal-body text-dark">
			<div class="row justify-content-between p-3">
				<div class="d-flex ">
					<button mat-raised-button (click)="allControls('select')">Select All</button>&nbsp;
					<button mat-raised-button (click)="allControls('deselect')">Deselect All</button>
				</div>
				<h4>{{vieingpline.line_description}} ({{vieingpline.line_field}}) Items</h4>
				<button mat-raised-button color="accent" (click)="completeBatch()">{{batchType}}</button>
			</div>
			<div class="table w-100">
				<table class="table table-sm w-100">
					<tr>
						<th>ACTION</th>
						<th>ITEM</th>
						<th>DESC</th>
						<th>QOH</th>
						<th>STATUS</th>
						<th>NEW STATUS</th>
					</tr>
					<tr *ngFor="let i of vieingpline.details" [ngClass]="partialMergeItemsControls[i.stockid].value == true ? 'dis_row' : ''">
						<td><mat-checkbox [formControl]="this.partialMergeItemsControls[i.stockid]" class="partial-check"></mat-checkbox></td>
						<td>{{i.stockid}}</td>
						<td>{{i.description}}</td>
						<td class="text-center w-10">{{i.qoh}}</td>
						<td class="text-center ">
							<ng-container *ngIf="batchType == 'Disable'">
								<ng-container *ngIf="i.enabled == 0">Disabled</ng-container>
								<ng-container *ngIf="i.enabled == 1">Active</ng-container>
							</ng-container>
							<ng-container *ngIf="batchType == 'Discontinue'">
								<ng-container *ngIf="i.discontinued == 0">Active</ng-container>
								<ng-container *ngIf="i.discontinued == 1">Discontinued</ng-container>
							</ng-container>
						</td>
						<td class="text-center ">
							<ng-container *ngIf="batchType == 'Disable'">
								<ng-container *ngIf="partialMergeItemsControls[i.stockid].value == true">Disabled</ng-container>
								<ng-container *ngIf="partialMergeItemsControls[i.stockid].value == false">Active</ng-container>
							</ng-container>
							<ng-container *ngIf="batchType == 'Discontinue'">
								<ng-container *ngIf="partialMergeItemsControls[i.stockid].value == true">Discontinued</ng-container>
								<ng-container *ngIf="partialMergeItemsControls[i.stockid].value == false">Active</ng-container>
							</ng-container>
						</td>
					</tr>
				</table>
			</div>
		</div>
	<div class="modal-footer"></div>
</ng-template>

<ng-template #categoryEdit let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i
				class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<div class="row">
						<div class="col-12">
							<form [formGroup]="categoryForm">

								<mat-form-field appearance="standard">
									<mat-label>Category#</mat-label>
									<input type="text" class="text-left " matInput formControlName="categoryid" />
								</mat-form-field>

								<mat-form-field appearance="standard">
									<mat-label>Description</mat-label>
									<input type="text" class="text-left " matInput
										formControlName="categorydescription" />
								</mat-form-field>
								<br>
								<mat-label>Category Type</mat-label>
								<ng-select [items]="cattypes" bindLabel="catLabel" bindValue="label"
									formControlName="stocktype">
								</ng-select>
								<br>
								<mat-label>Stock G/L Account</mat-label>
								<ng-select [items]="glaccounts" bindLabel="acctLabel" bindValue="accountcode"
									formControlName="stockact">
								</ng-select>
								<br>
								<mat-label>Adjustment G/L Account</mat-label>
								<ng-select [items]="glaccounts" bindLabel="acctLabel" bindValue="accountcode"
									formControlName="adjglact">
								</ng-select>
								<br>
								<mat-label>Purchase Price Variance G/L Account</mat-label>
								<ng-select [items]="glaccounts" bindLabel="acctLabel" bindValue="accountcode"
									formControlName="purchpricevaract">
								</ng-select>
								<br>
								<mat-label>Issue G/L Account</mat-label>
								<ng-select [items]="glaccounts" bindLabel="acctLabel" bindValue="accountcode"
									formControlName="issueglact">
								</ng-select>
								<br>
								<mat-label>Material Usage G/L Account</mat-label>
								<ng-select [items]="glaccounts" bindLabel="acctLabel" bindValue="accountcode"
									formControlName="materialuseagevarac">
								</ng-select>
								<br>
								<mat-label>WIP G/L Account</mat-label>
								<ng-select [items]="glaccounts" bindLabel="acctLabel" bindValue="accountcode"
									formControlName="wipact">
								</ng-select>
								<br>
								<mat-label>Default Tax Category</mat-label>
								<ng-select [items]="taxcats" bindLabel="taxcatname" bindValue="taxcatid"
									formControlName="defaulttaxcatid">
								</ng-select>
								<br>

								<button mat-stroked-button color="success" aria-label="" (click)="saveCategory()"
									class="mt-2">
									<mat-icon>save</mat-icon> Save
								</button>

							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #manuEdit let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i
				class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<div class="row">
						<div class="col-12">
							<form [formGroup]="manufacturerForm">

								<mat-slide-toggle formControlName="isactive">
									<mat-label>Enabled</mat-label>
								</mat-slide-toggle>

								<mat-form-field appearance="standard">
									<mat-label>MFG Code</mat-label>
									<input type="text" class="text-left " matInput formControlName="mfg_code" />
								</mat-form-field>

								<mat-form-field appearance="standard">
									<mat-label>Name</mat-label>
									<input type="text" class="text-left " matInput formControlName="name" />
								</mat-form-field>
								<mat-form-field appearance="standard">
									<mat-label>Sort Order</mat-label>
									<input type="text" class="text-left " matInput formControlName="sort_order" />
								</mat-form-field>
								<mat-form-field appearance="standard">
									<mat-label>Discount %</mat-label>
									<input type="text" class="text-left " matInput formControlName="discount_percent" />
								</mat-form-field>
								<mat-form-field appearance="standard">
									<mat-label>Discount Value</mat-label>
									<input type="text" class="text-left " matInput formControlName="discount_value" />
								</mat-form-field>
								<mat-form-field appearance="standard">
									<mat-label>Warehouses</mat-label>
									<input type="text" class="text-left " matInput formControlName="warehouses"
										placeholder="separated by commas (01,03,...)" />
								</mat-form-field>
								<mat-form-field appearance="standard">
									<mat-label>Website Address</mat-label>
									<input type="text" class="text-left " matInput formControlName="url" />
								</mat-form-field>
								<mat-form-field appearance="standard">
									<mat-label>Email</mat-label>
									<input type="text" class="text-left " matInput formControlName="email" />
								</mat-form-field>
								<button mat-stroked-button color="success" aria-label="" (click)="saveManufacturer()"
									class="mt-2">
									<mat-icon>save</mat-icon> Save
								</button>

							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #plmEdit let-modal>
	<div class="modal-header">
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i
				class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<app-prodline-markups [line_field]="selected_linefield"
			(updatePorductLines)="loadProductLines()"></app-prodline-markups>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-5">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Inventory
							Settings</h4>
						<p class="card-category"></p>
					</div>
					<div class="card-body">
						<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
							<mat-tab label="Product Lines">

								<button mat-stroked-button color="success" aria-label="" (click)="addProductLine()"
									class="mt-2 mb-2">
									<mat-icon>add</mat-icon> Add Product Line
								</button>

								<div class="container-fluid">
									<mat-form-field appearance="fill">
										<mat-label>Search</mat-label>
										<input type="text" class="text-left" matInput [formControl]="plinesearch" />
									</mat-form-field>

									<div class="table ov">
										<div class="text-right"> Results ({{searchingproductlines.length}})</div>
										<table
											class="table table-sm table-striped table-hover table-bordered table-fixed ov">
											<thead class="header">
												<tr>
													<th class="header" scope="col">Code#</th>
													<th class="header" scope="col">Description</th>
													<th class="header text-center" scope="col">
														#Items</th>
													<th *ngFor="let t of salestypes" class="header text-center w-10"
														scope="col">{{t.typeabbrev }} - {{ t.sales_type }}</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let p of searchingproductlines">

													<td>
														<button mat-icon-button [matMenuTriggerFor]="menu"
															aria-label="More">
															<mat-icon>more_vert</mat-icon>
														</button>
														<mat-menu #menu="matMenu">
															<button mat-menu-item color="accent" aria-label=""
																(click)="editPLM(p)">
																<mat-icon>cloud</mat-icon> Markups
															</button>
															<button mat-menu-item color="accent" aria-label=""
																(click)="lineMergeForm(p)">
																<mat-icon>call_merge</mat-icon> Merge
															</button>

															<button mat-menu-item (click)="viewProducts(p)">
																<mat-icon>view_list</mat-icon> View Items
															</button>
															<button mat-menu-item color="danger" aria-label=""
																(click)="removeProductLine(p)">
																<mat-icon>remove</mat-icon> Remove
															</button>
															<button mat-menu-item color="success" aria-label=""
																(click)="editProductLine(p)">
																<mat-icon>edit</mat-icon> Edit
															</button>
															<button mat-menu-item color="success" aria-label=""
																(click)="disableProductline(p)">
																<mat-icon>unpublished</mat-icon> Web Disable
															</button>
															<button mat-menu-item color="success" aria-label=""
																(click)="discontinueProductline(p)">
																<mat-icon>block</mat-icon> Discontinue
															</button>

														</mat-menu>
													</td>
													<td>{{ p.line_field }} - {{ p.line_description }}</td>
													<td class="text-center">{{ p.count }}</td>
													<td *ngFor="let pa of p.plm" class="text-center">
														<ng-container *ngIf="pa.ispricebook && getFallBack(p.line_field , p.typeabbrev)">
															{{ getFallBack(p.line_field , p.typeabbrev).pricebasis }}<br>
															{{ getFallBack(p.line_field , p.typeabbrev).operator_label }}
															{{ getFallBack(p.line_field , p.typeabbrev).amount }}
														</ng-container>
														<ng-container *ngIf="!pa.ispricebook">
															{{pa.pricebasis}}<br>{{ pa.operator_label }} {{ pa.amount }}
														</ng-container>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</mat-tab>
							<mat-tab label="Locations">
								<app-inventory-locations></app-inventory-locations>
							</mat-tab>
							<mat-tab label="Categories">
								<button mat-stroked-button color="success" aria-label="" (click)="addCategory()"
									class="mt-2 mb-2">
									<mat-icon>add</mat-icon> Add Category
								</button>

								<table class="table table-sm">
									<tr>
										<th>Code#</th>
										<th>Description</th>
										<th>Type</th>
										<th>Stock Acc#</th>
										<th>Adj Acc#</th>
										<th>Issue Acc#</th>
										<th>Purchase Price Var Acc#</th>
										<th>Useage Acc#</th>
										<th>WIP Acc#</th>
										<th>Default Tax Cat#</th>
										<th></th>
									</tr>
									<tr *ngFor="let p of categories">

										<td>{{ p.categoryid }}</td>
										<td>{{ p.categorydescription }}</td>
										<td>{{ p.stocktype }}</td>
										<td>{{ p.stockact }}</td>
										<td>{{ p.adjglact }}</td>
										<td>{{ p.issueglact }}</td>
										<td>{{ p.purchpricevaract }}</td>
										<td>{{ p.materialuseagevarac }}</td>
										<td>{{ p.wipact }}</td>
										<td>{{ p.defaulttaxcatid }}</td>
										<td>
											<button mat-stroked-button color="danger" aria-label=""
												(click)="removeCategory(p)" class="mt-2">
												<mat-icon>remove</mat-icon> Remove
											</button>
											<button mat-stroked-button color="success" aria-label=""
												(click)="editCategory(p)" class="mt-2">
												<mat-icon>edit</mat-icon> Edit
											</button>
										</td>
									</tr>
								</table>
							</mat-tab>
							<mat-tab label="GL Classes">
							<app-gl-classes></app-gl-classes>
							</mat-tab>
							<mat-tab label="Hazards">
							</mat-tab>
							<mat-tab label="Rebates">
							</mat-tab>
							<mat-tab label="Manufacturer">
								<button mat-stroked-button color="success" aria-label="" (click)="addManufacturer()"
									class="mt-2 mb-2">
									<mat-icon>add</mat-icon> Add Manufacturer
								</button>
								<table class="table table-sm">
									<tr>
										<th>Enabled</th>
										<th>MFG Code</th>
										<th>Name</th>
										<th>Sort Order</th>
										<th>Shorthand ID</th>
										<th>Discount %</th>
										<th>Discount Value</th>
										<th>Warehouses</th>
										<th></th>
									</tr>
									<tr *ngFor="let p of manufacturers">

										<td>{{ p.isactive ? 'Yes': 'No' }}</td>
										<td>{{ p.mfg_code }}</td>
										<td>{{ p.name }}</td>
										<td>{{ p.sort_order }}</td>
										<td>{{ p.shorthand_id }}</td>
										<td>{{ p.discount_percent }}</td>
										<td>{{ p.discount_value }}</td>
										<td>{{ p.warehouses }}</td>
										<td class="text-center ">

											<button mat-stroked-button color="success" aria-label=""
												(click)="editManufacturer(p)" class="mt-2">
												<mat-icon>edit</mat-icon> Edit
											</button>
											<button mat-stroked-button color="danger" aria-label=""
												(click)="disableManufacturer(p)" class="mt-2">
												<mat-icon>unpublished</mat-icon> Web Disable
											</button>
											<button mat-stroked-button color="success" aria-label=""
												(click)="discontinueManufacturer(p)" class="mt-2">
												<mat-icon>block</mat-icon> Discontinue
											</button>
											<button mat-stroked-button color="danger" aria-label=""
												(click)="removeManufacturer(p)" class="mt-2">
												<mat-icon>remove</mat-icon> Remove
											</button>
										</td>
									</tr>
								</table>
							</mat-tab>

							<mat-tab label="Change Stock ID">
								<ng-container *ngIf="stockidChanged.status == false">
									<div style="display: flex;" @slide>
										<div
											style="display: flex; justify-content: space-evenly; margin-top: 20px; width: 85%;">
											<mat-form-field style="width: 50%;">
												<input matInput type="text" placeholder="Search/Scan item"
													[formControl]="searchbar"
													(keyup)="$event.stopPropagation();$event.preventDefault();v8search($event.target, false)"
													(click)="showSearchResults()">
											</mat-form-field>

											<mat-form-field>
												<input matInput type="text" [formControl]="from" placeholder="From">
											</mat-form-field>

											<mat-form-field>
												<input matInput type="text" [formControl]="to" placeholder="To"
													(keyup)="v8search($event.target, true)">
											</mat-form-field>

										</div>
										<div style="display:flex; width: 15%;">
											<a mat-raised-button
												style="margin-left: 50px; height: 35px; margin-top: 30px;"
												(click)="changeStockID()"
												[disabled]="this.to.valid == false || this.from.valid == false ">Change</a>
										</div>
									</div>
									<div class="table table-responsive text-center searchlist" *ngIf="searchsub">
										<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"
											xmlns="http://www.w3.org/2000/svg">
											<circle class="path" fill="none" stroke-width="6" stroke-linecap="round"
												cx="33" cy="33" r="30"></circle>
										</svg>
									</div>
									<div class="table table-responsive searchlist" #searhlist
										*ngIf="searchResults && searchResults.length && viewSearch">
										<table class="tableitem table-sm border-0">
											<thead class="sticky-thead">
												<tr>
													<th></th>
													<th class="text-left">ITEM</th>
													<th class="text-left">DESC
														<mat-icon style="float: right; color: #e26d6d;"
															(click)="closeSearch()">cancel</mat-icon>
													</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let item of searchResults ">
													<td>
														<a mat-raised-button (click)="loadFrom(item)">From</a>
													</td>
													<td class="text-left">{{ item.stockid }}</td>
													<td class="text-left f12">{{ item.description }}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</ng-container>
								<ng-container *ngIf="stockidChanged.status">
									<div style="text-align: center; margin-top: 10px; width: 50%; margin-left: auto; margin-right: auto;"
										@slide>
										<h3>Stock ID changed from
											<span style="font-weight: bolder; font-style:
												italic;">{{stockidChanged.data.from}}</span>
											<span style="font-weight: lighter"> to </span>
											<span
												style="font-weight: bolder; font-style: italic;">{{stockidChanged.data.to}}</span>
										</h3>
										<button mat-raised-button color="primary" style="margin-bottom: 10px;"
											(click)="reset()">Okay</button>
									</div>
								</ng-container>
							</mat-tab>
						</mat-tab-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
