<ng-template #mmmodal let-modal>
	<div class="modal-header">
		<h4 class="modal-title">{{ promo.itemdesc.stockid }} <span>{{promo.itemdesc.description }}</span></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="col-md-9 text-left">
				{{ promo.itemdesc.stockid }} <span>{{promo.itemdesc.description }}</span>
			</div>

			<div class="col-md-3 text-right">
				<mat-form-field appearance="standard">
					<mat-label>Qty</mat-label>
					<span matPrefix>#</span>
					<input matInput type="number" value="{{ promo.qty | number }}" (input)="setParent($event, promo)" class="text-right">
				</mat-form-field>
				<button class="btn btn-primary mb-1" (click)="addMM(promo)" [ngClass]="{'enabled': !promo.error,'disabled': promo.error}"> Add To Order </button>
			</div>
		</div>
		<!-- {{ promo.descriptions | json }} <h4>PROMO {{ promo.description }} {{ ispromo.promo.price | currency }} </h4> -->
		<ul class="list-group" *ngIf="promo">
			<h5>Promo Items</h5>
			<ng-container *ngFor="let intro of promo.intros">
				<h4> {{ intro.name }}</h4>
				<div class="row">
					<div class="col-md-12 text-right" >

						<mat-divider></mat-divider>
						<ng-container [ngSwitch]="intro.type">
							<div *ngSwitchCase="intro.type == '1'">
								<!--normal item -->
								{{ intro.item }} {{ intro.description }} {{ intro.qty}} @ {{ intro.price | currency }}
							</div>
							<div *ngSwitchDefault [ngClass]="intro.error ? 'bg-danger text-white' : 'bg-success text-white'">
								{{ intro.price  }} {{ intro.dsptype }} {{ updateMMQty(intro) }} / {{ intro.floor }} {{ intro.price }}

							</div>
						</ng-container>
					</div>
				</div>
				<ng-container *ngFor="let item of intro.items">
					<li class="list-group-item text-left">
						<div class="row m-0 p-0">
							<div class="col-md-6 text-left">
								{{ item.stockid }}<br>{{ item.description}}
							</div>
							<div class="col-md-5 m-0 p-0 ml-1 pl-1 pr-1 mr-1 text-right form-inputs">
								<div class="row p-0 m-0">
									<div class="col-2 mt-3 ml-0 mr-0 ml-auto">
										<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="decrementmm(item,intro)">
											<small><i class="material-icons">remove</i></small>
										</button>
									</div>
									<div class="col-6 m-0 ">
										<mat-form-field appearance="standard">
											<mat-label>Qty</mat-label>
											<span matPrefix>#</span>
											<input matInput type="number" value="{{ item.quantity | number }}" (input)="qtyMM($event, item , intro)" class="text-right">
										</mat-form-field>
									</div>
									<div class="col-2 mt-3 ml-0 mr-0 mr-auto ">
										<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="incrementmm(item, intro)">
											<small>
												<i class="material-icons">add</i></small>
										</button>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ng-container>
			</ng-container>
		</ul>
	</div>
</ng-template>
<ng-template #itemHistoryRef let-modal>
	<div class="modal-header">
		<h4 class="modal-title">{{ selecteditem.stockid }} <span [innerHTML]="selecteditem.description"></span></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<mat-tab-group>
			<mat-tab label="{{ customer.name }} Sales">
				<div class="row mt-3">
					<div class="col-12">

						<div class="row" *ngIf="!itemhistory.length">
							<div class="col-12 text-center">
								<h5>No Sales History</h5>
							</div>
						</div>
						<table class="table table-sm smaller-font" *ngIf="itemhistory.length">
							<tr>
								<th>Date</th>
								<th>Type</th>
								<th>Order#</th>
								<th>Qty</th>
								<th>Price</th>
								<th>Total</th>
							</tr>
							<tr *ngFor="let i of itemhistory">
								<td>{{ i.trandate }}</td>
								<td>{{ i.typename }}</td>
								<td>{{ i.order_ }}</td>
								<td>{{ i.quantity }}</td>
								<td>{{ i.price  | currency}}</td>
								<td>{{ i.price * i.quantity | currency }}</td>
							</tr>
						</table>
					</div>
				</div>
			</mat-tab>
			<mat-tab label="Purchases">
				<!-- (orderloaded)="selectItemLookup($event)" (orderinvoice)="invoiceOrder($event)" -->
				<h4>Open Purchase Orders</h4>
				<app-open-purchase-orders [item]="selecteditem.stockid"></app-open-purchase-orders>
				<mat-divider></mat-divider>
				<h4>Purchase History</h4>
				<table class="table table-sm" *ngIf="itemhistory.purchase">
					<thead>
						<tr>
							<th class="text-left w-20">SUPP</th>
							<th class="text-left w-20">PO</th>
							<th class="text-left w-20">DATE</th>
							<th class="text-center w-20">QTY</th>
							<th class="text-right w-20">COST</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let h of itemhistory.purchase">
							<td class="text-left"> {{h.suppname}}</td>
							<td class="text-left"> {{h.orderno}}</td>
							<td class="text-left"> {{h.orddate}}</td>
							<td class="text-center"> {{h.quantityord}}</td>
							<td class="text-right"> {{h.unitprice | currency}}</td>
						</tr>
					</tbody>
				</table>
			</mat-tab>
			<mat-tab label="Purchase Data">
				<div class="row m-0 p-0 mt-3">
					<ng-container *ngFor="let pdata of selecteditem.purchdata">
						<mat-card class="ml-auto mr-auto col-md-4 cpdata" [ngClass]="selecteditem.selected_po_option == pdata ? 'cpdata-selected': '' " (click)="setItemSelectedPoOption(selecteditem,pdata)">
							<mat-card-header>
								<mat-card-subtitle><span matBadge="{{ pdata.preferred_sort }}" matBadgeSize="small" matBadgeColor="accent"></span></mat-card-subtitle>
								<mat-card-title>{{ pdata.supplierid }} - {{ pdata.suppname }} </mat-card-title>
								<mat-card-title> {{ pdata.price | currency }}</mat-card-title>
							</mat-card-header>
							<mat-card-content>
								<h4>Inventory</h4>
								<ng-container *ngIf="pdata.remote">
									<ul class="entry-list p-0 m-0" *ngFor="let remote of pdata.remote">
										<li>
											<div class="spec-label">{{ remote.feedlabel }}:</div>
											<div class="spec-value">{{ remote.qty }}</div>
										</li>
									</ul>
								</ng-container>
								<ng-container *ngIf="!pdata.remote">
									<ul class="entry-list p-0 m-0">
										<li>
											<div class="spec-label">N/A:</div>
											<div class="spec-value">N/A</div>
										</li>
									</ul>
								</ng-container>
							</mat-card-content>

						</mat-card>
					</ng-container>
				</div>
				<app-purchase-data [item]="selecteditem" (newitemdata)="updateItem($event)"></app-purchase-data>
			</mat-tab>
		</mat-tab-group>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #purchasedetails let-modal (click)="stopProp($event)">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Purchase Information</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row" *ngFor="let item of purchase_items">
			<div class="col-12">
				<app-purchase-data [item]="item" (newitemdata)="updateItem($event)"></app-purchase-data>
			</div>
			<div [ngClass]="item.purchdata.length == 1 ? 'col-md-12': 'col-md-6'" *ngFor="let pdata of item.purchdata">
				<div class="card highlight" (click)="setPurchOrderOptions(item.cart_id, pdata)">
					<span matBadge="{{ pdata.preferred_sort }}" matBadgeSize="large" matBadgeColor="accent"></span>
					<div class="card-header text-center">
						<h4 class="card-title text-center">{{ pdata.supplierid }} - {{ pdata.suppname }} {{ pdata.price | currency }}</h4>
					</div>
					<div class="card-body text-center">
						<div class="col-12">
							<h5>{{ pdata.stockid }} {{ pdata.supplierdescription }}</h5>
						</div>
						<div class="col-12 p-0 m-0">
							<ul class="entry-list p-0 m-0" *ngFor="let remote of pdata.remote">
								<li>
									<div class="spec-label">{{ remote.feedlabel }}:</div>
									<div class="spec-value">{{ remote.qty }}</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #itemdetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title"><b>{{ item_details.stockid }}</b><span [innerHTML]="item_details.name"></span> &nbsp;&nbsp;&nbsp;
			<a [routerLink]="'/inventory/view/'+item_details.stockid"><i class="pointer fa fa-edit"></i></a>
		</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row">
			<mat-card class="tire-card " *ngIf="item_details">
				<mat-card-title-group>
					<mat-card-title>
						<h3 [innerHTML]="item_details.descriptions.name"></h3>
						<section class="section-small" [innerHTML]="item_details.descriptions.description"></section>
					</mat-card-title>
					<div class="tire-image">
						<div class="tire-image-container" *ngIf="item_details.image_large">
							<img mat-card-lg-image src="{{ item_details.image_large }}" class="img img-responsive large-thumb" lazy>
						</div>
					</div>
				</mat-card-title-group>
				<mat-card-content class="padding-0">
					<mat-tab-group #tabPanel mat-align-tabs="start" color="accent">
						<mat-tab label="Overview">
							<div class="row">
								<div class="col-md-6">
									Features:<br>
									<span [innerHTML]="item_details.descriptions.features"></span>
								</div>
								<div class="col-md-6">
									Benefits:<br>
									<span [innerHTML]="item_details.descriptions.benefits"></span>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Attributes">
							<div class="row">
								<div class="col-md-6">
									<ul class="spec-list" *ngFor="let a of item_details.additional; let i = index">
										<li *ngIf="!(i % 2)">
											<div class="spec-label"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
								<div class="col-md-6">
									<ul class="spec-list" *ngFor="let a of item_details.additional; let i = index">
										<li *ngIf="i % 2">
											<div class="spec-label ml-1"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Specs">
							<!-- <mat-form-field appearance="standard" *ngIf="!addingattribute" class="text-right">
								<mat-label>Search Attributes</mat-label>
								<input type="text" class="text-right m-0 p-0" matInput (input)="searchAttributes($event.target.value)" />
							</mat-form-field> -->

							<div class="row">
								<div class="col-6">
									<ul class="spec-list" *ngFor="let a of item_details.specs; let i = index">
										<li *ngIf="!(i % 2)">
											<div class="spec-label"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
								<div class="col-6">
									<ul class="spec-list" *ngFor="let a of item_details.specs; let i = index">
										<li *ngIf="i % 2">
											<div class="spec-label ml-1"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
							</div>
						</mat-tab>
					</mat-tab-group>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #orderdetailsModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">{{ orderdetails.deliverto }} ( {{ orderdetails.debtorno }}.{{orderdetails.branchcode}} ) </h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-12">
				<h4>INVOICE: <b>{{ orderdetails.orderno }}</b><br>
				</h4>
			</div>
		</div>

		<div class="table table-responsive">
			<table class="table table-sm">
				<thead>
					<tr>
						<th class="text-left">ITEM</th>
						<th class="text-left">DESC</th>
						<th class="text-center">QTY</th>
						<th class="text-center">PRICE</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of orderdetails.details">
						<td class="text-left"><b>{{ item.stkcode }}</b></td>
						<td>{{ item.itemdesc }}</td>
						<td class="text-center">{{ item.quantity }} </td>

						<td class="text-right">{{ item.unitprice * item.quantity | currency }} </td>
					</tr>
				</tbody>
			</table>
		</div>

	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-container>
	<div class="row m-0">
		<div class="col-12">
			<ng-container *ngIf="itemSearchForm">
				<form [formGroup]="itemSearchForm" autocomplete="off" (keydown.enter)="$event.preventDefault();itemSearch()" class="no-overflow">
					<div class="row form-inputs m-0 ml-auto mr-0" *ngIf="customerAllowedInvoice()">

						<ng-container [ngSwitch]="config.env.package">

							<ng-container *ngSwitchCase="'beauty'">
								<!--<div class="col-md-2" *ngIf="!config.features.fitment && type == '10'">
									 <button mat-stroked-button class="mt-2" color="white" (click)="$event.preventDefault();toggleGuide()">Populate Order</button>
								</div>-->
								<div class="ml-auto col-md-2" *ngIf="type == '10' && !isdriver">
									<app-nonstock (nonstockadded)="addNonStock($event)"></app-nonstock>
								</div>
							</ng-container>

							<ng-container *ngSwitchCase="'tires'">
								<div class="col-md-2" *ngIf="config.features.fitment && type == '10' && !isdriver">
									<button mat-stroked-button class="mt-2" color="white" (click)="$event.preventDefault();toggleGuide()" (keydown.enter)="$event.preventDefault();">Fitment Guide</button>
								</div>
								<div class="col-md-2" *ngIf="type == '10' && !isdriver">
									<app-nonstock (nonstockadded)="addNonStock($event)"></app-nonstock>
								</div>
							</ng-container>
						</ng-container>
						<div class="col-md-2 mt-3" *ngIf="type == '11'" [ngClass]="{'d-none': isdriver}">
							<mat-slide-toggle [formControl]="search_customer_items">Customer Purchases Only </mat-slide-toggle>
						</div>
						<div class="col-md-4 mt-3" *ngIf="type == '21'">
							<mat-slide-toggle [formControl]="search_vendor_items">Vendor Items Only </mat-slide-toggle>
						</div>
						<div class="col-md-2">
							<mat-form-field appearance="standard" class="">
								<mat-label>Search Record Limit</mat-label>
								<mat-select tabindex="-1" formControlName="limit" (selectionChange)="itemSearch()">
									<mat-option *ngFor="let limit of limits" [value]="limit.value">
										{{limit.name }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-2">
							<mat-form-field appearance="standard" class="">
								<mat-label>Sort</mat-label>
								<mat-select tabindex="-1" formControlName="sort" (selectionChange)="itemSearch()">
									<mat-option *ngFor="let sort of sorts" [value]="sort.value">
										{{sort.name }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>

						<div [ngClass]="{'col-md-6': (type == '11' || (!config.features.fitment && !config.features.nonstock)), 'col-md-4': config.features.fitment}">

							<mat-form-field appearance="standard">
								<mat-label>Search For Items</mat-label>
								<button matPrefix mat-icon-button color="white" (click)="toggleFilters()">
									<mat-icon>filter_list</mat-icon>
								</button>
								<input tabindex="1" matInput value="" formControlName="keywords" class="text-right" autofocus (keydown.enter)="$event.preventDefault();itemSearch()" appSelectOnFocus>
								<span matSuffix>
									<button tabindex="-1" mat-icon-button color="white">
										<mat-icon (click)="itemSearch()">search</mat-icon>
									</button>
								</span>
								<mat-progress-bar mode="indeterminate" color="accent" *ngIf="searching"></mat-progress-bar>
							</mat-form-field>
						</div>
					</div>
				</form>
			</ng-container>
			<div class="col-12 text-center" *ngIf="showGuide">
				<app-tire-guide [input]="filtered_items" (output)="runFunction($event)"></app-tire-guide>
			</div>
			<div class="text-right">
				RESULTS: ({{ filtered_items.length }})
				<ng-container *ngIf="searched && !filtered_items.length && !searching">
					<div class="w-100 alert alert-danger">No results</div>
				</ng-container>

				<ng-container *ngIf="type =='10'">
					<div class="flex justify-between text-center max-w-xl mx-auto alert alert-danger" *ngIf="customer.disabletrans && !customer.dissallowinvoices && customer.dissallowinvoices !='1' && customer.disabletrans !='0'">
						<h3>CUSTOMER IS DISABLED</h3>
					</div>
					<div class="flex justify-between text-center max-w-xl mx-auto alert alert-danger flashing flash" *ngIf="customer.dissallowinvoices && customer.dissallowinvoices !='0'">
						<h2>CUSTOMER IS ON AR HOLD</h2>
					</div>
				</ng-container>

			</div>
			<ng-container>
				<div class="row m-0">
					<div class="col-md-3" @addFilters *ngIf="showfilters">
						<app-item-filters [items]="filtered_items" [keywords]="lastsearch" [limit]="itemSearchForm.get('limit').value" [customer]="customer_data" (itemupdate)="updateFilteredItems($event)" (filtersSelected)="updateFilters($event)">
						</app-item-filters>
					</div>
					<div @addFilters [ngClass]="!showfilters ? 'col-md-12 p-0': 'col-md-9 p-0'">
						<ng-container [ngSwitch]="this.config.env.package">
							<!-- BEAUTY VIEW -->
							<ng-container *ngSwitchCase="'beauty'">
								<ng-container *ngFor="let item of filtered_items; let i = index;">
									<!-- BEAUTY HEADER -->
									<div class="row mt-1 mb-1 pt-0 pb-0 border-top text-center text-md-left">
										<!-- HEADER [ngClass]="!showfilters ? 'col-md-7': 'col-md-6'" -->
											<!-- whole row container -->

													<!-- description stockid image -->
													<div class="col-12 text-center col-md-auto mt-0 ml-auto mr-auto ml-md-0 mr-md-auto" >
														<div class="ml-auto mr-auto" [ngClass]="( isExpanded(i) ) ? 'pholder_expanded': 'pholder'" (click)="expand_row(i)">
															<img src="{{item.thumb}}" class="ml-auto mr-auto img img-responsive img-fluid p-0 m-0 attribute-image" lazy>
															<span *ngFor="let image of item.attribute_images">
																<img src="{{image}}" class="ml-auto mr-auto img img-responsive img-fluid p-0 m-0 attribute-image" lazy>
															</span>
														</div>
													</div>
													<div class="col-md-3 col-xl-2 mt-2 ml-auto mr-auto ml-md-0 mr-md-auto ">
														<button tabindex="-1" mat-icon-button aria-label="Change Vendor" color="accent" (click)="viewPurchOrderOptions(item)" *ngIf="config.pofromorder">
															<mat-icon class="smaller-font">edit</mat-icon>
														</button>
														<span class="bold pointer" (click)="openModal(itemdetails, item)">
															{{ item.stockid }} | <span class="lightbrand">{{item.brand}}</span>
															<hr class="m0">
															<span class="lightdesc">{{ item.description }}</span>
														</span>
													</div>
													<div class="col-12 col-sm-1 col-md-1 col-xl-3 ml-auto mr-auto mr-md-0 showqtys">
														<div class="row mt-auto mb-auto border-r j-center qty-box ml-auto mr-auto" [ngClass]="item.specialflag ? 'specialbox' : ''">
															<span class="m-0 pb-0 text-center ml-auto mr-auto d-inline d-sm-none d-xl-inline-flex">QOH<br> {{ item.locqoh }}</span>
															<span class="m-0 pb-0 text-center ml-auto mr-auto d-inline d-sm-none d-xl-inline-flex">OnOrder<br> {{ item.qoo }}</span>
															<span class="m-0 pb-0 text-center ml-auto mr-auto d-inline d-sm-none d-xl-inline-flex">Committed<br> {{ item.qos }}</span>
															<span class="m-0 pb-0 text-center ml-auto mr-auto ">Available<br>{{ item.total_available }}</span>
															<span *ngIf="item.specialflag" class="m-0 pb-0 text-center ml-auto"><span>Original $<br>{{ item.originalPrice }}</span></span>
														</div>
													</div>
													<div class="col-12 col-sm-2 col-xl-3 mt-2 ml-auto mr-auto ml-md-auro mr-md-0">
														<div class="row m-0 ml-auto mr-0 p-0">
															<mat-form-field appearance="standard" class="col-2  col-lg-5 m-0 p-0  ml-auto mr-auto d-sm-none" *ngIf="config.discounts && editsallowed.discounts">
																<mat-label *ngIf="!item.specialflag">
																	<span> Discount</span>
																</mat-label>
																<span matPrefix>%</span>
																<input matInput tabindex="-1" (input)="updateSearchDiscount($event, item )" [value]="item.discount" class="text-right">
															</mat-form-field>

															<mat-form-field appearance="standard" class="col-4 col-md-6 m-0 p-0 ml-auto mr-auto">
																<mat-label *ngIf="!item.specialflag">
																	<span *ngIf="type == '21'">Cost</span>
																	<span *ngIf="type != '21'"> Sell Price</span>
																</mat-label>
																<mat-label *ngIf="item.specialflag">
																	Special
																</mat-label>
																<span matPrefix>
																	<button tabindex="-1" mat-icon-button aria-label="Change Vendor" color="accent" (click)="viewitemOrderHistory(item)">
																		<mat-icon class="smaller-font">visibility</mat-icon>
																	</button>
																</span>
																<span matPrefix>$</span>
																<input matInput tabindex="-1" (input)="updateSearchPrice($event, item )" [value]="item.price" class="text-right">

																<!-- <mat-hint *ngIf="item.hasdiscount && customer.discount" class="text-danger bolder text-right">
																	{{ (item.price - (customer.discount * item.price)) | currency }} @ {{(customer.discountdsp)}}
																</mat-hint> -->
																<mat-hint *ngIf="item.hasdiscount && item.discount" class="text-danger bolder text-right">
																	{{ (item.price - (getItemDiscountMultiplier(item) * item.price)) | currency }} @ {{ getItemDiscountMultiplier(item) | percent}}
																</mat-hint>
																<mat-hint *ngIf="item.fetvalue && item.fetvalue != '0.00'" class="text-danger bolder text-right">
																	FET: {{ item.fetvalue | currency }}
																</mat-hint>
															</mat-form-field>

														</div>
													</div>
													<div class="col-12 col-md-2 mt-2 ml-auto mr-auto">
														<div class="row p-0 m-0">
															<div class="col-2 mt-3 ml-auto mr-0 p-0 shrink-2">
																<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="decrement(item)">
																	<small><i class="material-icons">remove</i></small>
																</button>
															</div>
															<div class="col-6 m-0 mw100 p-0">
																<mat-form-field appearance="standard">
																	<mat-label>Qty</mat-label>
																	<span matPrefix>#</span>
																	<input matInput tabindex="{{ i + 2 }}" type="number" value="{{ item.quantity | number }}" autofocus (input)="updateSearchQuantity($event, item )" class="text-right">
																</mat-form-field>
															</div>
															<div class="col-2 mt-3 ml-0 mr-0 p-0 mr-auto shrink-2">
																<ng-container *ngIf="!item.ispromo">
																	<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="increment(item)">
																<small>
																	<i class="material-icons">add</i></small>
															</button>
																</ng-container>
																<ng-container *ngIf="item.ispromo">
																	<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button " (click)="triggerMm(item)" *ngIf="item.ispromo.requiresconfig">
																		<small><i class="material-icons">settings</i></small>
																	</button>

																	<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button " (click)="addPromo(item)" *ngIf="!item.ispromo.requiresconfig">
																		<small><i class="material-icons">add_box</i></small>
																	</button>
																</ng-container>
															</div>
														</div>
													</div>

													<!-- total box with add qty -->
													<div class="col-6 col-md-1 ml-auto mb-auto mt-auto ml-auto mr-auto">
														<div [ngClass]="(item.quantity > 0) ? 'linetotal_row_picked' : 'linetotal_row'" class="row">
															<span class="linetotal_price">
																&nbsp;{{ getLineTotal(item) | currency }} &nbsp;
															</span>

															<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button" (click)="addToOrder(item)" *ngIf="!item.ispromo">
																<small><i class="material-icons">add_box</i></small>
															</button>

															<ng-container *ngIf="item.ispromo">
																<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button " (click)="triggerMm(item)" *ngIf="item.ispromo.requiresconfig">
																	<small><i class="material-icons">settings</i></small>
																</button>
																<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button " (click)="addPromo(item)" *ngIf="!item.ispromo.requiresconfig">
																	<small><i class="material-icons">add_box</i></small>
																</button>
															</ng-container>
														</div>
													</div>

											<!-- end whole container -->
										<!-- END HEAD -->
									</div>
									<!-- END BEAUTY HEAD -->
								</ng-container>
							</ng-container>
							<!-- DEFAULT VIEW -->
							<ng-container *ngSwitchDefault>
								<ng-container *ngFor="let item of filtered_items; let i = index;">
									<!-- HEADER -->
									<div class="row mt-0 mb-0 pt-0 pb-0 border-top">
										<div class="text-center text-md-left ml-0" [ngClass]="!showfilters ? 'col-md-7': 'col-md-6'">
											<button tabindex="-1" mat-icon-button aria-label="Change Vendor" color="accent" (click)="viewPurchOrderOptions(item)">
												<mat-icon class="smaller-font">edit</mat-icon>
											</button>
											<span class="bold pointer" (click)="openModal(itemdetails, item)">
												{{ item.stockid }} </span> {{ item.mfginfo?.name }}: {{ item.description }} <span class="bold">{{ item?.size }}</span>

											<span *ngFor="let image of item.attribute_images">
												<img src="{{image}}" class="img img-responsive img-fluid p-0 m-0 attribute-image" lazy>
											</span>

										</div>
										<div class="ml-auto mr-0" [ngClass]="!showfilters ? 'col-md-5 ': 'col-md-6'">
											<ul class="list-group list-group-horizontal list-group-flush mt-0 mb-0 pt-0 pb-0 " *ngIf="!salesman && !isdriver">
												<li class="ml-auto list-group-item m-0 pb-0 text-center">QOH<br> {{ item.locqoh }}</li>
												<li class="list-group-item m-0 pb-0 text-center">COMMITED<br> {{ item.qos }}</li>
												<li class="list-group-item m-0 pb-0 text-center">AVAIL<br> {{ item.locqoh - item.qos }}</li>
												<li class="list-group-item m-0 pb-0 text-center pointer">ON PO<br> {{ item.qoo }}</li>
												<li class="list-group-item m-0 pb-0 text-center">EXT
													<br>
													{{ getLineTotal(item) | currency }}
												</li>
											</ul>
										</div>
									</div>
									<!-- END HEAD -->
									<!-- DETAILS -->
									<div @itemSearch class="row mt-0 mb-0 pt-0 pb-0 " [ngClass]="item.specialflag ? 'sale' : 'border-top'">
										<div class="col-md-8 m-0 p-0">
											<div class="row m-0 p-0">
												<div tabindex="-1" class="col-12 col-md-auto mt-3 mr-0 pr-0 text-center" *ngIf="item.thumb" (click)="openModal(itemdetails, item)">
													<img src="{{ item.thumb }}" class="img img-responzive p-0 m-0 " lazy *ngIf="item.thumb" />
												</div>
												<div [ngClass]="item.thumb ? 'col-12 col-md-6' : 'col-12 col-md-6'">
													<div class="row m-0 p-0">
														<ng-container *ngFor="let dsp of item.addon_display">
															<ul class="entry-list col-12 col-md-6 p-0 m-0">
																<li>
																	<div class="spec-label">{{ dsp.label }}:</div>
																	<div class="spec-value">{{ dsp.value }}</div>
																</li>
															</ul>
														</ng-container>
														<ng-container *ngIf="type == '11'">
															<ul class="entry-list col-12 col-md-6 p-0 m-0">
																<!-- <li>
																	<div class="spec-label">Last Purchase</div>
																	<div class="spec-value">{{item.lastpurchase.date_purchased}}&nbsp;&nbsp;#<span (click)="loadOrder(item.lastpurchase.orderno)" class="link">{{item.lastpurchase.orderno}}</span></div>
																</li> -->
																<li>
																	<!-- max credit label is odd find a beter label. its technically total units sold / credited added together: remaining value buy 10 credit 5 5 remain. -->
																	<div class="spec-label">MAX CREDIT</div>
																	<div class="spec-value"><b>{{item.max_credit}}</b></div>
																</li>
															</ul>
														</ng-container>
													</div>
												</div>
												<div class="col-md-3 p-0 m-0" *ngIf="!isdriver">
													<ul class="entry-list col-12 p-0 m-0">
														<li>
															<div class="spec-header">AVAIL</div>
														</li>
														<ng-container *ngIf="type=='11' && item.lastpurchase.date_purchased != null">
															<li>
																<div class="spec-label">Last Purchase</div>
																<div class="spec-value">{{item.lastpurchase.date_purchased}}&nbsp;&nbsp;#<span (click)="loadOrder(item.lastpurchase.orderno)" class="link">{{item.lastpurchase.orderno}}</span></div>
															</li>
														</ng-container>
														<li *ngFor="let w of item.qoh">
															<div class="spec-label">{{ w.locationname }}</div>
															<div class="spec-value">{{ w.quantity - item.qos }}</div>
														</li>
														<li *ngFor="let r of item.remote_inventory">
															<div class="spec-label">{{ r.feedlabel }}</div>
															<div class="spec-value">{{ r.qty }}</div>
														</li>
													</ul>
												</div>
												<ng-container *ngIf="type=='21'">
													<div class="col-md-2">
														<ul class="entry-list col-12 p-0 m-0">
															<li>
																<div class="spec-label">Min O Qty:</div>
																<div class="spec-value"> {{ item.minorderqty }}</div>
															</li>
															<li>
																<div class="spec-label">Max O Qty:</div>
																<div class="spec-value">{{ item.maxorderqty }}</div>
															</li>
															<li>
																<div class="spec-label">Min/MAX:</div>
																<div class="spec-value"> {{ item.minmax.minimum }}/{{ item.minmax.maximum}}</div>
															</li>
														</ul>
													</div>
												</ng-container>
											</div>
										</div>
										<div class="col-md-4 m-0 p-0 text-left">
											<div class="row m-0 p-0">
												<div class="col-7 ml-auto mr-auto text-center col-md-5 m-0 p-0 ml-md-1 pl-md-1 pr-md-1 mr-md-1 text-md-right form-inputs">
													<mat-form-field appearance="standard" class="">
														<mat-label *ngIf="!item.specialflag">
															<span *ngIf="type == '21'">Cost</span>
															<span *ngIf="type != '21'"> Sell Price</span>
														</mat-label>
														<mat-label *ngIf="item.specialflag">
															Special Sell Price
														</mat-label>
														<span matPrefix>
															<button tabindex="-1" mat-icon-button aria-label="Change Vendor" color="accent" (click)="viewitemOrderHistory(item)">
																<mat-icon class="smaller-font">visibility</mat-icon>
															</button>
														</span>
														<span matPrefix>$</span>
														<input matInput tabindex="-1" (input)="updateSearchPrice($event, item )" [value]="item.price" class="text-right">

														<mat-hint *ngIf="item.hasdiscount" class="text-danger bolder text-right">
															{{ (item.price - (customer.discount * item.price)) | currency }} @ {{(customer.discountdsp)}}
														</mat-hint>
														<mat-hint *ngIf="item.fetvalue && item.fetvalue != '0.00'" class="text-danger bolder text-right">
															FET: {{ item.fetvalue | currency }}
														</mat-hint>
													</mat-form-field>
												</div>
												<div class="col-md-5 m-0 p-0 ml-1 pl-1 pr-1 mr-1 text-right form-inputs">
													<div class="row p-0 m-0">
														<div class="col-2 mt-3 ml-0 mr-0 ml-auto">
															<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="decrement(item)">
																<small><i class="material-icons">remove</i></small>
															</button>
														</div>
														<div class="col-6 m-0 ">
															<mat-form-field appearance="standard">
																<mat-label>Qty</mat-label>
																<span matPrefix>#</span>
																<input matInput tabindex="{{ i + 2 }}" type="number" value="{{ item.quantity | number }}" autofocus (input)="updateSearchQuantity($event, item )" class="text-right">
															</mat-form-field>
														</div>
														<div class="col-2 mt-3 ml-0 mr-0 mr-auto ">
															<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="increment(item)">
																<small>
																	<i class="material-icons">add</i></small>
															</button>
														</div>
													</div>
												</div>
												<div class="col-md-1 m-0 p-0 ml-1 pl-1 pr-1 mr-1 mb-3 text-center text-md-right mt-3">

													<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2" (click)="addToOrder(item)" *ngIf="!item.ispromo && screenWidth > 751">
														<small><i class="material-icons">add_box</i></small>
													</button>

													<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-success btn-sm mr-md-2" (click)="addToOrder(item)" *ngIf="!item.ispromo && screenWidth <= 751">
														ADD
													</button>

												<ng-container *ngIf="item.ispromo">
													<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button " (click)="triggerMm(item)" *ngIf="item.ispromo.requiresconfig">
														<small><i class="material-icons">settings</i></small>
													</button>

													<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button " (click)="addPromo(item)" *ngIf="!item.ispromo.requiresconfig">
														<small><i class="material-icons">add_box</i></small>
													</button>

												</ng-container>
												</div>
											</div>
										</div>
									</div>
								</ng-container>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</ng-container>
