import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { InventoryService } from '../../services/inventory.service';
import { CustomerService } from '../../services/customer.service';
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { Directive, HostListener } from "@angular/core";
import { trigger, state, style, transition, animate } from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
	selector: 'app-inventory-adjustment',
	templateUrl: './inventory-adjustment.component.html',
	styleUrls: ['./inventory-adjustment.component.scss']
})
export class InventoryAdjustmentComponent implements OnInit {
	@Input() stockid;
	@Input() cost;
	@Output() closeadj: any = new EventEmitter < boolean > ();
	@Output() newitemdata: any = new EventEmitter < boolean > ();

	itembins: any = [];
	adjustment: any = [];
	selectedbin: any = '';
	movementqty: number = 0;
	movementreason: any = '';
	movements: any = [];
	adjustmentForm: UntypedFormGroup;
	user: any = [];
	locations: any = [];
	bins: any = [];
	total_qoh: any = false;

	constructor(private route: ActivatedRoute, public inventoryService: InventoryService, private globalsService: GlobalsService, private fb: UntypedFormBuilder,
		public globalSearchService: GlobalSearchService) {
		this.globalSearchService.user.subscribe((user: any) => {
			this.user = user.user
		});
	}

	ngOnInit(): void {
		this.globalSearchService.locations.subscribe((locs: any) => {
			this.locations = locs;
			this.inventoryService.getItemBins({ stockid: this.stockid, loccode: this.user.defaultlocation.loccode }).subscribe((results: any) => {
				this.itembins = results;
				this.setForm();

			});
		});

		this.inventoryService.getBinSearch('').subscribe((bins: any) => {
			this.bins = bins;

		});
	}

	totalQoh(): number {
		let movements = this.movements;
		var addon = 0;
		var totalqoh = 0;
		if(movements.length){
			movements.forEach(m => {
				addon += parseFloat(m.qty)
			});
		};

		if(this.itembins) {
			totalqoh = this.itembins.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.quantity);
			}, 0);
		}

		return totalqoh + addon;
	}

	selBin(bin) {
		this.adjustmentForm.get('bin').setValue(bin);
		$("#qty").focus();
	}

	setForm() {
		this.adjustmentForm = this.fb.group({
			stockid: [this.stockid, Validators.required],
			loccode: [this.user.defaultlocation.loccode, Validators.required],
			bin: ['', Validators.required],
			qty: [0, Validators.required],
			reason: ['', Validators.required],
			cost: [this.cost]
		});

		this.adjustmentForm.markAllAsTouched();

		this.adjustmentForm.get('loccode').valueChanges.subscribe(newValue => {
			this.inventoryService.getItemBins({ stockid: this.stockid, loccode: newValue }).subscribe((results: any) => {
				this.itembins = results;
				this.setForm();
			});
		});

		this.adjustmentForm.get('bin').valueChanges.subscribe(newValue => {
			if(newValue != '' && newValue != ' ') {
				newValue = newValue.toUpperCase()
				let search = {
					loccode: this.adjustmentForm.get('loccode').value,
					bin: newValue,
				}
				this.inventoryService.getBinSearch(search).subscribe((bins: any) => {
					this.bins = bins;
				});
			}
		});
	}

	addMovement() {

		if (this.adjustmentForm.valid && this.adjustmentForm.get('qty').value != 0) {
			let move = this.adjustmentForm.value;
			this.movements.push(move);
			this.setForm();
		} else {
			this.globalSearchService.showNotification('Invalid Adjustment','danger','bottom','right');
		}
	}

	binExists():boolean {
		var exits = false;
		let binfilter = this.bins.filter( (b:any)=>{
			return b.bin == this.adjustmentForm.get('bin').value;
		})[0];

		if(binfilter) {
			exits = true;
		}

		return exits;
	}

	removeMovement(move) {
		var index = this.movements.indexOf(move);
		this.movements.splice(index, 1);
	}

	makeAdjustment() {
		if(this.movements) {
			this.inventoryService.adjustInventory(this.movements).subscribe((results: any) => {

				this.inventoryService.loadItem(this.stockid).subscribe((results: any) => {
					this.newitemdata.emit(results)
					this.closeadj.emit(false);
				});

			});
		}
	}

}
