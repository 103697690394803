<ng-template #detailsModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">{{ orderdetails.header.name }} ( {{ orderdetails.header.debtorno }} ) </h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-12">
				<h4>INVOICE: <b>{{ orderdetails.header.orderno  }}</b><br>
				</h4>
			</div>
		</div>

		<div class="table table-responsive">
			<table class="table table-sm">
				<thead>
					<tr>
						<th class="text-left">ITEM</th>
						<th class="text-left">DESC</th>
						<th class="text-center">QTY</th>
						<th class="text-center">PRICE</th>
						<th class="text-right">EXT.</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of orderdetails.details">
						<td class="text-left"><b>{{ item.stkcode }}</b></td>
						<td>{{ item.description }}</td>
						<td class="text-center">{{ item.ordered }} </td>
						<td class="text-center">{{ item.ordered | currency}} </td>
						<td class="text-right">{{ item.unitprice * item.ordered | currency }} </td>
					</tr>
				</tbody>
			</table>
		</div>

	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<div class="table mt-3" *ngIf="item">
	<h3 class="text-center mt-0 mb-0"><b>{{ item.description }}</b></h3>
	<h5 class="text-center">( {{ item.stockid }} )</h5>
	<div class="card">
		<div class="card-header card-header-tabs card-header-{{color}}-2 p-0">
			<div class="nav-tabs-navigation">
				<div class="nav-tabs-wrapper text-nowrap">
					<ul class="nav nav-tabs col-12" data-tabs="tabs">
						<li class="nav-item col-6 mt-0 mb-0 text-center">
							<a mat-button class="nav-link active" href="#info" data-toggle="tab">
								<i class="material-icons">code</i> Send
								<div class="ripple-container"></div>
							</a>
						</li>
						<li class="nav-item col-6 mt-0 mb-0 text-center">
							<a mat-button class="nav-link" href="#history" data-toggle="tab">
								<i class="material-icons">cloud</i>Purch
								<div class="ripple-container"></div>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="card-body">
			<div class="tab-content">
				<div class="tab-pane active" id="info">
					<div class="row" *ngIf="expected_quantity > 0">
						<div class="col-12 col-md-12 ml-auro text-center">
							<mat-form-field appearance="standard" class="ml-2 mr-2 text-center" color="accent">
								<mat-label>BIN</mat-label>
								<input type="text" placeholder="BIN" aria-label="BIN" matInput [formControl]="qtyBin" class="bold bolder" (input)="updateBin($event.target.value)">
							</mat-form-field>
						</div>
						<div class="col-12 col-md-12 ml-auto mr-0 text-right">
							<div class="row m-0 p-0">
								<div class="col-12 text-center" *ngIf="vendorpos.remaining">
									<h5 class="text-center ml-auto mr-auto">
										<mat-checkbox [checked]='rec_complete' (change)="setCheckValue($event)" class="ml-2 mb-0 mat-checkbox mat-accent mt-4"></mat-checkbox>
										&nbsp;EXPECTED TO SEND: {{ vendorpos.total_pending }}
									</h5>
								</div>
								<div class="col-12 ml-auto mr-auto text-center">
									<mat-form-field appearance="standard" class="ml-2 mr-2 text-center" color="accent">
										<mat-label>Qty To Send</mat-label>
										<span matPrefix>
											<button class=" btn btn-just-icon btn-white btn-fab btn-round btn-sm" (click)="removePick($event)">
												<small>
													<i class="material-icons">remove</i>
												</small>
											</button>
										</span>

										<input type="number" placeholder="Receiving Qty" aria-label="Receving Qty" matInput [formControl]="qtyRec" class="bold bolder" (input)="updateQty($event.target.value)">
										<span matSuffix>
											&nbsp;&nbsp;&nbsp;
											<button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-2" (click)="addPick($event)">
												<small><i class="material-icons">add</i></small>
											</button>
										</span>
									</mat-form-field>
								</div>
							</div>
							<div class="col-12 text-center mb-3">
								<button mat-raised-button color="accent" (click)="updateRga()">CONFIRM</button>
							</div>
						</div>
					</div>

					<div class="row m-0 p-0">
						<div class="col-12">
							<ul class="entry-list">
								<li>
									<div class="spec-label">ON HAND </div>
									<div class="spec-value">{{ itemdetails.total_qoh }}</div>
								</li>
								<li>
									<div class="spec-label">ON ORDER</div>
									<div class="spec-value">{{ itemdetails.openpos }}</div>
								</li>
								<li>
									<div class="spec-label">COMMITTED</div>
									<div class="spec-value">{{ itemdetails.reserved }}</div>
								</li>
								<li>
									<div class="spec-label">AVAILABLE</div>
									<div class="spCON-03537780000ec-value">{{ itemdetails.total_qoh - itemdetails.reserved }}</div>
								</li>
								<li>
									<div class="spec-label">MIN/MAX</div>
									<div class="spec-value">{{ location_data.minimum}} / {{ location_data.maximum }}</div>
								</li>
								<li *ngFor="let loc of itemdetails.bins | keyvalue">
									<div class="spec-label">LOCATION</div>
									<div class="overflow-scroll">
										<ng-container *ngFor="let l of loc.value">
											<ng-container *ngIf="l.quantity != '0'">
												<div>
													<div  class="spec-value">{{ l.bin }} ({{ l.quantity }})</div>
												</div>
											</ng-container>
										</ng-container>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="row mt-2 mb-2">
						<div class="col-12 text-center">
							<h6 class="mt-0 mb-0">ORDERS</h6>
						</div>

						<div class="col-12 text-center" *ngIf="!itemdetails.reserved_orders.length">
							N/A
						</div>

						<div class="col-md-12 text-center" *ngIf="itemdetails.reserved_orders.length">
							<span class="ml-auto mr-auto" *ngFor="let order of itemdetails.reserved_orders">
								<a class="order-details" (click)="loadOrder(order.orderno)">
									<b>&nbsp;&nbsp;{{ order.orderno}}&nbsp;&nbsp;</b>
								</a>
							</span>
						</div>
						<div class="col-12 text-center text-md-center">
							<h6 class="mt-0 mb-0">BARCODES</h6>
							<div class="spec-value">{{ item.barcode }}</div>
						</div>
						<div class="col-md-12 text-center ">
							<ng-container *ngIf="itemdetails.openpos">
								<!--
									<span class="ml-auto mr-auto" *ngFor="let po of itemdetails.openpos">
										<b>Expected {{po.quantityord }} </b>
									</span>
								-->
							</ng-container>
						</div>
					</div>

				</div>
				<div class="tab-pane" id="history">
					<div class="table text-center" *ngIf="historyloading">
						<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</div>
					<div class="col-12" *ngIf="!history && !historyloading">
						<div class="alert alert-warning">No Purchase History</div>
					</div>
					<table class="table table-sm" *ngIf="history">
						<thead>
							<tr>
								<th class="text-left w-20">SUPP</th>
								<th class="text-left w-20">PO</th>
								<th class="text-left w-20">DATE</th>
								<th class="text-center w-20">QTY</th>
								<th class="text-right w-20">COST</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let h of history">
								<td class="text-left"> {{h.suppname}}</td>
								<td class="text-left"> {{h.orderno}}</td>
								<td class="text-left"> {{h.orddate}}</td>
								<td class="text-center"> {{h.quantityord}}</td>
								<td class="text-right"> {{h.unitprice | currency}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
