<ng-container *ngIf="!sending">
	<div class="row">
		<div class="col-4">
			<button class="btn btn-success" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></button>
			&nbsp;&nbsp;

			<!-- <button class="btn btn-danger" (click)="exportPdf();"><i class="fa fa-file-pdf-o"></i></button> -->

			<a href="{{this.arlink.apiServer.baseUrl}}{{this.arlink.apiServer.arTrialLink}}{{summary_details.value}}" class="btn btn-danger" ><i class="fa fa-file-pdf-o"></i></a>
		</div>
		<div class="col-md-6">&nbsp;&nbsp;</div>
		<div class="col-2 text-right">

			<mat-form-field appearance="standard" class="">
				<mat-label>Summary Or Detail</mat-label>
				<mat-select [formControl]="summary_details">
					<mat-option *ngFor="let k of summary_or_details" [value]="k.value">
						{{ k.label }}
					</mat-option>
				</mat-select>
			</mat-form-field>

		</div>
	</div>
</ng-container>
<div class="listitem row border-bottom pl-1 pr-1 ml-1 mr-1">
	<ng-container *ngIf="sending">
		<svg class="spinner" width="30px" height="30px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
			<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
		</svg>
	</ng-container>
	<div class="table">
		<input type="text" class="form-control text-right" (input)="onInput($event.target.value)" placeholder="Search..." />
		<ng-container>
			<div class="table table-responsive w-100 " #print_table>
				<table class="table table-responsive" >
					<thead>
					<tr>
						<th>Name</th>
						<th>Customer #</th>
						<th>Customer Type</th>
						<th>Terms</th>
						<th>Credit</th>
						<th>Status</th>
						<th class="text-right">Balance</th>
						<th class="text-right">Future</th>
						<th class="text-right">Current</th>
						<th class="text-right">30 Day</th>
						<th class="text-right">60 Day</th>
						<th class="text-right">90 Day</th>
						<th class="text-right">120 Day</th>
					</tr>
					</thead>
					<tbody>
						<ng-container *ngFor="let result of data; let i = index">
							<tr>
								<td>{{ result.name }}</td>
								<td><a [routerLink]="'/customers/view/'+result.debtorno">{{ result.debtorno }}</a></td>
								<td>{{ result.typename }}</td>
								<td>{{ result.terms }}</td>
								<td>{{ result.creditlimit | currency }}</td>
								<td>{{ result.reasondescription }}</td>
								<td class="text-right">{{ result.balance | currency }}</td>
								<td class="text-right">{{ result.future | currency }}</td>
								<td class="text-right">{{ result.current | currency}}</td>
								<td class="text-right">{{ result.due | currency}}</td>
								<td class="text-right">{{ result.overdue | currency}}</td>
								<td class="text-right">{{ result.overdue1 | currency}}</td>
								<td class="text-right">{{ result.overdue2 | currency}}</td>
							</tr>
							<ng-container *ngIf="!summary_details.value && result.details">
								<tr>
									<th></th>
									<th>Type</th>
									<th>Invoice#</th>
									<th>Order#</th>
									<th>Date</th>
									<th>Terms</th>
									<th class="text-right">Balance</th>
									<th class="text-right">Future</th>
									<th class="text-right">Current</th>
									<th class="text-right">30 Day</th>
									<th class="text-right">60 Day</th>
									<th class="text-right">90 Day</th>
									<th class="text-right">120 Day</th>
								</tr>
								<tr *ngFor="let det of result.details; let b = index">
									<th></th>
									<td>{{det.typename}}</td>
									<td>{{det.transno}}</td>
									<td>{{det.order_}}</td>
									<td>{{det.transdate | date:'short' }}</td>
									<td>{{det.payterms}}</td>
									<td class="text-right">{{det.balance | currency}}</td>
									<td class="text-right">{{det.future | currency}}</td>
									<td class="text-right">{{det.current | currency}}</td>
									<td class="text-right">{{det.due | currency}}</td>
									<td class="text-right">{{det.overdue | currency}}</td>
									<td class="text-right">{{det.overdue1 | currency}}</td>
									<td class="text-right">{{det.overdue2 | currency}}</td>
								</tr>
							</ng-container>
						</ng-container>
					<tr>
						<td>TOTAL</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="text-right">{{ total_balance | currency }}</td>
						<td class="text-right">{{ total_future | currency}}</td>
						<td class="text-right">{{ total_current | currency}}</td>
						<td class="text-right">{{ total_due | currency}}</td>
						<td class="text-right">{{ total_overdue | currency}}</td>
						<td class="text-right">{{ total_overdue1 | currency}}</td>
						<td class="text-right">{{ total_overdue2 | currency}}</td>
					</tr>
					</tbody>
				</table>
			</div>

			<!-- <table mat-table
				[dataSource]="matdata" multiTemplateDataRows  matSort (matSortChange)="announceSortChange($event)">
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
				<td mat-cell *matCellDef="let tran">
					{{ tran.name }}
				</td>
			</ng-container>
			<ng-container matColumnDef="debtorno">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Customer#</th>
				<td mat-cell *matCellDef="let tran">
					<a [routerLink]="'/customers/view/'+tran.debtorno">{{ tran.debtorno }}</a>
				</td>
			</ng-container>
			<ng-container matColumnDef="terms">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Terms#</th>
				<td mat-cell *matCellDef="let tran">
					{{ tran.terms }}
				</td>
			</ng-container>
			<ng-container matColumnDef="creditlimit">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Credit</th>
				<td mat-cell *matCellDef="let tran">
					{{ tran.creditlimit | currency }}
				</td>
			</ng-container>
			<ng-container matColumnDef="reasondescription">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
				<td mat-cell *matCellDef="let tran">
					{{ tran.reasondescription }}
				</td>
			</ng-container>
			<ng-container matColumnDef="balance">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Balance</th>
				<td mat-cell *matCellDef="let tran">
					{{ tran.balance | currency  }}
				</td>
			</ng-container>
			<ng-container matColumnDef="future">typename
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Future</th>
				<td mat-cell *matCellDef="let tran">
					{{ tran.future | currency  }}
				</td>
			</ng-container>
			<ng-container matColumnDef="current">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Current</th>
				<td mat-cell *matCellDef="let tran">
					{{ tran.current | currency  }}
				</td>
			</ng-container>
			<ng-container matColumnDef="due">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>30 Day</th>
				<td mat-cell *matCellDef="let tran">
					{{ tran.due | currency  }}
				</td>
			</ng-container>
			<ng-container matColumnDef="overdue">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>60 Day</th>
				<td mat-cell *matCellDef="let tran">
					{{ tran.overdue | currency  }}
				</td>
			</ng-container>
			<ng-container matColumnDef="overdue1">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>90 Day</th>
				<td mat-cell *matCellDef="let tran">
					{{ tran.overdue1 | currency  }}
				</td>
			</ng-container>
			<ng-container matColumnDef="overdue2">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>120 Day</th>
				<td mat-cell *matCellDef="let tran">
					{{ tran.overdue2 | currency  }}
				</td>
			</ng-container>
			<ng-container matColumnDef="expand">
				<th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
				<td mat-cell *matCellDef="let element">
				<button mat-icon-button aria-label="expand row" *ngIf="element.details !=''"
				(click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
					<mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
					<mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
				</button>
				</td>
			</ng-container>

			<ng-container matColumnDef="expandedDetail" class="center">
				<td mat-cell *matCellDef="let element" [attr.colspan]="trial_balance_columns_expand.length">
				<div class="po-element-detail"  *ngIf="element.details != ''"
					[@detailExpand]="expandedElement ? 'expanded' : 'collapsed'" [style.height]="element.expanded ? 'unset' : '0 !important'">

					<table mat-table [dataSource]="element.details" class="mat-elevation-z1" class="po_table">

						<ng-container matColumnDef="typename_po" >
							<th mat-header-cell *matHeaderCellDef> Type </th>
							<td mat-cell *matCellDef="let element"> {{element.typename}} </td>
						</ng-container>

						<ng-container matColumnDef="transno_po" >
							<th mat-header-cell *matHeaderCellDef> Trans# </th>
							<td mat-cell *matCellDef="let element"> {{element.transno}} </td>
						</ng-container>

						<ng-container matColumnDef="order__po" >
							<th mat-header-cell *matHeaderCellDef> Order# </th>
							<td mat-cell *matCellDef="let element"> {{element.order_}} </td>
						</ng-container>

						<ng-container matColumnDef="daysbeforedue_po" >
							<th mat-header-cell *matHeaderCellDef > Days till due </th>
							<td mat-cell *matCellDef="let element"> {{element.daysbeforedue}} </td>
						</ng-container>

						<ng-container matColumnDef="balance_po" >
							<th mat-header-cell *matHeaderCellDef> Balance </th>
							<td mat-cell *matCellDef="let element"> {{element.balance | currency }} </td>
						</ng-container>

						<ng-container matColumnDef="due_po" >
							<th mat-header-cell *matHeaderCellDef> due </th>
							<td mat-cell *matCellDef="let element"> {{element.due | currency}} </td>
						</ng-container>

						<ng-container matColumnDef="current_po" >
							<th mat-header-cell *matHeaderCellDef> current </th>
							<td mat-cell *matCellDef="let element"> {{element.current | currency}} </td>
						</ng-container>

						<ng-container matColumnDef="future_po" >
							<th mat-header-cell *matHeaderCellDef> future </th>
							<td mat-cell *matCellDef="let element"> {{element.future | currency}} </td>
						</ng-container>

						<ng-container matColumnDef="overdue1_po" >
							<th mat-header-cell *matHeaderCellDef> 30 Day </th>
							<td mat-cell *matCellDef="let element"> {{element.overdue1 | currency}} </td>
						</ng-container>

						<ng-container matColumnDef="overdue2_po" >
							<th mat-header-cell *matHeaderCellDef> 60 day </th>
							<td mat-cell *matCellDef="let element"> {{element.overdue2 | currency}} </td>
						</ng-container>

						<ng-container matColumnDef="payterms_po" >
							<th mat-header-cell *matHeaderCellDef> Terms# </th>
							<td mat-cell *matCellDef="let element"> {{element.payterms}} </td>
						</ng-container>

						<ng-container matColumnDef="trandate_po" >
							<th mat-header-cell *matHeaderCellDef> Transaction Date </th>
							<td mat-cell *matCellDef="let element"> {{element.trandate}} </td>
						</ng-container>

						<ng-container matColumnDef="reasondescription_po" class="center" >
							<th mat-header-cell *matHeaderCellDef> Standing </th>
							<td mat-cell *matCellDef="let element"> {{element.reasondescription}} </td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="po_columns"></tr>
						<tr mat-row *matRowDef="let row; columns: po_columns;"></tr>
					</table>

				</div>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="trial_balance_columns_expand"></tr>
			<tr mat-row *matRowDef="let elements; columns: trial_balance_columns_expand;" class="po-element-row" [class.po-expanded-row]="expandedElement === elements"
			(click)="expandedElement = expandedElement === elements ? null : elements;"></tr>
			<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="po-detail-row"></tr>
			</table> -->

			<ng-container *ngIf="paginator_show">
				<mat-paginator [pageSizeOptions]="[50, 100, 500]" showFirstLastButtons aria-label="select page" [hidePageSize]="paginator_show" [pageSize]="pageSize">
				</mat-paginator>
			</ng-container>
		</ng-container>
	</div>
</div>
