import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GlobalSearchService } from '../services/globalsearchservice.service';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import {MatRippleModule} from '@angular/material/core';
import { CustomersComponent } from './customers.component';
import { CustomerOrdersComponent } from './customer-orders/customer-orders.component';

import { ViewComponent } from './view/view.component';
import { DateAgoCustomerPipe } from '../pipes/date-ago-customer.pipe'
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { CustomerItemLookupComponent } from './customer-item-lookup/customer-item-lookup.component';
import { CustomerTransactionsComponent } from './customer-transactions/customer-transactions.component';
import { CustomerOpenordersComponent } from './customer-openorders/customer-openorders.component';
import { CustomerQuotesComponent } from './customer-quotes/customer-quotes.component';
import { CustomerShiptosComponent } from './customer-shiptos/customer-shiptos.component';
import { CustomerVehiclesComponent } from './customer-vehicles/customer-vehicles.component';

import { CustomerCreateComponent } from './customer-create/customer-create.component';
import { CustomerEditComponent } from './edit/customer-edit/customer-edit.component';
import { CustomerShiptoEditComponent } from './edit/customer-shipto-edit/customer-shipto-edit.component';
import { CustomerSettingsComponent } from './edit/customer-settings/customer-settings.component'

import { CreditCardDirectivesModule } from 'angular-cc-library';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CustomerNotesComponent } from './edit/customer-notes/customer-notes.component';
import { CustomerContactsComponent } from './edit/customer-contacts/customer-contacts.component';
import { CustomerArComponent } from './edit/customer-ar/customer-ar.component';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { CustomerShiptoAddComponent } from './edit/customer-shipto-add/customer-shipto-add.component';
import { CustomerPaymentComponent } from './customer-payment/customer-payment.component';
import { CustomerItemHistoryComponent } from './customer-item-history/customer-item-history.component';

//import { TrialBalanceComponent } from './customer-dashboard/trial-balance/trial-balance.component';

import { CustomerOnlineComponent } from './customer-dashboard/customer-online/customer-online.component';




import { SharedModule } from '../shared/shared.module';

import { DashboardComponentsModule } from '../dashboard-components/dashboard-components.module';
import { CustomersGroupsComponent } from './customers-groups/customers-groups.component';
import { CustomerStatementsComponent } from './customer-statements/customer-statements.component';
import { CustomerConsignmentComponent } from './customer-consignment/customer-consignment.component';
import { ConsignmentEditComponent } from './consignment-edit/consignment-edit.component';
import { CustomerOrderHeaderComponent } from './customer-order-header/customer-order-header.component';
import { DealerCodesComponent } from './edit/dealer-codes/dealer-codes.component';
import { CustomerCdetComponent } from './customer-cdet/customer-cdet.component';
import { CrptComponent } from './crpt/crpt.component';
import { CsrptComponent } from './csrpt/csrpt.component';
import { PcslsComponent } from './pcsls/pcsls.component';
import { CpslsComponent } from './cpsls/cpsls.component';
import { CvslsComponent } from './cvsls/cvsls.component';
import { CustomerMonthlyHistoryComponent } from './customer-monthly-history/customer-monthly-history.component'
import { CustomerCpdetComponent } from './customer-cpdet/customer-cpdet.component';
import { CustomerReportsComponent } from './customer-reports/customer-reports.component';
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
 // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  maxFilesize: 50,
  acceptedFiles: 'image/*'
};

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
	validation: false,
  };
};

@NgModule({
  declarations: [
	DateAgoCustomerPipe,
	CustomersComponent,
	CustomerOrdersComponent,
	CustomerEditComponent,
    ViewComponent,
    CustomerItemLookupComponent,
    CustomerTransactionsComponent,
    CustomerOpenordersComponent,
    CustomerQuotesComponent,
    CustomerShiptosComponent,
    CustomerVehiclesComponent,
    CustomerShiptoEditComponent,
    CustomerCreateComponent,
    CustomerSettingsComponent,
    CustomerNotesComponent,
    CustomerContactsComponent,
    CustomerArComponent,
    CustomerDashboardComponent,
    CustomerShiptoAddComponent,
    CustomerPaymentComponent,
    CustomerItemHistoryComponent,
    //TrialBalanceComponent,
    CustomerOnlineComponent,
    CustomersGroupsComponent,
    CustomerStatementsComponent,
    CustomerConsignmentComponent,
    ConsignmentEditComponent,
    CustomerOrderHeaderComponent,
    DealerCodesComponent,
    CustomerCdetComponent,
    CrptComponent,
    CsrptComponent,
    PcslsComponent,
    CpslsComponent,
    CvslsComponent,
    CustomerMonthlyHistoryComponent,
	CustomerCpdetComponent,
 CustomerReportsComponent
  ],
  imports: [
    CommonModule,
	BrowserModule,
	NgxDropzoneModule,
	DropzoneModule,
	RouterModule,
	BrowserAnimationsModule,
	SharedModule,
	ReactiveFormsModule,
	MatRippleModule,
	FormsModule,
	NgxMaskModule.forRoot(maskConfigFunction),
	MatToolbarModule,
	MatButtonModule,
	MatDialogModule,
	MatDividerModule,
	MatInputModule,
	MatTableModule,
	MatCheckboxModule,
	MatSortModule,
	MatCardModule,
	MatPaginatorModule,
	MatFormFieldModule,
	MatTooltipModule,
	MatAutocompleteModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatExpansionModule,
	MatSelectModule,
	MatMenuModule,
	MatIconModule,
	MatSlideToggleModule,
	MatRadioModule,
	MatStepperModule,
	MatListModule,
	MatTabsModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatButtonToggleModule,
	MatChipsModule,
	NgxImageZoomModule,
	NgxExtendedPdfViewerModule,
	CreditCardDirectivesModule,
	DashboardComponentsModule
  ],
  exports: [
	  CustomerTransactionsComponent,
	  CustomerItemHistoryComponent,
	  CustomerQuotesComponent,
	  CustomerMonthlyHistoryComponent,
	  CustomerShiptosComponent,
	  CustomerShiptoEditComponent
  ],
  providers: [{
		provide: DROPZONE_CONFIG,
		useValue: DEFAULT_DROPZONE_CONFIG
	}],
})
export class CustomersModule { }
