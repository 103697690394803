import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { interval, Subscription} from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router} from '@angular/router';

import { InventoryService } from '../../../services/inventory.service';
import { GlobalsService } from '../../../services/globals.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { Customer } from '../../../classes/customer';
import { Location } from '@angular/common'

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bin-history',
  templateUrl: './bin-history.component.html',
  styleUrls: ['./bin-history.component.scss']
})
export class BinHistoryComponent implements OnInit {

	@Input() bins;
	history : any = [];
	bindetails: any = [];
	fromdateCtrl = new UntypedFormControl('')
	todateCtrl = new UntypedFormControl('')
	stockIdCtrl = new UntypedFormControl('')
	todate = new Date();
	fromdate = new Date();
	today = new Date();

	constructor(private inventoryService : InventoryService,private modalService: NgbModal) { }

	ngOnInit(): void {
		var today = new Date();
		var priorDate = new Date(new Date().setDate(today.getDate() - 30));
		this.fromdate = priorDate;

		this.fromdateCtrl.setValue(this.fromdate)
		this.todateCtrl.setValue(this.todate)
		var daterange = {};
		daterange = {
				fromdate : this.fromdateCtrl.value,
				todate : this.todateCtrl.value,
				stockid: this.stockIdCtrl.value,
				};
		this.inventoryService.getBinMovements(daterange).subscribe((results: any)=>{
			this.history = results;
		})
	}
	
	getHistory(){
	
	var daterange = {};
		daterange = {
				fromdate : this.fromdateCtrl.value,
				todate : this.todateCtrl.value,
				stockid: this.stockIdCtrl.value,
				};
		this.inventoryService.getBinMovements(daterange).subscribe((results: any)=>{
			this.history = results;
		})
		
	}

	reverseMovements(id: any) {
		this.inventoryService.reverseMovements(id).subscribe((results: any)=>{
			this.history = results;
		})
	}

	getToBin(jsonencoded: any) {
		let bao = JSON.parse(jsonencoded);
		return bao.to
	}

	openModal(content: any , id: any) {

		this.inventoryService.getBinMovementDetails(id).subscribe((results:any) =>{
			this.bindetails = results
				this.modalService.open(content, {ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

				//this.editing_shipping = false;
			}, (reason) => {
				//close with reason..
			});
		});
	  }

}
