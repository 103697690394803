
<ng-template #thirdparty let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title"></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="urlSafe">
			<iframe allowfullscreen class="embed-responsive-item" [src]="urlSafe"></iframe>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="row tp-wrapper m-0 p-0">
			<div class="col-md-12 ">
				<div class="card ">
					<div class="card-header card-header-{{color}}">
						<h4 class="card-title">
							<i class="material-icons">arrow_left</i> Payment Entry
							<span class="bold card-title" *ngIf="customer">{{ customer.debtorno }}
									{{ customer.name }}</span>
						</h4>
					</div>
					<div class="card-body">
						<!--left-->
						<div class="row ">
						<div class="col-4" [ngClass]="{'d-none': searchString.value.length >= 1 || searchByInvoice.value.length >= 1, 'col-12': searchByAmount.value.length >= 1 }">
							<mat-form-field class="full-width text-right" appearance="standard" color="accent">
								<mat-label>Search Amount</mat-label>
								<input type="text" placeholder="Search for an amount" aria-label="Number" matInput [formControl]="searchByAmount" [matAutocomplete]="autoAmount" (input)="amountSearch($event.target.value)">
								<mat-autocomplete #autoAmount="matAutocomplete" (optionSelected)="selectCustomer($event.option.value)">
									<mat-option *ngFor="let customer of amountResults" [value]="customer">
										Invoice: {{ customer.transno }} Total {{ customer.total }}
										{{ customer.debtorno }}.{{ customer.branchcode}} {{ customer.name }} Balance {{ customer.aged.balance | currency }}
									</mat-option>
								</mat-autocomplete>
							</mat-form-field>
						</div>
						<div class="col-4" [ngClass]="{'d-none': searchString.value.length >= 1 || searchByAmount.value.length >= 1, 'col-12': searchByInvoice.value.length >= 1 }">
							<mat-form-field class="full-width text-right" appearance="standard" color="accent">
								<mat-label>Search Invoice#</mat-label>
								<input type="text" placeholder="Search for an invoice" aria-label="Text" matInput [formControl]="searchByInvoice" [matAutocomplete]="autoInvoice" (input)="invoiceSearch($event.target.value)">
								<mat-autocomplete #autoInvoice="matAutocomplete" (optionSelected)="selectCustomer($event.option.value)">
									<mat-option *ngFor="let customer of invoiceResults" [value]="customer">
										Invoice: {{ customer.transno }} Total {{ customer.total }}
										{{ customer.debtorno }}.{{ customer.branchcode}} {{ customer.name }}
										Balance {{ customer.aged.balance | currency }}
									</mat-option>
								</mat-autocomplete>
							</mat-form-field>
						</div>
						<div class="col-4" [ngClass]="{'d-none': searchByInvoice.value.length >= 1 || searchByAmount.value.length >= 1, 'col-12': searchString.value.length >= 1 }">
							<mat-form-field class="full-width text-right" appearance="standard" color="accent">
								<mat-label>Customer</mat-label>
								<input type="text" placeholder="Search for a Customer" aria-label="Number" matInput [formControl]="searchString" [matAutocomplete]="autoCustomer" (input)="customerSearch($event.target.value)" appSelectOnFocus>
								<mat-autocomplete #autoCustomer="matAutocomplete" (optionSelected)="selectCustomer($event.option.value)" >
									<ng-container *ngIf="customerSearchResults">
										<mat-option *ngFor="let customer of customerSearchResults" [value]="customer">
											{{ customer.debtorno }}.{{ customer.branchcode}} {{ customer.name }} Balance {{ customer.aged.balance | currency }}
										</mat-option>
									</ng-container>
								</mat-autocomplete>
							</mat-form-field>
						</div>
						<div class="container-fluid">
							<h4 class="text-center" *ngIf="!customer">Select a customer</h4>
							<app-payment [customer]="customer" *ngIf="customer"></app-payment>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
