<ng-template #matrix let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Price Matrix</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3">

			<div class="row">
				<div class="col-12 text-right">
					<button mat-raised-button color="accent" (click)="saveMatrix()">SAVE</button>
				</div>
			</div>

			<div class="row">
				<div class="col-md-3 border-bottom text-center" *ngFor="let p of customermatrix.productlines">
					{{ p.line_field }} :
					<input type="text" class="input" [value]="getCustomerMatrixDiscount(p.line_field)" (input)="updateCustomerMatrix(p,$event.target.value)">
					<input type="text" class="input" [value]="getCustomerMatrixLevel(p.line_field)" (input)="updateCustomerMatrixLevel(p,$event.target.value)">
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-container *ngIf="settingsFrom">
	<form [formGroup]="settingsFrom"  (submit)="updateCustomerSettings()">
		<div class="col-12 text-right" @grow>
			<button class="ml-auto mr-0" mat-button
			mat-raised-button [color]="settingsFrom.valid ? 'warn': 'accent' "> <i class="material-icons">save</i> Save</button>
		</div>
		<div class="row" @grow>
			<div class="col-md-12">
				<mat-slide-toggle formControlName="disabletrans">Disabled</mat-slide-toggle>
			</div>

			<div class="col-md-12">
				<mat-form-field appearance="standard">
			 		<mat-label>Username</mat-label>
			 		<input matInput required value="" formControlName="username">
			 	</mat-form-field>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="standard">
			 		<mat-label>Password</mat-label>
			 		<input matInput required value="" formControlName="password">
			 	</mat-form-field>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="standard" >
					<mat-label>Customer Type</mat-label>
					<mat-select formControlName="typeid">
						<mat-option *ngFor="let tpe of customertypes"
						[value]="tpe?.typeid">
						{{tpe.typename}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="standard" class="" @grow>
					<mat-label>Sales Person</mat-label>
					<mat-select
					formControlName="salesman"
					[(value)]="customer.customer.salesman"
					>
					<mat-option *ngFor="let person of salesman"
					[value]="person.salesmancode">
					{{ person.salesmancode}} {{person.salesmanname }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="standard" class="" >
					<mat-label>Price List</mat-label>
					<mat-select formControlName="salestype">
						<mat-option *ngFor="let tpe of salestypes"
						[value]="tpe?.typeabbrev">
						{{ tpe.typeabbrev }} - {{tpe.sales_type}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-12" *ngIf="settingsFrom.get('salestype').value == 'O'">
				<mat-form-field appearance="standard" class="" >
					<mat-label>Base Price</mat-label>
					<mat-select formControlName="salestype2">
						<mat-option *ngFor="let tpe of salestypes"
						[value]="tpe?.typeabbrev">
						{{ tpe.typeabbrev }} - {{tpe.sales_type}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-12" *ngIf="settingsFrom.get('salestype').value == 'M'">
				<button mat-flat-button (click)="$event.preventDefault();loadMatrix()">Edit Matrix</button>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="standard" class="" >
				<mat-label>Discount</mat-label>
					<input matInput value="0" type="text" class="text-right" formControlName="discount">
					<mat-icon matSuffix><small>&nbsp;&nbsp;%</small></mat-icon>
				</mat-form-field>
			</div>
		</div>
	</form>
</ng-container>
