import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { DataService } from '../data.service';
import { OmsService } from '../services/oms.service';
import { OrdersService } from '../services/orders.service';

import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';
import { DailySales } from '../classes/reports';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as Chartist from 'chartist';


import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

import { Message } from '../classes/message';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { GlobalSearchService } from '../services/globalsearchservice.service';



@Component({
  selector: 'app-ods',
  templateUrl: './ods.component.html',
  styleUrls: ['./ods.component.scss']
})
export class OdsComponent implements OnInit {

	newMessage: string;
	messageList:  string[] = [];
	sender;

	SENDER;
	messages = [];
	message;
	CHAT_ROOM = "OrderBoard";
	messageForm;
	data;
	carddata;

	todo = [];
	review = [];
	done = [];

	orders = [];
	status = [];
	cardaction;


	constructor(public omsService: OmsService, public location: Location, public ordersService: OrdersService, private globalSearchService: GlobalSearchService) {
		this.carddata = {todo: this.todo, review:this.review, done: this.done}
	}

	ngOnInit(): void {


		this.omsService.getOrderCards().subscribe((results:any) => {
			this.status = results.status
			this.carddata = results.orders;
			this.globalSearchService.orderData.next(results.orders);
			//this.omsService.joinRoom(this.CHAT_ROOM);
		});


		const token = this.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
		if (token) {
			this.omsService.setupSocketConnection(token);
			this.omsService.subscribeToCards((err, data) => {
				this.messages = [...this.messages, data.data];
				this.carddata = data.data;
			});
		}
	}

	submitMessage(cardaction) {
		const data = this.carddata //cardaction;
		if (data) {
			this.omsService.sendCardUpdate({data, roomName: this.CHAT_ROOM}, cb => {

			});

			this.messages = [...this.messages,{data,...this.SENDER,},];
		}
	}

	randomString(length, chars) {
		var result = '';
		for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
		return result;
	}

	drop(event: CdkDragDrop<string[]> , list: string) {

		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {

			transferArrayItem(event.previousContainer.data,
						event.container.data,
						event.previousIndex,
						event.currentIndex);
		}

		const cardaction = {
			pindex: event.previousIndex,
			cindex: event.currentIndex,
			//pcontainer: event.previousContainer.data,
			//ccontainer: event.container.data,
			list: list,
			message: this.carddata
		}

		this.submitMessage(cardaction);
	}

	back(): void {
		this.location.back()
	}

}
