<ng-template #binSelect let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Qty To Receive</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i
				class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
	<div class="row">
	<div class="col-md-6 card-group">
				<div class="card m-0">
				<div class="card-header">
					From:
				</div>
				<div class="card-body" style="white-space: pre-line">
				{{ fromaddress }}
				</div>
				</div>
			</div>
			<div class="col-md-6 card-group">
				<div class="card m-0">
				<div class="card-header">
					To:
				</div>
				<div class="card-body" style="white-space: pre-line">
				{{ toaddress }}
				</div>
				</div>
			</div>
	</div>
	<div  class="row" style="background:black;color:white;">
		<div class="col-md-3">
		Item Code
		</div>		
		<div class="col-md-2">
		Ship Qty
		</div>
		<div class="col-md-3">
		Bin
		</div>
		<div class="col-md-2">
		Qty to Receive
		</div>
	</div>
	<div  class="row" *ngFor="let bin of items">
		<div class="col-md-3">
		{{bin.stockid}}
		</div>		
		<div class="col-md-2">
		{{bin.releasedqty}}
		</div>
		<div class="col-md-3">
		<mat-form-field appearance="standard">
		<mat-label>Bin</mat-label>
			<mat-select (selectionChange)="updateBinSelection($event, bin)">
				<mat-option *ngFor="let bincode of bin.bins" [value]="bincode.bin">
					{{bincode.bin }}
				</mat-option>
			</mat-select>
		</mat-form-field>
		</div>
		<div class="col-md-2">
		<mat-form-field appearance="standard">
			<mat-label>Qty</mat-label>
			<span matPrefix>#</span>
			<input matInput tabindex="{{ i + 2 }}" type="number" minimum="0" value="{{ bin.quantity | number }}" autofocus (input)="updateBinQuantity($event, bin )" class="text-right">
		</mat-form-field>
		</div>
	</div>
	</div>
	<div class="row">
		<div class="col-md-10">
		</div>
		<div class="col-md-2">
		<button class="btn btn-success" (click)="receiveItems(items)"> Receive Items </button>
		</div>
	</div>
</ng-template>
<div class="container-fluid">
								
								<div class="row">

								<div class="container-fluid">
								<form [formGroup]="searchForm">
								  <div class="row">
									<div class="col-md-4">
									<mat-form-field appearance="fill">
										<mat-label>Start Date</mat-label>
										<input matInput [matDatepicker]="picker1" formControlName="startdate" (dateChange)="getTransfers()"/>
										<mat-datepicker-toggle matSuffix [for]="picker1">
										</mat-datepicker-toggle>
										<mat-datepicker #picker1></mat-datepicker>
									</mat-form-field>
									</div>
									<div class="col-md-4">
									<mat-form-field appearance="fill">
										<mat-label>End Date</mat-label>
										<input matInput [matDatepicker]="picker2" formControlName="enddate" (dateChange)="getTransfers()"/>
										<mat-datepicker-toggle matSuffix [for]="picker2">
										</mat-datepicker-toggle>
										<mat-datepicker #picker2></mat-datepicker>
									</mat-form-field>
									</div>
									<div class="col-md-4">
									<mat-form-field appearance="fill">
										<mat-label>To Location</mat-label>
												<mat-select formControlName="toloc" (selectionChange)="getTransfers()">
												<mat-option value="">
														---- Any ----
													</mat-option>
													<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
														{{loc.locationname }}
													</mat-option>
												</mat-select>
									</mat-form-field>
									</div>
									  </div>
									</form>
									<div class="table ov">
										<div class="text-right"> Results ({{transferlist.length}})</div>
										<table class="table table-sm">
										<tr>
											<th>Document #</th>
											<th>Input Date</th>
											<th>From Location</th>
											<th>To Location</th>
											<th>Status</th>
										</tr>
										<tr *ngFor="let t of transferlist">
											<td><button class="btn btn-sm button-primary" (click)="getTransferData(t.transno);">{{ t.transno }}</button></td>
											<td>{{ t.inputdate | date }}</td>
											<td>{{ t.fromstkloc }}</td>
											<td>{{ t.tostkloc }}</td>
											<td>{{ statuses[t.status].description }}</td>
										</tr>
										</table>
									</div>
								</div>
								</div>
							</div>