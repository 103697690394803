import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';
@Injectable({
	providedIn: 'root'
})

export class SystemService {
	key: string = 'UET6DFMPAXW7BKCB';
	config: any;
	package: any = '';

	constructor(private globalSearchService:GlobalSearchService, private http: HttpClient, private httpClient: HttpClient, private _configSvc: RuntimeConfigLoaderService) {
		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		})
	}

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	public saveEmailSettings(data: any) {
		var method = 'globals/settings/email'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getEmailSettings() {
		var method = 'globals/settings/email';
		return this.http.get(this.setEndPoint(method));
	}


	public updatetEmailSettings(data: any) {
		var method = 'system/settings/email'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getWebCategories(){
		var method = 'storefront/categories';
		return this.http.get(this.setEndPoint(method));
	}
	public addWebCategory(data: any){
		var method = 'storefront/catadd'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public updateCategoryList(data: any){
		var method = 'storefront/catupdate'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public uploadCatFile(formdata: any, cat: any) {
		var method = 'storefront/catupload&cat=' + cat;
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}
	public removeCategoryItems(data: any){
		var method = 'storefront/removeitem'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public addCategoryItems(data: any){
		var method = 'storefront/saveitems'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public getCategoryItems(data: any){
		var method = 'storefront/getcatprod'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public getCategoryItemSearch(data: any){
		var method = 'storefront/getitems'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	
	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId;
	}

	public getKey() {
		return '&apikey=' + this.key;
	}

	private handleError < T > (operation = 'operation', result ? : T) {
		return (error: any): Observable < T > => {
			return error;
		};
	}
}
