import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
declare const google: any;


@Component({
selector: 'app-address-lookup',
templateUrl: './address-lookup.component.html',
styleUrls: ['./address-lookup.component.scss'],
})

export class AddressLookupComponent implements OnInit {
	@Input() adressType: string;
	@Output() setAddress: EventEmitter < any > = new EventEmitter();
	@ViewChild('addresstext') addresstext: any;

	addressAutocompleteInput: string;
	queryWait: boolean;
	options = {
	  componentRestrictions: { country: "us" },
	  fields: ["address_components", "geometry", "icon", "name"],
	  strictBounds: false,
	  // /types: ["establishment","address"],
	};


	constructor() {}

	ngOnInit() {}

	ngAfterViewInit() {
		this.getPlaceAutocomplete();
	}

	private getPlaceAutocomplete() {

		const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, this.options);
		google.maps.event.addListener(autocomplete, 'place_changed', () => {
			const place = autocomplete.getPlace();
			this.invokeEvent(place);
		});
	}

	invokeEvent(place: Object) {
		this.setAddress.emit(place);
	}

}
