import { Injectable } from '@angular/core';
import { Message } from '../classes/message';
import { Observable } from 'rxjs';
import { UsersService } from './users.service';
import { catchError, retry } from 'rxjs/internal/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { GlobalSearchService } from './globalsearchservice.service';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';



@Injectable({
	providedIn: 'root'
})
export class GeneralLedgerService {
	key: string = 'UET6DFMPAXW7BKCB';
	config: any;
	//todo make connection reusabel
	socket: any;
	details: any;
	user: any = [];
	package: any = '';

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	constructor(private http: HttpClient, private _configSvc: RuntimeConfigLoaderService, private globalSearchService: GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		})
		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
		});

	}

	public repostGl() {
		var method = 'generalledger/repost';
		return this.http.get(this.setEndPoint(method));
	}

	public getPeriods() {
		var method = 'generalledger/getperiods';
		return this.http.get(this.setEndPoint(method));
	}

	public getTags() {
		var method = 'generalledger/gettags';
		return this.http.get(this.setEndPoint(method));
	}
	public getAcctListing() {
		var method = 'generalledger/listing';
		return this.http.get(this.setEndPoint(method));
	}
	public getAccountInquiry(data) {
		var method = 'generalledger/accountinquiry';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}
	public getTrialBalance(data: any) {
		var method = 'generalledger/trialbalance';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getTaxReport(data: any) {
		var method = 'generalledger/taxreport';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('taxreport', data))
		);
	};

	public getCompressedTaxReport(data: any) {
		var method = 'generalledger/taxcompressed';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('taxreport', data))
		);
	};

	public getProfitAndLoss(data: any) {
		var method = 'generalledger/profitandloss';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public postJournalEntry(data: any) {
		var method = 'generalledger/journalentry';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getBalanceSheet(data: any) {
		var method = 'generalledger/balancesheet';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	private getApi() {
		return this.config.apiServer.crossPoint;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId + '&userid=' + this.user.user.userid + this.getPackage();
	}

	private getPackage() {
		let p = this.globalSearchService.getSessionItem('package');
		if(p) {
			return '&package='+p;
		} else {
			return '';
		}
	}

	public getKey() {
		return '&apikey=' + this.key + '&userid=' + this.user.user.userid;
	}

	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}

	private handleError < T > (operation = 'operation', result ? : T) {
		return (error: any): Observable < T > => {
			return error;
		};
	}
}
