import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit} from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { interval, Subscription} from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router} from '@angular/router';

import { InventoryService } from '../../../services/inventory.service';
import { GlobalsService } from '../../../services/globals.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { Customer } from '../../../classes/customer';
import { Location } from '@angular/common'

@Component({
  selector: 'app-bin-merge',
  templateUrl: './bin-merge.component.html',
  styleUrls: ['./bin-merge.component.scss']
})
export class BinMergeComponent implements OnInit {
	@Input() bins;
	@Output() binupdate;

	tobins: any = [];
	allbins: any = [];
	filteredbins: any = [];
	filteredtobins: any = [];
	transferBin = new UntypedFormControl('');
	filteredOptions: any = [];
	searching: boolean = false;
	search_available: string = '';
	search_current: string = '';
	neworcurrent: string = ''
	sending: boolean = false;
	merged: boolean = false;
	affected: any = false;
	onhandoly: boolean = true;
	constructor(private inventoryService : InventoryService) { }

	ngOnInit(): void {
		this.allbins = this.bins
		this.filteredbins = this.bins
		this.filteredOptions = this.bins;

		this.filteredbins.sort((a, b) => (a.location > b.location) ? 1 : (a.location === b.location) ? ((a.location > b.location) ? 1 : -1) : -1 )

		this.transferBin.valueChanges.subscribe(newValue=>{
			this.filteredOptions = this.filterValues(newValue);
			this.affected = false;
			this.newOrCurrentBin();
		})

	}

	toggleOnHandOnly() {
		this.onhandoly = (!this.onhandoly) ? true : false;
	}

	moveItems() {
		this.sending = true;
		this.inventoryService.mergeBins(this.tobins, this.transferBin.value).subscribe((results: any) => {

			this.sending = false;
			if(results.success) {

				let allitems = this.tobins.map( bin => {
					return bin.stockid
				});

				let message = "Bin(s): "+ allitems.join(',')+' Merged To '+this.transferBin.value;
				alert(message);

				this.allbins = results.bins
				this.filteredbins = results.bins
				this.filteredOptions = results.bins;
				this.tobins = [];
				this.filteredtobins = [];
				this.transferBin.setValue('');
				this.search_current = '';
				this.search_available = '';
				this.neworcurrent ='';
				this.merged = true;
				this.affected = results.affected;
			} else {
				this.merged = false;
				let allbins = this.tobins.map( bin => {
					return bin.location
				});
				let message = "MERGE FAILED! Bin(s): "+allbins.join(',')+' To '+this.transferBin.value;
				alert(message);
			}
		});
	}

	newOrCurrentBin() {
		let exists = this.allbins.filter(bin =>{
			return bin.location == this.transferBin.value
		})

		if(this.transferBin.value =='') {
			this.neworcurrent = '';
		} else {
			this.neworcurrent = (exists.length) ? 'Merge to ' +this.transferBin.value : 'Creating New Bin: '+this.transferBin.value
		}
	}

	moveTo(bin: any) {
		//did something stupid..
		this.affected = false;
		let index =this.allbins.indexOf(bin)
		this.allbins.splice(index, 1);

		index =this.filteredbins.indexOf(bin)
		this.filteredbins.splice(index, 1);

		this.tobins.push(bin)
		this.filteredtobins.push(bin)
		this.filteredbins.sort((a, b) => (a.location > b.location) ? 1 : (a.location === b.location) ? ((a.location > b.location) ? 1 : -1) : -1 )
	}

	moveFrom(bin: any) {
		this.affected = false;
		let index = this.tobins.indexOf(bin)
		this.tobins.splice(index, 1);
		this.filteredtobins.splice(index, 1);

		this.filteredbins.push(bin)
		this.filteredbins.sort((a, b) => (a.location > b.location) ? 1 : (a.location === b.location) ? ((a.location > b.location) ? 1 : -1) : -1 )
	}

	searchBin(event: any) {
		this.filteredbins = this.filterItem(this.allbins, event.target.value, 'location');
	}

	searchSelectedBin(event: any) {
		this.filteredtobins = this.filterItem(this.tobins, event.target.value, 'location');
	}

	filterItem(input: any, term: any , keys: any ){
		if(!term){
			//this.assignCopy(input);
		}
		var result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) &&	new RegExp(term, 'gi').test(item[key])));
		return result
	}

	filterValues(search: string) {
		return this.allbins.filter( (value) => {
			return value.location.toLowerCase().indexOf(search.toLowerCase()) === 0
		});
	}

}
