import { Component, SimpleChanges, OnChanges,OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform , Output, EventEmitter} from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective } from '@angular/forms';

import { FormGroup, FormControl } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';

@Component({
	selector: 'app-inventory-locations-create',
	templateUrl: './inventory-locations-create.component.html',
	styleUrls: ['./inventory-locations-create.component.scss'],
	animations: [
		trigger("grow", [ // Note the trigger name
			transition(":enter", [
				// :enter is alias to 'void => *'
				style({ height: "0", width: "0" }),
				animate(200, style({ height: "*", width: "*" })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(":leave", [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0, })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	]

})
export class InventoryLocationsCreateComponent implements OnInit {

	@Input() editing: any = false;
	@Output() complete = new EventEmitter <any> ();
	loccode: any = '';
	locselected: boolean = false;
	ld: any = [];
	term: any = '';

	taxgroup: any = '';
	taxes: any = [];
	currencies: any = [];
	bankaccounts: any = [];
	types: any = [];
	zones: any = [];
	locationForm: any = false;
	//todo move this to config
	config: any =[];

	constructor(private route: ActivatedRoute, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder, public globalsService: GlobalsService) {
		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		})
	}

	ngOnInit(): void {
		this.globalsService.getDefaultZones().subscribe(details => {
			this.zones = details;
		});
	}

	ngOnChanges(changes: any) {
		//todo make this bettter
		if(changes.editing.currentValue == '') {
			this.locationForm = this.fb.group({
				loccode: ['', [Validators.required]],
				locationname: ['', [Validators.required]],
				deladd1: ['', [Validators.required]],
				deladd2: ['', []],
				deladd3: ['', [Validators.required]],
				deladd4: [this.config.defaults.region, [Validators.required]],
				deladd5: ['', [Validators.required]],
				deladd6: [this.config.defaults.country, [Validators.required]],
				tel: ['', []],
				email: ['', []],
				contact: ['', [Validators.required]],
				fax: ['', []],
			});
		}

		if(this.editing) {
			this.locationForm = this.fb.group({
				loccode: [this.editing.loccode, [Validators.required]],
				locationname: [this.editing.locationname, [Validators.required]],
				deladd1: [this.editing.deladd1, [Validators.required]],
				deladd2: [this.editing.deladd2, []],
				deladd3: [this.editing.deladd3, [Validators.required]],
				deladd4: [this.editing.deladd4, [Validators.required]],
				deladd5: [this.editing.deladd5, [Validators.required]],
				deladd6: [this.editing.deladd6, [Validators.required]],
				tel: [this.editing.tel, []],
				email: [this.editing.email, []],
				contact: [this.editing.contact, [Validators.required]],
				fax: [this.editing.fax, []],
			});

			this.locationForm.get('loccode').disable();
		}
	}

	updateZones(value: any) {
		let thisid = this.zones.countries.filter(r => r.name == value.value)[0];
		this.globalsService.getCountryZones(thisid.country_id).subscribe((details:any) => {
			this.zones.zones = details.zone;
		});
	}

	updateLocation() {
		if (this.locationForm.valid) {
			this.globalsService.updateLocation(this.locationForm.value).subscribe(details => {
				this.complete.emit(true);
			});
		}
	}

	cancel() {
		this.complete.emit(true);
	}

	back(): void {
		this.location.back()
	}

	ngAfterViewInit() {

	}
}
