import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { ReportsService } from '../../services/reports.service';
import { CardLists, Card } from '../../classes/orders';
import { Router } from '@angular/router';
import { Ng2CompleterModule } from 'ng2-completer';
import { Location } from '@angular/common'
import { PrintService } from '../../services/print.service'
import * as FileSaver from 'file-saver';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { PurchasingService } from 'app/services/purchasing.service';

@Component({
  selector: 'app-spsls',
  templateUrl: './spsls.component.html',
  styleUrls: ['./spsls.component.scss']
})
export class SpslsComponent implements OnInit {
  @ViewChild("orderView") orderStatusViewRef: ElementRef;
  @ViewChild("details") detailsRef: ElementRef;
  @ViewChild('print_table_pdf') printtablepdf: ElementRef;
  @ViewChild('print_table_xlsx') printtablexlsx: ElementRef;
  @ViewChild('salesFilter') salesmanRef: MatSelect;
  @ViewChild('branchFilter') branchesRef: MatSelect;
  @ViewChild('brandFilter') brandsRef: MatSelect;
  itemSearchRef = new FormControl('');
  orderSearchRef = new FormControl('');
  conBrRef = new UntypedFormControl('C');
  placedFromRef = new UntypedFormControl('');
  branchRef = new UntypedFormControl('');
  salespersonRef = new UntypedFormControl('');
  brands = new UntypedFormControl('');
  filterresults: any = [];
  filteredItems: any = [];
  orderData: CardLists;
  customerData: any = [];
  openOrderData: any = [];
  orders: any = [];
  orderSearch: any = [];
  orderResults: any = false;
  ordersFound: any = [];
  todate = new Date();
  fromdate = new Date();
  reportCriteria = {
    fromdate: '',
    todate: '',
    locs: [],
    salesperson: [],
    conbr: '',
    brands: '',
    placedfrom: ''
  };

  @Input() name: string;
  color: any = 'blue';
  config: any = [];
  pickinglink: any = '';
  filename: any = 'SPSLS';
  invoicelink: any = '';
  displaydetails: any = false;
  orderdetails: any = false;
  fromdateCtrl = new UntypedFormControl('');
  todateCtrl = new UntypedFormControl('');
  sending: any = false;
  branches: any = [];
  salespeople: any = [];
  conbr: any = [{ label: 'Consolidated', value: 'C' }, { label: 'Detailed', value: 'B' }];
  placedfrom: any = [{ label: 'Included', value: '' }, { label: 'Excluded', value: '1' }, { label: 'Only', value: '2' }];
  properties: any = [];
  drilldown: any = {};

  ngOnInit(): void { }

  constructor(private globalSearchService: GlobalSearchService, private printService: PrintService, private globalsService: GlobalsService, public router: Router, private reportsService: ReportsService, private location: Location, private modalService: NgbModal, private purchasingService: PurchasingService) {

    this.color = this.globalSearchService.getColor();
    this.globalSearchService.configsubscription.subscribe(r => {
      this.config = r;
    });

    this.globalSearchService.mesagesallowed.next(false);

    this.reportsService.getBranches().subscribe((results: any) => {
      this.branches = results;
    });

    this.reportsService.getSalesPeople().subscribe((results: any) => {
      this.salespeople = results;
    });

    this.purchasingService.getSuppliers("").subscribe((results: any) => {
      this.properties = results

    })

    var today = new Date();
    var priorDate = new Date(today.getFullYear(), today.getMonth(), 1);
    this.fromdate = priorDate;

    this.fromdateCtrl.setValue(this.fromdate)
    this.todateCtrl.setValue(this.todate)
  }

  public getSPSLS() {
    this.reportCriteria = {
      fromdate: this.fromdateCtrl.value.toISOString().split('T')[0],
      todate: this.todateCtrl.value.toISOString().split('T')[0],
      locs: this.branchRef.value,
      conbr: this.conBrRef.value,
      brands: this.brandsRef.value,
      salesperson: this.salespersonRef.value,
      placedfrom: this.placedFromRef.value
    };

    this.sending = true;
    this.reportsService.getSPSLS(this.reportCriteria).subscribe(r => {
      this.orderResults = r;
      this.sending = false;
    })


  }

  viewSalesman(line: any) {
    this.drilldown = {
      sp: line['SALES ID'],
      loc: line['Location'],
      fromdate: this.reportCriteria.fromdate,
      todate: this.reportCriteria.todate,
      locs: line['Location'],
      salesperson: line['SALES ID'],
      vendor: line['VEND'],
      name: line['SALES NAME'],
      placedfrom: this.reportCriteria.placedfrom
    };


    this.reportsService.getSPSLSDrill(this.drilldown).subscribe((order: any) => {
      this.orderdetails = order
      this.modalService.open(this.orderStatusViewRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => { }, (reason) => {
        this.drilldown = {};
      });
    });
  }


  openDocument(transaction: any, content: any, display: string) {

    switch (transaction.DocType) {
      case 'Credit':
        this.globalsService.getCreditNote(transaction.id, display).subscribe((result: any) => {
          this.displaydetails = result;
          this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => { }, (reason) => { });
        })
        break;
      case 'Invoice':
        this.globalsService.getInvoice(transaction.id, display).subscribe((result: any) => {
          this.displaydetails = result;
          this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => { }, (reason) => { });
        })
        break;
    }
  }

  exportPdf() {
    let d = new Date();
    this.sending = true;
    let encoded: string = this.globalSearchService.base64encode(this.printtablepdf.nativeElement.innerHTML);
    let data = {
      content: encoded,
      filename: this.getFileName(),
      title: `Salesman Product Line Ranking Report ${this.reportCriteria.fromdate} - ${this.reportCriteria.todate} Created @ ${d.toLocaleString()}`,
      subtitle: this.getReportSubtitle()
    }

    this.printService.pdf(data).subscribe((result: any) => {
      this.globalSearchService.downloadPdf(result.content, data.filename);
      this.sending = false;
    });
  }



  exportXls() {
    let d = new Date();
    this.sending = true;
    let encoded: string = this.globalSearchService.base64encode(this.printtablexlsx.nativeElement.innerHTML);
    let data = {
      content: encoded,
      filename: this.getFileName(),
      title: `Salesman Product Line Ranking Report ${this.reportCriteria.fromdate} - ${this.reportCriteria.todate} Created @ ${d.toLocaleString()}`,
      subtitle: this.getReportSubtitle()
    }

    this.printService.xls(data).subscribe((result: any) => {
      this.globalSearchService.downloadXls(result.content, data.filename);
      this.sending = false;
    });
  }
  back(): void {
    this.location.back()
  }

  selectAllToggle(filter_name: string) {
    let filter;
    switch (filter_name) {
      case 'salesman':
        filter = this.salesmanRef;
        break;
      case 'branch':
        filter = this.branchesRef;
        break;
      case 'brand':
        filter = this.brandsRef;
        break;
      default:
        return;
    }
    const selected = filter.options.find(opt => opt.selected);
    if (selected) {
      filter.options.forEach((item: MatOption) => item.deselect());
    } else {
      filter.options.forEach((item: MatOption) => item.select());
    }
  }

  getReportSubtitle() {
    let out = "";

    this.reportCriteria.conbr == "C" ? out += "Consolidated Report " : out += "Detail Report ";

    if (this.reportCriteria.locs.length != this.branches.length && this.reportCriteria.locs.length > 0) {
      out += "For Location(s): ";
      this.reportCriteria.locs.length ? (this.reportCriteria.locs).map((loc) => out += (loc + " ")) : '';
    } else {
      out += "For All Locations ";
    }

    if (this.reportCriteria.salesperson.length != this.salespeople.length && this.reportCriteria.salesperson.length > 0) {
      out += "and Salesperson(s): ";
      this.reportCriteria.salesperson.length ? (this.reportCriteria.salesperson).map((person) => out += (person + " ")) : '';
    } else {
      out += "and All Salespeople";
    }

    if(this.reportCriteria.placedfrom == " "){
      out+=" Including Web Orders"
    }
    if(this.reportCriteria.placedfrom == "1"){
      out+=" Excluding Web Orders"
    }
    if(this.reportCriteria.placedfrom == "2"){
      out+=" Only Web Orders"
    }

    return out;
  }

  brandData(id: any, type){
		let out;
		let brand;
		brand = (this.properties).filter((i)=>{
			if(i.supplierid == id){
				return i;
			}
		})

		switch(type){
			case 'code':
				out =  brand[0].supplierid
			break;
			case 'name':
				out =  brand[0].suppname;
			break;
		}
		return out;
	}

  getFileName(){
    let out = "";
    let date = new Date();
    this.reportCriteria.conbr == "C" ? out += "Consolidated_SPLSLS" : out += "Detail_SPSLS";
    out += (date.getFullYear() + "" + (date.getUTCMonth() +1) +  "" + date.getDate());
    return out;
  }


}
