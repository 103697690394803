import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';

import { TrialBalanceComponent } from './trial-balance/trial-balance.component';
import { AccountInquiryComponent } from './account-inquiry/account-inquiry.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { ProfitLossComponent } from './profit-loss/profit-loss.component';
import { SalesTaxComponent } from './sales-tax/sales-tax.component';
import { BalanceSheetComponent } from './balance-sheet/balance-sheet.component';
import { JournalEntryComponent } from './journal-entry/journal-entry.component';
import { SalesTaxCompressedComponent } from './sales-tax-compressed/sales-tax-compressed.component';




@NgModule({
  declarations: [
    TrialBalanceComponent,
    AccountInquiryComponent,
    ProfitLossComponent,
    SalesTaxComponent,
    BalanceSheetComponent,
    JournalEntryComponent,
    SalesTaxCompressedComponent
  ],
  imports: [
    CommonModule,
	BrowserModule,
	RouterModule,
	BrowserAnimationsModule,
	ReactiveFormsModule,
	FormsModule,
	MatToolbarModule,
	MatButtonModule,
	MatDialogModule,
	MatDividerModule,
	MatInputModule,
	MatTableModule,
	MatCheckboxModule,
	MatSortModule,
	MatPaginatorModule,
	MatFormFieldModule,
	MatAutocompleteModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatExpansionModule,
	MatSelectModule,
	MatMenuModule,
	MatIconModule,
	MatSlideToggleModule,
	MatStepperModule,
	MatListModule,
	MatTabsModule,
	SharedModule,
  ]
})
export class GeneralLedgerModule { }
