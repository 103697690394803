<ng-container *ngIf="userForm">

	<div class="main-content">
		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12">
					<div class="card">
						<div class="card-header card-header-{{color}}-1">
							<h4 class="card-title"> <i class="material-icons" (click)="back()">arrow_left</i>Edit Profile</h4>
							<p class="card-category">Complete your profile</p>
						</div>
						<div class="card-body">
							<form [formGroup]="userForm" class="text-dark" (submit)="updateUser()">
								<div class="row">
									<div class="col-md-6">
										<mat-form-field class="full-width">
											<mat-label>User Id</mat-label>
											<input matInput formControlName="userid">
										</mat-form-field>
									</div>
									<div class="col-md-6">
										<mat-form-field class="full-width">
											<mat-label>Password</mat-label>
											<input matInput formControlName="password">
										</mat-form-field>
									</div>
									<div class="col-md-6">
										<mat-form-field class="full-width">
											<mat-label>Name</mat-label>
											<input matInput formControlName="realname">
										</mat-form-field>
									</div>
									<div class="col-md-6">
										<mat-form-field class="full-width">
											<input matInput formControlName="email" placeholder="Email address" type="email">
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<mat-form-field class="full-width" appearance="standard">
											<mat-label>Phone#</mat-label>
											<input matInput placeholder="Phone" type="text" formControlName="phone">
										</mat-form-field>
									</div>
									<div class="col-md-6">

										<mat-form-field appearance="standard" class="">
											<mat-label>Default Location</mat-label>
											<mat-select formControlName="defaultlocation">
												<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
													{{loc.loccode}} {{loc.locationname}}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>


								</div>
								<div class="row" *ngIf="user.fullaccess == '8'">
									<div class="col-md-6">
										<mat-form-field class="full-width">
											<input matInput placeholder="Full Access" formControlName="fullaccess" type="text">
										</mat-form-field>
									</div>
								</div>
								<div class="row" *ngIf="user.fullaccess == '8'">
									<div class="col-md-3">
										<mat-form-field appearance="standard" class="">
											<mat-label>Customer Admin</mat-label>
											<mat-select formControlName="customeradmin">
												<mat-option *ngFor="let yn of yesno" [value]="yn.value">
													{{yn.name}}
												</mat-option>
											</mat-select>
										</mat-form-field>

									</div>
									<div class="col-md-3">

										<mat-form-field appearance="standard" class="">
											<mat-label>Create Tender</mat-label>
											<mat-select formControlName="cancreatetender">
												<mat-option *ngFor="let yn of yesno" [value]="yn.value">
													{{yn.name}}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-3">

										<mat-form-field appearance="standard" class="">
											<mat-label>Create Tender</mat-label>
											<mat-select formControlName="editinv">
												<mat-option *ngFor="let yn of yesno" [value]="yn.value">
													{{yn.name}}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-3">
										<mat-form-field appearance="standard" class="">
											<mat-label>Dispatch Admin</mat-label>
											<mat-select formControlName="dispatchadmin">
												<mat-option *ngFor="let yn of yesno" [value]="yn.value">
													{{yn.name}}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>
								<div class="row d-none">
									<div class="col-md-12">
										<label>About Me</label>
										<mat-form-field class="full-width">
											<textarea matInput placeholder=""></textarea>
										</mat-form-field>
										<!-- <div class="form-group">

									  <div class="form-group">
										  <label class="bmd-label-floating"> Lamborghini Mercy, Your chick she so thirsty, I'm in that two seat Lambo.</label>
										  <textarea class="form-control" rows="5"></textarea>
									  </div>
								  </div> -->
									</div>
								</div>

								<div class="row mt-4 mb-4">
									<div class="col-12">
										<div class="col-2">
											<div class="card-header card-header-rec">
											</div>
										</div>
										<div class="col-2">
											<div class="card-header card-header-space">
											</div>
										</div>
									</div>
								</div>



								<a href="javascript:void(0)" class="profile-plugin switch-trigger active-color">
									<div class="ml-auto mr-auto">
										<span class="badge filter badge-purple" data-color="purple" (click)="changColor('purple')"></span>
										<span class="badge filter badge-azure" data-color="azure" (click)="changColor('azure')"></span>
										<span class="badge filter badge-green" data-color="green" (click)="changColor('green')"></span>
										<span class="badge filter badge-orange" data-color="orange" (click)="changColor('orange')"></span>
										<span class="badge filter badge-danger active" data-color="danger" (click)="changColor('danger')"></span>
									</div>
									<div class="clearfix"></div>
									<div class="ripple-container"></div>
								</a>




								<button mat-raised-button type="submit" class="btn btn-danger pull-right">Update Profile</button>
								<div class="clearfix"></div>
							</form>
						</div>
					</div>
				</div>
				<div class="col-md-4 d-none">
					<div class="card card-profile">
						<div class="card-avatar">
							<a href="javascript:void(0)">
								<img class="img" src="./assets/img/faces/marc.jpg" />
							</a>
						</div>
						<div class="card-body">
							<h6 class="card-category text-gray">CEO / Co-Founder</h6>
							<h4 class="card-title">{{ userdetails.realname }}</h4>
							<p class="card-description">
								Don't be scared of the truth because we need to restart the human foundation in truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is...
							</p>
							<a href="javascript:void(0)" class="btn btn-danger btn-round">Follow</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
