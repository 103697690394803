import { Component, OnDestroy, SimpleChanges, OnChanges , OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, Pipe, PipeTransform, EventEmitter,ViewEncapsulation} from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


import { trigger, state, style, transition, animate, animation } from '@angular/animations';
import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { UsersService } from '../../services/users.service';
import { PurchasingService } from '../../services/purchasing.service';
import { PaymentsService } from '../../services/payments.service';
import { OmsService } from '../../services/oms.service';


@Component({
	selector: 'app-shared-order-entry',
	templateUrl: './shared-order-entry.component.html',
	styleUrls: ['./shared-order-entry.component.scss'],
	//encapsulation: ViewEncapsulation.Emulated,
	//encapsulation: ViewEncapsulation.None,
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('300ms ease-in')),
			transition('active => inactive', animate('300ms ease-out')),
		]),
		trigger('cinfo', [
			transition(':enter', [
				animate('50ms', style({ opacity: 1, height: 100 })),
			]),
			transition(':leave', [
				animate('100ms', style({ opacity: 1, height: 0 }))
			]),
		]),
		trigger('itemSearch', [
			transition(':enter', animation([style({ transform: 'translate(-800px,0)', }),
				animate('0.2s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(1200px,0)', })
				),
			])),
		]),
		trigger('addFilters', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
		trigger('navIn', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.10s cubic-bezier(0.59, 0.32, 0.38, 0.33)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.0s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
	],
})
export class SharedOrderEntryComponent implements OnInit {

	@Input() customer: any = false;
	@Input() flip: any = 'inactive';
	@Input() type: any = '10';
	@Input() invoicing: any = false;
	@Output() reloadCustomer = new EventEmitter <any> ();

	CHAT_ROOM = "OrderBoard";
	sending: boolean = false
	config: any = [];
	user:any = [];
	cart_items: any = [];
	proceed: any = true;
	total_cart_qty: number = 0;
	cart_totals: any = [];

	overcredit: any = false;
	allowed_credit: any = true;
	overcredit_override: any = true;
	editing_order: any = false;
	order_details: any = false;

	//orderResults: any = false;
	customer_form: any = false;

	po_vendors:any = [];
	order: any = false;
	created_pos: any = false;
	extra_pos: any = false;
	order_lines: any = false;
	loading_remote: boolean = false;
	orderno: any = false;
	remoteqtys: any = [];

	purchase_item:any = false;
	purchordercreated_details:any = false;
	purchasedetails:any = false;
	purchordercreated:any = false;

	baselink: any = '';
	pickingInvoiceLink: any = '';

	orderDetails: any = false;
	orderResults: any = false;
	invoiceResults: any = false;

	anydata = {
		"success": true,
		"orderno": "754076",
		"transno": null,
		"order": "754076",
		"id": null
	};

	freight_charge: any = 0.00;

	fetchingTotals: any = false;
	fetchingCart : any = false;
	purchase_items: any = [];
	customerdata: any = [];
	//deposits

	payments: any = [];
	payments_added: any = [];
	payment_total: number = 0.00;
	loadingcards: boolean = false;


	constructor(private purchasingService: PurchasingService, private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, public omsService: OmsService, private fb: UntypedFormBuilder, private modalService: NgbModal, public usersService:UsersService,private paymentsService: PaymentsService) {

		//private globalsService: GlobalsService,
		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		});

		this.globalSearchService.reloadorder.subscribe(r=>{
			if(r) {
				this.resetOrder();
			}
		});

		this.baselink = this.config.apiServer.baseUrl + this.config.apiServer.pickingLink;
		this.pickingInvoiceLink = this.config.apiServer.baseUrl + this.config.apiServer.pickingInvoice;

		//this.omsService.subscribeToCarts((err, data) => {
		//	if (data.data[0].customer == this.customer.debtorno) {
		//		this.orderService.getCart(this.customer.debtorno, this.customer.branchcode).subscribe(async (items: any) => {
		//			this.cart_items = items;
		//		});
		//	}
		//})

		this.globalSearchService.user.subscribe((results: any) => {
			if (results) {
				this.user = results.user;
			}
		});
	}

	showInvoiceResultandReset(event:any) {
		//this.invoiceResults = event;
		this.globalSearchService.showNotification('Invoice #'+ event.transno + ' Created', 'success', 'bottom', 'left');

		this.resetOrder();
	}

	resetOrder() {
		this.invoicing = false;

		this.orderResults = false;
		this.order_details = false;

		this.editing_order = false;
		this.customer.preselected = false;
		this.customer.dissallowinvoices = false;
		this.clearCart();
		this.updateTotals();

		this.reloadCustomer.emit('cleared');

	}

	updateShipping(value: any) {
		this.freight_charge = value
		this.updateTotals();
	}

	updateCustomer(event: any) {
		this.customer_form = event
	}

	invoiceOrder(orderno: any) {
		this.invoicing = orderno;
		this.orderResults = false;
	}

	addPromo(promo: any) {
		this.orderService.addPromo(promo, this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {
			if (results.success) {

				this.cart_items = results.cart;
				this.updateTotals();
				this.globalSearchService.showNotification(results.message, 'success', 'bottom', 'right')

			} else {
				this.globalSearchService.showNotification(results.message, 'danger', 'bottom', 'left');
			}
		});

	}
	addItems(items: any) {

		this.orderService.addMultipleToOrder(items, this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {

			if (results.success) {
				this.cart_items = results.cart;


				//this.updateTotals();

				let components = [];
				items.forEach((item, index) => {

					//reset qtys and show notification
					if (parseFloat(item.quantity) != 0) {
						let color = (item.isnegative) ? 'warn' : 'success';
						this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Added', color, 'bottom', 'left');
					}
				});

				this.updateTotals();

			} else {
				this.globalSearchService.showNotification(results.message, 'danger', 'bottom', 'left');
			}
		});
	}

	removeFromOrder(cart_id) {
		this.orderService.removeFromOrder(cart_id, this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {
			this.cart_items = results;
			this.globalSearchService.showNotification('Item Removed', 'danger', 'bottom', 'left');
			this.updateTotals();
			this.sendCartUpdate();
		});
	}

	updatePrice(event: any, item: any) {
		let index = this.cart_items.indexOf(item);
		this.cart_items[index].price = event.target.value;
		this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
		this.updateCartItem(this.cart_items[index]);
	}

	updateQuantity(event: any, item: any) {
		let index = this.cart_items.indexOf(item);
		this.cart_items[index].quantity = event.target.value;
		this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
		this.updateCartItem(this.cart_items[index]);
	}

	updateDiscount(event: any, item: any) {
		let index = this.cart_items.indexOf(item);

		if(parseFloat(event.target.value) < 100) {
		} else {
			event.target.value = 100;
		}

		this.cart_items[index].discount = event.target.value;
		this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
		this.updateCartItem(this.cart_items[index]);

	}

	updateNote(event: any, item: any) {
		let index = this.cart_items.indexOf(item);
		this.cart_items[index].notes = event.target.value;
		this.updateCartItem(this.cart_items[index]);
	}

	getDiscounPrice(item: any) {
		var total = (item.price - (item.price * (item.discount / 100)));
		// if(total < 0) {
		// 	total = 0;
		// }
		return total;
	}

	getDiscountTotal(item: any) {
		var total = item.quantity * (item.price - (item.price * (item.discount / 100)));
		// if(total < 0) {
		// 	total = 0;
		// }
		return total;
	}

	updateCartItem(item: any) {

			let data = {
				customer: this.customer.debtorno,
				branch: this.customer.branchcode,
				cartid: item.cart_id,
				cart_id: item.cart_id,
				price: item.price,
				option: item.option,
				quantity: item.quantity,
				discount: item.discount,
				notes: item.notes,
				stockid: item.stockid,
				coupon_used: item.coupon_used,
				editing_order: this.editing_order,
				order_details: this.order_details,
			};



			this.orderService.updateOrder(data).subscribe((results: any) => {
				this.updateTotals();
				//this.updateCart();
				//item.cart_id
				this.updateChildren();

			});
	}
	//if input is not actually the child? cartid: any not sure this is appropriate..
	updateChildren() {
		this.cart_items.forEach( (item: any) => {
			if(item.parent_id) {
				let parent = this.cart_items.filter(i => i.cart_id == item.parent_id)[0]
				//will not work for pers
				item.quantity = parent.quantity;
			}
		})
	}

	updateTotals() {

		if(this.fetchingTotals) {
			this.fetchingTotals.unsubscribe();
		}

		this.fetchingTotals = this.orderService.getTotals(this.customer.debtorno, this.customer.branchcode, this.freight_charge).subscribe((results: any) => {
		// this.customer_form.get('reference').setValue(event.header.customerref);
		// this.customer_form.get('brname').setValue(event.header.deliverto);
		// this.customer_form.get('braddress1').setValue(event.header.deladd1);
		// this.customer_form.get('braddress2').setValue(event.header.deladd2);
		// this.customer_form.get('braddress3').setValue(event.header.deladd3);
		// this.customer_form.get('braddress4').setValue(event.header.deladd4);
		// this.customer_form.get('braddress5').setValue(event.header.deladd5);
		// this.customer_form.get('braddress6').setValue(event.header.deladd6);

			this.cart_totals = results;
			this.total_cart_qty = this.cart_items.reduce(function(accumulator: number, items: any) {
				var counter = 0;
				if(items.mbflag != 'F') {
					counter = parseFloat(items.quantity);
				}
				return accumulator + counter;
			}, 0);

			if(!this.total_cart_qty) {
				this.flip = 'inactive';
			}

			this.checkCreditLimit()
		});

	}

	getTotal() {

		let total = this.cart_totals.filter(t => {
			return t.code=='total';
		})[0]

		var value = 0.00;
		if(total) {
			value = parseFloat(parseFloat(total.text).toFixed(2))
		}

		return value;

	}

	checkCreditLimit() {
		if (!this.allowed_credit) {
			this.proceed = true;
			this.overcredit = false;
			let subtotal = this.cart_totals.filter(t => {
				return t.code == 'sub_total'
			})[0]

			if (parseFloat(subtotal.text) > this.customer.credit_avail) {
				this.proceed = false;
				this.overcredit = true;
				if (this.overcredit_override == '') {
					this.orderService.flagCart(this.customer.debtorno, this.customer.branchcode).subscribe((result: any) => {
						this.overcredit_override = result
					})
				}
			}
		}
	}

	stopProp(event: any): void {
		event.stopPropagation();
	}

	sendCartUpdate() {

		if (this.user) {
			let data = [{ customer: this.customer.debtorno, user: this.user }]
			this.omsService.sendCartUpdate({ data, roomName: 'CartRoom' }, cb => {});
		}
	}

	toggleFlip() {

		switch (this.flip) {
			case 'inactive':
				this.flip = 'active';
				break;
			default:
				this.flip = 'inactive';
			break;
		}
	}

// 	reloadOrder(event: any) {
//
// 		if(this.fetchingCart) {
// 			this.fetchingCart.unsubscribe();
// 		}
// 		if(this.customer.debtorno) {
// 			this.fetchingCart = this.orderService.getCart(this.customer.debtorno, this.customer.branchcode).subscribe(async (items: any) => {
//
// 				//this.selectedindex = 0;
// 				this.editing_order = true;
//
// 				this.order_details = event;
// 				this.cart_items = items;
// 				if (items.length) {
//
// 					if (!items[0].override || items[0].override == '') {
// 						//order was not flagged - do not stop it
// 						this.proceed = true;
// 						this.allowed_credit = true;
// 						this.overcredit_override = items[0].override
//
// 					}
// 				}
//
// 				this.updateTotals();
// 			});
// 		}
// 	}

	ngOnInit(): void {

	}

	ngOnChanges(changes: any) {
		// if(changes.customer.currentValue && changes.customer.currentValue.length) {
			//if(changes.customer.currentValue != this.customer) {
				this.updateCart();
			//}
		//}
	}

	emitCustomerUpdate(event: any) {
		this.invoicing = false;
		this.reloadCustomer.emit(event);
	}

	saveQuote() {

		if(this.editing_order) {
			this.editing_order = true;
			var continueconfirm = confirm("This is an open order, save as a quote and cancel order?");
			if(continueconfirm) {
				this.orderService.cancelOrder(this.order_details.header.orderno).subscribe((results: any) => {
				});
			} else {
				return false;
			}
		}


		this.orderService.saveQuote(this.customer, this.cart_items, this.cart_totals, this.user).subscribe((result: any) => {
			this.globalSearchService.showNotification('Quote '+result.orderno+' Saved', 'success', 'bottom', 'left');
			this.reloadCustomer.emit(true)
			this.flip = 'inactive';
			this.orderDetails = false;
			this.resetOrder();
		});
	}

	clearCart() {
		this.orderService.clearOrder(this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {
			this.flip = 'inactive';
			this.updateCart();
		});
	}

	cancelOrder() {

		this.orderService.cancelOrder(this.order_details.header.orderno).subscribe((results: any) => {
			this.flip = 'inactive';
			//update sends
			let data = { user: this.user,}
			this.omsService.sendOrderPickUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});

			this.clearCart();
			this.resetOrder();
		});
	}

	placeOrder() {


		//this.checkCredit();
		this.sending = true;
		this.globalSearchService.getFormValidationErrors(this.customer_form);
		this.customer_form.markAllAsTouched();

		this.allowed_credit = true;
		/*
		if (!this.proceed && !this.allowed_credit) {
			this.modalService.open(this.creditover, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

			}, (reason) => {
				this.item_details = false;
			});
		}
		*/
		//customer form is emitted in
		if (this.customer_form.valid && this.proceed) {

			if (!this.editing_order) {

				this.orderService.createOrder(this.customer_form.value, this.cart_items, this.cart_totals, this.user).subscribe((results: any) => {
					this.sending = false;
					//this.globalSearchService.orderResults.next(results);
					this.orderResults = results;
					const order_results = results;
					this.flip = 'inactive';
					this.orderDetails = results;
					this.reloadCustomer.emit(results);
					this.editing_order = false;
					this.customer.preselected = false;
					this.orderService.clearOrder(this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {
						this.cart_items = [];
						this.total_cart_qty = 0;
						let data = { customerdata: this.customer_form.value,  editing: false, neworder: true, orderdetails: order_results, user: this.user };
						this.omsService.sendOrderPickUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
						//this.router.navigate(['/orders/invoice/' + this.orderResults.orderno]);
					});
				});
			} else {

				this.orderService.updateSalesOrder(this.customer_form.value, this.cart_items, this.cart_totals, this.order_details, this.user).subscribe((results: any) => {
					this.sending = false;
					this.flip = 'inactive';
					//this.globalSearchService.orderResults.next(results);
					this.orderResults = results;
					const order_results = results;
					this.flip = 'inactive';
					this.orderDetails = results;
					this.reloadCustomer.emit(results);

					this.editing_order = false;
					this.customer.preselected = false;
					this.customer.editingorder = false;
					let data = {};
					this.orderService.clearOrder(this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {
						this.cart_items = [];
						this.total_cart_qty = 0;

						let data = {customerdata: this.customer_form.value, editing: true,  neworder: false, orderdetails: order_results, user: this.user };
						this.omsService.sendOrderPickUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
					});
				});
			}
		} else {
			this.sending = false;
		}

		// this.updateCart()
	}

	viewPurchOrderOptions(line: any) {
		line.allownegative = true;
		let data = { cart_id: line.cart_id, stockid: line.stockid }
		this.orderService.getPurchDataFromLine(data).subscribe((results: any) => {
			this.purchase_items.push(results);
			this.modalService.open(this.purchasedetails, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result:any) => {

			}, (reason) => {
				this.purchase_items = [];
			});
		});
	}

	updateCart() {

		if(this.fetchingCart) {
			this.fetchingCart.unsubscribe();
		}

		if(this.customer.debtorno) {
			this.fetchingCart = this.orderService.getCart(this.customer.debtorno, this.customer.branchcode).subscribe( (items: any) => {

				var cart_items = items;
				if(items.length) {
					this.flip ='active';
				}
				//current cart tied to a sales order - user loaded an order and left
				let hasorderno = items.map(item => { return (item.orderno != 0) ? item.orderno : false })[0];
				//todo rework this - doubles up on the calls
				if (hasorderno && !this.order_details.header) {
					this.orderService.loadSalesOrder(hasorderno).subscribe((results: any) => {
						this.editing_order = true;
						this.customer.editingorder = results.header;

						this.customer.preselected = results.preselected;
						this.order_details = results;
						this.cart_items = results.cart;
						if(results.cart.length) {
							this.flip ='active';
						}
						this.updatePayments(false);
					});
				} else {
					this.cart_items = items;
				}

				this.updateTotals();
			});
		}
	}

	editOrder(orderno: any) {
		this.sending = false;
		this.orderResults = false;
		this.orderService.loadSalesOrder(orderno).subscribe((results:any) => {

			this.editing_order = true;
			this.order_details = results;

			this.customer.preselected = results.preselected;
			this.fetchingCart = this.orderService.getCart(results.header.debtorno, results.header.branchcode).subscribe(async (items: any) => {

				this.editing_order = true;
				this.order_details = results;

				this.cart_items = items;
				this.customer.editingorder = results.header
				this.customer.preselected = results.preselected;

				if (items.length) {

					if (!items[0].override || items[0].override == '') {
						//order was not flagged - do not stop it
						this.proceed = true;
						this.allowed_credit = true;
						this.overcredit_override = items[0].override
					}
				}

				this.updateTotals();
				this.updatePayments(false);
			});

			this.flip = 'active';
		})
	}

	//deposits
	updatePayments(event: any) {

		if(event) {
			this.payments_added = event;
			this.payment_total = this.payments_added.reduce(function(accumulator, item) {
				return parseFloat(accumulator) + parseFloat(item.amount);
			}, 0);
		}

		let data = {
			orderno: (this.order_details) ? this.order_details.header.orderno : '1',
			debtorno: this.customer.debtorno,
			branchcode: this.customer.branchcode,
		}

		this.paymentsService.getOrderPayments(data).subscribe((results: any) => {
			this.payments = results;
			this.updateTotals()

			this.payment_total = this.financial(results.reduce(function(accumulator, item) {
				return parseFloat(accumulator) + parseFloat(item.amount);
			}, 0));

		});

	}

	financial(x) {
		return parseFloat(Number.parseFloat(x).toFixed(2));
	}
}
