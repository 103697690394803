<div class="card mt-2">
	<div class="card-body">
			<div class="row">
				<div class="form-group ml-auto mr-auto col-4">
					<mat-form-field appearance="standard" class="">
						<mat-label>Brand</mat-label>
						<mat-select [formControl]="brand" (selectionChange)="search()" multiple>
							<mat-option *ngFor="let k of brands" [value]="k.manufacturer_id">
								{{ k.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="form-group ml-auto mr-auto  col-4">
					<mat-form-field appearance="standard">
						<mat-label>Category</mat-label>
						<mat-select [formControl]="category" multiple="true" (selectionChange)="search()">
							<mat-option *ngFor="let c of categories" [value]="c.categoryid">
								{{ c.categorydescription }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="form-group ml-auto mr-auto col-4">
					<mat-form-field appearance="standard">
						<mat-label>Product Line</mat-label>
						<mat-select [formControl]="productLine" multiple="true" (selectionChange)="search()">
							<mat-option *ngFor="let p of productlines" [value]="p.line_field">
								{{ p.line_description }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

			</div>
			<div class="row">
				<div class="col-md-6 ml-auto mr-auto ">
					<mat-form-field appearance="standard">
						<mat-label>Range Start</mat-label>
						<input autofocus autocomplete="off" aria-hidden="false" matInput [formControl]="rangestart" required value="" class="text-right" (keyup.enter)="$event.preventDefault(); "  (input)="search()">
					</mat-form-field>
				</div>
				<div class="col-md-6 ml-auto mr-auto ">

					<mat-form-field appearance="standard">
						<mat-label>Range End</mat-label>
						<input autofocus autocomplete="off" aria-hidden="false" matInput [formControl]="rangeend" required value="" class="text-right" (keyup.enter)="$event.preventDefault(); "  (input)="search()">
					</mat-form-field>
				</div>
			</div>
			<div class="row">

				<div class="col-md-12">
					<mat-form-field appearance="standard">
						<mat-label>Specific Item</mat-label>
						<input autocomplete="off" aria-hidden="false" matInput [formControl]="keywords" required value="" class="text-right" (keyup.enter)="$event.preventDefault();">
					</mat-form-field>
					<ng-container *ngIf="keywordresults">
						<table>
							<tr *ngFor="let r of keywordresults" (click)="addMMItem(r)">
								<td>{{ r.stockid }}</td>
								<td>{{ r.description }}</td>
							</tr>
						</table>
					</ng-container>
				</div>
				<div class="col-12 border-1 raised">
					<h4>Items</h4>
					<div class="table table-fluid table-responsive bg-light">
						<table class="table table-fixed" *ngFor="let r of selectedproducts">
							<thead>
								<th>ITEM</th>
								<th>DESC</th>
								<th></th>
							</thead>
							<tbody>
							<tr>
								<td> {{ r.stockid }}</td>
								<td> {{ r.description }}</td>
								<td><a class="remove text-danger" (click)="removeItem(r)">Remove</a></td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div class="table table-sm  table-fluid container-fluid">
					<h4>Exclusions</h4>
					<table class="table table-fluid table-sm table-striped table bordered" *ngIf="exclusions">
						<thead>
							<tr>
								<th>Item#</th>
								<th>Description</th>
								<th></th>
							</tr>
						</thead>
						<tbody id="exclusions">
							<ng-container >
								<tr *ngFor="let p of exclusions">
									<td>{{ p.stockid }}</td>
									<td>{{ p.description }}</td>
									<td><a class="remove text-danger" (click)="removeExclusion(p)">Remove</a></td>
								</tr>
							</ng-container>
						</tbody>
					</table>
				</div>

			</div>
		</div>
</div>
<div class="card mt-2">
	<div class="card-body">
		<div class="table table-sm  table-fluid container-fluid" *ngIf="results">
			<h4>Items Affected</h4>
			<table class="table table-fluid table-sm table-striped table bordered">
				<thead>
					<tr>
						<th>Item#</th>
						<th>Description</th>
						<th>DWO</th>
						<th>Discontinued</th>
						<th>QOH</th>
						<th></th>
					</tr>
				</thead>
				<tbody id="mmitems">
					<ng-container >
						<tr *ngFor="let p of results">
							<td>{{ p.stockid }}</td>
							<td>{{ p.description }}</td>
							<td>{{ p.dwo }}</td>
							<td>{{ p.discontinued }}</td>
							<td>{{ p.locstock }}</td>

							<td><a class="remove text-danger" (click)="exludeItem(p)">Exclude</a></td>
						</tr>
					</ng-container>
				</tbody>
			</table>
		</div>
	</div>
</div>
