<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<!--
		<mat-chip-set role="list">
			<mat-chip role="listitem" *ngFor="let l of viewedtransactions">
				<button mat-menu-item (click)="viewPayments(l,details)">
					<mat-icon>credit_card</mat-icon>
					<span>Payments</span>
				</button>
			</mat-chip>
		</mat-chip-set>
		-->

		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<app-customer-aging [aged]="customerdata.aged"></app-customer-aging>

<div class="table table-sm">
	<div class="row" *ngIf="transactions.length">
		<div class="col-md-8">
			&nbsp;
		</div>
		<div class="col-md-4 text-right">
			<mat-form-field appearance="standard">
				<mat-label>Transaction Dates</mat-label>
				<mat-date-range-input [rangePicker]="picker">
					<input matStartDate placeholder="Start date" [formControl]="fromDateControl"
					>
					<input matEndDate placeholder="End date" [formControl]="toDateControl">
				</mat-date-range-input>
				<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-date-range-picker #picker></mat-date-range-picker>

				<span matSuffix>
					<button mat-icon-button color="white" type="submit" (click)="loadTransactions()">
						<mat-icon class="p-0">search</mat-icon>
					</button>
				</span>

			</mat-form-field>
		</div>
	</div>
	<div class="table mt-3">
		<table class="table table-fixed table-sm table-hover" *ngIf="transactions.length ">
			<thead>
			<tr>
				<th></th>
				<th></th>
				<th>Invoice#</th>
				<th>Type</th>
				<th>Date</th>
				<th class="text-center">Order#</th>
				<th class="td-max">Reference</th>
				<th class="text-right">Total</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let tran of transactions" >
			<tr>
				<td>
					<button mat-icon-button *ngIf="tran.type == '10' || tran.type == '11'" (click)="openDocument(tran,details,'html')">
						<mat-icon>visibility</mat-icon>
					</button>
				</td>
				<td>
					<button mat-stroked-button color="accent" (click)="loadCredit(tran.id)">
						Credit
					</button>
				</td>
				<td>{{tran.transno }}</td>
				<td>{{tran.typename }}</td>
				<td>{{tran.trandate }}</td>
				<td class="text-center">{{tran.order_ }}</td>
				<td class="td-max">{{tran.invtext }}</td>
				<td class="text-right">{{tran.totalamount | currency }}</td>
			</tr>
			</ng-container>
			</tbody>
		</table>
		<div class="row">
			<div class="col-12 text-center" *ngIf="!transactions.length">
				<h4><b>No Transactions Found</b></h4>
			</div>
		</div>
	</div>
</div>
