import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, Injectable, ElementRef } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { DataService } from '../../data.service';
import { ChatService } from '../../services/chat.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';
import { DailySales } from '../../classes/reports';

import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

import { Message } from '../../classes/message';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { UsersService } from '../../services/users.service';
import { InventoryService } from '../../services/inventory.service';
import { PrintService } from '../../services/print.service'
import { trigger, state, style, transition, animate } from '@angular/animations';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-inventory-transfers',
	templateUrl: './inventory-transfers.component.html',
	styleUrls: ['./inventory-transfers.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(360deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(180deg)'
			})),
			transition('active => inactive', animate('500ms ease-out')),
			transition('inactive => active', animate('500ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		])
	]
})
export class InventoryTransfersComponent implements OnInit {

	transferlist: any = [];
	color: string = 'blue';
	flip: string = 'inactive';
	displaydetails: any = '';
	viewedtransactions: any = [];
	statuses: any = [
		{ 'code': '0', 'description': ' Entered ' },
		{ 'code': '1', 'description': ' Released ' },
		{ 'code': '2', 'description': ' Received ' },
		{ 'code': '3', 'description': ' Canceled ' }
	]
	editingtransfer: any = '';
	locations: any = [];
	alllocations: any = [];
	searchForm: UntypedFormGroup;
	config: any = false;

	constructor(private fb: UntypedFormBuilder, private modalService: NgbModal, private globalsService: GlobalsService, private globalSearchService: GlobalSearchService, private inventoryService: InventoryService) {
		this.config = this.globalsService.getApiConfig();

	}

	ngOnInit(): void {
		this.color = this.globalSearchService.getColor();
		this.globalsService.getUserLocations().subscribe((results: any) => {
			if (results) {
				this.locations = results;
			}
		});

		this.globalsService.getAllLocations().subscribe((results: any) => {
			if (results) {
				this.alllocations = results;
			}
		});
		this.searchForm = this.fb.group({
			'fromloc': '',
			'toloc': '',
			'startdate': '',
			'enddate': '',
			'status': ''
		});
		this.getTransfers();
	}

	addTransfer() {
		this.flip = 'active';
	}
	tComp(event) {
		if(event != ''){
		this.globalSearchService.showNotification('Transfer #' + event + ' Created', 'success', 'bottom', 'left');
		} else {
		this.globalSearchService.showNotification('Changes Saved', 'success', 'bottom', 'left');
		}
		this.editingtransfer = '';
		this.flip = 'inactive';
	}
	back() {
	}
	getTransfers() {

		this.inventoryService.getTransfers(this.searchForm.value).subscribe((results: any) => {
			if (results) {
				this.transferlist = results;
			}
		});
	}
	editTransfer(transno){

		this.flip = 'active';
		this.editingtransfer = transno;
	}

	openDocument(transaction: any, content: any, display: string) {
		this.viewedtransactions.push(transaction);

				this.globalsService.getTransfer(transaction.transno, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
				});

	}

}
