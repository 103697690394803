import { Component, OnDestroy, OnInit,ViewChild,ChangeDetectorRef,ElementRef} from '@angular/core';
import { CustomerService } from '../../services/customer.service';
import { Location } from '@angular/common'

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { ActivatedRoute, Router} from '@angular/router';

import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { merge, fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

@Component({
  selector: 'app-archive-list',
  templateUrl: './archive-list.component.html',
  styleUrls: ['./archive-list.component.scss']
})
export class ArchiveListComponent implements OnInit {

	color : string = 'blue';
	title = 'Archives';
	ready: boolean;
	noresults: boolean;

	customers: any = [];
	all_customers: any = [];
	results: any = [];


	constructor(private customerService: CustomerService, private location: Location,public cdr: ChangeDetectorRef,private globalSearchService: GlobalSearchService,public router: Router) { }

	ngOnInit(): void {
		this.ready = false;
		this.noresults = true;
		this.globalSearchService.mesagesallowed.next(true);
		this.color = this.globalSearchService.getColor();
	}

	back(): void {
		this.location.back()
	}



	announceSortChange(sortState: Sort) {

	}

}
