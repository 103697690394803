<ng-template #orderDetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">RGA</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<mat-tab-group #tabPanel mat-align-tabs="start" color="accent" class="no-overflow">
			<mat-tab label="Purchase Order">
				<div class="mb-3" *ngIf="podetails">
					<h4 class="mt-3">RGA # {{ podetails.poheader.orderno }}</h4>
					<div class="table">
						<table class="table table-sm">
							<tr>
								<td>
									{{podetails.poheader.suppliercontact}}<br>
									{{podetails.poheader.suppdeladdress1}}<br>
									{{podetails.poheader.suppdeladdress2}}<br>
									{{podetails.poheader.suppdeladdress3}}, {{podetails.poheader.suppdeladdress4}} {{podetails.poheader.suppdeladdress5}}
								</td>
								<td>
									{{podetails.poheader.deladd1}}<br>
									<span *ngIf="podetails.poheader.deladd2 != ''">{{podetails.poheader.deladd2}}<br></span>
									{{podetails.poheader.deladd3}}, {{podetails.poheader.deladd4}} {{podetails.poheader.deladd5}}
								</td>
							</tr>
						</table>
						<div class="card">
							<div class="card-body" [innerHTML]="podetails.poheader.stat_comment"></div>
						</div>
						<table class="table table-sm ">
							<tr>
								<th>ITEM</th>
								<th>DESC</th>
								<th class="text-center">QTY</th>
								<th class="text-center">RCVD</th>
								<th class="text-center">PEND</th>
								<th class="text-right">PRICE</th>
								<th class="text-right">TOTAL</th>
							</tr>
							<tr *ngFor="let item of podetails.polines">
								<td><a [routerLink]="'/inventory/view/'+item.itemcode" (click)="modal.dismiss('route change')">{{item.itemcode}}</a></td>
								<td>{{item.itemdescription}}</td>
								<td class="text-center">{{item.quantityord}}</td>
								<td class="text-center">{{item.quantityrecd}}</td>
								<td class="text-center">{{item.quantityord - item.quantityrecd}}</td>
								<td class="text-right">{{item.unitprice | currency}}</td>
								<td class="text-right">{{item.unitprice * item.quantityord | currency}}</td>
							</tr>
						</table>
					</div>
				</div>
			</mat-tab>
			<mat-tab label="Receiving Details">
				<ng-container *ngIf="podetails.receiving.length">
					<table class="table table-fixed table-sm">
						<tr>
							<th>GRN</th>
							<th>Order#</th>
							<th>Item#</th>
							<th>Description</th>
							<th>Received</th>
							<th>Invoiced</th>
							<th>Date</th>
						</tr>
						<tr *ngFor="let r of podetails.receiving">
							<td>{{ r.grnno }}</td>
							<td>{{ r.poorderno }}</td>
							<td>{{ r.itemcode }}</td>
							<td>{{ r.itemdescription }}</td>
							<td>{{ r.qtyrecd }}</td>
							<td>{{ r.quantityinv }}</td>
							<td>{{ r.update_date }}</td>
						</tr>
					</table>
				</ng-container>
				<ng-container *ngIf="!podetails.receiving.length">
					No Receiving Details
				</ng-container>
			</mat-tab>
			<mat-tab label="Receiving Movements">
				<ng-container *ngIf="podetails.movements.length">
					<table class="table table-fixed table-sm">
						<tr>
							<th>Item#</th>
							<th>Location</th>
							<th>User</th>
							<th>Quantity</th>
							<th>QOH</th>
							<th>Date</th>
						</tr>
						<tr *ngFor="let r of podetails.movements">
							<td>{{ r.stockid }}</td>
							<td>{{ r.loccode }}</td>
							<td>{{ r.userid }}</td>
							<td>{{ r.qty }}</td>
							<td>{{ r.newqoh }}</td>
							<td>{{ r.trandate }}</td>
						</tr>
					</table>
				</ng-container>
				<ng-container *ngIf="!podetails.movements.length">
					No Movements
				</ng-container>
			</mat-tab>
		</mat-tab-group>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<div class="row">
	<div class="col-2 text-left mt-3">
		<button mat-raised-button type="submit" color="accent" (click)="exportExcel()" *ngIf="purchaseorders">
			<i class="material-icons">text_snippet</i> XLS
		</button>
	</div>
	<div class="col-md-3">
		<mat-form-field appearance="standard" *ngIf="statuses">
			<mat-label>Status</mat-label>
			<mat-select [formControl]="status" >
				<mat-option [value]="''">All</mat-option>
				<mat-option *ngFor="let v of statuses" [value]="v.status_id">
					{{v.name}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="col-7">
		<mat-form-field appearance="standard" class="text-right">
			<mat-label>Search</mat-label>
			<input tabindex="1" matInput value="" (input)="onInput()" [formControl]="vendorSearch" class="text-right" autofocus (keydown.enter)="$event.preventDefault();onInput()">
			<span matSuffix>
				<button tabindex="-1" mat-icon-button color="white">
					<mat-icon (click)="searchOrders()">search</mat-icon>
				</button>
			</span>
			<mat-progress-bar mode="indeterminate" color="accent" *ngIf="searching"></mat-progress-bar>
		</mat-form-field>
	</div>
	<div class="table table-responsive text-center" *ngIf="!allpurchaseorders">
		<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
			<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
			</circle>
		</svg>
	</div>
</div>
<div class="table-container">
	<table mat-table [dataSource]="purchaseorders" matSort (matSortChange)="announceSortChange($event)">
		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef mat-sort-header></th>
			<td mat-cell *matCellDef="let tran">
				<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button mat-menu-item (click)="viewOrder(tran.orderno, orderDetails)">
						<mat-icon>visibility</mat-icon>
						<span>View</span>
					</button>
					<a href="{{config.apiServer.baseUrl}}{{baselink}}{{tran.orderno}}" mat-menu-item>
						<mat-icon>print</mat-icon>
						<span>PDF</span>
					</a>
					<a href="{{config.apiServer.baseUrl}}{{reclink}}{{tran.orderno}}" mat-menu-item>
						<mat-icon>print</mat-icon>
						<span>Rcv Doc</span>
					</a>
					<mat-divider></mat-divider>
					<a [routerLink]="'/purchasing/receive/'+tran.orderno" mat-menu-item>
						<mat-icon>call_received</mat-icon>
						<span>Receive</span>
					</a>
					<mat-divider></mat-divider>
					<button mat-menu-item (click)="loadOrder(tran.orderno)">
						<mat-icon>edit</mat-icon>
						<span>Edit RGA</span>
					</button>
				</mat-menu>
			</td>
		</ng-container>
		<ng-container matColumnDef="status_id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Status.</th>
			<td mat-cell *matCellDef="let tran">
				<ng-container *ngIf="!tran.editing">
					{{ tran.statusname }}
					<button mat-icon-button aria-label="Change Status" (click)="toggleStatusChange(tran)">
						<mat-icon class="smaller">edit</mat-icon>
					</button>
				</ng-container>
				<mat-form-field appearance="standard" *ngIf="tran.editing">
					<mat-label>Status</mat-label>
					<span matPrefix *ngIf="tran.editing">
						<mat-icon (click)="toggleStatusChange(tran)" class="smaller">clear</mat-icon>
					</span>
					<mat-select [(value)]="tran.status_id" (selectionChange)="updateStatus($event, tran)">
						<mat-option *ngFor="let status of statuses" [(value)]="status.status_id">{{ status.name }}</mat-option>
					</mat-select>

				</mat-form-field>
			</td>
		</ng-container>
		<ng-container matColumnDef="orderno">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>PO#</th>
			<td mat-cell *matCellDef="let tran">{{ tran.orderno }}</td>
		</ng-container>
		<ng-container matColumnDef="orddate">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Date Ordered</th>
			<td mat-cell *matCellDef="let tran">{{ tran.orddate }}</td>
		</ng-container>
		<ng-container matColumnDef="suppname">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Vendor</th>
			<td mat-cell *matCellDef="let tran">
				{{ tran.suppname }}
			</td>
		</ng-container>
		<ng-container matColumnDef="requisitionno">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Req#</th>
			<td mat-cell *matCellDef="let tran">{{ tran.requisitionno }}</td>
		</ng-container>

		<ng-container matColumnDef="ordervalue">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Net $</th>
			<td mat-cell *matCellDef="let tran">{{ tran.ordervalue | currency }}</td>
		</ng-container>
		<ng-container matColumnDef="orderpendingvalue">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Pending $</th>
			<td mat-cell *matCellDef="let tran">{{ tran.orderpendingvalue | currency }}</td>
		</ng-container>
		<ng-container matColumnDef="ordereditems" >
			<th mat-header-cell *matHeaderCellDef mat-sort-header >ORD/REC/PEND</th>
			<td mat-cell *matCellDef="let tran" >{{ tran.ordered }}/{{ tran.rec }}/<b>{{ tran.pendingitems }}</b></td>
		</ng-container>
		<ng-container matColumnDef="deliverydate">
			<th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" class="text-right">Date Wanted</th>
			<td mat-cell *matCellDef="let tran" class="text-right">{{ tran.deliverydate }}</td>
		</ng-container>
		<ng-container matColumnDef="initiator">
			<th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right" arrowPosition="before"> Initiator</th>

			<td mat-cell *matCellDef="let tran" class="text-right">
				{{ tran.initiator }}
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="headercolumns;sticky: true;"></tr>
		<tr mat-row *matRowDef="let row; columns: headercolumns"></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[50, 100, 500]" showFirstLastButtons aria-label="select page">
	</mat-paginator>
</div>
