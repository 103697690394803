import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { InventoryService } from '../../../services/inventory.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { PrintService } from '../../../services/print.service'

@Component({
	selector: 'app-review-counts',
	templateUrl: './review-counts.component.html',
	styleUrls: ['./review-counts.component.scss'],
})

export class ReviewCountsComponent implements OnInit {
	@Input() filters: any = false;
	@Input() flip: any = false;

	@Output() complete = new EventEmitter <any> ();
	@ViewChild('print_table') printtable: ElementRef;
	@ViewChild('print_table_two') printtabletwo: ElementRef;

	items: any = false;
	subscribed: any = false;
	printlink: any = false;
	fetching: boolean = false;
	count_results:any = false
	transno: any = '';
	filename: string = 'StockCounts';
	constructor(private printService: PrintService, private globalSearchService: GlobalSearchService, private inventoryService: InventoryService) {}

	ngOnInit(): void {

	}

	closeCounts() {
		let continue_on = confirm('Close Counts and adjust stock?');
		if(continue_on) {
			this.inventoryService.closeCounts(this.filters).subscribe( r=> {
				if(r.success) {
					this.count_results = r.movements;
					this.transno = r.transno

					this.globalSearchService.showNotification('Stock Adjusted', 'success', 'bottom', 'right');

				}
			})
		}
	}

	clearCounts() {
		this.items = false;
		this.subscribed = false;
		this.printlink = false;
		this.fetching = false;
		this.count_results = false
		this.transno= '';
		this.filename= 'StockCounts';
		this.flip == 'inactive'
		this.complete.emit(true);
		window.location.reload();
	}

	ngOnChanges(changes: any) {

		if(this.flip == 'active') {
			this.fetching = true;
			if(this.subscribed) {
				this.subscribed.unsubscribe();
			}
			this.inventoryService.reviewCounts(this.filters).subscribe(r=> {
				if(r.success) {
					this.items = r.items;
					this.printlink = r.printlink;
					this.fetching = false;
					this.count_results = false;
					if(r.success) {
						this.flip == 'inactive'
						this.complete.emit(true);
						//this.globalSearchService.showNotification('Stock Adjusted', 'success', 'bottom', 'right');
					}
				}
			});
		}
	}


	getTotalFinalQty() {
		var total = 0
		if(this.items) {
			total = this.count_results.reduce(function(accumulator, c) {
				return accumulator + parseFloat(c.adjustment);
			}, 0);
		}
		return total;
	}

	getTotalFinalValue() {
		var total = 0
		if(this.items) {
			let parent = this;
			total = this.count_results.reduce(function(accumulator, c) {
				return accumulator + parent.lineTotal(c);
			}, 0);
		}
		return total;
	}

	getTotalQty() {
		var total = 0
		if(this.items) {
			total = this.items.reduce(function(accumulator, c) {
				return accumulator + parseFloat(c.adjustment);
			}, 0);
		}
		return total;
	}

	getTotalValue() {
		var total = 0
		if(this.items) {
			let parent = this;
			total = this.items.reduce(function(accumulator, c) {
				return accumulator + parent.lineTotal(c);
			}, 0);
		}
		return total;
	}

	exportPdfTwo() {
		const today = new Date();
		let encoded: string = this.globalSearchService.base64encode(this.printtabletwo.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: this.filename,
			title: 'Stock Counts',
			subtitle: 'Created '+today.toLocaleString(),
			landscape: true,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}
	exportPdf() {
		const today = new Date();
		let encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: this.filename,
			title: 'Stock Counts',
			subtitle: 'Created '+today.toLocaleString(),
			landscape: true,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	lineTotal(item: any) {
		return this.financial(this.financial(item.standardcost) * this.financial(item.adjustment))
	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}
}
