<ng-container *ngIf="filters">
	<div class="" >
		<button mat-stroked-button (keyup.enter)="$event.preventDefault();" (keydown.enter)="$event.preventDefault();" class="resetButton" color="white" (click)="$event.preventDefault(); resetFilters();">Reset Filters</button>
	</div>
	<ng-container *ngFor="let filter of filters">
		<h5 class="mb-1 mt-2">{{ filter.name }}</h5>
		<ng-container *ngIf="filter.input_type == 'select'">
			<div class="row">
				<mat-form-field appearance="standard" class="col-12">
					<mat-label>{{ filter.name }}</mat-label>
					<mat-select [(value)]="filter.default_value" (selectionChange)="updateSearchSelect($event, filter.filter_id)">
						<mat-option *ngFor="let opt of filter.options" [value]="opt.value">
							{{opt.label }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</ng-container>
		<ng-container *ngIf="filter.input_type == 'checkbox'">
			<div class="row">
				<mat-checkbox class="col-6" *ngFor="let opt of filter.options" [value]="opt.value" (change)="updateChecks($event, filter.filter_id)">
					{{opt.label }}
				</mat-checkbox>
			</div>
		</ng-container>
		<mat-divider></mat-divider>
	</ng-container>
</ng-container>
