<ng-container *ngIf="invoice_result">
	<div class="row">
		<div class="col-md-12 text-center">
			<h4>{{document_text}}
				<span *ngIf="invoice_result.invoice.transno">{{ invoice_result.invoice.transno }}#</span>
				<span *ngIf="invoice_result.transno && !invoice_result.invoice.transno">{{ invoice_result.transno }}#</span>
				 Created</h4>
			<br />
			<a class="btn btn-danger btn-sm" href="{{ baseUrl }}{{invoiceLink}}{{ invoice_result.invoice.id }}"><i class="fa fa-file-pdf-o"></i> PDF </a>
			<a class="btn btn-warning btn-sm" [routerLink]="'/customers/view/'+order.header.debtorno"> View Customer  </a>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="!invoice_result">
	<ng-container *ngIf="order">
		<form [formGroup]="documentDetails" *ngIf="documentDetails">
			<div class="row mt-0 ml-0 mr-0 pl-0 pr-0">
				<div class="col-sm-9 col-md-9">
					<div class="row mt-0 ml-0 mr-0 pl-0 pr-0">
						<div class="col-md-12 m-0 p-0">
							<div class="row m-0 p-0 mt-0 ">
								<div class="col-md-5 mt-0 card-group m-0 p-0 ">
									<div class="col-md-6 card-group ml-0 pl-0 ">
										<div class="card no-shadow">
											<div class="card-header pt-1 bg-light mb-0 pb-1 border-bottom">
												<h6 class="card-title mb-0 pb-0  ">Bill To</h6>
											</div>
											<div class="card-body " [innerHTML]="order.header.billto">
											</div>
										</div>
									</div>
									<div class="col-md-6 card-group pr-0 mr-0">
										<div class="card no-shadow">
											<div class="card-header pt-1 bg-light mb-0 pb-1 border-bottom">
												<h6 class="card-title mb-0 pb-0  ">Ship To</h6>
											</div>
											<div class="card-body mt-0 " [innerHTML]="order.header.shipto">
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-7 mt-0 card-group">
									<div class="card no-shadow">
										<div class="card-body">
											<div class="row">
												<div class="col-md-4">
													<mat-form-field appearance="standard" class="text-right">
														<mat-label>{{ document_text }} Date</mat-label>
														<input matInput formControlName="invoiceDate" [matDatepicker]="invoicedatepicker" appNoEnterKey>
														<mat-datepicker-toggle matSuffix [for]="invoicedatepicker"></mat-datepicker-toggle>
														<mat-datepicker #invoicedatepicker></mat-datepicker>
													</mat-form-field>
												</div>
												<div class="col-md-4">
													<mat-form-field class="full-width" appearance="standard" class="text-center">
														<mat-label>#Packages</mat-label>
														<input matInput formControlName="packages" matInput placeholder="#Packages" appNoEnterKey appNumericField>
													</mat-form-field>
												</div>
												<div class="col-md-4">
													<mat-form-field appearance="standard">
														<mat-label>Sales Person</mat-label>
														<mat-select formControlName="salesperson" appNoEnterKey>
															<mat-option *ngFor="let person of salespeople" [value]="person.salesmancode">
																{{ person.salesmanname }}
															</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
												<div class="col-md-4">
													<mat-form-field class="full-width" appearance="standard">
														<mat-label>Reference#</mat-label>
														<input matInput formControlName="customerref" matInput placeholder="Invoice Note" appNoEnterKey>
													</mat-form-field>
												</div>
												<div class="col-md-4">
													<mat-form-field appearance="standard">
														<mat-label>Ship Via</mat-label>
														<mat-select formControlName="shipvia" appNoEnterKey>
															<mat-option *ngFor="let ship of shipvias" [value]="ship.shipper_id">
																{{ ship.shippername }}
															</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
												<div class="col-md-4">
													<mat-form-field appearance="standard">
														<mat-label>{{ doc_return_type }}</mat-label>
														<mat-select formControlName="document_type" appNoEnterKey>
															<mat-option *ngFor="let t of document_types" [value]="t.value">
																{{ t.label }}
															</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
												<div class="col-md-12">
													<mat-form-field class="full-width" appearance="standard">
														<mat-label>Email</mat-label>
														<input matInput formControlName="email" matInput placeholder="Invoice Note" appNoEnterKey>
													</mat-form-field>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-md-12">
							<div class="table table-fixed">
								<table class="table table-fixed table-sm table-outlined">
									<thead>
										<tr>
											<th>Item</th>
											<th class="text-center w-50px ">QOH</th>
											<th class="text-right">Price</th>
											<th class="text-right w-10">Invoice</th>
											<th class="text-center w-10">Sub-Total</th>
											<th class="text-right w-10">Tax</th>
											<th class="text-right w-10">Line Total</th>
										</tr>
									</thead>
									<tbody>
										<ng-container *ngFor="let item of order.details;let i = index">
										<tr [ngClass]="(i%2) ? 'even' : 'odd' ">
											<td class="w-50px ">{{ item.stockid}}<br>{{ item.description}}</td>
											<td class="text-center  w-5">{{ item.qoh }} {{ item.units}}</td>
											<td class="text-right">{{ item.discountedprice | currency }}
												 <span *ngIf="item.discountpercent > 0"><br>{{ item.unitprice | currency }} @ {{ item.dspdiscount }}</span></td>
											<td class="text-right w-150px">
												<mat-form-field class="full-width text-right" appearance="standard">
													<input matInput (input)="updateDispatchQty($event,item)" type="text" placeholder="Dispatch" [(value)]="item.dispatch" [ngClass]="{'text-danger bolder'
													: item.qoh < item.ordered}" appSelectOnFocus appNoEnterKey appNumericField>
													<mat-hint class="text-right"  *ngIf="item.qtyremain != item.ordered"> {{ item.qtyinvoiced }} / {{ item.ordered }} Invoiced</mat-hint>
													<span matSuffix class=""> / <span [ngClass]="{'text-danger bolder'
													: item.qoh < item.ordered}">{{ item.qtyremain }}</span>
													</span>
													<mat-error>
													</mat-error>


												</mat-form-field>
											</td>
											<td class="text-center w-10">{{ item.linesubtotal | currency }}</td>
											<td class="text-right  w-50px">
												<!-- <mat-label class="text-right"> Total: {{ item.taxes[i].total | currency }}</mat-label> -->
												<ng-container *ngFor="let tax of item.taxes; let i = index ">
													<mat-form-field class="text-right">
														<mat-label class="text-right ">{{ item.taxes[i].description }}</mat-label>
														<input matInput type="text" class="text-right" placeholder="Dispatch" [value]="item.taxes[i].taxrate" (input)="setTax($event, item, i)" appSelectOnFocus appNoEnterKey appNumericField>
														<span matSuffix>%</span>
														<mat-hint class="text-right"> Total: {{ item.taxes[i].total | currency }}</mat-hint>
													</mat-form-field>
												</ng-container>
											</td>
											<td class="text-right ">{{ item.linetotal | currency  }}</td>
										</tr>
										<tr *ngIf="item.narrative != '' && item.narrative" [ngClass]="(i%2) ? 'even' : 'odd' ">

											<td colspan="1">
												<mat-form-field class="full-width" appearance="standard">
													<mat-label>Note</mat-label>
													<input matInput matInput placeholder="" [(value)]="item.narrative" appNoEnterKey>
												</mat-form-field>
											</td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
										</ng-container>
										<tr *ngIf="!order.details">
											<td colspan="7" class="text-center text-primary">NO ITEMS TO {{document_text | uppercase}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm-3 col-md-3 bg-light text-right left-seperator ">
					<div class="row">
						<div class="col-12">
							<mat-form-field class="memo" appearance="fill">
								<mat-label> Memo</mat-label>
								<textarea matInput formControlName="invoiceText" placeholder="Memo For the Customer" appNoEnterKey></textarea>
							</mat-form-field>
						</div>

						<div class="col-12">
							<mat-form-field appearance="fill">
								<mat-label>Terms</mat-label>
								<mat-select formControlName="terms" appNoEnterKey>
									<mat-option *ngFor="let term of terms" [value]="term.termsindicator">
										{{ term.terms }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<h6 class="bolder mt-2">NET TOTAL</h6>
					<h4 class="bolder text-primary">{{ totals.total | currency }}</h4>
					<!-- shipping -->
					<mat-form-field>
						<mat-label class="text-right">Shipping Charge</mat-label>
						<span matPrefix>$</span>
						<input matInput type="text" class="text-right" [(value)]="order.header.freightcost" (input)="setFreightCharge($event)" placeholder="Shipping Charge" appNoEnterKey appSelectOnFocus appNumericField>
					</mat-form-field>
					<ng-container *ngFor="let ftax of order.freight_tax; let i = index ">
						<mat-form-field>
							<mat-label class="text-right">
								{{ ftax.description}} {{ ftax.taxrate }}%
							</mat-label>
							<input matInput type="text" class="text-right" (input)="setFreightTax($event,ftax)" placeholder="" [(value)]="ftax.taxrate" appNoEnterKey appNumericField>
							<span matSuffix>%</span>
							<mat-hint *ngIf="order.header.freightcost">
								{{ (ftax.taxrate / 100) * order.header.freightcost | currency}}
							</mat-hint>
						</mat-form-field>
					</ng-container>
					<!-- end shipping -->
					<!-- Totals -->
					<h5 class="text-right">Totals</h5>
					<ul class="list-group text-right ml-0 mr-0 no-border no-background">
						<li class="list-group-item">Sub-Total: {{ totals.subtotal| currency }}</li>
						<li class="list-group-item">Shipping: {{ totals.freight | currency }}</li>
						<li class="list-group-item">Tax: {{ totals.tax | currency }}</li>
						<li class="list-group-item">Total: {{ totals.total | currency }}</li>
					</ul>
					<mat-divider></mat-divider>

					<ng-container *ngIf="order.details">
						<ng-container *ngIf="document_text !='Credit'">

						<app-order-payments [totals]="totals" [order_header]="order.header" [debtorno]="order.header.debtorno" [branchcode]="order.header.branchcode" [orderno]="order.header.orderno" [payments]="payments" (payments_added)="updatePayments($event)" (payments_removed)="updatePayments($event)" [document_type]="document_type"></app-order-payments>
						</ng-container>
						<ul class="list-group list-group-flush text-right ml-0 mr-0 ">
							<li class="list-group-item mt-2 p-0 ">
								<h6>Balance Due:</h6>
							</li>
							<!-- [ngClass]="{'bg-danger': getBalance() > 0, 'bg-success' : getBalance() <= 0}" -->

							<li class="list-group-item p-0 " >
								{{ getBalance() | currency }}
							</li>

							<h4 class="text-center alert alert-danger" *ngIf="(getBalance() > 0 && termRequiresPayment()) && (document_text !='Credit')">CUSTOMER TERM REQUIRES PAYMENT {{ getBalance() | currency }}</h4>

						</ul>

					</ng-container>


					<ng-container *ngIf="!sending && order.details">
						<ng-container *ngIf="getTotalDispatching() != 0">
						<button mat-raised-button color="accent" class="mt-3 mb-3" (click)="createInvoice()">{{ document_text }} Account</button>
						</ng-container>
						<ng-container *ngIf="getTotalDispatching() == 0">
							<h3 class="text-center text-danger">NO ITEMS TO {{document_text | uppercase}}</h3>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="sending">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="5" stroke-linecap="round" cx="66" cy="66" r="66"></circle>
						</svg>
					</ng-container>
					<!-- end totals -->
				</div>
			</div>
		</form>
	</ng-container>
</ng-container>
