import { Component, SimpleChanges, OnChanges, OnInit ,Input, Output,EventEmitter} from '@angular/core';
import { GlobalsService } from '../../../services/globals.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-gl-account-lookup',
	templateUrl: './gl-account-lookup.component.html',
	styleUrls: ['./gl-account-lookup.component.scss']
})
export class GlAccountLookupComponent implements OnInit {
	glaccounts: any = [];
	allglaccounts: any = [];
	glsearch = new UntypedFormControl('')

	@Input() preselected: boolean = false;
	@Output() glselected = new EventEmitter < any > ();
	constructor(private globalSearchService: GlobalSearchService, private globalsService: GlobalsService) {}

	ngOnInit(): void {
		this.globalsService.getGLAccounts().subscribe((result: any) => {
			this.glaccounts = result;
			this.allglaccounts = result;


		});

		this.glsearch.valueChanges.subscribe(newValue => {

			//prevent already zeroed account length
			if(!this.glaccounts.length) {
				this.glaccounts = this.allglaccounts;
			}

			if (newValue != '') {
				this.glaccounts = this.globalSearchService.filterItem(this.glaccounts, newValue, 'accountcode,accountname')
			} else {
				this.glaccounts = this.allglaccounts
			}
		});
	}

	addGl(value: any) {
		this.glselected.emit(value);
	}

	clearGl() {
		this.glsearch.setValue('');
	}

	displayFn(account: any) {

		if(!account) {
			return '';
		}

		return account.accountcode + '-' + account.accountname
	}

	ngOnChanges(changes: any) {
		if(this.preselected) {
			let value = this.allglaccounts.filter(r=> {
				return r.accountcode == this.glselected
			})[0]
			if(value) {
				this.glsearch.setValue(value);
			}
		}
	}

}
