import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform,AfterViewInit, Output,EventEmitter} from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';

import { ActivatedRoute, Router} from '@angular/router';
import { InventoryService } from '../../../services/inventory.service';

import { VendorService } from '../../../services/vendor.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';

import { GlobalsService } from '../../../services/globals.service';

import { Directive, HostListener } from "@angular/core";
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
	selector: 'app-purchdata',
	templateUrl: './purchdata.component.html',
	styleUrls: ['./purchdata.component.scss']
})

export class PurchdataComponent implements OnInit {
	@Input() itemdetails: any
	@Input() clonestockid: any
	@Output() purchDataUpdate = new EventEmitter<any>();
	@Output() updateing = new EventEmitter<any>();
	user: any = [];

	itemForm: UntypedFormGroup;
	properties: any = false;
	files: File[] = [];
	today = new Date();
	searching: any = false;
	vendors: any = [];

	constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute,private inventoryService: InventoryService, private location: Location,public router: Router,public cdr: ChangeDetectorRef,private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private vendorService: VendorService) { }

	ngOnInit(): void {

		this.itemForm = this.fb.group({
			stockid: [this.itemdetails.stockid, [Validators.required]],
			supplierdescription: [this.itemdetails.description, [Validators.required]],
			supplierno: ['', [Validators.required]],
			price: ['', [Validators.required]],
			conversionfactor: ['1',],
			leadtime: ['3', [Validators.required]],
			effectivefrom: [this.today, [Validators.required]],
			minorderqty: ['1', [Validators.required]],
			maxorderqty: ['99999', [Validators.required]],
			suppliers_partno: [this.itemdetails.mfgpart, [Validators.required]],
			suppliersuom: ['each', [Validators.required]],
			preferred: [1,[Validators.required]],
		});

		this.itemForm.valueChanges.subscribe(newValue => {
			this.updateing.emit(this.itemForm);
		})
	}

	updateItem() {
		if(this.itemForm.valid) {
			this.purchDataUpdate.emit(this.itemForm);
		}
	}

	onSelect(event) {
	  this.files.push(...event.addedFiles);
	}

	onRemove(event) {
	  this.files.splice(this.files.indexOf(event), 1);
	}

	back(): void {
		this.location.back()
	}

	selectVendor(vendorid: any) {
		this.itemForm.get('supplierno').setValue(vendorid);
	}

	vendorSearch(value) {

		if(this.searching) {
			this.searching.unsubscribe()
		}

		this.searching = this.vendorService.getVendorSearch(value).subscribe( async (results: any) => {
			this.vendors = results;
		});
	}

	ngOnChanges(changes: any) {

		 if(changes.itemdetails) {
		 	this.purchDataUpdate.emit(this.itemForm);
		 }
	}

	ngAfterViewInit(): void {
		this.globalsService.getItemSetup().subscribe( (results: any) => {
			this.properties = results
		});

		if(this.clonestockid != ''){
		   this.inventoryService.getItem({stockid: this.clonestockid}).subscribe((results: any) => {

			this.itemForm.controls['supplierno'].setValue(results.purchdata[0].supplierno);
			this.itemForm.controls['price'].setValue(results.purchdata[0].price);
			this.itemForm.controls['maxorderqty'].setValue(results.purchdata[0].maxorderqty);
			this.itemForm.controls['minorderqty'].setValue(results.purchdata[0].minorderqty);
			this.itemForm.controls['conversionfactor'].setValue(results.purchdata[0].conversionfactor);
			});
		}
	}

}
