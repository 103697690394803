import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform} from '@angular/core';

import { ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common'
import { UntypedFormBuilder, UntypedFormControl,Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';

import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { PurchasingService } from '../../../services/purchasing.service';
import { PrintService } from '../../../services/print.service';
import { InventoryService } from '../../../services/inventory.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
	selector: 'app-purchasing-reordering-avgsales-mixed',
	templateUrl: './purchasing-reordering-avgsales-mixed.component.html',
	styleUrls: ['./purchasing-reordering-avgsales-mixed.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(179deg)'
			})),
			transition('active => inactive', animate('500ms ease-out')),
			transition('inactive => active', animate('500ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		])
	]
})
export class PurchasingReorderingAvgsalesMixedComponent implements OnInit {
	@ViewChild("purchasedetails") purchasedetails: ElementRef;
	@ViewChild("itemhistoryele") itemhistoryele: ElementRef;
	@ViewChild("exportTable") exportTableRef: ElementRef;


	flip: string = 'inactive';
	locations : any = [];
	reportForm: UntypedFormGroup;
	today = new Date();
	results: any = [];
	loading: boolean = false;
	suppliers: any = [];
	purchase_item: any = [];
	foundvendors: any = [];
	itemhistory: any = false;
	item: any =false;
	purchitem: any =false;
	months: any = false;
	multiplier = new UntypedFormControl(60)
	rolling_months: any = false;
	loading_remote: any = false;
	filters: any = [];
	futuremonths: any = [];
	selected_filters: any = [];
	selected_checks: any = [];
	purchase_items: any = [];
	brands:any = [];
	po_vendors: any = [];
	remoteqtys: any = [];
	product_lines:any = [];
	allresults: any = [];
	po_vendors_found: any = false;
	wathchlist: any = [];
	indexnow: number = 1;
	sending: boolean = false;
	currentstep: number = 0;
	allitems: any = false;
	watch_items: any = false;
	retriving: any = false;
	reviewlabel:string = "Review Primary Recommendations"
	config: any = [];
	user: any = false;
	winter_values: any = [{"label":"Non Winter Tires","value":"0"},{"label":"Winter Tires","value":"1"},{"label":"3-Peak All-Weather","value":"2"}];

	constructor(private inventoryService : InventoryService, private purchasingService: PurchasingService,private globalSearchService: GlobalSearchService,private location: Location,private route: ActivatedRoute,private globalsService: GlobalsService,private fb: UntypedFormBuilder , private modalService: NgbModal, private printService: PrintService) {

		this.config = this.globalsService.getApiConfig();
		this.globalSearchService.user.subscribe( results => {
			this.user = results
		});

	}

	getFilterString() : string {
		return '';
	}

	ngOnInit(): void {
		this.purchasingService.getFiltersTwo().subscribe(async (result:any) => {
			this.filters = result;
		});

		this.purchasingService.getSuppliers('').subscribe((results: any) => {
			this.suppliers = results;
			this.reportForm = this.fb.group({
				startdate: [this.today, Validators.required],
				minimum_sales: [0, Validators.required],
				item_type: [1, Validators.required],
				numberofmonths: [2, Validators.required],
				numberofweeks: [8, Validators.required],
				loccode: [this.user.user.defaultlocation.loccode, Validators.required],
				filters: [],
				brands:[],
				season: [],
				lines: [],
				supplier: ['',Validators.required],
				days_supply: [ 60 ]
			});

			this.inventoryService.getBrands().subscribe( (r:any)=> {
				this.brands = r;

				var selected = [];
				this.brands.forEach( a=> {
					selected.push(a.name)
				});
				this.reportForm.get('brands').setValue(selected)
			});

			this.inventoryService.getItemProductlines().subscribe( (r:any)=> {
				this.product_lines = r;

				var selected = [];
				// this.brands.forEach( a=> {
				// 	selected.push(a.name)
				// });
				// this.reportForm.get('brands').setValue(selected)
			});
		});
	}

	updateTargets(value: any) {
		if(this.po_vendors_found && this.po_vendors_found.length) {
			this.po_vendors_found.forEach(v => {
				this.results[v].forEach(i => {
					i.target_avg_day = (i.avg_day * value) - i.actual_on_hand
				})
			})
		}
	}

	updateTargetsPullover() {
		if(this.po_vendors_found && this.po_vendors_found.length) {
			this.po_vendors_found.forEach(v => {
				this.results[v].forEach(i => {
					i.target_avg_day = (i.avg_day * this.reportForm.get('days_supply').value) - i.actual_on_hand
				})
			})
		}
	}

	updateProductLines() {
			let data = {
				brands: this.reportForm.get('brands').value
			}
			this.inventoryService.getBrandProductlines(data).subscribe( (r:any)=> {
				this.product_lines = r;

				var selected = [];
				this.product_lines.forEach( a=> {
					selected.push(a.line_field)
				});
				this.reportForm.get('lines').setValue(selected)
			});
	}

	updateBrands(event: any) {
		this.reportForm.get('supplier').setValue(event.supplierid)
		var supplierid = event.supplierid;
		let defaultBrands = this.brands.filter( r=> {
			return supplierid == r.a2
		})
		var selected = [];
		defaultBrands.forEach( a=> {
			selected.push(a.name)
		})

		if(!selected.length) {
			// this.brands.forEach( a=> {
			// 	selected.push(a.name)
			// })
		}

		this.reportForm.get('brands').setValue(selected)


		let data = {
			supplier: event.supplierid
		}
		this.inventoryService.getBrandProductlines(data).subscribe( (r:any)=> {
			this.product_lines = r;

			var selected = [];
			this.product_lines.forEach( a=> {
				selected.push(a.line_field)
			});
			this.reportForm.get('lines').setValue(selected)
		});

	}

	getItemHistory(pitem: any) {
		this.purchitem = pitem;

		this.inventoryService.getItem({ stockid: pitem.stockid }).subscribe((item: any) => {
			this.item = item;
			this.inventoryService.getHistory(pitem.stockid).subscribe((results: any) => {
				this.itemhistory = results;
				this.modalService.open(this.itemhistoryele, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

				}, (reason) => {
					this.itemhistory = {};
				});

			})
		})
	}

	updateChecks(event: any, filter_id: any) {

		if (!this.selected_checks[filter_id]) {
			this.selected_checks[filter_id] = [];
		}

		let data = { value: event.source.value };

		if (event.checked) {
			this.selected_checks[filter_id].push(data)
		} else {

			let index = this.selected_checks[filter_id].indexOf(data);

			this.selected_checks[filter_id] = this.selected_checks[filter_id].filter((selected, index) => {
				return selected.value != event.source.value
			});
		}
		//prevent empty send
		if(this.selected_checks[filter_id].length) {
			this.selected_filters[filter_id] = { filter_id: filter_id, value: this.selected_checks[filter_id] };
		} else {
			this.selected_filters[filter_id] = {};
		}
	}

	createBatchBo() {
		this.flip = (this.flip == 'inactive') ? 'active': 'inactive';
	}

	creteMultiplePos() {

	}

	updateSearchSelect(event: any, filter_id: any) {
		this.selected_filters[filter_id] = { filter_id: filter_id, value: event.value };
	}

	updateSearchSelectMulti(event: any, filter_id: any) {
		//event.value.valueforEach(val => {
			this.selected_filters[filter_id] = { filter_id: filter_id, value: event.value };

		//})
	}

	editBin(item:any) {
		item.editing = (item.editing) ? false: true;
	}

	updateQuantity(event: any, item: any) {
		this.po_vendors.forEach( (po:any) => {
			let poline = po.lines.filter( a => {
				return a.stockid == item.stockid;
			})[0]
			if(poline) {
				poline.orderqty = event;
			}
		})

		item.qty_purchase = event;
		item.orderqty = event;
	}

	updatePrice(event: any, item: any) {
		item.price = event;
		this.reduceItems();
	}


	updateBinItem(event: any, item: any) {
		let index = this.results.indexOf(item);
		item.bin = event;
		item.editing = false;
	}

	setPurchOrderOptions(item: any, pdata: any)  {
		item.selected_option = pdata;
		this.modalService.dismissAll();
	}

	viewPurchData(item: any) {
		this.purchase_item = item;


		this.modalService.open(this.purchasedetails, {ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

		}, (reason) => {
			this.purchase_item = {};
		});
	}

	exportXls() {

		let encoded: string = this.globalSearchService.base64encode(this.exportTableRef.nativeElement.innerHTML);

		const today = new Date();

		let data = {
			content: encoded,
			filename: 'MixedRecomendations',
			title: 'Mixed Recmendatiosn Re-Order',
			subtitle: 'Created '+today.toLocaleString(),
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});

	}

	removePoItem(line: any, po: any, item: any) {
		let poindex = this.po_vendors.indexOf(po);
		let lineindex = this.po_vendors[poindex].lines.indexOf(line);
		this.po_vendors[poindex].lines.splice(lineindex, 1);
		let index = this.results.indexOf(item);
		this.results.splice(index, 1);
	}

	removeItem(item: any) {
		let index = this.results.indexOf(item);
		this.results.splice(index, 1);
	}

	reduceItems() {

		this.foundvendors = this.allresults.vendors
		this.months = this.allresults.months
		this.futuremonths = this.allresults.future_months
		const poitems = [];
		this.allresults.data.forEach((item: any) => {

			let already_ordered = 0;
			let qty = item.qty_purchase;

			item.vendor = item.selected_option.supplierno;
			item.price = parseFloat(item.selected_option.price);
			let data = {
					vendor: item.selected_option.supplierno,
					vendor_name: item.selected_option.suppname,
					stockid: item.stockid,
					description: item.description,
					orderqty: qty,
					price: parseFloat(item.selected_option.price),
					options_selected: item.selected_option,
					purchdata: item.purchdata,
					item: item,
				}
			poitems.push(data);

		});

		const all_vendors =  poitems.map( a => a.vendor);
		const unique_vendors = all_vendors.filter((x, i, a) => a.indexOf(x) == i)
		this.purchase_items = poitems;
		if(poitems.length) {
			let data = { poitems: poitems }
			this.loading_remote = true;
			this.purchasingService.getRemoteQty(data).subscribe( (results: any) => {
				this.allresults= results;
				this.loading_remote = false;
				//map data down: broken down by vendor
				//this.reduceItems();
			});
		}
	}

	getRecomendations() {

		if (this.reportForm.valid) {
			this.indexnow = 1;

			this.reviewlabel= "Review Primary Recommendations"
			this.reportForm.get('filters').setValue(this.selected_filters);
			this.loading = true;

			if(this.retriving) {
				this.retriving.unsubscribe();
			}

			this.retriving =this.purchasingService.getMixesRecomendations(this.reportForm.value).subscribe((results: any) => {

					this.loading = false;
					this.allresults = results;
					this.rolling_months = results.rolling_months
					this.results = results.data;
					this.allitems = results.data;
					this.months = results.months;
					this.futuremonths =  results.future_months;
					this.po_vendors_found = results.vendors;
					this.watch_items  = results.watch_items;
					this.retriving = false;

					this.updateTargetsPullover()
				});
		}
	}


	submitPo(po: any) {

		var proceed = confirm('You are about to create a purchase order. Continue?');

		if(proceed) {

			this.sending = true;
			let vendor = this.suppliers.filter( (s:any) => {
				var findindex = s.supplierid+': '+s.suppname;
				return po == findindex
			})[0];

			let remove_vendor = po;

			vendor.loccode = this.reportForm.get('loccode').value;
			vendor.lines = this.results[po].filter( (i:any) => {return i.qty_purchase > 0 });

			if(vendor.lines.length) {
				let data = [
					{ vendor: vendor }
				]

				this.purchasingService.createPurchaseOrdersFromRecomend(data).subscribe((result: any) => {
					this.sending = false;
					if (result.success) {

						this.globalSearchService.showNotification(result.message, 'success', 'bottom', 'right');

						this.indexnow++;

						switch(this.indexnow) {
							case 2:
								this.reviewlabel= "Review Secondary Recommendations"
							break;
							case 3:
								this.reviewlabel= "Review Tertiary Recommendations"
							break;
						}
						let newresults = this.results[po].filter( (ro:any) =>{
							return ((ro.qty_purchase <= ro.target_purchase) && ro.purchdata.length);
						});

						let watchlist = this.results[po].filter( (ro:any) =>{
							return ((ro.qty_purchase > ro.target_purchase) && ro.purchdata.length);
						});

						let next_set = [];
						var suppindex = '';
						this.results = [];


						newresults.forEach( (i:any, index) => {
							//secondary supplier?
							let newpdata = i.purchdata.filter( (p:any) => {
								return parseInt(p.preferred_sort) == this.indexnow;
							})[0]

							if(newpdata) {
								var suppindex = newpdata.supplierno+': '+newpdata.suppname;
								let thisalreadythere = this.po_vendors_found.indexOf(suppindex);

								if(thisalreadythere < 0) {
									this.po_vendors_found.push(suppindex)
								}

								i.vendor = suppindex
								i.selected_option = newpdata
								i.qty_purchase = i.target_purchase - i.qty_purchase;
								i.total_available = newpdata.total_available

								if(newpdata.total_available < i.qty_purchase) {
									i.qty_purchase = newpdata.total_available;
								}

								if(!this.results[suppindex]) {
									this.results[suppindex] = [];
								}

								this.results[suppindex].push(i)

							} else {

							}
						});

						watchlist.forEach((i:any, index) => {
							this.wathchlist.push(i)
						});


						let remove = this.po_vendors_found.indexOf(po)
						this.po_vendors_found.splice(remove,1)

						if(this.po_vendors_found.length) {
							this.currentstep = 0;
						}
						this.sending = false;
					} else {
						alert(result.message)
						this.sending = false;
					}


				});
			} else {
				this.sending = false;
			}
		}
	}

	vendorHasFillable(po: any) {
		let openlines = this.results[po].filter( (line:any) => {
			return line.qty_purchase >0
		})
		return (openlines.length) ? true : false;
	}
	printResults(po: any) {

		let vendor = this.suppliers.filter( (s:any) => {
			var findindex = s.supplierid+': '+s.suppname;
			return po == findindex
		})[0];

		vendor.loccode = this.reportForm.get('loccode').value;
		vendor.lines = this.results[po];

		let data = vendor

		this.purchasingService.printPreviewPo(data).subscribe( (results:any) => {
			this.goToLink(results.url);
		});
	}

	goToLink(url: string) {
		window.open(url, "_blank");
	}

	discontinueItems() {


		let proceed = confirm("Discontinue " + this.watch_items.length +' Items?')
		if(proceed) {
			this.purchasingService.discontinueItems(this.watch_items).subscribe((result: any) => {
				this.watch_items = this.watch_items.filter( (r:any )=> {
					return (!r.watchlist);
				});
			});
		}

	}

	ngAfterViewInit() {

		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
		});
	}

}
