import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';

import { DropzoneComponent, DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { ActivatedRoute, Router } from '@angular/router';
import { PurchasingService } from '../../services/purchasing.service';
import { VendorService } from '../../services/vendor.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { Product } from '../../classes/orders';
import { Directive, HostListener, ViewEncapsulation } from "@angular/core";
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
	selector: 'app-purchasing-open-orders',
	templateUrl: './purchasing-open-orders.component.html',
	styleUrls: ['./purchasing-open-orders.component.scss']
})
export class PurchasingOpenOrdersComponent implements OnInit {
	color: any = 'blue';
	activeIndex: number = 0;
	pos = [];
	vendor_data: any = false;

	constructor(private route: ActivatedRoute, private purchasingService: PurchasingService, private vendorService: VendorService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
	}
	ngOnInit(): void {}

	selectItemLookup(event: any) {
		this.router.navigate(['/purchasing/'+event.header.supplierno]);
	}

	updatePoData(event: any) {

	}
	back(): void {
		this.location.back()
	}
}
