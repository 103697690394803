import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder,FormGroup, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { Location } from '@angular/common'
import { ActivatedRoute, Router} from '@angular/router';

import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { InventoryService } from '../../services/inventory.service';


@Component({
  selector: 'app-inventory-barcodes',
  templateUrl: './inventory-barcodes.component.html',
  styleUrls: ['./inventory-barcodes.component.scss'],
  animations: [
		  trigger("grow", [ // Note the trigger name
			  transition(":enter", [
				  // :enter is alias to 'void => *'
				  style({ height: "0",width: "0", overflow: "hidden" }),
				  animate(200, style({ height: "*" , width: "*"})),
				  animate('200ms', style({ opacity: 1 })),
			  ]),
			  transition(":leave", [
				  // :leave is alias to '* => void'
				  animate(300, style({ height: 0, width: 0 , overflow: "hidden" })),
				  animate('300ms', style({ opacity: 0 }))
			  ])
			  ]),
		  ]
})
export class InventoryBarcodesComponent implements OnInit {
	@Input() item;
	@Output() editing = new EventEmitter<boolean>();
	@Output() newitemdata = new EventEmitter<any>();
	barcodeForm: FormGroup;
	itemForm: any = false;
	barcodes: any = [];
	barcode = new UntypedFormControl('');
	savevalue: any = false;
	savekey: any = false;

	addingbarcode: any = false;
	formEditAllowed = [
		'stockid',
		'barcode',
		'categoryid',
		'taxcatid',
		'lineid',
		'description',
		'longdescription',
		'controlled',
		'decimalplaces',
		'discontinued',
		'mbflag',
		'glclass',
		'units',
		'dwo',
		'enabled',
		'manufacturer_id',
		'winter',
		'designation'
	]

	constructor(private globalSearchService:GlobalSearchService, private route: ActivatedRoute, private fb: UntypedFormBuilder,private inventoryService: InventoryService, public router: Router, private location: Location, private globalsService: GlobalsService) {

	}

	toggleAddingBarcodes() {
		this.addingbarcode = (this.addingbarcode) ? false : true;
	}

	loadBarcodeForm() {
		this.barcodeForm = this.fb.group({
			stockid: [this.item.stockid, Validators.required],
			barcode: ['', Validators.required],
			primary: [''],
		});
	}

	addBarcode() {
		if(this.barcodeForm.valid) {
			this.inventoryService.addBarcode(this.barcodeForm.value).subscribe( (result: any) => {
				this.globalsService.getItemBarcodes(this.item.stockid).subscribe( (results: any) => {
					this.loadBarcodeForm();
					this.barcodes = results
					this.addingbarcode = true;
				});
			});
		}
	}

	saveBarcode(input: any) {

		let data = {
			stockid: input.item,
			barcode: input.savevalue,
			code: input.code,
			primary: input.isprimary,

		}
		if(input.savevalue != '') {
			this.inventoryService.addBarcode(data).subscribe( (result: any) => {
				this.globalSearchService.showNotification('Item Updated', 'success', 'bottom', 'left')
				this.globalsService.getItemBarcodes(this.item.stockid).subscribe( (results: any) => {
					this.barcodes = results
					this.addingbarcode = true;

				});
			})
			this.savevalue = false;
		}

	}

	removeBarcode(barcode: any) {

		let data = {
			stockid: barcode.item,
			barcode: barcode.code,
			primary: barcode.isprimary,
		}

		this.inventoryService.removeBarcode(data).subscribe( (result: any) => {
			this.globalSearchService.showNotification('Barcode removed', 'danger', 'bottom', 'left')
			this.globalsService.getItemBarcodes(this.item.stockid).subscribe( (results: any) => {
				this.barcodes = results
			});
		});
	}

	ngOnInit(): void {
		var details = this.item
		var controls = [];
			const formGroup: UntypedFormGroup = new UntypedFormGroup({});
			Object.keys(details).forEach(key => {
				if(this.formEditAllowed.includes(key)) {
					let value = (details[key]) ? details[key] : '';
					controls.push({ name: key, control: new UntypedFormControl(value, []) })
				}
			});
			controls.forEach(f => {
				formGroup.addControl(f.name, f.control, {})
			});
		this.loadBarcodeForm()
		this.itemForm = formGroup;
	}

	ngAfterViewInit(): void {
		this.globalsService.getItemBarcodes(this.item.stockid).subscribe( (results: any) => {
			this.barcodes = results
			this.addingbarcode = false;
		});
	}
}
