import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router } from '@angular/router';

import { InventoryService } from '../../../services/inventory.service';
import { CustomerService } from '../../../services/customer.service';
import { GlobalsService } from '../../../services/globals.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';

import { Location } from '@angular/common'

@Component({
	selector: 'app-dealer-codes',
	templateUrl: './dealer-codes.component.html',
	styleUrls: ['./dealer-codes.component.scss']
})
export class DealerCodesComponent implements OnInit {
	@Input() customer;
	@ViewChild("dealercodes") dealercodes: ElementRef;
	@Output() newcustomerdata = new EventEmitter < boolean > ();

	dcodeForm: UntypedFormGroup;
	dcodes: any = [];
	branches: any = [];
	mfgs: any = [];
	dealertypenames: any = ['','Chevy Dealer','Ford Dealer','Chrysler Dealer','Government Entity'];

	constructor(private globalsService: GlobalsService, private fb: UntypedFormBuilder, private customerService: CustomerService, private inventoryService: InventoryService, private modalService: NgbModal) {}

	ngOnInit(): void {

		this.customerService.getBranches(this.customer.debtorno).subscribe(details => {
			this.branches = details;
		});
		this.inventoryService.getMfgs().subscribe(details => {
			this.mfgs = details;
		});

		this.loadDealerCodes();
	}

	loadDealerCodes() {
		let data = { debtorno: this.customer.debtorno }

		this.customerService.getDealerCodes(data).subscribe((results: any) => {
			this.dcodes = results;
		});
	}

	addDC() {
		this.dcodes.push({ editing: 1, debtorno: this.customer.debtorno, branchcode: '', manufacturer_id: '', autoid: '', dealercode: '',dealertype: '' });
	}

	cancelDC() {
		this.loadDealerCodes();
	}

	removeDC(dcid: any) {
		let proceed = confirm("Remove Dealer Code?");
		if(proceed) {
			this.customerService.removeDealerCode(dcid).subscribe((results) => {
				this.loadDealerCodes();
			})
		}
	}
	saveDC(dcid: any) {
		this.customerService.saveDealerCode(dcid).subscribe((results: any) => {
			this.loadDealerCodes();
		});
	}
}
