import { ChangeDetectorRef, Component, OnInit, ElementRef, Input, Output, EventEmitter, HostListener, ViewChild, TemplateRef } from '@angular/core';
import { ROUTES } from '../../classes/routes';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { UsersService } from '../../services/users.service';
import { Message } from '../../classes/message';
import { FormControl, UntypedFormBuilder, FormGroup } from '@angular/forms';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { interval, Subscription } from 'rxjs';
import { OrdersService } from '../../services/orders.service';
import { OmsService } from '../../services/oms.service';
import * as moment from 'moment';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

	@Output() rightSidebarEvent = new EventEmitter < boolean > ();
	@ViewChild('checkedOrders') checkedOrdersTemplate = {} as TemplateRef < any > ;
	@ViewChild('pickingOrders') pickingOrdersTemplate = {} as TemplateRef < any > ;
	@ViewChild('pickedOrders') pickedOrdersTemplate = {} as TemplateRef < any > ;
	@ViewChild('newOrders') newOrdersTemplate = {} as TemplateRef < any > ;

	navSubscription: Subscription
	idleState = "NOT_STARTED";
	countdown ? : number = null;
	lastPing ? : Date = null;
	neworders: any = [];

	opened: boolean = false
	ordersopened: boolean = false

	listTitles: any[];
	location: Location;
	right_sidebar: boolean = true;
	mobile_menu_visible: any = 0;
	toggleButton: any;
	sidebarVisible: boolean;
	searchTerm: string = "";
	userData;
	orderData;
	searchResults;
	SENDER;
	messages = [];
	message;
	CHAT_ROOM = "DashBoard";
	messageForm;
	messagecount = 0;
	public itcount = 0;
	sub;
	notificationcount: number;
	public collapseMini: boolean = false;
	color: any = 'blue';
	config: any = false;
	original_url: any = false;
	user: any = false;
	timer: any = false;

	pendingorders: any = [];
	pendingopen: any = [];
	pickedorders: any = [];
	invoiceorders: any = [];
	pickingorders: any = [];
	pickopen: any = false;
	invoiceopen: any = false;
	checkopen: any = false;

	constructor(private bottomSheet: MatBottomSheet, private omsService: OmsService, private ordersService: OrdersService, location: Location, private element: ElementRef, private router: Router, private globalSearchService: GlobalSearchService, private usersService: UsersService, public formBuilder: UntypedFormBuilder, private idle: Idle, keepalive: Keepalive, cd: ChangeDetectorRef) {
		this.color = this.globalSearchService.getColor();
		this.location = location;

		this.globalSearchService.configsubscription.subscribe(conf => {
			this.config = conf;
		});

		this.globalSearchService.user.subscribe(result => {
			this.user = result;
			let data = [{ user: this.user.user.userid }];

			this.omsService.subscribeToOrderPicks((err, data) => {
				this.loadCheckedOrders();
			});

		});

		idle.setIdle(600); // how long can they be inactive before considered idle, in seconds
		idle.setTimeout(600); // how long can they be idle before considered timed out, in seconds
		idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

		// do something when the user becomes idle
		idle.onIdleStart.subscribe(() => {
			this.idleState = "IDLE";
			this.original_url = this.router.url
			this.autonav();
		});
		// do something when the user is no longer idle
		idle.onIdleEnd.subscribe(() => {
			this.idleState = "NOT_IDLE";
			this.countdown = null;
			cd.detectChanges(); // how do i avoid this kludge?
			this.reset();
			this.router.navigate([this.original_url]);
		});

		// do something when the user has timed out
		idle.onTimeout.subscribe(() => this.idleState = "TIMED_OUT");
		// do something as the timeout countdown does its thing
		idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);

		this.loadCheckedOrders();
	}

	orderInNewOrder(data: any) {
		let thisorderexits = this.neworders.filter((a: any) => {
			return a.orderno == data.orderno;
		});
		return thisorderexits[0];
	}

	togglePickedOrders() {
		if (!this.pickopen) {
			this.pickopen = true;
			let config = {
				hasBackdrop: true
			}
			this.bottomSheet.open(this.pickedOrdersTemplate, config);
		} else {
			this.pickopen = false;
			this.bottomSheet.dismiss(this.pickedOrdersTemplate);
		}
	}

	togglePickingOrders() {
		if (!this.pickopen) {
			this.pickopen = true;
			let config = {
				hasBackdrop: true
			}
			this.bottomSheet.open(this.pickingOrdersTemplate, config);
		} else {
			this.pickopen = false;
			this.bottomSheet.dismiss(this.pickingOrdersTemplate);
		}
	}

	toggleCheckedOrders() {
		if (!this.checkopen) {
			this.checkopen = true;
			let config = {
				hasBackdrop: true
			}
			this.bottomSheet.open(this.checkedOrdersTemplate, config);
		} else {
			this.checkopen = false;
			this.bottomSheet.dismiss(this.checkedOrdersTemplate);
		}
	}

	toggleNewOrders() {
		if (!this.ordersopened) {
			this.ordersopened = true;
			let config = {
				hasBackdrop: true
			}
			this.bottomSheet.open(this.newOrdersTemplate, config);
		} else {
			this.ordersopened = false;
			this.bottomSheet.dismiss(this.newOrdersTemplate);
		}
	}

	toggleBottomSheet() {
		if (!this.opened) {
			this.opened = true;
			let config = {
				hasBackdrop: true
			}
			this.bottomSheet.open(this.checkedOrdersTemplate, config);
		} else {
			this.opened = false;
			this.bottomSheet.dismiss(this.checkedOrdersTemplate);
		}
	}

	vibrate() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(2000);
		} else {
			// Not supported
		}
	}

	getColor() {
		return this.color;
	}

	resetNewOrders() {
		this.neworders = [];
		this.bottomSheet.dismiss(this.newOrdersTemplate);
	}

	closeBottomSheet() {
		this.bottomSheet.dismiss(this.checkedOrdersTemplate);
	}

	autonav() {

		if (this.idleState == "IDLE") {
			if (this.user && this.user.user.user_group == '21') {
				this.navSubscription = interval(15000).subscribe((x => {
					this.rotateNav();
				}));
			} else {
				const d = new Date();
				let hour = d.getHours();
				if (hour > 20) {
					this.reset();
					//this.router.navigate(['auth/logout']);
					location.reload()
				}
			}
		}
	}

	rotateNav() {

		var navoptions = [
			'warehouse/warehouse-reports',
			'dashboard',
		]

		if (this.itcount == navoptions.length - 1) {
			this.itcount = 0;
		}

		let newroute = navoptions[this.itcount];
		if (this.user) {
			this.router.navigate([newroute]);
			this.itcount = this.itcount + 1
		}

		if (
			this.router.url != '/orders/entry' &&
			this.router.url != '/inventory/lookup' &&
			this.router.url != '/dispatches/route' &&
			this.router.url != '/orders/pick'

		) {

			if (this.sidebarVisible) {
				//	this.sidebarToggle();
				//	this.collapseMini = false;
				//	this.miniSideBar()
			}

			//this.router.navigate([newroute]);
		} else {
			//not allowed here.
		}

	}

	keyPress(event: KeyboardEvent): void {
		this.reset();
	}

	@HostListener('document:mousemove', ['$event'])
	onMouseMove(e) {
		this.reset();
	}

	reset() {
		// we'll call this method when we want to start/reset the idle process
		// reset any component state and be sure to call idle.watch()
		this.idle.watch();
		this.idleState = "NOT_IDLE";
		this.countdown = null;
		this.lastPing = null;
		if (this.navSubscription) {
			this.navSubscription.unsubscribe();
		}
	}

	ngOnInit() {
		this.reset();
		this.userData = this.usersService.getLocalUser();

		this.globalSearchService.messages.subscribe((results: any) => {
			this.messagecount = results;
		});

		this.globalSearchService.searchTerm.subscribe((newValue: any) => {
			this.searchTerm = newValue;
		});

		this.globalSearchService.userData.subscribe((newValue: any) => {
			this.userData = newValue;
			this.user = newValue;
			this.loadCheckedOrders();
		});

		this.globalSearchService.searchResults.subscribe((newValue: any) => {
			this.searchResults = newValue;
		});

		this.listTitles = ROUTES.filter(listTitle => listTitle);

		this.listTitles.forEach(a => {
			if (a.children) {
				a.children.forEach(b => {
					if (this.listTitles.indexOf(b) < 1) {
						this.listTitles.push(b);
					}
				})
			}
		});

		const navbar: HTMLElement = this.element.nativeElement;
		this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
		this.router.events.subscribe((event) => {
			this.sidebarClose();
			var $layer: any = document.getElementsByClassName('close-layer')[0];
			if ($layer) {
				$layer.remove();
				this.mobile_menu_visible = 0;
			}
		});

		let navstate = this.usersService.getNavState();
		if (navstate) {
			this.collapseMini = false;
			this.miniSideBar();
		}

	}

	audioPlayNewOrder() {

		//var audio = new Audio("/assets/out-of-nowhere-message-tone.mp3");
		//let play = audio.play();
		// if (play !== undefined) {
		// 	play.catch(error => {
		// 		// Auto-play was prevented
		// 		// Show a UI element to let the user manually start playback
		// 	}).then(() => {
		// 		// Auto-play started
		// 	});
		// }
	}


	loadCheckedOrders() {
		var location = '';

		if (this.user) {
			location = this.user.user.defaultlocation.loccode;
		}

		let search = {
			loccode: location,
			debtorno: false,
		}

		this.ordersService.getCustomerOpenOrdersFiltered(search).subscribe((results: any) => {
			this.pendingorders = results.filter(r => {
				return parseInt(r.orderstatusid) < 5
			});

			this.pickingorders = results.filter(r => {
				return parseInt(r.orderstatusid) >= 5 && parseInt(r.orderstatusid) < 21
			});

			this.pickedorders = results.filter(r => {
				return parseInt(r.orderstatusid) == 21 && parseInt(r.orderstatusid) < 24
			});

			this.invoiceorders = results.filter(r => {
				return parseInt(r.orderstatusid) == 24
			});
		});

	}

	loadOrder(orderno: any) {
		this.globalSearchService.reloadingOrder(true);
		this.ordersService.loadSalesOrder(orderno).subscribe((result: any) => {
			this.router.navigate(['./orders/entry/' + result.header.debtorno + '/' + result.header.branchcode]);
			this.globalSearchService.reloadingOrder(false);
			this.spliceandCloseNewOrder(orderno);
		});
	}

	spliceandCloseNewOrder(orderno: any) {
		//REMOVED New orders - made more sense for pending
		if (!this.neworders.length) {
			this.bottomSheet.dismiss(this.newOrdersTemplate);
		} else {
			//not sure if this is wanted. place holder
			this.bottomSheet.dismiss(this.newOrdersTemplate);
		}
	}

	selectItemLookup(event: any) {
		this.router.navigate(['./customers/view/' + event.debtorno]);
	}

	pickOrder(event: any) {
		this.bottomSheet.dismiss()
		this.router.navigate(['./orders/pick/' + event]);
	}

	checkOrder(event: any) {
		this.bottomSheet.dismiss()
		this.router.navigate(['./orders/check/' + event]);
	}

	invoiceOrder(event: any) {
		this.bottomSheet.dismiss()
		this.router.navigate(['orders/invoice/' + event + '/dispatch']);
	}

	sidebarOpen() {
		const toggleButton = this.toggleButton;
		const body = document.getElementsByTagName('body')[0];
		setTimeout(function() {
			toggleButton.classList.add('toggled');
		}, 500);

		body.classList.add('nav-open');

		this.sidebarVisible = true;
	};

	sidebarClose() {
		const body = document.getElementsByTagName('body')[0];
		this.toggleButton.classList.remove('toggled');
		this.sidebarVisible = false;
		body.classList.remove('nav-open');
	};

	sidebarToggle() {
		// const toggleButton = this.toggleButton;
		// const body = document.getElementsByTagName('body')[0];
		var $toggle = document.getElementsByClassName('navbar-toggler')[0];
		//this.usersService.setNavState(this.sidebarVisible)

		if (this.sidebarVisible === false) {
			this.sidebarOpen();
		} else {
			this.sidebarClose();
		}
		const body = document.getElementsByTagName('body')[0];

		if (this.mobile_menu_visible == 1) {
			// $('html').removeClass('nav-open');
			body.classList.remove('nav-open');
			if ($layer) {
				$layer.remove();
			}
			setTimeout(function() {
				$toggle.classList.remove('toggled');
			}, 400);

			this.mobile_menu_visible = 0;
		} else {
			setTimeout(function() {
				$toggle.classList.add('toggled');
			}, 430);

			var $layer = document.createElement('div');
			$layer.setAttribute('class', 'close-layer');

			if (body.querySelectorAll('.main-panel')) {
				document.getElementsByClassName('main-panel')[0].appendChild($layer);
			} else if (body.classList.contains('off-canvas-sidebar')) {
				document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
			}

			setTimeout(function() {
				$layer.classList.add('visible');
			}, 100);

			$layer.onclick = function() { //asign a function
				body.classList.remove('nav-open');
				this.mobile_menu_visible = 0;
				$layer.classList.remove('visible');
				setTimeout(function() {
					$layer.remove();
					$toggle.classList.remove('toggled');
				}, 400);
			}.bind(this);

			body.classList.add('nav-open');
			this.mobile_menu_visible = 1;
		}
	};

	getTitle() {
		var titlee = this.location.prepareExternalUrl(this.location.path());
		if (titlee.charAt(0) === '#') {
			titlee = titlee.slice(1);
		}

		for (var item = 0; item < this.listTitles.length; item++) {
			if (this.listTitles[item].path === this.router.url) {
				return this.listTitles[item].title;
			}
		}

		var subpath = titlee.split("/", 3);

		return this.capitalize(subpath)

		return 'Dashboard';
	}

	public capitalize(s) {

		var fullstring = '';
		s.forEach(function(value) {
			fullstring += value && value[0].toUpperCase() + value.slice(1) + ' ';
		});

		return fullstring;
	}

	public miniSideBar() {
		const body = document.getElementsByTagName('body')[0];

		if (this.collapseMini) {
			this.collapseMini = false;
			body.classList.remove('sidebar-mini');
		} else {
			this.collapseMini = true;
			body.classList.add('sidebar-mini');
		}

		this.usersService.setNavState(this.collapseMini);
	}

	public onInput(event: any) {
		// this pushes the input value into the service's Observable.
		this.globalSearchService.searchTerm.next(event.target.value);
	}

	right_side_bar() {
		this.right_sidebar = !this.right_sidebar
		this.rightSidebarEvent.emit(this.right_sidebar)
	}

	resetCount() {
		this.messagecount = 0;
	}

	ngOnDestroy() {

	}
}
