<div class="main-content">
<div class="container-fluid">


			<div class="card">
				<div class="card-header card-header-{{color}}-6">
					<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Create User</h4>
					<p class="card-category text-right">User Information</p>
				</div>
				<button	 mat-raised-button class='back-btn'  (click)="back()">Cancel User Creation</button>
				<button *ngIf="userForm.valid && systemForm.valid && user.locations.length >= 1 && activeStep == 2" mat-raised-button class='create-btn'  (click)="createUser()">Create User</button>
				<div class="card-body flexrow ">
					<div class="flexcol w-50" >
					<mat-stepper orientation="vertical" linear class="w-100" (selectionChange)="logStep($event)">
						<mat-step [stepControl]="userForm">
						<ng-template matStepLabel>User information</ng-template>

							<form [formGroup]="userForm">
								<div class="col-md-12">
									<mat-form-field class="full-width">
										<mat-label>User Id</mat-label>
										<input matInput formControlName="userid">
									</mat-form-field>
								</div>
								<div class="col-md-12">
									<mat-form-field class="full-width">
										<mat-label>Password</mat-label>
										<input matInput formControlName="password">
									</mat-form-field>
								</div>
								<div class="col-md-12">
									<mat-form-field class="full-width">
										<mat-label>Name</mat-label>
										<input matInput formControlName="realname">
									</mat-form-field>
								</div>
								<div class="col-md-12">
									<mat-form-field class="full-width">
										<input matInput formControlName="email" placeholder="Email address" type="email">
									</mat-form-field>
								</div>
								<div class="col-md-12">
									<mat-form-field class="full-width" appearance="standard">
										<mat-label>Phone#</mat-label>
										<input matInput type="text" (input)="format_phone($event.target)" placeholder="(___) ___-____" formControlName="phone">
									</mat-form-field>
								</div>
							</form>

							<button mat-button matStepperNext [ngClass]="userForm.valid ? 'formvalid':'forminvalid'">
								<ng-container *ngIf="userForm.valid">Next</ng-container>
								<ng-container *ngIf="!userForm.valid"><span class="invalid-accent">* Invalid Form *</span></ng-container>
							</button>

						</mat-step>

						<mat-step [stepControl]="systemForm">
							<ng-template matStepLabel>System information</ng-template>
							<button mat-button matStepperPrevious class="formprevious">Previous Step</button>

							<form [formGroup]="systemForm">
								<div class='flexrow' >
									<div class="flexcol flex-60 jcb">
										<div class="col-md-auto">
											<mat-form-field appearance="standard" class="">
												<mat-label>Default Location</mat-label>
												<mat-select formControlName="defaultlocation" (selectionChange)="defaultLocation($event)">
													<mat-option *ngFor="let loc of recs" [value]="loc.loccode">
														{{loc.loccode}} {{loc.locationname}}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>

										<div class="col-md-auto">
											<mat-form-field class="full-width">
												<input matInput placeholder="Full Access" formControlName="fullaccess" type="text">
											</mat-form-field>
										</div>

										<div class="col-md-auto">
											<mat-form-field appearance="standard" class="">
												<mat-label>User Group</mat-label>
												<mat-select formControlName="user_group">
													<mat-option *ngFor="let yn of usergroups" [value]="yn.user_group_id">
														{{yn.name}}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
									</div>

									<div class="flexcol flex-40">
										<div class="col-md-auto">
											<mat-form-field appearance="standard" class="">
												<mat-label>Customer Admin</mat-label>
												<mat-select formControlName="customeradmin">
													<mat-option *ngFor="let yn of yesno" [value]="yn.value">
														{{yn.name}}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>

										<div class="col-md-auto">
											<mat-form-field appearance="standard" class="">
												<mat-label>Create Tender</mat-label>
												<mat-select formControlName="cancreatetender">
													<mat-option *ngFor="let yn of yesno" [value]="yn.value">
														{{yn.name}}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>

										<div class="col-md-auto">
											<mat-form-field appearance="standard" class="">
												<mat-label>Edit Invoice</mat-label>
												<mat-select formControlName="editinv">
													<mat-option *ngFor="let yn of yesno" [value]="yn.value">
														{{yn.name}}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>

										<div class="col-md-auto">
											<mat-form-field appearance="standard" class="">
												<mat-label>Dispatch Admin</mat-label>
												<mat-select formControlName="dispatchadmin">
													<mat-option *ngFor="let yn of yesno" [value]="yn.value">
														{{yn.name}}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
									</div>
								</div>

							</form>

						<button mat-button matStepperNext [ngClass]="systemForm.valid ? 'formvalid':'forminvalid'">
							<ng-container *ngIf="systemForm.valid">Next</ng-container>
							<ng-container *ngIf="!systemForm.valid"><span class="invalid-accent">* Invalid Form *</span></ng-container>
						</button>
						</mat-step>
						<mat-step [stepControl]="">
							<button mat-button matStepperPrevious class="formprevious">Previous Step</button>
							<ng-template matStepLabel>Locations</ng-template>

							<div class="row mt-4 h-700">
								<div class="col-md-6">
									<h5>Locations</h5>
									<mat-divider></mat-divider>
									<mat-action-list class="borderd perm-box mt-2" dense>
										<button mat-list-item class="mat-list-item-bordered" *ngFor="let loc of locations" (click)="addUserLocation(loc)">
											<span>
												<b>{{ loc.loccode }}</b>:&nbsp;{{ loc.locationname }}
											</span>
											<span class="ml-auto mr-0"><small> <mat-icon color="accent" class="small bold">add</mat-icon></small></span>
										</button>
									</mat-action-list>
								</div>
								<div class="col-md-6">
									<h5>Access</h5>
									<mat-divider></mat-divider>
									<mat-action-list class="borderd perm-box mt-2" dense>
										<button mat-list-item class="mat-list-item-bordered" *ngFor="let loc of user.locations" (click)="removeUserLocation(loc)">
											<span>
												<b>{{ loc.loccode }}</b>:&nbsp;{{ loc.locationname }}
											</span>
											<span class="ml-auto mr-0"><small> <mat-icon color="accent" class="small bold">remove</mat-icon></small></span>
										</button>
									</mat-action-list>
								</div>
							</div>
						</mat-step>
					</mat-stepper>

					</div>


					<mat-divider [vertical]="true"></mat-divider>


					<!-- review side -->
					<div class="review-container">
						<div class="m-auto f-20">
							<div class="h-header"><h3>&nbsp;User</h3></div>
							<ul class="entry-list entry-list-extra">
								<li>
									<div class="spec-label">User ID</div>
									<div class="spec-value">
										<ng-container *ngIf="userForm.get('userid').value != ''; else missing">{{userForm.get('userid').value}}</ng-container>
									</div>
								</li>
								<li>
									<div class="spec-label">Password</div>
									<div class="spec-value">
										<ng-container *ngIf="userForm.get('password').value != ''; else missing">{{userForm.get('password').value}}</ng-container>
									</div>
								</li>
								<li>
									<div class="spec-label">Name</div>
									<div class="spec-value">
										<ng-container *ngIf="userForm.get('realname').value != ''; else missing">{{userForm.get('realname').value}}</ng-container>
									</div>
								</li>
								<li>
									<div class="spec-label">Email Address</div>
									<div class="spec-value">
										<ng-container *ngIf="userForm.get('email').value != ''; else missing">{{userForm.get('email').value}}</ng-container>
									</div>
								</li>
								<li>
									<div class="spec-label">Phone #</div>
									<div class="spec-value">
										<ng-container *ngIf="userForm.get('phone').value != ''; else missing">{{userForm.get('phone').value}}</ng-container>
									</div>
								</li>

							</ul>
							<div class="h-header"><h3>&nbsp;System</h3></div>
							<ul class="entry-list entry-list-extra">
								<li>
									<div class="spec-label">Default Location</div>
									<div class="spec-value">
										<ng-container *ngIf="systemForm.get('defaultlocation').value != ''; else missing">{{systemForm.get('defaultlocation').value}}</ng-container>
									</div>
								</li>
								<li>
									<div class="spec-label">Full Access</div>
									<div class="spec-value">
										<ng-container *ngIf="systemForm.get('fullaccess').value != ''; else missing">{{systemForm.get('fullaccess').value}}</ng-container>
									</div>
								</li>
								<li>
									<div class="spec-label">User Group</div>
									<div class="spec-value">
										<ng-container *ngIf="translate_usergroup(systemForm.get('user_group').value) != ''; else missing">{{translate_usergroup(systemForm.get('user_group').value)}}</ng-container>
									</div>
								</li>
								<li>
									<div class="spec-label">Customer Admin</div>
									<div class="spec-value">
										<ng-container *ngIf="systemForm.get('customeradmin').value != ''; else missing">{{translate_yn(systemForm.get('customeradmin').value)}}</ng-container>
									</div>
								</li>
								<li>
									<div class="spec-label">Create Tender</div>
									<div class="spec-value">
										<ng-container *ngIf="systemForm.get('cancreatetender').value != ''; else missing">{{translate_yn(systemForm.get('cancreatetender').value)}}</ng-container>
									</div>
								</li>
								<li>
									<div class="spec-label">Edit Invoices</div>
									<div class="spec-value">
										<ng-container *ngIf="systemForm.get('editinv').value != ''; else missing">{{translate_yn(systemForm.get('editinv').value)}}</ng-container>
									</div>
								</li>
								<li>
									<div class="spec-label">Dispatch Admin</div>
									<div class="spec-value">
										<ng-container *ngIf="systemForm.get('dispatchadmin').value != ''; else missing">{{translate_yn(systemForm.get('dispatchadmin').value)}}</ng-container>
									</div>
								</li>
							</ul>
							<div class="h-header"><h3>&nbsp;Locations</h3></div>
							<ul class="entry-list entry-list-extra" >
								<ng-container *ngIf="user.locations[0]">
									<ng-container *ngFor="let loc of user.locations">
										<li>
											<div class="spec-label">{{loc.loccode}}</div>
											<div class="spec-value">{{loc.locationname}}</div>
										</li>
									</ng-container>
								</ng-container>
								<ng-container *ngIf="!user.locations[0]">
									<li>
										<div class="spec-label">Location</div>
										<div class="spec-value"><span class="missing">?</span></div>
									</li>
								</ng-container>
							</ul>
							<ng-template #missing><span class="missing">?</span></ng-template>
						</div>
					</div>
				</div>
			</div>

</div>
</div>
