import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform} from '@angular/core';

import { ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';

import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { PurchasingService } from '../../../services/purchasing.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-purchasing-reordering-avgsales',
	templateUrl: './purchasing-reordering-avgsales.component.html',
	styleUrls: ['./purchasing-reordering-avgsales.component.scss']
})
export class PurchasingReorderingAvgsalesComponent implements OnInit {
	@ViewChild("purchasedetails") purchasedetails: ElementRef;
	locations : any = [];
	reportForm: UntypedFormGroup;
	today = new Date();
	results: any = [];
	loading: boolean = false;
	suppliers: any = [];
	purchase_item: any = [];

	constructor(private purchasingService: PurchasingService,private globalSearchService: GlobalSearchService,private location: Location,private route: ActivatedRoute,private globalsService: GlobalsService,private fb: UntypedFormBuilder , private modalService: NgbModal) { }

	ngOnInit(): void {

		this.purchasingService.getSuppliers('').subscribe((results: any) => {
			this.suppliers = results;
			this.reportForm = this.fb.group({
				startdate: [this.today, Validators.required],
				minimum_sales: [3, Validators.required],
				item_type: [1, Validators.required],
				numberofmonths: [2, Validators.required],
				numberofweeks: [8, Validators.required],
				loccode: [this.locations[0].loccode, Validators.required],
				vendorid: ['', Validators.required ]
			});
		});
	}

	creteMultiplePos() {

	}

	editBin(item:any) {
		item.editing = (item.editing) ? false: true;
	}

	updateQuantity(event: any, item: any) {
		item.qty_purchase = event;
	}

	updateBinItem(event: any, item: any) {
		let index = this.results.indexOf(item);
		item.bin = event;
		item.editing = false;
	}

	setPurchOrderOptions(item: any, pdata: any)  {
		item.selected_option = pdata;
		this.modalService.dismissAll();
	}

	viewPurchData(item: any) {
		this.purchase_item = item;

		this.modalService.open(this.purchasedetails, {ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

		}, (reason) => {
			this.purchase_item = {};
		});


	}

	removeItem(item: any) {
		let index = this.results.indexOf(item);
		this.results.splice(index, 1);
	}

	getRecomendations() {
		if(this.reportForm.valid) {
			this.loading = true;
			this.purchasingService.getStockRecomendations(this.reportForm.value).subscribe((results: any) => {
				this.results = results
				this.loading = false;
			});
		}
	}

	ngAfterViewInit() {

		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
		});
	}

}
