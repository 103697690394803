<div class="main-content">
	<div class="container-fluid">
		<div class="card">
			<div class="card-header card-header-{{ color }}">
				<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Stock Counts </h4>
				<p class="card-category"></p>
			</div>
			<div class="card-body">
				<div class="container-fluid tp-box" [@flipState]="flip">
					<div class="tp-box__side tp-box__front" id="frontside" [ngClass]="flip != 'active' ? '' : 'd-none'">

						<form [formGroup]="sheetForm" *ngIf="sheetForm" class="no-overflow" (ngSubmit)="createLoadSheets()">
							<div class="row">
								<div class="col-md-3">
									<mat-form-field appearance="standard">
										<mat-label>Warehouse</mat-label>
										<mat-select formControlName="location">
											<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
												{{ loc.locationname }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-3">
									<app-vendor-auto-complete (vendor_selected)="selectVendor($event)"></app-vendor-auto-complete>
								</div>
								<div class="col-md-3">
									<app-category-autocomplete (value_selected)="selectCategory($event)"></app-category-autocomplete>
								</div>
								<div class="col-md-3">
									<app-productline-autocomplete (value_selected)="selectProductLine($event)"></app-productline-autocomplete>
								</div>

								<div class="col-md-6">

									<mat-form-field appearance="standard" class="text-right">
										<mat-label>Scan / Enter a Item </mat-label>
										<input matInput type="text" placeholder="Scan / Enter a Item" formControlName="stockid"/>
										<span matSuffix>
											<button tabindex="-1" mat-icon-button color="white">
												<mat-icon (click)="keywordItemSearch()">search</mat-icon>
											</button>
											<button mat-icon-button><mat-icon>
											camera</mat-icon></button>
										</span>
									</mat-form-field>
								</div>

								<div class="col-md-6">

									<mat-form-field appearance="standard" class="text-right">
										<mat-label>Scan / Enter a Bin </mat-label>
										<input matInput type="text" placeholder="Scan / Enter a Bin" formControlName="keywords"/>
										<span matSuffix>
											<button tabindex="-1" mat-icon-button color="white">
												<mat-icon (click)="keywordSearch()">search</mat-icon>
											</button>
											<button mat-icon-button><mat-icon>
											camera</mat-icon></button>
										</span>
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-8">
									<div class="alert alert-info " *ngIf="!counting_items"> Select a warehouse and inventory items to start. Then create and load the sheets to count. Use the PDF to produce stock count sheets if needed.</div>

								</div>
								<div class="col-4 text-right">
									<button mat-raised-button>Create/Load Sheets</button>
									<span *ngIf="sending">
										<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
											<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
										</svg>
									</span>
								</div>
							</div>
						</form>

						<div class="text-right" *ngIf="fetching">
							<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
						</div>
						<div #count_table class="table mt-3" *ngIf="counting_items">
							<table class="table table-bordered table-hover pure-table pure-table-bordered" >
								<thead>
									<tr>
										<td class="text-center">LINE</td>
										<td class="text-center">BIN</td>
										<td class="text-center">ITEM /<br>(MFG) </td>
										<td class="text-center">DESCRIPTION /<br>(BARCODE)</td>
										<td class="text-center">QUANTITY ON HAND</td>
										<td class="noprint text-center">QUANTITY<br> ACTUAL</td>
										<td class="noshow text-center">QUANTITY<br> ACTUAL</td>
									</tr>
								</thead>
								<tbody>
									<ng-container *ngFor="let item of counting_items;trackBy: identify">
										<tr>
											<td class="text-center"> {{ item.line }}</td>
											<td class="text-center"> {{ item.bin }}</td>
											<td class="text-center"> {{ item.partno }}<br>{{ item.vendor }}</td>
											<td class="text-center"> {{ item.description }}<br>{{item.vendeopartno}}</td>
											<td class="text-center"> {{ item.qoh }}</td>
											<td class="text-center noprint">
												<!-- no NUMBER! leaves mouse scoll -->
												<input class="form-control text-center" [ngClass]="item.background" type="text" [(value)]="item.counted" pattern="[0-9]*" inputmode="numeric" (input)="setQty($event, item)">
											</td>
											<td class="text-center noshow">
												<span class="mt-3">_________</span>
											</td>
										</tr>
									</ng-container>
								</tbody>
							</table>
						</div>

					</div>
					<div class="tp-box__side tp-box__back" [ngClass]="flip != 'inactive' ? '' : 'd-none'">
						<ng-container>
							<app-review-counts [filters]="sheetForm.value" (complete)="resetSheets()" *ngIf="sheetForm" [flip]="flip"></app-review-counts>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="col-12">
	<div id="floater-container">
	</div>
</div>
<nav class="navbar fixed-bottom navbar-dark bg-dark justify-content-right" id="bottom-nav">
	<div class="ml-auto nav-item" id="complete-buttons">
		<ng-container *ngIf="counting_items.length && flip == 'inactive'">
			<span class="text-white">COUNT SHEETS: &nbsp;&nbsp;&nbsp;</span>
			<a href="{{ pdfLink() }}"class="nav-item ml-auto mr-0 btn btn-danger" ><i class="fa fa-file-pdf-o"></i> </a> &nbsp;&nbsp;&nbsp;
			<span *ngIf="exporting">
				<svg class="spinner" width="55px" height="55px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
					<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
					</circle>
				</svg>
			</span>
			<button type="button" class="nav-item ml-auto mr-0 btn btn-success" (click)="$event.preventDefault();exportXls()" *ngIf="!exporting"><i class="fa fa-file-excel-o"></i> </button>
		</ng-container>
	</div>
	<div class="ml-auto nav-item" id="complete-buttons">
		<div class="text-white d-none" id="nocounts">
			<mat-slide-toggle
				  [formControl]="nil" class="text-white" *ngIf="flip == 'active'">
				ADJUST NO COUNTS TO ZERO
			</mat-slide-toggle>
		</div>
	</div>
	<div class="ml-auto nav-item">
		<button type="button" id="button-back" class="nav-item btn btn-warning" (click)="toggleFlip()">
			<span *ngIf="flip == 'inactive'">Review Current Counts</span>
			<span *ngIf="flip == 'active'">Add Counts</span>

		</button>

		<button type="button" id="save-counts" class="nav-item ml-auto mr-0 btn btn-danger" (click)="saveCounts()" *ngIf="flip == 'inactive'"><i class="fa fa-save"></i> Save </button>
	</div>
</nav>
