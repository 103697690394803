import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd } from '@angular/router';


import { OrdersService } from '../../services/orders.service';
import { CustomerService } from '../../services/customer.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { CreditService } from '../../services/credit.service';
import { GlobalsService } from '../../services/globals.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { Directive, HostListener } from "@angular/core";
import { trigger, state, style, transition, animate } from '@angular/animations';


@Component({
	selector: 'app-credit-customer-orders',
	templateUrl: './credit-customer-orders.component.html',
	styleUrls: ['./credit-customer-orders.component.scss']
})
export class CreditCustomerOrdersComponent implements OnInit {
	@Input() customerdata;
	@Output() cart_updated = new EventEmitter < any > ();
	todate = new Date();
	fromdate = new Date();
	customertrans: any = [];
	searching: any = false;
	searchingsubscription: any = false;
	keywords= new UntypedFormControl('');

	fromDateControl = new UntypedFormControl('');
	toDateControl = new UntypedFormControl('');

	displaydetails: any = [];
	displaymattable: any = false;
	transactions: any = []
	constructor(private creditService: CreditService, private route: ActivatedRoute,public ordersService: OrdersService,public globalSearchService: GlobalSearchService,public router: Router, public customerService: CustomerService,private modalService: NgbModal, private globalsService: GlobalsService) {

		this.fromdate = new Date(this.fromdate.setFullYear(this.fromdate.getFullYear() - 1));
		this.toDateControl.setValue(this.todate);
		this.fromDateControl.setValue(this.fromdate);
	}

	loadTransactions() {
		let search = {
			from: this.fromDateControl.value.toISOString(),
			to: this.toDateControl.value.toISOString(),
			debtor: this.customerdata.debtorno
		}
		if(this.searchingsubscription) {
			this.searchingsubscription.unsubscribe();
		}

		this.searchingsubscription = this.customerService.getCustomerCreditableInvoices(search).subscribe(r=> {
			this.transactions = r
		})
	}

	searchTransaction(value) {

		if(this.searchingsubscription) {
			this.searchingsubscription.unsubscribe();
		}

		this.searchingsubscription = this.customerService.searchTransactions(this.customerdata.debtorno, value).subscribe(async (results:any) => {
			this.transactions = results
		});
	}



	openDocument(transaction: any, content: any, display: string) {
		this.globalsService.getInvoice(transaction.id, display).subscribe((result: any) => {
			this.displaydetails = result;
			this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		})
	}

	loadCredit(id: any) {
		this.router.navigate(['/receivables/credit-invoice/'+id]);
		this.cart_updated.emit(id);
	}

	ngOnInit(): void {
		this.customerService.getCustomerOrders(this.customerdata.debtorno, '').subscribe(results => {
			this.transactions = results;
		});
	}

}
