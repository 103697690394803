import { Component, OnInit , ViewChild,ElementRef} from '@angular/core';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router} from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, fromEvent } from "rxjs";
import {debounceTime, distinctUntilChanged, startWith, tap, delay} from 'rxjs/operators';
import { Location } from '@angular/common'

@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.scss']
})
export class CustomerSearchComponent implements OnInit {

	title = 'customers';
	color: any = 'blue';

	constructor(private fb: UntypedFormBuilder,private globalSearchService: GlobalSearchService, public customerService: CustomerService,public router: Router,private location: Location) { }

	ngOnInit(): void {
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.mesagesallowed.next(true);
	}

	selectCustomer(row: any) {
		this.router.navigate(['./orders/entry/'+row.debtorno+'/'+row.branchcode]);
	}

	back(): void {
		this.location.back()
	}
}
