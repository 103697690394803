<div class="row mt-2">
	<div class="col-md-3">
		<h5><b>Bins</b>&nbsp;<span *ngIf="itembins">({{ itembins.length }})</span></h5>
		<div class="table" *ngIf="itembins">
			<table class="table table-fixed table-sm">
				<thead>
					<tr>
						<th class="text-left">BIN</th>
						<th class="text-center">QTY</th>
					</tr>
				</thead>
				<tbody>
					<ng-container *ngIf="itembins && itembins.length">
						<tr *ngFor="let bin of itembins" >
							<td>
								<button mat-icon-button (click)="selBin(bin.bin)">
									<mat-icon>add_box</mat-icon>
								</button>
								{{bin.bin}}
							</td>
							<td class="text-center">{{bin.quantity}}</td>
						</tr>
					</ng-container>

				</tbody>
			</table>
		</div>
		<ng-container *ngIf="!itembins">
			<h4>NO BIN'S</h4>
		</ng-container>
	</div>
	<div class="col-md-9">
		<ng-container *ngIf="adjustmentForm">
			<h5><b>Adjustment</b></h5>
			<div class="card">
				<div class="card-body">
					<form [formGroup]="adjustmentForm" autocomplete="off">
						<div class="row">
							<div class="col-md-12">
								<mat-form-field appearance="standard">
									<mat-label>Warehouse</mat-label>
									<mat-select formControlName="loccode">
										<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
											{{ loc.locationname }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-md-3">
								<mat-form-field appearance="standard">
									<mat-label>Bin to Adjust</mat-label>
									<input matInput formControlName="bin" id="bin" #bin [matAutocomplete]="binLookup" (keydown.enter)="$event.preventDefault();" (autoActiveFirstOption)="false" (autoSelectActiveOption)="false"/>
									<mat-autocomplete #binLookup="matAutocomplete">
										<mat-option *ngFor="let v of bins" [value]="v.bin">
											{{ v.bin }}
										</mat-option>
									</mat-autocomplete>
									<mat-hint *ngIf="!binExists()">Creating New Bin</mat-hint>
								</mat-form-field>
							</div>
							<div class="col-md-2">
								<mat-form-field appearance="standard" class="text-center">
									<mat-label>Qty</mat-label>
									<input matInput formControlName="qty" type="text" #qty id="qty" />
								</mat-form-field>
							</div>
							<div class="col-md-2">
								<mat-form-field appearance="standard" class="text-center">
									<mat-label>Cost</mat-label>
									<input matInput formControlName="cost" type="text" />
								</mat-form-field>
							</div>
							<div class="col-md-10">
								<mat-form-field appearance="standard">
									<mat-label>Adjustment Reason</mat-label>
									<input matInput formControlName="reason" type="text" #reason  />
								</mat-form-field>
							</div>
							<div class="col-md-2 text-center">
								<button mat-raised-button n-submit color="warn" class="mt-3" (click)="addMovement()">
									<mat-icon>add</mat-icon> Add
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</ng-container>
	</div>
</div>


<ng-container *ngIf="movements.length">
	<h5><b>Adjustments</b></h5>
	<div class="row">
		<div class="col-9">
			<div class="table">
				<table class="table table-fixed table-sm">
					<thead>
						<tr>
							<th class="text-left">BIN</th>
							<th class="text-left">Reason</th>
							<th class="text-center">QTY</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let move of movements">
							<td class="text-left">
								<button mat-button color="accent" (click)="removeMovement(move)">
									<mat-icon>remove_circle</mat-icon> {{move.bin}}
								</button>
							</td>
							<td class="text-left">{{move.reason}}</td>
							<td class="text-center">{{move.qty}}</td>
						</tr>
						<tr *ngIf="!movements.length">
							<td colspan="3" class="text-left">
								<h4><b>Add an adjustments</b></h4>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="col-3 text-right">
			<h5>NEW QOH {{ totalQoh() }} </h5>
			<button mat-raised-button [color]="movements.length ? 'accent': 'warn'" (click)="makeAdjustment()">
				Adjust Stock
			</button>
		</div>
	</div>
</ng-container>
