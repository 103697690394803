import { Component, OnDestroy, SimpleChanges, OnChanges, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, Pipe, PipeTransform, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


import { trigger, state, style, transition, animate, animation } from '@angular/animations';
import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { UsersService } from '../../services/users.service';
import { PurchasingService } from '../../services/purchasing.service';
import { CreditService } from '../../services/credit.service';
import { OmsService } from '../../services/oms.service';


@Component({
	selector: 'app-shared-credit-entry',
	templateUrl: './shared-credit-entry.component.html',
	styleUrls: ['./shared-credit-entry.component.scss'],
	encapsulation: ViewEncapsulation.Emulated,
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotate3d(0, 1, 0, 179deg)',
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('300ms ease-in')),
			transition('active => inactive', animate('300ms ease-out')),
		]),
		trigger('cinfo', [
			transition(':enter', [
				animate('50ms', style({ opacity: 1, height: 100 })),
			]),
			transition(':leave', [
				animate('100ms', style({ opacity: 1, height: 0 }))
			]),
		]),
		trigger('itemSearch', [
			transition(':enter', animation([style({ transform: 'translate(-800px,0)', }),
				animate('0.2s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(1200px,0)', })
				),
			])),
		]),
		trigger('addFilters', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
		trigger('navIn', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.10s cubic-bezier(0.59, 0.32, 0.38, 0.33)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.0s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
	],
})

export class SharedCreditEntryComponent implements OnInit {

	@Input() customer: any = false;
	@Input() original_order: any = false;
	@Input() flip: string = 'inactive';
	@Input() type: string = '11';
	@Input() invoicing: any = false;
	@Input() cart_items: any = [];
	@Output() reloadCustomer = new EventEmitter <any> ();

	CHAT_ROOM = "OrderBoard";
	config: any = [];
	user:any = [];
	proceed: any = true;
	total_cart_qty: number = 0;
	cart_totals: any = [];

	overcredit: any = false;
	allowed_credit: any = true;
	overcredit_override: any = true;
	editing_order: any = false;
	order_details: any = false;
	//orderResults: any = false;

	customer_form: any = false;

	po_vendors:any = [];
	order: any = false;
	created_pos: any = false;
	extra_pos: any = false;
	order_lines: any = false;
	loading_remote: boolean = false;
	orderno: any = false;
	remoteqtys: any = [];
	sending: boolean = false;
	purchase_item:any = false;
	purchordercreated_details:any = false;
	purchasedetails:any = false;
	purchordercreated:any = false;

	baselink: string = '';
	pickingInvoiceLink: string = '';

	orderDetails: any = false;
	orderResults: any = false;
	invoiceResults: any = false;

	anydata = {
		"success": true,
		"orderno": "754076",
		"transno": null,
		"order": "754076",
		"id": null
	};

	freight_charge: any = 0.00;
	fetchingTotals: any = false;
	fetchingCart : any = false;
	purchase_items: any = [];
	creditLink: string = '';
	baseUrl: string = '';

	constructor(private creditService: CreditService, private purchasingService: PurchasingService, private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, public omsService: OmsService, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal, public usersService:UsersService) {

		this.config = this.globalsService.getApiConfig();

		this.baseUrl = this.config.apiServer.baseUrl;

		this.baselink = this.config.apiServer.baseUrl + this.config.apiServer.creditPickingLink;
		this.creditLink = this.config.apiServer.creditLink
		this.pickingInvoiceLink = this.config.apiServer.baseUrl + this.config.apiServer.creditLink;

		// this.omsService.subscribeToCarts((err, data) => {
		// 	if (data.data[0].customer == this.customer.debtorno) {
		// 		this.creditService.getCart(this.customer.debtorno, this.customer.branchcode).subscribe(async (items: any) => {
		// 			this.cart_items = items;
		// 		});
		// 	}
		// })

		this.globalSearchService.user.subscribe((results: any) => {
			if (results) {
				this.user = results.user;
			}
		});
	}


	invoiceOrder(orderno: any) {
		this.invoicing = orderno;
		this.orderResults = false;
		//this.router.navigate(['/orders/invoice/' + this.orderResults.orderno]);
		this.router.navigate(['/receivables/dispatch/'+ orderno]);
	}

	updateCustomer(event: any) {
		this.customer_form = event
		if(this.editing_order){
			let oldBranch = this.order_details.header.branchcode;
			let newBranch = this.customer_form.get("branchcode").value;
			oldBranch != newBranch ? this.order_details.header.branchcode = newBranch : '';
		}
		this.reloadCustomer.emit(this.customer_form);
	}

	updateShipping(value: any) {
		this.freight_charge = value
		this.updateTotals();
	}

	addItems(items: any) {

		this.creditService.addMultipleToOrder(items, this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {

			if (results.success) {
				this.cart_items = results.cart;
				//this.updateTotals();

				if(results.warn) {
					this.globalSearchService.showNotification(results.message, 'warning', 'bottom', 'left');
				}


				items.forEach((item, index) => {
					//reset qtys and show notification
					if (parseFloat(item.quantity) != 0) {
						let color = (item.isnegative) ? 'warn' : 'success';
						this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Added', color, 'bottom', 'left');
					}
				});



			} else {
				this.globalSearchService.showNotification(results.message, 'danger', 'bottom', 'left');
			}

			this.updateTotals();
		});


	}

	removeFromOrder(cart_id) {
		this.creditService.removeFromOrder(cart_id, this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {
			this.cart_items = results;
			this.globalSearchService.showNotification('Item Removed', 'danger', 'bottom', 'left');
			this.updateTotals();
			this.sendCartUpdate();
		});
	}

	updatePrice(event: any, item: any) {
		let index = this.cart_items.indexOf(item);
		this.cart_items[index].price = event.target.value;
		this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
		this.updateCartItem(this.cart_items[index]);
	}

	updateQuantity(event: any, item: any) {
		let index = this.cart_items.indexOf(item);
		this.cart_items[index].quantity = event.target.value;
		this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
		this.updateCartItem(this.cart_items[index]);
	}

	updateDiscount(event: any, item: any) {
		let index = this.cart_items.indexOf(item);
		this.cart_items[index].discount = event.target.value;
		this.updateCartItem(this.cart_items[index]);
	}

	updateNote(event: any, item: any) {
		let index = this.cart_items.indexOf(item);
		this.cart_items[index].notes = event.target.value;
		this.updateCartItem(this.cart_items[index]);
	}

	getDiscounPrice(item: any) {
		var total = (item.price - (item.price * (item.discount / 100)));
		if(total < 0) {
			total = 0;
		}
		return total;
	}

	getDiscountTotal(item: any) {
		var total = item.quantity * (item.price - (item.price * (item.discount / 100)));
		if(total < 0) {
			total = 0;
		}
		return total;
	}

	updateChildren() {
		this.cart_items.forEach( (item: any) => {
			if(item.parent_id) {
				let parent = this.cart_items.filter(i => i.cart_id == item.parent_id)[0]

				//will not work for pers
				item.quantity = parent.quantity;
			}
		})
	}

	updateCartItem(item: any) {

			let data = {
				customer: this.customer.debtorno,
				branch: this.customer.branchcode,
				cartid: item.cart_id,
				cart_id: item.cart_id,
				price: item.price,
				option: item.options,
				quantity: item.quantity,
				discount: item.discount,
				notes: item.notes,
				stockid: item.stockid,
				editing_order: this.editing_order,
				order_details: this.order_details,
			};

			this.creditService.updateOrder(data).subscribe((results: any) => {
				this.updateTotals();
				this.updateChildren();
			});

	}

	updateTotals() {



		this.creditService.getTotals(this.customer.debtorno, this.customer.branchcode, this.freight_charge).subscribe((results: any) => {
			this.cart_totals = results;
			this.total_cart_qty = this.cart_items.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.quantity);
			}, 0);

			if(!this.total_cart_qty) {
				this.flip = 'inactive';
			}
			this.checkCreditLimit()
		});

	}

	getTotal() {

		let total = this.cart_totals.filter(t => {
			return t.code=='total';
		})[0]

		var value = 0.00;
		if(total) {
			value = parseFloat(parseFloat(total.text).toFixed(2))
		}

		return value;
	}

	checkCreditLimit() {

	}


	stopProp(event: any): void {
		event.stopPropagation();
	}

	sendCartUpdate() {

		if (this.user) {

			//let data = [{ customer: this.customer.debtorno, user: this.user }]

			//this.omsService.sendCartUpdate({ data, roomName: 'CartRoom' }, cb => {});
		}
	}

	toggleFlip() {

		switch (this.flip) {
			case 'inactive':
				this.flip = 'active';
				break;
			default:
				this.flip = 'inactive';
			break;
		}
	}

	reloadOrder(event: any) {

		//todo - reload header into form
		this.creditService.getCart(this.customer.debtorno, this.customer.branchcode).subscribe(async (items: any) => {
			//this.selectedindex = 0;
			this.editing_order = true;
			this.order_details = event;
			this.cart_items = items;
			if (items.length) {

				if (!items[0].override || items[0].override == '') {
					//order was not flagged - do not stop it
					this.proceed = true;
					this.allowed_credit = true;
					this.overcredit_override = items[0].override
				}
			}

			this.updateTotals();
		});
	}

	ngOnInit(): void {
		// this.updateCart();
	}

	ngOnChanges(changes: any) {
		this.updateCart();
	}

	saveQuote() {
		this.orderService.saveQuote(this.customer, this.cart_items, this.cart_totals, this.user).subscribe((result: any) => {
			this.globalSearchService.showNotification('Quote '+result.orderno+' Saved', 'success', 'bottom', 'left');
			this.reloadCustomer.emit(true)
			this.clearCart();
			this.updateCart();
		});
	}

	emitCustomerUpdate(event: any) {
		this.invoicing = false;
		this.reloadCustomer.emit(event);
	}

	clearCart() {
		this.creditService.clearOrder(this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {
			this.flip = 'inactive';
			this.updateCart();
		});
	}

	placeOrder() {

		//this.checkCredit();
		this.globalSearchService.getFormValidationErrors(this.customer_form);
		this.customer_form.markAllAsTouched();

		this.allowed_credit = true;
		/*
		if (!this.proceed && !this.allowed_credit) {
			this.modalService.open(this.creditover, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

			}, (reason) => {
				this.item_details = false;
			});
		}
		*/
		//customer form is emitted in
		if (this.customer_form.valid && this.proceed) {

			if (!this.editing_order) {
				this.creditService.createOrder(this.customer_form.value, this.cart_items, this.cart_totals, this.user).subscribe((results: any) => {
					this.globalSearchService.orderResults.next(results);
					this.orderResults = results;
					this.flip = 'inactive';
					this.orderDetails = results;
					this.reloadCustomer.emit(results);
					this.editing_order = false;
					//let data = { message: 'neworder' }
					//this.omsService.sendCardUpdate({data, roomName: this.CHAT_ROOM}, cb => {
					//});
					//this.omsService.sendOpenOrderUpdate({data, roomName: this.CHAT_ROOM}, cb => {
					//});

					this.creditService.clearOrder(this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {
						this.cart_items = [];
						this.total_cart_qty = 0;
						let data = {};
						this.omsService.sendOrderPickUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
						//this.router.navigate(['/orders/invoice/' + this.orderResults.orderno]);
					});


				});
			} else {
				this.creditService.updateSalesOrder(this.customer_form.value, this.cart_items, this.cart_totals, this.order_details, this.user).subscribe((results: any) => {
					this.flip = 'inactive';
					this.globalSearchService.orderResults.next(results);
					this.orderResults = results;
					let data = {};
					this.omsService.sendOrderPickUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
					//this.router.navigate(['/orders/invoice/' + this.orderResults.orderno]);
				});
			}
		}

		this.updateCart()
	}

	showInvoiceResultandReset(event:any) {
		//this.invoiceResults = event;
		this.globalSearchService.showNotification('Credit #'+ event.invoice.transno + ' Created', 'success', 'bottom', 'left');
		this.resetOrder();
	}

	resetOrder() {
		this.invoicing = false;
		this.orderResults = false;
		this.order_details = false;
		this.editing_order = false;
		this.clearCart();
		this.updateTotals();
	}

	updateCart() {
		this.creditService.getCart(this.customer.debtorno, this.customer.branchcode).subscribe( (items: any) => {
				if(items.length) {
					this.flip ='active';
				}
				let hasorderno = items.map(item => { return (item.orderno != 0) ? item.orderno : false })[0];
				//todo rework this - doubles up on the calls
				if (hasorderno && !this.order_details.header) {
					this.creditService.loadRma(hasorderno).subscribe((results: any) => {
						this.editing_order = true;
						this.order_details = results;
						this.customer.editingorder = results.header
						this.order_details = results;
						this.cart_items = results.cart;
					});
				} else {
					this.cart_items = items;
				}

			this.updateTotals();
		});
	}

	editOrder(orderno: any) {
		this.orderResults = false;
		this.creditService.loadRma(orderno).subscribe((results:any) => {
			this.editing_order = true;
			this.order_details = results;

			this.fetchingCart = this.creditService.getCart(results.header.debtorno, results.header.branchcode).subscribe(async (items: any) => {


				//this.selectedindex = 0;
				this.editing_order = true;
				this.order_details = results;
				this.cart_items = items;
				this.customer.editingorder = results.header

				if (items.length) {

					if (!items[0].override || items[0].override == '') {
						//order was not flagged - do not stop it
						this.proceed = true;
						this.allowed_credit = true;
						this.overcredit_override = items[0].override
					}
				}

				this.updateTotals();
			});

			this.flip = 'active';

			//this.router.navigate(['/orders/entry/'+link]);
		})
	}
}
