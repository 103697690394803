<ng-template #purchasedetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Purchase Information</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row" *ngIf="purchase_item">
			<div [ngClass]="purchase_item.purchdata.length == 1 ? 'col-md-12': 'col-md-6'" *ngFor="let pdata of purchase_item.purchdata">
				<div class="card highlight" (click)="setPurchOrderOptions(purchase_item, pdata)" [ngClass]="(pdata.supplierid == purchase_item.selected_option.supplierno) ? 'highlighted' : '' ">
					<div class="card-header text-center">
						<h4 class="card-title text-center">{{ pdata.supplierid }} - {{ pdata.suppname }} <b>{{ pdata.price | currency }}</b></h4>
					</div>
					<div class="card-body text-center">
						<div class="col-12">
							<h5>{{ pdata.stockid }} {{ pdata.supplierdescription }}</h5>
						</div>
						<div class="col-12 p-0 m-0">
							<ul class="entry-list p-0 m-0" *ngFor="let remote of pdata.remote">
								<li>
									<div class="spec-label">{{ remote.feedlabel }}:</div>
									<div class="spec-value">{{ remote.qty }}</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #itemhistoryele let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Item Sales History {{ item.item.stockid }} {{ purchitem.indexmonth }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="text-center" *ngIf="itemhistory">
			<p class="text-left" *ngIf="item.descriptions.description"> {{ item.descriptions.description }} </p>
			<p class="text-left" *ngIf="item.descriptions.benefits"> {{ item.descriptions.benefits }} </p>

			<div class="row smaller-font">
				<div class="col-md-3 ml-0 mr-0 pl-0 pr-0" [ngClass]="purchitem.remote_inventory ? 'col-md-3' : 'col-md-3'">
					<ul class="entry-list p-0 m-0 bold">
						<li>
							<div class="spec-label">Prd Sales:</div>
							<div class="spec-value">{{ purchitem.period_sales }}</div>
						</li>
						<li>
							<div class="spec-label">Target:</div>
							<div class="spec-value">{{ purchitem.target  }}</div>
						</li>
						<li>
							<div class="spec-label">Avg Sales:</div>
							<div class="spec-value">{{ purchitem.weekly_sales_amt }}</div>
						</li>
					</ul>
				</div>
				<div class="col-md-3 ml-0 mr-0 pl-0 pr-0">
					<ul class="entry-list p-0 m-0 bold">
						<li>
							<div class="spec-label">QOH:</div>
							<div class="spec-value">{{ purchitem.qoh }}</div>
						</li>
						<li>
							<div class="spec-label">Back Order:</div>
							<div class="spec-value">{{ purchitem.qos }}</div>
						</li>
						<li>
							<div class="spec-label">On Order:</div>
							<div class="spec-value">{{ purchitem.qop }}</div>
						</li>
					</ul>
				</div>
				<div class="col-md-3 ml-0 mr-0 pl-0 pr-0">
					<ul class="entry-list p-0 m-0 bold">
						<li>
							<div class="spec-label">Avail:</div>
							<div class="spec-value">{{ ( purchitem.qoh + purchitem.qop ) - purchitem.qos }}</div>
						</li>
						<li>
							<div class="spec-label">Net Avg:</div>
							<div class="spec-value">{{ purchitem.seasonaldetails.grandaverage | number:'1.0-0'}}</div>
						</li>
						<li>
							<div class="spec-label">Net Total:</div>
							<div class="spec-value">{{ purchitem.seasonaldetails.grandtotal | number:'1.0-0' }}</div>
						</li>
					</ul>
				</div>
				<div class="col-md-3 ml-0 mr-0 pl-0 pr-0">
					<ul class="entry-list p-0 m-0 bold">
						<li>
							<div class="spec-label">Period Target:</div>
							<div class="spec-value">{{ purchitem.qty_purchase }}</div>
						</li>
						<li>
							<div class="spec-label">Avg Month Recommend:</div>
							<div class="spec-value">{{ purchitem.avg_recomendation | number:'1.0-0' }}</div>
						</li>
						<li>
							<div class="spec-label">Seasonal Recommend:</div>
							<div class="spec-value">{{ purchitem.seasonal_recomendation | number:'1.0-0' }}</div>
						</li>
					</ul>
				</div>


				<div class="col-md-4 ml-0 mr-0 pl-0 pr-0 d-none">
					<div class="row m-0 p-0">
						<div class="col-12" *ngIf="purchitem.remote_inventory">
							<ul class="entry-list p-0 m-0 bold" *ngFor="let remote of purchitem.remote_inventory">
								<li>
									<div class="spec-label">{{ remote.feedlabel }}:</div>
									<div class="spec-value">{{ remote.qty }}</div>
								</li>
							</ul>
						</div>
						<div class="col-md-12" *ngIf="!item.remote_inventory">
							<ul class="entry-list p-0 m-0 bold">
								<li>
									<div class="spec-label">n/a:</div>
									<div class="spec-value">n/a</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<table class="table table-sm smaller-font" *ngFor="let year of itemhistory.years">
				<tr>
					<th></th>
					<th *ngFor="let month of year.sales" [ngClass]="{'highlighted-solid': futuremonths.indexOf(month.label)>= 0,
							'highlighted-prev': months.indexOf(month.label) >= 0}">
						{{ month.label }} {{ month.year }}
					</th>
				</tr>
				<tr>
					<td class="text-left">Qty</td>
					<td class="text-right" *ngFor="let month of year.qty" [ngClass]="{'highlighted-solid': futuremonths.indexOf(month.label)>= 0,'highlighted-prev': months.indexOf(month.label) >= 0}">
						{{ month.value | number:'1.0-0' }}
					</td>
				</tr>
				<tr>
					<td class="text-left">Sales </td>
					<td class="text-right" *ngFor="let month of year.sales" [ngClass]="{'highlighted-solid': futuremonths.indexOf(month.label) >= 0,'highlighted-prev': months.indexOf(month.label) >= 0}">
						{{ month.value | currency }}
					</td>
				</tr>
				<tr>
					<td class="text-left">Cost </td>
					<td class="text-right" *ngFor="let month of year.cost" [ngClass]="{'highlighted-solid': futuremonths?.indexOf(month.label)>= 0,'highlighted-prev': months.indexOf(month.label) >= 0}">
						{{ month.value | currency }}
					</td>
				</tr>
				<!--<tr>
						<td class="text-left">M Season</td>
						<td class="text-right" *ngFor="let month of purchitem.seasonals" [ngClass]="{'highlighted-solid': futuremonths.indexOf(month.label)>= 0,'highlighted-prev': months.indexOf(month.label) >= 0}">
							{{ month.projection | number:'1.0-0' }}
						</td>
					</tr>
					<tr>
						<td class="text-left">M Avg</td>
						<td class="text-right" *ngFor="let month of purchitem.seasonals" [ngClass]="{'highlighted-solid': futuremonths.indexOf(month.label)>= 0,'highlighted-prev': months.indexOf(month.label) >= 0}">
							{{ month.avg | number:'1.0-0' }}
						</td>
					</tr>
					-->
				<tr>
					<td class="text-left">Index</td>
					<td class="text-right" *ngFor="let month of purchitem.seasonals" [ngClass]="{'highlighted-solid': futuremonths.indexOf(month.label)>= 0,'highlighted-prev': months.indexOf(month.label) >= 0}">
						{{ month.index | number:'1.0-2'}}
					</td>
				</tr>

			</table>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<form [formGroup]="reportForm" *ngIf="reportForm">
	<div class="row p-0 m-0">
		<div class="col-md-2">
			<mat-form-field appearance="standard">
				<mat-label>Warehouse</mat-label>
				<mat-select formControlName="loccode">
					<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
						{{ loc.locationname }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-3">
			<app-vendor-auto-complete (vendor_selected)="updateBrands($event)"></app-vendor-auto-complete>
		</div>
		<div class="col-md-3">
			<mat-form-field appearance="standard"  >
				<mat-label>Brands</mat-label>
				<mat-select multiple formControlName="brands" (selectionChange)="updateProductLines()">
					 <mat-option *ngFor="let opt of brands" [value]="opt.name">
						{{ opt.name  }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-3">
			<mat-form-field appearance="standard"   >
				<mat-label>Product Lines</mat-label>
				<mat-select multiple formControlName="lines" >
					 <mat-option *ngFor="let opt of product_lines" [value]="opt.line_field">
					 	{{ opt.line_field  }} - {{ opt.line_description  }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-3">
			<mat-form-field appearance="standard"   >
				<mat-label>Winter Designation</mat-label>
				<mat-select multiple formControlName="season">
					 <mat-option *ngFor="let opt of winter_values" [value]="opt.value">
						{{ opt.label  }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-2">
			<mat-form-field appearance="standard">
				<mat-label>Minimum Orders</mat-label>
				<input matInput formControlName="minimum_sales">
			</mat-form-field>
		</div>
		<div class="col-md-2">
			<mat-form-field appearance="standard">
				<mat-label>Starting Date For Sales</mat-label>
				<input matInput [matDatepicker]="orderdatepicker" formControlName="startdate">
				<mat-datepicker-toggle matSuffix [for]="orderdatepicker"></mat-datepicker-toggle>
				<mat-datepicker #orderdatepicker></mat-datepicker>
			</mat-form-field>
		</div>
		<div class="col-md-2">
			<mat-form-field appearance="standard">
				<mat-label>Number of Preceding Months to Analyze</mat-label>
				<input class="text-center" type="number" placeholder="Number of Preceding Months" aria-label="Number of Preceding Months" formControlName="numberofmonths" matInput />
			</mat-form-field>
		</div>
		<div class="col-md-2">
			<mat-form-field appearance="standard">
				<mat-label>Number of weeks of inventory to stock</mat-label>
				<input class="text-center" type="number" placeholder="Number of weeks" aria-label="Number of weeks" formControlName="numberofweeks" matInput />
			</mat-form-field>
		</div>
		<div class="col-1">
			<mat-form-field appearance="standard">
				<mat-label class="text-right">Day Supply x</mat-label>
				<input tabindex="0" matInput formControlName="days_supply" class="text-right" (input)="updateTargets($event.target.value)">
				<mat-hint>Day Supply x</mat-hint>
			</mat-form-field>
		</div>
	</div>
</form>

<div class="row m-0 p-0">
	<div class="col-md-12 ">
		<div class="row p-2 md-0x border-bottom">
			<div class="col-12 text-left">
				<button mat-raised-button color="warn" (click)="getRecomendations()">Run</button>
				&nbsp;&nbsp;
				<button mat-raised-button color="accent" (click)="exportXls()" *ngIf="po_vendors_found">
					<i class="fa fa-file-excel-o"></i>&nbsp;&nbsp;XLS</button>
			</div>
			<div class="col-12">
				<div class="table table-responsive text-center" *ngIf="loading">
					<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
						<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
						</circle>
					</svg>
				</div>
			</div>


		</div>
		<ng-container *ngIf="!loading">
			<ng-container *ngIf="!results && po_vendors_found">
				<h4 class="mt-3">No Results</h4>
			</ng-container>
			<ng-container *ngIf="results">

				<mat-tab-group>
					<mat-tab label="Details">

						<ng-container *ngIf="po_vendors_found.length">
							<div class="table" *ngFor="let vendor of po_vendors_found" #exportTable>
							<!-- <h4>Filters: {{ reportForm | json }}</h4> -->
							<table class="table table-fixed table-hover table-bordered">
								<thead>
									<tr>
										<!-- <th>Brand</th> -->
										<th >ProductLine</th>
										<th class="td-item">Item#</th>
										<th class="td-max">Description</th>
										<th class="text-center">QOH</th>
										<th class="text-center">QOS</th>
										<th class="text-center">QOO</th>
										<th class="text-center" *ngFor="let rol of rolling_months">
											{{rol.month}} {{rol.year}}
										</th>
										<th class="text-center">Total 12</th>
										<th class="text-center">QOH</th>
										<th class="text-center">OutOfStockDays</th>
										<th class="text-center">DaysSupply</th>
										<th class="text-center">Daily Turn</th>
										<th class="text-center">Daily Turn * Day Supply - Available</th>

									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of results[vendor]">
										<!-- <td><b>{{ item.brand_data.brand }}</b></td> -->
										<td>{{ item.brand_data.lineid }}-{{item.brand_data.linedesc}}</td>
										<td class="td-item"><a (click)="getItemHistory(item)">{{ item.stockid }}</a></td>
										<td>{{ item.description }}</td>
										<td class="text-center">{{ item.qoh }}</td>
										<td class="text-center">{{ item.qos }}</td>
										<td class="text-center">{{ item.qop }}</td>
										<td class="text-center" *ngFor="let rol of item.rolling_year">
											{{rol.value}}
										</td>
										<td class="text-center">{{ item.rolling_total }}</td>
										<td class="text-center">{{ item.actual_on_hand }}</td>
										<td class="text-center">{{ item.daysoutofstock  }}</td>
										<td class="text-center">{{ item.days_supply | number:'1.0-2' }}</td>
										<td class="text-center">{{ item.avg_day | number:'1.0-2'}}</td>
										<td class="text-center">{{ item.target_avg_day | number:'1.0-0'}}</td>
									</tr>
								</tbody>
							</table>
							</div>
						</ng-container>
					</mat-tab>
					<mat-tab label="Results">
						<div class="table mt-2" *ngIf="po_vendors_found.length">
							<div class="container-fluid tp-box" [@flipState]="flip">
								<div class="row tp-box__side tp-box__front no-overflow">
									<mat-stepper #stepper (selectedIndex)="currentstep" class="w-100">
										<mat-step errorMessage="Review Results">

											<ng-template matStepLabel>{{ reviewlabel }}</ng-template>

											<div class="row mt-3 mb-3 no-overflow">

												<div class="col-12 text-right">
													<button mat-raised-button color="accent" matStepperNext>Next</button>
												</div>

												<div class="col-12 mt-3">

													<!--<mat-slide-toggle [formControl]="fully_available" (change)="filterResults($event)" (checked)="fully_available.value">Available to Purchase Only?</mat-slide-toggle>
											-->
													<div class="table" *ngFor="let vendor of po_vendors_found">



														<h4 class="card-title text-left mt-3">{{ vendor }}</h4>



														<div class="wrapper">
															<table class="table table-fixed w-100">
																<thead>
																	<tr>
																		<th class="header"></th>
																		<th class="header">Vendor</th>
																		<th class="header">Item</th>

																		<th class="header text-center">YTD Qty Sales</th>
																		<th class="header">QOH</th>
																		<th class="header text-center">Committed</th>
																		<th class="header text-center">OnOrder</th>
																		<th class="header text-center">Avail</th>
																		<th class="header">MIN</th>
																		<th class="header">MAX</th>
																		<th class="header text-right">Remote Avail</th>
																		<th class="header text-right">Price</th>
																		<th class="header text-right">Order</th>
																		<th class="header text-right">Qty</th>
																	</tr>
																</thead>
																<tbody>
																	<tr *ngFor="let item of results[vendor]">
																		<td>
																			<button class="mb-0 pt-0 pb-0 " mat-icon-button color="accent" (click)="removeItem(item,vendor)">
																				<mat-icon>remove</mat-icon>
																			</button>

																			<button class="mb-0 pt-0 pb-0 " mat-icon-button color="warn" (click)="viewPurchData(item)">
																				<mat-icon>settings</mat-icon>
																			</button>
																		</td>
																		<td>{{ item.vendor }}</td>
																		<!--<td (click)="getItemHistory(item)"> {{ item.stockid }}</td>-->
																		<td><a (click)="getItemHistory(item)"><b>{{ item.brand_data.brand }}</b><br>{{ item.stockid }}</a><br />
																			{{ item.description }}
																		</td>

																		<td class="text-center">{{ item.itemhistory.totals.thisyear_total_qty | number:'1.0-0' }}</td>
																		<td class="text-center">{{ item.qoh }}</td>
																		<td class="text-center">{{ item.qos }}</td>
																		<td class="text-center">{{ item.qop }}</td>
																		<td class="text-center">{{ ( item.qoh + item.qop ) - item.qos }}</td>

																		<td class="text-center">{{ item.minimum }}</td>
																		<td class="text-center">{{ item.maximum }}</td>
																		<td class="text-center">{{ item.total_available }}</td>
																		<td class="text-right">
																			{{ item.price | currency }}
																			<!--<input matInput type="text" placeholder="Price" [(value)]="item.price" (input)="updatePrice($event.target.value, item)"> -->
																		</td>
																		<td class="text-right">{{ item.target_purchase }}</td>
																		<td class="text-right bolder" [ngClass]="{'bg-danger text-white bolder': item.total_available == 0 , 'bg-warning text-dark bolder': item.target_purchase > item.total_available && item.total_available > 0 }">
																			<input matInput type="number" class="form-control" placeholder="Purchase Qty" [(value)]="item.qty_purchase" (input)="updateQuantity($event.target.value, item)" >
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</mat-step>
										<mat-step errorMessage="Verify Fillable">
											<ng-template matStepLabel>Verify Purchase Order(s)</ng-template>
											<table class="table table-sm w-100">
												<div class="col-12 text-right" *ngIf="results.length">

													<button mat-raised-button color="warn" matStepperNext>Next</button>
												</div>
												<ng-container *ngFor="let vendor of po_vendors_found">
													<div class="col-12 m-0 p-0 mb-2 mt-2" *ngIf="results[vendor]">
														<ng-container *ngIf="results[vendor][0] && results[vendor][0].export_template">
														<button mat-raised-button color="primary" (click)="exportTemplate()" *ngIf="this.config.env.package == 'tires' && results[vendor][0].export_template ">
														<i class="fa fa-file-excel-o"></i>&nbsp;&nbsp;TREADNET</button>&nbsp;&nbsp;&nbsp;
														</ng-container>
													</div>

													<div class="card mt-2" class="col-md-12">
														<!-- *ngIf="vendorHasFillable(vendor)" -->
														<div class="row p-0 m-0">

															<div class="col-md-6">
																<button mat-raised-button color="accent" matStepperPrevious>Previous</button>
															</div>
															<div class="col-md-6 text-right">
																<ng-container *ngIf="!sending">
																	<button mat-raised-button color="warn" (click)="printResults(vendor)">
																		<i class="fa fa-file-pdf-o"></i> &nbsp;Print PO Preview</button>
																	&nbsp;&nbsp;
																	<button mat-raised-button aria-label="Change Vendor" color="accent" (click)="submitPo(vendor)" class="mt-2">
																		Next
																	</button>
																</ng-container>
																<ng-container *ngIf="sending">
																	<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
																		<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
																		</circle>
																	</svg>
																</ng-container>
															</div>
														</div>
														<div class="col-md-12">
															<h4 class="card-title text-left mt-3"><b>{{ vendor }}</b></h4>
														</div>
														<div class="row p-0 m-0" *ngIf="!vendorHasFillable(vendor)">
															<div class="col-6">
																<h4>No Items To Order</h4>
															</div>
														</div>
														<div class="card-body">
															<div class="row " *ngFor="let line of results[vendor]">
																<ng-container *ngIf="line.qty_purchase > 0 ">
																	<div class="col-md-12 border-top text-left">
																		<div class="row">
																			<div class="col-md-1 p-0" [ngClass]="{'bg-danger text-white bolder': line.total_available == 0 , 'bg-warning text-dark bolder': line.target_purchase > line.total_available && line.total_available > 0 }">
																				<button mat-icon-button aria-label="Change Vendor" color="primary" (click)="viewPurchData(line)">
																					<mat-icon class="mt-2 smaller-font">edit</mat-icon>
																				</button>
																				<button class="mb-0 pt-0 pb-0 " mat-icon-button color="accent" (click)="removeItem(line,vendor)">
																					<mat-icon>remove</mat-icon>
																				</button>
																			</div>
																			<div class="col-md-3 mt-2 p-0">
																				<b>{{ line.brand_data.brand }}</b><br />
																				{{ line.stockid }}<br />
																				{{ line.description }}
																			</div>
																			<div class="col-md-3">
																				<ng-container>
																					<div class="col-md-12 p-0">
																						<ul class="entry-list p-0 m-0 bold">
																							<li>
																								<div class="spec-label">QOH</div>
																								<div class="spec-value">{{ line.qoh }}</div>
																							</li>
																						</ul>
																					</div>
																				</ng-container>
																				<ng-container *ngIf="line.selected_option">
																					<div class="col-md-12 p-0" *ngFor="let remote of line.selected_option.remote">
																						<ul class="entry-list p-0 m-0 bold">
																							<li>
																								<div class="spec-label">{{ remote.feedlabel }}:</div>
																								<div class="spec-value">{{ remote.qty }}</div>
																							</li>
																						</ul>
																					</div>
																				</ng-container>
																			</div>
																			<div class="col-md-2">
																				<mat-form-field appearance="standard">
																					<mat-label>Qty</mat-label>
																					<span matPrefix>#</span>
																					<input matInput type="number" value="{{ line.qty_purchase | number }}" (input)="updateQuantity($event.target.value, line )" class="text-right form-control">
																					<span matSuffix>/ {{ line.target_purchase }}</span>
																				</mat-form-field>
																			</div>
																			<div class="col-md-2">
																				<mat-form-field appearance="standard" class="">
																					<mat-label>
																						Price
																					</mat-label>
																					<span matPrefix>$</span>
																					<input matInput (input)="updatePrice($event.target.value, line )" value="{{ line.selected_option.price | number:'1.2-2' }}" class="text-right form-control" >
																				</mat-form-field>
																			</div>
																			<div class="col-md-1 mt-4">
																				<mat-label>
																					Total
																				</mat-label>
																				<b>{{ line.selected_option.price * line.qty_purchase | currency }}</b>
																			</div>
																		</div>
																	</div>
																</ng-container>
															</div>
														</div>
													</div>
												</ng-container>
											</table>
										</mat-step>
									</mat-stepper>
								</div>
							</div>
						</div>

					</mat-tab>
					<mat-tab label="Un-fillable Items">
						<h4 *ngIf="!watch_items.length" class="mt-3">No Un-fillable Items Found</h4>
						<ng-container *ngIf="watch_items.length">
							<div class="row p-0 m-0">
								<div class="col-12 text-right mt-2 mb-2">
									<button mat-raised-button aria-label="Discontinue Items" color="primary" (click)="discontinueItems()" >
										Discontinue Items
									</button>
								</div>
							</div>
							<div class="wrapper">
								<table class="table table-fixed w-100">
									<thead>
										<tr>
											<th class="header">Discontinue</th>
											<th class="header">Vendor</th>
											<th class="header">Item</th>
											<th class="header text-center">Committed</th>
											<th class="header text-center">OnOrder</th>
											<th class="header text-center">Avail</th>
											<th class="header">QOH</th>
											<th class="header">MIN</th>
											<th class="header">MAX</th>
											<th class="header text-right">Total Available</th>
											<th class="header text-right">Order Qty</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let item of watch_items">
											<td class="text-center">
												<mat-checkbox [(ngModel)]="item.watchlist"></mat-checkbox>
											</td>
											<td>
												<button class="mb-0 pt-0 pb-0 " mat-icon-button color="warn" (click)="viewPurchData(item)">
													<mat-icon>settings</mat-icon>
												</button>
												{{ item.vendor }}
											</td>
											<!--<td (click)="getItemHistory(item)"> {{ item.stockid }}</td>-->
											<td><b>{{ item.brand_data.brand }}</b><br>{{ item.stockid }}<br />
												{{ item.description }}
											</td>
											<td class="text-center">{{ item.qos }}</td>
											<td class="text-center">{{ item.qop }}</td>


											<td class="text-center">{{ ( item.qoh + item.qop ) - item.qos }}</td>
											<td class="text-center">{{ item.qoh }}</td>
											<td class="text-center">{{ item.minimum }}</td>
											<td class="text-center">{{ item.maximum }}</td>
											<td class="text-right">
												{{ item.total_remote_stock }}
											</td>
											<td class="text-right bolder">
												{{ item.target_purchase }}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</ng-container>
					</mat-tab>
				</mat-tab-group>
			</ng-container>
		</ng-container>
	</div>
</div>
