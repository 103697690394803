import { Injectable } from '@angular/core';
import { catchError , retry} from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders,HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { User } from './classes/user';

@Injectable({
  providedIn: 'root'
})
export class DataService {
	public user: User;
	public input: any[];
	public key: string = 'UET6DFMPAXW7BKCB';

	constructor(private http: HttpClient,private httpClient: HttpClient) { }

	public login(input) {
		localStorage.setItem('user', JSON.stringify(input));
		return true;
	}

	public logout() {
		localStorage.removeItem('user');
		return true;
	}

	public getDashBoard() {
		var method = 'dashboard'+this.getKey();
		return this.http.get(this.getApi()+method);
	}

	public getNegative() {
		var method = 'dashboard/negativeinv'+this.getKey();
		return this.http.get(this.getApi()+method);
	}

	public getNeedLocation() {
		var method = 'dashboard/needloc'+this.getKey();
		return this.http.get(this.getApi()+method);
	}

	public getOpenCOunt() {
		var method = 'dashboard/openorders'+this.getKey();
		return this.http.get(this.getApi()+method);
	}

	public getOrderCountByDay() {
		var method = 'dashboard/getordercountbyday'+this.getKey();
		return this.http.get(this.getApi()+method);
	}

	public getReceievedTotalByDay() {
		var method = 'dashboard/getposcountbyday'+this.getKey();
		return this.http.get(this.getApi()+method);
	}

	public getTaxOrders() {
		var method = 'dashboard/gettaxorders'+this.getKey();
		return this.http.get(this.getApi()+method);
	}
	public getKey() {
		return '&apikey='+this.key;
	}


	private getApi() {
		return 'https://clevehilltire.com/v4/?route=apir/'
		//return 'http://10.60.60.210/?route=apir/';
	}

}
