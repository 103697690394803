import { Injectable } from '@angular/core';
import { ActivatedRoute,ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { PermissionsService } from '../services/permissions.service';
import { GlobalSearchService } from '../services/globalsearchservice.service';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { RouteInfo, ROUTES } from '../classes/routes';

@Injectable()
export class AuthGuardService implements CanActivate {
	menuItems: any = [];;
	pagesubscription: any;
	user: any = [];
	constructor(
			protected auth: AuthService,
			public router: Router ,
			public currentroute: ActivatedRoute ,
			protected permissionsService: PermissionsService,
			protected globalSearchService: GlobalSearchService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
	//canActivate(route: ActivatedRouteSnapshot, router: Router): boolean {
		let logged = this.auth.isAuthenticated;
		if (!this.auth.isAuthenticated()) {
			this.router.navigate(['auth/login']);
			return false;
		}
		//state.url
		if(state.url && state.url != 'auth/permission') {
			this.globalSearchService.user.subscribe( user => {
				this.user = user;
				this.pagesubscription = user.user.access_permissions.includes(state.url);

				//need to rethink this forces every page vs every parent.
				if(!this.pagesubscription) {
					//check children
					//this.router.navigate(['auth/permission']);
				}
			});
		}
		/*
		this.globalSearchService.user.subscribe( user => {
			return this.permissionsService.checkPermission(user, this.router.url, []);
		})
		*/

		return true;
	}
}
