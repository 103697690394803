import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, AfterViewInit } from '@angular/core';
import { ViewportScroller } from "@angular/common";

import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { DataService } from '../../data.service';
import { OmsService } from '../../services/oms.service';

import { OrdersService } from '../../services/orders.service';
import { DispatchService } from '../../services/dispatch.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import * as XLSX from 'xlsx';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalsService } from 'app/services/globals.service';


@Component({
	selector: 'app-order-check',
	templateUrl: './order-check.component.html',
	styleUrls: ['./order-check.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('250ms ease-in')),
			transition('active => inactive', animate('250ms ease-out')),
		]),
		trigger('openClose', [
			state(':enter', style({ height: '*' })),
			state(':leave', style({ height: '0px' })),
			transition('false <=> true', animate(500))
		]),
		trigger('filterAnimation', [
			transition(':enter, * => 0, * => -1', []),
			transition(':increment', [
				query(':enter', [
					style({ opacity: 0, width: 0 }),
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 1, width: '*' })),
					]),
				], { optional: true })
			]),
			transition(':decrement', [
				query(':leave', [
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 0, width: 0 })),
					]),
				])
			]),
		]),
		trigger("grow", [ // Note the trigger name
			transition(":enter", [
				// :enter is alias to 'void => *'
				style({ height: "0", width: "0", overflow: "hidden" }),
				animate(200, style({ height: "*", width: "*" })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(":leave", [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0, overflow: "hidden" })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class OrderCheckComponent implements OnInit {

	@ViewChild("stockidsearchtwo") stockidSearchField: ElementRef;
	@ViewChild("stockidsearchtwo_order") stockidSearchField_order: ElementRef;
	@ViewChild("detailsModal") detailsModalEle: ElementRef;
	@ViewChild("checkingScan") checkingScanRef: ElementRef;


	flip: string = 'inactive';

	itemScanForm: UntypedFormGroup;
	search = new UntypedFormControl('')
	itemfiltertype = new UntypedFormControl(1);
	filterOrdersControl = new UntypedFormControl('');
	filterCitiessControl = new UntypedFormControl('');
	filterBinsControl = new UntypedFormControl('');
	routesControl = new UntypedFormControl('');
	binsearch = new UntypedFormControl('');
	ordersearch = new UntypedFormControl('');
	actionControl = new UntypedFormControl(true);
	scanChecking = new UntypedFormControl('');

	SENDER: any;
	messages = [];
	message: any;
	CHAT_ROOM = "OrderBoard";
	searching: any = false;

	status: any = [];
	pickable: any = [];
	carddata: any = [];
	sortcolumns: any = [];
	selectedColumn: any = [];
	selectedCity: any = [];
	selectedBin: any = [];
	removeitem: any = false;
	gettinguserpicks: any = false;

	picks: any = [];
	allpicks: any = [];

	filteredResults: any = []
	filteredItems: any = [];

	completed_items: any = [];
	mustcomplete: any = [];

	linecomplete = {};
	itemcount = {};
	errors = [];
	error: any = [];
	address: any = [];
	picks_in_hand: any = [];
	linedetails = {};
	forcesearch: boolean = true;

	filterson: boolean = false;
	column: any = false;
	groupbins: any = false;
	openOrderData: any = [];
	ordersFound: any = [];

	user: any = [];

	color: string = 'blue';
	config: any = [];
	orders: any = [];
	citys: any = [];
	bins: any = [];
	fillableonly: any = [
		{ value: 1, name: 'Picked Order Items' },
		{ value: 2, name: 'Partially Picked Order Items' },
		{ value: 3, name: 'All Order Items' },
	]

	pickedFilter = [
		//	{ label: 'Fillable', value: 'is_fillable' },
		//	{ label: 'Is Not Fillable', value: 'is_not_fillable' },
		//{ label: 'Inbound', value: 'inbound' },
		{ label: 'Picked', value: 'picked_only' },
		{ label: 'Partially Picked', value: 'partial_pick' },
		//	{ label: 'Credit', value: 'credit' },
		{ label: 'Not Due', value: 'not_due_only' },
		//	{ label: 'Not Due & Picked', value: 'not_due_and_picked' },
	]

	routes: any = [];
	selectedTab: string = 'pick';
	gettingorderstatus: any;
	orderStatus: any;

	loccode = new FormControl(['01']);
	locations: any = [];
	pickOrders: any = [];
	pickOrdersAll: any = [];
	checkingOrder: any = false;
	checkingOrderAll: any = false;
	checkingOrderOrderno: any = false;
	//OMS send to socket : orderupdate
	//OMS method : oms/picking/update

	constructor(public omsService: OmsService, public location: Location, private globalsService: GlobalsService, public ordersService: OrdersService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, public formBuilder: UntypedFormBuilder, public inventoryService: InventoryService, public dispatchService: DispatchService, private modalService: NgbModal) {

		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
			this.globalSearchService.locations.subscribe((l) => {
				this.locations = l;
				this.loccode.setValue(this.user.user.defaultlocation.loccode)
			});
		});

		this.dispatchService.getRoutes().subscribe(async (r: any) => {
			this.routes = r;
		});

		this.config = this.globalsService.getApiConfig();

		this.color = this.globalSearchService.getColor();
		const token = this.globalSearchService.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');

		if (token) {
			let data = [];
			this.omsService.setupSocketConnection(token);
			this.omsService.subscribeToOrderPicks((err, data) => {

				if (data.type == 'load') {

					if (data.data.length) {

						data.data.forEach(p => {
							let exists = this.allpicks.filter(ra => {
								return ra.pick_id == p.pick_id
							})[0];

							if (!exists) {
								this.picks.push(p)
							} else {

							}
						})

						this.allpicks = data.data;
						this.searchItems();
						this.filterRoute();
					}
				}
			});
		}
	}

	ngOnInit(): void {

		let default_status = ["picked_only", "partial_pick"];

		this.itemScanForm = this.formBuilder.group({
			stockidsearch: ['', Validators.required],
			stockidsearch_order: ['', Validators.required],
			itemfiltertypeselect: [default_status]
		});

		this.filterOrdersControl.valueChanges.subscribe((newV) => {
			this.prepData();
		});

		this.loccode.valueChanges.subscribe(newValue => {
			this.prepData();
		});

		this.prepData();

		this.ordersearch.valueChanges.subscribe(newValue => {
			this.searchOrders()
		})

	}

	tabChanged(tabChangeEvent: MatTabChangeEvent): void {
		this.selectedTab = (tabChangeEvent.index == 0) ? 'pick' : 'pickorder';
		this.picks = [];
		this.allpicks = []

		if (this.selectedTab == "pickorder" && this.filterOrdersControl.value != '') {
			this.prepData();
			//this.filterOrders;
		}

		if (this.selectedTab == 'pick') {
			this.prepData();
		}
	}

	toggleFilters(): void {
		this.filterson = (this.filterson) ? false : true;
	}

	prepData(): void {

		this.ordersService.getPicked(this.loccode.value).subscribe((result: any) => {

			this.orders = [];
			this.allpicks = result;

			this.filterPicked()

			this.route.params.subscribe(params => {
				if (params['id'] && params['id'] != 'ALL' && params['id'] != '') {
					this.loadOrder(params['id'])
				}
			});

// 			//unique values
// 			this.orders = result.map(i => i.orderno).filter((value, index, self) => self.indexOf(value) === index)
// 			this.orders.sort();
//
// 			//this.orders.unshift('ALL');
// 			this.citys = result.map(i => i.city).filter((value, index, self) => self.indexOf(value) === index)
// 			this.citys.sort();
//
// 			//this.citys.unshift('ALL');
// 			this.bins = result.map(i => i.bin).filter((value, index, self) => self.indexOf(value) === index)
// 			this.bins.sort();
//
// 			//this.bins.unshift('ALL');
// 			if (this.selectedTab === 'pickorder') {
// 				this.filterOrders();
			//}

			// if (this.itemScanForm.get('stockidsearch').value != '') {
			// 	this.searchItems();
			// }

			let search = {
				loccode: this.loccode.value,
				debtorno: false,
				filtered: [],
			}

			this.ordersService.getCustomerOpenOrdersFiltered(search).subscribe((results: any) => {
				this.pickOrders = results;
				this.pickOrdersAll = results;
			});


		});
	}

	scanItemToOrder() {
		let data = {
			orderno: this.checkingOrderOrderno,
			search: this.scanChecking.value
		}

		if (this.searching) {
			this.searching.unsubscribe();
		}

		this.searching = this.inventoryService.lookupBarcode(data).subscribe((item: any) => {
			if (item) {
				//item in list
				let found = this.checkingOrder.filter(arg => arg.stockid == item.stockid);

				switch (found.length) {
					case 1:
						this.addScanCheck(found[0]);
						this.scanChecking.setValue('');
						setTimeout(() => {
							this.checkingScanRef.nativeElement.focus();
						}, 0);
						break;
					case 0:
						this.error = 'No results';
						this.audioPlayBad();
						this.vibrate()
					break;
					default:
						this.error = 'Multiple Matches';
						this.vibrateLong();
						this.audioPlayBad();
					break;
				}
			}
		});

	}

	getRemainingChecks() {
		var total = 0;
		if (this.pickOrders && this.pickOrders.length > 0) {
			this.pickOrders.forEach(r => {
				if (r.orderstatusid != '24') {
					total += 1;
				}
			})
		}
		return total;
	}

	getTotalItems(details: any) {

		var total = 0;
		if (details.length) {
			total = details.reduce(function(accumulator, c) {
				return accumulator + parseFloat(c.quantity);
			}, 0);
		}

		return total;
	}

	loadFiltered(type: any) {

		this.ordersService.getFilteredPicks(type).subscribe((result: any) => {

			this.picks = result;
			this.orders = [];
			this.allpicks = result;

			//unique values
			this.orders = result.map(i => i.orderno).filter((value, index, self) => self.indexOf(value) === index)
			this.orders.sort();
			//this.orders.unshift('ALL');

			this.citys = result.map(i => i.city).filter((value, index, self) => self.indexOf(value) === index)
			this.citys.sort();
			this.bins = result.map(i => i.bin).filter((value, index, self) => self.indexOf(value) === index)
			this.bins.sort();
			if (this.filterOrdersControl.value != '' && this.filterOrdersControl.value != 'ALL') {
				this.picks = this.allpicks.filter(i => i.orderno == this.filterOrdersControl.value)
			}
		});
	}

	removeItemToPlace(dispatch: any, item: any) {

		let request = {
			dispatch: dispatch,
			item: item,
		}

		this.dispatchService.removeItemToPlace(request).subscribe(results => {
			if (results.success) {
				dispatch.loaded = true;
				this.prepData();
			}
		})
	}

	itemToPlace(item: any) {

		let request = {
			item: item,
		}

		this.dispatchService.loadItemToPlace(request).subscribe(results => {
			if (results.success) {
				this.prepData();
			}
		})
	}

	routeName(rid: any): string {
		//route name function not really a good idea
		if (!rid) {
			return 'N/A';
		} else {
			let r = this.routes.filter(r => { return r.route_Id == rid; })[0];
			if (typeof r === 'object') {
				return r.route_Id + '-' + r.route_description;
			} else {
				return 'N/A';
			}
		}

	}

	filterPicked() {

		//these can have crossovers. not ok for multiple
		var results = [];
		let filters = this.itemScanForm.get('itemfiltertypeselect').value;

		let count = 0;
		filters.forEach(filter => {
			switch (filter) {
				case '': {
					let filtered = this.globalSearchService.filterItem(this.allpicks, this.itemScanForm.get('stockidsearch').value, 'pick_complete');
					results = filtered;
					break
				}
				case 'inbound': {
					break;
				}
				case 'credit': {
					const filtered_results = this.allpicks.filter((card: any) => {
						return card.ordertype == "11" && card.pick_complete === false && !this.alreadyInList(card, results);
					})
					results = [...results, ...filtered_results];
					break;
				}
				case 'picked_only': {
					const filtered_results = this.allpicks.filter((card: any) => {
						return !(card.info.daystilldue > 0) && card.pick_complete === true && !this.alreadyInList(card, results)
					})
					results = [...results, ...filtered_results];
					break;
				}
				case 'partial_pick': {
					const filtered_results = this.allpicks.filter((card: any) => {

						return card.partial_pick === true && card.pick_complete === false && !this.alreadyInList(card, results)
					});
					results = [...results, ...filtered_results];
					break;
				}

				case 'not_due_only': {
					const filtered_results = this.allpicks.filter((card: any) => {
						return card.info.daystilldue > 0 && card.pick_complete === false && !this.alreadyInList(card, results)
					});
					results = [...results, ...filtered_results];
					break;
				}
				case 'not_due_and_picked': {
					const filtered_results = this.allpicks.filter((card: any) => {
						return card.info.daystilldue > 0 && card.pick_complete === true && !card.pick_complete && !this.alreadyInList(card, results)
					});
					results = [...results, ...filtered_results];
					break;
				}
				case 'is_fillable': {

					const filtered_results = this.allpicks.filter((card: any) => {

						return (card.info.daystilldue <= 0 && card.pick_complete === false && !card.notfillable && !this.alreadyInList(card, results));
					});
					results = [...results, ...filtered_results];
					break;
				}

				case 'is_not_fillable': {
					const filtered_results = this.allpicks.filter((card: any) => {
						return (card.notfillable && card.pick_complete === false && !this.alreadyInList(card, results));
					});
					results = [...results, ...filtered_results];
					break;
				}
				default: {
					const filtered_results = this.allpicks;
					results = filtered_results;
					break;
				}
			}

			this.picks = results;

			count++;



		})

		// this.picks = results;
		this.picks = results.filter(function(elem, index, self) {
			return index === self.indexOf(elem);
		});



	}

	alreadyInList(item: any, list: any) {
		return list.indexOf(item) > 0 ? true : false;
	}

	addScanCheck(item: any) {
		var proceed = true;

		if ((item.loaded + 1) > item.picked) {
			proceed = confirm(item.stockid + ': already checked ' + item.loaded + '/' + item.picked + '. Continue?')
		}

		if (proceed) {
			var pickitem = {
				item: item,
				count: 1,
				stockid: item.stockid,
				orderno: item.orderno,
				lineno: item.linenumber,
				pick_id: item.pick_id,
				user: this.user.user.userid,
			};

			item.loaded = item.loaded + 1;

			if (item.loaded >= 0 && item.loaded <= item.pick) {
				if (item.complete) {
					this.audioPlayComplete();
					// alert(this.allpicks.length);
					this.removeitem = item
					// this.filterPicked();
				} else {
					this.audioPlay();
					this.searchItems();
				}

				this.sendOrderUpdate(pickitem);
				this.globalSearchService.showNotification("Checked " + item.stockid + " x " + item.loaded, 'success', 'bottom', 'left')
				this.scanChecking.setValue('');
				setTimeout(() => {
					this.checkingScanRef.nativeElement.focus();
				}, 0);
			}
		}
	}

	addScanPick(item: any) {

		var proceed = true;
		if ((item.loaded + 1) > item.loaded) {
			proceed = confirm(item.stockid + ': already loaded' + item.loaded + '/' + item.pick + '. Continue?')
		}

		if (this.actionControl.value) {
			if (proceed) {
				var pickitem = {
					item: item,
					count: 1,
					stockid: item.stockid,
					orderno: item.orderno,
					lineno: item.linenumber,
					pick_id: item.pick_id,
					user: this.user.user.userid,
				};

				item.loaded = item.loaded + 1;

				if (item.loaded >= 0 && item.loaded <= item.pick) {
					if (item.complete) {
						this.audioPlayComplete();
						// alert(this.allpicks.length);
						this.removeitem = item
						// this.filterPicked();
					} else {
						this.audioPlay();
						this.searchItems();
					}

					this.sendOrderUpdate(pickitem);
					this.globalSearchService.showNotification("Picked " + item.stockid + " x " + item.loaded, 'success', 'bottom', 'left')

				}
			}
		}
	}

	addPick(item: any) {

		var pickitem = {
			item: item,
			count: 1,
			stockid: item.stockid,
			orderno: item.orderno,
			lineno: item.linenumber,
			pick_id: item.pick_id,
			user: this.user.user.userid,
		};

		item.loaded = item.loaded + 1;
		let complete = (item.loaded == item.pick);

		if (item.loaded >= 0 && item.loaded <= item.pick) {
			if (complete) {
				this.audioPlayComplete();
				this.removeitem = item
			} else {
				this.audioPlay();
			}

			this.sendOrderUpdate(pickitem);
		}
	}

	removePick(item: any) {
		// alert("removePick()");
		var pickitem = {
			item: item,
			count: -1,
			stockid: item.stockid,
			orderno: item.orderno,
			lineno: item.linenumber,
			pick_id: item.pick_id,
			user: this.user.user.userid,
		};

		item.loaded = item.loaded - 1;
		let complete = (item.loaded == item.ordered);
		if (item.loaded < 0) {
			item.loaded = 0;
		}
		if (item.loaded >= 0 && item.loaded <= item.ordered) {

			this.sendOrderUpdate(pickitem);
		}
	}


	setQty(value: any, item: any) {
		let movement = parseFloat(value) - parseFloat(item.loaded);

		var pickitem = {
			item: item,
			count: movement,
			stockid: item.stockid,
			orderno: item.orderno,
			lineno: item.linenumber,
			pick_id: item.pick_id,
			user: this.user.user.userid,
		};

		item.loaded = item.loaded + movement;

		if (item.loaded > item.ordered) {
			item.loaded = item.ordered;
		}
		if (item.loaded < 0) {
			item.loaded = 0;
		}


		if (item.loaded >= 0 && item.loaded <= item.ordered) {
			let complete = (item.loaded == item.loaded);

			if (complete) {
				this.removeitem = item
			}

			this.sendOrderUpdate(pickitem);
		}
	}

	setCheckValue(event, item) {
		//multibin is issuing total pick not sure if itll be a problem? functionaly its ok. pick 6 one bin 2 on the other total pick is 8 not 2 and 6 for each

		let movement = 0;

		if (event.checked) {
			movement = parseFloat(item.ordered) - item.loaded;
		} else {
			movement = -1 * parseFloat(item.ordered) - item.loaded;
		}

		var pickitem = {
			item: item,
			count: movement,
			stockid: item.stockid,
			orderno: item.orderno,
			lineno: item.linenumber,
			pick_id: item.pick_id,
			user: this.user.user.userid,
			type_filter: this.itemfiltertype.value
		};

		item.loaded = movement;
		let completed = event.checked;

		if (completed) {
			this.audioPlayComplete();
			this.removeitem = item

		} else {
			this.audioPlay();
		}

		this.sendOrderUpdate(pickitem);
	}

	viewOrder(orderno: any) {
		this.router.navigate(['orders/lookup/' + orderno]);
	}

	filterOrders() {
		if (this.filterOrdersControl.value != 'ALL' && this.selectedTab === 'pickorder') {
			this.picks = this.allpicks.filter(i => i.orderno == this.filterOrdersControl.value)
		} else {
			this.picks = this.allpicks;
			this.router.navigate(['/orders/pick/']);
		}
	}

	filterRoute() {
		// if (this.routesControl.value != 'ALL' && this.routesControl.value !='') {
		// 	this.picks = this.allpicks.filter(i => i.route == this.routesControl.value)
		// } else {
		// 	this.picks = this.allpicks;
		// }
	}

	filterCity() {

		// if (this.filterCitiessControl.value != 'ALL') {
		// 	this.picks = this.allpicks.filter(i => i.city == this.filterCitiessControl.value)
		// } else {
		// 	this.picks = this.allpicks;
		// }
	}

	filterBins() {
		// if (this.filterBinsControl.value != 'ALL') {
		// 	this.picks = this.allpicks.filter(i => i.bin == this.filterBinsControl.value)
		// } else {
		// 	this.picks = this.allpicks;
		// }
	}

	searchBins() {

		let search = this.binsearch.value;
		if (search != '') {
			this.picks = this.globalSearchService.filterItem(this.allpicks, search, 'bin');
		} else {
			this.picks = this.allpicks;
		}

	}

	updateColumns() {
		if (this.selectedColumn) {
			if (this.selectedBin != 'ALL') {
				this.router.navigate(['orders/pick/' + this.selectedColumn]);
				this.picks = this.allpicks.filter(i => i.orderno == this.selectedColumn)
			} else {
				this.picks = this.allpicks;
			}
		}
	}

	getPickRun(column: any) {

		this.omsService.getRunPick(column).subscribe((results: any) => {
			this.picks = results.items;
			this.sortcolumns = results.sortcolumns
			this.picks.forEach((keys: any, vals: any) => {
				let arraykey = keys.ITEMKEY;

				if (keys.MUSTPICK) {
					this.mustcomplete.push({
						item: arraykey,
						count: parseFloat(keys.ordered),
						stockid: keys.stockid,
						orderno: keys.orderno,
						lineno: keys.orderlineno,
						user: this.user.user.userid,
					})
				}

				this.linedetails[arraykey] = keys;
				this.itemcount[arraykey] = parseFloat(keys.PICKED);

				var pickitem = {
					item: arraykey,
					count: parseFloat(keys.PICKED),
					stockid: keys.stockid,
					orderno: keys.orderno,
					lineno: keys.orderlineno,
					user: this.user.user.userid,
				};

				if (keys.COMPLETED == '1') {
					this.completed_items.push(pickitem);
					this.linecomplete[arraykey] = true;
				} else {
					this.linecomplete[arraykey] = false;
				}
			});
		});
	}

	onInput(event: any, keys: string = 'orderno') {

		// this pushes the input value into the service's Observable.
		this.globalSearchService.searchTerm.next(event.target.value);
		let term = event.target.value;
		let allresults = [];
		if (this.openOrderData) {
			this.setFilteredLists(term);
			this.globalSearchService.itemSearch.next(this.ordersFound);
		}
	}

	finished() {
		var completed = true;
		if (!this.completed_items) {
			completed = false;
		}
		this.mustcomplete.forEach((keys: any) => {
			//keys item needs to be order+line now
			let lookup = keys.item;
			let counted = keys.count

			if (!this.completed_items.find(arg => arg.item === lookup)) {
				this.error = 'Please Finish Picking.';
				completed = false;
			} else {
				let thiscount = this.completed_items.find(arg => arg.item === lookup);

				if (thiscount.count < counted) {
					this.error = 'Please Finish the Pick.';
					completed = false;
					this.linecomplete[keys.item] = false;
				} else {
					this.linecomplete[keys.item] = true;
					this.error = 'Pick Complete';
				}

				this.itemcount[keys.item] = thiscount.count;
			}
		});

		return completed;
	}

	back(): void {
		this.location.back()
	}

	searchItems() {
		if (this.selectedTab == 'pick') {
			var search = this.itemScanForm.get('stockidsearch').value;
		} else {
			this.filterOrders();
			return;
		}
		if (search != '') {
			//only filter current filters or it ends up grabbing prior pick
			const tempresults = this.globalSearchService.filterItem(this.picks, search, 'bin,customer,description,mfgpart,orderno,stockid');
			var result = [];

			const filtered_results = tempresults.forEach((card: any) => {
				if (result.indexOf(card) < 0) {
					result.push(card);
				}
			});

			this.picks = result;

			if (!this.picks.length) {
				this.filterPicked();
			}
		} else {
			this.filterPicked()
		}
	}

	openModal() {
		this.getOrderStatus();

		this.modalService.open(this.detailsModalEle, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {}, (reason) => {


		});
	}

	doNothing() {
		// ta-da
	}

	scanItem() {
		let data = {}
		if (this.selectedTab == 'pick') {
			data['search'] = this.itemScanForm.get('stockidsearch').value;
		} else {
			data['search'] = this.itemScanForm.get('stockidsearch_order').value;
			data['orderno'] = this.filterOrdersControl.value;
		}

		// this.searching = this.inventoryService.lookup({ stockid: search, single: true }).subscribe((item: any) => {
		this.searching = this.inventoryService.lookupBarcode(data).subscribe((item: any) => {


			//is on the list to be completed
			if (item) {
				let found = this.picks.filter(arg => arg.stockid == item.stockid);
				this.picks = this.picks.filter(arg => arg.stockid == item.stockid);



				switch (found.length) {
					case 1:
						// alert("adding scanpick")
						this.addScanPick(found[0]);

						break;
					case 0:
						this.error = 'No results';
						this.audioPlayBad();
						this.vibrate()
						this.filterPicked()
						this.selectedTab == "pickorder" ? this.filterOrders() : "";
						break;
					default:
						this.error = 'Multiple Matches';
						this.vibrateLong();
						break;
				}

				//search
			}
		});

		this.focusInput();
		//this.filterPicked();

	}

	focusInput() {

		setTimeout(() => {
			this.stockidSearchField.nativeElement.focus();
		}, 0);

	}

	setFilteredLists(term: any) {

		let allresults = [];
		let ordersFound = [];
		if (this.openOrderData) {
			var openorders = this.filterItem(this.openOrderData, term, 'ADDRESS_1,CUST_NAME,ORDER_NUMBER,CUST_ORDER_NO_,DATE_ORDERED,DATE_WANTED,CUST_NUMBER,ITEM_NUMBER');
			if (openorders) {
				openorders.forEach(function(value) {
					allresults.push({ content: 'Open Order', details: value, link: '/orders/lookup/' + value.ORDER_NUMBER })
					ordersFound.push(value.ORDER_NUMBER)
				});
			}
		}

		this.ordersFound = ordersFound;
		this.setFiltered(allresults)

	}

	filterItem(input: any, term: any, keys: any) {
		if (!term) {
			this.assignCopy(input);
		}
		var result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
		return result
	}

	getFiltered() {
		return this.filteredItems;
	}

	setFiltered(results: any) {
		this.filteredItems = results;
	}

	assignCopy(input: any) {
		this.filteredItems = Object.assign([], input);
	}

	toggle(event: any, item: any, qty: any) {

		if (event.checked) {

			this.markComplete(item, qty);
		} else {

		}

		this.updatePick();
	}

	markComplete(item: string, qty: number) {

		var pickitem = {
			item: item,
			count: +qty, //as number
			stockid: this.linedetails[item].stockid,
			orderno: this.linedetails[item].orderno,
			lineno: this.linedetails[item].orderlineno,
			user: this.user.user.userid,
		};
	}

	randomString(length: any, chars: any) {
		var result = '';
		for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
		return result;
	}

	completeCheck() {

		let data = {
			location: this.user.user.defaultlocation.loccode,
			orderno: this.checkingOrderOrderno,
		}

		this.ordersService.completeOrderCheck(data).subscribe(r=> {

			let data = {
				user: this.user,
			}

			this.omsService.sendOrderPickUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});

			this.checkingOrder = false;
			this.checkingOrderOrderno = false;
			this.flip = 'inactive';
			this.router.navigate(['orders/check']);
		})
	}

	updatePick() {
		let pickupdate = {
			required: this.mustcomplete,
			items: this.completed_items,
			picked: this.itemcount,
			complete: this.linecomplete,
			user: this.user.user.userid,
		}
		//letting the socket fire it off and update rooms.
		this.sendOrderUpdate(pickupdate)
	}

	sendOrderUpdate(orderupdate: any) {
		var data = orderupdate
		if (data) {

			this.omsService.sendOrderCheckUpdate({ data, roomName: this.CHAT_ROOM }, cb => {

				if (this.removeitem) {
					// alert(this.allpicks.length);
					if (this.allpicks.length == 1) {
						this.picks = [];
						this.allpicks = [];
						this.prepData();
					}

					this.removeitem = false;
				}
			});
		}
	}



	audioPlayComplete() {
		var audio = new Audio("/assets/message-ringtone-magic.mp3");
		let play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	audioPlay() {
		var audio = new Audio("/assets/pristine-609.mp3");
		let play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	audioPlayBad() {
		var audio = new Audio("/assets/glitch.mp3");
		let play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	audioPlayFree() {
		var audio = new Audio("/assets/money.wav");
		let play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	vibrate() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(2000);
		} else {
			// Not supported
		}
	}

	vibrateLong() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(5000);
		} else {
			// Not supported
		}
	}

	togglePickClaim(item) {
		let data = {
			item: item,
			user: this.user
		}

		this.dispatchService.claimUserPick(data).subscribe((result: any) => {
			if (result.success) {

				if (result.removed) {
					item.claimed = '0';
				} else {
					item.claimed = '1';
				}

				let data = {};
				this.omsService.sendOrderPickUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
			} else {
				alert(result.msg);
			}

		});

	}

	getUserPicks() {

// 		if (this.gettinguserpicks) {
// 			this.gettinguserpicks.unsubscribe()
// 		}
//
// 		this.gettinguserpicks = this.dispatchService.getUserPick(this.user.user.userid).subscribe((results: any) => {
// 			this.picks_in_hand = results;
//
// 		})
	}

	getOrderStatus() {

		if (this.selectedTab == 'pickorder') {

			if (this.gettingorderstatus) {
				this.gettingorderstatus.unsubscribe()
			}

			this.gettingorderstatus = this.dispatchService.getOrderStatus(this.filterOrdersControl.value).subscribe((results: any) => {
				this.orderStatus = results;
			})
		}
	}

	printPackingList() {
		this.dispatchService.printPackingList(this.filterOrdersControl.value).subscribe((results: any) => {

		})
	}

	exportExcel(datain: any): void {
		this.globalSearchService.exportJsonToExcel(this.picks, 'Picks.xlsx')
	}

	getFilterName() {
		return this.fillableonly.filter(f => {
			return f.value == this.itemfiltertype.value;
		})[0].name;
	}

	filterItemType() {
		this.loadFiltered(this.itemfiltertype.value)
	}

	toggleFlip() {
		this.flip = (this.flip == 'active') ? 'inactive' : 'active';
	}

	searchOrders() {
		if(this.ordersearch.value == '') {
			this.pickOrders = this.pickOrdersAll;
		} else {
			this.pickOrders = this.globalSearchService.filterItem(this.pickOrders, this.ordersearch.value, 'orderno,deliverto');
		}
	}


	resetList() {
		this.flip = 'inactive';
		this.checkingOrder = false;
		this.router.navigate["'/orders/check'"];
	}

	asInt(value: any) {
		return parseInt(value);
	}

	loadOrder(orderno: any) {

		this.checkingOrder = false;
		this.checkingOrderOrderno = orderno;
		this.ordersService.getPicked(this.loccode.value, orderno).subscribe((result: any) => {
			this.flip = 'active';
			this.checkingOrder = result;
		});
	}

	ngAfterViewInit() {

	}
}
