import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Location } from '@angular/common'

import { merge, fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as FileSaver from 'file-saver';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

@Component({
	selector: 'app-inventory-locations',
	templateUrl: './inventory-locations.component.html',
	styleUrls: ['./inventory-locations.component.scss'],
	animations: [
		trigger("grow", [ // Note the trigger name
			transition(":enter", [
				// :enter is alias to 'void => *'
				style({ height: "0", width: "0" }),
				animate(200, style({ height: "*", width: "*" })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(":leave", [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0,})),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class InventoryLocationsComponent implements OnInit {
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('locationsearch') customersearch: ElementRef;
	locations: any = [];
	locationForm: UntypedFormGroup = this.fb.group({});
	controls: any = [];
	locsub: any = false;
	ready: boolean;
	results: any = [];
	headercolumns: string[] = [
		'actions',
		'loccode',
		'locationname',
		'deladd1',
		'tel'
	];

	color: string = 'blue';

	addedit: boolean = false;
	location_selected: any = false;

	constructor(private location: Location, public cdr: ChangeDetectorRef, public router: Router, private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private fb: UntypedFormBuilder) {

		this.color = this.globalSearchService.getColor();

	}

	reloadData(event: any) {
		if(event) {
			this.loadData();
		}
	}

	loadData() {
		this.location_selected = false;
		this.addedit = false;

		this.globalsService.getLocations().subscribe((results: any) => {
			this.locations = new MatTableDataSource(results);
			this.results = results
			this.locations.sort = this.sort;
			this.locations.paginator = this.paginator;
			this.globalSearchService.locationData.next(this.locations);
		});


	}
	properCase(str) {
		return str.split(' ').map(function(val) {
			return val.charAt(0).toUpperCase() + val.substr(1).toLowerCase();
		}).join(' ');
	}

	updateCompany() {

	}
	filterLocations(input: any) {
		this.ready = false;
		fromEvent(this.customersearch.nativeElement, 'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
			)
			.subscribe();

		if (this.locsub) {
			this.locsub.unsubscribe();
		}

		this.locsub = this.globalsService.getLocations().subscribe((results: any) => {
			this.locations = new MatTableDataSource(results);
			this.results = results
			this.locations.sort = this.sort;
			this.locations.paginator = this.paginator;
			this.globalSearchService.locationData.next(this.locations);
		});
	}
	back(): void {
		this.location.back()
	}

	ngOnInit(): void {
		this.loadData()
	}

	update() {
		this.globalsService.updateLocation(this.locationForm.value).subscribe((result) => {

		});
	}
	selectLocation(row: any) {
		this.addedit = true;
		this.location_selected = row;
		//this.router.navigate(['./system/inventory-locations/create/' + row.loccode]);
	}
	ngAfterViewInit() {

	}
	announceSortChange(sortState: Sort) {

	}
}
