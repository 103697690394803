import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { ViewportScroller } from "@angular/common";

import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';

import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { OmsService } from '../../services/oms.service';
import { DispatchService } from '../../services/dispatch.service';
import { PrintService } from '../../services/print.service';
import { NgbModal, ModalDismissReasons,NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { FormControl } from '@angular/forms';

declare const google: any;

interface scanner {
	value: string;
	viewValue: string;
}

@Component({
	selector: 'app-dispatches-route-builder',
	templateUrl: './dispatches-route-builder.component.html',
	styleUrls: ['./dispatches-route-builder.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(179deg)'
			})),
			transition('active => inactive', animate('300ms ease-out')),
			transition('inactive => active', animate('300ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		])
	]

})
export class DispatchesRouteBuilderComponent implements OnInit {
	@ViewChild("driverChange") driverChangeRef: ElementRef;
	@ViewChild("viewActive") viewActiveRef: ElementRef;
	@ViewChild("addDestination") addDestinationRef: ElementRef;
	@ViewChild("testingContent") testingContentRef: ElementRef;
	@ViewChild("scantoadd") scantoaddRef: ElementRef;
	@ViewChild("changeTruckEle") changeTruckEleRef: ElementRef;
	@ViewChild('stepper') stepper;

	@ViewChild("orderDetails") orderDetailsRef: ElementRef;

	devicesselected: any = [];
	CHAT_ROOM = "OrderBoard";
	driverForm: UntypedFormGroup;
	selected_route = new UntypedFormControl('');
	pickstatus = new UntypedFormControl('');
	viewactiverun: any = false;
	customDestination: UntypedFormGroup;
	pointtype = new UntypedFormControl('');
	pointlabel = new UntypedFormControl('');
	pointaddress = new UntypedFormControl('');
	simpletoggle = new UntypedFormControl(true);
	scantoadd1 = new UntypedFormControl('');
	scantoadd2 = new UntypedFormControl('');

	fromTruckOms = new UntypedFormControl('');
	toTruckOms = new UntypedFormControl('');

	isdispatch: boolean = true;
	reloaddispatch: boolean = false;
	testcontent: any = false;
	editactivedispatch: any = false;
	allavailableorders: any = false;
	dispatching: any = false;
	flip: string = 'inactive';
	user: any = [];
	color: string = 'blue';
	config: any = [];
	pickable: any = [];
	filteredItems: any = [];
	carddata: any = [];
	sortcolumns: any = [];
	allsortcolumns: any = [];
	status: any = [];
	completed_orders: any = [];
	focusdata: any = false;
	runlist: any = [];
	active_run: any = [];
	run_list: any = [];
	reviewDispatch: any = false;
	token: string = '';
	side: string = 'right';
	expandEnabled: boolean = true;
	columnfocus: any = false;
	innerWidth: any;
	totalonrun: any = 0;
	unpickedcount: any = 0;
	mustbepicked: any = true;
	map: any = false;
	updated_map: any = false;
	markers: any = [false];
	pathlines: any = false;
	map_animation: any = google?.maps.Animation.DROP;
	zoom: number = 11;
	delsheetlink: any = '';
	selectedRun: any = false;
	drivers: any = [];
	selectedColumn: any = [];
	selectedTruck: any = false;
	focusruntime: any = false;
	driverOverTimeLimit: string = "";
	inlinedriver: boolean = false;
	payment_methods: any = [];
	needpayments: any = [];
	prepay: any = [];
	payments: any = [];
	dispatches: any = [];
	activeDispatches: any = []
	invoicesCreated: any = false;
	sending: boolean = false
	addingcardparent: boolean = false
	currentStep: number = 0;
	routes: any = [];
	hovered_item: any = false;
	ordershidden: any = true;
	place: any = [];
	loadDataTime: Date = new Date(); //The date of when the data was loaded
	deliveryStopTime: number = 300; //Number of seconds that the driver stops to deliver
	deliveryReturnTime: string = ""; //Return time of the driver
	orderdetails: any = false;

	scanner: scanner[] = [
		{ value: 'LOADING1', viewValue: 'LOADING1' },
		{ value: 'LOADING2', viewValue: 'LOADING2' },
	];

	// scanneroptions: string[] = ['LOADING1', 'LOADING2'];
	// scanner = new FormControl('');
	title: string = '';

	pickedFilter = [

		{ label: 'Fillable', value: 'is_fillable' },
		{ label: 'Inbound', value: 'inbound' },
		{ label: 'Is Not Fillable', value: 'is_not_fillable' },
		//{ label: 'Inbound', value: 'inbound' },
		{ label: 'Picked', value: 'picked_only' },
		{ label: 'Partially Picked', value: 'partial_pick' },
		{ label: 'Credit', value: 'credit' },
		{ label: 'Not Due', value: 'not_due_only' },
		{ label: 'Not Due & Picked', value: 'not_due_and_picked' },
	]

	constructor(private printService: PrintService, private fb: UntypedFormBuilder, private dispatchService: DispatchService, public omsService: OmsService, public location: Location, public ordersService: OrdersService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, public formBuilder: UntypedFormBuilder, public inventoryService: InventoryService, private modalService: NgbModal) {

		this.token = this.globalSearchService.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
		this.omsService.setupSocketConnection(this.token);

		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
		});

		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		})
		this.delsheetlink = this.config.apiServer.baseUrl + this.config.apiServer.runSheet
		this.color = this.globalSearchService.getColor();

	}

	ngOnInit(): void {
		let default_status = ["is_fillable", "picked_only", "partial_pick", "credit"];
		this.pickstatus.setValue(default_status);
		this.globalSearchService.payment_methods.subscribe(result => this.payment_methods = result);
		this.innerWidth = window.innerWidth;

		this.driverForm = this.fb.group({
			driver: ['', Validators.required],
			truck: ['', Validators.required],
		});

		this.customDestination = this.fb.group({
			label: ['', Validators.required],
			address: ['', Validators.required],
			lat: ['', ],
			lng: ['', ],
			type: [''],
			column: [''],
		});
		//dont listen
		this.omsService.subscribeToCards((err, data) => {

			//cardupdate
			switch (data.type) {
				case 'claim':
					this.updateCardStatus(data);
				break;
				case 'delivery':
					this.loadData();
				break;
				case 'pick':
					//card color update
					if(!data.neworder) {
						this.updateCardStatus(data);
					}
					if(data.neworder) {
						this.loadData();
					}
				break;
				case 'cardupdate':
					//this.loadData();
					//this.carddata = data.data
					break;
				case 'dispatch':
					//this.loadData();
					break;
			}

			this.getUnPicked();
		});
		//load routes once
		this.dispatchService.getRoutes().subscribe((r: any) => {
			this.routes = r
			this.loadData()
		});
	}

	noNewLines(input:any) {
		if(input) {
			return input.replace(/[^\x20-\x7E]/gmi, " ").trim().replace(/\\n/g," ");
		}
		return input;
	}

	updateCardStatus(data: any) {
		//filtered
		let filterindex = this.filteredItems.indexOf(this.filteredItems.filter((ob: any) => {
			return ob.orderno == data.orderno;
		})[0]);

		if (filterindex >= 0) {
			this.filteredItems[filterindex].partial_pick = data.partial;
			this.filteredItems[filterindex].pick_complete = data.complete;
			this.filteredItems[filterindex].claimstatus = data.claimstatus;
		}
		//all
		let allindex = this.allavailableorders.indexOf(this.filteredItems.filter((ob: any) => {
			return ob.orderno == data.orderno;
		})[0]);

		if (allindex >= 0) {
			this.allavailableorders[allindex].partial_pick = data.partial;
			this.allavailableorders[allindex].pick_complete = data.complete;
		}
		//focusdata
		if (this.focusdata && this.focusdata.length) {
			let focusindex = this.focusdata.indexOf(this.focusdata.filter((ob: any) => {
				return ob.orderno == data.orderno;
			})[0]);
			if (focusindex >= 0) {
				this.focusdata[focusindex].partial_pick = data.partial;
				this.focusdata[focusindex].claimstatus = data.claimstatus;
				this.focusdata[focusindex].pick_complete = data.complete;
			}
		}
		//columns
		let k: keyof typeof this.carddata;
		for (k in this.carddata) {
			const v = this.carddata[k];
			if (v.length) {
				let columnindex = this.carddata[k].indexOf(this.carddata[k].filter((ob: any) => {
					return ob.orderno == data.orderno;
				})[0]);
				if (columnindex >= 0) {
					this.carddata[k][columnindex].partial_pick = data.partial;
					this.carddata[k][columnindex].pick_complete = data.complete;
				}
			}
		}
	}

	getCostTotalYear(year:any) {
		let value  = year.cost.reduce(function(accumulator: number, items: any) {
			let addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);

		return value;
	}

	getSaleTotalYear(year:any) {
		let value  = year.sales.reduce(function(accumulator: number, items: any) {
			let addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);

		return value;
	}

	getQtyTotalYear(year:any) {
		let value  = year.qty.reduce(function(accumulator: number, items: any) {
			let addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);

		return value;
	}

	viewOrder(orderno: any) {

		this.ordersService.getOrder(orderno).subscribe((order: any) => {
			this.orderdetails = order
			this.modalService.open(this.orderDetailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		});
	}

	exportDisaptchXls(column: any) {
		this.dispatchService.getActiveRun(column).subscribe((results: any) => {
			this.globalSearchService.exportJsonToExcel(results.dispatched, column + 'DeliverySheet' + new Date() + ".xlsx")
		});
	}

	deliverRun(column: any) {
		this.dispatchService.deliverRun(column).subscribe((results: any) => {
			this.reloaddispatch = true;
			//then

		});

	}

	hideOrders() {
		this.ordershidden = (this.ordershidden) ? false : true;
	}

	removeLines() {
		if (this.pathlines) {
			this.pathlines.setMap(null);
		}
	}

	checkPick() {
		var procced = true;
		this.stepper.next();

		// 		if(this.getUnPicked() > 0) {
		// 			procced = confirm('Unpicked Orders, Proceed to Take Payments?');
		// 		}
		//
		// 		if(procced) {
		// 			this.stepper.next();
		// 		}

	}

	getUnPicked(): number {
		var unpicked = 0;
		if (this.focusdata && this.focusdata.length) {
			unpicked = this.focusdata.reduce(function(accumulator, item) {
				var addon = 0;
				if (!item.pick_complete) {
					addon = 1;
				}
				return accumulator + addon;
			}, 0);
		}
		this.unpickedcount = unpicked;
		return unpicked;
	}

	createPayments() {

		let incomplete = this.focusdata.filter(i => !i.pick_complete);
		var proceed = true;
		// if (incomplete.length && proceed) {
		// 	proceed = confirm("There are unpicked orders on this run. Continue?");
		// }

		if (proceed) {
			this.sending = true;
			this.dispatchService.createInvoiceAndPay(this.needpayments).subscribe((results: any) => {
				this.sending = false;
				if (results.success) {
					this.invoicesCreated = results.data;
					this.getPrePay(this.selectedTruck.id);
					this.loadData();
				} else {
					alert(results.message)
					this.getPrePay(this.selectedTruck.id);
					this.loadData();
				}
			})
		}
	}

	getPaid(orderno: any) {
		var paid = false;
		if (this.invoicesCreated) {
			let invoice = this.invoicesCreated.filter(d => { return d.orderno == orderno })[0]
			if (invoice) {
				paid = true;
			}
		}

		return paid;
	}

	getPrePay(run: any) {

		this.invoicesCreated = false;
		this.needpayments = false;
		this.dispatchService.getNeedPrePay(run).subscribe((results: any) => {
			this.needpayments = results;

			results.forEach((pay: any) => {

				if(pay.preselected) {

					let card = pay.cardsonfile.filter( c => {
						return c.profile_id == pay.preselected.profile_id;
					})[0];

					this.setCreditCard(card, pay.details.payments[0])
				} else {
					if (pay.cc_on_file) {
						this.setCreditCard(pay.cardsonfile[0], pay.details.payments[0])
					}
				}
			})

			this.prepay = results;
		});

		this.dispatchService.getPrePaid(run).subscribe((paid: any) => {
			if (paid) {
				if (paid.length) {
					this.invoicesCreated = paid;
				}
			}
		});

		this.getUnPicked();
	}

	prePaid(da: any) {
		//if(this.invoicesCreated)
	}

	removeInvoice(inv: any) {
		let index = this.needpayments.indexOf(inv);
		this.needpayments.splice(index, 1)
	}


	pTotal(payments) {
		return payments.reduce((accumulator, obj) => { return accumulator + parseFloat(obj.payment) }, 0);
	}

	removePayment(payments: any = [], payment) {
		let proceed = confirm("Removing this payment will add the invoice on account, and a seperate payment will need to be taken. Proceed?");
		if(proceed) {
			let index = payments.indexOf(payment);
			payments.splice(index, 1)
		}
	}

	addPayment(da: any, payments: any = []) {
		let addon = {
			payment: da.details.total - this.pTotal(da.details.payments),
			payment_method: '',
			checknumber: '',
		}
		payments.push(addon)
	}

	setCheck(event: any, item: any) {
		item.checknumber = event;
	}

	setPayment(event: any, item: any) {
		item.payment = event;
	}

	setCreditCard(event: any, payment: any) {
		if (event) {
			let method = this.payment_methods.filter(p => {
				return event.card_type.toUpperCase() == p.paymentname.toUpperCase();
			})[0];
			if (method) {
				this.setPaymentMethod(method.paymentid, payment)
			}
			payment.cc_charge = event;
		} else {
			payment.cc_charge = false;
		}
	}

	setPaymentMethod(event: any, payment: any) {
		payment.payment_method = event;
	}

	goToLink(url: string) {
		//, "_blank"
		window.open(url);
	}

	switchPickOnly(event: any) {

		this.loadData();

		if (event.checked) {

		} else {
			//	this.getAllOrders()
		}
	}

	getAllOrders() {
		this.dispatchService.getAllOrders().subscribe((results: any) => {
			this.status = results.status
			this.pickable = results.orders;
			this.allavailableorders = results.orders;
			this.filteredItems = results.orders;
			this.carddata = results.current
			this.sortcolumns = results.columns;
			this.allsortcolumns = results.columns;
		});
	}

	loadData() {

		this.dispatchService.getOrders().subscribe((results: any) => {
			this.status = results.status
			this.pickable = results.orders;
			this.allavailableorders = results.orders;
			this.filteredItems = results.orders;
			this.carddata = results.current
			//currentyl focused on a run, update the run.
			if (this.focusdata) {
				this.focusdata = this.carddata[this.columnfocus];
			}

			this.sortcolumns = results.columns;
			this.allsortcolumns = results.columns;
			//no need to grab active dispatches when focused. cant see them
			if (!this.focusdata || !this.focusdata.length) {
				this.dispatchService.getActiveDispatches().subscribe(async (dres: any) => {
					this.dispatches = dres.dispatches;
					if(this.allsortcolumns) {
						this.allsortcolumns.forEach((i: any, index) => {
							let activeDispatch = dres.dispatches.filter((d: any) => {
								return d.truck == i.id
							})
							//dispatched
							this.activeDispatches[i.id] = activeDispatch[0]
						})
					}
				});
			}
			//Keep route time up to date
			this.setSeperateRouteTime()
			//Get the starting time for routing
			this.loadDataTime = new Date()
			//filter default data
			this.filterOrders()
			if (this.focusdata) {
				this.getUnPicked();
				this.updateMap(this.focusdata);
			} else {
				this.updateTruckDisplay();
			}
		});

	}

	saveDriver() {

		this.dispatchService.setDrivers(this.driverForm.value).subscribe((result: any) => {
			if (result.success) {

			} else {
				alert(result.message)
				this.loadData();
			}

			this.loadData();
			this.modalService.dismissAll();
			if (this.selectedTruck) {
				this.selectedTruck.driver = this.driverForm.get('driver').value.name;
			}

			const data = { truck: this.selectedTruck, driver: this.driverForm.value, user: this.user } //cardaction;

			if (data) {
				this.omsService.sendDriverUpdate({ data, roomName: 'driverchange' }, cb => {});
			}

			//this.driverForm = this.fb.group({
			//	driver: ['', Validators.required],
			//	truck: ['', Validators.required],
			//});
		});
	}

	cancelInlineDriver() {
		this.inlinedriver = false;
	}

	saveDriverInline() {

		this.dispatchService.setDrivers(this.driverForm.value).subscribe((result: any) => {
			this.loadData();
			if (this.selectedTruck) {
				this.selectedTruck.driver = this.driverForm.get('driver').value.name;
				const data = { truck: this.selectedTruck, driver: this.driverForm.value, user: this.user } //cardaction;

				if (data) {
					this.omsService.sendDriverUpdate({ data, roomName: 'driverchange' }, cb => {});
				}
			}
			this.inlinedriver = false;
		});
	}

	assignDriverInline(run: any) {
		this.inlinedriver = true;

		this.selectedRun = run
		this.dispatchService.getDrivers().subscribe((result: any) => {
			this.drivers = result;
			this.driverForm = this.fb.group({
				driver: ['', Validators.required],
				truck: [run],
			});
		});
	}

	assignDriver(run: any) {

		this.selectedRun = run
		this.dispatchService.getDrivers().subscribe((result: any) => {
			this.drivers = result;
			this.driverForm = this.fb.group({
				driver: ['', Validators.required],
				truck: [run],
			});
		});

		this.modalService.open(this.driverChangeRef, { ariaLabelledBy: 'modal-title', size: 'md' }).result.then((result) => {

		}, (reason) => {

		});
	}

	submitDispatch(columnid) {

		this.dispatching = false;

		var proceed = true;

		if (!this.selectedTruck.driver || this.selectedTruck.driver == '') {
			proceed = confirm("There is no driver assigned to this dispatch. Continue?");
		}

		if (this.activeDispatches[columnid].dispatched.length && proceed) {
			proceed = confirm("There is an active dispatch, this will add to the current dispatch. Continue?");
		}

		// let incomplete = this.focusdata.filter(i => !i.pick_complete);
		// if (incomplete.length && proceed) {
		// 	proceed = confirm("There are unpicked orders on this run. Continue?");
		// }

		if (proceed) {
			const data = { column: columnid, data: this.carddata[columnid], user: this.user } //cardaction;
			this.dispatching = true;
			this.omsService.sendDispatchUpdate({ data, roomName: this.CHAT_ROOM }, cb => {
				if (cb.status == 'ok') {
					this.loadData();
					let print_data = { column: columnid };
					this.printService.loadsheet(print_data).subscribe((r: any) => {
						setTimeout(() => {
							this.dispatching = false;
							this.focusdata = [];
							this.selectedTruck = false;
							this.toggleFlip('');
						}, 100);
					});

					this.printService.printRun(print_data).subscribe((r: any) => {});

				} else {
					this.dispatching = false;
				}
			});

		}
	}

	scanToAddOrder(event: any) {
		event.preventDefault();
		if (this.selectedColumn.length) {
			let items = this.globalSearchService.filterItem(this.allavailableorders, event.target.value, 'order');
			if (items.length == 1) {
				let indeof = this.allavailableorders.indexOf(items[0])
				this.moveItem(this.allavailableorders[indeof])
				this.scantoadd2.setValue('');
				this.scantoadd1.setValue('');
			} else {
				if (items.length > 1) {
					alert("Multiple Found - for " + this.scantoadd1.value);
				} else {
					let refresh = confirm("Order Not Found, Refresh?" + this.scantoadd1.value);
					if (refresh) {
						window.location.reload();
					}
				}
			}

		} else {
			this.scantoadd2.setValue('');
			this.scantoadd1.setValue('');
			alert("Select a Truck");
		}

	}

	onInput(event: any, keys: string = 'orderno') {
		if(event.target.value != '') {
			this.filteredItems = this.globalSearchService.filterItem(this.allavailableorders, event.target.value, 'customer.address,order,terms');
		} else {
			this.filterPicked();
		}
	}

	/**
	 * Filter route and orders together
	 */
	filterOrders() {
		this.filteredItems = this.pickable;

		//this.filterByRoute()
		this.filterPicked()
	}

	alreadyInList(item:any, list: any) {
		return list.indexOf(item) >= 0 ? true :false;
	}

	filterPicked() {
		//these can have crossovers. not ok for multiple
		var results = [];
		let filters = this.pickstatus.value;
		if(this.allavailableorders) {
			filters.forEach(filter => {
			switch (filter) {
				case 'inbound': {
					break;
				}
				case 'credit': {
					const filtered_results = this.allavailableorders.filter((card: any) => {
						return card.ordertype == "11" && !this.alreadyInList(card,results);
					})
					results = [...results,...filtered_results];
					break;
				}
				case 'picked_only': {
					const filtered_results = this.allavailableorders.filter((card: any) => {
						return !(card.info.daystilldue > 0) && card.pick_complete === true && !this.alreadyInList(card,results)
					})
					results = [...results,...filtered_results];

					break;
				}
				case 'partial_pick': {
					const filtered_results = this.allavailableorders.filter((card: any) => {
						return card.partial_pick === true && !card.notfillable &&  !this.alreadyInList(card,results)
					});
					results = [...results,...filtered_results];
					break;
				}

				case 'not_due_only': {
					const filtered_results = this.allavailableorders.filter((card: any) => {
						return card.info.daystilldue > 0 && card.pick_complete === false && !this.alreadyInList(card,results)
					});
					results = [...results,...filtered_results];
					break;
				}
				case 'not_due_and_picked': {
					const filtered_results= this.filteredItems.filter((card: any) => {
						return card.info.daystilldue > 0 && card.pick_complete === true && !this.alreadyInList(card,results)
					});
					results = [...results,...filtered_results];
					break;
				}
				case 'is_fillable': {

					const filtered_results= this.allavailableorders.filter((card: any) => {
						return (card.info.daystilldue <= 0 && !card.notfillable && !this.alreadyInList(card,results));
					});
					results = [...results,...filtered_results];
					break;
				}

				case 'is_not_fillable': {
					const filtered_results = this.allavailableorders.filter((card: any) => {
						return (card.notfillable && !this.alreadyInList(card,results));
					});
					results = [...results,...filtered_results];
					break;
				}
				case '': {
					const filtered_results = this.allavailableorders;
					results = filtered_results;
					break;
				}
				default: {
					const filtered_results = this.allavailableorders;
					results = filtered_results;
					break;
				}
			}
			this.filteredItems = results;

			if (this.selected_route.value != '') {
				this.filteredItems = this.globalSearchService.filterItem(this.filteredItems, this.selected_route.value, 'route');
			}

		})
		} else {
			return false;
		}

	}
	filterByRoute() {

		if (this.selected_route.value != '') {
			this.filteredItems = this.globalSearchService.filterItem(this.pickable, this.selected_route.value, 'route');
			// this.sortcolumns = this.filteredItems;
		} else {
			this.filteredItems = this.pickable
		}


	}

	updateTruckDisplay() {

		if (this.allsortcolumns && this.selectedColumn != '') {
			this.sortcolumns = this.allsortcolumns.filter(i => {
				//was multiple moving to single
				//return this.selectedColumn.includes(i.id)
				return this.selectedColumn == i.id
			})
			if(this.scantoaddRef.nativeElement) {
				setTimeout(() => this.scantoaddRef.nativeElement.focus(), 0);
			}
		} else {
			this.sortcolumns = (this.allsortcolumns) ? this.allsortcolumns : [];
			this.selectedColumn = '';
		}
	}

	updateTimes(column: any) {
		this.dispatchService.updateTimes(column).subscribe(async (r: any) => {

		})
	}

	getRouteTotalTime() {
		try {
			if (this.focusdata) {
				const runTime = this.focusdata[this.focusdata.length - 1].info.runtime
				this.setSeperateRouteTime()
				//this.checkDriverOverTimeLimit(runTime)
			}
		} catch (e: unknown) {
			this.loadData() //Reload the data if there is an issue
		}
	}

	/**
	 * Get the time of the individual route from one marker to another
	 */
	setSeperateRouteTime(): void {
		try {
			const convertSecondsToUnixTime = (routeTime: number): string => {
				let timeAdd = (Number.isNaN(routeTime)) ? 0 : routeTime;
				return moment.unix((timeAdd) ? timeAdd : 0).utc().format((timeAdd >= 3600) ? 'H [Hours,] m [Minutes]' : 'm [Minutes]')
			}
			let totalRouteTime: number = 0
			if (this.focusdata && this.focusdata.length) {
				for (let i = 0; i < this.focusdata.length; ++i) {
					const lastCard = this.focusdata[i - 1]
					const card = this.focusdata[i]

					const routeTime = ((lastCard && lastCard.address === card.address)) // If the location is the same as the previous
						?
						0 //If the delivery is the same location
						:
						+card.info.esttime + this.deliveryStopTime // stop time + route time

					if(Number.isNaN(routeTime)) {

					}

					const rtime = convertSecondsToUnixTime(routeTime);

					totalRouteTime += routeTime
					//dsptime does not always have the correct time when the two addresses are the same. We must manually check it.
					card["info"]["rtime"] = rtime
					card["info"]["dsptime"] = convertSecondsToUnixTime(totalRouteTime);
				}

				this.focusruntime = convertSecondsToUnixTime(totalRouteTime);
				this.setDeliveryReturnTimes(totalRouteTime);
			}
		}
		//Focusdata could be boolean or an array. This will catch if this runs whilst focusdata is boolean.
		catch (e: unknown) {
			console.warn(e)
		}
	}

	/**
	 * Check if the driver will be late after the last delivery
	 * @param deliveryTime The total time from all deliveries
	 */
	checkDriverOverTimeLimit(deliveryTime: number): void {
		if (!deliveryTime) {
			return
		}

		const currentTime = new Date(this.loadDataTime)

		currentTime.setSeconds(currentTime.getSeconds() + deliveryTime)
		this.driverOverTimeLimit = (currentTime.getHours() >= 15) ? 'Delivery will go past 3pm' : ''
	}


	/**
	 * Set the estimated return times of the driver
	 * @param deliveryTime The total time from all deliveries
	 */
	setDeliveryReturnTimes(deliveryTime: number): void {
		if (!deliveryTime) {
			return
		}

		const currentTime = new Date(this.loadDataTime)

		currentTime.setSeconds(currentTime.getSeconds() + deliveryTime)
		this.deliveryReturnTime = currentTime.toLocaleTimeString()
	}

	moveItem(item: any) {
		if (this.selectedColumn) {

			let thisitem = item;
			var proceed = true;
			//move back to order list no check on dates etc.

			let isdue = this.itemIsDue(thisitem);
			if (!isdue) {
				let conf = confirm('Item Not Due Yet. Procced?')
				proceed = false;
				if (conf) {
					proceed = true;
				}
			}

			//	if (!thisitem.pick_complete && item.type != '11') {
			//		let conf = confirm('Items Not Picked Yet. Procced?')
			//		proceed = false;
			//		if (conf) {
			//			proceed = true;
			//		}
			//	}

			if (proceed) {
				let indexfrom = this.filteredItems.indexOf(item);
				let indexto = this.carddata[this.selectedColumn].push(item);
				this.filteredItems.splice(indexfrom, 1);
				this.pushPullItem(item);
				// this.sortcolumns = this.filteredItems;
				const cardaction = {
					pindex: indexfrom,
					cindex: indexto,
					list: this.carddata[this.selectedColumn],
					message: this.carddata
				}

				this.submitMessage(cardaction);
				this.getRouteTotalTime()
			}
		}
	}

	// updateDeviceDisplay(event: any, columnid: any) {
	// 	let data = {
	// 		device: event.value,
	// 		column: columnid,
	// 	}
	// 	this.devicesselected[columnid] = event.value
	// 	this.carddata[columnid].device = event.value;
	// 	this.dispatchService.updateDevice(data).subscribe((r:any) => {

	// 	})

	// }
	updateDeviceDisplay(scannervalue: any = '', columnid: any) {
		let data = {
			device: scannervalue,
			column: columnid,
		}
		this.devicesselected[columnid] = scannervalue;
		this.carddata[columnid].device = scannervalue;
		this.dispatchService.updateDevice(data).subscribe((r: any) => {

		})

	}
	dropSingle(event: CdkDragDrop < string[] > , list: any) {
		this.hovered_item ='';
		let thisitem = event.previousContainer.data[event.previousIndex];
		var proceed = true;

		if (event.container.data != this.filteredItems) {
			let isdue = this.itemIsDue(thisitem);
			if (!isdue) {
				let conf = confirm('Item Not Due Yet. Procced?')
				proceed = false;
				if (conf) {
					proceed = true;
				}
			}
		}

		if (proceed) {

			this.pushPullItem(thisitem);
			if (event.previousContainer === event.container) {
				moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
			} else {
				transferArrayItem(event.previousContainer.data,
					event.container.data,
					event.previousIndex,
					event.currentIndex);
			}

			const cardaction = {
				pindex: event.previousIndex,
				cindex: event.currentIndex,
				list: list,
				message: this.carddata
			}

			this.submitMessage(cardaction);

			let thislist = list;
			let i = 0;
			thislist.forEach(item => {
				item.sort = i
				i++;
			})
			//setTimeout(() => {
				//this.updateMap(thislist);
				//this.updateTimes('');
				//this.updateMap(this.focusdata);

			//}, 500);
		}

	}

	getAddress(place: any) {
		this.place = place

		let componenets = place['address_components'];
		let name = place['name'];
		let phone = place['formatted_phone_number'];
		let website = place['website'];

		let address1 = this.parseComponentShortName(componenets, ['street_number', 'route']);
		let address2 = this.parseComponentShortName(componenets, ['subpremise']);
		let address3 = this.parseComponentShortName(componenets, ['locality']);
		let address4 = this.parseComponentShortName(componenets, ['administrative_area_level_1']);
		let address5 = this.parseComponentShortName(componenets, ['postal_code']);
		let address6 = this.parseComponentLongName(componenets, ['country']);

		if (name) {

		}

		if (phone) {}

		if (address2 != '' && address2) {
			address2 = '<br>' + address2;
		}

		let address = address1 + address2 + '<br>' + address3 + ' ' + address4 + ', ' + address5;

		this.customDestination.get('type').setValue('4')
		this.customDestination.get('label').setValue(name)
		this.customDestination.get('address').setValue(address)
		this.customDestination.get('lat').setValue(this.place.geometry.location.lat())
		this.customDestination.get('lng').setValue(this.place.geometry.location.lng())

	}

	itemIsDue(item: any) {
		if (parseInt(item.info.daystilldue) > 0) {
			return false;
		}
		return true;
	}

	addPoint(column: any) {

		this.customDestination.get('column').setValue(column);
		this.modalService.open(this.addDestinationRef, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

		}, (reason) => {

		});
	}

	addCustomDestination() {
		this.dispatchService.addPoint(this.customDestination.value).subscribe(r => {
			this.customDestination = this.fb.group({
				label: ['', Validators.required],
				address: ['', Validators.required],
				lat: ['', ],
				lng: ['', ],
				type: [''],
				column: [''],
			});
			this.modalService.dismissAll();
			this.loadData();

		})
	}

	private parseComponentShortName(componenets: any, types: any) {

		let result = [];
		componenets.forEach((comp: any) => {
			let compa = comp.types.map((local: any) => {
				if (types.includes(local)) {
					let adda = (comp.short_name) ? comp.short_name : '';
					result.push(adda);
				}
			});
		})
		return result.join(' ');
	}

	private parseComponentLongName(componenets: any, types: any) {
		let result = [];
		componenets.forEach((comp: any) => {
			let compa = comp.types.map((local: any) => {
				if (types.includes(local)) {
					let adda = (comp.long_name) ? comp.long_name : '';
					result.push(adda);
				}
			});
		})
		return result.join(' ');
	}



	drop(event: CdkDragDrop < any > , list: string) {

		let thisitem = event.previousContainer.data[event.previousIndex];

		var proceed = true;
		var reloadpre = false;
		//move back to order list no need to check dates / capacity etc.
		if (event.container.data != this.filteredItems) {
			let isdue = this.itemIsDue(thisitem);
			if (!isdue) {
				let conf = confirm('Item Not Due Yet. Procced?')
				proceed = false;
				if (conf) {
					proceed = true;
				}
			}

			let columnddata = this.sortcolumns.filter(r => { return r.id == list })[0];
			var nextvolume = this.getCapacityLoaded(list) + thisitem.totalvolume;

			if (nextvolume > columnddata.capacity) {

				let conf = confirm('Over Capacity. Procced?')
				proceed = false;
				if (conf) {
					proceed = true;
				}

			}
		} else {
			//removed from list update prepayment
			reloadpre = true;
		}

		this.pushPullItem(thisitem);

		if (proceed) {
			if (event.previousContainer === event.container) {
				moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
			} else {
				transferArrayItem(event.previousContainer.data,
					event.container.data,
					event.previousIndex,
					event.currentIndex);
			}




			const cardaction = {
				pindex: event.previousIndex,
				cindex: event.currentIndex,
				list: list,
				message: this.carddata
			}
			this.submitMessage(cardaction);


			if (reloadpre && this.selectedTruck.id) {
				this.getPrePay(this.selectedTruck.id)
				this.getUnPicked();

			}
		}

	}

	pushPullItem(item: any) {
		let currentindex = this.allavailableorders.indexOf(item);
		if(currentindex < 0) {
			this.allavailableorders.push(item);
		} else {
			this.allavailableorders.splice(currentindex, 1);
		}

	}

	toggleLock(truck: any) {
		truck.unlocked = (truck.unlocked) ? false : true;
	}


	forceCommitDispatch(columnfocus: any) {

		this.selectedTruck = this.allsortcolumns.filter(i => { return i.id == columnfocus })[0]
		this.columnfocus = columnfocus;
		this.focusdata = this.carddata[columnfocus];

		var proceed = true;

		if (!this.selectedTruck.driver || this.selectedTruck.driver == '') {
			proceed = confirm("There is no driver assigned to this dispatchContinue?");
		}

		if (this.activeDispatches[columnfocus].dispatched) {
			if (this.activeDispatches[columnfocus].dispatched.length && proceed) {
				proceed = confirm("There is an active dispatch, this will add to the current dispatch. Continue?");
			}
		}

		if (proceed) {
			const data = { column: columnfocus, data: this.carddata[columnfocus], user: this.user }
			this.omsService.sendDispatchUpdate({ data, roomName: this.CHAT_ROOM }, cb => {
				let print_data = { column: columnfocus };
				this.printService.loadsheet(print_data).subscribe((r: any) => {
					// 					this.testcontent = r.content;
					// 					this.modalService.open(this.testingContentRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {
					//
					// 					}, (reason) => {
					//
					// 					});
				});

				this.printService.printRun(print_data).subscribe((r: any) => {});
			});
		}
	}

	submitMessage(cardaction) {
		let data = {
			carddata: this.carddata,
			selected_dispatch: this.selectedTruck,
			devices: this.devicesselected,
		}
		//const data = this.carddata //cardaction;
		if (data) {
			this.omsService.sendCardUpdate({ data, roomName: this.CHAT_ROOM }, cb => {
				if (this.columnfocus) {
					//cb sinlge preview has updated data
					if (cb.status == 'ok') {
						if(cb.singlepreview) {

							cb.singlepreview.data.forEach((order: any) => {
								let fitem = this.focusdata.filter((a: any) => {
									return a.orderno == order.orderno
								})[0]
								let index = this.focusdata.indexOf(fitem);

								if (index >= 0) {
									this.focusdata[index].info = order.info;

								}
							})
							if (this.selectedTruck.id) {
								//this.updateTimes(this.selectedTruck.id)
							}
							if(this.focusdata) {
								this.getUnPicked();
								this.getRouteTotalTime();
								this.updateMap(this.focusdata);
							}
						}
					}
				}
			});
		}
	}

	onHeaderClick(event) {
		if (!this.expandEnabled) {
			event.stopPropagation();
		}
	}

	onDotClick(event) {
		if (!this.expandEnabled) {
			event.stopPropagation();
		}
	}

	onExpandEntry(expanded, index) {

	}

	toggleSide() {
		this.side = this.side === 'left' ? 'right' : 'left';
	}

	getColumnFocus() {
		if (this.columnfocus) {
			this.focusdata = this.carddata[this.columnfocus];
			this.getRouteTotalTime();
			return this.carddata[this.columnfocus];
		} else {
			this.focusdata = false;
			return false;
		}
	}

	setColumnFocus(column) {
		this.columnfocus = column;
	}

	removeFromOms(order: any, index: any) {

		if (index >= 0) {
			this.focusdata.splice(index, 1)
		}

		let data = { orderno: order }
		this.dispatchService.removeOrderFromOms(data).subscribe(r => {
			this.loadData();
		})
	}

	deleteRun(column: any) {

		let ok = confirm("Delete Entire Run?");
		if (ok) {
			let data = { truckid: column }
			this.dispatchService.deleteRun(data).subscribe(r => {
				this.modalService.dismissAll();
				this.loadData();
				const data = { truck: column, user: this.user }
				if (data) {
					this.omsService.sendDispatchPositionUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
				}
			});
		}

	}
	transferOmsTo() {
		let data = {
			from: this.fromTruckOms.value,
			to: this.toTruckOms.value,
		}
		this.dispatchService.moveOms(data).subscribe(r => {
			this.loadData();
			this.modalService.dismissAll();
			this.fromTruckOms.setValue('');
			this.toTruckOms.setValue('');
		})
	}

	changeTruck(column: any) {

		this.fromTruckOms.setValue(column);
		this.modalService.open(this.changeTruckEleRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
	}

	clearOmsColumn(column) {
		this.dispatchService.clearOmsColumn(column).subscribe(r => {
			this.loadData();
		});
	}

	clearRun(column) {

		this.dispatchService.clearRun(column).subscribe(r => {
			this.loadData();
		});
	}

	clearRunSaveForLater(column) {

		this.dispatchService.clearRunSaveForLater(column).subscribe(r => {
			this.loadData();
		});
	}

	getDropType() {
		if (this.innerWidth > 600) {
			return 'horizontal';
		} else {
			return 'vertical';
		}
	}

	zoomToPoint(point: any) {

		let lat = (!Number.isNaN(point.lat)) ? parseFloat(point.lat) : 0;
		let lng = (!Number.isNaN(point.lng)) ? parseFloat(point.lng) : 0;
		if(google) {
		var ltlng = new google.maps.LatLng(lat, lng);
			if (this.updated_map) {
				this.updated_map.setZoom(12);
				this.updated_map.setCenter(ltlng);
			} else {
				this.map.setZoom(12);
				this.map.setCenter(ltlng);
			}
		}
	}

	getRecalc(column) {
		this.hovered_item = false;
		this.dispatchService.getRecalc(column).subscribe((results: any) => {
			this.carddata[column] = results;
		});
	}

	getRecalcSingle(column) {
		if (this.selectedTruck.unlocked) {
			this.hovered_item = false;
			this.dispatchService.getRecalc(column).subscribe((results: any) => {
				this.carddata[column] = results;
				this.focusdata = results;
				this.getRouteTotalTime();
				this.updateMap(this.focusdata);
			});
		}
	}

	getActiveRun(column: any) {
		this.viewactiverun = column
		this.editactivedispatch = true;
		// 		this.modalService.open(this.viewActiveRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {
		//
		// 		}, (reason) => {
		//
		// 		});

	}

	runList(column: any, activerun: any) {
		this.active_run = activerun;
		this.run_list = this.carddata[column];
		this.dispatchService.getRunPick(column).subscribe((results: any) => {
			this.runlist = results.items;
			this.totalonrun = results.items.reduce(function(accumulator, item) {
				return accumulator + parseFloat(item.ordered);
			}, 0);
		});
	}

	back(): void {
		this.location.back()
	}

	updateMap(data: any) {

		if (this.markers) {
			this.markers.forEach(r => {
				if (r) {
					r.setMap(null)
				}
			})
		}

		this.markers = [];

		const infoWindow = new google.maps.InfoWindow();
		infoWindow.close();
		this.removeLines();

		let shortlines = [];
		//todo make this part of company settings
		let home = { lat: 42.928455044944165, lng: -78.82142723259965 }

		let columndata = Array.from(data); //Require this to be an array

		shortlines = [home];

		//start
		//shortlines.push(home);

		let i = 0;
		if (columndata) {
			columndata.forEach((item: any) => {
				var ltlng = new google.maps.LatLng(item.lat, item.lng);
				let itemsort = item.sort + 1;
				let m = new google.maps.Marker({
					position: ltlng,
					title: '(' + itemsort + ') ' + item.orderno,
					optimized: true,
					animation: null,
					label: {
						text: '(' + itemsort + ')',
						color: '#fff',
						fontSize: "14px",
						fontWeight: "bold"
					}
				});

				m.addListener("click", () => {
					if (this.hovered_item == item.orderno) {
						infoWindow.close();
						this.hovered_item = false;
					} else {
						infoWindow.close();
						infoWindow.setContent(item.address);
						infoWindow.open(m.getMap(), m);
						this.hovered_item = item.orderno;
					}
					//this.zoomToPoint(item)
				});


				this.markers.push(m);

				shortlines.push({ lat: parseFloat(item.lat), lng: parseFloat(item.lng) })
			});
		}
		shortlines.push(home)

		var myLatlng = new google.maps.LatLng(42.928455044944165, -78.82142723259965);
		var mapOptions = {
			zoom: 11,
			center: myLatlng,
			scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
			styles: [{
				"featureType": "water",
				"stylers": [{
					"saturation": 43
				}, {
					"lightness": -11
				}, {
					"hue": "#0088ff"
				}]
			}, {
				"featureType": "road",
				"elementType": "geometry.fill",
				"stylers": [{
					"hue": "#ff0000"
				}, {
					"saturation": -100
				}, {
					"lightness": 99
				}]
			}, {
				"featureType": "road",
				"elementType": "geometry.stroke",
				"stylers": [{
					"color": "#808080"
				}, {
					"lightness": 54
				}]
			}, {
				"featureType": "landscape.man_made",
				"elementType": "geometry.fill",
				"stylers": [{
					"color": "#ece2d9"
				}]
			}, {
				"featureType": "poi.park",
				"elementType": "geometry.fill",
				"stylers": [{
					"color": "#ccdca1"
				}]
			}, {
				"featureType": "road",
				"elementType": "labels.text.fill",
				"stylers": [{
					"color": "#767676"
				}]
			}, {
				"featureType": "road",
				"elementType": "labels.text.stroke",
				"stylers": [{
					"color": "#ffffff"
				}]
			}, {
				"featureType": "poi",
				"stylers": [{
					"visibility": "off"
				}]
			}, {
				"featureType": "landscape.natural",
				"elementType": "geometry.fill",
				"stylers": [{
					"visibility": "on"
				}, {
					"color": "#b8cb93"
				}]
			}, {
				"featureType": "poi.park",
				"stylers": [{
					"visibility": "on"
				}]
			}, {
				"featureType": "poi.sports_complex",
				"stylers": [{
					"visibility": "on"
				}]
			}, {
				"featureType": "poi.medical",
				"stylers": [{
					"visibility": "on"
				}]
			}, {
				"featureType": "poi.business",
				"stylers": [{
					"visibility": "simplified"
				}]
			}]

		};

		if (!this.updated_map) {
			this.updated_map = new google.maps.Map(document.getElementById("map"), mapOptions);
		}

		//this.map = new google.maps.Map(document.getElementById("map"), mapOptions);

		var marker = new google.maps.Marker({
			position: myLatlng,
			title: "Home"
		});

		marker.setMap(this.updated_map);

		this.markers.forEach((item: any) => {
			item.setMap(this.updated_map);
		});

		const lineSymbol = {
			path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
		};

		this.pathlines = new google.maps.Polyline({
			path: shortlines,
			geodesic: true,
			strokeColor: "#F1575A",
			strokeOpacity: 1.0,
			strokeWeight: 2,
			icons: [{
				icon: lineSymbol,
				offset: '100%'
			}],
		});

		this.pathlines.setMap(this.updated_map);
	}

	stepSelectionChange(event: any) {

		this.currentStep = event.selectedIndex;

		//if (!thisitem.pick_complete  && thisitem.type != '11') {
		//	let conf = confirm('Items Not Picked Yet. Procced?')
		//	proceed = false;
		//	if (conf) {
		//		proceed = true;
		//	}
		//}

		switch (event.selectedIndex) {
			//payments
			case 0:
				this.ordershidden = true;
				break;
			case 1:
				this.ordershidden = true;
				//this.getPrePay(this.selectedTruck)
				break;
			case 2:
				this.ordershidden = true;
				break;
			default:
				this.ordershidden = true;
				break;
		}
	}

	countPrePayments(): number {
		var prepays = 0;
		if (this.focusdata && this.focusdata.length) {
			prepays = this.focusdata.reduce(function(accumulator, item) {
				var addon = 0;
				if (item.info.processcc) {
					addon = 1;
				}
				return accumulator + addon;
			}, 0);
		}
		return prepays;
	}

	drawMap(column) {

		this.currentStep = 0;
		this.globalSearchService.hideSideBar();

		if (this.updated_map) {
			this.updated_map = false;
		}

		this.selectedTruck = this.allsortcolumns.filter(i => { return i.id == column })[0]
		this.selectedTruck.unlocked = true;

		let coordinates = [];
		this.columnfocus = column;

		this.setColumnFocus(column);
		this.focusdata = this.carddata[column];

		this.getRouteTotalTime();

		const infoWindow = new google.maps.InfoWindow();

		//this.getPrePay(column);
		//todo make this part of company settings
		let home = { lat: 42.928455044944165, lng: -78.82142723259965 }
		let lines = [home];
		let columndata = this.carddata[column];

		if (columndata) {
			let counter = 0;
			columndata.forEach((item: any) => {
				lines.push({ lat: parseFloat(item.lat), lng: parseFloat(item.lng) })
				var ltlng = new google.maps.LatLng(item.lat, item.lng);
				//counter +1;
				let itemsort = 1 + item.sort


				let m = new google.maps.Marker({
					position: ltlng,
					title: '(' + itemsort + ') ' + item.orderno,
					optimized: true,
					animation: google.maps.Animation.DROP,
					label: {
						text: '(' + itemsort + ')',
						color: '#fff',
						fontSize: "14px",
						fontWeight: "bold"
					}
				});


				m.addListener("click", () => {
					infoWindow.close();
					infoWindow.setContent(item.address);
					infoWindow.open(m.getMap(), m);
					this.hovered_item = item.orderno;
					//this.zoomToPoint(item)
				});

				coordinates.push(m);
				itemsort++;
			});
		}

		lines.push(home);

		var myLatlng = new google.maps.LatLng(42.928455044944165, -78.82142723259965);
		var mapOptions = {
			zoom: 11,
			center: myLatlng,
			scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
			styles: [{
				"featureType": "water",
				"stylers": [{
					"saturation": 43
				}, {
					"lightness": -11
				}, {
					"hue": "#0088ff"
				}]
			}, {
				"featureType": "road",
				"elementType": "geometry.fill",
				"stylers": [{
					"hue": "#ff0000"
				}, {
					"saturation": -100
				}, {
					"lightness": 99
				}]
			}, {
				"featureType": "road",
				"elementType": "geometry.stroke",
				"stylers": [{
					"color": "#808080"
				}, {
					"lightness": 54
				}]
			}, {
				"featureType": "landscape.man_made",
				"elementType": "geometry.fill",
				"stylers": [{
					"color": "#ece2d9"
				}]
			}, {
				"featureType": "poi.park",
				"elementType": "geometry.fill",
				"stylers": [{
					"color": "#ccdca1"
				}]
			}, {
				"featureType": "road",
				"elementType": "labels.text.fill",
				"stylers": [{
					"color": "#767676"
				}]
			}, {
				"featureType": "road",
				"elementType": "labels.text.stroke",
				"stylers": [{
					"color": "#ffffff"
				}]
			}, {
				"featureType": "poi",
				"stylers": [{
					"visibility": "off"
				}]
			}, {
				"featureType": "landscape.natural",
				"elementType": "geometry.fill",
				"stylers": [{
					"visibility": "on"
				}, {
					"color": "#b8cb93"
				}]
			}, {
				"featureType": "poi.park",
				"stylers": [{
					"visibility": "on"
				}]
			}, {
				"featureType": "poi.sports_complex",
				"stylers": [{
					"visibility": "on"
				}]
			}, {
				"featureType": "poi.medical",
				"stylers": [{
					"visibility": "on"
				}]
			}, {
				"featureType": "poi.business",
				"stylers": [{
					"visibility": "simplified"
				}]
			}]

		};


		this.map = new google.maps.Map(document.getElementById("map"), mapOptions);

		var marker = new google.maps.Marker({
			position: myLatlng,
			title: "Home"
		});

		marker.setMap(this.map);

		coordinates.forEach((item: any) => {
			item.setMap(this.map);
		});

		const lineSymbol = {
			path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
		};

		const pathTo = new google.maps.Polyline({
			path: lines,
			geodesic: true,
			strokeColor: "#F1575A",
			strokeOpacity: 1.0,
			strokeWeight: 2,
			icons: [{
				icon: lineSymbol,
				offset: '100%'
			}],
		});

		pathTo.setMap(this.map);

	}

	identify(index, item) {
		return item.did;
	}

	clearInput() {
		this.sortcolumns = this.allsortcolumns
		this.selectedColumn = '';
	}

	toggleFlip(column: any) {

		if (column == '') {
			this.focusdata = [];
			this.columnfocus = false;

		} else {
			this.setColumnFocus(column);
			this.selectedColumn = column;
			this.focusdata = this.carddata[column];

			this.selectedTruck = this.allsortcolumns.filter(i => { return i.id == column })[0]
			this.selectedTruck.unlocked = true;

		}

		var proceed = true;

		if (this.flip == 'inactive') {
			if (this.activeDispatches[column].dispatched.length) {
				proceed = confirm("There is an active dispatch, continue?");
			}
		}

		if (proceed) {
			this.flip = (this.flip == 'active') ? 'inactive' : 'active';
			if (this.flip == 'inactive') {
				this.selectedColumn = '';
			} else {
				if (column != '') {


					this.updateTimes(column);
					this.updateMap(this.focusdata);
					this.getPrePay(column);
				}
			}
		}

		this.filterPicked();
	}

	getCapacityLoaded(column_id: any) {
		var totalvolume = 0;
		if (this.carddata[column_id].length) {
			totalvolume = this.carddata[column_id].reduce(function(accumulator, i) {
				return accumulator + i.totalvolume;
			}, 0);

		}
		return totalvolume;
	}

	toggleAddingCard(p: any) {
		p.addingcardparent = (p.addingcardparent) ? false : true;
	}

	updateProfile(event: any, p: any) {

		let order = this.needpayments.filter(i => {
			return i.details.header.orderno == event.orderno
		})[0]

		if (order) {
			let index = this.needpayments.indexOf(order);

			this.needpayments[index].cc_on_file = true;
			this.needpayments[index].cardsonfile = event.card.cards
			this.needpayments[index].details.payments[0].payment_method = event.card.cards[0].paymentid;
			this.needpayments[index].addingcardparent = false;
			this.needpayments[index].cc_charge = event.card.cards[0];
		}

		p.addingcardparent = false;

	}

}
