import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

import * as FileSaver from 'file-saver';

import { CustomerService } from '../../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { CreditService } from '../../services/credit.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { PurchasingService } from '../../services/purchasing.service';

import { Directive, HostListener } from "@angular/core";
import { trigger, state, style, transition, animate } from '@angular/animations';
import { saveAs } from "file-saver";

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-finalize',
	templateUrl: './finalize.component.html',
	styleUrls: ['./finalize.component.scss']
})
export class FinalizeComponent implements OnInit {

	orderno: any = '';
	reprintlink: any = '';
	dispatchlink: any = '';
	dispatching: boolean = false;
	dispatch_details: any = [];
	taxes: any = [];
	order: any = [{
		header: [],
		details: [],
	}]

	prefered_vendor: any = [];
	order_lines: any = [];

	config: any = [];
	modifed: any = [];
	remoteqtys: any = [];

	baselink: string = '';

	po_vendors: any = [];
	po_vendor_items: any = [];

	purchase_item: any = {};
	created_pos: any = false;
	sending: boolean = false;
	purchordercreated: boolean = false;
	purchordercreated_details: any = [];
	color: string = 'blue'
	loading_remote: boolean = false;

	constructor(private globalsService: GlobalsService, private route: ActivatedRoute, private customerService: CustomerService, private creditService: CreditService, private location: Location, public router: Router, private globalSearchService: GlobalSearchService, private purchasingService: PurchasingService, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();

	}

	ngOnInit(): void {
		this.config = this.globalsService.getApiConfig()
		this.baselink = this.config.apiServer.baseUrl + this.config.apiServer.creditPickingLink;

		this.route.params.subscribe(params => {
			this.orderno = params.transno

			this.getOrder();

			this.globalSearchService.orderResults.subscribe((modified: any) => {
				this.modifed = modified;
			})

			if (params.dispatch == 'dispatch') {
				this.dispatching = true;
			} else {
				this.reprintlink = this.baselink + '' + this.orderno;
				this.dispatchlink = this.orderno + '/dispatch';
				//this.goToLink(this.baselink+this.orderno);
			}
		})
	}

	getOrder() {
		this.creditService.getOrderInvoice(this.orderno).subscribe((details: any) => {
			this.order = details
			this.order_lines = details.details;
			this.created_pos = details.created_pos
			const poitems = [];
			details.podetails.forEach((item: any) => {
				let already_ordered = 0;
				if (item.haspo) {
					already_ordered = item.haspo.quantiy_order;
				}

				let qty = (item.haspo) ? item.poqty - already_ordered : item.poqty;
				if (qty > 0) {
					let data = {
						vendor: item.po_options.supplierno,
						vendor_name: item.po_options.suppname,
						stockid: item.stockid,
						orderqty: qty,
						orderlineno: item.orderlineno,
						description: item.description,
						price: parseFloat(item.po_options.price),
						options_selected: item.po_options,
						purchdata: item.purchdata,
						haspo: item.haspo
					}
					var result = (item.porequired) ? data : false;
					if (result) {
						poitems.push(result);
					}
				}
			});

			const all_vendors = poitems.map(a => a.vendor);
			const unique_vendors = all_vendors.filter((x, i, a) => a.indexOf(x) == i)


		})
	}

	editOrder(orderno: any) {
		this.creditService.loadSalesOrder(orderno).subscribe((result: any) => {
			let link = result.header.debtorno + '/' + result.header.branchcode;
			this.router.navigate(['/receivables/credit-note/' + link]);
		})
	}

	setPurchOrderOptions(line: any, selected: any) {
		let data = {
			orderno: this.orderno,
			orderlineno: line.orderlineno,
			option: selected
		}

		this.creditService.setSalesOrderPoOptions(data).subscribe((results: any) => {
			this.getOrder();
			this.modalService.dismissAll();
			this.purchase_item = {};
			//.close(this.purchasedetails);
		});
	}

	createPurchaseOrders() {
		this.sending = true;

		this.purchasingService.createPurchaseOrders(this.po_vendors).subscribe((results: any) => {
			this.getOrder();
			this.modalService.dismissAll();
			this.purchase_item = {};
			this.po_vendors = false;
			this.sending = false;
			this.purchordercreated = results.success;
			this.purchordercreated_details = results.message
			this.order.porequired = false;
		});
	}

	updateItem(pdata: any) {
		this.purchase_item.purchdata = pdata.purchdata
	}

	addPoItems(supplier: any) {

		let item = [{
			stockid: '',
			description: '',
			line_notes: '',
			qty: '',
			price: '',
		}];

		this.purchasingService.addToOrder(item, supplier).subscribe((result) => {

		})
	}

	back(): void {
		this.location.back()
	}

}
