import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { InventoryService } from '../../services/inventory.service'
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-groups',
  templateUrl: './product-groups.component.html',
  styleUrls: ['./product-groups.component.scss']
})
export class ProductGroupsComponent implements OnInit {
	color: any = 'blue';
	searchingcgroups: any = false;
	@ViewChild("groupEdit") groupEditRef: ElementRef;

	filteredcgroups: any = [];
	filteredcustomers: any = [];
	cgroups: any = [];
	customers: any = [];
	editinggroup: any = {};
	cgroupsearch = new FormControl('');
	custsearch = new FormControl('');
	viewingglc: any = false;
  constructor(private fb: UntypedFormBuilder, private inventoryService: InventoryService, private globalSearchService: GlobalSearchService, private modalService: NgbModal) { }

  ngOnInit(): void {
		this.color = this.globalSearchService.getColor();
		this.cgroupsearch.valueChanges.subscribe(newValue => {
			this.filterGroup();
		});
		this.custsearch.valueChanges.subscribe(newValue => {

			this.filterCustomers();
		});
		this.inventoryService.getItems().subscribe(results => {
			this.customers = [];
			this.filteredcustomers = [];
		});
		this.loadGroups();
	}

	filterGroup() {

		if (this.cgroupsearch.value == '') {
			this.searchingcgroups = this.cgroups;
		} else {
			this.searchingcgroups = this.globalSearchService.filterItem(this.cgroups, this.cgroupsearch.value, 'name,description')
		}

	}
	filterCustomers() {

		if (this.custsearch.value == '') {
			this.filteredcustomers = this.customers;
		} else {
			this.inventoryService.itemSearch({ 'stockid': this.custsearch.value }).subscribe(results => {
				this.filteredcustomers = results;
			});
		}

	}

	loadGroups() {
		this.inventoryService.getGroups().subscribe(results => {
			this.cgroups = results;
			this.filteredcgroups = results;
			this.searchingcgroups = results;
		});
	}

	addCustomerGroup() {

		this.editinggroup = { group_id: '', name: '', description: '', findmy: '', group_type: 1, members: [] };
		this.modalService.open(this.groupEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});

	}

	editGroup(group: any) {
		this.editinggroup = group;
		
		this.modalService.open(this.groupEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}

	addCust(customerno: any) {

		if (this.editinggroup.members.length == 0) {
			this.editinggroup.members.push({ group_id: this.editinggroup.group_id, stockid: customerno.stockid, description: customerno.description});
		} else {
			this.editinggroup.members.push({ group_id: this.editinggroup.group_id, stockid: customerno.stockid, description: customerno.description });
		}
		this.custsearch.setValue('');
	}
	//So much for two-way binding
	updateGroup(fieldname: any, event: any) {

		if(fieldname == 'name'){
		this.editinggroup.name = event;
		} else if (fieldname == 'desc'){
		this.editinggroup.description = event;
		} else if (fieldname == 'findmy'){
		this.editinggroup.findmy = event;
		} else if (fieldname == 'group_type'){
		this.editinggroup.group_type = event;
		}
	}
	
	removeMember(cust: any) {
		let index = this.editinggroup.members.indexOf(cust);
		this.editinggroup.members.splice(index, 1);
	}
	setPrimary(cust: any, event: any) {

		let index = this.editinggroup.members.indexOf(cust);
		this.editinggroup.members[index].is_primary = event;
		this.editinggroup.members.forEach((member: any, index2) => {

			if (index2 != index) {
				member.is_primary = false;
			}
		});

	}
	saveGroup() {

		this.inventoryService.saveGroup(this.editinggroup).subscribe(results => {
			this.cgroups = results;
			this.filteredcgroups = results;
			this.searchingcgroups = results;
			this.editinggroup = {};
			this.modalService.dismissAll();
		});

	}
	removeGroup(group) {
		this.inventoryService.removeGroup(group.group_id).subscribe(results => {
			this.cgroups = results;
			this.filteredcgroups = results;
			this.searchingcgroups = results;
			this.editinggroup = {};
		});
	}
	displayFn(cust: any): string {
		return cust ? cust.stockid + ': ' + cust.description : '';
	}
	back() {

	}

}
