import { Injectable } from '@angular/core';
import { catchError , retry} from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpClientModule,HttpHeaders,HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';
import { HttpParams } from "@angular/common/http";

@Injectable({
	providedIn: 'root'
})
export class PrintService {
	key: string = 'UET6DFMPAXW7BKCB';
	config: any;
	user : any = [];
	package: any = '';

	constructor(private http: HttpClient,private _configSvc: RuntimeConfigLoaderService, public globalSearchService: GlobalSearchService) {
			this.globalSearchService.configsubscription.subscribe(r=>{
				this.config = r;
			});

			this.globalSearchService.user.subscribe( (result: any) => {
				this.user = result;
			});
		}

		public httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':	'application/json',
			})
		};

		public pdf(data: any) {
			var method = 'print/pdf';
			return this.http.post(this.setEndPoint(method), data , this.httpOptions).pipe(
				 catchError(this.handleError(method))
			);
		}



		public printRun(data: any) {
			var method = 'documents/delivery/printruninvoices';
			return this.http.post(this.setEndPoint(method), data , this.httpOptions).pipe(
				 catchError(this.handleError(method))
			);
		}

		public loadsheet(data: any) {
			var method = 'documents/delivery/print';
			return this.http.post(this.setEndPoint(method), data , this.httpOptions).pipe(
				 catchError(this.handleError(method))
			);
		}

		public xls(data: any) {
			var method = 'print/xls';
			return this.http.post(this.setEndPoint(method), data , this.httpOptions).pipe(
				 catchError(this.handleError(method))
			);
		}

		public getCustomer(customerid: any, branchcode:any) {
			var method = 'customers/orderentry&debtorno='+customerid+'&branchcode='+branchcode;
			return this.http.get(this.setEndPoint(method));
		}

		private setEndPoint(method: any) {
			return this.getApi()+method+this.getApiKey()
		}

		private getApi() {
			return this.config.apiServer.endpoint;
		}

		private getApiKey() {
			return '&key=' + this.config.apiCredentials.clientId + '&userid=' + this.user.user.userid + this.getPackage();
		}

		private getPackage() {
			let p = this.globalSearchService.getSessionItem('package');
			if(p) {
				return '&package='+p;
			} else {
				return '';
			}
		}

		public getKey() {
			return '&apikey='+this.key;
		}

		private handleError<T>(operation = 'operation', result?: T) {
			return (error: any): Observable<T> => {
				return error;
			};
		}

	}
