import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';


import { CreditFreestandingComponent } from './credit-freestanding/credit-freestanding.component';
import { FinalizeComponent } from './finalize/finalize.component';
import { CreditDispatchComponent } from './credit-dispatch/credit-dispatch.component';
import { CreditSuccessComponent } from './credit-success/credit-success.component';
import { CreditReceiveComponent } from './credit-receive/credit-receive.component';
import { CreditChangeCustomerComponent } from './credit-change-customer/credit-change-customer.component';

import { CreditCustomerOrdersComponent } from './credit-customer-orders/credit-customer-orders.component';

import { SharedModule } from '../shared/shared.module';
import { CreditLoadComponent } from './credit-load/credit-load.component';
import { OpenCreditListComponent } from './open-credit-list/open-credit-list.component';



@NgModule({
  declarations: [
    CreditFreestandingComponent,
    FinalizeComponent,
    CreditDispatchComponent,
    CreditSuccessComponent,
    CreditReceiveComponent,
    CreditChangeCustomerComponent,
	CreditCustomerOrdersComponent,
    CreditLoadComponent,
    OpenCreditListComponent,
  ],
  imports: [
    CommonModule,
	   BrowserModule,
	   RouterModule,
	   BrowserAnimationsModule,
	   ReactiveFormsModule,
	   FormsModule,
		MatCheckboxModule,
		MatToolbarModule,
		MatButtonModule,
		MatDialogModule,
		MatDividerModule,
		MatInputModule,
		MatTableModule,
		MatSortModule,
		MatPaginatorModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatSlideToggleModule,
		MatTabsModule,
		MatListModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatMenuModule,
		MatIconModule,
		MatSelectModule,
		SharedModule
  ]
})
export class CreditsModule { }
