import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import * as FileSaver from 'file-saver';

import { Directive, HostListener } from "@angular/core";
import { trigger, state, style, transition, animate, animation } from '@angular/animations';
import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { UsersService } from '../../services/users.service';

import { OmsService } from '../../services/oms.service';

@Component({
	selector: 'app-order-entry',
	templateUrl: './order-entry.component.html',
	styleUrls: ['./order-entry.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('500ms ease-in')),
			transition('active => inactive', animate('500ms ease-out')),
		]),
		trigger('cinfo', [
			transition(':enter', [
				animate('50ms', style({ opacity: 1, height: 100 })),
			]),
			transition(':leave', [
				animate('100ms', style({ opacity: 1, height: 0 }))
			]),
		]),
		trigger('itemSearch', [
			transition(':enter', animation([style({ transform: 'translate(-800px,0)', }),
				animate('0.2s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(1200px,0)', })
				),
			])),
		]),
		trigger('addFilters', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
	],

})

export class OrderEntryComponent implements OnInit {

	@ViewChild("itemdetails") itemDetails: ElementRef;
	@ViewChild("purchasedetails") purchasedetails: ElementRef;
	@ViewChild("creditover") creditover: ElementRef;
	@ViewChild("configproductele") configproductele: ElementRef;
	@ViewChild("itemHistoryRef") itemHistoryRef: ElementRef;
	@ViewChild("nonstock") nonstockref: ElementRef;


	CHAT_ROOM = "OrderBoard";
	orderResults: any = [];
	customer_data: any = [];
	filters: any = [];
	debtorno: any = '';
	branchcode: any = '';
	today = new Date();
	lastsearch: any = '';
	//cart
	dspcost: boolean = true;
	showGuide: boolean = false;
	total_cart_qty: number = 0;
	cart_items: any = [];
	cart_totals: any = [];
	selectedindex: number = 0;
	activeLink: any = 'order_entry';

	//subscription to search
	inventorysearching: any = false;

	search_items: any = [];
	filtered_items: any = [];
	searching: boolean = false;

	taxgroups: any = [];
	salespeople: any = [];
	shipvia: any = [];
	terms: any = [];
	zones: any = [];
	locations: any = [];
	holdreasons: any = [];
	itemhistory: any = [];

	limits = [
		{ name: '25', value: '25' },
		{ name: '50', value: '50' },
		{ name: '100', value: '100' },
		{ name: '150', value: '150' },
		{ name: '250', value: '200' },
		{ name: '500', value: '500' },
		{ name: 'No Limit', value: false },
	];

	sorts = [
		{ name: 'All Availability, Sell Low To High', value: 'avail.sell|ASC' },
		{ name: 'All Availability, Sell High To Low', value: 'avail.sell|DESC' },
		{ name: 'Local Availability, Sell Low To High', value: 'local.sell|ASC' },
		{ name: 'Local Availability, Sell High To Low', value: 'local.sell|DESC' },
		{ name: 'Sell Low To High', value: 'price|ASC' },
		{ name: 'Sell High To Low', value: 'price|DESC' },
		{ name: 'Name ASC', value: 'description|ASC' },
		{ name: 'Name DESC', value: 'description|DESC' },
	];

	itemSearchForm: UntypedFormGroup;
	customerForm: UntypedFormGroup

	customerEditAllowed = [
		'debtorno',
		'branchcode',
		'name',
		'address1',
		'address2',
		'address3',
		'address4',
		'address5',
		'address6',
		'contactname',
		'brname',
		'braddress1',
		'braddress2',
		'braddress3',
		'braddress4',
		'braddress5',
		'braddress6',
		'email',
		'salesman',
		'salestype',
		'defaultshipvia',
		'phoneno',
		'faxno',
		'specialinstructions',
		'defaultlocation',
		'paymentterms',
		'tax_ref',
		'taxgroupid',
		'area',
		'datewanted',
		'enteredby',
		'takenby',
	]

	addonfields: [
		'datewanted',
		'enteredby',
		'takenby',
		'freightcost',
	];

	addingitems: boolean = false;
	customerinfo: boolean = true;
	//viewing item
	item_details: any = false;
	editing_order: boolean = false;
	order_details: any = [];
	purchase_items: any = [];
	user: any = [];

	showfilters: boolean = false;
	overcredit: boolean = false;
	allowed_credit: boolean = false;
	proceed: boolean = false;
	overcredit_override: string = '';
	selecteditem: any = false;
	config: any = [];

	nonStockForm: UntypedFormGroup;
	brands: any = [];
	librarysearch = new UntypedFormControl('');
	librarySearchResults: any = [];
	librarySearchSelected: any = false;
	librarySearching: any = false;
	collapseMini: any = false;

	constructor(private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, public omsService: OmsService, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal, public usersService:UsersService) {

		this.config = this.globalsService.getApiConfig();
	}

	ngOnInit(): void {

		this.globalSearchService.mesagesallowed.next(false);
		this.customer_data.dissallowinvoices = false;
		this.route.params.subscribe(params => {
			if(this.debtorno != params['cid'] && this.branchcode != params['branch']) {
				this.debtorno = params['cid'];
				this.branchcode = params['branch'];
				//load customer. cart. and totals.
				this.orderService.getCustomer(this.debtorno, this.branchcode).subscribe((results: any) => {
					this.customer_data = results;
					var details = results
				});
			}
		});
	}

	loadOrder(order: any) {
		//this.orderService.loadSalesOrder(order.header.orderno).subscribe((result: any) => {
			this.selectedindex = 0;
		//})
	}

	back(): void {
		this.location.back()
	}

	randomString(length: any, chars: any) {
		var result = '';
		for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
		return result;
	}

	openModal(content, item) {

		this.item_details = item;
		this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {
			this.item_details = false;
		});
	}

	moveTODispatch(event) {
		if(event) {
			this.router.navigate(['/orders/invoice/'+event+'/dispatch']);
		}
	}

	invoice(event) {
		this.router.navigate(['/orders/invoice/'+event+'/dispatch']);
	}

	ngAfterViewInit() {

	}

	// CART FUNCTIONS
	setItemSelectedPoOption(item: any, data: any) {
		item.selected_po_option = data;
	}

	viewitemOrderHistory(item: any) {
		this.selecteditem = item;
		this.customerService.getCustomerHistoryItem(this.debtorno, item.stockid).subscribe((result: any) => {
			this.itemhistory = result
			this.modalService.open(this.itemHistoryRef, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result:any) => {

			}, (reason) => {
				this.itemhistory = [];
				this.selecteditem = false;
			});
		});
	}

	setPurchOrderOptions(cart_id: any, selected: any) {
		let data = { cart_id: cart_id, options: selected }
		this.orderService.setPoOptions(data).subscribe((results: any) => {
			this.modalService.dismissAll();
			this.purchase_items = [];
			this.orderService.getCart(this.debtorno, this.branchcode).subscribe(async (items: any) => {
				this.cart_items = items;
				this.updateTotals();
			});

			//.close(this.purchasedetails);
		});

		this.sendCartUpdate();
	}

	viewPurchOrderOptions(line: any) {
		line.allownegative = true;
		let data = { cart_id: line.cart_id, stockid: line.stockid }
		this.orderService.getPurchDataFromLine(data).subscribe((results: any) => {
			this.purchase_items.push(results);
			this.modalService.open(this.purchasedetails, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result:any) => {

			}, (reason) => {
				this.purchase_items = [];
			});
		});
	}

	removeFromOrder(cart_id) {
		this.orderService.removeFromOrder(cart_id, this.debtorno, this.branchcode).subscribe((results: any) => {
			this.cart_items = results;
			this.globalSearchService.showNotification('Item Removed', 'danger', 'bottom', 'left');
			this.updateTotals();
			this.sendCartUpdate();
		});
	}

	sendCartUpdate() {

		if (this.user) {

			let data = [{ customer: this.debtorno, user: this.user }]

			this.omsService.sendCartUpdate({ data, roomName: 'CartRoom' }, cb => {});
		}
	}

	updateTotals() {

		this.orderService.getTotals(this.debtorno, this.branchcode).subscribe((results: any) => {
			this.cart_totals = results;
			this.total_cart_qty = this.cart_items.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.quantity);
			}, 0);

			this.checkCreditLimit()
		});

	}

	getTotal() {

		let total = this.cart_totals.filter(t => {
			return t.code=='total';
		})[0]

		var value = 0.00;
		if(total) {
			value = parseFloat(parseFloat(total.text).toFixed(2))
		}

		return value;

	}


	customerChanged(customer: any) {
		this.customerinfo = true;
		this.customer_data.dissallowinvoices = false;
		this.selectedindex = 0;
		this.orderService.getCustomer(customer.debtorno, customer.branchcode).subscribe((results: any) => {
			this.customer_data = results;
			var details = results
			this.router.navigate(['/orders/entry/' + customer.debtorno + '/' + customer.branchcode]);
		});
	}

	getFormValidationErrors() {
		Object.keys(this.customerForm.controls).forEach((key, index) => {
			const controlErrors: ValidationErrors = this.customerForm.get(key).errors;
			if (controlErrors != null) {
				Object.keys(controlErrors).forEach(keyError => {
					//this.customerForm.controls[index].markAsTouched()
					this.customerForm.markAllAsTouched();
					this.itemSearchForm.markAsUntouched()
					this.customerinfo = true;


					if(key == 'reference') {
						key = 'PO Number';
					}

					this.globalSearchService.showNotification(key + ' ' + keyError , 'danger', 'bottom', 'right')
				});
			}
		});
	}


	checkCredit() {
		if (!this.allowed_credit) {
			this.proceed = true;
			let subtotal = this.cart_totals.filter(t => {
				return t.code == 'sub_total'
			})[0]

			if (parseFloat(subtotal.text) > this.customer_data.credit_avail) {
				//alert("This order will Breech the customers credit limit ($"+this.customer_data.credit_avail+'/$'+this.customer_data.creditlimit+")")
				this.proceed = false;
			}
		}

		this.proceed = true;

	}

	checkCreditLimit() {
		if (!this.allowed_credit) {
			this.proceed = true;
			this.overcredit = false;
			let subtotal = this.cart_totals.filter(t => {
				return t.code == 'sub_total'
			})[0]

			if (parseFloat(subtotal.text) > this.customer_data.credit_avail) {
				this.proceed = false;
				this.overcredit = true;
				if (this.overcredit_override == '') {
					this.orderService.flagCart(this.customer_data.debtorno, this.customer_data.branchcode).subscribe((result: any) => {
						this.overcredit_override = result
					})
				}
			}
		}
	}

	unlock() {
		if (this.proceed) {
			this.orderService.unflagCart(this.customer_data.debtorno, this.customer_data.branchcode).subscribe((result: any) => {
				this.overcredit_override = result
				this.overcredit = false;
				this.allowed_credit = true;
				this.modalService.dismissAll();
			})
		}
	}

	verifyUnlock(value: any) {
		this.proceed = false;
		value = value.toUpperCase()
		if (this.overcredit_override.toUpperCase() == value.toUpperCase()) {
			this.proceed = true;
		}

		return this.proceed;
	}

	updateItem(pdata: any) {
		this.purchase_items[0].allownegative = true;
		this.purchase_items[0].purchdata = pdata.purchdata
	}

	clearCartCancel() {
		this.orderService.clearOrder(this.debtorno, this.branchcode).subscribe((results: any) => {
			this.editing_order = false;
			this.order_details = false;
		});
	}

	placeOrder() {

		this.checkCredit();
		this.getFormValidationErrors();
		this.allowed_credit = true;
		if (!this.proceed && !this.allowed_credit) {
			this.modalService.open(this.creditover, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

			}, (reason) => {
				this.item_details = false;
			});
		}


		if (this.customerForm.valid && this.proceed) {

			if (!this.editing_order) {

				this.orderService.createOrder(this.customerForm.value, this.cart_items, this.cart_totals, this.user).subscribe((results: any) => {
					this.globalSearchService.orderResults.next(results);
					this.orderResults = results;

					//let data = { message: 'neworder' }
					//this.omsService.sendCardUpdate({data, roomName: this.CHAT_ROOM}, cb => {
					//});
					//this.omsService.sendOpenOrderUpdate({data, roomName: this.CHAT_ROOM}, cb => {
					//});

					this.orderService.clearOrder(this.debtorno, this.branchcode).subscribe((results: any) => {
						this.cart_items = [];
						this.total_cart_qty = 0;
						let data = {};
						this.omsService.sendOrderPickUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
						this.router.navigate(['/orders/invoice/' + this.orderResults.orderno]);
					});
				});
			} else {
				this.orderService.updateSalesOrder(this.customerForm.value, this.cart_items, this.cart_totals, this.order_details, this.user).subscribe((results: any) => {
					this.globalSearchService.orderResults.next(results);
					this.orderResults = results;
					let data = {};
					this.omsService.sendOrderPickUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
					this.router.navigate(['/orders/invoice/' + this.orderResults.orderno]);
				});
			}
		}
	}

}
