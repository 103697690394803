import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, Output, EventEmitter, PipeTransform, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { merge, fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { PurchasingService } from '../../services/purchasing.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';

import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Location } from '@angular/common'

import * as _ from 'underscore';
import * as XLSX from 'xlsx';
declare var $: any;


@Component({
	selector: 'app-open-purchase-orders',
	templateUrl: './open-purchase-orders.component.html',
	styleUrls: ['./open-purchase-orders.component.scss']
})
export class OpenPurchaseOrdersComponent implements OnInit {

	@Input() item: any = false;
	@Input() vendor: any = false;
	@Output() update_item = new EventEmitter < any > ();
	@Output() orderloaded = new EventEmitter < any > ();
	@Output() po_data = new EventEmitter < any > ();
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	purchaseorders: any = [];
	allpurchaseorders: any = [];
	headercolumns: string[] = [
		'actions',
		'orderno',
		'status_id',
		'suppname',
		'orddate',
		'requisitionno',
		'ordervalue',
		'orderpendingvalue',
		'ordereditems',
		'deliverydate',
		'initiator',

	];

	statuses: any = [];
	openOrderData: any = [];
	config: any = [];
	baselink: string = '';
	explink: string = '';
	reclink: string = '';
	color: string = '';
	podetails: any = [];
	vendorSearch = new FormControl('');
	searchInputItems = new UntypedFormControl('');
	status= new FormControl('');
	searching: boolean = false
	search: any = false;
	itemsearch: boolean = false;
	itemsearchsubscription: any = false;
	itemsearchresults: any = [];

	constructor(private route: ActivatedRoute, private purchasingService: PurchasingService, private globalsService: GlobalsService, public globalSearchService: GlobalSearchService, public location: Location, private modalService: NgbModal) {

		this.color = this.globalSearchService.getColor();

	}

	ngOnInit(): void {
		this.config = this.globalsService.getApiConfig()
		this.baselink = this.config.apiServer.poLink;
		this.explink = '?route=apir/documents/popicking/excel&key='+this.config.apiCredentials.clientId+'&orderno=';
		this.reclink = this.config.apiServer.recLink;
	}

	loadData() {
		this.purchasingService.searchPurchaseOrders(this.search).subscribe((results: any) => {
			this.allpurchaseorders = results;
			this.po_data.emit(results);
			this.statuses = results.statuses
			this.openOrderData = results.pos;
			this.setTableData(results.pos)
		});
	}

	getStatus(id: any) {
		return this.statuses.filter((st) => {
			return parseFloat(st.status_id) == parseFloat(id)
		})[0];
	}

	loadOrder(orderno: any) {
		this.purchasingService.loadPurchaseOrder(orderno).subscribe((result: any) => {
			this.orderloaded.emit(result);
		});
	}

	searchItems() {
		let value = this.searchInputItems.value;

		let search = {
			status: this.status.value,
			keywords: this.searchInputItems.value,
			item: this.item,
			vendor: this.vendor,
		}

		this.purchasingService.searchPurchaseOrders(search).subscribe((results: any) => {
			this.allpurchaseorders = results;
			this.po_data.emit(results);
			this.statuses = results.statuses
			this.openOrderData = results.pos;
			this.setTableData(results.pos)
		});
	}

	searchOrders() {
		let search = {
			status: this.status.value,
			keywords: this.vendorSearch.value,
			item: this.item,
			vendor: this.vendor,
		}

		this.purchasingService.searchPurchaseOrders(search).subscribe((results: any) => {
			this.allpurchaseorders = results;
			this.po_data.emit(results);
			this.statuses = results.statuses
			this.openOrderData = results.pos;
			this.setTableData(results.pos)
		});
	}

	updateStatus(event: any, trans: any) {

		let status_details = this.getStatus(event.value);
		let index = this.allpurchaseorders.pos.indexOf(trans)

		this.allpurchaseorders.pos[index].editing = false;
		this.allpurchaseorders.pos[index].status_id = status_details.status_id;
		this.allpurchaseorders.pos[index].statusname = status_details.name;

		this.purchasingService.updateStatus(this.allpurchaseorders.pos[index]).subscribe((result: any) => {
			this.searchOrders();

		})

	}

	exportExcel() {
		this.globalSearchService.exportJsonToExcel(this.allpurchaseorders.pos, 'OpenPurchaseOrders');
	}

	toggleStatusChange(trans: any) {
		let index = this.allpurchaseorders.pos.indexOf(trans);
		if (this.allpurchaseorders.pos[index].editing) {
			this.allpurchaseorders.pos[index].editing = false;
		} else {
			this.allpurchaseorders.pos[index].editing = true;
		}
	}

	onInput() {
		//tood make this ajax call to search for items on orders as well
		this.purchaseorders = this.openOrderData
		var search = this.vendorSearch.value;
		this.searching = true;

		this.filterByStatus();

		this.purchaseorders = this.globalSearchService.filterItem(this.purchaseorders, search, 'orderno,suppname,supplierno,deliverydate,itemcode,itemdescription,requisitionno,initiator');

		this.setTableData(this.purchaseorders)

		this.searching = false;
	}

	announceSortChange(event: any) {

	}

	viewOrder(orderno: any, content: any) {
		this.purchasingService.getPurchaseOrder(orderno).subscribe((order: any) => {
			this.podetails = order
			this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		});
	}


	back(): void {
		this.location.back()
	}

	setTableData(data: any) {
		this.purchaseorders = new MatTableDataSource(data);
		this.purchaseorders.sort = this.sort;
		this.purchaseorders.paginator = this.paginator;
		this.filterByStatus();
	}

	ngOnChanges(changes: any) {
		if (changes) {
			let search = {
				item: this.item,
				vendor: this.vendor,
			}

			this.purchasingService.searchPurchaseOrders(search).subscribe((results: any) => {
				this.allpurchaseorders = results;
				this.po_data.emit(results);
				this.statuses = results.statuses
				this.openOrderData = results.pos;
				this.setTableData(results.pos)
			});
		}
	}

	//conflicting status searches todo: correct
	filterByStatus() {
		const key = this.status.value

		if (!key) {
			//this.purchaseorders = this.openOrderData
		}
		else {
			this.purchaseorders = this.openOrderData.filter((item: any) => { return item.status_id == key })
		}
	}

	onStatusSelection() {
		this.onInput()
	}

	ngAfterViewInit() {
		//this.customers.sort = this.sort;
		//this.customers.paginator = this.paginator;
	}

}
