<div class="main-content" *ngIf="company">
	<form [formGroup]="companyForm" class="text-dark" (submit)="update()">
		<div class="container-fluid" *ngIf="company">
			<div class="row">
				<div class="col-md-12">
					<div class="card">
						<div class="card-header card-header-{{color}}">
							<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Company Settings</h4>
						</div>
						<div class="card-body">
							<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
								<mat-tab label="Company Preferences">
									<form [formGroup]="companyForm">
										<div class="row">
											<div class="col-md-6" *ngFor="let control of controls ">

												<mat-form-field appearance="fill">
													<mat-label>{{ properCase(control) }}</mat-label>
													<input matInput [formControlName]="control">
												</mat-form-field>

											</div>
										</div>

										<button mat-button mat-raised-button color='primary' (click)="update()">Update</button>

									</form>
								</mat-tab>
								<mat-tab label="Email Settings">
									<app-mail></app-mail>
								</mat-tab>
								<mat-tab label="Store Setup">
									<app-store-maintenance></app-store-maintenance>
								</mat-tab>
								<mat-tab label="Salesperson Setup">
									<app-sales-people></app-sales-people>
								</mat-tab>
							</mat-tab-group>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
