import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { Location } from '@angular/common'
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { PrintService } from '../../services/print.service'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { Directive, HostListener } from "@angular/core";
import { trigger, state, style, transition, animate } from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-receivables-allocations',
	templateUrl: './receivables-allocations.component.html',
	styleUrls: ['./receivables-allocations.component.scss'],
	animations: [
		trigger('detailExpand', [
		  state('collapsed', style({height: '0px', minHeight: '0'})),
		  state('expanded', style({height: '*'})),
		  transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})
export class ReceivablesAllocationsComponent implements OnInit {

	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild("allocations") allocationsRef: ElementRef;
	@ViewChild('print_table') printtable: ElementRef;

	headercolumns: string[] = [
		'transno',
		'trandate',
		'debtorno',
		'name',
		'invtext',
		'opentrans',
		'totalamount',
		'allocated',

		'balance',
		//'expandedDetail',
	];

	filename: string = 'OpenReceivables';
	transactions: any = [];
	alltransactions: any = [];
	color: any = 'blue';
	keywords = new UntypedFormControl('');
	customertypefilter= new UntypedFormControl('');
	displaymattable: any = true;
	displaydetails: any = [];
	transid: any = false;
	transidtype: any = false;
	trandata: any = false;
	expandedElement: any = false;
	prevRow: any = false;
	locations: any = false;
	loccode: any = '';
	payments: any = [];
	credits: any = [];
	transactionFilter = [
		{value: '', name: 'All'},
		{value: '1', name:'Open Transactions'},
		{value: '0', name:'No Open Transactions'}
	]
	hasinvoices: any = '';
	searchSubscription: any = false;
	loading: boolean = false;
	customertypes: any = [];
	pageSize: any = 10000;
	constructor(private location: Location,private printService: PrintService,private route: ActivatedRoute, public globalSearchService: GlobalSearchService, private modalService: NgbModal, private globalsService: GlobalsService) {
		this.color = this.globalSearchService.getColor();
		 this.globalSearchService.customertypes.subscribe(r=> {
			this.customertypes =r;
		})
	}

	ngOnInit(): void {

		this.globalSearchService.locations.subscribe((r:any) => {
			this.locations = r;
			if(r) {
				this.loccode = r[0].loccode
			}
			this.loadData();
		})

		this.route.params.subscribe(params => {
			if(params['transid'] != '' && params['transid']) {
				this.transid = params['transid'];
				let data = { transid: this.transid, type: '10' };
				this.globalsService.getAllocationData(data).subscribe((results: any) => {
					this.trandata = results;
					this.trandata.balance = Math.abs(results.balance);
				});
			}
		});
	}

	filterCustomerType() {
		let type = this.customertypefilter.value;
		if(type != '') {
			let filtered = this.globalSearchService.filterItem(this.alltransactions,type,'typeid')
			this.transactions = new MatTableDataSource(filtered);
			this.transactions.sort = this.sort;
			this.transactions.paginator = this.paginator;
		} else {
			this.transactions = new MatTableDataSource(this.alltransactions);
			this.transactions.sort = this.sort;
			this.transactions.paginator = this.paginator;
		}

	}

	filterPayments() {
		this.transactions = new MatTableDataSource(this.payments);
		this.transactions.sort = this.sort;
		this.transactions.paginator = this.paginator;
	}

	filterCredits() {
		this.transactions = new MatTableDataSource(this.credits);
		this.transactions.sort = this.sort;
		this.transactions.paginator = this.paginator;
	}

	expandRow(row: any, index: any ) {

		if(this.prevRow && this.prevRow >= 0) {
			this.transactions.data[this.prevRow].selected = false;
		}

		if(index >= 0) {
			let previousindex = index - 1;
			if(previousindex >= 0) {
				this.prevRow = previousindex;
				this.transactions.data[previousindex].selected = true;
			}
		}

		this.expandedElement = (this.expandedElement == row) ? false : row;

		if(!this.expandedElement) {
			this.loadData();
			row.selected = false;
		}
	}

	clickEvent(index: any) {

	}

	exportPdf() {

		let encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls() {
		let encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}


	loadData() {

		let search = {
			loccode:this.loccode,
			hasinvoices: this.hasinvoices,
		}
		if(this.searchSubscription) {
			this.searchSubscription.unsubscribe();
		}

		this.loading = true;
		this.searchSubscription = this.globalsService.getAllocationTrans(search).subscribe(async (results: any) => {
			this.alltransactions = results.ar.recs
			this.transactions = new MatTableDataSource(results.ar.recs);
			this.transactions.sort = this.sort;
			this.transactions.paginator = this.paginator;

			if(this.prevRow) {
				this.transactions.data[this.prevRow].selected = true;
			}

			if(this.keywords.value !='') {
				this.searchTransaction(this.keywords.value)
			}

			this.credits = results.ar.recs.filter(t => {
				return (t.type == '11') ? true : false;
			})

			this.payments = results.ar.recs.filter(t => {
				return (t.type == '12') ? true : false;
			})
			this.loading = false;
		});
	}

	searchTransaction(value) {
		if(value != '') {
			let filtered = this.globalSearchService.filterItem(this.alltransactions,value,'transno,balance,total,debtorno,branchcode,combined,name')
			this.transactions = new MatTableDataSource(filtered);
			this.transactions.sort = this.sort;
			this.transactions.paginator = this.paginator;
		} else {
			this.transactions = new MatTableDataSource(this.alltransactions);
			this.transactions.sort = this.sort;
			this.transactions.paginator = this.paginator;
		}
	}

	back(): void {
		this.location.back()
	}


	loadAllocations(transid: any, type: any) {

		this.transid = transid;
		this.transidtype = type;
		this.modalService.open(this.allocationsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {
			this.loadData();
		});
	}

	openDocument(transaction: any, content: any, display: string) {

		switch (transaction.type) {
			case '11':
				this.globalsService.getCreditNote(transaction.id, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
				})
				break;
			case '10':
				this.globalsService.getInvoice(transaction.id, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
				})

				break;
		}
	}

	identify(index, item) {
		return item.id;
	}

	announceSortChange(event: any) {

	}
}
