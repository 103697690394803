<form [formGroup]="itemForm" (ngSubmit)="updateItem()" *ngIf="itemForm">
	<div class="row">
		<div class="col-md-12">
			<div class="card border">
				<div class="card-body">
					<div class="card-title col-12 text-right">
						<button mat-raised-button color="accent">Next</button>
					</div>
					<div class="row">
						<div class="col-md-4">
							<mat-form-field appearance="standard" class="">
								<mat-label>Brand</mat-label>
								<mat-select required formControlName="brand">
									<mat-option *ngFor="let k of brands" [value]="k.manufacturer_id">
										{{ k.name }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Name</mat-label>
								<input matInput required value="" formControlName="name">
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Web Description</mat-label>
								<input matInput required value="" formControlName="name">
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<mat-form-field appearance="standard">
								<mat-label>Meta Title</mat-label>
								<input matInput value="" formControlName="meta_title">
							</mat-form-field>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="standard">
								<mat-label>Meta Description</mat-label>
								<input matInput value="" formControlName="meta_description">
							</mat-form-field>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="standard">
								<mat-label>Features</mat-label>
								<input matInput value="" formControlName="features">
							</mat-form-field>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="standard">
								<mat-label>Benefits</mat-label>
								<input matInput value="" formControlName="benefits">
							</mat-form-field>
						</div>
					</div>
					<div class="col-md-4">
						<div class="card border">
							<div class="card-header card-header-info card-header-icon">
								<div class="card-icon">
									<i class="material-icons">add_a_photo</i>
								</div>
								<h4 class="card-title bold">Product Image</h4>
								<div class="card-body mb-4">
									<ng-container *ngIf="inputdata.image">
										<img src="{{inputdata.image}}" class="img img-responsive">
									</ng-container>
									<ngx-dropzone (change)="onSelect($event)">
										<ngx-dropzone-label>Drop file, or click to upload!</ngx-dropzone-label>
										<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
											<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
										</ngx-dropzone-preview>
									</ngx-dropzone>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
