import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { ActivatedRoute, Router} from '@angular/router';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { VendorService } from '../../services/vendor.service';
import { Location } from '@angular/common'
import { trigger, state, style, transition, animate } from '@angular/animations';

import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-vendor-items',
  templateUrl: './vendor-items.component.html',
  styleUrls: ['./vendor-items.component.scss']
})
export class VendorItemsComponent implements OnInit {
items;
  dtOptions: DataTables.Settings = {};
  constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder,private vendorService: VendorService, private location: Location) {
	  this.route.params.subscribe(params => {
		const vendorid = params['id'];

		this.vendorService.getVendorItems(vendorid).subscribe(items => this.items = items);
	});

  }

  ngOnInit(): void {
	  this.dtOptions = {
      pagingType: 'full',
      pageLength: 100,
      processing: true
    };



  }

  back(): void {
		this.location.back()
	}


}
