<div class="row m-0 p-0">
<div [ngClass]="pos ? 'col-7' : 'col-12'">

<ng-container *ngIf="paymentForm">
	<h5 class="text-right mt-2 mb-2">ADD PAYMENT</h5>
	<form [formGroup]="paymentForm" (ngSubmit)="addPayment()" class="text-dark">
		<div class="row text-right text-dark m-0 p-0 border ">
			<div class="text-right d-none" [ngClass]="pos ? 'col-md-4' : 'col-md-12'" *ngIf="bankaccounts">
				<mat-form-field appearance="standard" >
					<mat-label>Bank Account</mat-label>
					<mat-select formControlName="bankaccount">
						<mat-option *ngFor="let method of bankaccounts" [value]="method.accountcode">
							{{method.bankaccountname }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class=" text-right" [ngClass]="pos ? 'col-md-4' : 'col-md-12'">
				<mat-form-field appearance="standard">
					<mat-label>Payment Type</mat-label>
					<mat-select formControlName="type" (selectionChange)="updatePaymentType($event, false)" >
						<ng-container *ngFor="let method of paymentmethods">
						<mat-option [value]="method.paymentname">
							{{method.paymentname }}
						</mat-option>
						</ng-container>
					</mat-select>
				</mat-form-field>
			</div>
			<ng-container *ngIf="preselected_payment && loadingcards">
				<div class="text-center" [ngClass]="pos ? 'col-md-4' : 'col-md-12'">
				<h5 class="text-danger">Pre Selected Payment Loading Profile</h5>
				<span>
					<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
						<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
					</svg>
				</span>
				</div>

			</ng-container>
			<ng-container *ngIf="card_required && !pos">
				<div class="text-center"  [ngClass]="pos ? 'col-md-4' : 'col-md-12'">
					<span *ngIf="loadingcards">
						<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>

					<mat-form-field appearance="standard" *ngIf="profile_cards.length">
						<mat-label>Charge Credit Card</mat-label>
						<mat-select formControlName="card_selected" (selectionChange)="changeCard($event.value)">
							<mat-option [value]="false">
								DO NOT CHARGE
							</mat-option>
							<mat-option *ngFor="let method of profile_cards" [value]="method" [ngClass]="preselected_payment.card_id == method.card_id ? 'text-danger': ''">
							{{ method.number }} {{ method.expiry_month }}/{{ method.expiry_year }}
							<span *ngIf="preselected_payment.card_id == method.card_id">Requested</span>
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</ng-container>
			<div class="text-right" [ngClass]="pos ? 'col-md-4' : 'col-md-12'">
				<mat-form-field appearance="standard">
					<mat-label>Reference</mat-label>
					<input type="text" class="text-right mt-0 pt-0" placeholder="Reference" aria-label="Reference" formControlName="reference" matInput appSelectOnFocus/>
				</mat-form-field>
			</div>
			<div class="text-right" [ngClass]="pos ? 'col-md-4' : 'col-md-12'">
				<mat-form-field appearance="standard">
					<mat-label>Amount</mat-label>
					<input type="text" class="text-right mt-0 pt-0" placeholder="Amount" aria-label="Amount" formControlName="amount" matInput appSelectOnFocus/>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-12 text-right">
				<button class="btn btn-sm btn-success" type="submit" [ngClass]="paymentForm.valid ? 'btn-success': 'btn-danger'" > Add Payment</button>
			</div>
		</div>
	</form>
</ng-container>
</div>
<div [ngClass]="pos ? 'col-5' : 'col-12'">
		<h5 class="text-right mt-3 mb-1" *ngIf="payments && payments.length">Payments Added</h5>
		<h5 class="text-right mt-3 mb-1" *ngIf="payments && !payments.length">Add Payments</h5>
			<ul class="list-group list-group-flush text-right ml-0 mr-0 mt-1">
				<ng-container *ngIf="payments">
					<li class="list-group-item ">DUE {{ totals.total | currency }}</li>
					<li class="list-group-item mr-0" *ngFor="let pay of payments">
						<mat-icon-button color="accent" class="mt-4 ml-1" (click)="removePayment(pay.payment_id)">
				 			<mat-icon  color="accent">remove</mat-icon>
						</mat-icon-button>
						<b>{{ pay.payment_type }}
							<span *ngIf="pay.options" class="d-inline">{{lastFour(pay.options.number) }} </span>: {{ pay.amount | currency }} </b>
					</li>
				</ng-container>
			</ul>
		</div>
</div>
