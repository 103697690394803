import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { ReportsService } from '../../services/reports.service';
import { merge, fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';
import { CardLists, Card } from '../../classes/orders';
import { Router } from '@angular/router';
import { Ng2CompleterModule } from 'ng2-completer';
import { Location } from '@angular/common'
import { PrintService } from '../../services/print.service'
import * as FileSaver from 'file-saver';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

declare var $: any;

@Component({
	selector: 'app-cvsls',
	templateUrl: './cvsls.component.html',
	styleUrls: ['./cvsls.component.scss']
})
export class CvslsComponent implements OnInit {
	@Input() customer;
	@ViewChild("orderView") orderStatusViewRef: ElementRef;
	@ViewChild("details") detailsRef: ElementRef;
	@ViewChild('print_tablexlsx') printtablexlsx: ElementRef;
	@ViewChild('print_tablepdf') printtablepdf: ElementRef;
	@ViewChild('brandFilter') brandsRef: MatSelect;
	@ViewChild('branchFilter') branchesRef: MatSelect;
	itemSearchRef = new FormControl('');
	orderSearchRef = new FormControl('');
	conBrRef = new UntypedFormControl('C');
	plRef = new UntypedFormControl('1');
	placedFromRef = new UntypedFormControl('');
	branchRef = new UntypedFormControl('');
	salespersonRef = new UntypedFormControl('');
	filterresults: any = [];
	filteredItems: any = [];
	orderData: CardLists;
	customerData: any = [];
	openOrderData: any = [];
	orders: any = [];
	orderSearch: any = [];
	orderResults: any = false;
	ordersFound: any = [];
	todate = new Date();
	fromdate = new Date();
	reportCriteria = {
		fromdate: '',
		todate: '',
		locs: [],
		conbr: '',
		plbreak: '',
		brands: [],
		brandname: '',
	};

	@Input() name: string;

	color: any = 'blue';
	config: any = [];
	pickinglink: any = '';
	invoicelink: any = '';
	displaydetails: any = false;
	orderdetails: any = false;
	fromdateCtrl = new UntypedFormControl('');
	todateCtrl = new UntypedFormControl('');
	brands = new UntypedFormControl('');
	sending: any = false;
	brandname: any = '';
	branches: any = [];
	salespeople: any = [];
	properties: any = [];

	conbr: any = [{ label: 'Consolidated', value: 'C' }, { label: 'Branch', value: 'B' }];
	plbreak: any = [{ label: 'Yes', value: '1' }, { label: 'No', value: '0' }];
	placedfrom: any = [{ label: 'Included', value: '' }, { label: 'Excluded', value: '1' }, { label: 'Only', value: '2' }];
	dowloading: boolean = false;

	ngOnInit(): void {}
	constructor(private globalSearchService: GlobalSearchService, private printService: PrintService, private globalsService: GlobalsService, public router: Router, private reportsService: ReportsService, private location: Location, private modalService: NgbModal) {

		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});

		this.globalSearchService.mesagesallowed.next(false);

		this.reportsService.getBranches().subscribe((results: any) => {
			this.branches = results;
		});

		this.globalsService.getItemSetup().subscribe((results: any) => {
			this.properties = results
		});

		this.reportsService.getSalesPeople().subscribe((results: any) => {
			this.salespeople = results;
		});

		var today = new Date();
		var priorDate = new Date(today.getFullYear(), today.getMonth(), 1);
		this.fromdate = priorDate;

		this.fromdateCtrl.setValue(this.fromdate);
		this.todateCtrl.setValue(this.todate);

	}

	public getCVSLS() {

		if (this.brands.value == '') {
			this.showNotification('Please Select a Brand', 'danger', 'top', 'center');
		} else {
			this.sending = true;



			this.properties.brands.forEach((brand) => {

				if (brand.manufacturer_id == this.brands.value[0]) {

					this.brandname = brand.name;
				}
			});
			this.reportCriteria = {
				fromdate: this.fromdateCtrl.value.toISOString().split('T')[0],
				todate: this.todateCtrl.value.toISOString().split('T')[0],
				locs: this.branchRef.value,
				conbr: this.conBrRef.value,
				plbreak: this.plRef.value,
				brands: this.brands.value,
				brandname: this.brandname,
			};

			this.reportsService.getCVSLS(this.reportCriteria).subscribe(r => {
				this.sending = false;
				this.orderResults = r;
			})
		}
	}

	viewCustomer(debtorno: any, prodline: any, loccode: any) {
		let drilldown = {
			cust: debtorno,
			pl: prodline,
			loc: loccode,
			plbreak: this.reportCriteria.plbreak,
			fromdate: this.reportCriteria.fromdate,
			todate: this.reportCriteria.todate,
			brands: this.reportCriteria.brands,
			locs: this.reportCriteria.locs,
			conbr: this.reportCriteria.conbr
		};
		this.reportsService.getCVSLSDrill(drilldown).subscribe((order: any) => {
			this.orderdetails = order
			this.modalService.open(this.orderStatusViewRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		});
	}
	openDocument(transaction: any, content: any, display: string) {

		switch (transaction.DocType) {
			case 'Credit':
				this.globalsService.getCreditNote(transaction.id, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
				})
				break;
			case 'Invoice':
				this.globalsService.getInvoice(transaction.id, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
				})

				break;
		}
	}
	exportPdf() {
		this.dowloading = true;
		const today = new Date();
		let encoded: string = this.globalSearchService.base64encode(this.printtablepdf.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: 'Vendor Product Customer Sales ' + this.reportCriteria.fromdate + ' - ' + this.reportCriteria.todate,
			title: 'Vendor Product Customer Sales',
			subtitle: this.getTitle() + ' ' + this.fromdateCtrl.value.toLocaleDateString() + ' - ' + this.todateCtrl.value.toLocaleDateString() + ' created ' + today.toLocaleString()
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.dowloading = false;
		});
	}

	exportXls() {
		this.dowloading = true;
		const today = new Date();
		let encoded: string = this.globalSearchService.base64encode(this.printtablexlsx.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: 'Vendor Product Customer Sales ' + this.reportCriteria.fromdate + ' - ' + this.reportCriteria.todate,
			title: 'Vendor Product Customer Sales',
			subtitle: this.getTitle() + ' ' + this.fromdateCtrl.value.toLocaleDateString() + ' - ' + this.todateCtrl.value.toLocaleDateString() + ' created ' + today.toLocaleString()
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.dowloading = false;
		});
	}

	getTitle() {
		var consolidate_branch = (this.reportCriteria.conbr == 'C') ? 'Consolidated Report' : 'Branch Report';
		var locations = (this.reportCriteria.locs.length == this.branches.length) ? ' For All Locations' : ' ';
		var brands = (this.reportCriteria.brands.length == this.properties.brands.length) ? ' For All Brands' : ' ';

		if (this.reportCriteria.locs.length != this.branches.length) {
			locations = " For Locations:";
			if (this.reportCriteria.locs.length) {
				this.reportCriteria.locs.forEach(l => {
					locations += ' ' + l + ' ';
				})
			} else {
				locations += " All ";
			}
		}

		return consolidate_branch + locations;
	}

	back(): void {
		this.location.back()
	}

	showNotification(message, type, from, align) {
		//  const type = ['','info','success','warning','danger'];
		// const color = Math.floor((Math.random() * 4) + 1);

		$.notify({
			icon: "notifications",
			message: message

		}, {
			type: type,
			timer: 1000,
			placement: {
				from: from,
				align: align
			},
			template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
				'<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
				'<i class="material-icons" data-notify="icon">notifications</i> ' +
				'<span data-notify="title">{1}</span> ' +
				'<span data-notify="message">{2}</span>' +
				'<div class="progress" data-notify="progressbar">' +
				'<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
				'</div>' +
				'</div>'
		});
	}

	brandData(id: any, type) {
		let out;
		let brand;
		brand = (this.properties.brands).filter((i) => {
			if (i.manufacturer_id == id) {
				return i;
			}
		})

		switch (type) {
			case 'code':
				out = brand[0].manufacturer_id
				break;
			case 'name':
				out = brand[0].name;
				break;
		}
		return out;
	}

	selectAllToggle(filter_name: string) {
		let filter;
		switch (filter_name) {
			case 'brand':
				filter = this.brandsRef;
				break;
			case 'branch':
				filter = this.branchesRef;
				break;
			default:
				return;
		}
		const selected = filter.options.find(opt => opt.selected);
		if (selected) {
			filter.options.forEach((item: MatOption) => item.deselect());
		} else {
			filter.options.forEach((item: MatOption) => item.select());
		}
	}
}
