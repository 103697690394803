<div *ngIf="addedit">
	<app-inventory-locations-create [editing]="location_selected" (complete)="reloadData($event)"></app-inventory-locations-create>
</div>
<div class="table table-sm " *ngIf="!addedit">
	<div class="table table-responsive text-center" *ngIf="!locations">
		<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
			<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
		</svg>
	</div>
	<div class="row">
		<div class="col-4">
			<button (click)="selectLocation('')" mat-stroked-button >New Location</button>
		</div>
	</div>
	<mat-table [dataSource]="locations" matSort (matSortChange)="announceSortChange($event)">
		<ng-container matColumnDef="actions">
			<mat-header-cell class="" *matHeaderCellDef></mat-header-cell>
			<mat-cell class="" *matCellDef="let location">

				<button (click)="selectLocation(location)" mat-stroked-button color="accent">Edit</button>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="loccode">
			<mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Code">
				Location Code
			</mat-header-cell>
			<mat-cell *matCellDef="let location"> {{location.loccode}} </mat-cell>
		</ng-container>
		<ng-container matColumnDef="locationname">
			<mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
			<mat-cell *matCellDef="let location"> {{ location.locationname}} </mat-cell>
		</ng-container>
		<ng-container matColumnDef="contact">
			<mat-header-cell class="" *matHeaderCellDef mat-sort-header> Contact</mat-header-cell>
			<mat-cell class="" *matCellDef="let location" [innerHTML]="location.contact"></mat-cell>
		</ng-container>
		<ng-container matColumnDef="deladd1">
			<mat-header-cell class="" *matHeaderCellDef mat-sort-header> Address</mat-header-cell>
			<mat-cell class="" *matCellDef="let location" [innerHTML]="location.deladd1"></mat-cell>
		</ng-container>
		<ng-container matColumnDef="tel">
			<mat-header-cell class="" *matHeaderCellDef mat-sort-header> Phone#</mat-header-cell>
			<mat-cell class="" *matCellDef="let location" [innerHTML]="location.tel"></mat-cell>
		</ng-container>
		<mat-header-row *matHeaderRowDef="headercolumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: headercolumns;" (click)="selectLocation(row)"></mat-row>
	</mat-table>
	<mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons aria-label="select page">
	</mat-paginator>
</div>
