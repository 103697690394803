import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { OmsService } from '../../services/oms.service';
import { DispatchService } from '../../services/dispatch.service';
import { MatAccordion } from '@angular/material/expansion';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

declare const google: any;


@Component({
	selector: 'app-dispatches-load-sheet',
	templateUrl: './dispatches-load-sheet.component.html',
	styleUrls: ['./dispatches-load-sheet.component.scss']
})
export class DispatchesLoadSheetComponent implements OnInit {
	@ViewChild(MatAccordion) accordion: MatAccordion;
	@ViewChild("orderdetails") orderdetailsref: ElementRef;

	devices = [
		{ name: '--' },
		{ name: 'LOADING1' },
		{ name: 'LOADING2' },
	]

	scannerForm = new UntypedFormControl('--');
	transfertruck = new UntypedFormControl('')
	truck = new UntypedFormControl('');
	selectedtruck: any = [];
	alltrucks: any = false;
	itemScanCtrl = new UntypedFormControl('');

	CHAT_ROOM = "OrderBoard";
	user: any = [];
	color: string = 'blue';

	config: any = [];
	token: string = '';
	dispatches: any = [];
	selectedorder: any = false;
	trucks: any = [];
	allpoints: any = [];
	map: any = false;
	zoomed: boolean = false;
	zoom: number = 11;
	pathlines: any = [];
	markers: any = false;
	zoomed_details_truck: any = false;
	seleccted_alt: any = false;

	disabled: boolean = false;
	active_dispatches: any = [];
	active_allpoints: any = [];
	loadingdata: any = false;
	loadingrun: any = false;
	total_capacity: number = 0;
	total_order_volume: number = 0;
	total_items: number = 0;
	delsheetlink: string = '';
	loadshhetrunsheet: number = 0;
	activetrucks: any = [];

	truckisactive: boolean = false;
	isdriver: boolean = false;

	constructor(private modalService: NgbModal, private dispatchService: DispatchService, public omsService: OmsService, public location: Location, public ordersService: OrdersService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, public formBuilder: UntypedFormBuilder, public inventoryService: InventoryService) {

		this.globalSearchService.user.subscribe((result) => {
			this.user = result;

		});

		this.token = this.globalSearchService.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
		this.omsService.setupSocketConnection(this.token);
		this.color = this.globalSearchService.getColor();

		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		});
		this.delsheetlink = this.config.apiServer.baseUrl + this.config.apiServer.runSheet

	}

	ngOnInit(): void {

		this.getTruckData();

		this.dispatchService.getActiveDispatches().subscribe((dres: any) => {
			this.activetrucks = dres;
		});

		this.omsService.subscribeToDriverChange((err, data) => {
			this.getTruckData();
		})

		this.omsService.subscribeToOrderPicks((err, data) => {

			switch (data.type) {
				case 'pick':
					//this.loadData();
				break;
			}
		})



		this.omsService.subscribeToCards((err, data) => {

			switch (data.type) {
				case 'pick':
					this.loadData();
					break;
				case 'buildingroute':
					this.loadData();
					break;
				case 'dispatch':

					if(this.selectedtruck.id == data.data.truck) {
						this.globalSearchService.showNotification("Dispatch Updated", 'danger', 'bottom', 'left');
					}
					this.loadData();
					break;
			}
		})

	}

	updateDeviceDisplay() {
		let data = [];
		data['id'] = this.truck['value']['id'];
		data['name'] = this.truck['value']['name'];
		this.loadRun(data)
	}

	getTruckData() {
		this.dispatchService.getTrucks().subscribe((result: any) => {
			if (result.length == 1) {
				let truck = result.filter(t => {
					return t.id == result[0].id
				})[0];
				this.isdriver = true;
				this.selectedtruck = truck;
				this.truck.setValue(truck);
				this.disabled = true;
				this.truck.disable();
				this.total_capacity = truck.capacity
				this.loadRun(truck);
			} else {
				this.isdriver = false;
				this.total_capacity = result[0].capacity
				this.loadRun(this.truck.value)
			}

			this.trucks = result;
			this.alltrucks = result
		});
	}

	truckFilter(event: any) {
		if (event != '') {
			this.trucks = this.globalSearchService.filterItem(this.alltrucks, event, 'truck_name');
		} else {
			this.trucks = this.alltrucks;
		}
	}

	setQty(qty: any, item: any, da: any) {
		item.qtyloaded = qty

		let request = {
			dispatch: da,
			item: item,
			qty: item.qtyloaded,
		}

		this.dispatchService.loadItemToTruck(request).subscribe(results => {
			if (results.success) {
				this.loadData();
			}
		})

	}

	addPick(da: any, item: any) {
		if (item.qtyloaded < item.quantity) {
			item.qtyloaded += 1

			let request = {
				dispatch: da,
				item: item,
				qty: item.qtyloaded,
			}

			this.dispatchService.loadItemToTruck(request).subscribe(results => {
				if (results.success) {
					this.audioPlay();
					this.loadData();
				}
			})

		} else {
			this.audioPlayFree();
		}
	}

	checkLoad() {

		if (this.selectedtruck) {
			if (this.activetrucks.dispatches) {
				let activeTruck = this.activetrucks.dispatches.filter((d: any) => {
					return d.truck == this.selectedtruck.id
				})[0];
				if (activeTruck) {
					if (activeTruck.dispatched.length > 0) {
						//truck already en route.
						this.truckisactive = true;
						return true;
					}
				}
			}
		}

		var all_loaded = true;
		if (this.dispatches) {
			this.dispatches.forEach((r: any) => {
				if (!r.loaded) {
					all_loaded = false;
				}
			});
		}

		return all_loaded;
	}

	removePick(da: any, item: any) {
		if (item.qtyloaded > 0) {
			item.qtyloaded -= 1

			let request = {
				dispatch: da,
				item: item,
				qty: item.qtyloaded,
			}

			this.dispatchService.loadItemToTruck(request).subscribe(results => {
				if (results.success) {
					this.loadData();
				}
			})

		} else {
			//?
		}
	}

	removeItemToTruck(dispatch: any, item: any) {

		item.qtyloaded = 0;

		let request = {
			dispatch: dispatch,
			item: item,
			qty: item.qtyloaded,
		}

		this.dispatchService.removeItemToTruck(request).subscribe(results => {
			if (results.success) {
				dispatch.loaded = false;
				this.loadData();
			}
		})
	}

	itemToTruck(dispatch: any, item: any, qty: any) {

		item.qtyloaded = item.quantity;

		let request = {
			dispatch: dispatch,
			item: item,
			qty: item.qtyloaded,
		}

		this.dispatchService.loadItemToTruck(request).subscribe(results => {
			if (results.success) {
				dispatch.loaded = true;
				this.loadData();
			}
		})
	}

	scanAllItems() {
		let allitems = this.dispatches.every(r => r.orderdetails)
	}

	scanItem() {
		let allitems = [];
		let dispatches = [];

		if (this.itemScanCtrl.value != '') {
			this.allpoints.forEach((r: any) => {
				let v = this.globalSearchService.filterItem(r.orderdetails, this.itemScanCtrl.value, 'orderno,stkcode,itemdesc');
				if (v.length) {
					v.forEach((a: any) => {
						allitems.push(a)
						if (v.length == 1) {

							this.addPick(r, a)
						}
					});
					dispatches.push(r);
				}
			})
		} else {
			this.dispatches = this.allpoints;
		}
		//change to dispatch items only?
		if (dispatches.length) {
			this.dispatches = dispatches;
			this.itemScanCtrl.setValue('')

		} else {
			this.itemScanCtrl.setValue('')
			this.audioPlayBad();
			this.vibrate();
		}
	}

	cancel(dispatch: any) {
		this.dispatchService.cancelDispatch(dispatch).subscribe(results => {
			this.loadData();
			this.modalService.dismissAll();
		})
	}

	loadAll(truck: any) {

		this.dispatchService.loadTruck(truck).subscribe(results => {
			this.loadData();
			this.modalService.dismissAll();
		})
	}

	deliverRun(truck: any) {
		let proceed = confirm('Mark All As Delivered?');
		if (proceed) {
			this.dispatchService.deliverRun(truck.id).subscribe(results => {
				this.loadData();
				this.modalService.dismissAll();
			})
		}
	}

	clearRun(truck: any) {
		let proceed = confirm('Clear This Run?');

		if (proceed) {
			this.dispatchService.clearRun(truck.id).subscribe(results => {
				this.loadData();
				this.modalService.dismissAll();
			})
		}
	}

	dropAtHome(dispatch: any) {
		this.dispatchService.returnDispatch(dispatch).subscribe(results => {
			this.loadData();
			this.modalService.dismissAll();
		})
	}

	delivered(dispatch: any) {
		this.dispatchService.deliverDispatch(dispatch).subscribe(results => {
			this.loadData();
			this.modalService.dismissAll();
		})
	}

	altLocation() {
		if (this.transfertruck.value) {
			this.dispatchService.transferDispatch(this.selectedorder.did, this.transfertruck.value).subscribe(results => {
				this.loadData();
				this.modalService.dismissAll();
			})
		}
	}

	exportPdf() {
		this.globalSearchService.exportPDF('printableRunSheet')
	}

	exportLoadSheetXls() {
		this.globalSearchService.exportJsonToExcel(this.dispatches, 'dispatches.xlsx');
	}

	loadData() {
		this.getTruckData();
		if (this.loadingdata) {
			this.loadingdata.unsubscribe();
		}

		this.loadingdata = this.dispatchService.getActivePicked(this.selectedtruck.id).subscribe((results: any) => {
			this.dispatches = results;
			this.allpoints = results;
			if (results) {
				this.total_order_volume = results.reduce(function (accumulator, i) {
					return accumulator + i.ordercapacity;
				}, 0);

				this.total_items = results.reduce(function (accumulator, i) {
					return accumulator + i.item_count;
				}, 0);
			}
		});

		if (this.loadingrun) {
			this.loadingrun.unsubscribe();
		}

		this.loadingrun = this.dispatchService.getActiveRun(this.selectedtruck.id).subscribe((results: any) => {
			this.active_dispatches = results.dispatched;
			this.active_allpoints = results.allpoints;
		});
		this.emitEventToChild();
	}

	loadRun(event: any) {
		this.selectedtruck = event;
		var loadvar = '';


		var truckname = 'All';
		if (event.id) {
			loadvar = event.id;
			truckname = event.name
		} else {
			loadvar = event;
		}

		if (this.loadingdata) {
			this.loadingdata.unsubscribe();
		}

		var device = '';
		if (this.scannerForm.value != '--') {
			device = this.scannerForm.value;
		}


		this.loadingdata = this.dispatchService.getActivePicked(loadvar, device).subscribe((results: any) => {
			this.dispatches = results;
			this.allpoints = results;

			if (results) {
				this.total_order_volume = results.reduce(function (accumulator, i) {
					return accumulator + i.ordercapacity;
				}, 0);


				this.total_items = results.reduce(function (accumulator, i) {
					return accumulator + i.item_count;
				}, 0);
			}
		});

		if (this.loadingrun) {
			this.loadingrun.unsubscribe();
		}

		this.loadingrun = this.dispatchService.getActiveRun(loadvar).subscribe((results: any) => {
			this.active_dispatches = results.dispatched;
			this.active_allpoints = results.allpoints;
		});

	}

	back(): void {
		this.location.back()
	}

	viewOrder(order: any) {
		this.selectedorder = order
		this.modalService.open(this.orderdetailsref, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result) => { }, (reason) => {
			this.selectedorder = false;
		});
	}

	submitMessage() {

		//data has been re-arranged already by cdk drop
		//remove points from dispatches
		let thesedispatches = Object.create(this.dispatches);
		thesedispatches.forEach((d, index) => {
			thesedispatches[index] = d;
			thesedispatches[index].dispatched = d.dispatched.filter(a => { return a.ispoint == '0' });;
		})

		const data = { data: thesedispatches, user: this.user };

		if (data) {
			this.omsService.sendDispatchPositionUpdate({ data, roomName: this.CHAT_ROOM }, cb => { });
		}
	}

	viewLoadSheet() {
		//throw in a small dialog?
	}

	identify(index, item) {
		return item.orderdetails;
	}

	audioPlay() {
		var audio = new Audio("/assets/pristine-609.mp3");
		let play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	audioPlayBad() {
		var audio = new Audio("/assets/glitch.mp3");
		let play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	audioPlayFree() {
		var audio = new Audio("/assets/money.mp3");
		let play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	vibrate() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(2000);
		} else {
			// Not supported
		}
	}

	vibrateLong() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(5000);
		} else {
			// Not supported
		}
	}

	displayFn(truck: any) {
		//this.truck.setValue(this.selectedtruck.name);
		if (truck) {
			return truck.name
		}
	}

	eventsSubject: Subject<void> = new Subject<void>();

	emitEventToChild() {
		this.eventsSubject.next();
	}

}
