import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { CustomerService } from '../../services/customer.service';
@Component({
	selector: 'app-over-due-customers',
	templateUrl: './over-due-customers.component.html',
	styleUrls: ['./over-due-customers.component.scss'],
	animations: [
	  trigger('flipState', [
		  state('active', style({
			  transform: 'rotate3d(0, 1, 0, 179deg)',
		  })),
		  state('inactive', style({
			  transform: 'rotateY(0)'
		  })),
		  state('orderlookup', style({
			  transform: 'rotateY(170deg)'
		  })),
		  transition('active => inactive', animate('500ms ease-out')),
		  transition('inactive => active', animate('500ms ease-in')),
		  transition('inactive => orderlookup', animate('500ms ease-out')),
		  transition('orderlookup => inactive', animate('500ms ease-in')),
	  ]),
	 trigger('openClose', [
		  state(':enter', style({ height: '*' })),
		  state(':leave', style({ height: '0px' })),
		  transition('false <=> true', animate(500))
	]),
	trigger('filterAnimation', [
	  transition(':enter, * => 0, * => -1', []),
	  transition(':increment', [
		query(':enter', [
		  style({ opacity: 0, width: 0 }),
		  stagger(50, [
			animate('300ms ease-out', style({ opacity: 1, width: '*' })),
		  ]),
		], { optional: true })
	  ]),
	  transition(':decrement', [
		query(':leave', [
		  stagger(50, [
			animate('300ms ease-out', style({ opacity: 0, width: 0 })),
		  ]),
		])
	  ]),
	]),
	trigger("grow", [ // Note the trigger name
	  transition(":enter", [
		// :enter is alias to 'void => *'
		style({ height: "0",width: "0" }),
		animate(200, style({ height: "*" , width: "*"})),
		animate('200ms', style({ opacity: 1 })),
	  ]),
	  transition(":leave", [
		// :leave is alias to '* => void'
		animate(100, style({ height: 0, width: 0 })),
		animate('100ms', style({ opacity: 0 }))
	  ])
	]),
	]
})
export class OverDueCustomersComponent implements OnInit {
	@Input() flip: string = 'inactive';
	@Output() viewData = new EventEmitter < any > ();

	trialbalance: any = false;
	overdueone: any = false;
	overduetwo: any = false;
	overduethree: any = false;
	overduezero: any = false;
	original_trialbalance: any = false;


	total_overdue_details = {
		zero: 0,
		one: 0,
		two: 0,
		three: 0,
		total: 0,
	}

	viewing: any = false;
	title: any = '';

	constructor(private customerService: CustomerService) {}

	ngOnInit(): void {

		this.customerService.getTrialBalance().subscribe(async (results: any) => {
			this.trialbalance = results;
			this.original_trialbalance = results;

			//to do this is overkill clean it up
			this.overdueone = this.trialbalance.filter((customer: any) => {
				let add_customer_bucket1 = parseFloat(customer.overdue) > 0.0000 ? 1 : 0;
				return parseFloat(customer.overdue) > 0.0000 ? true : false;
			});

			this.overduetwo = this.trialbalance.filter((customer: any) => {
				return parseFloat(customer.overdue1) > 0.0000 ? true : false;
			});

			this.overduethree = this.trialbalance.filter((customer: any) => {
				return parseFloat(customer.overdue2) > 0.0000 ? true : false;
			});

			this.overduezero =  this.trialbalance.filter((customer: any) => {
				return parseFloat(customer.due) > 0.0000 ? true : false;
			});

			this.total_overdue_details.total = results.reduce(function(accumulator, customer) {
				var addon = 0;
				if(parseFloat(customer.overdue) > 0.0000) {
					addon = 1;
				} else {
				 	if(parseFloat(customer.overdue1) > 0.0000 ) {
						addon = 1;
				 	}
				 }
				return accumulator + addon;
			}, 0);

			this.total_overdue_details.zero = results.reduce(function(accumulator, customer) {
				let add_customer_bucket1 = parseFloat(customer.due) > 0.0000 ? 1 : 0;
				return accumulator + add_customer_bucket1;
			}, 0);

			this.total_overdue_details.one = results.reduce(function(accumulator, customer) {
				var addon = 0;
				if(parseFloat(customer.overdue) > 0.0000 && !(parseFloat(customer.overdue1) > 0.0000)) {
					addon = 1;
				}
				return accumulator + addon;
			}, 0);

			this.total_overdue_details.two = results.reduce(function(accumulator, customer) {
				let add_customer_bucket2 = 0;
				add_customer_bucket2 = parseFloat(customer.overdue1) > 0.0000 ? 1 : 0;
				return accumulator + add_customer_bucket2;
			}, 0);

			this.total_overdue_details.three = results.reduce(function(accumulator, customer) {
				let add_customer_bucket3 = 0;
				add_customer_bucket3 = parseFloat(customer.overdue2) > 0.0000 ? 1 : 0;
				return accumulator + add_customer_bucket3;
			}, 0);
		});

	}

	toggleFlip(data: any, title: string) {

		if(!data) {
			this.flip = 'inactive';
		} else {
			this.flip = (this.flip == 'active') ? 'inactive' : 'active';
		}

		if (this.flip == 'active') {
			this.viewing = data;
			this.title = title
			let dashboard_data = { data: data, title: 'agedc' }
			this.viewData.emit(dashboard_data);
		} else {
			this.viewData.emit(false);
		}
	}

}
