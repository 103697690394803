import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { OmsService } from '../../services/oms.service';
import { Order, PickItem, PickItems } from '../../classes/orders';
import { ItemList } from '../../classes/items';
import { OrderTypes, OrderHeader } from '../../classes/orders';
import { Location } from '@angular/common'
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss'],
})
export class OrderViewComponent implements OnInit {

	//@ViewChild("stockidsearchtwo") stockidSearchField: ElementRef;

	public itemScanForm: UntypedFormGroup;
	public snoozeForm: UntypedFormGroup;

	SENDER;
	messages = [];
	message;
	CHAT_ROOM = "OrderBoard";

	initaldate = new Date();

	order: Order;
	orderset;
	orderno;
	orderdetails: Order;
	orderheader;
	ordertitle;
	filteredResults = []
	filteredItems;
	routeback;

	completed_items: PickItem[] = [];
	mustcomplete: PickItem[] = [];
	allorders: OrderHeader;

	picking: OrderTypes;

	linecomplete = {};
	itemcount = {};
	errors = [];
	error;
	address;
	linedetails = {};
	forcesearch: boolean = true;
	numberofdays: number = 1

	snoozeorder;
	snoozetime;
	issnoozed: boolean = false
	user: any = [];

	constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder,private ordersService: OrdersService, public router: Router, private location: Location, private inventoryService: InventoryService, private omsService: OmsService, private globalSearchService: GlobalSearchService ) { }

	ngOnInit(): void {

		this.snoozeForm = this.formBuilder.group({
			message: ['', Validators.required],
			initials: ['', Validators.required],
			//numberofdays: [1],
			//datepicked: [''],
			// ['', Validators.required],
		});

		this.omsService.subscribeToPicks((err, data) => {
			if(data) {
				this.globalSearchService.pickingOrderData.next(data);
			}
		});

		this.globalSearchService.pickingOrderData.subscribe((results: any) => {
			this.picking = results;
		});

		this.globalSearchService.user.subscribe( r => this.user = r);


		this.itemScanForm = this.formBuilder.group({
			orderno: [''],
			completed: [],
			stockidsearch: ['', Validators.required],
		});


		this.orderset = '';
		this.address = '';

		this.route.params.subscribe(params => {
			const orderno = params['id'];

			this.orderset = orderno;
			this.routeback = '';

			if(params['type']) {
				this.routeback = params['type'];
			}

			if(orderno) {
				this.ordersService.getOrder(orderno).subscribe((order: any) => {
					this.order = order;
					this.ordertitle = '';
					this.orderdetails = order;
					this.forcesearch = false;
					this.address = order.address;

					this.issnoozed = order.snoozeactive

					this.orderdetails.details.forEach((keys : any, vals :any) => {
						if(keys.MUSTPICK) {
							this.mustcomplete.push({ item: keys.orderlineno, count: parseFloat(keys.ordered), stockid: keys.stockid, orderno: keys.orderno,lineno: keys.orderlineno })
						}

						this.linedetails[keys.orderlineno] = keys;
						this.itemcount[keys.orderlineno] = parseFloat(keys.PICKED);

						var pickitem = {
							item: keys.orderlineno,
							count: parseFloat(keys.PICKED),
							stockid: keys.stockid,
							orderno: keys.orderno,
							lineno: keys.orderlineno,
						};

						this.completed_items.push(pickitem);
					});

					this.focusInput();
				});
			}


			if(!orderno) {
				this.forcesearch = true;
				this.ordersService.getOrders().subscribe((orders: any) => {
					this.allorders = orders
				});
			}
		});
	}




	scanItem() {

		let item = this.itemScanForm.value.stockidsearch;
		//let item = '';
		this.inventoryService.lookup({stockid: item, single: true}).subscribe((item: any) => {

			//is on the list to be completed
			this.error = '';
			if(item.details) {

				let found = this.mustcomplete.find(arg => arg.stockid === item.details.stockid);

				if(found) {
					//add qty.
					let thisitem = found.item
					//item.details.stockid;
					var pickitem = {
						item: thisitem,
						count: 1,
						stockid: this.linedetails[thisitem].stockid,
						orderno: this.linedetails[thisitem].orderno,
						lineno: this.linedetails[thisitem].orderlineno,
					};

					this.error = 'Item Added.';

					//is already in completed + qty
					if(this.completed_items.find(arg => arg.item === thisitem)) {
						let current = this.completed_items.find((arg: any) => arg.item === thisitem);

						this.completed_items = this.completed_items.filter((arg: any) => arg.item != thisitem);
						this.error = 'Item updated.';

						pickitem.count = current.count + 1;
						this.itemcount[thisitem] = pickitem.count;
						this.completed_items.push(pickitem);
						//
					} else {

						this.itemcount[thisitem] = pickitem.count;
						this.completed_items.push(pickitem);
					}

					this.updatePick();

				} else {
					this.error = 'Item Is not on Order.';
				}
			} else {

				this.error = item+ ' No Found';
			}

			this.itemScanForm = this.formBuilder.group({
				orderno: [''],
				completed: [],
				stockidsearch: ['', Validators.required],
			});

			//this.focusInput();
		});

	}

	toggle(event, item, qty){
		if(event.checked) {
			this.markComplete(item, qty);
		} else {
			if(this.completed_items.find((arg: any) => arg.item === item)) {
				this.completed_items = this.completed_items.filter((arg: any) => arg.item != item);
				this.itemcount[item] = 0;
				this.linecomplete[item] = false;
			}
		}

		this.updatePick();
	}

	markComplete(item: string, qty: number) {

		var pickitem = {
			item: item,
			count: +qty, //as number
			stockid: this.linedetails[item].stockid,
		};

		if(this.completed_items.find((arg: any) => arg.item === item)) {
			this.itemcount[item] = 0;
			this.linecomplete[item] = false;
			this.completed_items = this.completed_items.filter((arg: any) => arg.item != item);

		} else {
			this.itemcount[item] = qty;
			this.linecomplete[item] = true;
			this.completed_items.push(pickitem);
		}

	}

	back(): void {
		switch(this.routeback) {
			case 'pick':
				this.router.navigate(['orders/pick/']);
			break;
			default:
				this.router.navigate(['orders/dashboard/'+this.routeback]);
			break;
		}
	}

	onSubmit() {

	}

	getCount(item) {
		let count = 0;
		if(this.itemcount[item]) {
			count = this.itemcount[item];
		}
		return count;
	}

	isChecked(item){
		if(this.linecomplete[item]) {
			return true;
		}
		return false;
	}

	finished() {
		var completed = true;
		if(!this.completed_items) {
			completed = false;
		}
		this.mustcomplete.forEach((keys: any) => {
			let lookup = keys.item;
			let counted = keys.count

			if(!this.completed_items.find(arg => arg.item === lookup)) {
				this.error = 'Please Finish Picking.';
				completed = false;
			} else {
				let thiscount = this.completed_items.find(arg => arg.item === lookup);

				if(thiscount.count < counted) {
					this.error = 'Please Finish the Pick.';
					completed = false;
					this.linecomplete[keys.item] = false;
				} else {
					this.linecomplete[keys.item] = true;
					this.error = 'Pick Complete';
				}

				this.itemcount[keys.item] = thiscount.count;
			}
		});
		return completed;
	}

	addPick(item) {

		let found = this.mustcomplete.find(arg => arg.item === item);

		if(found) {
			//add qty.
			let thisitem = found.item
			//item.details.stockid;
			var pickitem = {
				item: thisitem,
				count: 1,
				stockid: found.stockid
			};

			this.error = 'Item Added.';

			//is already in completed + qty
			let must = this.mustcomplete.find((arg: any) => arg.item === thisitem);
			if(this.completed_items.find(arg => arg.item === thisitem)) {
				let current = this.completed_items.find((arg: any) => arg.item === thisitem);

				this.completed_items = this.completed_items.filter((arg: any) => arg.item != thisitem);
				this.error = 'Item update.';
				pickitem.count = current.count + 1;

				pickitem.count = current.count + 1;
				if(pickitem.count > must.count) {
					pickitem.count = must.count;
				}

				this.itemcount[thisitem] = pickitem.count;
				this.completed_items.push(pickitem);
				//
			} else {

				this.itemcount[thisitem] = pickitem.count;
				this.completed_items.push(pickitem);
			}

			this.updatePick();
		}

	}
	removePick(item) {

		let found = this.mustcomplete.find(arg => arg.item === item);

		if(found) {

			//add qty.
			let thisitem = found.item
			//item.details.stockid;
			var pickitem = {
				item: thisitem,
				count: 1,
				stockid: found.stockid,
				orderno: found.orderno,
				lineno: found.lineno,
			};

			this.error = 'Item Added.';

			//is already in completed + qty
			if(this.completed_items.find(arg => arg.item === thisitem)) {
				let current = this.completed_items.find((arg: any) => arg.item === thisitem);
				if(current.count > 0) {
					this.completed_items = this.completed_items.filter((arg: any) => arg.item != thisitem);
					this.error = 'Item update.';
					pickitem.count = current.count - 1;
					this.itemcount[thisitem] = pickitem.count;
					this.completed_items.push(pickitem);
					this.updatePick();
				}
			}
		}

	}

	deepEqual(x, y) {
		const ok = Object.keys, tx = typeof x, ty = typeof y;
		return x && y && tx === 'object' && tx === ty ? (
		ok(x).length === ok(y).length &&
		ok(x).every(key => this.deepEqual(x[key], y[key]))
		) : (x === y);
	}

	focusInput() {
		//if(this.stockidSearchField) {
		//	this.stockidSearchField.nativeElement.focus();
		//}
	}

	updatePick() {

		let pickupdate = {
			orderno: this.orderset,
			required: this.mustcomplete,
			items: this.completed_items,
			picked: this.itemcount,
			complete: this.linecomplete,
			details: this.linedetails,
		}





		//this.ordersService.setPicking(pickupdate).subscribe((pick: any) => {});
		this.sendOrderUpdate(pickupdate)

	}

	loadOrder(orderno) {

	}

	orderSearch() {

		let search = this.itemScanForm.value.orderno;
		//let search = '';
		let allresults = [];

		if(this.allorders) {
			var results = this.filterItem(this.allorders, search, 'CUST_NAME,CUST_NUMBER,ADDRESS_1,orderno');
			if(results) {
				results.forEach(function (value: OrderHeader) {
					allresults.push({ content: 'Orders:', details: value });
				});
			}
		}

		this.filteredResults = allresults;
	}

	assignCopy(input){
		this.filteredItems = Object.assign([], input);
	}

	filterItem(input, term , keys ){
		if(!term){
			this.assignCopy(input);
		}
		var result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) &&	new RegExp(term, 'gi').test(item[key])));
		return result.splice(0,5)
	}

	sendOrderUpdate(orderupdate) {
		const data = orderupdate
		if (data) {
			//send to socket
			this.omsService.sendOrderUpdate({data, roomName: this.CHAT_ROOM}, cb => {});
		}
	}

	setSnoozeOrder(orderno) {
		this.snoozeorder = orderno;
	}

	dateChanged(event) {
		var now = new Date();
			now.setDate(now.getDate());
		var until = new Date(event.value);
			until.setDate(until.getDate());
		this.numberofdays = (until.getDate() - now.getDate())
		this.initaldate = new Date(event.value)
	}


	public setSnooze(event: any){
		this.numberofdays = parseInt(event.target.value);
		this.dateNumberChanged();
	}

	dateNumberChanged() {

		var subject = new Date();
			subject.setDate(subject.getDate());

		var until = new Date();
			until.setDate(until.getDate() + this.numberofdays);

		this.initaldate = new Date(until);
	}

	snooze(orderno) {

		if(this.snoozeForm.status == "INVALID" && !this.issnoozed) {
			return false;
		}

		let data = {orderno: orderno, days: this.numberofdays, inititals: this.snoozeForm.controls['initials'].value, reason: this.snoozeForm.controls['message'].value, user: this.user};

		if(this.issnoozed) {
			this.omsService.sendSnoozeRemove({data, roomName: this.CHAT_ROOM}, cb => {});
			this.issnoozed = false;
		} else {
			this.omsService.sendSnoozeUpdate({data, roomName: this.CHAT_ROOM}, cb => {});
			this.issnoozed = true;
			//$('#popsnooze').click()

		}

	}


}
