import { Component, OnInit, Input, AfterViewInit,ViewChild,ElementRef } from '@angular/core';
import { InventoryService } from '../../services/inventory.service';
import { PurchasingService } from '../../services/purchasing.service';
import { OrdersService } from '../../services/orders.service';
import { OmsService } from '../../services/oms.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { FormBuilder, FormGroup, Validators,UntypedFormControl} from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-warehouse-sending',
  templateUrl: './warehouse-sending.component.html',
  styleUrls: ['./warehouse-sending.component.scss']
})
export class WarehouseSendingComponent implements OnInit {

  @Input() itemdetails: any = false;
	@Input() vendor_selected: any = false;
	@Input() vendorpos: any = false;

	@ViewChild("detailsModal") detailsModalEle: ElementRef;

	CHAT_ROOM = "OrderBoard";
	historyloading: boolean = false;
	showorder: boolean = false;
	orderdetails: any = [];
	history: any = false;
	color: any = 'blue';
	item: any = false;
	user: any = [];
	location :any ='';
	location_data: any = [];
	recitems: any = [];
	rec_complete = false;
	receiving_quantity: number = 0;
	sending_quantity: number = 0;
	expected_quantity: number = 0;
	receiving_bin: string = 'RECDOC';
	vendor_pos: any = [];

	qtyRec: UntypedFormControl = new UntypedFormControl(0, Validators.required);
	qtySend: UntypedFormControl = new UntypedFormControl(0, Validators.required);
	qtyBin: UntypedFormControl = new UntypedFormControl('RECDOC', Validators.required);

	constructor(private route: ActivatedRoute,public router: Router,private modalService: NgbModal,private omsService: OmsService,private globalSearchService: GlobalSearchService, private inventoryService: InventoryService, public ordersService: OrdersService, public purchasingService: PurchasingService) {
		this.color = this.globalSearchService.getColor();

		this.globalSearchService.user.subscribe( (u:any) => {
			this.user = u;
			this.location = u.user.defaultlocation.loccode
		});

	}

	ngOnInit(): void {

		this.location_data = this.itemdetails.itemstock.filter( l => {
			return l.loccode == this.location;
		})[0];

		this.route.params.subscribe(params => {
			//re -get item - not behainv as expected
			if (params['item']) {
				this.loadData(params['item']);
				/*
				this.inventoryService.loadItem(params['item']).subscribe((result: any) => {
					this.item = result

					this.itemdetails = result
					let search = {
						stockid: params['item'],
						vendor: this.vendor_selected
					}

					this.purchasingService.getPurchasesForItemByVendor(search).subscribe( (results:any) => {
						this.vendorpos = results;

						if(results.total_pending) {
							this.receiving_quantity = results.total_pending;
							this.qtyRec.setValue(results.total_pending);
						}
					})

					this.purchasingService.getOpenByItem(params['item']).subscribe((recitems:any) => {
						this.recitems= recitems;
					});

					this.inventoryService.getPurchaseHistory(params['item']).subscribe(async (result: any) => {
						this.historyloading = false;
						this.history = result
					})

					this.setItemData()
				});
				*/
			}
		});
	}

	loadData(stockid: any) {

		this.inventoryService.loadItem(stockid).subscribe((result: any) => {

			this.item = result
			this.itemdetails = result
			let search = {
				stockid: stockid,
				vendor: this.vendor_selected
			}

			this.purchasingService.getPurchasesForItemByVendor(search).subscribe( (results:any) => {
				this.vendorpos = results;

				if(results.total_pending) {
					this.receiving_quantity = results.total_pending;
					this.qtyRec.setValue(results.total_pending);
				}
			})

			this.purchasingService.getRgasForItemByVendor(search).subscribe( (results:any) => {
				this.vendorpos = results;

				if(results.total_pending) {
					// this.sending_quantity = results.total_pending;
					this.expected_quantity = results.total_pending;
					this.sending_quantity = 0;
					this.qtySend.setValue(results.total_pending);
				} else{
					this.expected_quantity = 0;
					this.qtySend.setValue(null);
				}
			})

			this.purchasingService.getOpenByItem(stockid).subscribe((recitems:any) => {
				this.recitems= recitems;
			});

			this.inventoryService.getPurchaseHistory(stockid).subscribe(async (result: any) => {
				this.historyloading = false;
				this.history = result
			})


			this.setItemData()
		});
	}




	setItemData() {
		this.item = this.itemdetails.item
		this.historyloading = true;
		this.receiving_quantity = this.itemdetails.openpos;
		this.qtyRec.setValue(this.receiving_quantity);
		this.vendor_pos = this.vendorpos
	}

	setCheckValue(event: any) {
		if(event.checked) {
			this.receiving_quantity = this.itemdetails.openpos;
			this.rec_complete= true;
		} else {
			this.receiving_quantity = 0;
			this.rec_complete= false;
		}

		this.qtyRec.setValue(this.receiving_quantity);

	}

	updateBin(value: any) {
		this.receiving_bin = value
	}

	updateQty(value: any) {
		this.receiving_quantity = parseFloat(value);
		if(this.receiving_quantity >= parseFloat(this.itemdetails.openpos)) {
			this.rec_complete= true;
		} else {
			this.rec_complete= false;
		}
	}
	removePick(event:any) {
		event.stopPropagation();
		event.preventDefault();
		let movement = -1;

		let pre = this.sending_quantity + movement;
		if(pre >= 0) {
		} else {
			movement = 0
		}

		let recval = this.sending_quantity + movement;
		if(recval <= 0) {
			recval = 0;
		} else {
			this.sending_quantity = recval;
			this.qtyRec.setValue(this.sending_quantity);

			if(this.sending_quantity >= parseFloat(this.itemdetails.openpos)) {
				this.rec_complete= true;
			} else {
				this.rec_complete= false;
			}
		}
	}

	addPick(event:any) {
		event.stopPropagation();
		event.preventDefault();
		let movement = 1;
		this.sending_quantity = this.sending_quantity + movement;
		if(this.sending_quantity > this.expected_quantity){

			if(!window.confirm("You are about to return more tires than expected.")) {
				this.sending_quantity--;
			}
		}
		this.qtyRec.setValue(this.sending_quantity);

		if(this.sending_quantity >= parseFloat(this.itemdetails.openpos)) {
			this.rec_complete= true;
		} else {
			this.rec_complete= false;
		}
	}


	updatePick() {
		event.stopPropagation();
		event.preventDefault();
		let item = {
			quantityrecd: this.receiving_quantity ,
			stockid: this.item.stockid,
			loccode: this.location,
			bin: this.receiving_bin,
			vendor: this.vendor_selected
		};


		var data = { item, user: this.user.user};
		if (data) {
			//send to socket
			this.omsService.sendAutoPutUpdate({data, roomName: this.CHAT_ROOM}, cb => {
				//doesnt update auto complete?
				this.loadData(this.item.stockid);
			});
		}
	}



	updateRga() {
		event.stopPropagation();
		event.preventDefault();
		let item = {
			quantitysending: this.sending_quantity,
			stockid: this.item.stockid,
			loccode: this.location,
			bin: this.receiving_bin,
			vendor: this.vendor_selected
		};


		var data = { item, user: this.user.user};
		if (data) {
			//send to socket
			this.omsService.rgaAutoPutUpdate({data, roomName: this.CHAT_ROOM}, cb => {
				//doesnt update auto complete?
				this.loadData(this.item.stockid);
			});
		}
	}



	audioPlay() {
		var audio = new Audio("/assets/pristine-609.mp3");
		audio.play();
	}

	audioPlayBad() {
		var audio = new Audio("/assets/glitch.mp3");
		audio.play();
	}

	audioPlayFree() {
		var audio = new Audio("/assets/money.mp3");
		let play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	vibrate() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(2000);
		} else {
			// Not supported
		}
	}

	loadOrder(order) {
		this.ordersService.getOrder(order).subscribe((results: any) => {
			this.showorder = true;
			this.orderdetails = results;

			this.modalService.open(this.detailsModalEle, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {

			}, (reason) => {

			});

		});
	}

	ngAfterViewInit(): void {

	}

}
