<div class="main-content" *ngIf="!accountForm">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> General Ledger Accounts</h4>
					</div>
					<div class="card-body">
						<div class="table table-sm ">
							<div class="table table-responsive text-center" *ngIf="!accounts">
								<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</div>
							<div class="row">
								<div class="col-4">
									<a [routerLink]="'edit'" mat-stroked-button color="danger">New Account</a>
								</div>
							</div>
							<mat-table [dataSource]="accounts" matSort (matSortChange)="announceSortChange($event)">
								<ng-container matColumnDef="actions">
									<mat-header-cell class="d-none d-md-inline" *matHeaderCellDef></mat-header-cell>
									<mat-cell class="d-none d-md-inline" *matCellDef="let account">
										<a [routerLink]="[account.accountcode]" mat-stroked-button class="mt-1">Edit</a>
										<button class="btn btn-sm btn-danger " (click)="deleteAccount(account.accountcode)"><i class="fa fa-remove"></i></button>
									</mat-cell>
								</ng-container>

								<ng-container matColumnDef="accountcode">
									<mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Code">
										Account Code
									</mat-header-cell>
									<mat-cell *matCellDef="let account"> {{account.accountcode}} </mat-cell>
								</ng-container>
								<ng-container matColumnDef="accountname">
									<mat-header-cell *matHeaderCellDef mat-sort-header> Account Name</mat-header-cell>
									<mat-cell *matCellDef="let account"> {{ account.accountname}} </mat-cell>
								</ng-container>
								<ng-container matColumnDef="group">
									<mat-header-cell *matHeaderCellDef mat-sort-header> Account Group</mat-header-cell>
									<mat-cell *matCellDef="let account"> {{ account.group}} </mat-cell>
								</ng-container>
								<ng-container matColumnDef="accounttype">
									<mat-header-cell *matHeaderCellDef mat-sort-header> Account Type</mat-header-cell>
									<mat-cell *matCellDef="let account"> {{ account.type}} </mat-cell>
								</ng-container>
								<mat-header-row *matHeaderRowDef="headercolumns"></mat-header-row>
								<mat-row *matRowDef="let row; columns: headercolumns;"></mat-row>
							</mat-table>
							<mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons aria-label="select page">
							</mat-paginator>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="main-content" *ngIf="accountForm">
	<form [formGroup]="accountForm" class="text-dark" (submit)="updateAccount()" *ngIf="accountForm">
		<div class="container-fluid" *ngIf="ld">
			<div class="row">
				<div class="col-md-12">
					<div class="card">
						<div class="card-header card-header-{{color}}-5">
							<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i>General Ledger Accounts</h4>
							<p class="card-category"></p>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-md-12">
									<mat-form-field class="full-width" appearance="standard">
										<mat-label>Account Code</mat-label>
										<input matInput required formControlName="accountcode" placeholder="Account Code" [value]="ld?.accountcode">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field class="full-width" appearance="standard">
										<mat-label>Account Name</mat-label>
										<input matInput formControlName="accountname" placeholder="Account Name" [value]="ld?.accountname">
									</mat-form-field>
								</div>

							</div>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field class="full-width" appearance="standard">
										<mat-label>Account Group</mat-label>
										<mat-select formControlName="group">
											<mat-option value=""></mat-option>
											<mat-option *ngFor="let group of groups" [value]="group.groupname">
												{{ group.groupname }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12 ml-auto mr-auto ml-0 mb-6">
								<button mat-raised-button type="submit" class="btn btn-danger pull-right">Update G\L Account</button>
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>