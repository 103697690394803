import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';

import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { PurchasingService } from '../../services/purchasing.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { OmsService } from '../../services/oms.service';
import { GlobalsService } from '../../services/globals.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common'

@Component({
	selector: 'app-orders-open-quotes',
	templateUrl: './orders-open-quotes.component.html',
	styleUrls: ['./orders-open-quotes.component.scss']
})
export class OrdersOpenQuotesComponent implements OnInit {

	@ViewChild("stockidsearch") stockidSearchField: ElementRef;
	@ViewChild("purchaseDetails") purchaseDetailsRef: ElementRef;

	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	orderForm: UntypedFormGroup;
	CHAT_ROOM = "OrderBoard";
	allorders: any  = [];
	picking: any  = [];
	forcesearch: boolean = true;
	filteredItems: any = [];
	openorders: any;
	headercolumns: string[] = [
		'transno',
		'status',
		'orderno',
		'debtorno',
		//'deliverto',
		//'transno',
		'haspo',
		'deliverydate',

	];
	orderupdates: any = [];
	openOrderData: any = [];
	config: any = [];
	orderdetails: any = [];
	searchInput = new UntypedFormControl('');
	searchInputItems = new UntypedFormControl('');
	//pdf: todo - make this part of config
	baselink: string = '';
	color: any = '';
	defaultlocation = new UntypedFormControl('');
	locations: any = [];
	itemsearch: boolean = false;
	itemsearchresults: any = false
	podetails: any = [];

	allcustomer = {
		customer: {
			debtorno: '*',
		}
	}
	user: any = false;
	data: any = [];
	constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, private ordersService: OrdersService, public router: Router, private location: Location, private inventoryService: InventoryService, private omsService: OmsService, private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private modalService: NgbModal, public purchasingService: PurchasingService) {
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.user.subscribe(r => {
			this.user = r;
		});
	}

	ngOnInit(): void {

		this.config = this.globalsService.getApiConfig()
		this.baselink = this.config.apiServer.pickingLink;


		this.ordersService.getOpenQuotes().subscribe(async (results: any) => {
			this.data = results;
		});
	}

	selectItemLookup(event: any) {
		this.router.navigate(['./orders/entry/' + event.header.debtorno + '/' + event.header.branchcode]);
	}

	back(): void {
		this.location.back()
	}

	invoiceOrder(event: any) {
		this.router.navigate(['orders/invoice/' + event + '/dispatch']);
	}

	exportExcel() {
		this.globalSearchService.exportJsonToExcel(this.openOrderData, 'OpenOrders');
	}
}
