import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, FormControl, ValidationErrors } from '@angular/forms';

import { formatDate } from '@angular/common';
import { GlobalsService } from '../../services/globals.service';
import { ReportsService } from '../../services/reports.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PrintService } from '../../services/print.service';
import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-dreg',
	templateUrl: './dreg.component.html',
	styleUrls: ['./dreg.component.scss']
})
export class DregComponent implements OnInit {

	@ViewChild('print_table') printtable: ElementRef;
	dreg: any = {
		dregdate: new Date(),
		startcash: 0.00,
		endcash: 0.00,
		expenses: [],
		payments: [],
		salestotal: 0.00,
		taxtotal: 0.00,
		calctotal: 0.00,
		calcdeposit: 0.00,
		deposit: 0.00,
		overshort: 0.00,
		loccode: '',
	};
	location: any = new UntypedFormControl('');
	user: any = [];
	color: any = '';
	branches: any = [];
	filename: any = 'DREG';
	canedit: boolean = true;
	printing: any = false;

	constructor(private globalsService: GlobalsService, private reportsService: ReportsService, private printService: PrintService, private globalSearchService: GlobalSearchService) {}

	ngOnInit(): void {
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.user.subscribe(result => {
			this.user = result;
			this.location.value = this.user.user.defaultlocation.loccode;
		});
		this.globalsService.getUserLocations().subscribe((results: any) => {
			this.branches = results;
		});

		let ddate = formatDate(this.dreg.dregdate, 'yyyy-MM-dd', 'en_US');





		this.getPayments();
	}
	changeLocation(e) {
		this.getPayments();
	}
	getPayments() {
		let ddate = formatDate(this.dreg.dregdate, 'yyyy-MM-dd', 'en_US');
		let data = { 'dregdate': ddate, 'dreglocation': this.location.value };
		this.globalsService.getDregPayments(data).subscribe((results: any[]) => {

			this.dreg.payments = results['payments'];
			this.dreg.salestotal = results['salestotal'];
			this.dreg.taxtotal = results['taxtotal'];
			this.dreg.deposit = results['deposit'];
			this.dreg.startcash = results['startcash'];
			this.dreg.endcash = results['pmcash'];
			this.dreg.expenses = results['expenses'];
			this.recalc();
		});

	}
	dregdatechange(e) {

		let ddate = formatDate(e, 'yyyy-MM-dd', 'en_US');
		let data = { 'dregdate': ddate, 'dreglocation': this.location.value };
		this.globalsService.getDregPayments(data).subscribe((results: any[]) => {

			this.dreg.payments = results['payments'];
			this.dreg.salestotal = parseFloat(results['salestotal']).toFixed(2);
			this.dreg.taxtotal = parseFloat(results['taxtotal']).toFixed(2);
			this.dreg.deposit = parseFloat(results['deposit']).toFixed(2);
			this.dreg.startcash = parseFloat(results['startcash']).toFixed(2);
			this.dreg.endcash = parseFloat(results['pmcash']).toFixed(2);
			this.dreg.expenses = results['expenses'];

			this.recalc();
			if (ddate != formatDate(new Date(), 'yyyy-MM-dd', 'en_US')) {
				this.canedit = false;
			} else {
				this.canedit = true;
			}
		});
	}

	recalc() {
		this.dreg.calctotal = parseFloat(this.dreg.salestotal);
		for (let x = 0; x < this.dreg.payments.length; x++) {

			if (this.dreg.payments[x].cash == 0) {
				this.dreg.calctotal = this.dreg.calctotal - this.dreg.payments[x].total;
			}
		}
		this.dreg.calctotal = this.dreg.calctotal - this.dreg.endcash;
		this.dreg.calctotal = this.dreg.calctotal + this.dreg.startcash;

		this.dreg.calctotal = parseFloat(this.dreg.calctotal);
		//Now include expenses for final deposit and calculate over/short

		this.dreg.calcdeposit = this.dreg.calctotal;
		for (let x = 0; x < this.dreg.expenses.length; x++) {
			this.dreg.calcdeposit = this.dreg.calcdeposit - this.dreg.expenses[x].total;
		}

		this.dreg.overshort = Math.round(((this.dreg.deposit - this.dreg.calcdeposit) * 100)) / 100;

		this.dreg.calcdeposit = parseFloat(this.dreg.calcdeposit).toFixed(2);
		this.dreg.deposit = parseFloat(this.dreg.deposit).toFixed(2);
		this.dreg.calctotal = parseFloat(this.dreg.calctotal).toFixed(2);
	}
	recalc2() {
		this.dreg.calctotal = parseFloat(this.dreg.salestotal).toFixed(2);
		for (let x = 0; x < this.dreg.payments.length; x++) {

			if (this.dreg.payments[x].cash == 0) {
				this.dreg.calctotal = this.dreg.calctotal - parseFloat(this.dreg.payments[x].total);
			}
		}
		this.dreg.calctotal = this.dreg.calctotal - parseFloat(this.dreg.endcash);
		this.dreg.calctotal = this.dreg.calctotal + parseFloat(this.dreg.startcash);

		//Now include expenses for final deposit and calculate over/short
		this.dreg.calcdeposit = parseFloat(this.dreg.calctotal);

		for (let x = 0; x < this.dreg.expenses.length; x++) {
			this.dreg.calcdeposit = Math.round(((this.dreg.calcdeposit - this.dreg.expenses[x].total) * 100)) / 100;
		}

		this.dreg.deposit = parseFloat(this.dreg.calcdeposit) + parseFloat(this.dreg.overshort);

		this.dreg.calcdeposit = parseFloat(this.dreg.calcdeposit).toFixed(2);
		this.dreg.deposit = parseFloat(this.dreg.deposit).toFixed(2);
		this.dreg.calctotal = parseFloat(this.dreg.calctotal).toFixed(2);
	}
	addexp(e) {
		this.dreg.expenses.push({ name: '', total: 0 });
	}
	removeexpense(expnum) {
		this.dreg.expenses.splice(expnum, 1);
	}
	postdreg() {

		this.dreg.loccode = this.location.value;

		this.globalsService.postDreg(this.dreg).subscribe((results: any) => {


		});
	}

	back() {


	}
	exportPdf() {
		this.printing = true;
		let encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);

		let data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.printing = false;
		});

	}
}
