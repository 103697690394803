import { AppComponent } from './app.component';

import { APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app.routing';

import { ComponentsModule } from './components/components.module';
import { InventoryModule } from './inventory/inventory.module';
import { DispatchesModule } from './dispatches/dispatches.module';
import { SystemModule } from './system/system.module';
import { OrdersModule } from './orders/orders.module';
import { CustomersModule } from './customers/customers.module';
import { PurchasingModule } from './purchasing/purchasing.module';
import { QantelModule } from './qantel/qantel.module';
import { UsersModule } from './users/users.module';
import { CounterPosModule } from './counter-pos/counter-pos.module';
import { VendorsModule } from './vendors/vendors.module';
import { GeneralLedgerModule } from './general-ledger/general-ledger.module';
import { DateAgoPipe } from './pipes/date-ago.pipe'
import { DataTablesModule } from 'angular-datatables';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ButtonModule } from 'primeng/button';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { NotifierModule } from 'angular-notifier';


import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';

import { FileuploadComponent } from './fileupload/fileupload.component';

import { WarehouseModule } from './warehouse/warehouse.module'

import { LogoutComponent } from './auth/logout/logout.component';
import { LoginComponent } from './auth/login/login.component';
import { ServiceWorkerModule } from '@angular/service-worker';

import { ReportsComponent } from './reports/reports.component';
import { ReportViewComponent } from './reports/report-view/report-view.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
//import { OrdersComponent } from './orders/orders.component';
import { DispatchesComponent } from './dispatches/dispatches.component';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { OdsComponent } from './ods/ods.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
//import { PickComponent } from './orders/pick/pick.component';
import { GridsterModule } from 'angular-gridster2';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { VendorItemsComponent } from './vendors/vendor-items/vendor-items.component';
import { CreditsModule } from './credits/credits.module';
import { AppConfig } from './app.config';
import { RuntimeConfigLoaderModule } from 'runtime-config-loader';
import { environment } from '../environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { LazyLoadDirective } from './directives/lazy-load.directive';
import { ItemSearchFiltersComponent } from './item-search-filters/item-search-filters.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SharedModule } from './shared/shared.module';
import { MinusPipe } from './pipes/minus.pipe';
import { NgMagicIframeModule } from '@sebgroup/ng-magic-iframe';
import { PhoneFormatPipe } from './pipes/phone-format.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CategoryAndLinesComponent } from './dashboard/dashboard-components/category-and-lines/category-and-lines.component';
import { DashboardComponentsModule } from './dashboard-components/dashboard-components.module';




const maskConfigFunction: () => Partial < IConfig > = () => {
	return {
		validation: false,
	};
};

const jsonFile = `assets/config/config.${environment.name}.json`;
export function initializeApp(appConfig: AppConfig) {
	if (!appConfig.load()) {
		return '';
	}
	return () => appConfig.load();
}

declare global {
	interface Navigator {
		msSaveBlob: (blob: Blob, fileName: string) => boolean
	}
}

//changed to 3009
//const config: SocketIoConfig = { url: '//clevehilltire.com:3009', options: {} };
//const config: SocketIoConfig = { url: 'wss://socket.clevehilltire.com:5009', options: {} };


//import { SocketIoModule, SocketIoConfig } from 'ng-socket-io';
//const socketconfig: SocketIoConfig = { url: 'http://localhost:3000', options: {} };
const config: SocketIoConfig = { url: '' }

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
	// Change this to your upload POST address:
	url: 'https://httpbin.org/post',
	maxFilesize: 50,
	acceptedFiles: 'image/*'
};


@NgModule({
	imports: [
		RouterModule,
		CommonModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		HttpClientModule,
		ComponentsModule,
		InventoryModule,
		SystemModule,
		QantelModule,
		OrdersModule,
		UsersModule,
		CreditsModule,
		CounterPosModule,
		GeneralLedgerModule,
		VendorsModule,
		CustomersModule,
		PurchasingModule,
		DispatchesModule,
		WarehouseModule,
		AppRoutingModule,
		NgxDropzoneModule,
		DropzoneModule,
		DataTablesModule,
		BrowserAnimationsModule,
		MatMenuModule,
		ButtonModule,
		DragDropModule,
		GridsterModule,
		NgxMaskModule.forRoot(maskConfigFunction),
		MatToolbarModule,
		MatButtonModule,
		MatDialogModule,
		MatDividerModule,
		MatInputModule,
		MatTableModule,
		MatCheckboxModule,
		MatSortModule,
		MatPaginatorModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatSelectModule,
		MatTableModule,
		MatTooltipModule,
		MatIconModule,
		MatListModule,
		ScrollingModule,
		NotifierModule,
		RuntimeConfigLoaderModule.forRoot({ configUrl: './assets/config/config.' + environment.name + '.json' }),
		//SocketIoModule.forRoot(config),
		NgxDaterangepickerMd,
		MatNativeDateModule,
		NgxDaterangepickerMd.forRoot(),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		}),
		NgbModule,
		NgMagicIframeModule,
		CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
	],
	declarations: [
		AppComponent,
		AdminLayoutComponent,
		FileuploadComponent,
		LogoutComponent,
		LoginComponent,
		ReportsComponent,
		ReportViewComponent,
		DateAgoPipe,
		// OrdersComponent,
		DispatchesComponent,
		OdsComponent,
		VendorItemsComponent,
		LazyLoadDirective,
		ItemSearchFiltersComponent,
		MinusPipe,
		PhoneFormatPipe,
		CategoryAndLinesComponent,

		//  PickComponent,
	],
	providers: [AuthGuard, AuthService, {
		provide: DROPZONE_CONFIG,
		useValue: DEFAULT_DROPZONE_CONFIG
	}],
	bootstrap: [AppComponent],
	exports: [SharedModule]

})
export class AppModule {}
