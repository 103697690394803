import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit} from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { interval, Subscription} from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router} from '@angular/router';


import { GlobalsService } from '../../../services/globals.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { Customer } from '../../../classes/customer';
import { Location } from '@angular/common'

import { VendorService } from '../../../services/vendor.service';

@Component({
	selector: 'app-vendor-edit',
	templateUrl: './vendor-edit.component.html',
	styleUrls: ['./vendor-edit.component.scss'],
	animations: [
	  trigger('flipState', [
		state('active', style({
			transform: 'rotateY(-179deg)'
		})),
		state('inactive', style({
			transform: 'rotateY(0)'
		})),
		transition('inactive => active', animate('250ms ease-in')),
		transition('active => inactive', animate('250ms ease-out')),
	  ]),
	  trigger("grow", [ // Note the trigger name
		transition(":enter", [
		  // :enter is alias to 'void => *'
		  style({ height: "0",width: "0"}),
		  animate(200, style({ height: "*" , width: "*"})),
		  animate('200ms', style({ opacity: 1 })),
		]),
		transition(":leave", [
		  // :leave is alias to '* => void'
		  animate(100, style({ height: 0, width: 0 })),
		  animate('100ms', style({ opacity: 0 }))
		])
	  ]),
	  trigger('openClose', [
			state(':enter', style({ height: '*' })),
			state(':leave', style({ height: '0px' })),
			transition('false <=> true', animate(500))
	  ]),
	]
})
export class VendorEditComponent implements OnInit {

	@Input() vendor;
	@Output() editing = new EventEmitter<boolean>();
	@Output() newvendordata = new EventEmitter<boolean>();
	vendorForm: any = false;
	zones: any = [];
	formEditAllowed = [
		'supplierid',
		'suppname',
		'address1',
		'address2',
		'address3',
		'address4',
		'address5',
		'address6',
		//'contactname'
	]

	constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder,private vendorService: VendorService, public router: Router, private location: Location, private globalsService: GlobalsService) { }

	ngOnInit(): void {
		var details = this.vendor
		var controls = [];
			const formGroup: UntypedFormGroup = new UntypedFormGroup({});
			Object.keys(details).forEach(key => {
				if(this.formEditAllowed.includes(key)) {
					let value = (details[key]) ? details[key] : '';
					controls.push({ name: key, control: new UntypedFormControl(value, []) })
				}
			});
			controls.forEach(f => {
				 formGroup.addControl(f.name, f.control, {})
			});
		this.vendorForm = formGroup;
	}

	onSubmit() {
		if(this.vendorForm.valid) {
			this.vendorService.updateVendor(this.vendorForm.value).subscribe((result: any)=>{
				this.newvendordata.emit(result);
				this.editing.emit(false);
			})
		}
	}

	ngAfterViewInit() {
		this.globalsService.getDefaultZones().subscribe(details => {
			this.zones = details;
		});
	}

	back(): void {
		this.editing.emit(false);
	}



}
