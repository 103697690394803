 import { Component, Input , OnInit,TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { GlobalsService } from '../services/globals.service';
import { GlobalSearchService } from '../services/globalsearchservice.service';
import { ROUTES } from '../classes/routes';
import { Router } from '@angular/router';
import { OmsService } from '../services/oms.service';
import { OrdersService } from '../services/orders.service';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent  {
	@ViewChild('checkedOrders') springTemplate = {} as TemplateRef<any>;
	@ViewChild('newOrders') newOrdersTemplate = {} as TemplateRef<any>;
	filterresults = [];
	filteredItems;
	orderData: any;
	customerData;
	locations: any = [];
	user: any = [];
	openOrderData: any = [];
	allresults: any = [];
	selectedItem: any = '';
	location = new UntypedFormControl();
	selected: any = false;
	config: any = false;
	datasets: any = false;
	selectedset: any = '';
	checkedorders: any = false;
	opened: boolean = false
	ordersopened: boolean = false
	@Input() name: string;
	neworders: any = [];

	constructor(private globalsService: GlobalsService, private bottomSheet: MatBottomSheet,private ordersService: OrdersService, private omsService: OmsService, private globalSearchService: GlobalSearchService, public router: Router){


		this.globalSearchService.configsubscription.subscribe(conf=>{

			this.config = conf;

			this.globalSearchService.user.subscribe( result => {
				this.user = result;
				this.selected = result.user.defaultlocation.loccode;
				if(this.user.pass) {
					this.globalSearchService.locations.subscribe( l => {
						this.locations = l;
					});
				}
				if(this.user.user.department == '1') {
					this.globalSearchService.datasets.subscribe( datasets => {
						this.datasets = datasets;
					});
				}
			});
		});
	}

	vibrate() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(2000);
		} else {
			// Not supported
		}
	}

	closeBottomSheet() {
		this.bottomSheet.dismiss(this.springTemplate);
	}

	loadCheckedOrders() {
		let input = {loccode: this.selected}
		this.ordersService.getCheckedOrders(input).subscribe(r => {
			this.checkedorders = r;
		});
	}

	loadOrder(orderno: any) {
		this.globalSearchService.reloadingOrder(true);
		this.ordersService.loadSalesOrder(orderno).subscribe((result: any) => {
			this.router.navigate(['./orders/entry/'+result.header.debtorno+'/'+result.header.branchcode]);
			this.globalSearchService.reloadingOrder(false);

		});
	}

	selectItemLookup(event: any) {
		this.router.navigate(['./customers/view/'+event.debtorno]);
	}

	pickOrder(event: any) {
		this.router.navigate(['./orders/pick/'+event]);
	}

	invoiceOrder(event: any) {
		this.router.navigate(['orders/invoice/'+event+'/dispatch']);
	}

	ngOnInit() {


		this.globalSearchService.selectedlocation.subscribe( result => {
			if(!result) {
				this.globalSearchService.selectedlocation.next(this.user.user.defaultlocation.loccode)
				result = this.user.user.defaultlocation.loccode
			}
			this.selected = result;

		});
	}

	setDataSet(event: any) {
		let newDataSet = event.value;

		localStorage.removeItem('app');

		this.globalSearchService.setPackage('app',event.value.config.env.package)

		let newpackaege = this.globalSearchService.getSessionItem('app');
		this.globalSearchService.configsubscription.next(event.value.config);

		this.selectedset = event.value.config.env.package;
		this.config = event.value.config;
		this.router.navigate(['/']);
		window.location.reload();

	}

	changeLocation(event: any) {

		let newLocation = event.value;

		let newloc = this.locations.filter( (l:any) => {
			return l.loccode == newLocation;
		})[0];

		//reset the users default location on change - not permanent.
		//default to clevehill
		if(!newloc) {
			newloc = '01'
		}


		this.user.user.defaultlocation = newloc;
		this.globalSearchService.user.next(this.user)
		this.globalSearchService.userData.next(this.user);
		localStorage.setItem('user', JSON.stringify(this.user))
	}

	onChange(event: any) {

		console.log(event);

		let option =this.allresults.filter(f=> {
			return f.name == event.target.value;
		})[0];

		if(option) {
			if(option.link) {
				this.router.navigate([option.link])
			}
		}
	}

	navto(link:any) {
		this.router.navigate([link])
	}

	onInput(event: any, keys: string = 'orderno'){
		// this pushes the input value into the service's Observable.

		this.globalSearchService.searchTerm.next(event.target.value);

		let term = event.target.value;

		this.globalSearchService.orderData.subscribe((cards: any) => {
			this.orderData = cards;
		});

		this.globalSearchService.customerData.subscribe((customers: any) => {
			this.customerData = customers;
		});

		this.globalSearchService.openOrderData.subscribe((openorders: any) => {
			this.openOrderData = openorders;
		});

		let allresults = [];

		if(this.openOrderData) {
			var openorders = this.filterItem(this.openOrderData, event.target.value, 'ADDRESS_1,CUST_NAME');
			if(openorders) {
				openorders.forEach(function (value) {
					allresults.push({content: 'Open Order', name: value.ORDER_NUMBER + ' ' + value.CUST_NAME, link:'orders/'+value.ORDER_NUMBER})
				});
			}
		}

		if(this.orderData) {
			var todo = this.filterItem(this.orderData.todo, event.target.value, 'orderno,content');
			var pending = this.filterItem(this.orderData.review, event.target.value, 'orderno,content');
			var done = this.filterItem(this.orderData.done, event.target.value, 'orderno,content');

			if(todo) {
				todo.forEach(function (value) {
					allresults.push({content: 'Oms ToDo', name: value.content + value.content, link:'orders/oms'})
				});
			}

			if(pending) {
				pending.forEach(function (value) {
					allresults.push({content: 'Oms Pending', name: value.content + value.content, link:'orders/oms'})
				});
			}
			if(done) {
				done.forEach(function (value) {
					allresults.push({content: 'Oms Done', name: value.content + value.content, link:'orders/oms'})
				});
			}
		}

		if(ROUTES) {
			var routes = this.filterItem(ROUTES, event.target.value, 'title,icon');
			routes.forEach(function (value) {
				if(value.children) {

					if(value.children.length) {
						let thesekeys = 'title,icon'
						var childrensearch = Object.assign([], value.children).filter((item) => thesekeys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(event.target.value, 'gi').test(item[key])));

						childrensearch.forEach(function (childvalue) {
							allresults.push({content: childvalue.title, name: childvalue.title, link: ''+childvalue.path })
						});
					}
				}
			});
		};

		this.allresults = allresults;
		this.setFiltered(allresults)
	}

	getFiltered() {
		return this.filteredItems;
	}

	setFiltered(results) {
		this.filteredItems = results;
	}

	assignCopy(){
		this.filteredItems = Object.assign([], this.orderData);
	}

	filterItem(input, term , keys ){
		if(!term){
			this.assignCopy();
		}
		var result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) &&	new RegExp(term, 'gi').test(item[key])));
		return result.splice(0,15)
	}

	clicked(link) {
		this.router.navigate([link]);
	}


}
