<div class="main-content">
	<div class="container-fluid">
		<div class="row tp-wrapper m-0 p-0">
			<div class="col-md-12 ">
				<div class="card ">
					<div class="card-header card-header-{{color}}">
						<h4 class="card-title">
							<i class="material-icons">arrow_left</i> Statements
						</h4>
					</div>

					<div class="card-body">
					<!--left-->

					<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
						<mat-tab label="Statement Messages" class="no-overflow">
							<ng-template matTabContent>
								<div class="row mt-3">
									<div class="col-md-3" *ngFor="let r of statement_text">
										<h5>{{ r.confname }}</h5>
										<angular-editor [init]="editorConfig" (onSelectionChange)="updateValue($event, r)" [config]="editorConfig" [initialValue]="r.confvalue"></angular-editor>
									</div>
								</div>
								<div class="col-12 text-right">
									<button mat-raised-button color="accent" (click)="saveMessages()">Save Message Text</button>
								</div>
							</ng-template>
						</mat-tab>

						<mat-tab label="Print Statements" class="no-overflow">
							<ng-template matTabContent>
								<form [formGroup]="statementForm" class="no-overflow" *ngIf="statementForm">
									<div class="row">



										<div class="col-md-3">
											<mat-form-field appearance="standard">
												<mat-label>Starting Customer (Customer code)</mat-label>
												<input type="text" class="text-right mt-0 pt-0" placeholder="Reference" formControlName="FromCust" aria-label="Reference" matInput />
											</mat-form-field>
										</div>
										<div class="col-md-3">
											<mat-form-field appearance="standard">
												<mat-label>Ending Customer (Customer code)</mat-label>
												<input type="text" class="text-right mt-0 pt-0" placeholder="Reference" formControlName="ToCust" aria-label="Reference" matInput />
											</mat-form-field>
										</div>
										<div class="col-md-3">
											<mat-form-field appearance="standard">
												<mat-label>Cut off Date</mat-label>
												<input matInput [value]="today" formControlName="dateto" [matDatepicker]="paydatepicker">
												<mat-datepicker-toggle matSuffix [for]="paydatepicker"></mat-datepicker-toggle>
												<mat-datepicker #paydatepicker></mat-datepicker>
											</mat-form-field>
										</div>

										<div class="col-md-3">
											<mat-form-field appearance="standard">
												<mat-label>Statement Due Date</mat-label>
												<input matInput [value]="today" formControlName="datedue" [matDatepicker]="paydatepickerdatedue">
												<mat-datepicker-toggle matSuffix [for]="paydatepickerdatedue"></mat-datepicker-toggle>
												<mat-datepicker #paydatepickerdatedue></mat-datepicker>
											</mat-form-field>
										</div>


										<div class="col-md-6">
											<mat-form-field appearance="outline">
												<mat-label>Statement Types</mat-label>
												<mat-select formControlName="type" multiple>
													<mat-option value="credit">Credit Balance</mat-option>
													<mat-option value="debit">Debit Balance</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
										<div class="col-md-6 mt-3">
											Customers with Print Statement Flag Only <mat-slide-toggle formControlName="printonly">
											</mat-slide-toggle>
										</div>

										<div class="table table-responsive text-center" *ngIf="loading">
											Loading Please Wait...
											<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
											</svg>
										</div>
										<div class="col-md-12" *ngIf="!loading">
											<button mat-raised-button color="accent" (click)="printStatements()">Print Statements</button>
										</div>
									</div>
								</form>
							</ng-template>
						</mat-tab>


						<!-- <mat-tab label="Email Statements" class="no-overflow">

							<form action="/JobScheduler.php" method="post">
								<input type="hidden" name="FormID" value="019155599f70ad69305c3106c6385b3a5cb2d0b1">
								<input type="hidden" name="schedule" value="true">
								<input type="hidden" name="script" value="autosender.php">
								<table class="selection">
									<tbody>
										<tr>
											<td>Schedule Date/Time: </td>
											<td>
												<input type="text" name="job_schedule" maxlength="10" size="10" class="date" alt="d/m/Y" value="22/10/2022">
												<select name="job_schedule_time">

													<option value="01:">1 am</option>
													<option value="02:">2 am</option>
													<option value="03:">3 am</option>
													<option value="04:">4 am</option>
													<option value="05:">5 am</option>
													<option value="06:">6 am</option>
													<option value="07:">7 am</option>
													<option value="08:">8 am</option>
													<option value="09:">9 am</option>
													<option value="10:">10 am</option>
													<option value="11:">11 am</option>
													<option value="12:">12 pm</option>
													<option value="13:">1 pm</option>
													<option value="14:">2 pm</option>
													<option value="15:">3 pm</option>
													<option value="16:">4 pm</option>
													<option value="17:">5 pm</option>
													<option value="18:">6 pm</option>
													<option value="19:">7 pm</option>
													<option value="20:">8 pm</option>
													<option value="21:">9 pm</option>
													<option value="22:">10 pm</option>
													<option value="23:">11 pm</option>
													<option value="00:">12 am</option>
												</select>
												<select name="job_schedule_time_min">
													<option value="00:00">0 min</option>
													<option value="01:00">1 min</option>
													<option value="02:00">2 min</option>
													<option value="03:00">3 min</option>
													<option value="04:00">4 min</option>
													<option value="05:00">5 min</option>
													<option value="06:00">6 min</option>
													<option value="07:00">7 min</option>
													<option value="08:00">8 min</option>
													<option value="09:00">9 min</option>
													<option value="10:00">10 min</option>
													<option value="11:00">11 min</option>
													<option value="12:00">12 min</option>
													<option value="13:00">13 min</option>
													<option value="14:00">14 min</option>
													<option value="15:00">15 min</option>
													<option value="16:00">16 min</option>
													<option value="17:00">17 min</option>
													<option value="18:00">18 min</option>
													<option value="19:00">19 min</option>
													<option value="20:00">20 min</option>
													<option value="21:00">21 min</option>
													<option value="22:00">22 min</option>
													<option value="23:00">23 min</option>
													<option value="24:00">24 min</option>
													<option value="25:00">25 min</option>
													<option value="26:00">26 min</option>
													<option value="27:00">27 min</option>
													<option value="28:00">28 min</option>
													<option value="29:00">29 min</option>
													<option value="30:00">30 min</option>
													<option value="31:00">31 min</option>
													<option value="32:00">32 min</option>
													<option value="33:00">33 min</option>
													<option value="34:00">34 min</option>
													<option value="35:00">35 min</option>
													<option value="36:00">36 min</option>
													<option value="37:00">37 min</option>
													<option value="38:00">38 min</option>
													<option value="39:00">39 min</option>
													<option value="40:00">40 min</option>
													<option value="41:00">41 min</option>
													<option value="42:00">42 min</option>
													<option value="43:00">43 min</option>
													<option value="44:00">44 min</option>
													<option value="45:00">45 min</option>
													<option value="46:00">46 min</option>
													<option value="47:00">47 min</option>
													<option value="48:00">48 min</option>
													<option value="49:00">49 min</option>
													<option value="50:00">50 min</option>
													<option value="51:00">51 min</option>
													<option value="52:00">52 min</option>
													<option value="53:00">53 min</option>
													<option value="54:00">54 min</option>
													<option value="55:00">55 min</option>
													<option value="56:00">56 min</option>
													<option value="57:00">57 min</option>
													<option value="58:00">58 min</option>
													<option value="59:00">59 min</option>
												</select>
											</td>
										</tr>
										<tr>
											<td>Starting Customer (Customer code)</td>
											<td><input type="text" maxlength="10" size="8" name="debtorstart" value="0"></td>
										</tr>
										<tr>
											<td>Ending Customer (Customer code)</td>
											<td><input type="text" maxlength="10" size="8" name="debtorend" value="zzzzzz"></td>
										</tr>
										<tr>
											<td>Cut off Date: </td>
											<td><input type="text" name="cutoffdate" maxlength="10" size="10" class="date" alt="d/m/Y" value="22/10/2022"></td>
										</tr>
										<tr>
											<td colspan="2">

												<button mat-raised-button color="accent">Schedule Email</button>

											</td>
										</tr>
									</tbody>
								</table>
							</form>
						</mat-tab> -->
					</mat-tab-group>
				</div>
			</div>
		</div>
	</div>
</div>
</div>
