import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl,Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { Location } from '@angular/common'
import { ActivatedRoute, Router} from '@angular/router';

import { GlobalsService } from '../../../services/globals.service';
import { InventoryService } from '../../../services/inventory.service';

@Component({
	selector: 'app-inventory-edit-prices',
	templateUrl: './inventory-edit-prices.component.html',
	styleUrls: ['./inventory-edit-prices.component.scss'],
	animations: [
		trigger("grow", [ // Note the trigger name
			transition(":enter", [
				// :enter is alias to 'void => *'
				style({ height: "0",width: "0", overflow: "hidden" }),
				animate(200, style({ height: "*" , width: "*"})),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(":leave", [
				// :leave is alias to '* => void'
				animate(300, style({ height: 0, width: 0 , overflow: "hidden" })),
				animate('300ms', style({ opacity: 0 }))
			])
			]),
		]
})
export class InventoryEditPricesComponent implements OnInit {
	@Input() item;
	@Output() editing = new EventEmitter<boolean>();
	@Output() newitemdata = new EventEmitter<boolean>();
	itemForm: any = false;
	properties: any = [];

	itemMaterialCost =  new UntypedFormControl('');
	itemLastCost =  new UntypedFormControl('');
	itemActualCost =  new UntypedFormControl('');

	constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder,private inventoryService: InventoryService, public router: Router, private location: Location, private globalsService: GlobalsService) {}

	onSubmit() {

		let data = {
			prices: this.item.prices,
			material: this.itemMaterialCost.value,
			lastcost: this.itemLastCost.value,
			actualcost: this.itemActualCost.value
		}

		this.inventoryService.updatePrices(data).subscribe( (result: any) => {
			this.newitemdata.emit(result);
		})
	}

	ngOnInit(): void {
		var details = this.item

		this.itemMaterialCost.setValue(this.financial(this.item.item.cost));
		this.itemLastCost.setValue(this.financial(this.item.item.lastcost));
		this.itemActualCost.setValue(this.financial(this.item.item.actualcost));

	}

	updatePrice(value: any , price : any) {
		price.price = value;
	}

	ngAfterViewInit(): void {
		this.globalsService.getItemSetup().subscribe( (results: any) => {
			this.properties = results
		});
	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

}
