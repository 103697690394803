import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalSearchService } from '../services/globalsearchservice.service';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { SharedModule } from '../shared/shared.module';
import { OverDueCustomersComponent } from './over-due-customers/over-due-customers.component';
import { DisabledCustomersComponent } from './disabled-customers/disabled-customers.component';
import { AllCustomersComponent } from './all-customers/all-customers.component';
import { CustomersOnlineComponent } from './customers-online/customers-online.component';
import { CustomerCartsComponent } from './customer-carts/customer-carts.component';
import { CustomerOpenQuotesComponent } from './customer-quotes/customer-quotes.component';
import { OutstandingGrnsComponent } from './outstanding-grns/outstanding-grns.component';
import { OobCustomersComponent } from './oob-customers/oob-customers.component';
import { DeliveredNotInvoicedComponent } from './delivered-not-invoiced/delivered-not-invoiced.component';
import { MatchingDocsComponent } from './matching-docs/matching-docs.component';
import { MatchingDocCustomerTypeComponent } from './matching-doc-customer-type/matching-doc-customer-type.component';
import { PickableOrdersComponent } from './pickable-orders/pickable-orders.component';
import { PickingOrdersComponent } from './picking-orders/picking-orders.component';
import { PurchOpenOrdersComponent } from './purch-open-orders/purch-open-orders.component';


const maskConfigFunction: () => Partial < IConfig > = () => {
	return {
		validation: false,
	};
};

@NgModule({
	declarations: [
		OverDueCustomersComponent,
		DisabledCustomersComponent,
		AllCustomersComponent,
		CustomersOnlineComponent,
		CustomerCartsComponent,
		CustomerOpenQuotesComponent,
		OutstandingGrnsComponent,
		OobCustomersComponent,
		DeliveredNotInvoicedComponent,
		MatchingDocsComponent,
		MatchingDocCustomerTypeComponent,
		PickableOrdersComponent,
		PickingOrdersComponent,
		PurchOpenOrdersComponent,
	],
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule,
		BrowserAnimationsModule,
		SharedModule,
		ReactiveFormsModule,
		FormsModule,
		NgxMaskModule.forRoot(maskConfigFunction),
		MatToolbarModule,
		MatButtonModule,
		MatDialogModule,
		MatDividerModule,
		MatInputModule,
		MatTableModule,
		MatCheckboxModule,
		MatSortModule,
		MatCardModule,
		MatPaginatorModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatExpansionModule,
		MatSelectModule,
		MatMenuModule,
		MatIconModule,
		MatSlideToggleModule,
		MatRadioModule,
		MatStepperModule,
		MatListModule,
		MatTabsModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatButtonToggleModule,
		MatChipsModule,
		NgxImageZoomModule,
	],
	exports: [
		OverDueCustomersComponent,
		DisabledCustomersComponent,
		AllCustomersComponent,
		CustomersOnlineComponent,
		CustomerOpenQuotesComponent,
		OutstandingGrnsComponent,
		CustomerCartsComponent,
		OobCustomersComponent,
		DeliveredNotInvoicedComponent,
		MatchingDocsComponent,
		MatchingDocCustomerTypeComponent,
		PickableOrdersComponent,
		PickingOrdersComponent,
		PurchOpenOrdersComponent,
	]
})
export class DashboardComponentsModule {}
