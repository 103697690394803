import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { Location } from '@angular/common'

import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { PaymentsService } from '../../services/payments.service';
import { PrintService } from '../../services/print.service'
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-daily-transactions',
  templateUrl: './daily-transactions.component.html',
  styleUrls: ['./daily-transactions.component.scss']
})
export class DailyTransactionsComponent implements OnInit {
@ViewChild('print_table') printtable: ElementRef;
	color: string = 'blue';
	payments: any = [];
	today = new Date();
	filename: string = 'dailytransactions';
	title: string = 'Daily Transactions';

	datefrom = new UntypedFormControl(this.today);
	dateto = new UntypedFormControl(this.today);
	running: boolean = false;
	total_pay: any = '';

	journals: any = false;
	total_trans: any = 0;

	total_sub: any = 0;
	total_tax: any = 0;
	total_freight: any = 0;
	total_fee: any = 0;
	total_discount: any = 0;
	locations: any = [];
	user: any = false;
	defaultlocation = new UntypedFormControl('');
	ref_location: any = false;
	exporting: boolean = false;
	config: any = false;

	constructor(private location: Location, private printService: PrintService,private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private paymentsService: PaymentsService, private fb: UntypedFormBuilder, private route: ActivatedRoute, public router: Router) {

		this.globalSearchService.configsubscription.subscribe(s => {
			this.config = s;
		})

		this.color = this.globalSearchService.getColor();

		this.globalSearchService.user.subscribe( results => {
			//only run if user is definied
			if(this.user) {
				if(results.user.defaultlocation.loccode != this.ref_location) {
					this.defaultlocation.setValue(this.user.user.defaultlocation.loccode);
					this.ref_location = false;
				}
			}

			this.user = results

			if(!this.ref_location) {
				this.ref_location = this.user.user.defaultlocation.loccode;
			}

			this.globalSearchService.locations.subscribe(async (results: any) => {
				this.locations = results;

				if (results) {
					let val = [
						this.user.user.defaultlocation.loccode
					];
					this.defaultlocation.setValue(val)
					this.loadData();
				}
			});

		});
	}

	ngOnInit(): void {

	}

	loadData() {
		this.running = true;


		let data = { from: this.datefrom.value, to: this.dateto.value, locations: this.defaultlocation.value }
		this.paymentsService.getDailyTransactions(data).subscribe( (results: any) => {
			this.running = false;
			this.payments = results;
			this.total_trans = results.transactions.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.total);
			}, 0);


			this.total_sub = results.transactions.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.ovamount);
			}, 0);


			this.total_tax = results.transactions.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.ovgst);
			}, 0);


			this.total_freight = results.transactions.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.ovfreight);
			}, 0);

			this.total_fee = results.transactions.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.ovfee);
			}, 0);

			this.total_discount = results.transactions.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.ovdiscount);
			}, 0);



			this.total_pay = results.summary.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.amount);
			}, 0);





			this.getJournals();
		})
	}

	getLocationName() {
		var name = 'N/A';
		if(this.defaultlocation.value.length) {
			name = '';
			this.defaultlocation.value.forEach(v => {

				let loc = this.locations.filter( r=> {
					return v == r.loccode
				})[0];
				if(loc) {
					name += loc.locationname+ " ";
				}
			});
		}

		return name;
	}


	getJournals() {
		let data = {};
		this.paymentsService.getClosedJournals(data).subscribe((result: any) => {
			this.journals = result;
		});
	}

	exportPdf() {

		let encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const today = new Date();
		let data = {
			content: encoded,
			filename: this.filename,
			landscape: true,
			title: 'Daily Transactions',
			subtitle: 'Locations: ' +this.defaultlocation.value.join(',')+' '+this.datefrom.value.toLocaleDateString()+' - '+this.dateto.value.toLocaleDateString()+' created '+today.toLocaleString()
		}
		this.exporting = true;
		this.printService.pdf(data).subscribe((result: any) => {
			this.exporting = false;
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}
	exportXls() {
		let encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const today = new Date();
		let data = {
			content: encoded,
			filename: this.filename,
			title: 'Daily Transactions',
			subtitle: 'Locations: ' +this.defaultlocation.value.join(',')+' '+this.datefrom.value.toLocaleDateString()+' - '+this.dateto.value.toLocaleDateString()+' created '+today.toLocaleString()
		}
		this.exporting = true;
		this.printService.xls(data).subscribe((result: any) => {
			this.exporting = false;
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	updateDayPayMents(event: any) {
		this.today = event;
		this.loadData();
	}

	back(): void {
		this.location.back()
	}


}
