import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpClientModule, HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { CartProduct, Product } from '../classes/orders';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';

@Injectable({
	providedIn: 'root'
})

export class PurchasingService {


	key: string = 'UET6DFMPAXW7BKCB';
	items: Product[] = [];
	config: any;
	user: any = [];
	package: any = '';

	constructor(private http: HttpClient, private _configSvc: RuntimeConfigLoaderService, public globalSearchService: GlobalSearchService) {

		this.globalSearchService.user.subscribe((result: any) => {
			this.user = result;
		});
		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		})
	}

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	public getOrderInqSearch(keywords: any) {
		var method = 'purchasing/inqsearch&keywords=' + keywords;
		return this.http.get(this.setEndPoint(method));
	}

	public getMstPo(data: any){
		var method = 'purchasing/getmstpo';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getPurchasesForItemByVendor(data: any) {
		var method = 'purchasing/vendoritempending';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public mailDiscrepancy(data: any) {
		var method = 'purchasing/maildiscrepancy';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public finalizeRec(data: any) {
		var method = 'oms/recitems/putrecitems';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public removeRec(data: any) {
		var method = 'oms/recitems/removerecitems';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getRecs(data: any) {
		var method = 'oms/recitems/getrecs';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public updateRec(data: any) {
		var method = 'oms/recitems/updaterecitems';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getRgasForItemByVendor(data: any) {
		var method = 'purchasing/rgavendoritempending';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	// public getRecScanHistory() {
	// 	var method = 'oms/recitems/getrecs';
	// 	return this.http.get(this.setEndPoint(method));
	// }

	public removeScannned(data: any) {
		var method = 'oms/recitems/removescannneditem';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public decrementScannned(data: any) {
		var method = 'oms/recitems/decrementscannneditem';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public incrementScannned(data: any) {
		var method = 'oms/recitems/incrementscannneditem';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public updateScanned(data: any) {
		var method = 'oms/recitems/updatescanneditem';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getTruckIncoming(data: any) {
		var method = 'purchasing/truckincoming';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}
	public setTruckHold(data: any) {
		var method = 'purchasing/holdreceiving';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}
	public setTruckDone(data: any) {
		var method = 'purchasing/finalreceiving';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}
	public discontinueItems(data: any) {
		var method = 'purchasing/discontinueitems';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public updateItem(data: any) {
		var method = 'purchasing/updateitemminmax';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getBackOrders(data: any) {
		var method = 'inventory/backorder';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}
	public getFilters() {
		var method = 'filters/purchasing';
		return this.http.get(this.setEndPoint(method));
	};

	public getFiltersTwo() {
		var method = 'filters/purchasingtwo';
		return this.http.get(this.setEndPoint(method));
	};



	public removePurchaseDate(data: any) {
		var method = 'purchasing/purchdata/remove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public printPreviewPo(data: any) {
		var method = 'purchasing/order/preview';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public addPurchaseDate(data: any) {

		var method = 'purchasing/purchdata/add';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);

	}

	public getRemoteQty(stockids: any) {
		var method = 'remote/inventory/get';
		return this.http.post(this.setEndPoint(method), stockids, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public loadPurchaseOrder(order: any) {
		var method = 'purchasing/order/loadorder&orderno=' + order;
		return this.http.get(this.setEndPoint(method));

	}

	public loadRga(order: any) {
		var method = 'rga/order/loadorder&orderno=' + order;
		return this.http.get(this.setEndPoint(method));

	}

	public getPurchaseOrder(order: any) {
		var method = 'purchasing/order/getorder&orderno=' + order;
		return this.http.get(this.setEndPoint(method));

	}

	public getRga(order: any) {
		var method = 'rga/order/getorder&orderno=' + order;
		return this.http.get(this.setEndPoint(method));

	}

	public geMinMaxRecomendationsTwo(data: any) {
		var method = 'purchasing/reorder/minmaxtwo';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
		return this.http.get(this.setEndPoint(method));
	}

	public geMinMaxRecomendations(data: any) {
		var method = 'purchasing/reorder/minmax';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
		return this.http.get(this.setEndPoint(method));
	}

	public getStockRecomendations(data: any) {
		var method = 'purchasing/reorder/recomendations';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
		return this.http.get(this.setEndPoint(method));
	}

	public getMixesRecomendations(data: any) {
		var method = 'purchasing/reorder/mixed';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
		return this.http.get(this.setEndPoint(method));
	}

	public getReStock(data: any) {
		var method = 'purchasing/reorder/restock';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
		return this.http.get(this.setEndPoint(method));
	}

	public closeJournal(journal: any) {
		var method = 'purchasing/closejounral';
		return this.http.post(this.setEndPoint(method), journal, this.httpOptions).pipe(
			catchError(this.handleError(method, journal))
		);
	}

	public getOpenByItem(stockid: any) {

		var method = 'oms/put/getopenitem&stockid=' + stockid;
		return this.http.get(this.setEndPoint(method));
	}

	public getItemScan(data: any) {
		var method = 'purchasing/items/scan';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
		return this.http.get(this.setEndPoint(method));
	}

	public getItemSearch(data: any) {
		var method = 'purchasing/items/search';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
		return this.http.get(this.setEndPoint(method));
	}

	public getPutAwaySearch(data: any) {
		var method = 'oms/put/search';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
		return this.http.get(this.setEndPoint(method));
	}



	public getOpenGrns() {
		var method = 'purchasing/order/grns';
		return this.http.get(this.setEndPoint(method));
	}

	public getOpenGrnBatches(supplierid: any) {
		var method = 'purchasing/order/opengrns&supplierid=' + supplierid;
		return this.http.get(this.setEndPoint(method));
	}

	public getGrnDetails(supplierid: any) {
		var method = 'purchasing/order/grns&supplierid=' + supplierid;
		return this.http.get(this.setEndPoint(method));
	}

	public getPurchaseOrderDetails(orderno: any) {
		var method = 'purchasing/order/details&orderno=' + orderno;
		return this.http.get(this.setEndPoint(method));
	}

	public searchPurchaseOrders(search: any) {
		//var method = 'purchasing/order';
		var method = 'purchasing/order';
		return this.http.post(this.setEndPoint(method), search, this.httpOptions).pipe(
			catchError(this.handleError(method, search))
		);
	}

	public searchRgas(search: any) {
		//var method = 'purchasing/order';
		var method = 'rga/order';
		return this.http.post(this.setEndPoint(method), search, this.httpOptions).pipe(
			catchError(this.handleError(method, search))
		);
	}

	public getPurchaseOrders() {
		var method = 'purchasing/order';
		return this.http.get(this.setEndPoint(method));
	}

	public getRgas() {
		var method = 'purchasing/order';
		return this.http.get(this.setEndPoint(method));
	}

	public getVendorPurchaseOrders(vendorid: any) {
		var method = 'purchasing/order&supplierid=' + vendorid;
		return this.http.get(this.setEndPoint(method));
	}

	public completeVendorPurchaseOrder(data: any) {
		var method = 'purchasing/order/receive';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getStatus() {
		var method = 'purchasing/order/statuses';
		return this.http.get(this.setEndPoint(method));
	}

	public getLocations(loccode: any) {
		var method = 'purchasing/getlocations&loccode=' + loccode;
		return this.http.get(this.setEndPoint(method));
	}

	public getSuppliers(supplier: any) {
		var method = 'purchasing/getsuppliers&supplierid=' + supplier;
		return this.http.get(this.setEndPoint(method));
	}

	public getSupplier(supplier: any) {
		var method = 'purchasing/getsupplier&supplierid=' + supplier;
		return this.http.get(this.setEndPoint(method));
	}


	public getCustomer(customerid) {
		var method = 'customers/getcustomer&customerid=' + customerid;
		return this.http.get(this.setEndPoint(method));
	}

	public getOrders() {
		var method = 'purchasing/order';
		return this.http.get(this.setEndPoint(method));
	}

	public getDispatches() {
		var method = 'orders/dispatches';
		return this.http.get(this.setEndPoint(method));
	}

	public getProducts(search) {
		var method = 'purchasing/items/supplieritems';
		return this.http.post(this.setEndPoint(method), search, this.httpOptions).pipe(
			catchError(this.handleError(method, search))
		);
	}

	public getOrder(oid) {
		var method = 'orders/getdetails&oid=' + oid;
		return this.http.get(this.setEndPoint(method));
	}

	public setOrderItems(items) {
		this.items = items;
	}

	public addToOrder(product: any, supplier: any) {
		this.items.push(product);
		var method = 'purchasing/add';
		return this.http.post(this.setEndPoint(method), { item: product, supplier: supplier }, this.httpOptions).pipe(
			catchError(this.handleError(method, product))
		);
	}

	public addToCredit(product: any, supplier: any) {
		this.items.push(product);
		var method = 'rga/add';
		return this.http.post(this.setEndPoint(method), { item: product, supplier: supplier }, this.httpOptions).pipe(
			catchError(this.handleError(method, product))
		);
	}

	public addMultipleToOrder(products: any, supplierid: string) {
		var method = 'purchasing/addmultiple';
		return this.http.post(this.setEndPoint(method), { items: products, supplierid: supplierid }, this.httpOptions).pipe(
			catchError(this.handleError(method, products))
		);
	}

	public addMultipleToRga(products: any, supplierid: string) {
		var method = 'rga/addmultiple';
		return this.http.post(this.setEndPoint(method), { items: products, supplierid: supplierid }, this.httpOptions).pipe(
			catchError(this.handleError(method, products))
		);
	}

	public uploadFile(formdata: any) {
		var method = 'purchasing/filedrop';
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}

	public updateStatus(data: any) {
		var method = 'purchasing/order/updatestatus';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public updateRgaStatus(data: any) {
		var method = 'rga/order/updatestatus';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public updateOrder(product) {
		var method = 'purchasing/update';
		return this.http.post(this.setEndPoint(method), product, this.httpOptions).pipe(
			catchError(this.handleError(method, product))
		);
	}

	public updateCredit(product) {
		var method = 'rga/update';
		return this.http.post(this.setEndPoint(method), product, this.httpOptions).pipe(
			catchError(this.handleError(method, product))
		);
	}



	public updatePurchaseOrder(data: any) {
		var method = 'purchasing/order/updatepurchaseorder';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public createPurchaseOrdersFromRecomend(data: any) {
		var method = 'purchasing/order/createfromrecommendations';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, method))
		);
	}

	public createPurchaseOrders(data: any) {
		var method = 'purchasing/order/createfromsalesorder';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, method))
		);
	}

	public createPurchaseOrder(supplier: any, header: any, details: any, user: any) {
		var method = 'purchasing/order/create';
		return this.http.post(this.setEndPoint(method), { supplier: supplier, header: header, details: details, user: user }, this.httpOptions).pipe(
			catchError(this.handleError(method, method))
		);
	}

	public createRga(supplier: any, header: any, details: any, user: any) {
		var method = 'rga/order/create';
		return this.http.post(this.setEndPoint(method), { supplier: supplier, header: header, details: details, user: user }, this.httpOptions).pipe(
			catchError(this.handleError(method, method))
		);
	}


	public clearCart(supplierno) {
		this.items = [];
		var method = 'purchasing/clearcart';
		return this.http.post(this.setEndPoint(method), { customerno: supplierno }, this.httpOptions).pipe(
			catchError(this.handleError(method, supplierno))
		);
	}

	public clearOrder(supplierno) {
		this.items = [];
		var method = 'purchasing/clear';
		return this.http.post(this.setEndPoint(method), { customerno: supplierno }, this.httpOptions).pipe(
			catchError(this.handleError(method, supplierno))
		);
	}

	public clearCredit(supplierno) {
		this.items = [];
		var method = 'rga/clear';
		return this.http.post(this.setEndPoint(method), { customerno: supplierno }, this.httpOptions).pipe(
			catchError(this.handleError(method, supplierno))
		);
	}

	public removeFromCredit(cart_id, customer) {

		var method = 'rga/remove';
		return this.http.post(this.setEndPoint(method), { cart_id: cart_id, customer: customer }, this.httpOptions).pipe(
			catchError(this.handleError(method, cart_id))
		);
	}

	public cancelCredit(data: any) {
		var method = 'rga/cancelorder';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public removeFromOrder(cart_id, customer) {

		var method = 'purchasing/remove';
		return this.http.post(this.setEndPoint(method), { cart_id: cart_id, customer: customer }, this.httpOptions).pipe(
			catchError(this.handleError(method, cart_id))
		);
	}

	public cancelOrder(data: any) {
		var method = 'purchasing/cancelorder';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getOrderItems(supplier: any) {
		var method = 'purchasing/getorder&supplier=' + supplier;
		this.getCart(supplier).subscribe((results: Product[]) => {
			this.items = results;
			return results
		});;

		return this.items;
	}

	public getCart(supplier) {
		var method = 'purchasing&supplierid=' + supplier;
		return this.http.get(this.setEndPoint(method));
	}

	public getRgaCart(supplier) {
		var method = 'rga&supplierid=' + supplier;
		return this.http.get(this.setEndPoint(method));
	}

	public getOrderList(oid) {
		var method = 'orders/getdetails&oid=' + oid;
		return this.http.get(this.setEndPoint(method));
	}

	public getPicking() {
		var method = 'oms/fillable';
		return this.http.get(this.setEndPoint(method));
	}

	public setPicking(data) {
		var method = 'orders/pick';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public updatePo(product: any, supplier: any) {
		var method = 'purchasing/update';
		return this.http.post(this.setEndPoint(method), { item: product, supplier: supplier }, this.httpOptions).pipe(
			catchError(this.handleError(method, product))
		);
	}

	public getTotals(supplier: any, freight: any = 0) {
		var method = 'purchasing/gettotals&supplierid=' + supplier;
		return this.http.post(this.setEndPoint(method), supplier, this.httpOptions).pipe(
			catchError(this.handleError(method, supplier))
		);
	}

	public getRgaTotals(supplier: any, freight: any = 0) {
		var method = 'rga/gettotals&supplierid=' + supplier;
		return this.http.post(this.setEndPoint(method), supplier, this.httpOptions).pipe(
			catchError(this.handleError(method, supplier))
		);
	}

	public emailPO(data){
		var method = 'purchasing/emailpo';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId + '&userid=' + this.user.user.userid + this.getPackage();
	}

	private getPackage() {
		let p = this.globalSearchService.getSessionItem('package');
		if(p) {
			return '&package='+p;
		} else {
			return '';
		}
	}

	public getKey() {
		return '&apikey=' + this.key;
	}

	private handleError < T > (operation = 'operation', result ? : T) {
		return (error: any): Observable < T > => {
			return error;
		};
	}

}
