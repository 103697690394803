<nav class="navbar bg-transparent m-0 p-0 noshadow">
	<ul class="navbar-nav bg-transparent ">
		<li class="nav-item no-borde noshadowr" *ngIf="user && locations && locations.length > 1">
			<form class="form-inline my-2 my-lg-0">
				<!-- (selectionChange)="changeLocation($event)"  -->
				&nbsp;&nbsp;&nbsp;
				<select [(value)]="selected" (change)="changeLocation($event.target)" class="select-dropdown" matTooltip="Change Location">
					<option [value]="loc.loccode" *ngFor="let loc of locations">{{ loc.locationname }}</option>
				</select>
			</form>
		</li>
	</ul>
</nav>
