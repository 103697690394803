<ng-container *ngIf="invoicing">
	<nav class="navbar navbar-expand navbar-light bg-light no-shadow nobox" @navIn>
		<button class="nav-item ml-2 mr-2 " mat-raised-button (click)="resetOrder()" color="warn">Reset</button>
	</nav>
	<app-credit-invoice [orderno]="invoicing" (reloadCustomer)="emitCustomerUpdate($event);" (invoiced)="showInvoiceResultandReset($event)" *ngIf="invoicing"> </app-credit-invoice>
</ng-container>
<ng-container *ngIf="!invoicing">
	<h4 class="text-center mt-3" *ngIf="order_details && !orderResults">Editing RMA#{{order_details.header.orderno}}</h4>
	<nav class="navbar navbar-expand navbar-light bg-light no-shadow nobox" *ngIf="!orderResults && cart_items.length" @addFilters>
		<button class="nav-item ml-2 mr-2 " mat-raised-button (click)="resetOrder()" *ngIf="cart_items.length" color="warn">Reset</button>
		<!-- <button class="nav-item ml-2 mr-2 " mat-raised-button  (click)="clearCart()" *ngIf="cart_items.length" color="warn">Clear Cart</button> -->
		<ul class="navbar-nav ml-auto mr-0">
			<li class="nav-item" *ngIf="cart_items.length">
				<button class="nav-item ml-2 mr-2 " mat-raised-button (click)="saveQuote()" *ngIf="type == '10'" color="secondary">Save Quote</button>
			</li>
			<li class="nav-item">
				<button class="nav-item ml-2 mr-2 " mat-raised-button (click)="toggleFlip()" color="primary">
					<span *ngIf="flip =='active'">Add items</span>
					<span *ngIf="flip =='inactive'">Review RMA</span>
				</button>
			</li>
			<li class="nav-item" *ngIf="cart_items.length">
				<button mat-raised-button (click)="placeOrder()" color="accent">
					<span *ngIf="!editing_order">Create</span>
					<span *ngIf="editing_order">Update</span>
					RMA</button>
			</li>
		</ul>
	</nav>
	<ng-container *ngIf="orderResults">
		<h4 class="mt-3 bold text-center ">RMA#: {{ orderResults.orderno }}
			<span [innerHTML]="modifed ? 'Updated' : 'Created'"></span>
		</h4>
		<nav class="navbar navbar-expand navbar-light no-shadow bg-light nobox">
			<button mat-raised-button color="accent" class="nav-item ml-auto mr-auto" (click)="editOrder(orderResults.orderno)">Edit RMA</button>
			<a class="nav-item ml-auto mr-auto" href="{{ baselink }}{{ orderResults.orderno }}" mat-raised-button color="accent">Print RMA</a>
			<a class="nav-item ml-auto mr-auto" mat-raised-button color="accent" [routerLink]="'/receivables/dispatch/'+ orderResults.orderno">Credit Account</a>
			<!-- <button mat-raised-button color="accent" class="nav-item ml-auto mr-auto" (click)="invoiceOrder(orderResults.orderno)">Credit Account</button> -->
			<!--<a class="nav-item ml-auto mr-auto" href="{{ pickingInvoiceLink }}{{ orderResults.orderno }}" mat-raised-button color="accent">Re-print Pick Credit</a>
			<a [routerLink]="'/receivables/dispatch/'+orderResults.orderno" mat-raised-button color="warn"  class="nav-item  ml-auto mr-auto">Credit Account</a>
			-->
			<!--<a [routerLink]="'/orders/pick/'+orderResults.orderno" mat-raised-button color="primary"  class="nav-item  ml-auto mr-auto">Pick</a>-->
			<button mat-raised-button color="primary" [routerLink]="'/receivables/credit-note'" class="nav-item  ml-auto mr-auto">New RMA</button>
		</nav>
		<app-order-entry-po [orderno]="orderResults.orderno"></app-order-entry-po>
	</ng-container>
	<ng-container *ngIf="!orderResults">
		<div class="container-fluid tp-box" [@flipState]="flip">
			<div class="tp-box__side tp-box__back" [ngClass]="flip == 'inactive' ? 'd-none' : ''">

				<app-credit-review [original_order]="original_order" [customer]="customer" [cart_items]="cart_items" [cart_totals]="cart_totals" (updateCart)="updateCartItem($event)" (removeItem)="removeFromOrder($event)" (updateCustomer)="updateCustomer($event)" (freight_charge)="updateShipping($event)"></app-credit-review>
			</div>
			<div id="frontside" class="row tp-box__side tp-box__front" [ngClass]="flip == 'active' ? 'd-none' : ''">
				<app-item-search [customer]="customer" (addItem)="addItems($event)" (updateCart)="updateCart()" [type]="11" class="w-100 no-overflow"></app-item-search>
			</div>
			<div class="row m-0 p-0 " *ngIf="!cart_items.length">
				<div class="col-md-4 ml-auto mr-0">
					<p @addFilters class="alert alert-info text-center"><b>No Items, Add items above and review order.</b></p>
				</div>
			</div>
		</div>
		<!---- CART NEEDS ABSTRACTED-->
		<div class="fixed-plugin">
			<div class="dropdown show-dropdown text-center cart-fixed" *ngIf="cart_items">
				<a href="#" data-toggle="dropdown" aria-expanded="false">
					<!-- <mat-icon>shopping_cart</mat-icon> 2x?-->
					<i class="ml-auto mr-auto fa fa-shopping-cart fa-1x text-white" [ngClass]="!proceed ? 'text-danger' : ''">
						({{ cart_items.length }})
					</i>
					<span class="text-white" *ngIf="total_cart_qty"><br><i class="fa fa-caret-left fa-1x text-white" aria-hidden="true"></i> ({{total_cart_qty}})</span>
					<span class="text-white"><br> {{ getTotal() | currency}}</span>
					<!--
						<button class="nav-item ml-auto mr-auto" mat-raised-button (click)="placeOrder()" color="accent" *ngIf="flip =='active'">
							<span *ngIf="!editing_order">Create</span>
							<span *ngIf="editing_order">Update</span>
						 </button>
					-->
				</a>
				<ul class="dropdown-menu cart-fixed-content" x-placement="bottom-start" id="rightcart" *ngIf="cart_items">
					<li class="header-title" [ngClass]="{'hasitems': cart_items.length }">Current Order
						<ng-container *ngIf="cart_items.length">
							<nav class="navbar navbar-expand navbar-light bg-light no-shadow nobox">
								<button class="nav-item ml-auto mr-auto " mat-raised-button (click)="saveQuote()" *ngIf="type == '10'" color="secondary">Save Quote</button>
								<button class="nav-item ml-auto mr-auto " mat-raised-button (click)="flip ='active'" color="primary" *ngIf="flip == 'inactive'">
									<span>Review RMA</span>
								</button>
								<button class="nav-item ml-auto mr-auto " mat-raised-button (click)="flip ='inactive'" color="primary" *ngIf="flip == 'active'">
									<span>Add Items</span>
								</button>
								<!-- <button class="nav-item ml-auto mr-auto" mat-raised-button (click)="placeOrder()" color="accent" *ngIf="flip =='active'">
									<span *ngIf="!editing_order">Create</span>
									<span *ngIf="editing_order">Update</span>
								 	Credit</button> -->
							</nav>
						</ng-container>
					</li>
					<ng-container>
						<div class="container text-right" id="rightcart-content">

							<ng-container *ngIf="cart_items.length" (click)="stopProp($event)">

								<div class="row mt-0 mb-0 pt-0 pb-0 border-top small-font" *ngFor="let item of cart_items" (click)="stopProp($event)" [ngClass]="{'parent': ((item.option != '') && (item.option != 'Component')) ? 'true' : '', 'component': ((item.option != '') && (item.option == 'Component')) ? 'true' : ''}">
									<div class="col-md-12 mt-0 pt-0 text-left">
										<!--<button mat-icon-button aria-label="Update Options" color="accent" (click)="viewOptions(item)" *ngIf="item.requiresconfig=='1'">
												<mat-icon class="smaller-font">settings</mat-icon>
											</button>
											<button mat-icon-button aria-label="Change Vendor" color="accent" (click)="viewPurchOrderOptions(item)" *ngIf="item.porequired =='1'">
												<mat-icon class="smaller-font">edit</mat-icon>
											</button>
											-->
										<!--<span *ngIf="item.po_options" color="accent">{{ item.po_options.supplierno }}&nbsp;</span>-->
										<b>{{item.stockid}}</b>&nbsp;&nbsp;<span class="bold">{{item.description}}<span *ngIf="item.option && item.option != 'Component'"><span class="parent-tag"> KIT </span></span></span>

									</div>
									<!--
										<div class="col-md-1 mt-1 p-0">
											<img src="{{ item.thumb }}" class="img img-responzive" lazy *ngIf="item.thumb" />
										</div>
										[ngClass]="{'col-md-5':!config.discounts,'col-md-3':config.discounts, 'col-md-5':item.mbflag == 'F'}"
										-->
									<div class="text-center col-md-3">
										<!-- <div class="flex-row h-30"> -->
										<div *ngIf="item.option && item.option == 'Component'">
											<div class="flex-row">
												<mat-icon class="h-19">segment</mat-icon><span class="component-tag">component</span>
											</div>
										</div>
										<div [ngClass]="{ 'bg-warning': item.porequired == '1', 'bg-success': item.porequired == '0' }" *ngIf="!item.parent_id && config.pofromorder">
											<p class="text-light mt-3" *ngIf="item.porequired == '0'"> Available</p>
											<p class="text-dark mt-3" *ngIf="item.porequired == '1'"> PO Required</p>
										</div>
										<!-- </div> -->
									</div>
									<div class="col-md-2 text-right" *ngIf="config.discounts && item.mbflag != 'F'">
										<mat-form-field appearance="standard" class="text-right">
											<mat-label>Discount</mat-label>
											<input matInput type="text" class="text-right" placeholder="Discount" [value]="item.discount" (input)="updateDiscount($event,item)">
											<span matSuffix>%</span>
										</mat-form-field>
									</div>
									<div class="col-md-4 m-0 p-0 ">
										<mat-form-field appearance="standard" class="m-0 p-0 text-right">
											<mat-label>Price</mat-label>
											<span matPrefix>$</span>
											<input matInput type="text" class="text-right" placeholder="Price" [value]="item.price" (input)="updatePrice($event,item)">
											<mat-hint *ngIf="config.discounts && item.discount > 0" class="text-right text-danger bolder">
												Discount Price {{ getDiscounPrice(item) | currency }} @ {{ item.discount }}%
											</mat-hint>
										</mat-form-field>
									</div>
									<div class="col-md-2 mt-0 mb-0 pt-0 pb-0 text-right">
										<mat-form-field appearance="standard" class="m-0 p-0 text-center">
											<mat-label>Qty</mat-label>
											<span matPrefix>#</span>
											<input matInput type="text" placeholder="QTY" class="text-center" [value]="item.quantity" (input)="updateQuantity($event, item)">
										</mat-form-field>
									</div>
									<div class="col-1 m-0 p-0 pt-2 text-right">
										<button mat-icon-button color="warn" aria-label="remove" (click)="removeFromOrder(item.cart_id)">
											<mat-icon>remove</mat-icon>
										</button>
									</div>
								</div>
								<div class="col-12 p-0 m-0 text-right" *ngFor="let total of cart_totals">
									<b>{{total.title }}</b>: {{ total.text | currency }}
								</div>
							</ng-container>
						</div>
					</ng-container>
				</ul>
			</div>
		</div>
	</ng-container>
</ng-container>
