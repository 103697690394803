<ng-template #orderView let-modal>
	<div class="modal-header">
		<div class="modal-title row m-0 p-0" id="modal-title">
			<h4>Order# {{orderdetails.header.orderno}}</h4>
		</div>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="orderdetails">
			<div class="row border-bottom">
				<div class="col-md-3 text-center">
					<h5>Ship Via: {{ orderdetails.header.shippername }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Date Wanted: {{ orderdetails.header.datewanted }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Entered By: {{ orderdetails.header.enteredby }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Created: {{ orderdetails.header.date_created }}</h5>
				</div>
			</div>
			<div class="row mt-2 mb-2 border-bottom">
				<div class="col-md-3 " >
					<h5 class="mb-0">Ship To:</h5>
					<span [innerHTML]="orderdetails.header.shipto"></span>
				</div>
				<div class="col-md-3" >
					<h5 class="mb-0">Phone:</h5>
					{{orderdetails.header.contactphone}}
					<ng-container *ngIf="orderdetails.header.contactemail && orderdetails.header.contactemail != ''">
					<h5 class="mb-0">Email:</h5>
						{{orderdetails.header.contactemail}}
					</ng-container>
				</div>
				<div class="col-md-3" >
					<h5>Notes:</h5>
					{{orderdetails.header.comments}}
				</div>
				<div class="col-md-3" >
				<h5>History: </h5>
					<ng-container *ngIf="orderdetails.dispatch_details.dispatch_id">
						<ul>
						<li *ngIf="orderdetails.dispatch_details.truck_name">ShipVia: {{ orderdetails.dispatch_details.truck_name }}</li>
						<li *ngIf="orderdetails.dispatch_details.datecreated !='0000-00-00 00:00:00'">Dispatched:
							{{ orderdetails.dispatch_details.datecreated | date:'shortDate' }}
							{{ orderdetails.dispatch_details.datecreated | date:'shortTime' }}

						</li>
						<li *ngIf="orderdetails.dispatch_details.delivery_date !='0000-00-00 00:00:00'">Delivered:
							{{ orderdetails.dispatch_details.delivery_date | date:'shortDate' }}
							{{ orderdetails.dispatch_details.delivery_date | date:'shortTime' }}
							</li>
						</ul>
					</ng-container>
					<ng-container *ngIf="!orderdetails.dispatch_details.dispatch_id">
						N/A
					</ng-container>
				</div>
			</div>
			<div class="table">
				<table class="table table-sm">
					<tr>
						<th>ITEM</th>
						<th>DESC</th>
						<th class="text-center">ORDERED</th>
						<th class="text-center">INVOICED</th>
						<th class="text-center">OUTSTANDING</th>
						<th class="text-right">PRICE</th>
						<th class="text-right">DISCOUNT</th>
						<th class="text-right">TOTAL</th>
					</tr>
					<tr *ngFor="let item of orderdetails.details">

						<td>{{item.stkcode}}</td>
						<td>{{item.description}}</td>
						<td class="text-center">{{item.ordered}}</td>
						<td class="text-center">{{item.qtyinvoiced}}</td>
						<td class="text-center">{{item.ordered - item.qtyinvoiced}}</td>
						<td class="text-right">{{item.unitprice | currency}}</td>
						<td class="text-right">{{item.discountpercent | percent }}</td>
						<td class="text-right">{{item.discountprice * item.ordered | currency}}</td>
					</tr>
					<tr *ngFor="let t of orderdetails.saved_totals">
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center"></td>
						<td class="text-center"></td>
						<td class="text-center"></td>
						<td class="text-right">{{ t.title }}:</td>
						<td class="text-right">{{t.text | currency}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Order Inquiry
						</h4>
						<p class="card-category text-right">Search For an Order/Invoice</p>
					</div>
					<div class="card-body">
						<form class="navbar-form">
							<div class="row">
								<!-- <div class="col-6">
									<mat-form-field appearance="standard" class="">
										<span matPrefix *ngIf="orderSearch.length">
											<button  mat-icon-button class="p-0" (click)="clearForm()">
												<mat-icon class="p-0" >clear</mat-icon>
											</button>
										</span>

										<input matInput class="text-right mt-0 mb-0 pt-0 pb-0 " value="" [formControl]="orderSearchRef" placeholder="Search For an Order/Invoice..." (keydown.enter)="$event.preventDefault();searchOrders()">
										<span matSuffix>
											<button mat-icon-button color="white" type="submit" (click)="searchOrders()">
												<mat-icon class="p-0">search</mat-icon>
											</button>
										</span>
										<div class="ripple-container"></div>
									</mat-form-field>
								</div> -->
								<div class="col-12">

									<mat-form-field appearance="standard" class="">
										<span matPrefix *ngIf="orderSearch.length">
											<button  mat-icon-button class="p-0" (click)="clearForm()">
												<mat-icon class="p-0" >clear</mat-icon>
											</button>
										</span>

										<input matInput class="text-right mt-0 mb-0 pt-0 pb-0 " value="" [formControl]="orderSearchRef" placeholder="Search For an Order/Invoice..." (keydown.enter)="$event.preventDefault();searchOrders()">
										<span matSuffix>
											<button mat-icon-button color="white" type="submit" (click)="searchOrders()">
												<mat-icon class="p-0">search</mat-icon>
											</button>
										</span>
										<div class="ripple-container"></div>
									</mat-form-field>
								</div>
							</div>

						</form>
						<table class="table" *ngIf="orderResults">
							<ng-container *ngFor="let o of orderResults">
								<tr>
									<th>Order#</th>
									<th>Created</th>
									<th>Customer#</th>
									<th>Deliver To</th>
									<th>Updated</th>
									<th>Status</th>
									<th>Entered By</th>
									<th>Edited by</th>
									<th>Dispatch</th>
									<th>Delivered</th>
									<th>Tax</th>
									<th>SubTotal</th>
									<th>Total</th>
								</tr>
								<tr >
									<td>
										<!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
											<mat-icon>more_vert</mat-icon>
										</button>
										<mat-menu #menu="matMenu">
											<button mat-menu-item (click)="viewOrder(o.orderno)">
												<mat-icon>visibility</mat-icon>
												<span>View</span>
											</button>

											<a href="{{invoicelink}}{{o.orderno}}" mat-menu-item>
												<mat-icon>print</mat-icon>
												<span>Customer Copy</span>
											</a>
											<mat-divider></mat-divider>

											<a href="{{pickinglink}}{{o.orderno}}" mat-menu-item>
												<mat-icon>print</mat-icon>
												<span>Picking PDF</span>
											</a>
										</mat-menu> -->
									{{ o.orderno }}
								</td>
									<td>{{ o.date_created | date:"short" }}</td>
									<td><a class="link" (click)="viewCustomer(o.debtorno)">{{ o.debtorno }}.{{ o.branchcode }}</a></td>
									<td>{{ o.deliverto }}</td>
									<td>{{ o.date_updated | date:"short" }}
									<td class="status-{{ o.orderstatusid }}">{{ o.statusname }}</td>
									<td>{{ o.enteredby }}</td>
									<td>{{ o.takenby }}</td>
									<td>
										<span *ngIf="o.dispatch">
											{{ o.dispatch.driveruserid }}
											{{ o.dispatch.datecreated | date:"short"}}
										</span>
										<span *ngIf="!o.dispatch">
											N/A
										</span>
									</td>
									<td>
										<span *ngIf="o.dispatch">
											{{ o.dispatch.delivery_date | date:"short"}}
										</span>
										<span *ngIf="!o.dispatch">
											N/A
										</span>
									</td>


									<td>{{ getValueByType(o.payment_details, 'tax') | currency }}</td>

									<td>{{ getValueByType(o.payment_details, 'sub_total') | currency}}</td>

									<td>{{ getValueByType(o.payment_details, 'total')| currency }}</td>

								</tr>
								<tr>
									<th>Item</th>
									<th colspan="8">Desc</th>
									<th>Price</th>
									<th>Ordered</th>
									<th>Invoiced</th>
									<th>Net</th>
								</tr>
								<tr *ngFor="let det of o.details">
									<td>{{ det.stkcode }}</td>
									<td colspan="8">{{ det.itemdesc}}</td>
									<td>{{ det.unitprice | currency }}</td>
									<td>{{ det.quantity }}</td>
									<td>{{ det.qtyinvoiced  }}</td>
									<td>{{ det.qtyinvoiced * det.unitprice | currency }}</td>
								</tr>
							</ng-container>

						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
