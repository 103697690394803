import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpClientModule, HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { CartProduct, Product } from '../classes/orders';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';

@Injectable({
	providedIn: 'root'
})

export class CreditService {

	key: string = 'UET6DFMPAXW7BKCB';
	items: Product[] = [];
	config: any;
	user: any = [];
	package: any = '';

	constructor(private http: HttpClient, private _configSvc: RuntimeConfigLoaderService, public globalSearchService: GlobalSearchService) {

		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		})
		this.globalSearchService.user.subscribe((result: any) => {
			this.user = result;
		});
	}

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	public getDeliveredOrders() {
		var method = 'credits/delivered';
		return this.http.post(this.setEndPoint(method), method, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getRefused() {
		var method = 'oms/dashboard/refused';
		return this.http.post(this.setEndPoint(method), method, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public unflagCart(debtorno: any, branchcode: any) {
		var method = 'credits/cart/unflagcredit&debtorno=' + debtorno + '&branchcode=' + branchcode;
		return this.http.post(this.setEndPoint(method), method, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public flagCart(debtorno: any, branchcode: any) {
		var method = 'credits/cart/flagcredit&debtorno=' + debtorno + '&branchcode=' + branchcode;
		return this.http.post(this.setEndPoint(method), method, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public changeOrderCustomer(customer: any) {
		var method = 'credits/cart/changecustomer';
		return this.http.post(this.setEndPoint(method), customer, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public setSalesOrderPoOptions(data: any) {
		var method = 'credits/cart/updatesalespoitem';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public setDelivered(data: any) {
		var method = 'oms/picking/setdelivered';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public updateOrderToPicked(data: any) {
		var method = 'oms/picking/updatepicked';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public setPoOptions(data: any) {
		var method = 'credits/cart/updatepoitem';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getPurchDataFromLine(data: any) {
		var method = 'credits/cart/poitem';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getPoRequired(data: any) {
		var method = 'credits/cart/poitems';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getItemSearch(data: any) {
		var method = 'credits/items/search';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getSingleItemSearch(data: any) {
		var method = 'credits/items/searchsingle';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getItemFilter(data: any) {
		var method = 'credits/items/search&filter=true';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getCustomer(customerid: any, branchcode: any) {
		var method = 'customers/orderentry&debtorno=' + customerid + '&branchcode=' + branchcode;
		return this.http.get(this.setEndPoint(method));
	}


	public loadCreditFromSalesOrder(orderno: any) {
		var method = 'credits/loadsalesorder&oid=' + orderno;
		return this.http.get(this.setEndPoint(method));
	}

	public loadRma(orderno: any) {
		var method = 'credits/loadorder&oid=' + orderno;
		return this.http.get(this.setEndPoint(method));
	}

	public loadSalesOrder(orderno: any) {
		var method = 'credits/loadsalesorder&oid=' + orderno;
		return this.http.get(this.setEndPoint(method));
	}

	public cancelQuote(orderno: any) {
		var method = 'credits/quotes/cancel';
		return this.http.post(this.setEndPoint(method), { orderno: orderno }, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}
	public cancelOrder(orderno: any, ) {
		var method = 'credits/cancel';
		return this.http.post(this.setEndPoint(method), { orderno: orderno }, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getTransactionById(id: any) {
		var method = 'credits/gettransaction&id=' + id;
		return this.http.get(this.setEndPoint(method));

	}
	public getQuotes(debtorno: any, branchcode: any) {
		var method = 'credits/getquotes&debtorno=' + debtorno + '&branchcode=' + branchcode;
		return this.http.get(this.setEndPoint(method));
	}


	public saveQuote(customer: any, items: any, totals: any, user: any) {
		var method = 'credits/savequote';
		return this.http.post(this.setEndPoint(method), { customer: customer, items: items }, this.httpOptions).pipe(
			catchError(this.handleError(method, customer))
		);
	}

	public getPick(orderno: any) {
		let url = 'https://cleve.qbcsys.com/PDFPickingListSinglePortrait.php?TransNo=2135133&pre=1&se=1';
	}

	public downloadReport(): Observable < any > {
		// Create url
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
			}),
		}

		let url = 'https://cleve.qbcsys.com/?route=salesman/documents/picking&key=9NPZ5AJ4FNNT9C7Y&id=2135137';
		var body = { orderno: 2135133 };

		return this.http.post(url, body, {
			responseType: "blob",
			headers: new HttpHeaders().append("Content-Type", "application/json")
		});
	}

	public getOrders() {
		var method = 'orders';
		return this.http.get(this.setEndPoint(method));
	}
	public getOrderSearch(keywords: any) {
		var method = 'credits/search&keywords=' + keywords;
		return this.http.get(this.setEndPoint(method));
	}

	public getCustomerOpenOrders(debtorno: any) {
		var method = 'credits&debtorno=' + debtorno;
		return this.http.get(this.setEndPoint(method));
	}

	public getCustomerOpenOrdersByItem(item: any) {
		var method = 'credits&stockid=' + item;
		return this.http.get(this.setEndPoint(method));
	}

	public getTotals(customer: any, branchcode: any, freight: any = 0.00) {
		var method = 'credits/cart/gettotals';
		return this.http.post(this.setEndPoint(method), { debtorno: customer, branchcode: branchcode, freight: freight }, this.httpOptions).pipe(
			catchError(this.handleError(method, customer))
		);
	}

	public getDispatches() {
		var method = 'credits/dispatches';
		return this.http.get(this.setEndPoint(method));
	}

	public getOrderInvoice(oid) {
		var method = 'credits/open/invoice&oid=' + oid;
		return this.http.get(this.setEndPoint(method));
	}

	public getOrder(oid) {
		var method = 'credits/open&oid=' + oid;
		return this.http.get(this.setEndPoint(method));
	}

	public setOrderItems(items) {
		this.items = items;
	}

	public addMultipleToOrder(products: any, customer: string, branchcode: string) {
		var method = 'credits/cart/addmultiple';
		return this.http.post(this.setEndPoint(method), { items: products, customer: customer, branchcode: branchcode }, this.httpOptions).pipe(
			catchError(this.handleError(method, products))
		);
	}


	public addNonStock(data: any) {
		var method = 'credits/cart/addnonstock';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public addToOrder(product: any, customer: string, branchcode: string) {
		this.items.push(product);
		var method = 'credits/cart/add';
		return this.http.post(this.setEndPoint(method), { item: product, customer: customer, branchcode: branchcode }, this.httpOptions).pipe(
			catchError(this.handleError(method, product))
		);
	}


	public updateOrder(product) {
		var method = 'credits/cart/update';
		return this.http.post(this.setEndPoint(method), product, this.httpOptions).pipe(
			catchError(this.handleError(method, product))
		);
	}

	public clearOrder(customerno: any, branchcode: any) {
		this.items = [];
		var method = 'credits/cart/clear';
		return this.http.post(this.setEndPoint(method), { customerno: customerno, branchcode: branchcode }, this.httpOptions).pipe(
			catchError(this.handleError(method, customerno))
		);
	}

	public createInvoice(order: any) {
		var method = 'credits/invoice';
		return this.http.post(this.setEndPoint(method), order, this.httpOptions).pipe(
			catchError(this.handleError(method, order))
		);
	}

	public updateSalesOrder(customer: any, items: any, totals: any, order: any, user: any) {
		var method = 'credits/cart/updateorder';
		return this.http.post(this.setEndPoint(method), { customer: customer, items: items, totals: totals, order: order, user: user }, this.httpOptions).pipe(
			catchError(this.handleError(method, customer))
		);
	}

	public createOrder(customer: any, items: any, totals: any, user: any) {
		var method = 'credits/cart/complete';
		return this.http.post(this.setEndPoint(method), { customer: customer, items: items, totals: totals, user: user }, this.httpOptions).pipe(
			catchError(this.handleError(method, customer))
		);
	}

	public removeFromOrder(cart_id, customer, branchcode: any) {
		var method = 'credits/cart/remove';
		return this.http.post(this.setEndPoint(method), { cart_id: cart_id, customer: customer, branchcode: branchcode }, this.httpOptions).pipe(
			catchError(this.handleError(method, cart_id))
		);
	}

	public getRunPick(column: any) {
		var method = 'oms/runpick&column=' + column;
		return this.http.get(this.setEndPoint(method));
	}

	public getCards() {
		var method = 'oms/cards';
		return this.http.get(this.setEndPoint(method));
	}

	public getPicks() {
		var method = 'oms/picking';
		return this.http.get(this.setEndPoint(method));
	}

	public getOrderItems(search, branchcode: any) {
		var method = 'credits/cart';
		this.getCart(search, branchcode).subscribe((results: Product[]) => {
			this.items = results;
			return results
		});;

		return this.items;
	}

	public getCart(search: any, branchcode: any) {
		var method = 'credits/cart';
		return this.http.post(this.setEndPoint(method), { customerno: search, branchcode: branchcode }, this.httpOptions).pipe(
			catchError(this.handleError(method, search))
		);

	}

	public getStockProducts(search) {
		var method = 'inventory&stock=true';
		return this.http.post(this.setEndPoint(method), search, this.httpOptions).pipe(
			catchError(this.handleError(method, search))
		);
	}

	public getProducts(search) {
		var method = 'inventory';
		return this.http.post(this.setEndPoint(method), search, this.httpOptions).pipe(
			catchError(this.handleError(method, search))
		);
	}


	public getOrderList(oid) {
		var method = 'credits/getdetails&oid=' + oid;
		return this.http.get(this.setEndPoint(method));
	}

	public getPicking() {
		var method = 'oms/fillable';
		return this.http.get(this.setEndPoint(method));
	}

	public setPicking(data) {
		var method = 'credits/pick';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getADRS() {
		var method = 'credits/adrs/pending';
		return this.http.get(this.setEndPoint(method));
	}
	public getADRSReject() {
		var method = 'credits/adrs/rejected';
		return this.http.get(this.setEndPoint(method));
	}
	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId + '&userid=' + this.user.user.userid + this.getPackage();
	}

	private getPackage() {
		let p = this.globalSearchService.getSessionItem('package');
		if(p) {
			return '&package='+p;
		} else {
			return '';
		}
	}

	public getKey() {
		return '&apikey=' + this.key;
	}

	private handleError < T > (operation = 'operation', result ? : T) {
		return (error: any): Observable < T > => {
			return error;
		};
	}

}
