<div>
    <ng-container *ngIf="content">
        <form [formGroup]="itemForm" *ngIf="itemForm">
            <div class="row p-0 m-0">
                <div class="col-md-3">
                    <mat-form-field appearance="standard">
                        <mat-label>Warehouse</mat-label>
                        <mat-select formControlName="loccode">
                            <mat-option *ngFor="let loc of locations" [value]="loc.loccode">
                                {{ loc.locationname }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field appearance="standard">
                        <mat-label>From</mat-label>
                        <input matInput [matDatepicker]="fromdatepicker" formControlName="fromdate">
                        <mat-datepicker-toggle matSuffix [for]="fromdatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #fromdatepicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field appearance="standard">
                        <mat-label>To</mat-label>
                        <input matInput [matDatepicker]="todatepicker" formControlName="todate">
                        <mat-datepicker-toggle matSuffix [for]="todatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #todatepicker></mat-datepicker>
                    </mat-form-field>
                </div>
                   <div class="col-md-2 m-auto ">
                    All Picks&nbsp;&nbsp;<mat-slide-toggle formControlName="option"></mat-slide-toggle>&nbsp;&nbsp;Completed Picks
                </div>

                <div class="col-md-1 text-right mt-3">
                    <div *ngIf="sending">
                        <svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                            </circle>
                        </svg>
                    </div>
                    <button mat-icon-button (click)="getPickHistory()" *ngIf="!sending"><mat-icon>refresh</mat-icon></button>
                </div>
            </div>

            <table class="table table-sm table-fixed " *ngIf="results.length">
				<thead>
					<tr>
                        <th class="t-50 text-left">Cust#</th>
						<th class="t-50 text-left" (click)="toggleErrorHighlight()">Document#</th>
                        <th class="t-50 text-left">Trans#</th>


						<th class="t-50 text-left">Order#</th>
                        <th class="text-left">Pick ID#</th>
						<th class="t-50 text-center">Date Created</th>
                        <th class="text-left">Bin#</th>

                        <th class="text-center t-25" *ngIf="errorHighlight">QOH</th>
                        <th class="text-center t-25">Ordered</th>
						<th class="text-center t-25">Pick Total</th>
                        <th class="text-center t-25">Picked</th>

                        <th class="t-50 text-center">User ID</th>
                        <th class="t-50 text-center">Claimed By</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let r of results" [ngClass]="r.pick == r.picked ? 'valid' : errorHighlight == true ? 'invalid' : 'valid'">
						<td class="text-left">{{ r.debtorno }}.{{r.branchcode}}</td>
                        <td class="text-left">{{r.document}}</td>
                        <td class="text-left">{{r.transno}}</td>


                        <td class="t-50 text-left">
							<a *ngIf="r.type == '10' || r.type == '11' || r.type == '25'" (click)="openDocument(r,r.type,'html')">
								{{ r.orderno }}
							</a>
							<span *ngIf="r.type != '10' && r.type !='11' && r.type !='25' ">
								{{ r.orderno }}
							</span>
						</td>
                        <td class="text-left">{{ r.pick_id }}</td>
						<td class="t-50 text-center">{{ r.date_created }}</td>
                        <td class="text-left">{{ r.bin }}</td>


						<td class="text-center t-25" *ngIf="errorHighlight">{{ r.qoh }}</td>
                        <td class="text-center t-25">{{ r.ordered }}</td>
						<td class="text-center t-25">{{ r.pick }}</td>
						<td class="text-center t-25">{{ r.picked }}</td>

                        <td class="t-50 text-center" *ngIf="r.userid != ''">{{ r.userid }}</td>
                        <td class="t-50 text-center" *ngIf="r.userid == ''">SYSTEM</td>
                        <td class="text-center t-25" *ngIf="r.claimed_user != ''">{{ r.claimed_user }} @ {{r.claimed_date}}</td>
                        <td class="text-center t-25" *ngIf="r.claimed_user == ''">-</td>
					</tr>
				</tbody>
			</table>
            <h5 *ngIf="!results.length && !sending" class="text-center">
                <b>No Pick History Found</b>
            </h5>
        </form>
    </ng-container>
</div>