<div @grow class="list-group  list-group-flush border-1">
	<div class="row">
		<div class="col-md-6 text-left">
			<button mat-raised-button color="accent" (click)="toggleAddContact()">Add Contact</button>
		</div>
		<div class="col-md-6 text-right">
			<button mat-raised-button (click)="close()" >Close</button>
		</div>
	</div>

	<ng-container *ngIf="editingcontact">
		<form [formGroup]="contactForm" *ngIf="contactForm">
			<div class="row mt-2">
				<div class="col-md-6">
					<mat-form-field appearance="standard" >
						<mat-label>Name</mat-label>
						<input matInput formControlName="name" placeholder="Contact Name">
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<mat-form-field class="full-width"  appearance="standard">
						<mat-label>Phone</mat-label>
						<!--mask="(000) 000-0000" value="{{contact.phone | phoneFormat}}"-->
						<input matInput formControlName="phone"  placeholder="Contact Phone"  mask="(000) 000-0000" [showMaskTyped]="true" >
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<mat-form-field class="full-width" appearance="standard" >
						<mat-label>Email</mat-label>
						<input matInput formControlName="email" placeholder="Contact Email" >
						<mat-hint *ngIf="emailwarn"> Provide a Valid Email</mat-hint>
					</mat-form-field>
				</div>
				<div class="col-md-6">

					<mat-form-field appearance="standard">
						<mat-label>Roles</mat-label>
						<mat-select formControlName="role" multiple>
							<mat-option *ngFor="let loc of contacttypes" [value]="loc.contact_role">
								{{loc.contact_type}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<!-- <mat-form-field class="full-width" appearance="standard" >
						<mat-label>Role</mat-label>
						<input matInput required formControlName="role" placeholder="Contact Role" >
					</mat-form-field> -->
				</div>
			</div>

			<button mat-raised-button color="primary" (click)="addContact()">Save  Contact</button>
		</form>
	</ng-container>

	<div class="table mt-2" *ngIf="!editingcontact">
		<table class="table table-fixed table-sm">
				<thead>
					<tr>
						<th></th>
						<th></th>
						<th>Name</th>
						<th>Email</th>
						<th>Phone</th>
						<th>Role</th>
					</tr>
				</thead>
				<tbody>
					<ng-container *ngIf="customer.contacts.length">
					<tr *ngFor="let contact of customer.contacts">
						<td><mat-icon (click)="editContact(contact)">edit</mat-icon></td>
						<td><mat-icon matSuffix (click)="removeContact(contact.id)">remove</mat-icon></td>
						<td>{{contact.name}}</td>
						<td>{{contact.email}}</td>
						<td>{{contact.phone | phoneFormat}}</td>
						<td>{{contact.role}}</td>
					</tr>
					</ng-container>
					<ng-container *ngIf="!customer.contacts.length">
						<tr>
							<td colspan="6" class="text-center">
								<h4>No Contacts</h4>
							</td>
						</tr>
					</ng-container>
				</tbody>
		</table>
	</div>


</div>
