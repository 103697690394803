import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { PrintService } from '../../services/print.service'


@Component({
	selector: 'app-negative-inventory',
	templateUrl: './negative-inventory.component.html',
	styleUrls: ['./negative-inventory.component.scss']
})
export class NegativeInventoryComponent implements OnInit {
	@Input() data: any = [];
	filename: string = 'negative_items';
	@ViewChild('print_table') printtable: ElementRef;

	constructor(private globalSearchService: GlobalSearchService, private printService: PrintService) {}

	ngOnInit(): void {

	}

	exportPdf() {

		let encoded: string = btoa(this.printtable.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}
	exportXls() {
		let encoded: string = btoa(this.printtable.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

}
