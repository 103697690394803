<div class="row">
	<div class="col-md-4 mt-4">
		<h5 class="bold">Select A Bin</h5>
		<mat-form-field class="col-md-12 text-right" appearance="standard" *ngIf="bins">
		  	<mat-label>Bin Search</mat-label>
		  	<input type="text"
				 	aria-label="Item"
				 	matInput
					formControlNane="binSearch"
				 	(input)="searchBin($event)">
		</mat-form-field>
		<mat-divider></mat-divider>
		<mat-action-list class="borderd bin-box mt-2" dense>
	  		<button mat-list-item (click)="selectBin(bin)" class="mat-list-item-bordered" *ngFor="let bin of filteredbins">
				<span *ngIf="bin.location == ''"><b>NO BIN</b> ({{ bin.count }}) <b>w/QOH</b> ({{ bin.active_items }})</span>
				<span *ngIf="bin.location != ''"><b>{{ bin.location }}</b>  <b>#</b> ({{ bin.count }}) <b>w/QOH</b> ({{ bin.active_items }})</span>

				<span class="ml-auto mr-0"><small> <mat-icon color="accent" class="small bold">visibility</mat-icon></small></span>
		  	</button>
		</mat-action-list>
	</div>
	<div class="col-md-4 mt-4">
		<h5 class="bold">{{selectedbin.location }} Bin Items
		 <mat-slide-toggle
			  class="example-margin"
			  color="accent"
			  [checked]="onhandonly"
			  (change)="updateItems($event)"
			  >
			QOH > 0?
		</mat-slide-toggle>
		</h5>
		<mat-form-field class="col-md-12 text-right" appearance="standard" *ngIf="bins">
			  <mat-label>Item Search</mat-label>
			  <input type="text"
					 aria-label="Item"
					 matInput
					 formControlNane="itemSearch"
					 (input)="searchItems($event)">

			<mat-hint *ngIf="filtered_items.length"><b>ITEMS</b> ({{filtered_items.length}})</mat-hint>
		</mat-form-field>
		<mat-divider></mat-divider>
		<mat-list dense class="bin-box mt-2">
			<mat-list-item *ngIf="loadingbin" class="mat-list-item-bordered text-center">
				 <div class="ml-auto mr-auto">
					 <svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
					 </svg>
				 </div>
			 </mat-list-item>
		 	<mat-list-item class="mat-list-item-bordered" *ngFor="let item of filtered_items" (click)="addSelectedItem(item)">
				 <p matLine ><b>{{ item.stockid }}</b> {{ item.bin }} ({{ item.qoh }})</p>
				 <p matLine >{{ item.description }} </p>
				 <span class="ml-auto mr-0"><small> <mat-icon color="warning" class="small bold">add</mat-icon></small></span>
			 </mat-list-item>
			 <mat-list-item class="text-warning bold" *ngIf="!filtered_items.length && !loadingbin">Select A Bin</mat-list-item>
		 </mat-list>

	</div>
	<div class="col-md-4 mt-4">

		<h5 class="bold" [ngClass]="transferBin.valid ? '' : 'text-danger'">Transfer Items ({{tobin.length}})</h5>
		<mat-form-field appearance="standard">
			<mat-label>Transfer To Bin</mat-label>
			<input type="text"
				placeholder="Transfer To Bin"
				aria-label="Transfer To Bin"
				matInput
				required
				[formControl]="transferBin"
				[matAutocomplete]="transferbin"
				getOrderDetails>
			<mat-autocomplete autoActiveFirstOption #transferbin="matAutocomplete">
				<mat-option *ngFor="let bin of filteredOptions" [value]="bin.location">
					{{bin.location }}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
		<mat-divider></mat-divider>
		<mat-list dense class="bin-box mt-2"  *ngIf="tobin.length">
			 <mat-list-item class="mat-list-item-bordered"  *ngFor="let item of tobin" (click)="removeSelectedItem(item)">
				 <p matLine ><b>{{ item.stockid }}</b>  {{ item.bin }} ({{ item.qoh }})  </p>
				 <p matLine >{{ item.description }} </p>
				 <span class="ml-auto mr-0"><small> <mat-icon color="accent" class="small bold">remove</mat-icon></small></span>
			 </mat-list-item>
		 </mat-list>
		 <div class="col-12 notification text-right">
			 <h6 class="mt-2 mb-2" *ngIf="!tobin.length || transferBin.value ==''">*Issues</h6>
			 <mat-list dense class="bin-box mt-2" *ngIf="!tobin.length || transferBin.value ==''">
				 <mat-list-item *ngIf="!tobin.length" class="mat-list-item-bordered text-danger bold"><mat-icon>error</mat-icon> Select Items To Move</mat-list-item>
				 <mat-list-item *ngIf="transferBin.value ==''"  class="mat-list-item-bordered text-danger bold"><mat-icon>error</mat-icon> Choose/Create a Transfer Bin</mat-list-item>
			 </mat-list>
			 <h6 class="mt-2 mb-2 bold">{{ neworcurrent }}</h6>
			 <div *ngIf="!sending">
				 <button *ngIf="tobin.length && transferBin.value !='' " mat-raised-button color="warn" class="mt-3 mb-3"(click)="moveItems()">Move Items</button>
			 </div>
			 <div *ngIf="sending">
				 <svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				 </svg>
			 </div>
			 <div *ngIf="affected">
				 <h6 class="mt-2 mb-2">Affected</h6>
				 <div class="row" *ngFor="let item of affected">
					 <div class="col-12"> {{ item.stockid }} </div>
				 </div>
			 </div>

		 </div>
	</div>
</div>
