import {Directive, HostListener} from '@angular/core';
@Directive({
  selector: '[appNoPlusMinus]'
})
export class NoPlusMinusDirective {
	unacceptedCharacters: string[] = ['+','-'];

constructor() { }

@HostListener('keydown', ['$event'])
	onKeyDown(event: any) {
	if (this.unacceptedCharacters.includes(event.key)) {
		event.preventDefault();
	}
}

}
