<form [formGroup]="itemForm" (ngSubmit)="updateItem()" *ngIf="itemForm">
	<div class="row">
		<div class="col-md-12">

			<div class="card border">
				<div class="card-header card-header-warning card-header-icon">
					<div class="card-icon">
						<i class="material-icons">info</i>
					</div>
					<h4 class="card-title bold">
						Item Details
					</h4>
					<div class="card-title col-12 text-right">
						<button mat-raised-button color="accent">Next</button>
					</div>
					<div class="card border">
						<div class="card-body text-dark">
							<div class="row">
								<div class="col-md-3">
									<mat-form-field appearance="outline" class="">
										<mat-label>Category</mat-label>
										<mat-select required formControlName="categoryid">
											<mat-option *ngFor="let k of properties.categories" [value]="k.value">
												{{ k.name }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-3">
									<mat-form-field appearance="outline" class="">
										<mat-label>Product Line</mat-label>
										<mat-select required formControlName="lineid" >
											<mat-option *ngFor="let k of properties.productlines" [value]="k.value">
												{{ k.name }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-3">
									<mat-form-field appearance="outline">
										<mat-label>MFGPart# </mat-label>
										<input matInput required value="" formControlName="mfgpart">
									</mat-form-field>
								</div>
								<div class="col-md-3">
									<mat-form-field appearance="outline">
										<mat-label>Item Code</mat-label>
										<input matInput #stockid required value="" formControlName="stockid" maxlength="20">
										<mat-hint *ngIf="stockid_exists" class="text-danger">&nbsp;Exists&nbsp;</mat-hint>
										<mat-hint> {{ stockid.value.length }} / 20 </mat-hint>
									</mat-form-field>
								</div>
								<mat-divider></mat-divider>
								<hr />
								<div class="col-md-4">
									<mat-form-field appearance="standard">
										<mat-label>Description</mat-label>
										<input matInput required value="" formControlName="description">
									</mat-form-field>
								</div>
								<div class="col-md-4">
									<mat-form-field appearance="standard">
										<mat-label>Long Description</mat-label>
										<input matInput required value="" formControlName="longdescription">
									</mat-form-field>
								</div>
								<div class="col-md-4">
									<mat-form-field appearance="standard">
										<mat-label>Barcode </mat-label>
										<input matInput required value="" formControlName="barcode">
									</mat-form-field>
								</div>
								<div class="col-md-4">
									<mat-form-field appearance="standard" class="">
										<mat-label>Tax Category</mat-label>
										<mat-select required formControlName="taxcatid">
											<mat-option *ngFor="let k of properties.tax_categories" [value]="k.value">
												{{ k.name }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-4">
									<mat-form-field appearance="standard" class="">
										<mat-label>Product Type</mat-label>
										<mat-select required formControlName="mbflag">
											<mat-option *ngFor="let k of properties.product_types" [value]="k.value">
												{{ k.name }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-4">
									<mat-form-field appearance="standard">
										<mat-label>Sort </mat-label>
										<input matInput required value="" formControlName="sort">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4">
									<mat-form-field appearance="standard">
										<mat-label>FET </mat-label>
										<input matInput required value="" formControlName="additionalfee">
									</mat-form-field>
								</div>
								<div class="col-md-4">

									<mat-form-field appearance="standard">
										<mat-label>GL Class</mat-label>
										<input required type="text"
											placeholder="GL Class"
											matInput
											formControlName="glclass"
											[displayWith]="displayFn"
											[matAutocomplete]="auto"
											>
										<mat-autocomplete #auto="matAutocomplete">
											<mat-option *ngFor="let k of glclasses" [value]="k.code">{{ k.code }} - {{ k.name }}</mat-option>
										</mat-autocomplete>
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
					<div class="card border">
						<div class="card-body text-dark">
							<div class="row">
								<div class="col-md-3">
									<mat-form-field appearance="standard" class="">
										<mat-label>Unit of Measure</mat-label>
										<mat-select required formControlName="units">
											<mat-option *ngFor="let k of properties.uom" [value]="k.value">
												{{ k.name }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-3">
									<mat-form-field appearance="standard">
										<mat-label>Decimals</mat-label>
										<input matInput required value="" formControlName="decimalplaces">
									</mat-form-field>
								</div>
								<div class="col-md-3">
									<mat-form-field appearance="standard">
										<mat-label>Packaged Volume</mat-label>
										<input matInput required value="" formControlName="volume">
									</mat-form-field>
								</div>

								<div class="col-md-3">
									<mat-form-field appearance="standard">
										<mat-label>Packaged Gross Weight </mat-label>
										<input matInput required value="" formControlName="grossweight">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4">
									<mat-form-field appearance="standard" class="">
										<mat-label>Perishable</mat-label>
										<mat-select formControlName="perishable">
											<mat-option *ngFor="let k of properties.perishable" [value]="k.value">
												{{ k.name }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-4">
									<mat-form-field appearance="standard" class="">
										<mat-label>Discontinued</mat-label>
										<mat-select formControlName="discontinued">
											<mat-option *ngFor="let k of properties.discontinued" [value]="k.value">
												{{ k.name }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-4">
									<mat-form-field appearance="standard" class="">
										<mat-label>Enabled</mat-label>
										<mat-select formControlName="enabled">
											<mat-option *ngFor="let k of properties.enabled" [value]="k.value">
												{{ k.name }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>

							<div class="row">

								<div class="col-md-4">
									<mat-form-field appearance="standard" class="" >
										<mat-label>Batch, Serial or Lot Controlled</mat-label>
										<mat-select formControlName="controlled">
											<mat-option *ngFor="let k of properties.controlled" [value]="k.value" [selected]="first">
												{{ k.name }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-4">
									<mat-form-field appearance="standard" class="" *ngIf="itemForm.get('controlled').value == '1'">
										<mat-label>Serialized</mat-label>
										<mat-select formControlName="serialised">
											<mat-option *ngFor="let k of properties.serialized" [value]="k.value">
												{{ k.name }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
