import { NgModule } from '@angular/core';

import { WebBluetoothModule } from '@manekinekko/angular-web-bluetooth';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { SharedModule } from '../shared/shared.module';
import { DashboardComponentsModule } from '../dashboard-components/dashboard-components.module'

import { GlobalSearchService } from '../services/globalsearchservice.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { WarehouseDashboardComponent } from './warehouse-dashboard/warehouse-dashboard.component';
import { WarehouseBinsComponent } from './warehouse-bins/warehouse-bins.component';

import { WarehouseOrderReceiveComponent } from './warehouse-order-receive/warehouse-order-receive.component';

import { BinMergeComponent } from './warehouse-bins/bin-merge/bin-merge.component';
import { BinEditComponent } from './warehouse-bins/bin-edit/bin-edit.component';
import { BinItemsComponent } from './warehouse-bins/bin-items/bin-items.component';
import { BinHistoryComponent } from './warehouse-bins/bin-history/bin-history.component';
import { BinListComponent } from './warehouse-bins/bin-list/bin-list.component';
import { BinItemScanComponent } from './warehouse-bins/bin-item-scan/bin-item-scan.component';
import { BarcodeScannerLivestreamModule, BarcodeScannerLivestreamOverlayModule } from "ngx-barcode-scanner";
import { BinLabelsComponent } from './warehouse-bins/bin-labels/bin-labels.component';
import { StorageLookupComponent } from './storage-lookup/storage-lookup.component';
import { ItemBarcodeAddComponent } from './item-barcode-add/item-barcode-add.component';
import { FocusMeDirective } from './directive/focus-me.directive';
import { HighlightDirective } from './directive/highlight.directive';
import { BinBarcodeComponent } from './warehouse-bins/bin-barcode/bin-barcode.component';
import { WarehouseStockCountsComponent } from './warehouse-stock-counts/warehouse-stock-counts.component';
import { WarehouseDashboardViewposComponent } from './warehouse-dashboard/warehouse-dashboard-viewpos/warehouse-dashboard-viewpos.component';
import { WarehouseItemReceivingComponent } from './warehouse-item-receiving/warehouse-item-receiving.component';
import { WarehouseItemScanComponent } from './warehouse-item-scan/warehouse-item-scan.component';
import { WarehouseViewItemComponent } from './warehouse-view-item/warehouse-view-item.component';
import { WarehouseItemLookupComponent } from './warehouse-item-lookup/warehouse-item-lookup.component';
import { WarehouseReceivingComponent } from './warehouse-receiving/warehouse-receiving.component';
import { DeliveryBoardComponent } from './delivery-board/delivery-board.component';
import { ReviewCountsComponent } from './warehouse-stock-counts/review-counts/review-counts.component';
import { WarehouseSendingComponent } from './warehouse-sending/warehouse-sending.component';
import { QantelModule } from 'app/qantel/qantel.module';
import { WarehouseScaninoutComponent } from './warehouse-scaninout/warehouse-scaninout.component';
import { WarehouseFinalizerecComponent } from './warehouse-finalizerec/warehouse-finalizerec.component';
import { WarehouseItemScanTwoComponent } from './warehouse-item-scan-two/warehouse-item-scan-two.component';
import { WarehouseReportsComponent } from './warehouse-reports/warehouse-reports.component';
import { WarehouseCheckingComponent } from './warehouse-checking/warehouse-checking.component';


@NgModule({
declarations: [
    WarehouseDashboardComponent,
    WarehouseBinsComponent,
    WarehouseOrderReceiveComponent,
	BinMergeComponent,
	BinEditComponent,
	BinItemsComponent,
	BinHistoryComponent,
	BinMergeComponent,
	BinEditComponent,
	BinItemsComponent,
	BinHistoryComponent,
	BinListComponent,
	BinItemScanComponent,
	BinLabelsComponent,
	StorageLookupComponent,
	ItemBarcodeAddComponent,
	FocusMeDirective,
	HighlightDirective,
	BinBarcodeComponent,
	WarehouseStockCountsComponent,
	WarehouseDashboardViewposComponent,
	WarehouseItemReceivingComponent,
	WarehouseItemScanComponent,
	WarehouseViewItemComponent,
	WarehouseItemLookupComponent,
	WarehouseReceivingComponent,
	DeliveryBoardComponent,
	ReviewCountsComponent,
	WarehouseSendingComponent,
 WarehouseScaninoutComponent,
 WarehouseFinalizerecComponent,
 WarehouseItemScanTwoComponent,
 WarehouseReportsComponent,
 WarehouseCheckingComponent,
],
imports: [
	QantelModule,
    CommonModule,
	RouterModule,
	ReactiveFormsModule,
	FormsModule,
	ScrollingModule,
	MatToolbarModule,
	MatButtonModule,
	MatDialogModule,
	MatDividerModule,
	MatInputModule,
	MatTableModule,
	MatCheckboxModule,
	MatSortModule,
	MatPaginatorModule,
	MatFormFieldModule,
	MatAutocompleteModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatExpansionModule,
	MatSelectModule,
	MatMenuModule,
	MatIconModule,
	MatSlideToggleModule,
	MatStepperModule,
	MatListModule,
	MatTabsModule,
	MatDividerModule,
	MatProgressBarModule,
	BarcodeScannerLivestreamModule,
	BarcodeScannerLivestreamOverlayModule,
	MatButtonToggleModule,
	SharedModule,
	DashboardComponentsModule,
	WebBluetoothModule.forRoot({
	  enableTracing: true
	})
]
})
export class WarehouseModule { }
