import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit} from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup,ReactiveFormsModule } from '@angular/forms';
import { interval, Subscription} from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router} from '@angular/router';
import { TaxesService } from '../../../services/taxes.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tax-authorities',
  templateUrl: './tax-authorities.component.html',
  styleUrls: ['./tax-authorities.component.scss']
})
export class TaxAuthoritiesComponent implements OnInit {
	@ViewChild("modalView") modalViewRef: ElementRef;
	taxauthorities: any = [];
	editing: boolean = false;
	editForm: FormGroup;

	yesno  = [
		{ label: 'Yes' , value: '1' },
		{ label: 'No' , value: '0' },
	];

	bankaccounts: any = false;
	editaddlable: string ="Add";
	selectedtaxgl: any = false;
	selectedpurchgl: any = false;

	constructor(private modalService: NgbModal,private globalSearchService: GlobalSearchService, private taxesService: TaxesService,private fb: FormBuilder) {
	}

	ngOnInit(): void {

		this.editForm = this.fb.group({
			taxid: [''],
			description: ['', Validators.required],
			taxglcode: ['', Validators.required],
			purchtaxglaccount: ['', Validators.required],
			bank: ['', Validators.required],
			bankacc: ['', Validators.required],
			bankswift: ['', Validators.required],
			bankacctype: ['', Validators.required],
			always_taxable: ['', Validators.required],
			tax_exempt_cert: ['', Validators.required],
			always_exempt: ['', Validators.required],
			freight: ['', Validators.required],
		});

		this.globalSearchService.bankaccounts.subscribe(value => {
			this.bankaccounts = value
		});

		this.taxesService.getTaxAuthorities().subscribe((results: any) => {
			this.taxauthorities = results;
		})
	}

	selectGl(value : any) {
		this.editForm.get('purchtaxglaccount').setValue(value.value);
	}

	openModal() {

		this.modalService.open(this.modalViewRef, {ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});

	}
	saveTax() {
		this.taxesService.updatetTaxAuthority(this.editForm.value).subscribe((result: any) => {
			if(result.success) {
				this.taxesService.getTaxAuthorities().subscribe((results: any) => {
					this.taxauthorities = results;
					this.globalSearchService.showNotification('Updated', 'success','bottom','right');
				});
			}
		})
	}
	addTax() {
		this.editaddlable = "Add Tax Auth";
		this.openModal();
	}

	editTax(tax: any) {


		this.selectedtaxgl = tax.taxglcode
		this.selectedpurchgl = tax.purchtaxglaccount;

		this.editForm = this.fb.group({
			taxid: [tax.taxid],
			description: [tax.description, Validators.required],
			taxglcode: [tax.taxglcode, Validators.required],
			purchtaxglaccount: [tax.purchtaxglaccount, Validators.required],
			bank: [tax.bank, Validators.required],
			bankacc: [tax.bankacc, Validators.required],
			bankswift: [tax.bankswift, Validators.required],
			bankacctype: [tax.bankacctype, Validators.required],
			always_taxable: [tax.always_taxable, Validators.required],
			tax_exempt_cert: [tax.tax_exempt_cert, Validators.required],
			always_exempt: [tax.always_exempt, Validators.required],
			freight: [tax.freight, Validators.required],
		});

		this.editaddlable = "Edit Tax Auth "+ tax.description;
		this.openModal();
	}

}
