<ng-template #userdetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">User Details</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<app-user-edit [user]="selecteduser" (updated)="updateUser($event)"></app-user-edit>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Users</h4>
						<p class="card-category text-right">User Information</p>
					</div>
					<div class="table-container">
						<div class="card-body">
							<div class="table">
								<div class="row">
									<div class="col-8 text-left mt-3">
										<a mat-raised-button color="accent" [routerLink]="'/users/create'">New User</a>
									</div>

									<div class="col-md-4">
										<mat-form-field appearance="standard" class="text-right">
											<mat-label>Search</mat-label>
											<input tabindex="1" matInput value="" (input)="onInput()" [formControl]="userSearch" class="text-right" autofocus (keydown.enter)="$event.preventDefault();onInput()">
											<span matSuffix>
												<button tabindex="-1" mat-icon-button color="white">
													<mat-icon (click)="searchUsers()">search</mat-icon>
												</button>
											</span>
											<mat-progress-bar mode="indeterminate" color="accent" *ngIf="isSearching"></mat-progress-bar>
										</mat-form-field>
									</div>
								</div>
								<table mat-table [dataSource]="users" matSort (matSortChange)="announceSortChange($event)">
									<ng-container matColumnDef="actions">
										<th mat-header-cell *matHeaderCellDef></th>
										<td mat-cell *matCellDef="let user">
											<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
												<mat-icon>more_vert</mat-icon>
											</button>
											<mat-menu #menu="matMenu">
												<button mat-menu-item (click)="openUser(user)">
													<mat-icon>edit</mat-icon>
													<span>Edit User</span>
												</button>
											</mat-menu>
										</td>
									</ng-container>
									<ng-container matColumnDef="userid">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>User ID</th>
										<td mat-cell *matCellDef="let user"> {{ user.userid }} </td>
									</ng-container>
									<ng-container matColumnDef="realname">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
										<td mat-cell *matCellDef="let user"> {{ user.realname }}</td>
									</ng-container>
									<ng-container matColumnDef="groupname">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
										<td mat-cell *matCellDef="let user"> {{ user.groupname }}</td>
									</ng-container>
									<ng-container matColumnDef="blocked">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>Disabled</th>
										<td mat-cell *matCellDef="let user">
											<span *ngIf=" user.blocked == '1' ">Yes</span>
											<span *ngIf=" user.blocked == '0' ">No</span>
										</td>
									</ng-container>

									<tr mat-header-row *matHeaderRowDef="headercolumns;sticky: true;"></tr>
									<tr mat-row *matRowDef="let row; columns: headercolumns"></tr>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
