import { Component, OnInit } from '@angular/core';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { VendorService } from '../../services/vendor.service'
import { PurchasingService } from '../../services/purchasing.service'
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';

@Component({
	selector: 'app-vendors-dashboard',
	templateUrl: './vendors-dashboard.component.html',
	styleUrls: ['./vendors-dashboard.component.scss'],
	animations: [
	  trigger('flipState', [
		  state('active', style({
			 // transform: 'rotateY(179deg)',
			 //safari does not like rotate Y
			  transform: 'rotate3d(0, 1, 0, 180deg)',
		  })),
		  state('inactive', style({
			  transform: 'rotateY(0)'
		  })),
		  state('orderlookup', style({
			  transform: 'rotate3d(0, 1, 0, 180deg)',
		  })),
		  transition('active => inactive', animate('400ms ease-out')),
		  transition('inactive => active', animate('400ms ease-in')),
		  transition('inactive => orderlookup', animate('400ms ease-out')),
		  transition('orderlookup => inactive', animate('400ms ease-in')),
	]),
	 trigger('openClose', [
		  state(':enter', style({ height: '*' })),
		  state(':leave', style({ height: '0px' })),
		  transition('false <=> true', animate(500))
	]),
	trigger('filterAnimation', [
	  transition(':enter, * => 0, * => -1', []),
	  transition(':increment', [
		query(':enter', [
		  style({ opacity: 0, width: 0 }),
		  stagger(50, [
			animate('300ms ease-out', style({ opacity: 1, width: '*' })),
		  ]),
		], { optional: true })
	  ]),
	  transition(':decrement', [
		query(':leave', [
		  stagger(50, [
			animate('300ms ease-out', style({ opacity: 0, width: 0 })),
		  ]),
		])
	  ]),
	]),
	trigger("grow", [ // Note the trigger name
	  transition(":enter", [
		// :enter is alias to 'void => *'
		style({ height: "0",width: "0", overflow: "hidden" }),
		animate(200, style({ height: "*" , width: "*"})),
		animate('200ms', style({ opacity: 1 })),
	  ]),
	  transition(":leave", [
		// :leave is alias to '* => void'
		animate(100, style({ height: 0, width: 0 , overflow: "hidden" })),
		animate('100ms', style({ opacity: 0 }))
	  ])
	]),
	]
})
export class VendorsDashboardComponent implements OnInit {
	color: any = 'blue';
	aged: any = false;
	payments: any = false;
	purchaseorders: any = false;
	invoice: any = false;
	partials: any = false;
	invoice_issues: any = false;
	flip: string = 'inactive';
	dataset: any = false;
	title: string = '';
	allpurchaseorders: any = [];
	statuses: any = [];

	constructor(private purchasingService: PurchasingService, private vendorService: VendorService, private globalSearchService: GlobalSearchService) {
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.mesagesallowed.next(true);
	}

	ngOnInit(): void {

		this.vendorService.getDashBoard().subscribe( (results:any) => {
			this.aged = results.aged;
			this.payments = results.payments;
		})

		this.purchasingService.getPurchaseOrders().subscribe((results: any) => {

			this.purchaseorders= results.pos;

			this.partials = results.pos.filter( i => {
				return parseFloat(i.ordered) > parseFloat(i.rec) && parseFloat(i.rec) > 0
			})

			this.invoice = results.grnready;

			this.invoice_issues = results.grnready.filter( i => {
				return parseFloat(i.quantityord) != parseFloat(i.qtyrecd) && parseFloat(i.qtyrecd) > 0
			})
		});

		this.purchasingService.getPurchaseOrders().subscribe((results: any) => {
			this.allpurchaseorders= results;
			this.statuses = results.statuses

		});

	}

	toggleFlip(title: string, dataset: any) {

		if(dataset != '') {
			this.dataset = dataset
		} else {
			this.dataset = false;
		}

		this.title = title;
		switch (this.flip) {
			case 'inactive':
				this.flip = 'active';
			break;
			default:
				this.flip = 'inactive';
			break;
		}
	}
}
