import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, EventEmitter, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { VehicleService } from '../../services/vehicle.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

declare var $: any;


@Component({
	selector: 'app-tire-guide',
	templateUrl: './tire-guide.component.html',
	styleUrls: ['./tire-guide.component.scss']
})
export class TireGuideComponent implements OnInit {
	@Input() input;
	@Output() output = new EventEmitter < any > ();

	years: any = [];
	makes: any = [];
	models: any = [];
	opts: any = [];

	all_years: any = [];
	all_makes: any = [];
	all_models: any = [];


	tguidelist: any = [];
	tireGuideForm: UntypedFormGroup;
	sending: boolean = false;
	constructor(public globalSearchService: GlobalSearchService, public vehicleService: VehicleService, private fb: UntypedFormBuilder) {}

	ngOnInit(): void {

		this.tireGuideForm = this.fb.group({
			year: ['', [Validators.required]],
			make: ['', [Validators.required]],
			model: ['', []],
			model_type: ['', [Validators.required]],
		});

		this.vehicleService.getVehYears().subscribe(years => {
			this.years = years;
			this.all_years = years

			//this.tireGuideForm.get('year').setValue(years[0].cyear);

			this.vehicleService.getVehMakes(this.tireGuideForm.get('year').value).subscribe(makes => {
				this.makes = makes;
				this.all_makes = makes;
			});
		});

		this.tireGuideForm.get('year').valueChanges.subscribe(newValue => {
			if (newValue == '') {
				this.all_years = this.all_years;
			} else {
				this.all_years = this.globalSearchService.filterItem(this.years, newValue, 'cyear');
				if(newValue == this.all_years[0].cyear) {
					this.getMakes();
				}
			}
		})

		this.tireGuideForm.get('make').valueChanges.subscribe(newValue => {
			if (newValue == '') {
				this.all_makes = this.makes;
			} else {
				this.all_makes = this.globalSearchService.filterItem(this.makes, newValue, 'make1');
			}
		})

	}

	getMakes() {
		this.tireGuideForm.get('make').setValue('');
		this.tireGuideForm.get('model').setValue('');
		this.tireGuideForm.get('model_type').setValue('');

		if (this.tireGuideForm.get('year').value != '') {
			this.vehicleService.getVehMakes(this.tireGuideForm.get('year').value).subscribe(details => {
				this.all_makes = details;
				this.makes = details;
			});
		}
	}

	getModels() {
		if (this.tireGuideForm.get('year').value != '' &&
			this.tireGuideForm.get('make').value != '') {
			this.vehicleService.getVehModels(this.tireGuideForm.get('year').value, this.tireGuideForm.get('make').value).subscribe(details => {
				this.models = details;
				this.all_models = details;
			});
		}
	}
	getOpts() {

		if (this.tireGuideForm.get('year').value != '' &&
			this.tireGuideForm.get('make').value != '' &&
			this.tireGuideForm.get('model').value != ''
		) {
			this.vehicleService.getVehOpts(
				this.tireGuideForm.get('year').value,
				this.tireGuideForm.get('make').value,
				this.tireGuideForm.get('model').value
			).subscribe(details => {
				this.opts = details;
			});
		}
	}
	getFitment() {
		this.sending = true;
		this.vehicleService.getVehFitment(
			this.tireGuideForm.get('year').value,
			this.tireGuideForm.get('make').value,
			this.tireGuideForm.get('model').value,
			this.tireGuideForm.get('model_type').value

		).subscribe(details => {
			this.sending = false;
			this.tguidelist = details;
		});

	}
	getResults() {

	}
	//sends the output to the parent. parent runs whatever function its got in the output handler
	sendResult(output: any) {
		this.output.emit(output)
	}
}
