import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common'
import { GlobalsService } from '../../services/globals.service'
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sales-people',
  templateUrl: './sales-people.component.html',
  styleUrls: ['./sales-people.component.scss']
})
export class SalesPeopleComponent implements OnInit {
  @ViewChild("salesEdit") salesEditRef: ElementRef;
  salespeople:any = [];
  zones: any = [];
  
  salesForm: UntypedFormGroup;
  constructor(private fb: UntypedFormBuilder, private globalsService: GlobalsService, private globalSearchService: GlobalSearchService, private modalService: NgbModal) { }

  ngOnInit(): void {
  }
  
  addSalesPerson(){
  this.salesForm = this.fb.group({
			salesmancode: ['',Validators.required],
			salesmanname: ['',Validators.required],
			smantel: [''],
			smanfax: [''],
			commissionrate1: [''],
			breakpoint: [''],
			commissionrate2: [''],
			current: ['1'],
			chatid: [''],
			group_id: ['1'],
			group_level: ['0'],
			group_type: ['1'],
			username: [''],
			smanlogin:[''],
			smanpass: [''],
			smanaddr: [''],
			smancity: [''],
			smanstate: [''],
			smanzip: [''],
			smantab: [''],
			smancell: [''],
			smanemail: ['',Validators.required],
			access_level: ['0'],
			csremails: [''],		
		});

		this.modalService.open(this.salesEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
  }
  
  saveSalesPerson(){
  if (this.salesForm.valid) {
			this.globalsService.saveSalesPerson(this.salesForm.value).subscribe(result => {
				this.salespeople = result
				this.modalService.dismissAll();
			})
		}
  
  }
  
  editSalesPerson(salesperson: any){
  
	this.salesForm = this.fb.group({
			salesmancode: [salesperson.salesmancode,Validators.required],
			salesmanname: [salesperson.salesmanname,Validators.required],
			smantel: [salesperson.smantel],
			smanfax: [salesperson.smanfax],
			commissionrate1: [salesperson.commissionrate1],
			breakpoint: [salesperson.breakpoint],
			commissionrate2: [salesperson.commissionrate2],
			current: [salesperson.current],
			chatid: [salesperson.chatid],
			group_id: [salesperson.group_id],
			group_level: [salesperson.group_level],
			group_type: [salesperson.group_type],
			username: [salesperson.username],
			smanlogin:[salesperson.smanlogin],
			smanpass: [salesperson.smanpass],
			smanaddr: [salesperson.smanaddr],
			smancity: [salesperson.smancity],
			smanstate: [salesperson.smanstate],
			smanzip: [salesperson.smanzip],
			smantab: [salesperson.smantab],
			smancell: [salesperson.smancell],
			smanemail: [salesperson.smanemail,Validators.required],
			access_level: [salesperson.access_level],
			csremails: [salesperson.csremails],		
		});
	
		this.modalService.open(this.salesEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
  }
  
  ngAfterViewInit() {

		this.globalsService.getSalesPeople().subscribe(results => {
			this.salespeople = results;
		});
		this.globalSearchService.zones.subscribe((results: any) => {
			this.zones = results;
		});
		
  }
}
