<form class="" autocomplete="off">
	<div class="row">
		<ng-container *ngIf="!creating">
			<div class="col-md-2 mt-3 mb-0">
				<a [routerLink]="'/customers/create'" mat-stroked-button color="danger">New Customer</a>
			</div>
			<div class="col-md-3 mb-0 mt-3">
				<mat-slide-toggle [formControl]="phoneonly">Phone Number Search</mat-slide-toggle>
			</div>
		</ng-container>
		<div class="mt-0 mb-0" [ngClass]="{'col-md-7' : !creating, 'col-md-12' : creating } ">
			<mat-form-field appearance="{{outlined}}" class="smaller-font-form ">
				<span matPrefix *ngIf="all_customers.length && !sending">
					<button  mat-icon-button class="p-0" (click)="clearForm()">
						<mat-icon class="p-0" >clear</mat-icon>
					</button>
				</span>
				<mat-spinner matPrefix diameter="30" *ngIf="sending"></mat-spinner>

				<input matInput #customerinput (input)="ready=false;" autocomplete="off" class="text-right mt-0 mb-0 pt-0 pb-0 " value="" [formControl]="customersearch" placeholder="Search By Phone/Email/Customer#/Name ..." (keydown.enter)="$event.preventDefault();filterCustomers()" (keyUpCheck)="filterCustomers()" appWaitType autofocus >
				<span matSuffix>
					<button mat-icon-button color="white" type="submit" (click)="filterCustomers()">
						<mat-icon class="p-0">search</mat-icon>
					</button>
				</span>
			</mat-form-field>
		</div>
	</div>
</form>

<div class="table" *ngIf="all_customers">
	<ng-container *ngIf="(!all_customers?.length && ready)">
		<h3 class="text-center">No Results For {{customersearch.value}}</h3>
	</ng-container>
	<mat-table class="table table-fluid table-responsive" [dataSource]="customers" matSort (matSortChange)="announceSortChange($event)" [ngClass]="!all_customers || (!all_customers?.length && !sending)? 'd-none': ''">
		<ng-container matColumnDef="actions">
			<mat-header-cell class="" *matHeaderCellDef mat-sort-header> </mat-header-cell>
			<mat-cell class="" *matCellDef="let customer">
				<a class="mt-2" mat-stroked-button (click)="selectCustomer(customer)">VIEW</a>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="debtorno">
			<mat-header-cell class="" *matHeaderCellDef mat-sort-header> CUSTOMER#</mat-header-cell>
			<mat-cell class="" *matCellDef="let customer">
				{{ customer.debtorno }}.{{ customer.branchcode }}
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef mat-sort-header> NAME</mat-header-cell>
			<mat-cell *matCellDef="let customer">
				{{ customer.name}}
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="phoneno">
			<mat-header-cell *matHeaderCellDef mat-sort-header> PHONE#</mat-header-cell>
			<mat-cell *matCellDef="let customer">
				{{ customer.phoneno | phoneFormat }}
			</mat-cell>
		</ng-container>
		<mat-header-row *matHeaderRowDef="headercolumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: headercolumns;" (click)="selectCustomer(row)"></mat-row>
	</mat-table>
	<mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons aria-label="select page" [ngClass]="!all_customers ? 'd-none': ''">
	</mat-paginator>
</div>

<div class="table table-responsive text-center" *ngIf="!ready && customersearching">
	<svg class="spinner" width="55px" height="55px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
		<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
		</circle>
	</svg>
</div>
