import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ViewEncapsulation,ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms'
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { PaymentsService } from '../../services/payments.service'


@Component({
	selector: 'app-pos-order-payments',
	templateUrl: './pos-order-payments.component.html',
	styleUrls: ['./pos-order-payments.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class PosOrderPaymentsComponent implements OnInit {

	paymentForm: UntypedFormGroup;
	numberposition: number = 1;
	@Input() order_header: any = [];
	@Input() items: any = [];
	@Input() totals: any;
	@Input() pos: any = true;
	@Input() document_type: any = '30';
	@Input() payments: any = [];

	//not being sent in the header. on time
	@Input() orderno: any;
	@Input() debtorno: any;
	@Input() branchcode: any;

	@Output() payments_added = new EventEmitter < any > ();
	@Output() payments_removed = new EventEmitter < any > ();

	@ViewChild("amountId") amountIdRef: ElementRef;

	paymentmethods: any = false
	bankaccounts: any = false;
	loadingcards: boolean = false;
	default_payment = '';
	change_due: any = false;

	hasprofile: any = false;
	profile_cards: any = false;
	payment_card: any = false;
	card_required: any = false;
	preselected_payment: any = false;
	color = 'blue'
	balance_due: any = 0.00;

	constructor(private formBuilder: UntypedFormBuilder, private globalSearchService: GlobalSearchService, private paymentsService: PaymentsService) {
		this.color = this.globalSearchService.color;

	}


	numpad: number;

	keypadresult: any = false;

	ngOnInit(): void {
		this.globalSearchService.payment_methods.subscribe((results: any) => {
			this.paymentmethods = results;
		})

		this.globalSearchService.bankaccounts.subscribe((results: any) => {
			this.bankaccounts = results;
		});

		this.resetPayForm();

	}

	addPaymentInput() {
		this.resetPayForm();
	}

	addPayment() {

		if(this.paymentForm.get('type').value == '') {
			this.globalSearchService.showNotification('Payment Type Required', 'danger', 'bottom', 'right');
			return false;
		}

		if (this.paymentForm.valid) {
			//order 1 is pre payment
			const data = {
				payment: this.paymentForm.value,
				order: {
					orderno: (this.order_header.orderno) ? this.order_header.orderno : '1',
					debtorno: this.order_header.debtorno,
					branchcode: this.order_header.branchcode,
					document_type: this.document_type
				}
			};
			if(this.getBalance() != 0) {
				this.paymentsService.addPayment(data).subscribe((results: any[]) => {
					this.payments_added.emit(false);
					this.resetPayForm();
				});
			}
		}
	}

	removePayment(payment_id: any) {
		//this.payments[index].splice(0,1);
		this.paymentsService.removePayment(payment_id).subscribe((results: any[]) => {
			//this.getPayments();
			this.payments_removed.emit(false);
		});

	}

	updatePaymentAmount(event: any, payment: any) {
		payment.amount = event.target.value;
		this.updatePayment(payment)
	}

	updatePaymentType(event: any) {

		let thispay = this.paymentmethods.filter(p => {
			return p.paymentname == event.value
		})[0];

		if (thispay) {
			if (thispay.cc_process != '0') {
				this.card_required = true;
			} else {
				this.card_required = false;
				this.paymentForm.get('card_selected').setValue(false);
			}
		}
	}

	getBalance() {

		let total = this.totals.filter(t => {
			return t.code == 'total';
		})[0];

		var total_amount = 0.00;

		if (total && this.totals.length) {
			total_amount = total.text
		}

		var value = this.financial(total_amount) - this.financial(this.getTotalPayment());
		if (value < 0) {

			this.payments.forEach((pay) => {
				//Is there cash involved, then we can give change.
				//&& pay.value > 0 &&
				if (this.openCashDroor(pay.payment_type_id) && value < 0) {
					this.change_due = value;
					//It's cash!
					// if (pay.value > Math.abs(value)) {
					// 	this.change_due = Math.abs(value);
					// 	value = 0;
					// } else {
					// 	this.change_due = pay.value;
					// 	value = value + pay.value;
					// }
				} else {

				}
			});
		} else {
			this.change_due = 0;
		}

		return value;
	}

	updatePaymentReference(event: any, payment: any) {
		payment.reference = event.target.value;
		this.updatePayment(payment)
	}

	updatePaymentBank(event: any, payment: any) {
		payment.bankaccount = event.target.value;
		this.updatePayment(payment)
	}

	updatePayment(payment: any) {
		//this.payments[index].splice(0,1);
		this.paymentsService.updatePayment(payment).subscribe((results: any[]) => { this.payments = results });
		this.getPayments();
	}

	resetPayForm() {

		var account = (this.bankaccounts && this.bankaccounts[0]) ? this.bankaccounts[0].accountcode : '';

		this.paymentForm = this.formBuilder.group({
			reference: [''],
			amount: [this.balance_due, [Validators.required, Validators.pattern("^[0-9.]*$")]],
			type: [this.default_payment, [Validators.required]],
			bankaccount: [account, [Validators.required]],
			card_selected: [false],
			charge_card: [false],
		});

		//this.paymentForm.markAllAsTouched();

	}

	getPayments() {
		this.payments_added.emit(false);
	}

	balance(): number {
		var balance = 0.00;
		if (this.payments && this.payments.length > 0) {

			let payment_total = this.payments.reduce(function(accumulator, am) {
				return accumulator + parseFloat(am.amount);;
			}, 0);

			balance = this.financial(this.totals.total) - this.financial(payment_total);
		}

		this.balance_due = this.financial(balance)
		return this.financial(balance);
	}

	getTotalPayment() {
		var total = 0.00
		if (this.payments && this.payments.length > 0) {
			total = this.payments.reduce(function(accumulator, am) {
			return accumulator + parseFloat(am.amount);;
		}, 0);
		}

		total = this.financial(total);
		return total;
	}


	changeCard(event: any) {
		if (event && event.card_type) {
			this.paymentForm.get('type').setValue(event.card_type);
		}
	}

	lastFour(value: any) {
		return 'XX' + value.substr(value.length - 4);
	}

	ngOnChanges(changes: any) {

		if (changes.debtorno) {
			this.getPayments();
			this.loadProfile(this.debtorno, this.branchcode);

			if (changes.order_header.currentValue.preselectedpay) {
				this.preselected_payment = changes.order_header.currentValue.preselectedpay;
			}
		}

		if (changes.order_header) {

		}

		if (changes.payments) {
			if (changes.payments.currentValue && changes.payments.currentValue.length) {
				let payment_total = this.payments.reduce(function(accumulator, am) {
					return accumulator + parseFloat(am.amount);;
				}, 0);

				var balance = this.financial(this.totals.total) - this.financial(payment_total);
				if(this.paymentForm) {
					this.paymentForm.get('amount').setValue(this.financial(balance))
				}
			}
		}

		if (this.paymentForm) {

			if (this.paymentForm.untouched) {
				if (this.bankaccounts) {
					this.paymentForm.get('bankaccount').setValue(this.bankaccounts[0].accountcode)
				}
				//this.paymentForm.get('type').setValue(this.paymentmethods[0].paymentname)
				//let paymentid = this.getPaymentIdFromName(this.paymentmethods[0].paymentname);
				//this.setCardRequired(paymentid)
			}

			if (!this.profile_cards && this.preselected_payment && this.paymentForm) {

				this.loadingcards = true;
				this.paymentsService.loadProfile(this.debtorno, this.branchcode).subscribe(async (result: any) => {
					this.loadingcards = false;
					if (result.cards) {
						this.hasprofile = true;
						this.profile_cards = result.cards
						this.paymentForm.get('type').setValue(this.preselected_payment.card_type);

						let card = this.profile_cards.filter((card: any) => {
							return card.card_id == this.preselected_payment.card_id;
						})[0];

						this.paymentForm.get('card_selected').setValue(card);
						let paymentid = this.getPaymentIdFromName(this.preselected_payment.card_type);
						this.setCardRequired(paymentid)

					} else {
						this.hasprofile = false;
						this.profile_cards = [];
						this.payment_card = false;
					}
				});
			}

			if (this.payments) {
				let payment_total = this.payments.reduce(function(accumulator, am) {
					return accumulator + parseFloat(am.amount);;
				}, 0);

				var balance = this.financial(this.totals.total) - this.financial(payment_total);
				if(this.paymentForm) {
					this.paymentForm.get('amount').setValue(this.financial(balance))
				}
			}


			this.balance();
		}
	}

	getPaymentIdFromName(name: any) {
		if(this.paymentmethods) {
			let type = this.paymentmethods.filter(p => {
				return p.paymentname == name
			})[0];

			if (type) {
				return type.paymentid;
			}
		}
		return false;

	}

	loadProfile(debtorno: any, branchcode: any) {
		this.loadingcards = true;
		this.paymentsService.loadProfile(debtorno, branchcode).subscribe(async (result: any) => {
			this.loadingcards = false;
			if (result.cards) {
				this.hasprofile = true;
				this.profile_cards = result.cards
				let card = result.cards.filter((card: any) => {
					return card.default
				})[0];
				this.paymentForm.get('card_selected').setValue(card);

			} else {
				this.hasprofile = false;
				this.profile_cards = [];
				this.payment_card = false;
				this.paymentForm.get('card_selected').setValue(false);
			}
		});
	}

	addOnRequired() {

		var value = false;
		if(this.paymentmethods) {
			let thispay = this.paymentmethods.filter(p => {
				return p.paymentname == this.paymentForm.get('type').value
			})[0];
			if (thispay) {
				if (thispay.additionalrequired == '1') {
					value = true;
				}
			}
		}

		return value;
	}
	openCashDroor(method: any) {

		var value = false;
		if(this.paymentmethods) {
			let thispay = this.paymentmethods.filter(p => {
				return p.paymentid == method
			})[0];
			if (thispay) {
				if (thispay.opencashdrawer == '1') {
					value = true;
				}
			}
		}

		return value;
	}

	setCardRequired(method: any) {

		if (method) {
			let thispay = this.paymentmethods.filter(p => {
				return p.paymentid == method
			})[0];

			if (thispay) {
				if (thispay.cc_process != '0') {
					this.card_required = true;
				} else {
					this.card_required = false;
					this.paymentForm.get('charge_card').setValue(false);
					this.paymentForm.get('card_selected').setValue(false);
				}
			}
		}
	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}


	runKey(value: any) {
		switch(value) {
			case 'add':
				this.addPayment();
			break;
			case 'backspace':
				let newvalue = (this.paymentForm.get('amount').value+'').slice(0, -1);
				if(newvalue != '') {
					this.paymentForm.get('amount').setValue(newvalue);
				} else {
					this.paymentForm.get('amount').setValue(0.00);
				}

			break;
			case '0':
			case '.':
				var periodval = this.paymentForm.get('amount').value+''+value;
				this.paymentForm.get('amount').setValue(periodval);
			break;
			default:
				let currentvalue = this.paymentForm.get('amount').value+''+value;
				this.paymentForm.get('amount').setValue(this.financial(currentvalue));
			break;
		}
	}

	setChargeType(event: any) {
		if(event.value) {
			this.paymentForm.get('type').setValue(event.value)
			this.updatePaymentType(event.value)
			this.setCardRequired(event.value);

			setTimeout(()=>{
				this.amountIdRef.nativeElement.focus();
			},0);
		}
	}

	ngAfterViewInit(): void {


	}

}
