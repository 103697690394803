import { Component, EventEmitter, Output, OnInit, ViewChild, ElementRef, Input, OnChanges} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Location } from '@angular/common'
import { InventoryService } from '../../services/inventory.service';
import { OrdersService } from '../../services/orders.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';


@Component({
	selector: 'app-inventory-searcher',
	templateUrl: './inventory-searcher.component.html',
	styleUrls: ['./inventory-searcher.component.scss']
})
export class InventorySearcherComponent implements OnInit {
	@Input() rule: any = false;
	@Input() forceupdate: any = false;
	@Output() saveresults = new EventEmitter < any > ();
	results: any = [];
	categories: any = false;
	productlines: any = false;
	brands: any = [];
	user: any = false;
	brand = new UntypedFormControl();
	productLine = new UntypedFormControl();
	rangestart = new UntypedFormControl();
	rangeend = new UntypedFormControl();
	category = new UntypedFormControl();
	keywords = new UntypedFormControl();
	products: any = false;
	inventorysearching: any = false;
	allitems: any = [];
	selectedproducts: any = [];
	keywordresults: any = false;
	exclusions: any = [];

	constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, public router: Router, private location: Location, public inventoryService: InventoryService, public orderService: OrdersService, private globalSearchService: GlobalSearchService) {

	}

	ngOnInit(): void {

		this.inventoryService.getItemCategories().subscribe(c => {
			this.categories = c
		});

		this.inventoryService.getItemProductlines().subscribe(p => {
			this.productlines = p
		});

		this.inventoryService.getMfgs().subscribe(r => {
			this.brands = r
		});

		this.globalSearchService.user.subscribe(r => {
			this.user = r;
		});

		this.keywords.valueChanges.subscribe(newValue => {
			this.searchKeyWords();
		});
	}

	//keyword results seperate formm normal exlusion. whole program is really a rule builder.
	searchKeyWords() {
		//api side stockid is keywords
		let input = {
			stockid: this.keywords.value
		};

		this.inventoryService.lookup(input).subscribe(r=> {
			this.keywordresults = r;
		});
	}

	addMMItem(item:any) {

		let exists = this.selectedproducts.filter(r=> {
			return r.stockid == item.stockid;
		})[0];

		if(!exists) {
			let newprod = { stockid: item.stockid, description: item.description }
			this.selectedproducts.push(newprod);
			this.keywords.setValue('');
			this.search();
		}
	}

	search() {

		let filters = {
			manufacturers: this.brand.value,
			productids: this.products,
			productlines: this.productLine.value,
			categories: this.category.value,
			range_start: this.rangestart.value,
			range_end: this.rangeend.value,
			exclusions: this.exclusions
		}

		// if(this.inventorysearching) {
		// 	this.inventorysearching.unsubscribe();
		// }

		this.inventorysearching = this.inventoryService.getMixItems(filters).subscribe((items: any) => {
			this.results = items;
		});

	}

	removeItem(item:any) {
		let index = this.selectedproducts.indexOf(item);
		this.selectedproducts.splice(index,1);
		this.search();
	}

	exludeItem( item:any) {
		let index = this.results.indexOf(item);
		if(index >= 0) {
			this.results.splice(index, 1);
			this.exclusions.push(item);
		}
	}

	removeExclusion(item:any) {

		let ob = this.exclusions.filter( (i:any) => {
			return i.stockid == item.stockid;
		})[0];

		let index = this.exclusions.indexOf(ob);
		this.exclusions.splice(index,1);
		this.search();
	}


	ngOnChanges(change: any) {
		//this is never gonna let
		if(change.rule) {

			let rule = change.rule.currentValue;

			if(rule.selectedmanufacturers) {
				let brandvalues = [];

				rule.selectedmanufacturers.forEach( r => {

					let va = this.brands.filter(p => {
						return p.name = r
					})[0];

					if(va) {
						brandvalues.push(va.manufacturer_id)
					}
				});

				this.brand.setValue(brandvalues);
			}

			if(rule.selectedcategories) {
			let catvalues = [];
				rule.selectedcategories.forEach(p => {
					catvalues.push(p)
				});

				this.category.setValue(catvalues);
			}

			if(rule.selectedlines) {
				let pvalues = [];
				rule.selectedlines.forEach(p => {
					pvalues.push(p.value)
				});

				this.productLine.setValue(pvalues);
			}

			if(rule.range_start) {
				this.rangestart.setValue(rule.range_start)
			}
			if(rule.range_end) {
				this.rangeend.setValue(rule.range_end)
			}

			if(rule.exclusions) {
				this.exclusions = rule.exclusions
			}
			if(rule.productssel) {
				this.selectedproducts = rule.productssel
			}

			//run rule search.
			this.search();
		}

	}
}
