<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> User Groups {{ group.name }}</h4>
						<p class="card-category">User Groups</p>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-md-10">
								<mat-form-field class="full-width">
									<mat-label>Group Name</mat-label>
									<input matInput [formControl]="groupname">
								</mat-form-field>
							</div>
							<div class="col-2">
								<button mat-raised-button color="accent" (click)="saveAccess()">Update Group Settings</button>
							</div>
							<!-- access|modify -->
							<div class="col-md-12">
								<h4 class="ml-2">
									<mat-checkbox class="auth-margin" [checked]="allAccess" [labelPosition]="'after'" [color]="'accent'" (change)="setAllAccess($event.checked)">
										Access
									</mat-checkbox>
								</h4>
								<h4 class="ml-2"> Allowed Locations </h4>
								<mat-list #access class="permission-box" dense>

									<ng-container *ngFor="let route of access_list; let i = index; ">
										<mat-list-item>
											<mat-checkbox class="auth-margin" [checked]="route.authorized" [indeterminate]="updateAccessComplete(route)" [labelPosition]="'after'" [color]="'accent'" (change)="setChildAccessAuthorized($event.checked, route)">
												{{route.title}}
											</mat-checkbox>
										</mat-list-item>
										<ng-container *ngIf="route.children">
											<mat-list class="child-list">
												<mat-list-item *ngFor="let child of route.children ">
													<mat-checkbox class="auth-margin" [labelPosition]="'after'" [color]="'warn'" [checked]="child.authorized" (change)="updateAccess($event.checked, route, child)">
														{{child.title}}
													</mat-checkbox>
												</mat-list-item>
											</mat-list>
										</ng-container>
									</ng-container>
									<mat-divider></mat-divider>
								</mat-list>
							</div>
							<div class="col-md-6 d-none">
								<h4> Modify </h4>
								<mat-list #access class="permission-box" dense>
									<ng-container *ngFor="let route of ROUTES ">
										<mat-list-item>
											{{route.title}}
										</mat-list-item>
										<ng-container *ngIf="route.children">
											<mat-list class="child-list">
												<mat-list-item *ngFor="let child of route.children ">
													{{ child.title }}
												</mat-list-item>
											</mat-list>
										</ng-container>
									</ng-container>
									<mat-divider></mat-divider>
								</mat-list>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
