import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ViewportScroller } from "@angular/common";

import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { trigger, state, style, transition, animate } from '@angular/animations';
import * as Chartist from 'chartist';

import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

import { UntypedFormBuilder, UntypedFormGroup, Validators,UntypedFormControl} from '@angular/forms';

import { OmsService } from '../../services/oms.service';
import { OrdersService } from '../../services/orders.service';
import { PurchasingService } from '../../services/purchasing.service';
import { InventoryService } from '../../services/inventory.service';
import { VendorService } from '../../services/vendor.service';

import { Message } from '../../classes/message';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import * as XLSX from 'xlsx';

declare var $: any;

@Component({
  selector: 'app-warehouse-item-receiving',
  templateUrl: './warehouse-item-receiving.component.html',
  styleUrls: ['./warehouse-item-receiving.component.scss']
})
export class WarehouseItemReceivingComponent implements OnInit {

	@ViewChild("keywordstwo") keywordsField: ElementRef;
	@ViewChild("itemCodeRef") itemCodeRef: ElementRef;


	itemScanForm: UntypedFormGroup;
	itemSearchForm: UntypedFormGroup;
	SENDER: any;
	messages = [];
	message: any;
	CHAT_ROOM = "OrderBoard";

	status: any;
	pickable: any;
	carddata: any;
	sortcolumns: any;
	selectedColumn: any;
	picks: any = [];
	poSearch: UntypedFormControl = new UntypedFormControl('');
	itemCode: UntypedFormControl = new UntypedFormControl('');
	vendor: UntypedFormControl = new UntypedFormControl('');
	filteredResults: any = []
	filteredItems: any = [];

	completed_items: any = [];
	mustcomplete: any = [];

	linecomplete = {};
	itemcount = {};
	errors = [];
	error: any;
	address: any;
	linedetails = {};
	forcesearch: boolean = true;
	column: any;
	openOrderData: any;
	ordersFound: any;
	purchaseorders: any = [];
	selectedorder: any = [];
	purchoderdetails: any = [];
	searchsubscription: any = false;
	mustpick: any = [];
	journals: any = [];
	user: any = [];
	selectedpo: string = '';
	color: string = 'blue';

	item_bin = {
		stockid: '',
		bin: '',
		location: '',
		qty: 0,
	}

	scanResults: any = [];
	vendors: any = [];
	receviginorder: any = false;
	pageindex: number = 0;

	constructor(private vendorService: VendorService,public omsService: OmsService, public location: Location, public ordersService: OrdersService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, public formBuilder: UntypedFormBuilder, public inventoryService: InventoryService, private purchasingService: PurchasingService) {

		this.color = this.globalSearchService.getColor();
	 }

	ngOnInit(): void {

		const token = this.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
		if (token) {
			this.omsService.setupSocketConnection(token);
			this.omsService.subscribeToCards((err, data) => {

				if(data.message !='auto') {
				}
				//could interfere with each other. maybe only on a new purchase order?
				if(data.name == 'updatepick') {
					this.loadPos();
				}

				if(!this.column) {
				} else {
				}

			});
		}

		this.vendorService.getVendors().subscribe(results=>this.vendors = results);

		let all = {
			supplierid: "*",
			suppname: "ALl",
		}

		this.vendors.unshift(all)

		this.globalSearchService.user.subscribe( (result) => {
			this.user = result;
		});

		this.itemScanForm = this.formBuilder.group({
			keywords: ['', Validators.required],
			journal: ['', Validators.required],

		});

		//init show all

		this.route.params.subscribe(params => {
			if (params['id']) {
				this.receviginorder = params['id'];
				this.pageindex = 1;
				this.getOrderDetails(params['id'])
				this.itemScanForm.get('poSearch').setValue(params['id'])
			}
		});

		this.loadPos();
		this.getInboundOrders();



	}

	closeOpenNewJournal() {
		this.purchasingService.closeJournal(this.itemScanForm.value).subscribe( (result: any) => {
			this.journals = result;
			this.itemScanForm.get('journal').setValue(result[0]);
		});
	}

	getInboundOrders() {

		this.purchasingService.getOrders().subscribe( (results: any) => {
			this.purchaseorders = results.pos;

			this.journals = results.journals;
			this.itemScanForm.get('journal').setValue(results.journals[0]);
		});
	}

	itemScan() {

		if(this.searchsubscription) {
			this.searchsubscription.unsubscribe();
		}

		let data = { scan: this.itemCode.value, vendor: this.vendor.value }

		this.searchsubscription = this.purchasingService.getItemScan(data).subscribe( (result:any) => {

			let thisitem = this.mustpick.filter( (items:any) => {
				return items.stockid == result.stockid
			})

			let item  = thisitem[0];
			let index = this.mustpick.indexOf(item) ;

			if(index < 0 ) {

			} else {
				let floatvalue = parseFloat(this.mustpick[index].quantityrecd) + 1;
				this.mustpick[index].current_movement = 1;
				this.mustpick[index].quantityrecd = floatvalue;

				if(floatvalue > parseFloat(this.mustpick[index].quantityord)) {
					if(confirm("You are trying to receive more than ordered, Proceed?")) {

					} else {
						let floatvalue = parseFloat(this.mustpick[index].quantityrecd);
						this.mustpick[index].quantityrecd = floatvalue;
						this.mustpick[index].current_movement = 0;
					}
				}

				this.itemCode.setValue('');
				this.updatePick(this.mustpick[index]);
				if(this.mustpick[index].quantityrecd == this.mustpick[index].quantityord) {
					this.mustpick.splice(index, 1);
				}
			}
		})
	}

	filterItems() {
		if(this.vendor.value != '*') {
			this.mustpick = this.purchoderdetails.filter(r => {
				return r.supplierno == this.vendor.value
			})
		} else {
			this.mustpick = this.purchoderdetails;
		}
		this.itemCodeRef.nativeElement.focus();

	}

	itemSearch() {

		if(this.searchsubscription) {
			this.searchsubscription.unsubscribe();
		}

		this.searchsubscription = this.purchasingService.getPutAwaySearch(this.itemScanForm.value).subscribe((result: any)=> {

			this.purchoderdetails = result;
			this.mustpick = result;
			let process = true;
			if(result.length == 1) {
				let item  = result[0];
				let index = this.mustpick.indexOf(item) ;
				let floatvalue = parseFloat(this.mustpick[index].quantityrecd) + 1;
				this.mustpick[index].current_movement = 1;

				if(floatvalue > parseFloat(this.mustpick[index].quantityord)) {
					if(confirm("You are trying to receive more than ordered, Proceed?")) {

					} else {
						let floatvalue = parseFloat(this.mustpick[index].quantityrecd);
						this.mustpick[index].quantityrecd = floatvalue;
						this.mustpick[index].current_movement = 0;
						process = false;
					}
				}

				this.mustpick[index].quantityrecd = floatvalue;
				this.itemScanForm.controls['keywords'].setValue('');
				if(process) {
					this.updatePick(this.mustpick[index]);
				}
			}
		});
	}

	loadPos() {
		this.poSearch.setValue('');
		this.purchasingService.getPurchaseOrderDetails('*').subscribe((results: any) => {
			this.purchoderdetails = results;
			this.mustpick = results;

			if(this.receviginorder) {
				this.mustpick = this.purchoderdetails.filter(r => {
					return r.orderno == this.receviginorder
				})
			}

			/*
			//completed items
			this.mustpick = results.filter((item) => {
				return parseFloat(item.quantityrecd) < parseFloat(item.quantityord)
			})
			*/
		});
	}

	setBin(value: any, bin: any) {
		bin.bin = value.toUpperCase();
	}

	addBin(item) {

		let item_bin = {
			stockid: item.stockid,
			bin: '',
			location: item.intostocklocation,
			qty: 0,
		}

		item.bins.push(item_bin)
	}

	removeBin(bin, item) {

		var proceed = true;
		if(bin.qty > 0) {
			proceed = confirm("This Bin has qty, Proceed? Bin will be reduced by"+bin.qty );
		}

		if(proceed) {
			if(bin.qty > 0) {
				item.current_bin = bin;
				item.current_movement = -1 * bin.qty;

				let floatvalue = parseFloat(item.quantityrecd) + item.current_movement;
				bin.qty = bin.qty + item.current_movement;
				item.quantityrecd = floatvalue;
				this.updatePick(item);
			}

			if(item.bins.length > 1) {
				let index = item.bins.indexOf(bin);;
				item.bins.splice(index, 1);
			}
		}
	}

	getOrderDetails(orderno: any) {

		this.selectedorder = this.purchaseorders.filter((order: any)=>{
			return order.orderno = orderno;
		})[0];

		this.purchasingService.getPurchaseOrderDetails(orderno).subscribe((results: any) => {
			this.purchoderdetails = results;
			this.mustpick = results;
		});
	}

	setCheckValue(event, item) {

		let floatvalue = 0;
		if(event.checked) {
			floatvalue = parseFloat(item.quantityord);
			item.bins[0].qty = floatvalue;
			item.current_movement = floatvalue;
		} else {
			item.current_movement  = -1 * parseFloat(item.quantityord);
		}

		item.bins.splice(1,item.bins.length)

		item.bins[0].qty = floatvalue;
		item.quantityrecd = floatvalue;
		this.updatePick(item);
	}

	//todo move these methods to order pick much cleaner
	setQty(event: any, item: any, bin: any) {
		//current input - - current bin qty
		item.current_bin = bin;
		item.current_movement = parseFloat(event.target.value) - bin.qty;
		let floatvalue = parseFloat(item.quantityrecd) + item.current_movement;
		//text input handle
		if(event.target) {
			if(floatvalue > parseFloat(item.quantityord)) {
				if(confirm("You are trying to receive more than ordered, Proceed?")) {

					bin.qty = bin.qty + item.current_movement;
					item.quantityrecd = floatvalue;
					this.updatePick(item);
				} else {
					event.target.value = bin.qty
				}
			} else {
				bin.qty = bin.qty + item.current_movement;
				item.quantityrecd = floatvalue;
				this.updatePick(item);
			}
		}
	}

	addPick(bin: any, item: any) {

		item.current_movement = 1;
		item.current_bin = bin;
		let floatvalue = parseFloat(item.quantityrecd) + item.current_movement;

		if(floatvalue > parseFloat(item.quantityord)) {
			let proceed = confirm("You are trying to receive more than ordered, Proceed?");

			if(proceed) {
				bin.qty = bin.qty + item.current_movement;
				item.quantityrecd = floatvalue;
				this.updatePick(item);
			} else {
				item.current_movement = 0;
			}

		} else {
			bin.qty = bin.qty + item.current_movement;
			item.quantityrecd = floatvalue;
			this.updatePick(item);
		}
	}

	removePick(bin: any, item: any) {
		item.current_movement = -1
		item.current_bin = bin;
		//new total valur
		let floatvalue = parseFloat(item.quantityrecd) + parseFloat(item.current_movement);

		if(floatvalue < 0) {
			//no update
			floatvalue = 0;
			item.current_movement = 0;
			bin.qty = bin.qty;
		} else {
			item.current_movement = -1;
			item.quantityrecd = floatvalue;
			bin.qty = bin.qty + item.current_movement;
			this.updatePick(item);
		}
	}

	updatePick(item: any) {

		var data = { item, user: this.user.user, form: this.itemScanForm.value};
		if (data) {
			//send to socket
			this.omsService.sendPutUpdate({data, roomName: this.CHAT_ROOM}, cb => {
				//doesnt update auto complete?
				this.purchasingService.getOrders().subscribe( (results: any) => {
					this.purchaseorders = results.pos;
				});
			});
		}
	}

	back(): void {
		this.location.back()
	}

	finished() {
		return false;
	}

	randomString(length: any, chars: any) {
		var result = '';
		for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
		return result;
	}

}
