<div class="main-content" *ngIf="vendorForm">
	<div class="container-fluid" *ngIf="vd">
		<div class="card">
			<div class="card-header card-header-{{ color}}-5">
				<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i>Create Vendor</h4>
				<p class="card-category"></p>
			</div>
			<div class="card-body">
				<mat-stepper linear #stepper>
					<form [formGroup]="vendorForm" class="text-dark" >

						<div class="row">
							<mat-step [stepControl]="vendorForm.get('supplierid').valid">
								<ng-template matStepLabel color="purple" >Billing Information</ng-template>

								<div class="row">
									<div class="col-md-8">
										<app-address-lookup (setAddress)="getAddress($event)" adressType="establishment"></app-address-lookup>
									</div>
									<div class="col-md-4 text-right">
										<button mat-button matStepperNext mat-raised-button color="accent" >Next</button>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<mat-form-field class="full-width" appearance="standard">
											<mat-label>Vendor ID</mat-label>
											<input matInput required formControlName="supplierid" placeholder="Vendor ID" [value]="vd?.supplierid">
										</mat-form-field>
									</div>
									<div class="col-md-6">
										<mat-form-field class="full-width" appearance="standard">
											<mat-label>Vendor Name</mat-label>
											<input matInput formControlName="suppname" placeholder="Vendor Name" [value]="vd?.suppname">
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<mat-form-field class="full-width" appearance="standard">
											<mat-label>Address 1</mat-label>
											<input matInput placeholder="Vendor Name" formControlName="address1" [value]="vd?.address1">
										</mat-form-field>
									</div>
									<div class="col-md-6">
										<mat-form-field class="full-width" appearance="standard">
											<mat-label>Address 2</mat-label>
											<input matInput placeholder="Address 2" formControlName="address2" [value]="vd?.address2">
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4">
										<mat-form-field class="full-width" appearance="standard">
											<mat-label>City</mat-label>
											<input matInput placeholder="City" formControlName="address3" [value]="vd?.address3">
										</mat-form-field>
									</div>
									<div class="col-md-4">
										<mat-form-field appearance="standard">
											<mat-label>Region</mat-label>
											<mat-select formControlName="address4">
												<mat-option *ngFor="let zone of zones.zones" [value]="zone.code">
													{{zone.code }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-4">
										<mat-form-field class="full-width" appearance="standard">
											<input matInput placeholder="Postal" formControlName="address5" [value]="vd?.address5">
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<mat-form-field appearance="standard" class="">
											<mat-label>Country</mat-label>
											<mat-select formControlName="address6" (selectionChange)="updateZones($event)">
												<mat-option *ngFor="let zone of zones.countries" [value]="zone.name">
													{{ zone.name }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>



							</mat-step>
							<mat-step>
								<ng-template matStepLabel color="purple">Contact Information</ng-template>
								<div class="row">
									<div class="col-md-4 text-left">
										<button mat-button matStepperPrevious mat-raised-button color="accent" >Back</button>
									</div>
									<div class="col-8 text-right ml-0 mb-4">
										<button mat-raised-button type="submit" class="btn btn-danger pull-right" (click)="updateVendor()">Add Vendor</button>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="card card-profile">
											<div class="card-header card-header-danger card-header-icon">
												<div class="card-icon">
													<i class="material-icons">contact_mail</i>
												</div>
												<p class="card-category"><b>Vendor Contact</b></p>
											</div>
											<div class="card-body">
												<div class="row">
													<div class="col-md-6">

														<mat-form-field class="full-width" appearance="standard">
															<mat-label>Email</mat-label>
															<input formControlName="email" matInput placeholder="Vendor Email">
														</mat-form-field>
													</div>
													<div class="col-md-6">
														<mat-form-field class="full-width" appearance="standard">
															<mat-label>Phone</mat-label>
															<input formControlName="telephone" matInput placeholder="Vendor Phone" mask="(000) 000-0000" [showMaskTyped]="true">
														</mat-form-field>
													</div>
													<div class="col-md-6">
														<mat-form-field class="full-width" appearance="standard">
															<mat-label>Website</mat-label>
															<input formControlName="url" matInput placeholder="WebSite">
														</mat-form-field>
													</div>
													<div class="col-md-6">
														<mat-form-field appearance="standard" *ngIf="types">
															<mat-label>Vendor Type</mat-label>
															<mat-select required formControlName="supptype" [(value)]="vd.supptype">
																<mat-option *ngFor="let type of types.supptypes" [value]="type.typeid">{{type.typename}}</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="card card-profile">
											<div class="card-header card-header-warning card-header-icon">
												<div class="card-icon">
													<i class="material-icons">settings</i>
												</div>
												<p class="card-category"><b>Settings</b></p>
											</div>
											<div class="card-body">
												<div class="row">
													<div class="col-md-6">
														<mat-form-field appearance="standard" class="ml-2 mr-2" *ngIf="paymentterms">
															<mat-label>Payment Terms</mat-label>
															<mat-select [(value)]="vd.paymentterms" formControlName="paymentterms">
																<mat-option *ngFor="let term of paymentterms" [value]="term?.termsindicator">{{term.terms}}</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
													<div class="col-md-6">
														<mat-form-field appearance="standard" class="ml-2 mr-2" *ngIf="taxes">
															<mat-label>Tax Group</mat-label>
															<mat-select formControlName="taxgroupid" [(value)]="vd.taxgroupid">
																<mat-option *ngFor="let tax of taxes" [value]="tax?.taxgroupid">{{tax.taxgroupdescription}}</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<mat-form-field appearance="standard" class="ml-2 mr-2" *ngIf="currencies">
															<mat-label>Currency</mat-label>
															<mat-select formControlName="currcode" [(value)]="vd.currcode">
																<mat-option *ngFor="let currency of currencies" [value]="currency?.currabrev">
																	{{currency.currabrev}} {{currency.currency}}
																</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
													<div class="col-md-6">
														<mat-form-field appearance="standard" class="ml-2 mr-2" *ngIf="bankaccounts">
															<mat-label>Bank Account</mat-label>
															<mat-select formControlName="bankact">
																<mat-option *ngFor="let account of bankaccounts" [value]="account.accountcode">{{account.bankaccountname}}</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

							</mat-step>
						</div>
					</form>
				</mat-stepper>
			</div>
		</div>
	</div>
</div>
