<div class="main-content" #editRow>
	<div class="card tp-box m-0 p-0">
		<div class="card-header card-header-{{config.color}}-5"> COUNTER POS RECEIPT</div>
		<div class="card-body">

				<ng-container *ngIf="receipt_data">
					<div class="row m-0 p-0s">

						<div class="col-12 text-center">
							<h1 class="text-success">SUCCESS</h1>
							<h4 class="bold">DOCUMENT#: {{ receipt_data.transno }}</h4>
							<h4 class="bold">INVOICE#: {{ receipt_data.orderno }}</h4>
						</div>
						<div class="col-12 text-center">
							<div class="circle-loader load-complete">
								<div class="checkmark-complete draw checkmark"></div>
							</div>
						</div>
						<div class="col-md-4 ml-auto mr-auto">
							<a mat-flat-button color="primary" class="bg-primary fullsize" href="{{receipt_data.link}}"><div class="pt-4 mt-2">RE-PRINT</div></a>
						</div>
						<div class="col-md-4 text-center ml-auto mr-auto">
							<button mat-flat-button color="danger" class="bg-danger text-white fullsize m-0 p-0" (click)="$event.preventDefault();newOrder()">NEW ORDER</button>
						</div>
						<div class="col-md-4 ml-auto mr-auto">
							<button mat-flat-button color="success" class="bg-success fullsize m-0 p-0" (click)="$event.preventDefault();emailReceipt()">EMAIL</button>
							<mat-form-field class="w-100" appearance="outline">
								<mat-label>Email</mat-label>
								<input matInput [formControl]="email" matInput placeholder="Email Note">
							</mat-form-field>
						</div>
						<div class="col-md-12 text-center">
							<div class="ml-auto mr-auto rec ml-auto mr-auto">
								<div innerHTML="{{ recDisplay }}"></div>
							</div>
						</div>
					</div>
				</ng-container>
		</div>
	</div>
</div>
