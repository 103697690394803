import { Component, OnInit } from '@angular/core';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { Location } from '@angular/common'
@Component({
  selector: 'app-purchasing-reordering',
  templateUrl: './purchasing-reordering.component.html',
  styleUrls: ['./purchasing-reordering.component.scss']
})
export class PurchasingReorderingComponent implements OnInit {
	color: string = 'blue';
	constructor(private location: Location, private globalSearchService:GlobalSearchService) {

		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
	}

	back(): void {
		this.location.back()
	}
}
