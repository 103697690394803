<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-5">
						<h4 class="card-title" id="headof"><i class="material-icons">arrow_left</i> Order Invoiced</h4>
						<p class="card-category">
							<b></b>
						</p>
					</div>
					<div class="card-body">
						<div class="col-12 text-center">
							<h1 class="text-success">SUCCESS</h1>
							<h4 class="bold">DOCUMENT#: {{ invoice.transno }}</h4>
							<h4 class="bold">INVOICE#: {{ invoice.order_ }}</h4>
						</div>
						<div class="col-12 text-center">
							<div class="circle-loader load-complete">
								<div class="checkmark-complete draw checkmark"></div>
							</div>
						</div>
						<div class="col-12 text-center">
							<a href="{{ reprintlink }}" mat-raised-button color="warn"><i class="fa fa-file-pdf-o"> </i>&nbsp;Print Invoice</a>
							&nbsp;&nbsp;<a mat-raised-button class="btn btn-warning " [routerLink]="'/customers/view/'+invoice.debtorno"> View Customer </a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
