import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';

@Injectable({
	providedIn: 'root'
})

export class GlobalsService {

	key: string = 'UET6DFMPAXW7BKCB';
	config: any;
	color: string = 'blue';
	user : any = [];

	constructor(private http: HttpClient, private httpClient: HttpClient, private _configSvc: RuntimeConfigLoaderService) {
		this.config = this._configSvc.getConfig()
		let user = JSON.parse(localStorage.getItem('user'));
		if(user !== null && user) {
			this.user = user;
		}
	}

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	public setColor(value: any): string {
		localStorage.setItem('color', JSON.stringify(value))
		this.color = value;
		return value;
	}

	public getColor(): string {
		let color = JSON.parse(localStorage.getItem('color'));
		if (color === null) {
			color = 'blue';
			localStorage.setItem('color', JSON.stringify('blue'))
		}
		this.color = color;
		return color;
	}

	public getApiConfig() {
		this.config.color = this.getColor();
		return this.config;
	}
	public getItemBarcodes(stockid: any) {
		var method = 'inventory/barcodes&stockid='+stockid;
		return this.http.get(this.setEndPoint(method));
	}

	public getItemSetup() {
		var method = 'inventory/properties';
		return this.http.get(this.setEndPoint(method));
	}
	public getInvoice(id: any, display: any) {
		var method = 'documents/invoice&id=' + id + '&display=' + display;
		return this.http.get(this.setEndPoint(method));
	}
	public getTransfer(id: any, display: any) {
		var method = 'documents/transfer&id=' + id + '&display=' + display;
		return this.http.get(this.setEndPoint(method));
	}
	public getVendorInvoice(id: any, display: any) {
		var method = 'documents/vendorinvoice&id=' + id + '&display=' + display;
		return this.http.get(this.setEndPoint(method));
	}

	public getPOSReceipt(id: any, display: any) {
		var method = 'documents/posreceipt&id=' + id + '&display=' + display;
		return this.http.get(this.setEndPoint(method));
	}

	public emailInvoice(id: any, display: any, email: any) {
		var method = 'documents/invoice&id=' + id + '&display=' + display + '&email=' + email;
		return this.http.get(this.setEndPoint(method));
	}
	//WIP


	public emailQuoteInvoice(id: any, email:any, display: any) {
		var method = `documents/quote&id=${id}&email=${email}&display=${display}`;
		return this.http.get(this.setEndPoint(method));
	}

	public getQuoteInvoice(id: any, display: any) {
		var method = `documents/quote&id=${id}&display=${display}`;
		return this.http.get(this.setEndPoint(method));
	}

	public removeSalesType(data: any) {
		var method = 'globals/types/salestyperemove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public removeCustomerType(data: any) {
		var method = 'globals/types/customertyperemove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public removePaymentTerm(data: any) {
		var method = 'globals/terms/remove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public savePaymentTerm(data: any) {
		var method = 'globals/terms/add';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public saveCustomerType(data: any) {
		var method = 'globals/types/customertypeupdate';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public saveVendorType(data: any) {
		var method = 'globals/types/vendortypeupdate';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public removeVendorType(data: any) {
		var method = 'globals/types/vendortyperemove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public saveSalesType(data: any) {
		var method = 'globals/types/salestypeupdate';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public getCreditNote(id: any, display: any) {
		var method = 'documents/credits&id=' + id + '&display=' + display;
		return this.http.get(this.setEndPoint(method));
	}
	public emailCreditNote(id: any, display: any, email: any) {
		var method = 'documents/credits&id=' + id + '&display=' + display + '&email=' + email;
		return this.http.get(this.setEndPoint(method));
	}

	public getHowPaid(transno: any, type: any) {
		var method = 'payments/allocations/view&id=' + transno + '&type=' + type;
		return this.http.get(this.setEndPoint(method));
	}

	public getSupplierHowPaid(transno: any, type: any) {
		var method = 'payments/allocations/purchaseview&id=' + transno + '&type=' + type;
		return this.http.get(this.setEndPoint(method));
	}

	public getTransactionGl(transno: any, type: any) {
		var method = 'globals/glaccounts/transaction&transno=' + transno + '&type=' + type;
		return this.http.get(this.setEndPoint(method));
	}

	public getAllocations(id: any) {
		var method = 'payments/allocations/view&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public getVendoorAllocations(id: any) {
		var method = 'vendor/allocations/view&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public dealllocateDocument(data:any) {
		var method = 'globals/allocations/deallocatesupptrans';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getDataSets() {
		var method = 'globals/datasets';
		return this.http.get(this.setEndPoint(method));
	}

	public getPaymentMethods() {
		var method = 'globals/paymentmethods';
		return this.http.get(this.setEndPoint(method));
	}
	public getSettings() {
		var method = 'globals/settings/setting';
		return this.http.get(this.setEndPoint(method));
	}

	public getCompany() {
		var method = 'globals/settings/company';
		return this.http.get(this.setEndPoint(method));
	}
    public getStores(){
		var method = 'globals/stores';
		return this.http.get(this.setEndPoint(method));
	}
	public saveStore(data: any) {
		var method = 'globals/stores/save';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	 public getSalesPeople(){
		var method = 'globals/salesman/fulldetails';
		return this.http.get(this.setEndPoint(method));
	}
	public saveSalesPerson(data: any) {
		var method = 'globals/salesman/save';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public getDregPayments(data){
		var method = 'dreg/payments';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public postDreg(dreg){
		var method = 'dreg/postdreg';
		return this.http.post(this.setEndPoint(method), {dregdata: dreg}, this.httpOptions).pipe(
			catchError(this.handleError(method, dreg))
		);
	}
	public getDailyARTotals(data) {
		var method = 'globals/dayend';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public postDailyARTotals(data) {
		var method = 'globals/dayend/submit';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getRSI(data) {
		var method = 'globals/dayend/rsi';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public updateLocation(data) {
		var method = 'globals/locations/update';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public updateCompany(data) {
		var method = 'globals/settings/updatecompany'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public getConfig() {
		var method = 'globals/settings/config';
		return this.http.get(this.setEndPoint(method));
	}

	public getTypes() {
		var method = 'globals/types';
		return this.http.get(this.setEndPoint(method));
	}

	public getHoldReasons() {
		var method = 'globals/types/holdreasons';
		return this.http.get(this.setEndPoint(method));
	}

	public getCountryZoness(id: any) {
		var method = 'globals/zones/country&country_id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public getCountryZones(id: any) {
		var method = 'globals/zones/country&country_id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public getDefaultZones() {
		var method = 'globals/zones/defaultzone';
		return this.http.get(this.setEndPoint(method));
	}

	public getCustomerTypes() {
		var method = 'globals/types/customer';
		return this.http.get(this.setEndPoint(method));
	}

	public getSalesTypes() {
		var method = 'globals/types/sales';
		return this.http.get(this.setEndPoint(method));
	}

	public getShipVia() {
		var method = 'globals/shipvia';
		return this.http.get(this.setEndPoint(method));
	}

	public getSalesman() {
		var method = 'globals/salesman';
		return this.http.get(this.setEndPoint(method));
	}

	public getAreas() {
		var method = 'globals/areas';
		return this.http.get(this.setEndPoint(method));
	}

	public getLocations() {

		let user = JSON.parse(localStorage.getItem('user'));
		if(user === null ) {
			  ///return false;
		} else {
			this.user = user;
		}

		let addon = (this.user.user) ? '&userid=' + this.user.user.userid : '';
		var method = 'globals/locations&userid='+addon;
		return this.http.get(this.setEndPoint(method));
	}

	public getUserLocations(){

		let user = JSON.parse(localStorage.getItem('user'));
		if(user === null ) {
  			///return false;
		} else {
			this.user = user;
		}

		let addon = (this.user.user) ? '&userid=' + this.user.user.userid : '';
		var method = 'globals/locations&userid='+addon;
		return this.http.get(this.setEndPoint(method));
	}

	public getAllLocations() {
		var method = 'globals/locations/all';
		return this.http.get(this.setEndPoint(method));
	}

	public getLocation(data) {
		var method = 'globals/locations/get';
		return this.http.post(this.setEndPoint(method), { loccode: data }, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getBankAccounts() {
		var method = 'globals/bankaccounts';
		return this.http.get(this.setEndPoint(method));
	}

	public getBankAccount(account:any) {
		var method = 'globals/bankaccounts&account='+account;
		return this.http.get(this.setEndPoint(method));
	}

	public deleteBankAccount(account:any) {
		var method = 'globals/bankaccounts/remove&account='+account;
		return this.http.get(this.setEndPoint(method));
	}

	public saveBankAccount(data:any) {
		var method = 'globals/bankaccounts/save';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public getAccountSections() {
		var method = 'globals/glaccounts/sections';
		return this.http.get(this.setEndPoint(method));
	}
	public getAccountSection(data) {
		var method = 'globals/glaccounts/sections';
		return this.http.post(this.setEndPoint(method), { sectionid: data }, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public updateSection(data) {
		var method = 'globals/glaccounts/sectionupdate';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public deleteSection(data) {
		var method = 'globals/glaccounts/sectiondelete';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getAccountGroups() {
		var method = 'globals/glaccounts/groups';
		return this.http.get(this.setEndPoint(method));
	}
	public getAccountGroup(data) {
		var method = 'globals/glaccounts/groups';
		return this.http.post(this.setEndPoint(method), { groupname: data }, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public updateGroup(data) {
		var method = 'globals/glaccounts/groupupdate';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public deleteGroup(data) {
		var method = 'globals/glaccounts/groupdelete';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getGLAccounts() {
		var method = 'globals/glaccounts';
		return this.http.get(this.setEndPoint(method));
	}
	public getGLAccount(data) {
		var method = 'globals/glaccounts';
		return this.http.post(this.setEndPoint(method), { accountcode: data }, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public updateGLAccount(data) {
		var method = 'globals/glaccounts/update';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public deleteGLAccount(data) {
		var method = 'globals/glaccounts/gldelete';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public getProdLineMarkups(data) {
		var method = 'globals/inventory/getprodlinemarkup';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public saveProdLineMarkup(data) {
		var method = 'globals/inventory/saveprodlinemarkup';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public deleteProdLineMarkup(data) {
		var method = 'globals/inventory/removeprodlinemarkup';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getTransAllocations(data) {

		var method = 'globals/allocations/transallocations';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getAllocationTrans(data: any) {
		var method = 'globals/allocations';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public getAllocationData(data) {
		var method = 'globals/allocations/transallocations';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public addAllocation(data) {
		var method = 'globals/allocations/save';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public removeAllocation(data) {
		var method = 'globals/allocations/remove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public getTerms() {
		var method = 'globals/terms';
		return this.http.get(this.setEndPoint(method));
	}

	public getTaxes() {
		var method = 'globals/taxes';
		return this.http.get(this.setEndPoint(method));
	}
	public getRemoteAPISettings() {
		var method = 'globals/rapi';
		return this.http.get(this.setEndPoint(method));
	}
	public getRemoteAPI(data) {
		var method = 'globals/rapi/fetch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getRemoteMap(data) {
		var method = 'globals/rapi/fetchmap';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getRemoteEx(data) {
		var method = 'globals/rapi/fetchex';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public saveRemote(data) {
		var method = 'globals/rapi/save';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public delRemote(data) {
		var method = 'globals/rapi/remove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getCurrencies() {
		var method = 'globals/currancy';
		return this.http.get(this.setEndPoint(method));
	}

	public getTerm(code: any) {
		var method = 'inventory/history';
		return this.http.get(this.setEndPoint(method));
	}

	public lookup(data) {
		var method = 'inventory'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey() + this.getPackage();

	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getPackage() {
		let p = localStorage.getItem('app')
		if(p) {
			return '&package='+p;
		} else {
			return '';
		}
	}

	private getApiKey() {
		let addon = (this.user.user) ? '&userid=' + this.user.user.userid : '';
		return '&key=' + this.config.apiCredentials.clientId  + addon;
	}

	public getKey() {
		return '&apikey=' + this.key;
	}

	isEmail(search: string): boolean {
		var serchfind: boolean;
		let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		serchfind = regexp.test(search);
		return serchfind
	}

	private handleError < T > (operation = 'operation', result ? : T) {
		return (error: any): Observable < T > => {
			return error;
		};
	}
}
