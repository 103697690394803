<ng-container>
	<!--
	<code>
		<pre>
			{{ vendor_data | json }}
		</pre>
	</code>
	-->
	<div class="row mt-3">
		<div class="col-md-4 text-center" >
			<h6>Currency</h6>
			{{ vendor_data.currcode }}
		</div>
		<div class="col-md-4 text-center">
			<h6> Terms</h6>
			{{ vendor_data.terms }}
		</div>
		<div class="col-md-4 text-center">
			<h6>Tax Group</h6>
			{{ vendor_data.taxgroupdescription }}
		</div>
		<mat-divider></mat-divider>
	</div>

	<form [formGroup]="invoiceForm" (ngSubmit)="onSubmit()" *ngIf="invoiceForm" (keydown.enter)="$event.preventDefault()" class="no-overflow">
		<ng-template matStepLabel>Credit Information</ng-template>
		<div class="row">
			<div class="col-md-3">
				<mat-form-field appearance="standard">
					<mat-label>Reference</mat-label>
					<input matInput required value="" formControlName="reference">
				</mat-form-field>
			</div>
			<div class="col-md-3">
				<mat-form-field appearance="standard">
					<mat-label>Credit Date</mat-label>
					<input matInput [matDatepicker]="invoiceDate" formControlName="invoiceDate">
					<mat-datepicker-toggle matSuffix [for]="invoiceDate"></mat-datepicker-toggle>
					<mat-datepicker #invoiceDate></mat-datepicker>
				</mat-form-field>
			</div>
			<div class="col-md-3">
				<mat-form-field appearance="standard">
					<mat-label>Discount Date</mat-label>
					<input matInput [matDatepicker]="discountDate" formControlName="discountDate">
					<mat-datepicker-toggle matSuffix [for]="discountDate"></mat-datepicker-toggle>
					<mat-datepicker #discountDate></mat-datepicker>
				</mat-form-field>
			</div>
			<div class="col-md-3">
				<mat-form-field appearance="standard" class="text-right">
					<mat-label>Discount Amount</mat-label>
					<span matPrefix>$</span>
					<input matInput formControlName="discountAmount" (input)="setDiscount($event)">
				</mat-form-field>
			</div>
			<div class="col-md-12" *ngIf="vendor_data.currcode != company.currencydefault">
				<mat-form-field appearance="standard" class="text-right">
					<mat-label>Exchange Rate</mat-label>
					<input matInput required value="" formControlName="rate">
					<span matSuffix>%</span>
				</mat-form-field>
			</div>

		</div>
		<div class="row">
			<div class="col-md-6">
				<mat-form-field class="full-width" appearance="standard">
					<mat-label>GRN's</mat-label>
					<mat-chip-list #grnList aria-label="Selected Pos">
						<mat-chip *ngFor="let grn of selected_grnbatches" (removed)="remove(grn)">
							GRN#: {{ grn.grnbatch }} PO#:{{grn.orderno}}
							<button matChipRemove>
								<mat-icon>cancel</mat-icon>
							</button>
						</mat-chip>
						<input type="text" #grnInput placeholder="Select Purchase Order" [matChipInputFor]="grnList" matInput [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)" formControlName="grns" [matAutocomplete]="auto">
					</mat-chip-list>
					<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
						<mat-option *ngFor="let grn of grn_batches" [value]="grn">
							GRN#: {{ grn.grnbatch }} PO#:{{grn.orderno}}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>

			</div>
			<div class="col-md-6" [ngClass]="selected_grns.length >= 1 ? 'mt-3' : '' ">
				<app-gl-account-lookup (glselected)="addGl($event)"></app-gl-account-lookup>
			</div>
			<div class="col-md-12">
				<table class="table table-sm" *ngIf="selected_grns.length || glcharges.length">
					<tr>
						<th>Item</th>
						<th>Desc</th>
						<th>Received</th>
						<th>PO Price</th>
						<th>Invoice#</th>
						<th>Invoice Price</th>
						<th class="text-right">Line Total</th>
						<th class="text-right"></th>
					</tr>
					<tr *ngFor="let grn of selected_grns">
						<td class="pt-4">{{ grn.itemcode }}</td>
						<td class="pt-4">{{ grn.itemdescription }}</td>
						<td class="pt-4" [ngClass]="grn.qtyrecd != grn.quantityord ? 'text-danger bold': 'text-success bold'">{{ grn.qtyrecd }} / {{grn.quantityord}}</td>
						<td class="pt-4">{{ grn.unitprice | currency}}</td>
						<td>
							<mat-form-field appearance="standard" class="text-center col-10">
								<mat-label>Credit Qty.</mat-label>
								<input matInput required [(value)]="grn.toinvoice" (input)="setQty($event, grn)" appSelectOnFocus>
							</mat-form-field>
						</td>
						<td>
							<mat-form-field appearance="standard" class="text-right col-10">
								<span matPrefix>$</span>
								<mat-label>Credit Price</mat-label>
								<input matInput required [(value)]="grn.unitprice" (input)="setPrice($event, grn)" moneyPipe appSelectOnFocus>
							</mat-form-field>
						</td>
						<td class="pt-4">
							<b> {{ grn.unitprice * grn.toinvoice | currency}}</b>
						</td>
						<td class="pt-4">
							x <button mat-flat-button (click)="removeLine(grn)">
								<i class="material-icons">remove</i>
							</button>
						</td>
					</tr>
					<tr *ngFor="let gl of glcharges">
						<td class="pt-4">{{ gl.account }}</td>
						<td class="pt-4">{{ gl.name }}</td>
						<td></td>
						<td></td>
						<td></td>
						<td>
							<mat-form-field appearance="standard" class="text-right col-10">
								<span matPrefix>$</span>
								<mat-label>Price</mat-label>
								<input matInput required [(value)]="gl.value" (input)="setGlPrice($event, gl)" moneyPipe appSelectOnFocus>
							</mat-form-field>
						</td>
						<td class="pt-4 text-right">
							<b> {{ gl.value * gl.quantity | currency}}</b>
						</td>
						<td class="pt-4 text-right">
							<button mat-flat-button (click)="removeGl(gl)">
								<i class="material-icons">remove</i>
							</button>
						</td>
					</tr>
				</table>
				<div class="row">
					<div class="col-12 text-right" *ngIf="selected_grns.length || glcharges.length">
						<h5>Sub-Total: {{ invoiceTotal | currency }}</h5>
						<ng-container>
							<div class="ml-auto text-right" *ngFor="let tax of vendor_data.taxes">
								<mat-form-field appearance="fill" class="ml-auto col-2 text-right">
									<span matPrefix>$</span>
									<mat-label>{{ tax.description }}</mat-label>
									<input matInput required value="{{tax.taxamount | number:'1.2-2'}}" (input)="setTaxValue($event, tax)" appSelectOnFocus>
								</mat-form-field>
							</div>
						</ng-container>
						<div class="row">
							<div class="col-md-12">
								<h5 class="mr-4">Tax: {{ taxTotal | currency }}</h5>
								<h5 class="mr-4">Discount: {{ discount | currency }}</h5>
								<h5 class="mr-4">Total: {{ totalTotal | currency }}</h5>
								<button mat-raised-button [color]="invoiceForm.valid ? 'primary' : 'accent'">Create Credit</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
	<div class="text-center bold" *ngIf="!selected_grns.length  && !glcharges.length">
		Add Purchase Orders And/Or GL Charges
	</div>
</ng-container>
