<div class="row">
	<div class="col-md-6  text-left">
		<button mat-raised-button color="accent" (click)="addNote()">Add Note</button>
	</div>
	<div class="col-md-6 text-right">
		<button mat-raised-button class="btn btn-primary" (click)="onSubmit()">Save</button>
	</div>
</div>
<ul @grow class="list-group list-group-flush p-0 m-0">

	<!-- <ng-container *ngIf="editingnote">
		<form [formGroup]="noteForm" *ngIf="noteForm">
			<div class="row mt-2">
				<div class="col-md-6">
					<mat-form-field class="full-width" appearance="standard" >
						<mat-label>note</mat-label>
						<input matInput formControlName="note" placeholder="note" >
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="standard" class="" formControlName="note_code" >
						<mat-label>Note Type</mat-label>
						<mat-select
						required

						>
						<mat-option *ngFor="let type of notetypes" [value]="type">
							{{type}}
						</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>

			<button mat-raised-button color="primary" (click)="addContact()">Save  Contact</button>
		</form>
	</ng-container> -->

	<li class="list-group-item border-bottom mt-0 mb-0 pt-1 pb-1" *ngFor="let note of customer.notes ">
		<div class="row border-bottom">
			<div class="col-md-12">
				<mat-form-field appearance="standard" class="" >
					<mat-label>Note Type</mat-label>
					<mat-select
					required
					(selectionChange)="updateTopic($event, note)"
					[(value)] ="note.note_code"
					>
					<mat-option *ngFor="let type of notetypes" [value]="type">
						{{type}}
					</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-12">
				<mat-form-field class="full-width" appearance="standard">
					<mat-label>Note</mat-label>
					<input matInput required placeholder="Note" name="note[{{note.noteid}}]" value="{{ note.note }}" (input)="updateNote($event, note)" >
				 	<mat-icon matSuffix (click)="removeNote(note.noteid)">remove</mat-icon>
		 		</mat-form-field>
			</div>
		</div>
	</li>
</ul>
