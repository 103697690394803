import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { UserGroup, User } from '../../classes/user';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { DispatchService } from '../../services/dispatch.service';
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

	public loginForm: UntypedFormGroup;
	public registerForm: UntypedFormGroup;
	public logo: any = '/assets/logo.png';
	public result: any;
	public user: any;
	public txterror: any;
	public config: any = false;


	constructor(private globalsService: GlobalsService, private dispatchService: DispatchService, private formBuilder: UntypedFormBuilder, private loginService: LoginService, public router: Router, public globalSearchService: GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		});
		this.logo = '/assets/'+this.config.env.package+'.png'
		this.createLoginForm();
		this.createRegisterForm();
	}

	createLoginForm() {
		this.loginForm = this.formBuilder.group({
			userName: [''],
			password: [''],
			txterror: [''],
		})
	}
	createRegisterForm() {
		this.registerForm = this.formBuilder.group({
			userName: [''],
			password: [''],
			confirmPassword: [''],
			txterror: [''],
		})
	}

	onLogin() {

		this.loginService.login(this.loginForm.value).subscribe((user: any) => {
			this.user = user;
			if(user.pass) {
				localStorage.setItem('user', JSON.stringify(this.user))

				this.globalSearchService.getColor()
				this.globalSearchService.user.next(this.user);
				this.globalSearchService.selectedlocation.next(this.user.user.defaultlocation.loccode)

				//user is assigned to a run. move them to load sheet.
				this.dispatchService.getTrucks().subscribe((result:any) => {
					if(result.length == 1){
						this.router.navigate(['/dispatches/load-sheet']);
					} else {
						this.router.navigate(['dashboard']);
					}
				});
			} else {
				this.txterror = user.error
			}
		});

	}

	ngOnInit() {

	}

}
