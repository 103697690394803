import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy, PopStateEvent } from '@angular/common';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { Router} from '@angular/router';
import 'rxjs/add/operator/filter';
import * as $ from "jquery";

@Component({
  selector: 'app-chat-sidebar',
  templateUrl: './chat-sidebar.component.html',
  styleUrls: ['./chat-sidebar.component.scss']
})
export class ChatSidebarComponent implements OnInit {
messagecount = 0;
active: boolean = true;
  constructor(private globalSearchService: GlobalSearchService,private router: Router) { }

  ngOnInit(): void {

	this.globalSearchService.messages.subscribe((results: any) => {
		this.messagecount = results;
	});

	this.active = false;
	// this.globalSearchService.mesagesallowed.subscribe((results: any) => {
	// 	this.active = results;
	// });


	this.messagecount = 0;
	  const window_width = $(window).width();
      let $sidebar = $('.sidebar');
      let $sidebar_responsive = $('body > .navbar-collapse');
      let $sidebar_img_container = $sidebar.find('.sidebar-background');

      $('.fixed-plugin .badge').click(function(){
	      let $full_page_background = $('.full-page-background');


	      $(this).siblings().removeClass('active');
	      $(this).addClass('active');

	      var new_color = $(this).data('color');

	      if($sidebar.length !== 0){
	          $sidebar.attr('data-color', new_color);
	      }

	      if($sidebar_responsive.length != 0){
	          $sidebar_responsive.attr('data-color',new_color);
	      }
	  });
  }

  resetCount() {
	  	this.messagecount = 0;
  	}
}
