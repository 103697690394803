import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { InventoryService } from 'app/services/inventory.service';
import { PrintService } from 'app/services/print.service';
import { element } from 'protractor';
import { quantity } from 'chartist';
import { L } from '@angular/cdk/keycodes';
// import { da } from 'date-fns/locale';
// import { Paginator } from 'primeng/paginator';

@Component({
	selector: 'app-bommodal',
	templateUrl: './bommodal.component.html',
	styleUrls: ['./bommodal.component.scss']
})
export class BommodalComponent implements OnInit, OnChanges {
	@Input() openclosed: any = false;
	@Output() closeEvent = new EventEmitter<boolean>();
	@ViewChild('paginator') paginator: MatPaginator;
	@ViewChild("breakdownModal") breakdownKit: ElementRef;



	lookup = new UntypedFormControl('');
	componentlookup = new UntypedFormControl('');
	searchsub: any;
	searching: boolean;
	itemlist: any;
	showlist: boolean;
	noresults: boolean;
	activeBom: any = [];
	user: any = [];
	breakqty: any = 0;
	bomForm: FormGroup<{}> = new FormGroup({});
	parentForm: FormGroup<{}> = new FormGroup({});
	bomParentEditing: any = false;
	componentitemlist: any = [];
	bdData: any = [];
	componentdatasource: any = new MatTableDataSource([]);
	componentsearching: boolean;
	showcomponentlist: boolean;
	componentnoresults: boolean;
	componentsearchsub: any;


	componentdisplayedColumns: string[] = [
		"stockid",
		"description",
		"loccode",
		"mfgpart",
		"action"
	];

	formsub: any = false;



	constructor(public inventoryService: InventoryService, private globalSearchService: GlobalSearchService, private printService: PrintService, private modalService: NgbModal, public fb: FormBuilder) { }
	ngOnChanges(changes: SimpleChanges): void {
		if(changes.openclosed.currentValue){
			this.openLookup(changes.openclosed.currentValue)
		}
	}


	ngAfterViewInit(): void {

	}

	ngOnInit(): void {
		this.globalSearchService.user.subscribe( result => {
			this.user = result;
		});

	}

	validateId(event: any){
		let data = {
			new_id: event.value,
			original_id: this.activeBom['bomParent']
		};
		this.inventoryService.absoluteKitSearch(data).subscribe((results: any) => {
			if (results.length == 0) {
				this.bomForm.get('activeBomParent').clearValidators();
			} else {
				this.bomForm.get('activeBomParent').addValidators([Validators.required, this.validateKitId()]);
			}
			this.bomForm.get('activeBomParent').updateValueAndValidity();
		});

	}

	private validateKitId(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			let input = {
				new_id: control.value,
				original_id: this.activeBom['bomParent']
			};
			return this.inventoryService.absoluteKitSearch(input).subscribe(({ data }) => {

				return data;
			},
				(error) => {

					return error;
				}
			);
		}
	}


	openLookup(stockid){


		let data = {
			bomParent: stockid
		}

		this.activeBom = [];

		this.searchsub = this.inventoryService.explodeBom(data).subscribe((results: any) => {

			if(results.length > 0){
				this.activeBom = results;
				this.activeBom['bomParent'] = stockid;

				let activeafter = results[0].effectiveafter;
				let activeto = results[0].effectiveto;

				results.forEach(component => {
					if(activeto > component.effectiveto){
						activeto = component.effectiveto;
					}
					if(activeafter < component.effectiveto){
						activeafter = component.effectiveafter
					}
				});

				this.activeBom['bomParent_effectiveto'] = activeto;
				this.activeBom['bomParent_effectiveafter'] = activeafter;

				this.activeBom.forEach(part => {
					this.bomForm.addControl(part.component ,new FormControl(part.quantity))
					this.bomForm.addControl(part.component + '_effectiveto' ,new FormControl(part.effectiveto))
					this.bomForm.addControl(part.component + '_effectiveafter' ,new FormControl(part.effectiveafter))
				});

				this.bomForm.addControl('activeBomParent' ,new FormControl(this.activeBom['bomParent'], Validators.required))
				this.bomForm.addControl('activeBomParent_effectiveafter' ,new FormControl(this.activeBom['bomParent_effectiveafter']))
				this.bomForm.addControl('activeBomParent_effectiveto' ,new FormControl(this.activeBom['bomParent_effectiveto']))

				// this.formlistener();
			}else{

				this.activeBom['bomParent'] = stockid;
				this.activeBom['bomParent_effectiveto'] = "";
				this.activeBom['bomParent_effectiveafter'] = "";
				this.bomForm.addControl('activeBomParent' ,new FormControl(this.activeBom['bomParent'], Validators.required))
				this.bomForm.addControl('activeBomParent_effectiveafter' ,new FormControl(this.activeBom['bomParent_effectiveafter']))
				this.bomForm.addControl('activeBomParent_effectiveto' ,new FormControl(this.activeBom['bomParent_effectiveto']))
			}

			this.masterDate();
			this.bomParentEditing == true ?
			this.bomParentEditing = false : this.bomParentEditing = true;
		});
	}


	close(){
		this.closeEvent.emit(false);
	}


	componentSearch(){
		// if (this.componentlookup.value != '' ) {
			let data = {};
			data['stockid'] = this.componentlookup.value;
			data['type'] = ['component'];

			this.componentsearchsub = this.inventoryService.itemSearch(data).subscribe((results: any) => {
				this.componentitemlist = results
				this.componentdatasource = new MatTableDataSource(results);
				this.componentdatasource.paginator = this.paginator;
				this.componentsearching = false;
			});
	}


	removeComponent(component: any){
		Object.keys(this.bomForm.value).forEach((key) => {
			let formattedKey = key.split('_')[0];
			if(component.component == formattedKey){
				this.bomForm.removeControl(key);
			}
		})

		for(let x = 0; x<this.activeBom.length; x++){
			if(component.component == this.activeBom[x].component){
				this.activeBom.splice(x,1);
			}
		}

		this.shuffleSequence();
		// this.updateBom();
	}

	formlistener(){

		this.bomForm.valueChanges.subscribe((newV)=>{

			this.updateBom();
		})
	}

	masterDate(){
		this.bomForm.get('activeBomParent_effectiveto').valueChanges.subscribe((newV)=>{
			Object.keys(this.bomForm.controls).forEach(key => {
				if(key.split('_')[1] == 'effectiveto' && key.split('_')[0] != 'activeBomParent'){
					this.bomForm.get(key).setValue(newV)
					alert(newV);
				}
			});
		});
		this.bomForm.get('activeBomParent_effectiveafter').valueChanges.subscribe((newV)=>{

			Object.keys(this.bomForm.controls).forEach(key => {
				if(key.split('_')[1] == 'effectiveafter' && key.split('_')[0] != 'activeBomParent'){
					this.bomForm.get(key).setValue(newV)
					alert(newV);
				}
			});
		});
	}


	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.activeBom, event.previousIndex, event.currentIndex);
		this.shuffleSequence();
		// this.updateBom();
	}

	shuffleSequence(){
		for(let x = 0; x < this.activeBom.length; x++){
			this.activeBom[x].sequence = (x + 1);
		}
	}

	editBomParent(){
		this.openLookup(this.activeBom.bomParent);


	}

	updateBom(){
		let data = {};
		//get objects (components) from activebom object
		this.activeBom.forEach(element => {
			typeof element == 'object' ? data[element.sequence - 1] = element: '';
			if(data[element.sequence - 1]){
				let comp = data[element.sequence - 1].component;
				data[element.sequence - 1].effectiveafter = this.bomForm.get(`${comp}_effectiveafter`).value;
				data[element.sequence - 1].effectiveto = this.bomForm.get(`${comp}_effectiveto`).value;
				data[element.sequence - 1].quantity = this.bomForm.get(comp).value;
			}
		});

		data['parent'] = this.activeBom['bomParent'];

		this.searchsub = this.inventoryService.updateBom(data).subscribe((results: any) => {
			// this.activeBom['bomParent'] = this.bomForm.get('activeBomParent').value;
		});
	}

	renameBomParent(){
		//not needed - leaving in for now
		// if(this.bomForm.get('activeBomParent').valid){
		// 	let data = {
		// 		newParent: this.bomForm.g cartet('activeBomParent').value,
		// 		originalParent: this.activeBom['bomParent']
		// 	}

		// 	this.searchsub = this.inventoryService.renameBom(data).subscribe((results: any) => {
		// 		this.activeBom['bomParent'] = this.bomForm.get('activeBomParent').value;
		// 	});

		// };
		// this.updateBom();
	}

	validityAlert(){
		alert("This parent's stockid is already present. \nPlease enter an ID that isn't in use");
	}

	saveAndClose(){

		this.updateBom();
		this.editBomParent();
	}

	addToBom(component: any){
		let valid = true;
		let adding = component.stockid;
		let parent = this.activeBom['bomParent'];
		let effectiveto = this.activeBom["bomParent_effectiveto"]
		let effectiveafter = this.activeBom['bomParent_effectiveafter'];

		//validity check for adding to itself
		this.activeBom.forEach(active => {
			let present = active['component'];
			if(adding == present || adding == parent){
				valid = false;
			}
		});

		if(valid ){
			let data = {
				parent: parent,
				sequence: (this.activeBom.length + 1) + "",
				component: component.stockid,
				loccode: component.loccode,
				effectiveto: effectiveto,
				effectiveafter: effectiveafter,
				quantity: "1"
			}

			this.bomForm.addControl(component.stockid ,new FormControl("1"))
			this.bomForm.addControl(component.stockid + '_effectiveto' ,new FormControl(effectiveto))
			this.bomForm.addControl(component.stockid + '_effectiveafter' ,new FormControl(effectiveafter))

			this.activeBom.push(data);
			this.shuffleSequence();
			// this.updateBom();
		} else {
			alert(`Duplicate items and parents can't be added to the bom.\n\nCancelling addition of '${component.stockid}'. ` )
		}


	}

	breakdown(){

	this.inventoryService.getBreakdown({stockid: this.activeBom['bomParent'], loccode: this.user.user.defaultlocation.loccode}).subscribe((results: any) => {
				this.bdData = results;
				this.modalService.open(this.breakdownKit, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
			});

	}

	updateBD(event: any){
	let mult = event;
	this.bdData.bomlines.forEach(bomline => {
	bomline.qtyadd = bomline.quantity* mult;
	this.breakqty = event;
	});
	}

	processBreakdown(){
	this.inventoryService.postBreakdown({stockid: this.activeBom['bomParent'], loccode: this.user.user.defaultlocation.loccode, qty: this.breakqty}).subscribe((results: any) => {
				this.bdData = results;
				this.modalService.dismissAll();
			});


	}



}
