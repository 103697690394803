<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{ color }}">
						<h4 class="card-title" id="headof"><i class="material-icons" (click)="back()">arrow_left</i> Profit & Loss</h4>

					</div>
					<div class="card-body">
						<form [formGroup]="formGroup" *ngIf="formGroup">
							<div class="row">
								<div class="col-md-6">
									<mat-form-field appearance="standard" class="ml-2 mr-2">
										<mat-label>From Period</mat-label>
										<mat-select formControlName="from_period" (selectionChange)="updateToOptions($event.value)">
											<mat-option *ngFor="let period of from_period_options" [value]="period.periodno">
												{{period.lastdate_in_period }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-6">
									<mat-form-field appearance="standard" class="ml-2 mr-2">
										<mat-label>To Period</mat-label>
										<mat-select formControlName="to_period">
											<mat-option *ngFor="let period of to_period_options" [value]="period.periodno">
												{{period.lastdate_in_period }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-6">
									<button mat-raised-button color="accent" (click)="getTrialBalance()">Run</button>
									&nbsp;
									<ng-container *ngIf="trial_balance.length">
										&nbsp;&nbsp;
										<a href="{{pdflink}}" class="btn btn-danger"><i class="fa fa-file-excel-o"></i></a>
										&nbsp;&nbsp;
										<a href="{{excellink}}" class="btn btn-success"><i class="fa fa-file-pdf-o"></i></a>
									</ng-container>

								</div>
							</div>
						</form>
						<div class="row">
							<div class="col-md-12 text-center" *ngIf="loading">
								<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
									</circle>
								</svg>
							</div>
						</div>
						<ng-container *ngIf="trial_balance">
							<h3 class="mt-2 bold mb-2">{{ title }}</h3>
							<div class="table" *ngFor="let group of trial_balance; last as isLast">
								<h4 class="bold">{{ group.title }}</h4>
								<table class="table table-sm">
									<tr>
										<th width="15%">Account</th>
										<th width="25%">Account Name</th>
										<th width="15%" class="text-right">Month Actual</th>
										<th width="15%" class="text-right"></th>
										<th width="15%" class="text-right">Period Actual</th>
										<th width="15%" class="text-right"></th>
									</tr>
									<tr *ngFor="let account of group.accounts">
										<td>{{ account.accountcode }}</td>
										<td>{{ account.accountname }}</td>
										<td class="text-right">{{ account.monthactual | currency }}</td>
										<td class="text-right"></td>
										<td class="text-right">{{ account.periodactual | currency }}</td>
										<td class="text-right"></td>
									</tr>
									<tr>
										<td>
											<h5 class="bold"> {{group.title }} Totals</h5>
										</td>
										<td></td>
										<td class="text-right">{{ group.totals.monthactual | currency }}</td>
										<td class="text-right"></td>
										<td class="text-right">{{ group.totals.periodactual | currency }}</td>
										<td class="text-right"></td>
									</tr>
									<tr *ngIf="isLast">
										<td colspan="4" class="text-right">
											<h5 class="bold">Month Actual Total</h5>
										</td>
										<td></td>
										<td class="text-right">{{ trial_balance_totals.monthactual | currency }}</td>
									</tr>
									<tr *ngIf="isLast">
										<td colspan="4" class="text-right">
											<h5 class="bold">Period Actual Total</h5>
										</td>
										<td class="text-right"></td>
										<td class="text-right">{{ trial_balance_totals.periodactual | currency }}</td>
									</tr>
									<tr *ngIf="isLast">
										<td colspan="4" class="text-right">
											<h5 class="bold">Total Revenue</h5>
										</td>
										<td></td>
										<td class="text-right">{{trial_balance_totals.revenue | currency }}</td>
									</tr>
									<tr *ngIf="isLast">
										<td colspan="4" class="text-right">
											<h5 class="bold">Total Cost of Goods</h5>
										</td>
										<td></td>
										<td class="text-right">{{trial_balance_totals.cogs | currency }}</td>
									</tr>
									<tr *ngIf="isLast">
										<td colspan="4" class="text-right">
											<h5 class="bold">Gross Profit</h5>
										</td>
										<td></td>
										<td class="text-right">{{trial_balance_totals.revenue - trial_balance_totals.cogs | currency }}</td>
									</tr>
									<tr *ngIf="isLast">
										<td colspan="4" class="text-right">
											<h5 class="bold">Total Expenses</h5>
										</td>
										<td></td>
										<td class="text-right">{{trial_balance_totals.expenses | currency }}</td>
									</tr>
									<tr *ngIf="isLast">
										<td colspan="4" class="text-right">
											<h5 class="bold">Total Profit / Loss</h5>
										</td>
										<td></td>
										<td class="text-right">{{trial_balance_totals.revenue - trial_balance_totals.cogs - trial_balance_totals.expenses | currency }}</td>
									</tr>

								</table>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
