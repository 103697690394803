import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';

import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router } from '@angular/router';
import { VendorService } from '../../services/vendor.service';
import { PurchasingService } from '../../services/purchasing.service';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { Location } from '@angular/common'


@Component({
  selector: 'app-vendor-credit',
  templateUrl: './vendor-credit.component.html',
  styleUrls: ['./vendor-credit.component.scss']
})
export class VendorCreditComponent implements OnInit {

	invoiceForm: UntypedFormGroup;
	separatorKeysCodes: number[] = [ENTER, COMMA];
	vendor_data: any = [];
	open_grns: any = [];
	selected_grns: any = [];
	selected_grnbatches: any = [];
	invoice_grns: any = [];
	invoiceTotal: number = 0.00;
	taxTotal: number = 0.00;
	totalTotal: number = 0.00;
	glcharges: any = [];
	glaccounts: any = [];
	taxes: any = [];
	today = new Date();
	overide_tax: boolean = false;
	grn_batches: any = [];
	all_grn_batches: any = [];
	discount: any = 0.00;
	user: any = [];
	errors: any = [];
	color: any = '';
	allglaccounts: any = [];

	company: any = false;

	@ViewChild('grnInput') grnInput: ElementRef < HTMLInputElement > ;
	//@ViewChild('glcharge') glcharge: ElementRef < HTMLInputElement > ;

	constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, private vendorService: VendorService, public router: Router, private location: Location, private globalsService: GlobalsService, public purchasingService: PurchasingService, private globalSearchService: GlobalSearchService) {
		this.color = this.globalSearchService.getColor()
		this.globalSearchService.user.subscribe((user: any) => {
			this.user = user
		});

		this.globalSearchService.company.subscribe((company: any) => {
			this.company = company
		});
	}

	ngOnInit(): void {

		this.route.params.subscribe(params => {
			const supplierid = params['id'];

			this.vendorService.getVendor(supplierid).subscribe((results: any) => {
				this.vendor_data = results;

				this.purchasingService.getGrnDetails(this.vendor_data.supplierid).subscribe((results: any) => {
					this.open_grns = results;
				})

				this.purchasingService.getOpenGrnBatches(this.vendor_data.supplierid).subscribe((results: any) => {
					this.grn_batches = results;
					this.all_grn_batches = results;
				})

				let due = new Date(this.vendor_data.duedate)

				this.invoiceForm = this.fb.group({
					reference: ['', [Validators.required]],
					invoiceDate: [this.today, [Validators.required]],
					discountAmount: [0.00, [Validators.pattern('[0-9.]*')]],
					discountDate: [due, [Validators.required]],
					dueDate: [due, [Validators.required]],
					rate: [this.vendor_data.exrate, [Validators.pattern('[0-9.]*')]],
					grns: [''],
					glsearch: ['']
				});



				this.invoiceForm.get('grns').valueChanges.subscribe(newValue=> {

					if(newValue != '') {
						this.grn_batches = this.globalSearchService.filterItem(this.all_grn_batches, newValue, 'grnbatch,grnno,orderno,itemcode')
					} else {
						this.grn_batches = this.all_grn_batches
					}

				});

				this.invoiceForm.markAllAsTouched();

			});
		});
	}

	back(): void {
		this.location.back()
	}

	add(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();

		// Add our fruit
		if (value) {
			this.selected_grns.push(value);
		}

		// Clear the input value
		//event.chipInput!.clear();
		this.grnInput.nativeElement.value = '';
		this.invoiceForm.controls['grns'].setValue(null);
		this.getTotal();
	}

	removeLine(grn: any): void {
		var index = this.selected_grns.indexOf(grn);

		if (index >= 0) {
			this.selected_grns.splice(index, 1);
		}
		this.getTotal();
	}

	remove(grn: any): void {
		var index = this.selected_grns.indexOf(grn);

		if (index >= 0) {
			this.selected_grns.splice(index, 1);
		}

		index = this.selected_grnbatches.indexOf(grn);
		//add back to the list
		this.grn_batches.push(this.selected_grnbatches[index]);

		if (index >= 0) {
			this.selected_grnbatches.splice(index, 1);
		}

		//remove items too?
		var selected = this.selected_grns.filter((line: any) => {
			return parseFloat(line.orderno) != parseFloat(grn.orderno);
		});

		this.selected_grns = selected;

		this.getTotal();
	}


	selected(event: MatAutocompleteSelectedEvent): void {

		let index = this.grn_batches.indexOf(event.option.value);
		//add to chip list
		this.selected_grnbatches.push(event.option.value)

		var moving_grn = event.option.value.orderno

		this.grn_batches.splice(index, 1);

		//map returns just the array value. filter will be list of all
		this.open_grns.filter((grn: any) => {
			if (grn.orderno == moving_grn) {
				this.selected_grns.push(grn);
			}
			return grn.orderno == moving_grn;
		})

		//this.grnInput.nativeElement.value = '';
		this.invoiceForm.controls['grns'].setValue(null);
		this.getTotal();
	}

	removeGl(gl: string): void {
		const index = this.glcharges.indexOf(gl);

		if (index >= 0) {
			this.glcharges.splice(index, 1);
		}
		this.getTotal();
	}

	setTaxValue(event: any, tax: any) {
		this.overide_tax = true;
		let index = this.vendor_data.taxes.indexOf(tax);
		this.vendor_data.taxes[index].taxamount = event.target.value;
		this.getTotal();
	}

	setQty(event: any, grn: any) {
		let index = this.selected_grns.indexOf(grn);
		if (parseFloat(event.target.value) > parseFloat(this.selected_grns[index].qtyrecd)) {
			event.target.value = parseFloat(this.selected_grns[index].qtyrecd);
		}

		this.selected_grns[index].toinvoice = event.target.value;
		this.getTotal();
	}

	setDiscount(event: any) {
		this.discount = parseFloat(event.target.value);
		this.getTotal();
	}

	setPrice(event: any, grn: any) {
		let index = this.selected_grns.indexOf(grn);
		this.selected_grns[index].unitprice = event.target.value;
		this.getTotal();
	}

	setGlPrice(event: any, gl: any) {
		let index = this.glcharges.indexOf(gl);
		this.glcharges[index].value = event.target.value;
		this.getTotal();
	}

	getSubTotal() {
		let itemtotal = 0
		itemtotal = this.selected_grns.reduce(function(accumulator, items) {
			return parseFloat(accumulator) + parseFloat(items.unitprice) * parseFloat(items.toinvoice);
		}, 0);

		let gltotal = 0
		gltotal = this.glcharges.reduce(function(accumulator, items) {
			return parseFloat(accumulator) + parseFloat(items.value);
		}, 0);
		let total = gltotal + itemtotal;
		this.invoiceTotal = total
		return total;

	}

	getTaxTotal(subtotal: any) {

		if (!this.overide_tax) {
			this.vendor_data.taxes.forEach((tax, index) => {
				let value = parseFloat(tax.taxrate) * parseFloat(subtotal)
				this.vendor_data.taxes[index].taxamount = value;
			});
		}

		let taxtotal = 0
		taxtotal = this.vendor_data.taxes.reduce(function(accumulator, tax) {
			return (parseFloat(accumulator) + parseFloat(tax.taxamount));
		}, 0);

		this.taxTotal = taxtotal
		return taxtotal;
	}

	getTotal() {

		let subtotal = this.getSubTotal();
		let taxtotal = this.getTaxTotal(subtotal);
		this.totalTotal = subtotal + taxtotal - this.discount;
	}


	addGl(event: any) {
		let selection = event.option.value
		let newGlCharge = {
			account: selection.accountcode,
			name: selection.accountname,
			value: 0.00,
			quantity: 1,
			group: selection.group,
			description: '',
		};

		this.glcharges.push(newGlCharge);
	}

	onSubmit() {
		this.getTotal();
		this.errors = false;
		for (let el in this.invoiceForm.controls) {
			if (this.invoiceForm.controls[el].errors) {
				this.errors = true;
			}
		}

		if (this.invoiceForm.valid) {

			let data = {
				vendor: this.vendor_data,
				header: this.invoiceForm.value,
				grns: this.selected_grns,
				glcharges: this.glcharges,
				subtotal: this.invoiceTotal,
				taxes: this.taxTotal,
				total: this.totalTotal,
				user: this.user,
			}

			this.vendorService.createCredit(data).subscribe((result: any) => {
				if (result.success) {
					this.globalSearchService.showNotification('#' + result.transno + ' Created', 'success', 'bottom', 'right');

					let due = new Date(this.vendor_data.duedate)

					this.invoiceForm = this.fb.group({
						reference: ['', [Validators.required]],
						invoiceDate: [this.today, [Validators.required]],
						discountAmount: [0.00, [Validators.pattern('[0-9.]*')]],
						discountDate: [due, [Validators.required]],
						dueDate: [due, [Validators.required]],
						rate: [this.vendor_data.exrate, [Validators.pattern('[0-9]*')]],
						grns: ['']
					});

					this.invoiceForm.get('grns').valueChanges.subscribe(newValue=> {

						if(newValue != '') {
							this.grn_batches = this.globalSearchService.filterItem(this.all_grn_batches, newValue, 'grnbatch,grnno,orderno,itemcode')
						} else {
							this.grn_batches = this.all_grn_batches
						}

					});

					this.invoiceForm.markAllAsTouched();

					this.glcharges = [];
					this.selected_grns = [];
					this.selected_grnbatches = [];

				} else {

				}

			});
		} else {
			this.globalSearchService.getFormValidationErrors(this.invoiceForm);
		}
	}

	ngAfterViewInit() {
		this.globalsService.getGLAccounts().subscribe((result: any) => {
			this.glaccounts = result;
			this.allglaccounts = result;
		});

		this.globalsService.getTaxes().subscribe((result: any) => {
			this.taxes = result;
		});

	}
}
