import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { PrintService } from '../../services/print.service'
import { FormBuilder, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { InventoryService } from 'app/services/inventory.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';

interface designations {
	value: string;
	viewValue: string;
}

@Component({
	selector: 'app-missingimages',
	templateUrl: './missingimages.component.html',
	styleUrls: ['./missingimages.component.scss']
})
export class MissingimagesComponent implements OnInit {
	@Input() data: any = [];

	@ViewChild("detailsModal") detailsModalEle: ElementRef;
	@ViewChild('paginator') paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild('print_table') printtable: ElementRef;
	itemForm: UntypedFormGroup;
	itemlist: any = false;
	lookup = new UntypedFormControl('');
	item: any = false;
	ordertitle: string = '';
	orderdetails: any = [];
	searching: boolean;
	showlist: boolean;
	historyloading: boolean;
	showorder: boolean;
	lastsearch: string = '';
	noresults: boolean = false;
	searchsub: any = false;
	tlibsearchsub: any = false;
	atdsearchsub: any = false;
	mfisearchsub: any = false;
	viewingitem: any = false;
	itemio: any = false;
	filename: string = 'missing_urchase_data';
	activetire: any;
	atdresults: any;
	tlibresults: any;
	mfiresults: any;
	atdsearching: any;
	tlibsearching: any;
	mfisearching: any;
	searchParams: { mfgpartno: any; mfg: any; };
	mfg = new FormControl('');
	brandOption = new FormControl('');
	mfgSelected = new FormControl('');
	mfgpartno = new FormControl('', Validators.required);
	missingImageToggle = new FormControl(true);
	missingAttributeToggle = new FormControl(false);
	v4Toggle: any = true;
	v8Toggle: any = true;
	imagesFound: any = [];
	mfgs: any = false;
	//attr form controls
	form_attr: FormGroup;
	form_filters: FormGroup;
	databaseSelected: any = '';
	datasource: any = [];
	displayedColumns: string[] = [
				"image",
				"stockid",
				"description",
				"size",
				"speedrating",
				"utgq",
				"loadindex",
				"qty",
				"version",
				"action"
			];
	des: any = [];
	truck_des: any = [];

		constructor(public inventoryService: InventoryService, private globalSearchService: GlobalSearchService, private printService: PrintService, private modalService: NgbModal, public fb: FormBuilder) {
		}

		ngOnInit(): void {
			this.inventoryService.tireinfoBrands({}).subscribe((result: any) => {
				this.mfgs = result;
			});

			this.inventoryService.getSeasons().subscribe((results: any) => {
				this.des = results;
			});

			this.inventoryService.getDesignations().subscribe((results: any) => {
				this.truck_des = results;

			});

			this.form_attr = this.fb.group({
				size_attr:  [''],
				mfgpartno_attr: [''],
				benefits_attr: [''],
				// mfg_attr: [''],
				// line_attr: [''],
				features_attr: [''],
				truckdesignation_attr: [''],
				description_attr: [''],
				displayName_attr: [''],
				upc_attr: [''],
				speedrating_attr: [''],
				loadindex_attr: [''],
				utgq_attr: [''],
				image_attr: [''],
				revolutionsPerMile_attr: [''],
				approvedrimwidth_attr: [''],
				runflat_attr: [''],
				overallDiameter_attr: [''],
				aspectRatio_attr: [''],
				overallWidth_attr: [''],
				treadDepth_attr: [''],
				maxInflationPressure_attr: [''],
				warranty_attr: [''],
				sidewall_attr: [''],
				sectionWidth_attr: [''],
				winterdesignation_attr: [''],
				treadConstruction_attr: [''],
				measuringRimWidth_attr: [''],
			})

			this.form_filters = this.fb.group({
				missingImageToggle:  [true],
				missingAttributeToggle:  [false],
			})



			this.lookupVersionEight();


		}

		logger(event: any){
			this.lookupVersionEight();
		}

		filterBrands(event: any){ //can change to just lookup now
			this.lookupVersionEight();
		}

		resetForm() {
			this.form_attr.reset();
		}

		filterDatabase(){
			let data = [];
			if(this.v4Toggle){
				data.push('v4');
			}
			if(this.v8Toggle){
				data.push('tlib');
			}
			if(!this.v4Toggle && !this.v8Toggle){
				data.push('v4');
				data.push('tlib');
			}
			return  {type:data};
		}

		exportPdf() {
			let encoded: string = btoa(this.printtable.nativeElement.innerHTML);
			let data = {
				content: encoded,
				filename: this.filename,
			}

			this.printService.pdf(data).subscribe((result: any) => {
				this.globalSearchService.downloadPdf(result.content, data.filename);
			});
		}

		exportXls() {
			let encoded: string = btoa(this.printtable.nativeElement.innerHTML);
			let data = {
				content: encoded,
				filename: this.filename,
			}

			this.printService.xls(data).subscribe((result: any) => {
				this.globalSearchService.downloadXls(result.content, data.filename);
			});
		}


		openLookup(stockid, version) {

			let data = {stockid: stockid};

			this.searchsub = this.inventoryService.getTireInfo(data).subscribe((results: any) => {

				for (const [key, value] of Object.entries(results)) {
					if(value){
						this.updateattribute(key, value);
					}
				}


				this.activetire = results;
				this.activetire['version'] = version;
				this.searchParams = {mfgpartno: this.activetire['mfgpartno'], mfg: this.activetire['mfg']}
				this.mfg.setValue(this.searchParams.mfg);
				this.mfgpartno.setValue(this.searchParams.mfgpartno)

				this.atdsearching = this.inventoryService.atdSingleSearch(this.searchParams).subscribe((results: any) => {
					this.atdsearching = false;
					this.atdresults = results;
					this.atdresults.forEach(element => {
						if(element.key == 'image'){
							this.imagesFound.push({'image' : element.value, 'vendor': 'ATD'});
						}
					});
				});

				this.tlibsearching = this.inventoryService.tlibSingleSearch(this.searchParams).subscribe((results: any) => {
					this.tlibsearching = false;
					this.tlibresults = results;
					this.tlibresults.forEach(element => {
						if(element.key == 'image'){
							this.imagesFound.push({'image' : element.value, 'vendor': 'Tire Library'});
						}
					});
				});

				this.mfisearching = this.inventoryService.mfiSingleSearch(this.searchParams).subscribe((results: any) => {
					this.mfisearching = false;
					this.mfiresults = results;
				});

				this.modalService.open(this.detailsModalEle, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
				}, (reason) => {
					//resetti on modal close
					this.atdresults = ""; this.tlibresults = ""; this.mfiresults= ""; this.activetire = ""; this.imagesFound = []; this.resetForm();
					this.lookupVersionEight();
				});

			});
		}

		searchApis(){
			this.atdresults = ""; this.tlibresults = ""; this.mfiresults= ""; this.imagesFound = [];
			this.searchParams = {mfgpartno: this.mfgpartno.value, mfg: this.mfg.value}

				this.atdsearching = this.inventoryService.atdSingleSearch(this.searchParams).subscribe((results: any) => {
					this.atdsearching = false;
					this.atdresults = results;
					this.atdresults.forEach(element => {
						if(element.key == 'image'){
							this.imagesFound.push({'image' : element.value, 'vendor': 'ATD'});
						}
					});
				});

				this.tlibsearching = this.inventoryService.tlibSingleSearch(this.searchParams).subscribe((results: any) => {
					this.tlibsearching = false;
					this.tlibresults = results;
					this.tlibresults.forEach(element => {
						if(element.key == 'image'){
							this.imagesFound.push({'image' : element.value, 'vendor': 'Tire Library'});
						}
					});
				});

				this.mfisearching = this.inventoryService.mfiSingleSearch(this.searchParams).subscribe((results: any) => {
					this.mfisearching = false;
					this.mfiresults = results;
				});
		}

		getWinterDesignations(){

		}


		saveAttributes(){ //blah rewrite
			if(this.form_attr.valid){
				let data = {
					attributes:this.form_attr.value,
					stockid:this.activetire.stockid,
					version:this.activetire.version
				};

				this.inventoryService.submitAttributes(data).subscribe((results: any) => {

					if(results){


						//attr only
						if(!this.missingImageToggle.value && this.missingAttributeToggle.value){
							if((results['speedrating'] || results['speed_rating']) && (results['loadindex'] || results['load_index']) && (results['size'] || results['tire_size']) && results['image']){
								alert("Tire attributes and image fully updated.")
								this.resetForm();
								this.spliceTire();
							} else{
								let response = ''
								Object.keys(results).forEach(function(key, v){
									response += key + ', ';
								});
								alert(`Updated ${this.activetire.stockid}'s following attributes:\n\n${response.substring(0, response.length - 2)}`);
							}
						}


						//image only
						if(this.missingImageToggle.value && !this.missingAttributeToggle.value){
							if(results['image'] && Object.keys(results).length == 1 ){
								alert("Tire image updated.")
								this.resetForm();
								this.spliceTire();
							} else{
								let response = ''
								Object.keys(results).forEach(function(key, v){
									response += key + ', ';
								});
								alert(`Updated ${this.activetire.stockid}'s following attributes:\n\n${response.substring(0, response.length - 2)}`);
							}
						}

						//both
						if((this.missingImageToggle.value && this.missingAttributeToggle.value) || (!this.missingAttributeToggle.value && !this.missingImageToggle.value)){
							if((results['speedrating'] || results['speed_rating']) && (results['loadindex'] || results['load_index']) && (results['size'] || results['tire_size']) && results['image']){
								alert("Tire attributes and image fully updated.")
								this.resetForm();
								this.spliceTire();
							} else{
								let response = ''
								Object.keys(results).forEach(function(key, v){
									response += key + ', ';
								});
								alert(`Updated ${this.activetire.stockid}'s following attributes:\n\n${response.substring(0, response.length - 2)}`);
							}
						}
					}
				});
			} else {
				alert("Please enter a name/mfg# for this product.")
			}
		}



		spliceTire(){
			let index = 0;
			let found = false;
			this.itemlist.forEach(item => {
				if(item.stockid == this.activetire.stockid && item.version == this.activetire.version){
					found = true;
				}
				if(!found){
					index++;
				}
			});
			this.itemlist.splice(index, 1);
		}

		lookupVersionEight() {
			if (this.searchsub) {
				this.searchsub.unsubscribe();
			}

			let brand = this.mfgSelected.value.split('-')[0];
			this.searching = true;

			if (brand != '' || this.lookup.value != '' || this.missingImageToggle || this.missingAttributeToggle) {
				let data = this.filterDatabase();

				if(brand != '' && this.lookup.value == ''){
					data['brand'] = brand;
				}

				if(brand == '' && this.lookup.value != '' ){
					data['stockid'] = this.lookup.value;
				}

				if(brand != '' && this.lookup.value != '' ){
					data['brand'] = brand;
					data['stockid'] = this.lookup.value;
				}

				if(this.missingImageToggle.value){
					data['imageToggle'] = true;
				}

				if(this.missingAttributeToggle.value){
					data['attributeToggle'] = true;
				}

				this.searchsub = this.inventoryService.itemSearch(data).subscribe((results: any) => {
					this.datasource = new MatTableDataSource(results);
					this.datasource.sort = this.sort;
					this.datasource.paginator = this.paginator;
					this.itemlist = results
					this.searching = false;
				});

			} else {
				this.searching = false;
				this.showlist = false;
				this.noresults = false;
				this.itemlist = null;
			}
		}


		updateattribute(key, value){
			switch(key){
				case 'size':
					this.form_attr.get('size_attr').setValue(value);
					break;
				case 'itemcode':
					this.form_attr.get('mfgpartno_attr').setValue(value);
					break;
				case 'upc':
					this.form_attr.get('upc_attr').setValue(value);
					break;
				case 'speedrating':
					this.form_attr.get('speedrating_attr').setValue(value);
					break;
				case 'loadindex':
					this.form_attr.get('loadindex_attr').setValue(value);
					break;
				case 'utqg':
					this.form_attr.get('utgq_attr').setValue(value);
					break;
				case 'image':
					this.form_attr.get('image_attr').setValue(value);
					break;
				case 'revolutionsPerMile':
					this.form_attr.get('revolutionsPerMile_attr').setValue(value)
					break;
				case "rpm":
					this.form_attr.get('revolutionsPerMile_attr').setValue(value)
					break;
				case "maxrimwidth":
					this.form_attr.get('approvedrimwidth_attr').setValue(value)
					break;
				case "approvedRimWidth":
					this.form_attr.get('approvedrimwidth_attr').setValue(value)
					break;
				case "runflat":
					this.form_attr.get('runflat_attr').setValue(value)
					break;
				case "overalltirediameter":
					this.form_attr.get('overallDiameter_attr').setValue(value)
					break;
				case "overallDiameter":
					this.form_attr.get('overallDiameter_attr').setValue(value)
					break;
				case "aspectratio":
					this.form_attr.get('aspectRatio_attr').setValue(value)
					break;
				case "aspectRatio":
					this.form_attr.get('aspectRatio_attr').setValue(value)
					break;
				case "loadindex":
					this.form_attr.get('loadindex_attr').setValue(value)
					break;
				case "productwidth":
					this.form_attr.get('overallWidth_attr').setValue(value)
					break;
				case "overallWidth":
					this.form_attr.get('overallWidth_attr').setValue(value)
					break;
				case "size":
					this.form_attr.get('size_attr').setValue(value)
					break;
				case "treaddepth":
					this.form_attr.get('treadDepth_attr').setValue(value)
					break;
				case "treadDepth":
					this.form_attr.get('treadDepth_attr').setValue(value)
					break;
				case "maxtirepressure":
					this.form_attr.get('maxInflationPressure_attr').setValue(value)
					break;
				case "maxInflationPressure":
					this.form_attr.get('maxInflationPressure_attr').setValue(value)
					break;
				case "mileagewarranty":
					this.form_attr.get('warranty_attr').setValue(value)
					break;
				case "sidewall":
					this.form_attr.get('sidewall_attr').setValue(value)
					break;
				case "tirewidth":
					this.form_attr.get('sectionWidth_attr').setValue(value)
					break;
				case 'winterdesignation':
					this.form_attr.get('winterdesignation_attr').setValue(value);
					break;
				case 'mfgpartno':
					this.form_attr.get('mfgpartno_attr').setValue(value);
					break;
				case 'treadwear':
					this.form_attr.get('treadConstruction_attr').setValue(value);
					break;
				case 'treadConstruction':
					this.form_attr.get('treadConstruction_attr').setValue(value);
					break;
				case 'minrimwidth':
					this.form_attr.get('measuringRimWidth_attr').setValue(value);
					break;
				case 'measuringRimWidth':
					this.form_attr.get('measuringRimWidth_attr').setValue(value);
					break;
				case 'name':
					this.form_attr.get('displayName_attr').setValue(value);
					break;
				case 'size':
					this.form_attr.get('size_attr').setValue(value);
					break;
				case 'itemcode':
					this.form_attr.get('mfgpartno_attr').setValue(value);
					break;
				case 'mfg':
					// this.form_attr.get('mfg_attr').setValue(value);
					break;
				case 'line':
					// this.form_attr.get('line_attr').setValue(value);
					break;
				case 'name':
					this.form_attr.get('displayName_attr').setValue(value);
					break;
				case 'upc':
					this.form_attr.get('upc_attr').setValue(value);
					break;
				case 'speedrating':
					this.form_attr.get('speedrating_attr').setValue(value);
					break;
				case 'loadindex':
					this.form_attr.get('loadindex_attr').setValue(value);
					break;
				case 'description':
					this.form_attr.get('description_attr').setValue(value);
					break;
				case 'features':
					this.form_attr.get('features_attr').setValue(value);
					break;
				case 'utqg':
					this.form_attr.get('utgq_attr').setValue(value);
					break;
				case 'image':
					this.form_attr.get('image_attr').setValue(value);
					break;
				case 'TreadWear':
					this.form_attr.get('treadConstruction_attr').setValue(value);
					break;
				case 'MinRimWidth':
					this.form_attr.get('measuringRimWidth_attr').setValue(value);
					break;
				case "MaxTirePressure":
					this.form_attr.get('maxInflationPressure_attr').setValue(value)
					break;
				case "RPM":
					this.form_attr.get('revolutionsPerMile_attr').setValue(value)
					break;
				case "MaxRimWidth":
					this.form_attr.get('approvedrimwidth_attr').setValue(value)
					break;
				case "RunFlat":
					this.form_attr.get('runflat_attr').setValue(value)
					break;
				case "OverallTireDiameter":
					this.form_attr.get('overallDiameter_attr').setValue(value)
					break;
				case "AspectRatio":
					this.form_attr.get('aspectRatio_attr').setValue(value)
					break;
				case "ProductWidth":
					this.form_attr.get('overallWidth_attr').setValue(value)
					break;
				case "TreadDepth":
					this.form_attr.get('treadDepth_attr').setValue(value)
					break;
				case "MileageWarranty":
					this.form_attr.get('warranty_attr').setValue(value)
					break;
				case "SideWall":
					this.form_attr.get('sidewall_attr').setValue(value)
					break;
				case "TireWidth":
					this.form_attr.get('sectionWidth_attr').setValue(value)
					break;
				case "sectionWidth":
					this.form_attr.get('sectionWidth_attr').setValue(value)
						break;
				case 'WinterDesignation':
					this.form_attr.get('winterdesignation_attr').setValue(value);
					break;
				case 'Size':
					this.form_attr.get('size_attr').setValue(value);
					break;
				case 'itemcode':
					this.form_attr.get('mfgpartno_attr').setValue(value);
					break;
				case 'mfg':
					// this.form_attr.get('mfg_attr').setValue(value);
					break;
				case 'productGroup':
					// this.form_attr.get('line_attr').setValue(value);
					break;
				case 'name':
					this.form_attr.get('displayName_attr').setValue(value);
					break;
				case 'UPC':
					this.form_attr.get('upc_attr').setValue(value);
					break;
				case 'SpeedRating':
					this.form_attr.get('speedrating_attr').setValue(value);
					break;
				case 'LoadIndex':
					this.form_attr.get('loadindex_attr').setValue(value);
					break;
				case 'UTQG':
					this.form_attr.get('utgq_attr').setValue(value);
					break;
				case 'benefits':
					this.form_attr.get('benefits_attr').setValue(value);
					break;
				case 'warranty':
					this.form_attr.get('warranty_attr').setValue(value);
					break;
				case 'truckdesignation':
					this.form_attr.get('truckdesignation_attr').setValue(value);
					break;
				default:
					break;
			}

		}

		populateAttributes(api: any = ''){
			if(api == 'atd'){
				if(this.atdresults.length > 1){
					this.atdresults.forEach(attr => {
						if(attr['value'] !== ''){

							switch(attr['key']){
								case 'size': //
									this.form_attr.get('size_attr').setValue(attr['value']);
									break;
								case 'itemcode':
									this.form_attr.get('mfgpartno_attr').setValue(attr['value']);
									break;
								case 'upc':
									this.form_attr.get('upc_attr').setValue(attr['value']);
									break;
								case 'speedrating':
									this.form_attr.get('speedrating_attr').setValue(attr['value']);
									break;
								case 'loadindex':
									this.form_attr.get('loadindex_attr').setValue(attr['value']);
									break;
								case 'utqg':
									this.form_attr.get('utgq_attr').setValue(attr['value']);
									break;
								case 'image':
									this.form_attr.get('image_attr').setValue(attr['value']);
									break;
								case "rpm":
									this.form_attr.get('revolutionsPerMile_attr').setValue(attr['value'])
									break;
								case "maxrimwidth":
									this.form_attr.get('approvedrimwidth_attr').setValue(attr['value'])
									break;
								case "runflat":
									this.form_attr.get('runflat_attr').setValue(attr['value'])
									break;
								case "overalltirediameter":
									this.form_attr.get('overallDiameter_attr').setValue(attr['value'])
									break;
								case "aspectratio":
									this.form_attr.get('aspectRatio_attr').setValue(attr['value'])
									break;
								case "loadindex":
									this.form_attr.get('loadindex_attr').setValue(attr['value'])
									break;
								case "productwidth":
									this.form_attr.get('overallWidth_attr').setValue(attr['value'])
									break;
								case "size":
									this.form_attr.get('size_attr').setValue(attr['value'])
									break;
								case "treaddepth":
									this.form_attr.get('treadDepth_attr').setValue(attr['value'])
									break;
								case "maxtirepressure":
									this.form_attr.get('maxInflationPressure_attr').setValue(attr['value'])
									break;
								case "mileagewarranty":
									this.form_attr.get('warranty_attr').setValue(attr['value'])
									break;
								case "sidewall":
									this.form_attr.get('sidewall_attr').setValue(attr['value'])
									break;
								case "tirewidth":
									this.form_attr.get('sectionWidth_attr').setValue(attr['value'])
									break;
								case 'winterdesignation':
									switch(attr['value']){
										case 'all other':
											attr['value'] = "0";
										break;
										case 'winter':
											attr['value'] = "1";
										break;
										case '3 peak mt. snowflake':
											attr['value'] = "2";
										break;
									}
									this.form_attr.get('winterdesignation_attr').setValue(attr['value']);
									break;
								case 'treadwear':
									this.form_attr.get('treadConstruction_attr').setValue(attr['value']);
									break;
								case 'minrimwidth':
									this.form_attr.get('measuringRimWidth_attr').setValue(attr['value']);
									break;
								case 'name':
									this.form_attr.get('displayName_attr').setValue(attr['value']);
									break;
								default:
									break;
							}
						}
					});
				}
			}

			if(api == 'tlib'){
				if(this.tlibresults.length > 1){
					this.tlibresults.forEach(attr => {
						if(attr['value'] !== ''){
							switch(attr['key']){
								case 'size':
									this.form_attr.get('size_attr').setValue(attr['value']);
									break;
								case 'itemcode':
									this.form_attr.get('mfgpartno_attr').setValue(attr['value']);
									break;
								case 'mfg':
									// this.form_attr.get('mfg_attr').setValue(attr['value']);
									break;
								case 'line':
									// this.form_attr.get('line_attr').setValue(attr['value']);
									break;
								case 'name':
									this.form_attr.get('displayName_attr').setValue(attr['value']);
									break;
								case 'upc':
									this.form_attr.get('upc_attr').setValue(attr['value']);
									break;
								case 'speedrating':
									this.form_attr.get('speedrating_attr').setValue(attr['value']);
									break;
								case 'benefits':
									this.form_attr.get('benefits_attr').setValue(attr['value']);
									break;
								case 'loadindex':
									this.form_attr.get('loadindex_attr').setValue(attr['value']);
									break;
								case 'description':
									this.form_attr.get('description_attr').setValue(attr['value']);
									break;
								case 'features':
									this.form_attr.get('features_attr').setValue(attr['value']);
									break;
								case 'utqg':
									this.form_attr.get('utgq_attr').setValue(attr['value']);
									break;
								case 'image':
									this.form_attr.get('image_attr').setValue(attr['value']);
									break;
								default:
									break;
							}
						}
					});
				}
			}

			if(api == 'fkl'){
				if(this.mfiresults.length > 1){
					this.mfiresults.forEach(attr => {
						if(attr['value'] !== '' && parseInt(attr['value']) !== 0){
							switch(attr['key']){
								case 'TreadWear':
									this.form_attr.get('treadConstruction_attr').setValue(attr['value']);
									break;
								case 'MinRimWidth':
									this.form_attr.get('measuringRimWidth_attr').setValue(attr['value']);
									break;
								case "MaxTirePressure":
									this.form_attr.get('maxInflationPressure_attr').setValue(attr['value'])
									break;
								case "RPM":
									this.form_attr.get('revolutionsPerMile_attr').setValue(attr['value'])
									break;
								case "MaxRimWidth":
									this.form_attr.get('approvedrimwidth_attr').setValue(attr['value'])
									break;
								case "RunFlat":
									this.form_attr.get('runflat_attr').setValue(attr['value'])
									break;
								case "OverallTireDiameter":
									this.form_attr.get('overallDiameter_attr').setValue(attr['value'])
									break;
								case "AspectRatio":
									this.form_attr.get('aspectRatio_attr').setValue(attr['value'])
									break;
								case "ProductWidth":
									this.form_attr.get('overallWidth_attr').setValue(attr['value'])
									break;
								case "TreadDepth":
									this.form_attr.get('treadDepth_attr').setValue(attr['value'])
									break;
								case "MileageWarranty":
									this.form_attr.get('warranty_attr').setValue(attr['value'])
									break;
								case "SideWall":
									this.form_attr.get('sidewall_attr').setValue(attr['value'])
									break;
								case "TireWidth":
									this.form_attr.get('sectionWidth_attr').setValue(attr['value'])
									break;
								case 'WinterDesignation':
									switch(attr['value']){
											case 'All Other':
												attr['value'] = "0";
											break;
											case 'Winter':
												attr['value'] = "1";
											break;
											case '3 peak mt. snowflake':
												attr['value'] = "2";
											break;
										}
									this.form_attr.get('winterdesignation_attr').setValue(attr['value']);
									break;
								case 'Size':
									this.form_attr.get('size_attr').setValue(attr['value']);
									break;
								case 'itemcode':
									this.form_attr.get('mfgpartno_attr').setValue(attr['value']);
									break;
								case 'mfg':
									// this.form_attr.get('mfg_attr').setValue(attr['value']);
									break;
								case 'productGroup':
									// this.form_attr.get('line_attr').setValue(attr['value']);
									break;
								case 'name':
									this.form_attr.get('displayName_attr').setValue(attr['value']);
									break;
								case 'UPC':
									this.form_attr.get('upc_attr').setValue(attr['value']);
									break;
								case 'SpeedRating':
									this.form_attr.get('speedrating_attr').setValue(attr['value']);
									break;
								case 'LoadIndex':
									this.form_attr.get('loadindex_attr').setValue(attr['value']);
									break;
								case 'UTQG':
									this.form_attr.get('utgq_attr').setValue(attr['value']);
									break;
								default:
									break;
							}
						}
					});
				}
			}
		}


	}
