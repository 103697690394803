
<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-3">
						<h4 class="card-title "> RSI - Sales Inquiry</h4>
						<p class="card-category"></p>
					</div>
					<div class="card-body">
					<div class="row">
						<div class="col-md-3">
						<mat-form-field appearance="standard">
			<mat-label>Date Range</mat-label>
			<mat-date-range-input [rangePicker]="picker">
				<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date" (dateChange)="fieldReset();">
				<input matEndDate [formControl]="todateCtrl" placeholder="End date" (dateChange)="fieldReset();">
			</mat-date-range-input>
			<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
			<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
			<mat-date-range-picker #picker></mat-date-range-picker>
		</mat-form-field>
						</div>
						<div class="col-md-3">
							<mat-form-field appearance="fill">
							  <mat-label>Location</mat-label>
							  <mat-select [formControl]="location" (selectionChange)="fieldReset()">
											<mat-option *ngFor="let v of branches" [value]="v.loccode">
												{{v.locationname}}
											</mat-option>
										</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-3">
						<button *ngIf="!sending" class="btn btn-primary" (click)="getRSIData()"> Calculate </button>
						<span *ngIf="sending">
							<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
						</span>
						</div>
						<div class="col-md-3">
						<button (click)="pdfJournal()" class="btn btn-danger"><i class="fa fa-file-pdf-o"></i></button>
								<button (click)="xlsJournal()" class="btn btn-success"><i class="fa fa-file-excel-o"></i></button>
						</div>
					</div>
					
					<div #rsi_details id="rsi_details">
					
					<table class="table">
					<tr><th colspan="6" style="text-align:center"> RSI - Sales Inquiry for {{startdate | date: 'M/d/Y' }} - {{enddate | date: 'M/d/Y' }}</th></tr>
					<tr><th colspan="3">Sales</th><th colspan="3">Receipts</th></tr>
					<tr><th colspan="1">Amount Ordered</th><td colspan="2" style="text-align:right">{{rsidata.placed | currency}}</td><th colspan="3" [rowSpan]="config.env.package == 'tires' ? '9' : '5'"><table>
					
					
					<tr *ngFor="let pay of rsidata.payments"><td>{{pay.name}}</td><td style="text-align:right">{{pay.amount}}</td></tr></table></th></tr>
					<tr><th colspan="1">Amount Shipped</th><td colspan="2" style="text-align:right">{{rsidata.shipped | currency}}</td></tr>
					<tr><th colspan="1">Discounts</th><td colspan="2" style="text-align:right">{{rsidata.discounts | currency}}</td></tr>
					<tr *ngIf="config.env.package == 'tires'"><th colspan="1">Fed Excise Tax</th><td colspan="2" style="text-align:right">{{rsidata.fet | currency}}</td></tr>
					<tr *ngIf="config.env.package == 'tires'"><th colspan="1">Tire Adjustments</th><td colspan="2" style="text-align:right">{{rsidata.tadj | currency}}</td></tr>
					<tr><th colspan="1">Sales Tax</th><td colspan="2" style="text-align:right">{{rsidata.tax | currency}}</td></tr>
					<tr *ngIf="config.env.package == 'tires'"><th colspan="1">Core Amount</th><td colspan="2" style="text-align:right">$0.00</td></tr>
					<tr><th colspan="1">Freight</th><td colspan="2" style="text-align:right">{{rsidata.freight | currency }}</td></tr>
					<tr *ngIf="config.env.package == 'tires'"><th colspan="1">NYS Tire Fee</th><td colspan="2" style="text-align:right">{{rsidata.tirefee | currency }}</td></tr>
					<tr *ngIf="config.env.package == 'tires'"><th colspan="1">EDF1 Fee</th><td colspan="2" style="text-align:right">{{rsidata.edf | currency }}</td></tr>
					<tr><th colspan="1">Deposits</th><td colspan="2" style="text-align:right">{{rsidata.deposits | currency }}</td></tr>
					<tr><th colspan="1">Gross Profit Dollars</th><td colspan="2" style="text-align:right">{{rsidata.gpdol | currency }}</td><th colspan="1">Cash Sales</th><td style="text-align:right">{{rsidata.cashsales | currency}}</td></tr>
					<tr><th colspan="1">Gross Profit %</th><td colspan="2" style="text-align:right">{{ (rsidata.gppct) }} %</td><th colspan="1">A/R Charges</th><td style="text-align:right">{{rsidata.onacct | currency}}</td></tr>
					<tr><th colspan="3"></th><th colspan="1">Total Sales (incl. Fees)</th><td style="text-align:right"> {{(rsidata.totalsale) | currency }}</td></tr>
					</table>
					</div>
</div>
				</div>
			</div>
		</div>
	</div>
</div>
