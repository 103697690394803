<ng-template #purchasedetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Purchase Information</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row" *ngIf="purchase_item">
			<div [ngClass]="purchase_item.purchdata.length == 1 ? 'col-md-12': 'col-md-6'" *ngFor="let pdata of purchase_item.purchdata">
				<div class="card highlight" (click)="setPurchOrderOptions(purchase_item, pdata)" [ngClass]="(pdata.supplierid == purchase_item.selected_option.supplierno) ? 'highlighted' : '' ">
					<div class="card-header text-center">
						<h4 class="card-title text-center">{{ pdata.supplierid }} - {{ pdata.suppname }} <b>{{ pdata.price | currency }}</b></h4>
					</div>
					<div class="card-body text-center">
						<div class="col-12">
							<h5>{{ pdata.stockid }} {{ pdata.supplierdescription }}</h5>
						</div>
						<div class="col-12 p-0 m-0">
							<ul class="entry-list p-0 m-0" *ngFor="let remote of pdata.remote">
								<li>
									<div class="spec-label">{{ remote.feedlabel }}:</div>
									<div class="spec-value">{{ remote.qty }}</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<form [formGroup]="reportForm" *ngIf="reportForm">
	<div class="row p-0 m-0">
		<div class="col-md-4">
			<mat-form-field appearance="standard">
				<mat-label>Supplier</mat-label>
				<mat-select formControlName="vendorid">
					<mat-option *ngFor="let sup of suppliers" [value]="sup.supplierid">
						{{ sup.supplierid }} - {{ sup.suppname }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-4">
			<mat-form-field appearance="standard">
				<mat-label>Minimum Orders</mat-label>
				<input matInput formControlName="minimum_sales">
			</mat-form-field>
		</div>
		<div class="col-md-4">
			<mat-form-field appearance="standard">
				<mat-label>Item Type</mat-label>
				<input matInput formControlName="item_type">
			</mat-form-field>
		</div>
		<div class="col-md-3">
			<mat-form-field appearance="standard">
				<mat-label>Starting Date For Sales</mat-label>
				<input matInput [matDatepicker]="orderdatepicker"
					formControlName="startdate">
				<mat-datepicker-toggle matSuffix [for]="orderdatepicker"></mat-datepicker-toggle>
				<mat-datepicker #orderdatepicker></mat-datepicker>
			</mat-form-field>
		</div>
		<div class="col-md-3">
			<mat-form-field appearance="standard">
				<mat-label>Number of Preceding Months to Analyze</mat-label>
				<input class="text-center" type="number"
				placeholder="Number of Preceding Months"
				aria-label="Number of Preceding Months"
				formControlName="numberofmonths"
				matInput
				/>
			</mat-form-field>
		</div>
		<div class="col-md-3">
			<mat-form-field appearance="standard">
				<mat-label>Number of weeks of inventory to stock</mat-label>
				<input class="text-center" type="number"
				placeholder="Number of weeks"
				aria-label="Number of weeks"
				formControlName="numberofweeks"
				matInput
				/>
			</mat-form-field>
		</div>
		<div class="col-md-3">
			<mat-form-field appearance="standard">
				<mat-label>Warehouse</mat-label>
				<mat-select formControlName="loccode">
					<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
						{{ loc.locationname }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-12 ">
			<div class="row p-2 md-0x border-bottom">
				<div class="col-6 text-left">
					<button mat-stroked-button color="warn" (click)="getRecomendations()">Run</button>
				</div>

				<div class="col-6 text-right" *ngIf="results.length">
					<button mat-stroked-button color="warn" (click)="getRecomendations()">Create Purchase Order</button>
				</div>
				<div class="col-12">
					<div class="table table-responsive text-center" *ngIf="loading">
						<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
							</circle>
						</svg>
					</div>
				</div>
			</div>
			<div class="table mt-2" *ngIf="!loading && results.length">
				<div class="row border-bottom" *ngFor="let item of results">
					<div [ngClass]="item.remote_inventory ? 'col-md-1' : 'col-md-1'">
						<button class="mb-0 pt-0 pb-0 " mat-icon-button color="accent" (click)="removeItem(item)">
							<mat-icon>remove</mat-icon>
						</button>
						<button  class="mb-0 pt-0 pb-0 " mat-icon-button color="warn" (click)="viewPurchData(item)">
							<mat-icon>settings</mat-icon>
						</button>

						<!--<div class="col-md-2 text-center ml-0 mr-0 pl-0 pr-0">
								<button class="mt-0 mb-0 pt-0 pb-0 " mat-icon-button color="accent" (click)="editBin(item)">
									<mat-icon>edit</mat-icon>
								</button>

								<app-purchasing-bin-select
									[bin]="item.bin"
									(updateBin)="updateBinItem($event, item )"
									*ngIf="item.editing"
									></app-purchasing-bin-select>
								<br>Min/Max: {{ item.min }}/{{ item.max }}
							</div>-->
					</div>
					<div [ngClass]="item.remote_inventory ? 'col-md-3' : 'col-md-3'">
						<b>{{ item.stockid }}</b><br>
						{{ item.description }}
					</div>
					<div class="col-md-2 ml-0 mr-0 pl-0 pr-0" [ngClass]="item.remote_inventory ? 'col-md-2' : 'col-md-2'">
						Prd Sales: {{ item.period_sales }}<br>
						Target: {{ item.target }}<br>
						Avg Sales: {{ item.weekly_sales_amt }}
					</div>
					<div class="col-md-2 ml-0 mr-0 pl-0 pr-0" >
					 	QOH: {{ item.qoh }}<br />
					 	Back Order : {{ item.qos }}<br />
					 	On Order: {{ item.qop }}
					</div>
					<div class="col-md-4">
						<div class="row m-0 p-0">
							<div class="col-md-6" *ngIf="item.remote_inventory">
								<ul class="entry-list p-0 m-0 bold" *ngFor="let remote of item.remote_inventory">
									<li>
										<div class="spec-label">{{ remote.feedlabel }}:</div>
										<div class="spec-value">{{ remote.qty }}</div>
									</li>
								</ul>
							</div>
							<div class="col-md-6" *ngIf="!item.remote_inventory">
								<ul class="entry-list p-0 m-0 bold" >
									<li>
										<div class="spec-label">n/a:</div>
										<div class="spec-value">n/a</div>
									</li>
								</ul>
							</div>
							<div class="col-md-6">
								<mat-form-field class="full-width text-center" appearance="standard" >
									<mat-label>Purchase Qty <span *ngIf="item.selected_option">({{ item.selected_option.supplierno }})</span></mat-label>
										<input matInput type="number"
												placeholder="Purchase Qty"
											[(value)]="item.qty_purchase"
											(input)="updateQuantity($event.target.value, item)">
									<mat-hint class="text-right ml-auto">
										<mat-icon color="accent" (click)="editBin(item)">edit</mat-icon>
										 {{ item.bin }}
									</mat-hint>
									<mat-error>
									</mat-error>
								</mat-form-field>
								<app-purchasing-bin-select
								[bin]="item.bin"
								(updateBin)="updateBinItem($event, item )"
								*ngIf="item.editing"
								></app-purchasing-bin-select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
