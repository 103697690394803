import { Injectable, } from '@angular/core';
import { catchError , retry} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClientModule,HttpHeaders,HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { GlobalSearchService } from '../services/globalsearchservice.service';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';


@Injectable({
	providedIn: 'root'
})

export class VendorService {
	key: string = 'UET6DFMPAXW7BKCB';
	config: any;
	user: any = [];
	package: any = '';

	constructor(private globalSearchService: GlobalSearchService, private http: HttpClient,private _configSvc: RuntimeConfigLoaderService) {
		this.globalSearchService.user.subscribe((result: any) => {
			this.user = result;
		});

		this.globalSearchService.configsubscription.subscribe((result: any) => {
			this.config = result;
		});
	}


	public httpOptions = {
			headers: new HttpHeaders({
			'Content-Type':	'application/json',
		})
	};


	public vendorService(id: any) {

	}
	public getAge() {
		var method = 'vendor/ageing';
		return this.http.get(this.setEndPoint(method));
	}

	public getDashBoard() {
		var method = 'vendor/dashboard';
		return this.http.get(this.setEndPoint(method));
	}

	public createInvoice(invoicedata: any) {
		var method = 'vendor/invoice/create';
		return this.http.post(this.setEndPoint(method), invoicedata, this.httpOptions).pipe(
			catchError(this.handleError(method, invoicedata))
		);
	}

	public createCredit(data: any) {
		var method = 'vendor/credit/create';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public createReturn(data: any) {
		var method = 'vendor/return/create';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public updateVendorContacts(settings: any) {
		var method = 'vendor/updatevendorcontacts';
		return this.http.post(this.setEndPoint(method), settings, this.httpOptions).pipe(catchError(this.handleError(method, settings))
		);
	}
	public updateVendorNotes(settings: any) {
		var method = 'vendor/updatenotes';
		return this.http.post(this.setEndPoint(method), settings, this.httpOptions).pipe(catchError(this.handleError(method, settings))
		);
	}

	public updateVendor(input: any) {
		var method = 'vendor/update';
		return this.http.post(this.setEndPoint(method), {vendordata: input}, this.httpOptions).pipe(
				catchError(this.handleError(method))
		);
	}

	public getVendor(vendorid: any) {
		var method = 'vendor/read&supplierid='+vendorid;
		return this.http.get(this.setEndPoint(method));
	}

	public getVendorTransactions(data: any) {
		var method = 'vendor/transactions';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
					catchError(this.handleError(method, data))
		);
	}

	public getVendors() {
		var method = 'vendor/search';
		return this.http.get(this.setEndPoint(method));
	}
	public getVendorsPost(data) {
		var method = 'vendor/search';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
					catchError(this.handleError(method, data))
		);
	}

	public getVendorSearch(keywords: any) {
		var method = 'vendor/search&keywords='+keywords;
		return this.http.get(this.setEndPoint(method));
	}

	public getVendorTypes() {
		var method = 'vendor/getvendortypes';
		return this.http.get(this.setEndPoint(method));
	}

	public getInactiveType() {
		var method = 'vendor/getinactivetype';
		return this.http.get(this.setEndPoint(method));
	}

	public getVendorItems(vendorid){
		 var method = 'vendor/purchdata&supplierid='+vendorid;
		 return this.http.get(this.setEndPoint(method));
	}

	public deleteVendor(vendorid){
		 var method = 'vendor/remove&supplierid='+vendorid;
		 return this.http.get(this.setEndPoint(method));
	}

	public saveVendor(vendordata){
		var method = 'vendor/save';
		return this.http.post(this.setEndPoint(method), {vendordata: vendordata}, this.httpOptions).pipe(
					catchError(this.handleError(method, vendordata))
		);
	}

	private setEndPoint(method: any) {
		return this.getApi()+method+this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId + '&userid=' + this.user.user.userid + this.getPackage();
	}

	private getPackage() {
		let p = this.globalSearchService.getSessionItem('package');
		if(p) {
			return '&package='+p;
		} else {
			return '';
		}
	}

	public getKey() {
		return '&apikey='+this.key;
	}

	private handleError<T>(operation = 'operation', result?: T) {
	return (error: any): Observable<T> => {
		return error;
	};
	}
}
