import { Component, SimpleChanges, OnChanges, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, EventEmitter, Pipe, PipeTransform } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors, FormControl, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Directive, HostListener } from "@angular/core";
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { CustomerService } from '../../../services/customer.service';
import { OrdersService } from '../../../services/orders.service';
import { InventoryService } from '../../../services/inventory.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { UsersService } from '../../../services/users.service';
import { DispatchService } from '../../../services/dispatch.service';
import { OmsService } from '../../../services/oms.service';
import { E } from '@angular/cdk/keycodes';
import { isNull } from 'util';
import { CouponService } from 'app/services/coupon.service';
import { compareDesc } from 'date-fns';
import { info } from 'console';

@Component({
	selector: 'app-order-review',
	templateUrl: './order-review.component.html',
	styleUrls: ['./order-review.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('250ms ease-in')),
			transition('active => inactive', animate('250ms ease-out')),
		]),
		trigger('openClose', [
			state(':enter', style({ height: '*' })),
			state(':leave', style({ height: '0px' })),
			transition('false <=> true', animate(500))
		]),
		trigger('filterAnimation', [
			transition(':enter, * => 0, * => -1', []),
			transition(':increment', [
				query(':enter', [
					style({ opacity: 0, width: 0 }),
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 1, width: '*' })),
					]),
				], { optional: true })
			]),
			transition(':decrement', [
				query(':leave', [
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 0, width: 0 })),
					]),
				])
			]),
		]),
		trigger("grow", [ // Note the trigger name
			transition(":enter", [
				// :enter is alias to 'void => *'
				style({ height: "0", width: "0" }),
				animate(200, style({ height: "*", width: "*" })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(":leave", [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0, })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class OrderReviewComponent implements OnInit {
	@Input() customer: any = false;
	@Input() cart_items: any = false;
	@Input() cart_totals: any = false;
	@Output() updateCustomer = new EventEmitter < any > ();
	@Output() updateCart = new EventEmitter < any > ();
	@Output() refreshCart = new EventEmitter < any > ();
	@Output() removeItem = new EventEmitter < any > ();
	@Output() freight_charge = new EventEmitter < any > ();

	@ViewChild("purchasedetails") purchasedetailsRef: ElementRef;
	@ViewChild("couponModal") couponModalRef: ElementRef;
	editbilling: boolean = false
	editshipping: boolean = false
	editsettings: boolean = false
	chargeTypeSwitch = new UntypedFormControl('');
	customerForm: UntypedFormGroup;
	noteselector = new UntypedFormControl(0);
	couponFormControl = new UntypedFormControl('');
	date_selected: any = false;
	purchase_items: any = [];
	customerdata: any = false;
	customerEditAllowed = [
		'debtorno',
		'branchcode',
		'name',
		'address1',
		'address2',
		'address3',
		'address4',
		'address5',
		'address6',
		'contactname',
		'brname',
		'braddress1',
		'braddress2',
		'braddress3',
		'braddress4',
		'braddress5',
		'braddress6',
		'email',
		'salesman',
		'salestype',
		'defaultshipvia',
		'phoneno',
		'faxno',
		'specialinstructions',
		'defaultlocation',
		'paymentterms',
		'tax_ref',
		'taxgroupid',
		'area',
		'datewanted',
		'enteredby',
		'takenby',
	]

	addonfields: [
		'datewanted',
		'enteredby',
		'takenby',
		'freightcost',
	];

	user: any = [];
	today = new Date();
	tomorrow = new Date();
	shipvia: any = false;
	taxgroups: any = [];
	salespeople: any = [];
	terms: any = [];
	zones: any = false;
	locations: any = [];
	holdreasons: any = [];
	salestypes: any = [];
	quote = [
		{ label: 'Order', value: '0' },
		{ label: 'Quote', value: '1' }
	]
	config: any = [];
	onetimecard: boolean = false;
	routes: any = [];
	ship_options: any = [
		{ value: "0", viewValue: "***Ship ASAP***" },
		{ value: "1", viewValue: "Ship when available" },
		{ value: "2", viewValue: "" },
	];
	shipping_notes: boolean = false;
	selected_ship_option: any = '';

	selected_card = new UntypedFormControl('');
	recalculated: boolean = false;
	couponData: any = false;
	historic_cart: any;

	editsallowed: any = {
		discounts: true,
		editar: true,
		editbilling: true,
		editshipping: true,
		editcontact: true,
		editprofile: true,
		editnotes: true,
		takepayment: true,
	}

	preOrderOpenState:boolean = false;
	constructor(private dispatchService: DispatchService, private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, public omsService: OmsService, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal, public usersService: UsersService, private couponService: CouponService) {

		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;

			this.globalSearchService.user.subscribe((results: any) => {

				if (results) {
					this.user = results.user;

					if(results.user.issalesman) {
						this.editsallowed = this.config.salesmanAllowed
					}

					if(this.customer.defaultlocation) {
						if(results.user.defaultlocation != this.customer.defaultlocation) {
							this.customer.defaultlocation = results.user.defaultlocation;
							this.customerForm.get('defaultlocation').setValue(results.user.defaultlocation.loccode);
						}
					}
				}
			});
		});

		this.dispatchService.getRoutes().subscribe(r => {
			this.routes = r;
		});



		this.tomorrow.setDate(this.tomorrow.getDate() + 1);
	}

	ngOnInit(): void {


		//globals
		this.globalSearchService.taxgroups.subscribe(async (results: any) => {
			this.taxgroups = results;
		});

		this.globalSearchService.salespeople.subscribe(async (results: any) => {
			this.salespeople = results;
		});

		this.globalSearchService.terms.subscribe(async (results: any) => {
			this.terms = results;
		});

		this.globalSearchService.holdreasons.subscribe(async (results: any) => {
			this.holdreasons = results;
		});

		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
		});
		this.globalSearchService.zones.subscribe(async (results: any) => {
			this.zones = results;
		});

		this.globalSearchService.salestypes.subscribe(async (results: any) => {
			this.salestypes = results;
		});
	}

	toggleOneTimeCard() {
		this.onetimecard = (this.onetimecard) ? false : true;
		if (this.onetimecard) {
			this.chargeTypeSwitch.setValue('card_onfile');
		}
	}

	getFormattedDate(date) {
		var year = date.getFullYear();
		var month = (1 + date.getMonth()).toString();
			month = month.length > 1 ? month : '0' + month;
		var day = date.getDate().toString();
			day = day.length > 1 ? day : '0' + day;
		return month + '/' + day + '/' + year;
	}

	shippingOptionChange(event: any) {
		let currentComment = this.customerForm.get('comments').value;
		if (currentComment === null) {
			currentComment = '';
		}

		if (event.value) {
			let new_selection = this.ship_options[event.value].viewValue;

			if (event.value == 0 || event.value == 1) {
				this.customerForm.get('datewanted').setValue(this.today);

				if(this.date_selected) {
					currentComment = currentComment.replace(this.date_selected, "").trim();
					this.date_selected = false
				}

			} else {
				this.customerForm.get('datewanted').setValue(this.tomorrow);
				var date = new Date(this.customerForm.get('datewanted').value);
				let parsed_date = this.getFormattedDate(date)
				new_selection = 'Wanted ' + parsed_date;
				this.date_selected = 'Wanted ' + parsed_date;
			}

			//if comment empty just add the current selected ship option
			if (currentComment === '') {

				this.customerForm.get('comments').setValue(new_selection + '\n');
				this.selected_ship_option = new_selection;

				//if comment is not empty
			} else {
				//if no selected ship option
				if (this.selected_ship_option === '') {
					//Add the selected ship option to existing current comment;
					this.customerForm.get('comments').setValue(new_selection + '\n' + currentComment);
					this.selected_ship_option = new_selection;
					//if previous selected ship option
				} else {
					///date wanted specifics
					if (event.value == 2) {
						var date = new Date(this.customerForm.get('datewanted').value);
						let parsed_date = this.getFormattedDate(date)
						new_selection = 'Wanted ' + parsed_date;
					}
					//strip old selected ship option and add new one
					currentComment = currentComment.replace(this.selected_ship_option, "").trim();
					this.customerForm.get('comments').setValue(new_selection + '\n' + currentComment);
					this.selected_ship_option = new_selection;
				}
			}

			//'none' value handling, strip the last actice ship_option
		} else {
			if (this.selected_ship_option !== '') {
				alert(currentComment)
				currentComment = currentComment.replace(this.selected_ship_option, "").trim();
				this.customerForm.get('comments').setValue(currentComment);
				this.selected_ship_option = '';
			}
		}
	}

	openShipNotes() {
		this.shipping_notes = (this.shipping_notes) ? false : true;
	}

	attachCard(data: any) {

		this.onetimecard = false;
		//change order terms. TODO: need to filter out terms instead
		this.customerForm.get('paymentterms').setValue('CCN');
		if (data.requested_card) {
			this.customerForm.get('payment_method').setValue(data.requested_card)
		} else {
			this.customerForm.get('payment_method').setValue(data)
		}
	}

	removePaymentMethod() {
		this.customerForm.get('payment_method').setValue(false)
	}


	updateItem(data: any, item) {
		item.purchdata = data.purchdata;
	}

	loadCustomer() {
		//let location = (this.user.user.defaultlocation) ? this.user.user.defaultlocation.loccode : this.locations[0].loccode;
		this.globalSearchService.user.subscribe((results: any) => {
			this.user = results.user;
			this.customerService.getDebtorWithBranch(this.customer.debtorno, this.customer.branchcode).subscribe(async (results: any) => {
				this.customerdata = results;
			});
		});

	}

	viewPurchOrderOptions(line: any) {
		line.allownegative = true;
		let data = { cart_id: line.cart_id, stockid: line.stockid }
		this.orderService.getPurchDataFromLine(data).subscribe((results: any) => {
			this.purchase_items.push(results);
			this.modalService.open(this.purchasedetailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result: any) => {

			}, (reason) => {
				this.purchase_items = [];
			});
		});
	}


	setPurchOrderOptions(cart_id: any, selected: any) {
		let data = { cart_id: cart_id, options: selected }
		this.orderService.setPoOptions(data).subscribe((results: any) => {
			this.purchase_items = [];
			this.modalService.dismissAll();
		});

		this.updateCart.emit(true);
	}

	changeBranch(branchcode: any) {
		let originalBranch = this.customer.branchcode;
		let ref = this.customerForm.get('reference').value


		this.customerService.getDebtorWithBranch(this.customer.debtorno, branchcode).subscribe(async (results: any) => {
			this.customer = results.customer;
			this.customerdata = results;

			if(results.customer.defaultlocation) {
				if(this.customerForm.get('defaultlocation').value != results.customer.defaultlocation) {
					this.customerForm.get('defaultlocation').setValue(results.customer.defaultlocation);
				}
			}


			this.loadData();

			this.customerForm.get('branchcode').setValue(branchcode)
			this.orderService.updateCartShipto(this.customer.debtorno, originalBranch, this.customer.branchcode).subscribe(async (branchrresults: any) => {
				this.customerForm.get('reference').setValue(ref)
			});
		});
	}

	getRouteName(id: any) {
		if (id) {
			let route = this.routes.filter(r => {
				return r.route_Id == id;
			})[0]

			if (route) {
				return route.route_description;
			}
			return '';
		} else {
			return '';
		}
	}

	stopProp(event) {
		event.stopPropagation();
	}

	loadData() {

		var dnote = '';
		var inote = '';
		var ref = '';
		var card = false;

		this.loadCustomer();
		//note already set re apply it
		if (this.customerForm) {
			if (this.customerForm.get('comments').value != '') {
				dnote = this.customerForm.get('comments').value;
			} else {
				dnote = this.customer.dispatchnote;
			}
			if (this.customerForm.get('notes').value != '') {
				inote = this.customerForm.get('notes').value;
			}

			if(this.customerForm.get('payment_method').value) {
				card = this.customerForm.get('payment_method').value;
			}
		}

		//reset form based on existing order details

		if (this.customer.editingorder) {
			dnote = this.customer.editingorder.notes;
			inote = this.customer.editingorder.comments;
			ref = this.customer.editingorder.customerref;

			//preselected payment method
			if (this.customer.preselected) {
				card = this.customer.preselected;
			}
		}

		var location = this.customer.defaultlocation;
		//var location = this.user.user.defaultlocation.loccode;
		if (this.user) {
			this.customer.defaultlocation = this.user.defaultlocation.loccode;
			location = this.user.defaultlocation.loccode;
		}

		this.customerForm = this.fb.group({
			debtorno: [this.customer.debtorno, Validators.required],
			branchcode: [this.customer.branchcode, Validators.required],
			name: [this.customer.name, Validators.required],
			address1: [this.customer.address1, Validators.required],
			address2: [this.customer.address2],
			address3: [this.customer.address3, Validators.required],
			address4: [this.customer.address4, Validators.required],
			address5: [this.customer.address5, Validators.required],
			address6: [this.customer.address6],
			brname: [this.customer.brname, Validators.required],
			braddress1: [this.customer.braddress1, Validators.required],
			braddress2: [this.customer.braddress2],
			braddress3: [this.customer.braddress3, Validators.required],
			braddress4: [this.customer.braddress4, Validators.required],
			braddress5: [this.customer.braddress5, Validators.required],
			braddress6: [this.customer.braddress6],
			reference: [ref],
			paymentterms: [this.customer.paymentterms, Validators.required],
			datewanted: [this.today, Validators.required],
			email: [this.customer.email],
			defaultlocation: [location, Validators.required],
			salesman: [this.customer.salesman, Validators.required],
			phoneno: [this.customer.phoneno],
			enteredby: [this.user.userid, Validators.required],
			takenby: [this.user.userid, Validators.required],
			defaultshipvia: [this.customer.defaultshipvia, Validators.required],
			salestype: [this.customer.salestype, Validators.required],
			freightcost: [0.00],
			comments: [dnote],
			notes: [inote],
			payment_method: [card]
			//quote: ['0', Validators.required]
		});

		if (this.customer.customerpoline === true || this.customer.customerpoline === '1') {
			this.customerForm.get('reference').setValidators([Validators.required])
			this.customerForm.get('reference').updateValueAndValidity();
			if (ref != '') {
				this.customerForm.get('reference').setValue(ref);
			}
		} else {

		}
		//this.customerForm.markAllAsTouched();
		this.globalSearchService.shipvia.subscribe((results: any) => {
			if (results !== null) {
				this.shipvia = results;
				let shipvia = results.filter(v => {
					v.shipper_id == this.customer.defaultshipvia
				})[0]
				if (shipvia) {
					this.customerForm.get('freightcost').setValue(shipvia.mincharge);
				}
			}
		});

		this.customerForm.get('name').disable();
		this.customerForm.get('address1').disable();
		this.customerForm.get('address2').disable();
		this.customerForm.get('address3').disable();
		this.customerForm.get('address4').disable();
		this.customerForm.get('address5').disable();
		this.customerForm.get('address6').disable();

		this.customerForm.updateValueAndValidity();

		this.customerForm.valueChanges.subscribe(selectedValue => {
			this.updateCustomer.emit(this.customerForm);
		})

		this.customerForm.get('datewanted').valueChanges.subscribe( newValue => {
			if(this.noteselector.value == 2) {
				if(this.date_selected) {
					let parsed_date = this.getFormattedDate(newValue)
					let new_selection = 'Wanted ' + parsed_date;

					if(this.date_selected) {
						let currentComment = this.customerForm.get('comments').value;
						if (currentComment === null) {
							currentComment = '';
						}
						currentComment = currentComment.replace(this.date_selected, "").trim()
						this.customerForm.get('comments').setValue(new_selection + '\n' + currentComment);
						this.date_selected = 'Wanted ' + parsed_date;
					}
				}
			}
		});


		// this.customerForm.get('datewanted').disable();
	}
	updateShipping() {

		if(this.customer.editingorder) {
			this.customer.editingorder.freightcost = this.customerForm.get('freightcost').value;
		}

		setTimeout(() => {
			this.freight_charge.emit(this.customerForm.get('freightcost').value);
			this.updateCustomer.emit(this.customerForm);
		}, 800);

	}

	repriceCart(event: any) {
		if (event.value != this.customer.salestype) {
			this.customer.salestype = event.value
			let data = {
				debtorno: this.customer.debtorno,
				branchcode: this.customer.branchcode,
				salestype: event.value,
			}
			this.orderService.repriceCart(data).subscribe((result: any) => {
				this.sendCartUpdate();
			});
		}
	}

	toggleEditShipping() {
		this.editshipping = (this.editshipping) ? false : true;
	}

	toggleEditBilling() {
		this.editbilling = (this.editbilling) ? false : true;
	}

	toggleEditSettings() {
		this.editsettings = (this.editsettings) ? false : true;
	}

	updatePrice(event: any, item: any, recalc?: any) {
		if (event.target.value != '') {
			let index = this.cart_items.indexOf(item);
			this.cart_items[index].price = event.target.value;

			this.updateCartItem(this.cart_items[index]);
			if(recalc == true){
				this.recalculateCoupon();
			}
		}
	}

	updateQuantity(event: any, item: any, recalc?: any) {
		if (event.target.value != '') {
			let index = this.cart_items.indexOf(item);
			this.cart_items[index].quantity = event.target.value;

			//this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
			this.updateCartItem(this.cart_items[index]);
			if(recalc == true){
				this.recalculateCoupon();
			}
		}
	}

	getDiscounPrice(item: any) {
		var discountprice = this.financial((item.price * (item.discount / 100)));
		var total = this.financial(item.price - discountprice);
		// if (total < 0) {
		// 	total = 0;
		// }
		return total;
	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	getDiscountTotal(item: any) {

		var discounted = (item.price * (item.discount / 100)).toFixed(2);
		var discountprice = (item.price - parseFloat(discounted))
		var total = item.quantity * discountprice;
		// if (total < 0) {
		// 	total = 0;
		// }

		if(item.stockid !=='COUPON'){
			var discounted = (item.price * (item.discount / 100)).toFixed(2);
			var discountprice = (item.price - parseFloat(discounted))
			var total = item.quantity * discountprice;
			if (total < 0 ) {
				total = 0;
			}

		} else {
			if(item.discount > 0){
				var discounted = (item.price * (item.discount / 100)).toFixed(2);
				var discountprice = (item.price - parseFloat(discounted))
				var total = item.quantity * discountprice;
			} else{
				var tot = (item.price * item.quantity).toFixed(2);
				var totprice = parseFloat(tot);
				var total = totprice;

			}

		}

		return total;

	}

	updateDiscount(event: any, item: any) {

		if(event.target.value != '') {
			if (parseFloat(event.target.value) < 100) {

			} else {
				event.target.value = 100;
			}
			let index = this.cart_items.indexOf(item);
			this.cart_items[index].discount = event.target.value;
			// if(item['coupon_used'] != '' && this.config.env.package == 'beauty'){
			// 	this.removeCoupon(item);
			// }
			this.updateCartItem(this.cart_items[index]);
		}
	}



	updateNote(event: any, item: any) {
		let index = this.cart_items.indexOf(item);
		this.cart_items[index].notes = event.target.value;
		this.updateCartItem(this.cart_items[index]);
	}

	updateCartItem(item: any) {


		let index = this.cart_items.indexOf(item);


		let data = {
			customer: this.customer.debtorno,
			branch: this.customer.branchcode,
			cartid: item.cart_id,
			cart_id: item.cart_id,
			price: item.price,
			option: item.options,
			quantity: item.quantity,
			discount: item.discount,
			notes: item.notes,
			coupon_used: item.coupon_used
			//editing_order: this.editing_order,
			//order_details: this.order_details,
		};


		this.updateCart.emit(data);



	}


	removeFromOrder(cart_id) {
		this.removeItem.emit(cart_id)
	}

	sendCartUpdate() {
		this.updateCart.emit(true);
	}

	ngOnChanges(changes: any) {

		if (changes.customer) {
			this.loadData();
		}

		if (this.customer.editingorder) {
			let ref = this.customer.editingorder.customerref;

			this.customerForm.get('branchcode').setValue(this.customer.editingorder.branchcode)
			// this.customerForm.get('reference').setValidators([Validators.required])
			this.customerForm.get('reference').updateValueAndValidity();
			this.customerForm.get('reference').setValue(ref);
			this.customerForm.get('comments').setValue(this.customer.editingorder.notes);
			this.customerForm.get('notes').setValue(this.customer.editingorder.comments);

			this.customer.brname = this.customer.editingorder.brname;
			this.customer.deliverto = this.customer.editingorder.deliverto;
			this.customer.branchcode = this.customer.editingorder.branchcode;

			this.customer.braddress1 = this.customer.editingorder.deladd1;
			this.customer.braddress2 = this.customer.editingorder.deladd2;
			this.customer.braddress3 = this.customer.editingorder.deladd3;
			this.customer.braddress4 = this.customer.editingorder.deladd4;
			this.customer.braddress5 = this.customer.editingorder.deladd5;
			this.customer.braddress6 = this.customer.editingorder.deladd6;
			this.customer.contactname = this.customer.editingorder.deliverto;
			this.customer.shipto = this.customer.editingorder.shipto;

			this.customerForm.get('brname').setValue(this.customer.editingorder.deliverto);
			this.customerForm.get('braddress1').setValue(this.customer.editingorder.deladd1)
			this.customerForm.get('braddress2').setValue(this.customer.editingorder.deladd2)
			this.customerForm.get('braddress3').setValue(this.customer.editingorder.deladd3)
			this.customerForm.get('braddress4').setValue(this.customer.editingorder.deladd4)
			this.customerForm.get('braddress5').setValue(this.customer.editingorder.deladd5)
			this.customerForm.get('braddress6').setValue(this.customer.editingorder.deladd6);
			this.customerForm.get('email').setValue(this.customer.editingorder.contactemail);
			this.customerForm.get('phoneno').setValue(this.customer.editingorder.contactphone);
			this.customerForm.get('freightcost').setValue(this.customer.editingorder.freightcost);
			this.customerForm.get('salesman').setValue(this.customer.editingorder.salesperson);
			;


			if (this.user) {
				if(this.customer.editingorder.fromstkloc != this.user.defaultlocation.loccode && this.customer.editingorder.fromstkloc ) {
					this.customerForm.get('defaultlocation').setValue(this.customer.editingorder.fromstkloc );
				}
			}

			if (this.customer.preselected) {
				this.customerForm.get('payment_method').setValue(this.customer.preselected);
				this.attachCard(this.customer.preselected);
			}

		} else {
			let ref = this.customerForm.get('reference').value;

			this.customerForm.get('comments').setValue('');
			this.customerForm.get('notes').setValue('');
			if(ref == ''){
			this.customerForm.get('reference').setValue('');
			}

		}

		if(this.config.env.package == 'beauty' && ( this.recalculated == false || this.cart_items !== this.historic_cart)){
			this.historic_cart = this.cart_items;
			this.recalculateCoupon();
		}
		this.updateCustomer.emit(this.customerForm);
	}


	/**
	 * Helper function to return date
	 * @returns Current UTC date in YYYY/MM/DD
	 */
	getTimeFormat(): string {
		const cTime = new Date();

		const month = (((cTime.getUTCMonth() + 1) < 10) ? '0' : '') + (cTime.getUTCMonth() + 1);
		const day = ((cTime.getUTCDate() < 10) ? '0' : '') + cTime.getUTCDate();
		const year = cTime.getUTCFullYear();

		return year + '/' + month + '/' + day;
	}



	applyCoupon(input: any) {
		let code = this.couponFormControl.value;

		let data = {
			code: code,
			customer: this.customer,
			user: this.user.userid,
			cart: this.cart_items
		}

		this.couponData == false ? this.couponData = {} : '';

		this.couponService.applyCoupon(data).subscribe((result: any) => {

			if(result['status']){
				this.globalSearchService.showNotification(result.message, 'success', 'bottom', 'left');
				this.couponData[code] = {data: result['data'], items: result['prods'], categories: result['cats']};
				this.refreshCart.emit();

			}else{
				this.globalSearchService.showNotification(result.message, 'danger', 'bottom', 'left');
				return;
			}
		});

	}


	recalculateCoupon(){
		let items = [];
		let coupons = [];
		(this.cart_items).forEach(item => {
			let data = {
				stockid: item.stockid,
				quantity: item.quantity,
				category: item.categoryid,
				price: item.price
			}
			item['stockid'] == 'COUPON' ? coupons.push(item) : items.push(data);
		});
		if(coupons.length == 0){
			return;
		}

		let data = {
			items: items,
			coupons: coupons,
			customer: this.customer['debtorno'],
			branch: this.customer['branchcode']
		}

		let activeCouponData = {};

		this.couponService.recalculateCoupons(data).subscribe((result: any) => {
			result.forEach(item => {

				activeCouponData[item.data.code] == undefined ? activeCouponData[item.data.code] = item : '';
				let cart_item = this.cart_items.filter((i)=> i.cart_id == item.line.cart_id)[0];

				let index = this.cart_items.indexOf(cart_item);

				let event = {target:{value:''}};

				if(item.line.discount !== 'invalid'){
					event.target.value = this.financial(item.line.price) + '';
					this.updatePrice(event, cart_item, false);
					this.cart_items[index].validity = 'valid';

				}else{
					event.target.value = "0";
					this.updatePrice(event, cart_item, false);
					this.cart_items[index].validity = item.line.discount;
				}


			});
			this.couponData = activeCouponData;
			this.recalculated = true;
		});

	}

	removeCoupon(item: any){
		let code = item['coupon_used'];
		let data = {
			customer: this.customer['debtorno'],
			code: code,
		}
		this.couponService.getCouponData(data).subscribe((result: any) => {
			let type = result['type'];
			if(type == 0){
				this.removeFromOrder(item['cart_id']);
			}else{
				item['coupon_used'] = '';
				item['discount'] = 0;
				this.updateCartItem(item);
				this.removeFromOrder(item['cart_id']);
			}
			//types don't really matter anymore - can remove both the same way ^
			this.recalculateCoupon();
		});
	}


	isCouponFixed(code: any){
		let type = this.couponData[code].data.type == 0 ? '$' : '';
		return type;
	}

	isCouponPercent(code: any){
		let type = this.couponData[code].data.type == 1 ? '%' : '';
		return type;
	}

	ngAfterViewInit() {

	}
}
