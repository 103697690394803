<ng-template #breakdownModal let-modal>
<div class="modal-content">
	<div class="modal-header">
		<h4>Kit Breakdown</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>

	<div class="modal-body">


				<div class="row">
					<div class="col-md-4" >
						{{activeBom.bomParent}}
					</div>
				</div>
				<div class="row">
					<div class="col-md-4" >
					<table>
					<tr><th>Number in Stock</th><th>Number to Breakdown</th></tr>
					<tr><td>{{bdData.parentInStock}}</td><td>
					<mat-form-field>
										<input type="text" class="text-right m-0 p-0" matInput (input)="updateBD($event.target.value)" />
										</mat-form-field>
									</td></tr>
					</table>
					</div>
					<div class="col-md-2" >
						<mat-icon>arrow_right_alt</mat-icon>
					</div>
					<div class="col-md-6" >
					<table>
					<tr><th>Component</th><th>Number in Stock</th><th>Number per Kit</th><th>Number to Add</th></tr>
					<tr *ngFor="let bomline of bdData.bomlines"><td>{{bomline.component}}<td>{{bomline.qoh}}</td><td>{{bomline.quantity}}</td><td>{{bomline.qtyadd}}</td></tr>
					</table>
					</div>

				</div>

	</div>

	<div class="modal-footer"><a mat-raised-button style="margin-left: 5px;" (click)="processBreakdown();">Breakdown Kit</a></div>
</div>
</ng-template>

<!-- modal -->

		<!-- modal header -->
		<div class="modal-header" [formGroup]="bomForm">

			<!-- viewing -->
			<h3 style="display: flex; flex-direction: initial;">
				<div style="display: flex; flex-direction: column;">
					<div style="display: flex; flex-direction: row;">
						<span *ngIf="bomParentEditing == false; else editing">
							<a mat-raised-button (click)="editBomParent();"  style="margin-right: 5px; margin-left: -10px;" >
								<mat-icon>edit</mat-icon>
							</a>
							<b>{{activeBom.bomParent}}</b>
							| Viewing BOM Information<br>
							<a mat-raised-button (click)="breakdown();"  style="margin-right: 5px; margin-left: -10px;" >
								<mat-icon>auto_awesome</mat-icon> Break Down
							</a>
						</span>
						<ng-template #editing>
							<a mat-raised-button (click)="bomForm.get('activeBomParent').valid ? saveAndClose():validityAlert();" [disabled]="!bomForm.get('activeBomParent').valid" style="margin-right: 5px; margin-left: -10px;" >
								<mat-icon >save</mat-icon>
							</a>

							<b>{{activeBom.bomParent}}</b>
							| Editing BOM Information
						</ng-template>

					</div>

					<!-- editing -->
					<div style="display: flex; flex-direction: column; font-size: 15px;" *ngIf="bomParentEditing && activeBom">
						<div style="display: flex; flex-direction: row; height: 28px;">
							<span style="font-size: 13px; width: 85px; line-height: 50px; text-align: left;">Effective:</span>
							<md-input-container class="attribute-formfield">
								<input matInput type="date" placeholder="" value="{{activeBom['bomParent_effectiveto']}}" formControlName="activeBomParent_effectiveafter" class="elipse" style="width: 100%;">
							</md-input-container>
						</div>
						<div style="display: flex; flex-direction: row; height: 28px;">
							<span style="font-size: 13px; width: 85px; line-height: 50px; text-align: left;">Until:</span>
							<md-input-container class="attribute-formfield">
								<input matInput type="date" placeholder="" value="{{activeBom['bomParent_effectiveto']}}" formControlName="activeBomParent_effectiveto" class="elipse" style="width: 100%;">
							</md-input-container>
						</div>
					</div>
				</div>
			</h3>
			<mat-icon style="float: right;" (click)="close()">close</mat-icon>
		</div>


		<!-- modal body -->
		<div class="modal-body">

			<!-- active bom list -->
            <ng-container *ngIf="activeBom">
                <div class="row" [formGroup]="bomForm" [ngClass]="bomParentEditing ? 'pe-auto':'pe-none disabled'">
                    <table class="table table-sm table-bordered table-striped text-center">
                        <thead>
                            <tr>
                                <th>Sequence</th>
                                <th>Component</th>
                                <th>Loccode</th>
                                <th>Effecitve After</th>
                                <th>Effective To</th>
                                <th>Quantity</th>
								<th>Action</th>
                            </tr>
                        </thead>
                        <tbody cdkDropList (cdkDropListDropped)="drop($event)" >
                            <tr cdkDrag cdkDragLockAxis="y" *ngFor="let part of activeBom">
                                <td cdkDragHandle>{{part.sequence}}</td>
                                <td>{{part.component}}</td>
                                <td *cdkDragPreview matchSize="true">{{part.component}}</td>
                                <td>{{part.loccode}}</td>
                                <td>
									<div style="width: 85%; margin-left: auto; margin-right: auto;">
										<md-input-container class="attribute-formfield">
											<input mdInput type="date" placeholder="" value="" formControlName="{{part.component + '_effectiveafter'}}" class="elipse" style="width: 100%;">
										</md-input-container>
									</div>
								</td>
                                <td>
									<div style="width: 85%; margin-left: auto; margin-right: auto;">
										<md-input-container class="attribute-formfield">
											<input mdInput type="date" placeholder="" value="" formControlName="{{part.component + '_effectiveto'}}" class="elipse" style="width: 100%;">
										</md-input-container>
									</div>
								</td>
                                <td>
									<div style="width: 50%; margin-left: auto; margin-right: auto;">
										<md-input-container class="attribute-formfield">
											<input mdInput type="number" placeholder="" value="" formControlName="{{part.component}}" class="elipse" style="width: 60%;">
										</md-input-container>
									</div>
								</td>
								<td>
									<a mat-button (click)="removeComponent(part)"><mat-icon [ngClass]="bomParentEditing ? 'active':'inactive'">delete</mat-icon></a>
								</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </ng-container>

				<hr>



            <div [ngClass]="bomParentEditing ? '':'hidden'">

				<div class="flex-row">
					<mat-form-field appearance="standard" class="searchbar">
						<input matInput [formControl]="componentlookup" value="" class="margin-top" autocomplete="off"
							placeholder="* Component Search  "
							(keyup.enter)="$event.stopPropagation();$event.preventDefault();componentSearch();$event.target.blur();">
						<div matSuffix class="text-right">
							<button mat-icon-button type="button" color="white"
								(click)="$event.stopPropagation();$event.preventDefault();componentSearch()">
								<mat-icon>search</mat-icon>
							</button>
						</div>
					</mat-form-field>
				</div>


					<div class="table table-responsive text-center searchlist" *ngIf="componentsearching">
						<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</div>

					<div class="table table-responsive searchlist" *ngIf="componentitemlist && componentitemlist.length > 1">
						<table mat-table [dataSource]="componentdatasource" class="mat-elevation-z8" *ngIf="componentdatasource">

							<ng-container matColumnDef="stockid">
								<th mat-header-cell *matHeaderCellDef> Stock ID </th>
								<td mat-cell *matCellDef="let element"> {{element.stockid}} </td>
							</ng-container>

							<ng-container matColumnDef="description">
								<th mat-header-cell *matHeaderCellDef> Description </th>
								<td mat-cell *matCellDef="let element"> {{element.description}} </td>
							</ng-container>

							<ng-container matColumnDef="loccode">
								<th mat-header-cell *matHeaderCellDef> Loccode </th>
								<td mat-cell *matCellDef="let element"> {{element.loccode}} </td>
							</ng-container>

							<ng-container matColumnDef="mfgpart">
								<th mat-header-cell *matHeaderCellDef> mfgpart # </th>
								<td mat-cell *matCellDef="let element"> {{element.mfgpart}} </td>
							</ng-container>

							<ng-container matColumnDef="action">
								<th mat-header-cell *matHeaderCellDef> Action </th>
								<td mat-cell *matCellDef="let element"><a mat-raised-button (click)="addToBom(element)">Add</a></td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="componentdisplayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: componentdisplayedColumns;"></tr>

						</table>

					</div>

				<mat-paginator #paginator [pageSizeOptions]="[15,25,50]" showFirstLastButtons></mat-paginator>
            </div>

		</div>




	<!-- </ng-template> -->
