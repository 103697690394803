import { Component, OnDestroy, OnInit, ViewChild, OnChanges, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import * as FileSaver from 'file-saver';

import { Directive, HostListener } from "@angular/core";
import { trigger, state, keyframes, style, transition, animate, animation } from '@angular/animations';
import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { UsersService } from '../../services/users.service';
import { PaymentsService } from '../../services/payments.service';
import { OmsService } from '../../services/oms.service';

@Component({
	selector: 'app-pos-receipt',
	templateUrl: './pos-receipt.component.html',
	styleUrls: ['./pos-receipt.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('250ms ease-in')),
			transition('active => inactive', animate('250ms ease-out')),
		]),
		trigger("grow", [ // Note the trigger name
			transition(":enter", [
				// :enter is alias to 'void => *'
				style({ height: "0", width: "0" }),
				animate(200, style({ height: "*", width: "*" })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(":leave", [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0, })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
		trigger('cinfo', [
			transition(':enter', [
				animate('50ms', style({ opacity: 1, height: 100 })),
			]),
			transition(':leave', [
				animate('100ms', style({ opacity: 1, height: 0 }))
			]),
		]),
		trigger('itemSearch', [
			transition(':enter', animation([style({ transform: 'translate(-800px,0)', }),
				animate('0.2s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(1200px,0)', })
				),
			])),
		]),
		trigger('addFilters', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
		trigger('navIn', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.10s cubic-bezier(0.59, 0.32, 0.38, 0.33)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.0s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
		trigger('shakeit', [
			state('shakestart', style({
				transform: 'scale(1)',
			})),
			state('shakeend', style({
				transform: 'scale(1)',
			})),
			transition('shakestart => shakeend', animate('1000ms ease-in',
				keyframes([
					style({ transform: 'translate3d(-1px, 0, 0)', offset: 0.1 }),
					style({ transform: 'translate3d(2px, 0, 0)', offset: 0.2 }),
					style({ transform: 'translate3d(-4px, 0, 0)', offset: 0.3 }),
					style({ transform: 'translate3d(4px, 0, 0)', offset: 0.4 }),
					style({ transform: 'translate3d(-4px, 0, 0)', offset: 0.5 }),
					style({ transform: 'translate3d(4px, 0, 0)', offset: 0.6 }),
					style({ transform: 'translate3d(-4px, 0, 0)', offset: 0.7 }),
					style({ transform: 'translate3d(2px, 0, 0)', offset: 0.8 }),
					style({ transform: 'translate3d(-1px, 0, 0)', offset: 0.9 }),
				]))),
		])
	],
})
export class PosReceiptComponent implements OnInit {
	color: any = 'blue';
	config: any = [];
	receipt_data: any = false;
	recDisplay: any = '';
	sendemail: any = '';
	email = new UntypedFormControl();
	constructor(private paymentsService: PaymentsService, private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, public omsService: OmsService, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal, public usersService: UsersService) {
		this.color = this.globalSearchService.getColor();
		this.config = this.globalsService.getApiConfig()
	}

	ngOnInit(): void {
		this.globalSearchService.mesagesallowed.next(false);

		this.route.params.subscribe(params => {
			if (params['id'] != '' && params['id'] !== undefined) {
				this.orderService.getOrderReceipt(params['id']).subscribe((rec: any) => {
					this.receipt_data = rec;
					this.recDisplay = rec.display;
				})
			}
		});
	}

	newOrder() {
		let url = '/counter-pos';
		this.router.navigate([url]);
	}

	printReceipt() {
		let data = {
			id: this.receipt_data.id
		}
		this.orderService.printOrderReciept(data).subscribe( r=> {

		});
	}

abstract

	emailReceipt() {
		let data = {
			id: this.receipt_data.id
		}
		this.globalsService.emailInvoice(this.receipt_data.transid, 'email', this.email.value).subscribe((result: any) => {
		});
	}

}
