import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class PermissionsService {
	featurePermission: any = false;

	checkPermission(user: any, feature: any, permission: any): boolean {

		if(user.user.access_permissions) {
			this.featurePermission = user.user.access_permissions.find( f =>{
				return f == feature
			});
		}

		if(this.featurePermission) {
			if (!!this.featurePermission) {
				switch (permission) {
					case permission.view:
						return this.featurePermission.permission !== permission.view;
					case permission.edit:
						return this.featurePermission.permission === permission.edit;
					}
				}
			}

		return false;
	}
}
