import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { PrintService } from '../../../services/print.service'

@Component({
	selector: 'app-purchase-backorders',
	templateUrl: './purchase-backorders.component.html',
	styleUrls: ['./purchase-backorders.component.scss']
})

export class PurchaseBackordersComponent implements OnInit {
	@Input() dataset: any;
	@Input() title: any = '';
	@ViewChild('print_table') printtable: ElementRef;
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	filename: string = 'BackOrders';

	headercolumns: string[] = [
		'SUPPLIER',
		'DATE',
		'ORDERNO',
		'CUSTOMER',
		'NAME',
		'PHONE',
		'SALESPERSON',
		'ITEMCODE',
		'DESCRIPTION',
		'OUTSTANDING',
		'PRICE',
		'DISCOUNT',
		'NETTOTAL',
		'QOH'
	];
	statuses: any = [];
	constructor(private printService: PrintService,private globalSearchService: GlobalSearchService) {}

	ngOnInit(): void {
		this.statuses = this.dataset.statuses
		this.dataset.pos.sort = this.sort;
		this.dataset.pos.paginator = this.paginator;
	}

	exportPdf() {

		let encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls() {
		let encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}


	announceSortChange(event: any) {

	}

	getStatus(id: any) {
		return this.statuses.filter((st) => {
			return st.status_id = id
		})[0];
	}

}
