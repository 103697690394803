<ng-template #inactiveModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Re-Activate Customer</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3">
			<h3 class="bg-danger text-center"> CONFIRM CUSTOMER ACCOUNT </h3>
			<app-customer-shiptos [edittoggle]="edittoggle" [newcustomer]="newcustomertoggle" [customerdata]="customer_details" (editing)="updateEditing($value)" (newcustomerdata)="updateCustomer($event)" (updatebranch)="updateBranch($event)"></app-customer-shiptos>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #itemdetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title"><b>{{ item_details.item.stockid }}</b><span [innerHTML]="item_details.item.name"></span> &nbsp;&nbsp;&nbsp;
			<a [routerLink]="'/inventory/view/'+item_details.item.stockid"><i class="pointer fa fa-edit"></i></a>
		</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row">


			<mat-card class="tire-card " *ngIf="item_details">
				<mat-card-title-group>
					<mat-card-title>
						<h3 [innerHTML]="item_details.descriptions.name"></h3>
						<section class="section-small" [innerHTML]="item_details.descriptions.description"></section>
					</mat-card-title>
					<div class="tire-image">
						<div class="tire-image-container" *ngIf="item_details.item.image_large">
							<img mat-card-lg-image src="{{ item_details.image_large }}" class="img img-responsive large-thumb" lazy>
						</div>
					</div>
				</mat-card-title-group>
				<mat-card-content class="padding-0">
					<mat-tab-group #tabPanel mat-align-tabs="start" color="accent">
						<mat-tab label="Overview">
							<div class="row">
								<div class="col-md-6">
									Features:<br>
									<span [innerHTML]="item_details.descriptions.features"></span>
								</div>
								<div class="col-md-6">
									Benefits:<br>
									<span [innerHTML]="item_details.descriptions.benefits"></span>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Attributes">
							<div class="row">
								<div class="col-md-6">
									<ul class="spec-list" *ngFor="let a of item_details.additional; let i = index">
										<li *ngIf="!(i % 2)">
											<div class="spec-label"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
								<div class="col-md-6">
									<ul class="spec-list" *ngFor="let a of item_details.additional; let i = index">
										<li *ngIf="i % 2">
											<div class="spec-label ml-1"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Specs">
							<!-- <mat-form-field appearance="standard" *ngIf="!addingattribute" class="text-right">
								<mat-label>Search Attributes</mat-label>
								<input type="text" class="text-right m-0 p-0" matInput (input)="searchAttributes($event.target.value)" />
							</mat-form-field> -->

							<div class="row">
								<div class="col-6">
									<ul class="spec-list" *ngFor="let a of item_details.specs; let i = index">
										<li *ngIf="!(i % 2)">
											<div class="spec-label"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
								<div class="col-6">
									<ul class="spec-list" *ngFor="let a of item_details.specs; let i = index">
										<li *ngIf="i % 2">
											<div class="spec-label ml-1"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
							</div>
						</mat-tab>
					</mat-tab-group>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #taxdetails let-modal>
	<div class="modal-header">
		<h4> Tax Details</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="table mt-3" *ngIf="item_details">

			<h4 class="text-center" *ngIf="!item_details">
				No Results
			</h4>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #monthDetails let-modal>
	<div class="modal-header">
		<h4> Item Details</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<!-- <div class="col-md-auto text-right mt-2">
			<mat-checkbox [formControl]="hideshowunits">Units&nbsp;</mat-checkbox>
			<mat-checkbox [formControl]="hideshowsales">Sales&nbsp;</mat-checkbox>
			<mat-checkbox [formControl]="hideshowcost" *ngIf="user.user.viewcost">Cost&nbsp;</mat-checkbox>
		</div> -->
		<div class="table mt-3" *ngIf="brand_details">

			<!-- <div class="table mt-3" *ngIf="brand_details" #print_history>
				<table class="table table-bordered" *ngFor="let year of brand_details.years">
					<tr>
						<th></th>
						<th *ngFor="let month of year.sales" class="text-center">
							{{ month.label }} {{ month.year }}
						</th>
						<th class="text-center">Total</th>
					</tr>

					<tr *ngIf="hideshowunits.value">
						<td class="text-center">Qty</td>
						<td class="text-center" *ngFor="let month of year.qty">
							<button mat-flat-button (click)="viewHistory( month.monthnumber, month.year)">{{ month.value | number:'1.0-0' }}</button>
						</td>
						<td class="text-center">{{ getQtyTotalYear( year ) }}</td>
					</tr>
					<tr *ngIf="hideshowsales.value">
						<td class="text-center">Sales </td>
						<td class="text-center" *ngFor="let month of year.sales">
							{{ month.value | currency }}
						</td>
						<td class="text-center">{{ getSaleTotalYear( year ) | currency }}</td>
					</tr>
					<tr *ngIf="hideshowcost.value">
						<td class="text-center">Cost </td>
						<td class="text-center" *ngFor="let month of year.cost">
							{{ month.value | currency }}
						</td>
						<td class="text-center">{{ getCostTotalYear( year ) | currency }}</td>
					</tr>
				</table>
			</div> -->

			<table class="table table-striped table-fixed table-hover" *ngIf="brand_details">
				<thead>
					<tr>
						<th>DOCUMENT#</th>
						<th>TYPE</th>
						<th>LOCATION#</th>
						<th>DATE</th>
						<th>ITEM#</th>
						<th>DESC</th>
						<th class="text-center">DISCOUNT</th>
						<th class="text-center">QTY</th>
						<th class="text-right">PRICE</th>
						<th class="text-right">TOTAL</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of brand_details.items">
						<td>
							<!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More" class="donotprint">
							<mat-icon>more_vert</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<button mat-menu-item *ngIf="item.type == '10' || item.type == '11'" (click)="openDocument(item,details,'html')">
								<mat-icon>visibility</mat-icon>
								<span>View HTML</span>
							</button>
							<button mat-menu-item *ngIf="item.type == '10' || item.type == '11'" (click)="openDocument(item,details,'pdf')">
								<mat-icon>visibility</mat-icon>
								<span>View PDF</span>
							</button>
							<button mat-menu-item (click)="creditInvoice(item)" *ngIf="item.type == '10'">
								<mat-icon>history</mat-icon>
								<span>RMA/Credit</span>
							</button>
						</mat-menu> -->
							{{ item.transno }}
						</td>
						<td>{{ item.typename }}</td>
						<td>{{ item.loccode }}</td>
						<td>{{ item.trandate }}</td>
						<td>{{ item.stockid }}</td>
						<td>{{ item.description }}</td>
						<td class="text-center">{{item.discountpercent }}</td>
						<td class="text-center">{{ item.qty }}</td>
						<td class="text-right">{{ item.price | currency }}</td>

						<td class="text-right">{{ item.discountedlinetotal | currency }}</td>
					</tr>
				</tbody>
			</table>
			<h4 class="text-center" *ngIf="!brand_details.items">
				No Results
			</h4>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #orderModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Load Order</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body" *ngIf="customer_details">
		<app-open-orders [customerdata]="customer_details" (orderloaded)="loadOrder($event)"></app-open-orders>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #quoteModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Load Quote</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body" *ngIf="customer_details">
		<app-customer-quotes [customerdata]="customer_details" (quote_loaded)="loadQuote($event)" (quotesUpdated)="updateCustomer($event)"></app-customer-quotes>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #editCustomer let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Edit Customer</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body" *ngIf="customer_details">
		<app-customer-edit [customerdata]="customer_details" (newcustomerdata)="loadCustomerBranch();modal.dismiss('Cross click')"></app-customer-edit>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content" #editRow>
	<div class="container-fluid tp-box mwidth" [@flipState]="flip">
		<div class="tp-box__side tp-box__back" [ngClass]="flip == 'inactive' ? 'd-none' : ''">
			<div class="card tp-box m-0 p-0" *ngIf="customer_details && flip == 'active'">
				<div class="card-header card-header-{{config.color}}-5">ORDER HISTORY</div>
				<div class="card-body m-0 p-0">
					<button mat-raised-button class="ml-4 mt-2 mb-2" color="accent" matRipple (click)="flip = 'inactive';$event.preventDefault();">BACK TO ORDER</button>
					<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent" [selectedIndex]="activeIndex" (selectedIndexChange)="updateActiveIndex($event)">
						<mat-tab label="ORDER HISTORY">
							<ng-template matTabContent>
								<mat-tab-group #tabPanel mat-align-tabs="start" class="no-overflow" color="accent">
									<mat-tab label="Customer Transactions" *ngIf="customer_details">
										<ng-template matTabContent>
											<app-customer-transactions [customerdata]="customer_details" [pos]="true">
											</app-customer-transactions>
										</ng-template>
									</mat-tab>
									<mat-tab label="Customer History" *ngIf="customer_details">
										<ng-template matTabContent>
											<app-customer-item-history [customerdata]="customer_details" [pos]="true" [menuapp]="false">
											</app-customer-item-history>
										</ng-template>
									</mat-tab>
								</mat-tab-group>
							</ng-template>
						</mat-tab>
					</mat-tab-group>
				</div>
			</div>
		</div>
		<div id="frontside" class="row tp-box__side tp-box__front" [ngClass]="flip == 'active' ? 'd-none' : ''">
			<div class="card tp-box m-0 p-0">
				<div class="card-header card-header-{{config.color}}-5"> COUNTER POS <span *ngIf="loggedemployee">{{ loggedemployee }}</span></div>
				<div class="card-body m-0 p-0">
					<div class="row m-0 p-0">
						<mat-stepper #stepper [animationDuration]="duration.value" [selectedIndex]="currentstep" (selectionChange)="checkStep($event)" class="w-100 m-0 p-0" [@.disabled]="true">
							<mat-step label="ENTER CREDENTIALS">
								<ng-template matStepContent>
									<div class="row m-0 p-0" [@shakeit]="this.states['state1']" (@shakeit.done)="shakeEnd('state1', $event)">
										<div class="col-12 text-center">
											<mat-form-field appearance="outline" color="accent" class="large-input  mb-0 pb-0">
												<mat-label>EMPLOYEE CODE</mat-label>
												<input matInput #empCode autocomplete="off" [formControl]="empcode" class="text-center mt-0 mb-0 pt-0 pb-0 " value="" placeholder="EMPLOYEE CODE" (keydown.enter)="$event.preventDefault();checkCode();" autofocus>

											</mat-form-field>
											<button mat-raised-button color="accent">
												<mat-icon>vpn_key</mat-icon>
											</button>
											<ng-container *ngIf="emperror">
												<h3 class="text-danger mt-2 pt-0">EMPLOYEE CODE NOT FOUND</h3>
											</ng-container>
										</div>
									</div>
								</ng-template>
							</mat-step>
							<mat-step label="SELECT CUSTOMER">
								<!-- shared / input -->
								<ng-template matStepContent>
									<app-customer-lookup-pos (customer_selected)="selectCustomer($event)" class="w-100"></app-customer-lookup-pos>
								</ng-template>
							</mat-step>
							<mat-step label="ADD ITEMS" class="mr-0">
								<ng-template matStepContent>
									<div class="row m-0 p-0 ">

										<div class="col-md-8 m-0 p-0 ">
											<ng-container *ngIf="!cart_item">
												<div class="card  mat-elevation-z3 flex-row" @cinfo>
													<div class="card-body">
														<div class="card-title row m-0 p-0">
															<div class="col-md-9 m-0 p-0">
																<h4 class="card-title h5 h4-sm col-8 ml-0 mr-auto text-left">SCAN ITEMS BELOW</h4>
															</div>
														</div>
														<div class="row m-0 p-0">
															<div class="checkbox-wrapper-44 max-50 ml-auto mr-0" *ngIf="customer_details && config.env.package == 'beauty'">

																<label class="toggleButton">
																	<small>{{ isActiveText() }}</small>
																	<input type="checkbox" [checked]="customer_details.customer.isactive" (click)="updateActive($event)">
																	<div>
																		<svg viewBox="0 0 44 44">
																			<path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)" stroke-width="5"></path>
																		</svg>
																	</div>
																</label>
															</div>
														</div>
													</div>
												</div>
											</ng-container>
											<ng-container *ngIf="cart_item">
												<!--border-{{color}} -->
												<div class="card mat-elevation-z3 flex-row border ">
													<img class="d-none d-md-inline card-img-left img img-fluid mt-auto mb-auto" src="{{cart_item.thumb}}" (click)="getItemDetails(cart_item.stockid)" />
													<div class="card-body">
														<div class="card-title row m-0 p-0">
															<div class="col-md-10 m-0 p-0">
																<h2 class="card-title h5 h4-sm col-12 col-md-8 ml-0 mr-auto text-left"><strong>{{ cart_items.length - getCartItemIndex(cart_item) }}. {{cart_item.description}}</strong></h2>
															</div>
															<div class="col-md-2 ml-auto text-right">
																<!-- <button mat-raised-button color="primary" (click)="updateTax(cart_item)">T</button> -->
																<button mat-raised-button color="accent" (click)="removeFromOrder(cart_item.cart_id)">DELETE ITEM</button>
															</div>
														</div>
														<div class="row m-0 p-0">
															<div class="col-md-3">
																<mat-form-field appearance="outline" class="smaller-font-form ">
																	<mat-label>PRICE $</mat-label>
																	<input matInput (input)="updatePrice($event,cart_item)" autocomplete="off" class="text-center mt-0 mb-0 pt-0 pb-0 item-input" value="{{ cart_item.price }}" placeholder="PRICE" (keydown.enter)="$event.preventDefault();updatePrice($event,cart_item)" (keyUpCheck)="updatePrice($event,cart_item)">
																</mat-form-field>
															</div>
															<div class="col-md-3">
																<div class="row m-0 p-0">
																	<div class="col-2 ml-auto mr-auto p-0">
																		<button tabindex="-1" mat-stroked-button class="mt-3 btn btn-just-icon btn-accent btn-fab btn-round btn-sm mr-md-2" (click)="decrement(cart_item)">
																			<small><i class="material-icons">remove</i></small>
																		</button>
																	</div>
																	<div class="col-7 m-0 ">
																		<mat-form-field appearance="outline" class="smaller-font-form ">
																			<mat-label>QTY #</mat-label>
																			<input matInput #itemQty id="itemQty" (input)="updateQuantity($event,cart_item)" autocomplete="off" class="text-center item-input mt-0 mb-0 pt-0 pb-0 " value="{{ cart_item.quantity }}" placeholder="Qty" (keydown.enter)="$event.preventDefault();updateQuantity($event,cart_item)" (keyUpCheck)="updateQuantity($event,cart_item)" (keydown.tab)="focusSearch()"  appNumericField>
																		</mat-form-field>
																	</div>
																	<div class="col-2 ml-auto mr-auto p-0">
																		<button tabindex="-1" mat-stroked-button class="mt-3 btn btn-just-icon btn-accent btn-fab btn-round btn-sm mr-md-2" (click)="increment(cart_item)">
																			<small>
																				<i class="material-icons">add</i></small>
																		</button>
																	</div>
																</div>
															</div>
															<div class="col-md-3">
																<mat-form-field appearance="outline" class="smaller-font-form ">
																	<mat-label>DISCOUNT %</mat-label>
																	<input matInput (input)="updateDiscount($event,cart_item)" autocomplete="off" class="text-center mt-0 mb-0 pt-0 pb-0 item-input" value="{{ cart_item.discount }}" placeholder="DISCOUNT" (keydown.enter)="$event.preventDefault();updateDiscount($event,cart_item)" (keyUpCheck)="updateDiscount($event,cart_item)" appSelectOnFocus>
																</mat-form-field>
															</div>
															<div class="col-md-3">
																<h4 class="mt-4 mb-0 text-center">
																	<div class="item-totals mt-4">{{ cart_item.quantity }} @ {{ getDiscountPrice(cart_item) | currency }}</div>
																	<small *ngIf="config.discounts && cart_item.discount > 0" class="text-right text-danger bolder">
																		Discount: {{ cart_item.discount }}%
																		<!-- {{ getDiscount(cart_item) | currency }} -->
																	</small>
																	<hr class="m0">
																</h4>

															</div>
															<div class="col-md-6">
																<input matInput type="text" class="item-input-o" (input)="updateNote($event,cart_item)" value="{{ cart_item.notes }}" placeholder="Item Note..">
															</div>
															<!-- <div class="col-md-3 text-right ml-auto">
																<ng-container *ngFor="let t of cart_item.taxdetails">
																	<mat-form-field appearance="outline" class="smaller-font-form">
																		<mat-label>{{ t.description }} {{ getTaxDisplay(t.taxrate) }} %</mat-label>
																		<input matInput (input)="updateTaxRate($event.target.value ,t)" autocomplete="off" class="text-center mt-0 mb-0 pt-0 pb-0 item-input" value="{{ getTaxDisplay(t.taxrate) }}" placeholder="{{ t.description }} {{ getTaxDisplay(t.taxrate) }}" (keydown.enter)="$event.preventDefault();updateTaxRate($event.target.value,t)" (keyUpCheck)="updateTaxRate($event.target.value,t)" appSelectOnFocus>
																	</mat-form-field>
																</ng-container>
															</div> -->
															<div class="col-md-6">
																<h4 class="card-title h5 h4-sm ml-auto mr-0 text-right mt-0 mb-0 pb-0 pt-0 mt-0" *ngIf="cart_item.taxtotal">
																	<b>TAX: {{ cart_item.taxtotal | currency }}</b>
																</h4>
																<h4 class="card-title h5 h4-sm ml-auto mr-0 text-right mt-0 ">
																	<b>TOTAL: {{ getDiscountTotal(cart_item) | currency }}</b>
																</h4>
															</div>
														</div>
													</div>
												</div>
											</ng-container>
											<div class="table mat-elevation-z5">
												<div class="row">
													<div class="col-12">
														<h4 class="text-right" *ngIf="cart_items.length">&nbsp;&nbsp;<b>Lines: {{ cart_items.length }}  Qty: {{ 	getTotalItems() }} </b>&nbsp;&nbsp;</h4>
													</div>
												</div>
												<table class="table compact table-hover table-striped table-sm table-fixed ">
													<thead>
														<tr>
															<th class="table-header text-center"></th>
															<th class="table-header">Item</th>
															<th class="table-header text-right">Price</th>
															<th class="table-header text-right discount-t-width" *ngIf="globalDiscount">
																<mat-icon *ngIf="globalon" class="text-center header-icon" (click)="globalon = false;">locked</mat-icon>
																<mat-icon *ngIf="!globalon" class="text-center header-icon " (click)="globalon = true;">lock_open</mat-icon>
																<input matInput type="text" [formControl]="globalDiscount" class="header-input header-input-discount text-center ">
																% &nbsp; Discount
															</th>
															<th class="table-header text-center">Qty</th>
															<th class="table-header text-right">Total</th>
															<th class="table-header text-center">Tax</th>
														</tr>
													</thead>
													<tbody>
														<ng-container *ngIf="cart_items.length">
															<tr *ngFor="let item of cart_items;let i = index;" (click)="cart_item = false;cart_item = item; focusToTop()" class="pointer" [ngClass]="item == cart_item ? 'selected-item-'+color : ''">
																<td class="text-center" matRipple>{{ cart_items.length - i }}.</td>
																<td matRipple>{{ item.description }}</td>
																<td class="text-right" matRipple>{{ item.price  | currency }}</td>
																<td class="text-right " matRipple>{{ item.discount }}%</td>
																<td class="text-center" matRipple>{{ item.quantity }}</td>
																<td class="text-right" matRipple>{{ getDiscountTotal(item) | currency }}</td>
																<td class="text-center">
																	<span *ngIf="item.taxtotal != 0">T</span>
																	<span *ngIf="item.taxtotal == 0"></span>
																</td>
															</tr>
														</ng-container>
														<ng-container *ngIf="!cart_items.length">
															<tr>
																<td colspan="7">
																	<h3 class="text-center">NO ITEMS</h3>
																</td>
															</tr>
														</ng-container>
													</tbody>
												</table>
											</div>
										</div>
										<div class="col-md-4">
											<div class="card mat-elevation-z5 customer-info-{{color}} border-{{color}} m-0 p-0 mb-1">
												<h4 class="mt-2 card-title text-center mb-0">
													{{ customer_data.name }}
													<hr class="m0 mt-0 pt-0 mb-0 pb-0">
													<small class="text-muted bold">CUSTOMER# {{ customer_data.debtorno }}.{{ customer_data.branchcode }}</small>
												</h4>
												<div class="card-body m-0 p-0 text-center">
													<ng-container *ngIf="customer_data.notes && customer_data.notes.length">
														<p class="pl-2 pr-2 pb-0 mb-0">
															<mat-chip-list class="ml-auto mr-auto text-center" aria-label="Customer Notes" [color]="'accent'">
																<ng-container *ngFor="let note of customer_data.notes">
																	<mat-chip class="ml-auto mr-auto text-center note-color" [color]="'accent'">
																		{{ note.note }}
																	</mat-chip>
																</ng-container>
															</mat-chip-list>
														</p>
													</ng-container>
													<p [innerHTML]="customer_data.billto | uppercase" class="pt-0 pb-0 mt-0 mb-0"></p>

													<div class="row m-0 p-0 text-center">
														<!-- <div class="col-md-6 text-center">

														</div> -->
														<div class="col-md-12 text-center">
															<button mat-flat-button class="mb-2 mt-1" (click)="(!expand_details) ? expand_details = true: expand_details = false;">
																C360 <mat-icon *ngIf="!expand_details">
																	expand_more
																</mat-icon>
																<mat-icon *ngIf="expand_details">
																	expand_less
																</mat-icon>
															</button>
														</div>
													</div>
													<ul class="entry-list p-0 m-0 flat-black">
														<li *ngIf="customer_details">
															<div class="spec-label bg-white">
																Signed Contract:
															</div>
															<div class="spec-value bg-white text-left" matTooltip="Contract: {{ customer_details.customer.signed_mor }}" [ngClass]="customer_details.customer.signed_mor == 'No' ? ' bg-danger flashing flash bg-danger text-danger' : '' ">
																<span >{{ customer_details.customer.signed_mor }}</span>
															</div>
														</li>
														<li *ngIf="customer_details">
															<div class="spec-label bg-white">
																REFUSED EMAIL:
															</div>
															<div class="spec-value bg-white ">
																<div class="refused-checkmark-words mt-2">
																	<input class="tgl tgl-flip" id="rfb" type="checkbox" [checked]="refusedemail" (click)="updateRefused($event)"  />
																	<label class="tgl-btn" data-tg-off="NO" data-tg-on="YES" for="rfb"></label>
																</div>
															</div>
														</li>
														<li *ngIf="customer_details">
															<div class="spec-label bg-white">
																Tax Id:
															</div>
															<div class="spec-value bg-white text-left" matTooltip="Tax Id: {{ customer_details.customer.tax_ref }}" [ngClass]="customer_details.customer.tax_ref == '' ? ' bg-danger flashing flash bg-danger text-danger' : '' ">
																<span *ngIf="customer_details.customer.tax_ref !=''">{{ customer_details.customer.tax_ref }}
																	<mat-slide-toggle [formControl]="forceTax">Tax Sale</mat-slide-toggle>
																</span>
																<span *ngIf="customer_details.customer.tax_ref ==''"> NO TAX EXEMPTIONS</span>
															</div>
														</li>
														<li *ngIf="customer_details">
															<div class="spec-label bg-white">
																License:
															</div>
															<div class="spec-value bg-white text-left" matTooltip="License: {{ customer_details.customer.cosm_license }}" [ngClass]="customer_details.customer.cosm_license == '' ? ' bg-danger flashing flash bg-danger text-danger' : '' ">
																<span *ngIf="customer_details.customer.cosm_license !=''">{{ customer_details.customer.cosm_license }}</span>
																<span *ngIf="customer_details.customer.cosm_license ==''">N/A</span>
															</div>
														</li>
														<li *ngIf="customer_details">
															<div class="spec-label bg-white">
																Salesman:
															</div>
															<div class="spec-value bg-white text-left" matTooltip="SALESMAN: {{ salemanName(customer_details.customer.salesman) }}">
																{{ salemanName(customer_details.customer.salesman) }}
															</div>
														</li>
														<li *ngIf="customer_details">
															<div class="spec-label bg-white">
																Email:
															</div>
															<div class="spec-value bg-white text-left" matTooltip="EMAIL: {{ customer_details.customer.invoiceemailaddress }}" [ngClass]="customer_details.customer.invoiceemailaddress == '' && !refusedemail ? ' bg-danger flashing flash bg-danger text-danger' : '' ">
																<span *ngIf="customer_details.customer.invoiceemailaddress == '' && !refusedemail">
																	N/A.
																</span>
																<span *ngIf="customer_details.customer.invoiceemailaddress !='' && !refusedemail">
																	{{ customer_details.customer.invoiceemailaddress }}
																</span>
																<span *ngIf="refusedemail">
																	REFUSED
																</span>

															</div>
														</li>
														<li *ngIf="customer_details">
															<div class="spec-label bg-white">
																Phone#:
															</div>
															<div class="spec-value bg-white text-left" matTooltip="PHONE: {{ customer_details.customer.phoneno | phoneFormat }}" [ngClass]="customer_details.customer.phoneno == '' ? ' bg-danger flashing flash bg-danger text-danger' : '' ">
																{{ customer_details.customer.phoneno | phoneFormat}}
															</div>
														</li>

														<li @cinfo *ngIf="customer_details && expand_details">
															<div class="spec-label bg-white">
																Discount:
															</div>
															<div class="spec-value bg-white text-left">
																{{ customer_details.customer.discountdsp }}
															</div>
														</li>


														<li @cinfo *ngIf="customer_details && expand_details">
															<div class="spec-label bg-white">Last Purchase</div>
															<div class="spec-value bg-white text-left">{{ customer_details.customer.last_purchase }}</div>
														</li>

														<li @cinfo *ngIf="customer_details && expand_details">
															<div class="spec-label bg-white">Credit Limit</div>
															<div class="spec-value bg-white text-left">{{ customer_data.creditlimit | currency  }}</div>
														</li>
														<li @cinfo *ngIf="customer_details && expand_details">
															<div class="spec-label bg-white">Price Level</div>
															<div class="spec-value bg-white text-left">{{ customer_data.salestype }}</div>
														</li>
														<li @cinfo *ngIf="customer_details && expand_details">
															<div class="spec-label bg-white">Customer Since</div>
															<div class="spec-value bg-white text-left">{{ customer_data.clientsince | dateAgo }}</div>
														</li>
														<li @cinfo *ngIf="customer_details && expand_details">
															<div class="spec-label bg-white">
																Total Spend:
															</div>
															<div class="spec-value bg-white text-left">
																{{ customer_details.customer.totalspend | currency }}
															</div>
														</li>
														<li @cinfo *ngIf="customer_details && expand_details">
															<div class="spec-label bg-white">
																Last Paid:
															</div>
															<div class="spec-value bg-white text-left" *ngIf="customer_details.customer.lastpaid">
																{{ customer_details.customer.lastpaid | currency }} | {{ customer_details.customer.lastpaiddate | dateAgo }}
															</div>

															<div class="spec-value bg-white" *ngIf="!customer_details.customer.lastpaid">
																N/A
															</div>
														</li>
														<li @cinfo *ngIf="customer_details && expand_details">
															<div class="spec-label bg-white">
																Avg Order:
															</div>
															<div class="spec-value bg-white text-left">
																<span *ngIf="customer_details.customer.avgspend">
																	{{ customer_details.customer.avgspend | currency }}
																</span>
																<span *ngIf="!customer_details.customer.avgspend">
																	N/A
																</span>
															</div>
														</li>
														<li *ngIf="customer_details && customer_details.customer.topthree" class="bg-white">
															<div class="text-center ml-auto mr-auto ">
																Most Purchased Brands: <br />

																<span *ngIf="customer_details.customer.topthree[0]" (click)="getBrandSales(customer_details.customer.debtorno, customer_details.customer.topthree[0].manufacturer_id)" matRipple>{{customer_details.customer.topthree[0].name}} ({{customer_details.customer.topthree[0].brandcount}})&nbsp;</span>

																<span *ngIf="customer_details.customer.topthree[1]" (click)="getBrandSales(customer_details.customer.debtorno, customer_details.customer.topthree[1].manufacturer_id)" matRipple>{{customer_details.customer.topthree[1].name}} ({{customer_details.customer.topthree[1].brandcount}})&nbsp;</span>

																<span *ngIf="customer_details.customer.topthree[2]" (click)="getBrandSales(customer_details.customer.debtorno, customer_details.customer.topthree[2].manufacturer_id)" matRipple>{{customer_details.customer.topthree[2].name}} ({{customer_details.customer.topthree[2].brandcount}})&nbsp;</span>

															</div>
														</li>
													</ul>
												</div>
												<div class="card-footer">


													<button mat-button (click)="editCustomerModal()">EDIT CUSTOMER</button>
													<button mat-button (click)="clearOrder()" *ngIf="cart_items.length">CLEAR CART </button>
												</div>
											</div>
											<mat-list>
												<div mat-subheader></div>
												<mat-divider></mat-divider>
												<mat-list-item matRipple (click)="loadOrders()">
													<mat-icon matListItemIcon>history</mat-icon>
													<div matListItemTitle>&nbsp;History</div>
												</mat-list-item>
												<mat-divider></mat-divider>
												<mat-list-item (click)="saveQuote()" matRipple *ngIf="cart_items.length">
													<mat-icon matListItemIcon>save</mat-icon>
													<div matListItemTitle>&nbsp;Save Quote</div>
												</mat-list-item>
												<mat-divider></mat-divider>
												<mat-list-item (click)="loadQuoteModal()" matRipple *ngIf="customer_details && customer_details.opens">
													<mat-icon matListItemIcon>rotate_right</mat-icon>

													<div matListItemTitle>&nbsp;Load Quote ({{ customer_details.opens.quotes }})</div>
												</mat-list-item>
												<mat-divider></mat-divider>

												<mat-list-item (click)="loadOrderModal()" matRipple *ngIf="customer_details && customer_details.opens">
													<mat-icon matListItemIcon>autorenew</mat-icon>
													<div matListItemTitle>&nbsp;Load Order ({{ customer_details.opens.orders }})</div>
												</mat-list-item>



											</mat-list>
										</div>
									</div>
								</ng-template>
							</mat-step>
							<mat-step label="PAYMENT">
								<ng-template matStepLabel>PAYMENT</ng-template>
								<ng-container *ngIf="!orderResults">
									<ng-container *ngIf="customer_data">
										<div class="row m-0 p-0">
											<div [ngClass]="!preOrderOpenState ? 'col-md-2' : 'col-md-3'">

												<mat-list>
													<div mat-subheader></div>
													<mat-divider></mat-divider>
													<mat-list-item matRipple (click)="loadOrders()">
														<mat-icon matListItemIcon>history</mat-icon>
														<div matListItemTitle>&nbsp;HISTORY</div>
													</mat-list-item>
													<mat-divider></mat-divider>
													 <!--<mat-list-item (click)="togglePreOrder()" matRipple *ngIf="customer_details && customer_details.opens">
														<mat-icon matListItemIcon>rotate_right</mat-icon>
														<div matListItemTitle *ngIf="!preOrderOpenState">&nbsp;SAVE ORDER</div>
														<div matListItemTitle *ngIf="preOrderOpenState">&nbsp;INVOICE & PAY</div>
													</mat-list-item>-->

													<mat-divider></mat-divider>
													<mat-list-item (click)="saveQuote()" matRipple *ngIf="cart_items.length">
														<mat-icon matListItemIcon>save</mat-icon>
														<div matListItemTitle>&nbsp;SAVE QUOTE</div>
													</mat-list-item>
													<mat-divider></mat-divider>
												</mat-list>

												<ng-container *ngIf="preOrderOpenState">
													<ng-container *ngIf="customerForm">
														<form @grow [formGroup]="customerForm" (ngSubmit)="nothing()" class="text-dark border-1">
															<div class="row w-100" @grow>
																<div class="col-md-12">
																	<mat-form-field class="full-width" appearance="standard">
																		<mat-label>Customer PO#</mat-label>
																		<input formControlName="reference" matInput placeholder="PO number">
																	</mat-form-field>
																	<mat-form-field appearance="standard">
																		<mat-label>Ship Via</mat-label>
																		<mat-select formControlName="defaultshipvia">
																			<mat-option *ngFor="let ship of shipvia" [value]="ship.shipper_id">
																				{{ ship.shippername }}
																			</mat-option>
																		</mat-select>
																	</mat-form-field>
																	<mat-form-field appearance="standard">
																		<mat-label>Date Wanted</mat-label>
																		<input matInput formControlName="datewanted" [matDatepicker]="orderdatepicker">
																		<mat-datepicker-toggle matSuffix [for]="orderdatepicker"></mat-datepicker-toggle>
																		<mat-datepicker #orderdatepicker></mat-datepicker>
																	</mat-form-field>
																	<mat-form-field appearance="standard">
																		<mat-label>Name</mat-label>
																		<input matInput value="" formControlName="brname">
																	</mat-form-field>
																	<mat-form-field class="full-width" appearance="standard">
																		<mat-label>Phone</mat-label>
																		<input matInput formControlName="phoneno" matInput placeholder="phone#">
																	</mat-form-field>
																	<mat-form-field class="full-width" appearance="standard">
																		<mat-label>Email</mat-label>
																		<input matInput formControlName="email" matInput placeholder="email">
																	</mat-form-field>

																</div>
															</div>
														</form>
													</ng-container>
												</ng-container>

												<div *ngIf="dealsmissed.length != 0">Deals missed</div>
												<div *ngIf="dealsmissed.length == 0">NO DEALS</div>
												<ng-container *ngIf="dealsmissed.length != 0">
													<div *ngFor="let deal of dealsmissed;" class="row dealrow" (click)="applyDeal(deal.promoid)">
														<div class="col-sm-4"> {{ deal.stockid }} </div>
														<div class="col-sm-4"> {{ deal.name }} </div>
														<div class="col-sm-4"> {{ deal.description }} </div>
													</div>
												</ng-container>

												<input matInput type="text" class="text-right" placeholder="Coupon" [value]="coupon" (input)="addCoupon($event,cart_items)">
												<div *ngIf="coupons.length != 0">
													<p class=""><i class="fa fa-smile-o"> </i>Coupons Applied</p>
													<div *ngFor="let deal of coupons;" class="row dealrow" (click)="applyDeal(deal.promoid)">
														<div class="col-sm-4"> {{ deal.stockid }} </div>
														<div class="col-sm-4"> {{ deal.name }} </div>
														<div class="col-sm-4"> {{ deal.description }} </div>
													</div>
												</div>
											</div>
											<div [ngClass]="!preOrderOpenState ? 'col-md-10' : 'col-md-9'">
												<app-pos-order-payments [totals]="cart_totals" [pos]="true" [order_header]="customer_data" [items]="cart_items" [debtorno]="customer_data.debtorno" [branchcode]="customer_data.branchcode" [orderno]="1" [payments]="payments" (payments_added)="updatePayments($event)" (payments_removed)="updatePayments($event)" [document_type]="'30'"></app-pos-order-payments>
											</div>
										</div>
									</ng-container>
								</ng-container>
							</mat-step>
							<!-- <mat-step label="RECEIPT">
								<ng-template matStepLabel>RECEIPT</ng-template>

								<ng-container *ngIf="orderResults">
									<div class="row m-0 p-0s">
										<div class="col-md-4">
											<button mat-flat-button color="primary" class="bg-primary m-0 p-0 w-100 h-100 fullsize" (click)="$event.preventDefault();printReceipt()">RE-PRINT</button>
										</div>
										<div class="col-4 text-center">
											<h3>RECEIPT</h3>
											<div [innerHTML]="receipt_data.display" class="ml-auto mr-auto rec"></div>
										</div>
										<div class="col-md-4">
											<button mat-flat-button color="success" class="bg-success m-0 p-0 w-100 h-100 fullsize" (click)="$event.preventDefault();emailReceipt()">EMAIL</button>

											<mat-form-field class="full-width" appearance="outline">
												<mat-label>Email</mat-label>
												<input matInput [formControl]="email" matInput placeholder="Invoice Note">
											</mat-form-field>

										</div>
									</div>
								</ng-container>
							</mat-step> -->
						</mat-stepper>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<nav class="bg-light pt-0 pb-0 mat-elevation-z8 navbar fixed-bottom navbar-expand navbar-toggleable-xl navbar-light " *ngIf="customer_data && customer_data.debtorno">
	<a class="navbar-brand d-none d-md-inline" href="#">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
		<ul class=" navbar-nav ml-auto  mr-0 mt-2 mt-lg-0 w-100">
			<li class="nav-item form-inline my-2 my-lg-0 form-inline col-md-5 w-100 text-right active" *ngIf="currentstep == 2">
				<app-pos-item-search [customer]="customer_data" (addItem)="addItems($event)" (addMix)="addPromo($event)" (updateCart)="updateCart()" (hasresults)="itemSearching($event)" class="w-100 mt-3"></app-pos-item-search>
			</li>
			<li class="d-none d-md-inline col-md-2 ml-auto mr-auto nav-item form-inline my-2 my-lg-0 form-inline text-right w-100" *ngIf="currentstep >= 2 && currentstep < 3">
				<app-nonstock (nonstockadded)="addNonStockItem($event)"></app-nonstock>
			</li>
			<li class="d-none d-md-inline col-md-5 nav-item my-2 my-lg-0 form-inline text-right" *ngIf="cart_totals.length && currentstep < 3 ">
				<div class="col-12 dotted" *ngFor="let t of cart_totals">
					<b>{{ t.title }}:</b> {{ t.text | currency}}
				</div>
			</li>
		</ul>
		<form class="form-inline my-2 my-lg-0 m-0 p-0">
			<button mat-flat-button color="primary" class="m-0 p-0 w-100 h-100 fullsize customer-info-{{color}}" (click)="$event.preventDefault();currentstep = 3;" [disabled]="!cart_totals.length" *ngIf="currentstep < 3">
				PAY
			</button>
			<button mat-flat-button color="warn" class="m-0 p-0 w-100 h-100 fullsize" (click)="$event.preventDefault();placeOpenOrder();" [disabled]="!cart_totals.length" *ngIf="currentstep == 3 && preOrderOpenState">
				SAVE
			</button>
			<button mat-flat-button color="accent" class="bg-success m-0 p-0 w-100 h-100 fullsize" (click)="$event.preventDefault();completeOrder();" *ngIf="currentstep == 3 && !preOrderOpenState" [disabled]="getBalance() > 0">COMPLETE</button>

			<button mat-flat-button color="accent" class="bg-danger m-0 p-0 w-100 h-100 fullsize" (click)="$event.preventDefault();orderResults= false;clearOrder();" *ngIf="currentstep == 4">NEW ORDER</button>
		</form>

</nav>
