import { Component, OnDestroy, SimpleChanges, OnChanges, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, Pipe, PipeTransform, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


import { trigger, state, style, transition, animate, animation } from '@angular/animations';
import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { UsersService } from '../../services/users.service';
import { PurchasingService } from '../../services/purchasing.service';

import { OmsService } from '../../services/oms.service';

@Component({
	selector: 'app-shared-purchase-order-entry',
	templateUrl: './shared-purchase-order-entry.component.html',
	styleUrls: ['./shared-purchase-order-entry.component.scss'],
	//encapsulation: ViewEncapsulation.Emulated,
	//encapsulation: ViewEncapsulation.None,
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotate3d(0, 1, 0, 179deg)',
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('300ms ease-in')),
			transition('active => inactive', animate('300ms ease-out')),
		]),
		trigger('cinfo', [
			transition(':enter', [
				animate('50ms', style({ opacity: 1, height: 100 })),
			]),
			transition(':leave', [
				animate('100ms', style({ opacity: 1, height: 0 }))
			]),
		]),
		trigger('itemSearch', [
			transition(':enter', animation([style({ transform: 'translate(-800px,0)', }),
				animate('0.2s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(1200px,0)', })
				),
			])),
		]),
		trigger('addFilters', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
		trigger('navIn', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.10s cubic-bezier(0.59, 0.32, 0.38, 0.33)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.0s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
	],
})
export class SharedPurchaseOrderEntryComponent implements OnInit {
	@Input() vendor: any = false;
	@Input() flip: string = 'inactive';
	@Input() type: string = '21';
	@Input() invoicing: any = false;
	@Output() reloadVendor = new EventEmitter < any > ();
	@ViewChild("details") detailsRef: ElementRef;
	CHAT_ROOM = "OrderBoard";
	document_type = 'PO';
	config: any = [];
	user: any = [];
	cart_items: any = [];
	proceed: any = true;
	total_cart_qty: number = 0;
	cart_totals: any = [];

	overcredit: any = false;
	allowed_credit: any = true;
	overcredit_override: any = true;
	editing_order: any = false;
	order_details: any = false;

	//orderResults: any = false;
	vendor_form: any = false;

	po_vendors: any = [];
	order: any = false;
	created_pos: any = false;
	extra_pos: any = false;
	order_lines: any = false;
	loading_remote: boolean = false;
	orderno: any = false;
	remoteqtys: any = [];
	sending: boolean = false;
	purchase_item: any = false;
	purchordercreated_details: any = false;
	purchasedetails: any = false;
	purchordercreated: any = false;

	baselink: string = '';
	pickingInvoiceLink: string = '';

	orderDetails: any = false;
	orderResults: any = false;
	invoiceResults: any = false;

	anydata = {
		"success": true,
		"orderno": "754076",
		"transno": null,
		"order": "754076",
		"id": null
	};

	freight_charge: any = 0.00;

	fetchingTotals: any = false;
	fetchingCart: any = false;
	purchase_items: any = [];
	recdoc: string = '';
	confirmedEmail: boolean = false;
	poEmailType = new UntypedFormControl('');
	emailRadioOptions: any = [{view: 'PDF', value: 'pdf'}];
	emailType = this.emailRadioOptions[0].value;
	poEmailAddress = new UntypedFormControl('');

	constructor(private purchasingService: PurchasingService, private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, public omsService: OmsService, private fb: UntypedFormBuilder, private modalService: NgbModal, public usersService: UsersService) {
		//private globalsService: GlobalsService,
		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
			if(this.config.env.package == 'beauty'){
				this.emailRadioOptions = [{view: 'Excel', value: 'xlsx'}, {view: 'PDF', value: 'pdf'}];
				this.emailType = this.emailRadioOptions[0].value
			}
		});

		this.baselink = this.config.apiServer.baseUrl + this.config.apiServer.poLink;
		this.recdoc = this.config.apiServer.baseUrl + this.config.apiServer.recLink;
		this.pickingInvoiceLink = this.config.apiServer.baseUrl + this.config.apiServer.pickingInvoice;
		this.baselink = this.config.apiServer.poLink;

		//this.omsService.subscribeToCarts((err, data) => {
		//	if (data.data[0].customer == this.vendor.supplierid) {
		//		this.orderService.getCart(this.vendor.supplierid, this.vendor.branchcode).subscribe(async (items: any) => {
		//			this.cart_items = items;
		//		});
		//	}
		//})

		this.globalSearchService.user.subscribe((results: any) => {
			if (results) {
				this.user = results.user;
			}
		});


	}
	showInvoiceResultandReset(event: any) {
		//this.invoiceResults = event;
		this.globalSearchService.showNotification('Invoice #' + event.transno + ' Created', 'success', 'bottom', 'left');
		this.resetOrder();
	}

	resetOrder() {
		this.invoicing = false;
		this.orderResults = false;
		this.order_details = false;
		this.editing_order = false;
		this.clearCart();
		this.updateTotals();
	}

	updateShipping(value: any) {
		this.freight_charge = value
		this.updateTotals();
	}

	updateCustomer(event: any) {
		this.vendor_form = event
	}

	invoiceOrder(orderno: any) {
		this.invoicing = orderno;
		this.orderResults = false;
	}

	addItems(items: any) {

		this.purchasingService.addMultipleToOrder(items, this.vendor.supplierid).subscribe((results: any) => {

			if (results.success) {
				this.cart_items = results.cart;
				//this.updateTotals();
				items.forEach((item, index) => {
					//reset qtys and show notification

					if (parseFloat(item.quantity) != 0) {
						let color = (item.isnegative) ? 'warn' : 'success';
						this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Added', color, 'bottom', 'left');
					}
				});

				this.updateTotals();

			} else {
				this.globalSearchService.showNotification(results.message, 'danger', 'bottom', 'left');
			}
		});
	}

	removeFromOrder(cart_id) {
		this.purchasingService.removeFromOrder(cart_id, this.vendor.supplierid).subscribe((results: any) => {
			this.cart_items = results;
			this.globalSearchService.showNotification('Item Removed', 'danger', 'bottom', 'left');
			this.updateTotals();
			this.sendCartUpdate();
		});
	}

	updatePrice(event: any, item: any) {
		let index = this.cart_items.indexOf(item);
		this.cart_items[index].price = event.target.value;
		this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
		this.updateCartItem(this.cart_items[index]);
	}

	updateQuantity(event: any, item: any) {
		let index = this.cart_items.indexOf(item);
		this.cart_items[index].quantity = event.target.value;
		this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
		this.updateCartItem(this.cart_items[index]);
	}

	updateDiscount(event: any, item: any) {
		let index = this.cart_items.indexOf(item);

		if (parseFloat(event.target.value) < 100) {} else {
			event.target.value = 100;
		}

		this.cart_items[index].discount = event.target.value;
		this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
		this.updateCartItem(this.cart_items[index]);

	}

	updateNote(event: any, item: any) {
		let index = this.cart_items.indexOf(item);
		this.cart_items[index].notes = event.target.value;
		this.updateCartItem(this.cart_items[index]);

	}

	getDiscounPrice(item: any) {
		var discountprice = this.financial((item.price * (item.discount / 100)));
		var total = this.financial(item.price - discountprice);
		if(total < 0) {
			total = 0;
		}
		return total;
	}

	financial(x) {
		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	getDiscountTotal(item: any) {
		var discounted = (item.price * (item.discount / 100)).toFixed(2);
		var discountprice = (item.price - parseFloat(discounted))
		var total = item.quantity *  discountprice;
		if(total < 0) {
			total = 0;
		}

		return total;
	}

	updateCartItem(item: any) {


		let data = {
			customer: this.vendor.supplierid,
			branch: this.vendor.branchcode,
			cartid: item.cart_id,
			cart_id: item.cart_id,
			price: item.price,
			option: item.options,
			quantity: item.quantity,
			discount: item.discount,
			notes: item.notes,
			stockid: item.stockid,
			editing_order: this.editing_order,
			order_details: this.order_details,
		};


		this.purchasingService.updateOrder(data).subscribe((results: any) => {
			this.updateTotals();
			//this.updateCart();
			//item.cart_id
			this.updateChildren();
		});
	}
	//if input is not actually the child? cartid: any not sure this is appropriate..
	updateChildren() {
		this.cart_items.forEach((item: any) => {
			if (item.parent_id) {
				let parent = this.cart_items.filter(i => i.cart_id == item.parent_id)[0]

				//will not work for pers
				item.quantity = parent.quantity;
			}
		})
	}

	updateTotals() {

		if (this.fetchingTotals) {
			this.fetchingTotals.unsubscribe();
		}

		this.fetchingTotals = this.purchasingService.getTotals(this.vendor.supplierid, this.freight_charge).subscribe((results: any) => {
			this.cart_totals = results;

			this.total_cart_qty = this.cart_items.reduce(function(accumulator: number, items: any) {
				var counter = 0;
				if (items.mbflag != 'F') {
					counter = parseFloat(items.quantity);
				}
				return accumulator + counter;
			}, 0);

			if (!this.total_cart_qty) {
				this.flip = 'inactive';
			}

			this.checkCreditLimit()
		});

	}

	getTotal() {

		let total = this.cart_totals.filter(t => {
			return t.code == 'total';
		})[0]

		var value = 0.00;
		if (total) {
			value = parseFloat(parseFloat(total.text).toFixed(2))
		}

		return value;

	}



	checkCreditLimit() {
		// this.updateNote();
	}


	stopProp(event: any): void {
		event.stopPropagation();
	}

	sendCartUpdate() {

		if (this.user) {
			let data = [{ customer: this.vendor.supplierid, user: this.user }]
			this.omsService.sendCartUpdate({ data, roomName: 'CartRoom' }, cb => {});
		}
	}

	toggleFlip() {

		switch (this.flip) {
			case 'inactive':
				this.flip = 'active';
				break;
			default:
				this.flip = 'inactive';
				break;
		}
	}

	ngOnInit(): void {

	}


	cartPromise() {
		return new Promise<any>((resolve, reject) => {
			this.updateCart();
			resolve;
		});
	}

	ngOnChanges(changes: any) {

		this.cartPromise().then(res => {

			if(changes.vendor) {
				if(!changes.vendor.firstChange) {
					if(changes.vendor.currentValue.supplierid != changes.vendor.previousValue.supplierid) {
						//this.updateTotals();
						this.orderResults = false;
						this.flip = 'active';
					}
				}
			}
		 })
	}


	emitCustomerUpdate(event: any) {
		this.invoicing = false;
		this.reloadVendor.emit(event);
	}

	saveQuote() {

	}

	clearCart() {
		this.purchasingService.clearOrder(this.vendor.supplierid).subscribe((results: any) => {
			this.flip = 'inactive';
			this.updateCart();
		});
	}

	cancelOrder() {

		let order = { orderno:this.order_details.header.orderno }

		this.purchasingService.cancelOrder(order).subscribe((results: any) => {
			if(results.success) {
				this.flip = 'inactive';
				this.resetOrder();
			}
		});
	}

	placeOrder() {

		//this.checkCredit();
		this.globalSearchService.getFormValidationErrors(this.vendor_form);
		this.vendor_form.markAllAsTouched();

		this.allowed_credit = true;


		/*
		  if (!this.proceed && !this.allowed_credit) {
			  this.modalService.open(this.creditover, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

			  }, (reason) => {
				  this.item_details = false;
			  });
		  }
		  */
		//customer form is emitted in

		// /
		var zeropriceconfirm = true;
		this.cart_items.forEach( (i:any) => {

			let price = this.financial(i.price);
			if(price == 0) {
				zeropriceconfirm = false;
			}
		})
		var proceed = true;
		if(!zeropriceconfirm) {
			proceed =confirm('Zero Priced Items found, Proceed?');
		}

		if (this.vendor_form.valid && this.proceed && proceed) {



			if (!this.editing_order) {
				//createPurchaseOrder(supplier: any, header: any, details: any , user: any)
				this.purchasingService.createPurchaseOrder(this.vendor,this.vendor_form.value, this.cart_items, this.user).subscribe((results: any) => {

					if(results.success) {
						this.globalSearchService.orderResults.next(results);
						this.orderResults = results;
						this.flip = 'inactive';
						this.orderDetails = results;
						this.reloadVendor.emit(results);
						this.editing_order = false;

						//let data = { message: 'neworder' }
						//this.omsService.sendCardUpdate({data, roomName: this.CHAT_ROOM}, cb => {
						//});
						//this.omsService.sendOpenOrderUpdate({data, roomName: this.CHAT_ROOM}, cb => {
						//});

						this.purchasingService.clearOrder(this.vendor.supplierid).subscribe((results: any) => {
							this.cart_items = [];
							this.total_cart_qty = 0;
							let data = {};
							this.omsService.sendOrderPickUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
							//this.router.navigate(['/orders/invoice/' + this.orderResults.orderno]);
						});
					} else {
						this.globalSearchService.showNotification(results.message,'danger','bottom','right');
					}


				});
			} else {

				var payload = {
					supplier: this.vendor,
					header: this.vendor_form.value,
					details: this.cart_items,
					user: this.user,
					order_details: this.order_details,
				}

				this.purchasingService.updatePurchaseOrder(payload).subscribe((results: any) => {
					this.flip = 'inactive';
					this.globalSearchService.orderResults.next(results);
					this.orderResults = results;
					let data = {};
					this.omsService.sendOrderPickUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
					//this.router.navigate(['/orders/invoice/' + this.orderResults.orderno]);
				});
			}
		}

		this.updateCart()
	}

	viewPurchOrderOptions(line: any) {
		line.allownegative = true;
		let data = { cart_id: line.cart_id, stockid: line.stockid }
		this.orderService.getPurchDataFromLine(data).subscribe((results: any) => {
			this.purchase_items.push(results);
			this.modalService.open(this.purchasedetails, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result: any) => {

			}, (reason) => {
				this.purchase_items = [];
			});
		});
	}

	updateCart() {

		if (this.fetchingCart) {
			this.fetchingCart.unsubscribe();
		}
		if (this.vendor.supplierid) {

			this.fetchingCart = this.purchasingService.getCart(this.vendor.supplierid).subscribe((items: any) => {
				var cart_items = items;
				if (items.length) {
					this.flip = 'active';
				}
				//current cart tied to a sales order - user loaded an order and left
				let hasorderno = items.map(item => { return (item.orderno != 0) ? item.orderno : false })[0];
				//todo rework this - doubles up on the calls
				if (hasorderno && !this.order_details.header) {
					this.purchasingService.loadPurchaseOrder(hasorderno).subscribe((results: any) => {

						this.editing_order = true;
						this.flip = 'active';
						this.vendor.editingorder = results.header
						this.order_details = results;
						this.cart_items = results.cart;
						//this.updateTotals();
					});
				} else {
					this.cart_items = items;
				}

				this.updateTotals();
			});
		}
	}

	editOrder(orderno: any) {
		this.orderResults = false;
		this.purchasingService.loadPurchaseOrder(orderno).subscribe((results: any) => {
			this.editing_order = true;
			this.order_details = results;

			this.fetchingCart = this.purchasingService.getCart(results.header.supplierno).subscribe(async (items: any) => {
				//this.selectedindex = 0;
				this.editing_order = true;
				this.order_details = results;
				this.cart_items = items;
				this.vendor.editingorder = results.header
				if (items.length) {

					if (!items[0].override || items[0].override == '') {
						//order was not flagged - do not stop it
						this.proceed = true;
						this.allowed_credit = true;
						this.overcredit_override = items[0].override
					}
				}

				this.updateTotals();
			});

			this.flip = 'active';

		})
	}

	openEmailPO(){
		if(this.vendor.email != ''){
			this.poEmailAddress.setValue(this.vendor.email);
		}
		this.modalService.open(this.detailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => { }, (reason) => {
			if(this.confirmedEmail){
				this.confirmedEmail = false;

				let data = {
					orderno: this.orderResults.orderno,
					vendor: this.vendor.supplierid,
					email: this.poEmailAddress.value,
					type: this.emailType
				}
				this.purchasingService.emailPO(data).subscribe((result)=>{


				});
			}
		});
	}

	confirmEmailPO(){
		this.confirmedEmail = true;
		this.modalService.dismissAll();
	}
}
