import { Component, ViewChild, ChangeDetectorRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { OrdersService } from '../../services/orders.service';
import { Location } from '@angular/common'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalSearchService } from '../../services/globalsearchservice.service';


@Component({
	selector: 'app-adrs',
	templateUrl: './adrs.component.html',
	styleUrls: ['./adrs.component.scss']
})
export class AdrsComponent implements OnInit {

	@ViewChild('sorter1') sorter1: MatSort;
	@ViewChild('sorter2') sorter2: MatSort;
	@ViewChild('sorter3') sorter3: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('page2') paginator2: MatPaginator;
	@ViewChild('page3') paginator3: MatPaginator;

	pending: any = [];
	sent: any = [];
	color: string = 'blue';
	headercolumns: string[] = [
		'selected',
		'orderno',
		'orderdate',
		'doctype',
		'debtorno',
		'branchcode',
		'brname',
		'manufacturer',
		'totalines'
	];
	reject: any = [];
	rheadercolumns: string[] = [
		'selected',
		'orderno',
		'orderdate',
		'debtorno',
		'branchcode',
		'brname',
		'reason'
	];
	constructor(private location: Location, private formBuilder: UntypedFormBuilder, private ordersService: OrdersService,private globalSearchService: GlobalSearchService) {}

	ngOnInit(): void {

		this.color = this.globalSearchService.getColor();
		this.ordersService.getADRS().subscribe((results: any) => {
			this.pending = new MatTableDataSource(results);
			this.pending.sort = this.sorter1;
			this.pending.paginator = this.paginator;

		});
		this.ordersService.getADRSReject().subscribe((results: any) => {
			this.reject = new MatTableDataSource(results);
			this.reject.sort = this.sorter2;
			this.reject.paginator = this.paginator2;

		});
		this.ordersService.getADRSSent().subscribe((results: any) => {
			this.sent = new MatTableDataSource(results);
			this.sent.sort = this.sorter3;
			this.sent.paginator = this.paginator3;

		});
	}
	sendADRS() {
		
		this.ordersService.sendADRS(this.pending.filteredData).subscribe((results: any) => {
			this.pending = new MatTableDataSource(results);
			this.pending.sort = this.sorter1;
			this.pending.paginator = this.paginator;

		});
		


	}
	removeADRS() {
		if (confirm("This will remove all selected ADRS orders, are you sure?") == true) {
		this.ordersService.removeADRS(this.pending.filteredData).subscribe((results: any) => {
			this.pending = new MatTableDataSource(results);
			this.pending.sort = this.sorter1;
			this.pending.paginator = this.paginator;

		});
		}


	}
	clearADRS(orderno){
	    this.ordersService.clearADRS(orderno).subscribe((results: any) => {
			this.pending = new MatTableDataSource(results);
			this.pending.sort = this.sorter1;
			this.pending.paginator = this.paginator;

			this.ordersService.getADRSReject().subscribe((results: any) => {
			this.reject = new MatTableDataSource(results);
			this.reject.sort = this.sorter2;
			this.reject.paginator = this.paginator2;

		});
		this.ordersService.getADRSSent().subscribe((results: any) => {
			this.sent = new MatTableDataSource(results);
			this.sent.sort = this.sorter3;
			this.sent.paginator = this.paginator3;

		});

		});
	}
	resendADRS(orderno){
		this.ordersService.resendADRS(orderno).subscribe((results: any) => {
			this.pending = new MatTableDataSource(results);
			this.pending.sort = this.sorter1;
			this.pending.paginator = this.paginator;

			this.ordersService.getADRSReject().subscribe((results: any) => {
			this.reject = new MatTableDataSource(results);
			this.reject.sort = this.sorter2;
			this.reject.paginator = this.paginator2;

		});
		this.ordersService.getADRSSent().subscribe((results: any) => {
			this.sent = new MatTableDataSource(results);
			this.sent.sort = this.sorter3;
			this.sent.paginator = this.paginator3;

		});
		});
	}
	applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.pending.filter = filterValue;
      }
	back(): void {
		this.location.back()
	}

	announceSortChange(sortState: Sort) {

	}

}
