import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, FormArray, Validators, ValidationErrors, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Location } from '@angular/common'

import { merge, fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from '../../services/customer.service'

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


declare var $: any;

@Component({
	selector: 'app-merge-customers',
	templateUrl: './merge-customers.component.html',
	styleUrls: ['./merge-customers.component.scss']
})
export class MergeCustomersComponent implements OnInit {
	color: any = 'blue';
	fromcustomer = new FormControl('');
	tocustomer = new FormControl('');
	customersearchsubscription: any = false;
	sending: any = false;
	fromresults: any = false;
	toresults: any = false;
	from: any = false;
	to: any = false;
	transactions: any = false;

	form = new FormGroup({
		items: new FormArray([])
	});
	selected: any = [];

	constructor(private route: ActivatedRoute, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder, public globalsService: GlobalsService, private modalService: NgbModal, private customerService: CustomerService) {

		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {}

	back(): void {
		this.location.back();
	}

	setTo(value: any) {
		this.to = value
	}
	checkAll(event: any) {
		if (event.target.checked) {
			this.selected = this.transactions.transactions;
		} else {
			this.selected = [];
		}
	}

	mergeCustomers() {

			let ok = confirm("Merge Customers?");
			if (ok) {
				let data = {
					from: this.from,
					to: this.to,
				}

				this.customerService.mergeDebtors(data).subscribe((r: any) => {
					if(r.success) {
						this.globalSearchService.showNotification("Customers Merged", 'success', 'bottom', 'right');
						this.customerService.getDebtorTransactions(this.from.debtorno).subscribe((r: any) => {

							this.fromcustomer.setValue('');
							this.tocustomer.setValue('');

							this.fromresults = [];
							this.toresults = [];

							r.transactions.forEach(() => this.control.push(new FormControl()))
							this.transactions = r;
							this.selected = [];
						});
					} else {
						this.globalSearchService.showNotification(r.message, 'danger', 'bottom', 'right');
					}

				})
			}

	}
	addRemove(event: any, trans: any) {
		if (event.target.checked) {
			this.selected.push(trans)
		} else {
			let index = this.selected.indexOf(trans);
			this.selected.splice(index, 1);
		}
	}
	setFrom(value: any) {
		this.from = value
		this.customerService.getDebtorTransactions(value.debtorno).subscribe((r: any) => {
			r.transactions.forEach(() => this.control.push(new FormControl()))
			this.transactions = r;
		})
	}
	get control() {
		return this.form.get('items') as FormArray;
	}

	customerSearch(keyword: any, type: any) {

		let search = {
			keywords: keyword,
		}

		if (this.customersearchsubscription) {
			this.customersearchsubscription.unsubscribe();
		}

		this.sending = true;

		this.customersearchsubscription = this.customerService.getCustomerSearch(search).subscribe((results: any) => {
			this.sending = false;
			switch (type) {
				case 'from':
					this.fromresults = results;
					break;
				default:
					this.toresults = results;
					break;

			}
		});
	}

	displayFn(results: any): string {
		if (results) {
			return results.debtorno + ' ' + results.name;
		} else {
			return '';
		}

	}

}
