<div class="main-content">
	<div class="container-fluid">
		<div class="row tp-wrapper m-0 p-0">
			<div class="col-md-12 ">
				<div class="card ">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title" (click)="back()">
							<i class="material-icons">arrow_left</i>
							{{ title }}
						</h4>
					</div>
					<div class="card-body">

						<div class="row">
							<div class="col-md-3">
								<mat-form-field appearance="standard" >
									<mat-label>Warehouse</mat-label>
									<mat-select [formControl]="defaultlocation" multiple>
										<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
											{{ loc.locationname }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-md-3">
								<mat-form-field appearance="standard">
									<mat-label>From Date</mat-label>
									<input matInput (dateChange)="updateDayPayMents($event.value)" [formControl]="datefrom" [matDatepicker]="payfrom">
									<mat-datepicker-toggle matSuffix [for]="payfrom"></mat-datepicker-toggle>
									<mat-datepicker #payfrom></mat-datepicker>
								</mat-form-field>
							</div>
							<div class="col-md-3">
								<mat-form-field appearance="standard">
									<mat-label>To Date</mat-label>
									<input matInput (dateChange)="updateDayPayMents($event.value)" [formControl]="dateto" [matDatepicker]="payto">
									<mat-datepicker-toggle matSuffix [for]="payto"></mat-datepicker-toggle>
									<mat-datepicker #payto></mat-datepicker>
								</mat-form-field>
							</div>
							<div class="col-md-3">

								<button class="mt-3"
								 mat-raised-button  color="accent" (click)="loadData()" [disabled]="running">Load</button>
								 <span *ngIf="running || exporting"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									 <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									 </svg>
								 </span>
								&nbsp;&nbsp; <button class="btn btn-success btn-sm" (click)="$event.preventDefault();exportXls()" *ngIf="payments.summary && payments.transactions && !running && !exporting && payments.transactions.length" >
									<i class="fa fa-file-excel-o"></i> XLS
								</button>
								&nbsp;&nbsp;
								<button class="btn btn-danger btn-sm" (click)="$event.preventDefault();exportPdf()" *ngIf="payments.summary&& payments.transactions && !running && !exporting && payments.transactions.length" >
									<i class="fa fa-file-pdf-o"></i> PDF
								</button>
							</div>
						</div>
						<!--left-->
						<div class="row m-0 p-0 mt-3 ">

							<div class="col-md-12" id="payrep" *ngIf="!running" >
								<h4>Total Trans {{ total_trans | currency }}</h4>
							</div>
							<div class="col-md-12" id="payrep" *ngIf="!running" >
								<ng-container *ngIf="payments.transactions">
									<div  class="table" #print_table >
									<div class="bg-white mt-2 rounded">
										<div id="receipts">
											<h4 class="mt-2 mb-4 noprint">{{ title }} {{ getLocationName() }}</h4>
											<table id="rcptTable" name="rcptTable" class="table compact table-sm table-fixed collapsed" *ngIf="payments.transactions && payments.transactions.length">
												<thead>
													<tr>

														<th class="text-center" *ngIf="locations.length > 1">Location</th>
														<th class="text-center">Date</th>
														<th class="text-center">Order#</th>
														<th class="text-center">Document#</th>
														<th class="text-center">Type#</th>
														<th class="text-center">Terms#</th>
														<th class="text-right">Customer#</th>
														<th class="text-left">Name</th>
														<th class="text-right">Amount</th>
														<th class="text-right">Freight</th>
														<th class="text-right">Discount</th>
														<th class="text-right">Tax</th>
														<th class="text-right" *ngIf="config.env.package =='tires'">FET</th>
														<th class="text-right"><b>Total</b></th>
														<th class="text-right"><b>Payment</b></th>
														<th class="text-center">Settled</th>
														<th class="text-center">Reference</th>
														<th class="text-center">Payments</th>
													</tr>
												</thead>
												<tbody>
													<ng-container *ngFor="let pay of payments.transactions">
													<tr>
														<td class="text-left" *ngIf="locations.length > 1">{{ pay.loccode }}</td>
														<td class="text-center"> {{ pay.trandate }}</td>
														<td class="text-center"> {{ pay.order_ }} </td>
														<td class="text-center"> {{ pay.transno }} </td>
														<td class="text-center"> {{ pay.typename }} </td>
														<td class="text-center"> {{ pay.payterms }} </td>

														<td class="text-right text-nowrap">
															<a [routerLink]="'/customers/view/' + pay.debtorno">
																<b>{{ pay.debtorno }}.{{ pay.branchcode }}</b>
															</a>
														</td>
														<td class="text-left text-nowrap">{{ pay.name }}</td>
														<td class="text-right"> {{ pay.ovamount | currency }}</td>
														<td class="text-right"> {{ pay.ovfreight | currency }}</td>
														<td class="text-right"> {{ pay.ovdiscount | currency }}</td>
														<td class="text-right"> {{ pay.ovgst | currency }}</td>
														<td class="text-right" *ngIf="config.env.package =='tires'"> {{ pay.ovfee | currency }}</td>
														<td class="text-right">{{ pay.total | currency }}</td>
														<td class="text-right" [ngClass]="{'text-success
														': pay.settled == 'Yes', 'text-danger' : pay.settled =='No'}">{{ pay.alloc | currency }}</td>
														<td class="text-center"> {{ pay.settled }}</td>
														<td class="text-center"> {{ pay.reference}}</td>
														<td class="text-center"> {{ pay.paytext}}</td>
													</tr>
													</ng-container>

													<tr>
														<td class="text-center"><b>TOTALS</b></td>
														<td class="text-center"></td>
														<td class="text-center">  </td>
														<td class="text-center">  </td>
														<td class="text-center">  </td>
														<td class="text-center">  </td>
														<td class="text-right text-nowrap"></td>
														<td class="text-left text-nowrap"></td>
														<td class="text-right text-nowrapnumber"> {{ total_sub | currency }}</td>
														<td class="text-right text-nowrapnumber"> {{ total_freight | currency }}</td>
														<td class="text-right text-nowrapnumber"> {{ total_discount | currency }}</td>
														<td class="text-right text-nowrapnumber"> {{ total_tax | currency }}</td>
														<td class="text-right text-nowrapnumber" *ngIf="config.env.package =='tires'"> {{ total_fee | currency }}</td>
														<td class="text-right text-nowrapnumber">{{ total_trans | currency }}</td>
														<td class="text-right" ></td>
														<td class="text-center"></td>
														<td class="text-center"></td>
														<td class="text-center"></td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								</ng-container>
								<ng-container *ngIf="payments.transactions && !payments.transactions.length">
									<h3 class="text-center">No Transactions Found</h3>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
