import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

@Component({
	selector: 'app-report-builder',
	templateUrl: './report-builder.component.html',
	styleUrls: ['./report-builder.component.scss']
})
export class ReportBuilderComponent implements OnInit {
	color: any = 'blue';
	html: any = '';
	urlSafe: SafeResourceUrl;
	config: any = [];
	constructor(public sanitizer: DomSanitizer, public globalSearchService: GlobalSearchService, ) {
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		})
	}

	ngOnInit(): void {
		this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.config.apiServer.reportBuilder+'?template_color='+this.color)
	}
}
