import { Component, OnDestroy, SimpleChanges, OnChanges, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

import * as FileSaver from 'file-saver';

import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PurchasingService } from '../../services/purchasing.service';
import { PaymentsService } from '../../services/payments.service';

import { Directive, HostListener } from "@angular/core";
import { trigger, state, style, transition, animate } from '@angular/animations';
import { saveAs } from "file-saver";

@Component({
	selector: 'app-order-invoice',
	templateUrl: '../dispatch-invoice-shared.html',
	styleUrls: ['../dispatch-invoice-shared.scss']
})
export class OrderInvoiceComponent implements OnInit {

	@Input() orderno: any = false;
	@Input() reload: any = false;
	@Input() ctlordirect: any = 'ctl';
	@Output() invoiced = new EventEmitter < any > ();
	@Output() forceReset = new EventEmitter < any > ();
	@Output() reloadCustomer = new EventEmitter < any > ();

	invoice_result: any = false;
	document_text: string = 'Invoice';
	document_type: string = '30';
	documentDetails: FormGroup;

	order: any = {
		header: [],
		details: [],
		tax_data: {},
	};

	//orderno: any = '';
	debtorno: any = '';
	branchcode: any = '';
	reprintlink: any = '';
	dispatchlink: any = '';
	dispatching: boolean = false;
	addingpay: boolean = false;
	dispatch_details: any = [];
	//container for specifics about taxes.
	taxes: any = [];

	freight_tax_total: number = 0;
	freight_charge: number = 0.00;
	payment_total: number = 0.00
	freight_taxes: any = []
	display_total: any = '';

	payments_added: any = [];

	headercolumns = [
		'stockid',
		'quantity',
		'units'
	];

	totals = {
		subtotal: 0.00,
		freight: 0.00,
		tax: 0.00,
		discount: 0.00,
		total: 0.00,
	};
	taxdatatotal: number = 0;

	prefered_vendor: any = [];
	payments: any = [];
	color: string = 'blue'
	sending: boolean = false;
	config: any = false;
	baseUrl: string = '';
	invoiceLink: string = '';
	terms: any = false;
	salespeople: any = false;
	shipvias: any = false;
	document_types = [
		{ label: 'Back Order', value: 'backorder' },
		{ label: 'Cancel', value: 'cancel' },
	];

	doc_return_type: string = 'Item Balance';
	tax_details = [];

	constructor(private fb: FormBuilder, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, private globalSearchService: GlobalSearchService, private purchasingService: PurchasingService, private paymentsService: PaymentsService) {

		this.color = this.globalSearchService.getColor();
		this.baseUrl = this.globalSearchService.config.apiServer.baseUrl;
		this.invoiceLink = this.globalSearchService.config.apiServer.invoiceLink;
		this.globalSearchService.configsubscription.subscribe( r=> {
			this.config = r;
		});
	}

	ngOnInit(): void {

		this.globalSearchService.salespeople.subscribe(async (results: any) => {
			this.salespeople = results;
		});

		this.globalSearchService.terms.subscribe(async (results: any) => {
			this.terms = results;
		});

		this.globalSearchService.shipvia.subscribe((results: any) => {
			this.shipvias = results;
		});

		//this.loadOrder();
		//this.route.params.subscribe(params => {
		//	this.orderno = params.id
		//	this.loadOrder()
		//})
	}

	getTotalDispatching() {
		var value = 0;
		if(this.order.details) {
			value = this.order.details.reduce(function(accumulator, item) {
				return parseFloat(accumulator) + parseFloat(item.dispatch);
			}, 0);
		}

		return value;
	}


	getBalance() {
		var value = this.totals.total - this.payment_total;
		//if(value < 0) {
		//	value = 0.00
		//}
		return value;
	}

	createInvoice() {
		this.updateTotals();

		if (this.order && this.documentDetails.valid) {
			let data = {
				order: this.order,
				totals: this.totals,
				payments: this.payments_added,
				taxes: this.order.tax_data,
				document_form: this.documentDetails.value
			}

			this.sending = true;
			this.orderService.createInvoice(data).subscribe((results: any) => {
				this.sending = false;
				if (results.success) {
					this.invoiced.emit(results);
					this.reloadCustomer.emit(true);
					if(this.ctlordirect == 'direct') {
						this.invoice_result = false;
						this.reloadCustomer.emit(true);
						this.forceReset.emit(true);
						this.clearCart();
						this.router.navigate(['/orders/success/' + results.id]);
					}
				} else {
					alert(results.message);
				}
			});
		} else {
			if (!this.documentDetails.valid) {
				this.globalSearchService.getFormValidationErrors(this.documentDetails);
			}
		}
	}

	clearCart() {
		this.orderService.clearOrder(this.order.header.debtorno, this.order.header.branchcode).subscribe((results: any) => {
		});
	}

	termRequiresPayment() {
		let term = this.terms.filter( r=> {
			return r.termsindicator == this.documentDetails.get('terms').value;
		})[0];

		if(term) {
			return term.collect_payment == '1';
		}

		return false;
		//collect_payment
	}

	updateDispatchQty(event: any, item: any) {
		if (event.target.value < 0) {
			event.target.value = 0;
		}

		if (parseFloat(item.ordered) >= parseFloat(event.target.value)) {
			item.dispatch = this.santizeNumberValue(event.target.value);
		} else {

			event.target.value = parseFloat(item.ordered);
			item.dispatch = parseFloat(item.ordered)
		}

		this.updateTotals()
	}

	setQty(event: any, item: any) {
		if (parseFloat(item.ordered) < parseFloat(event.target.value)) {
			item.dispatch = this.santizeNumberValue(event.target.value);
		} else {
			item.dispatch = item.ordered;
		}

		this.updateTotals()
	}

	updatePayments(event: any) {

		if(event) {
			this.payments_added = event;
			this.payment_total = this.payments_added.reduce(function(accumulator, item) {
				return parseFloat(accumulator) + parseFloat(item.amount);
			}, 0);
		}

		let data = {
			orderno: this.order.header.orderno,
			debtorno: this.order.header.debtorno,
			branchcode: this.order.header.branchcode,
		}

		this.paymentsService.getOrderPayments(data).subscribe((results: any) => {
			this.payments = results;
			this.updateTotals()

			this.payment_total = this.financial(results.reduce(function(accumulator, item) {
				return parseFloat(accumulator) + parseFloat(item.amount);
			}, 0));

		});

	}

	setFreightCharge(event: any) {
		this.order.header.freightcost = this.santizeNumberValue(event.target.value);
		this.freight_charge = this.order.header.freightcost;
		this.updateTotals();
	}

	setFreightTax(event: any, tax: any) {
		if (event.target.value < 0) {
			event.target.value = 0;
		}
		tax.taxrate = this.santizeNumberValue(event.target.value);
		this.updateTotals();
	}

	setTax(event: any, item: any, taxinndex: any) {
		if (event.target.value < 0) {
			event.target.value = 0;
		}

		item.taxes[taxinndex].taxrate = this.santizeNumberValue(event.target.value);
		this.updateTotals();
	}

	getFreightTax() {

		let total = 0
		this.freight_tax_total = 0;
		let freight = this.freight_charge
		var freighttax = 0;
		if (this.order) {
			let taxauths = [];
			this.freight_taxes = this.order.freight_tax;

			//by auth
			this.order.freight_tax.forEach((tax, index) => {
				taxauths.push({ taxauthid: tax.taxauthid, value: ((tax.taxrate / 100) * freight) })
			});

			this.freight_taxes = taxauths;
			//total freight tax
			freighttax = this.order.freight_tax.reduce(function(accumulator, tax) {
				return parseFloat(accumulator) + ((tax.taxrate / 100) * freight);
			}, 0);
		}

		this.freight_tax_total = freighttax;
		return freighttax;
	}

	updateTotals() {

		//todo clean this up. requires two containers..
		//parse items subtotals / taxes first.

		this.totals.total = 0;
		let totaltax = 0;
		//zero out tax containers
		var parent = this;

		this.order.tax_data.authorities.forEach((item, index) => {
			this.order.tax_data.authorities[index].total = 0;
		});

		let freighttax = this.getFreightTax()
		//add freight tax to tax total container

		this.freight_taxes.forEach((fghtax) => {
			this.order.tax_data.authorities.forEach((tax, index) => {
				if (tax.taxauthid == fghtax.taxauthid) {
					let value = parseFloat(Number.parseFloat(fghtax.value).toFixed(2));
					if (value) {
						this.order.tax_data.authorities[index].total += parseFloat(Number.parseFloat(fghtax.value).toFixed(2));
					}
				}
			})
		})

		if(this.order.details && this.order.details.length) {
			this.order.details.forEach((item, index) => {
				//subtotals
				var discountprice = parent.financial(item.unitprice) - parent.financial(item.discountpercent * item.unitprice);
				let subtotal = parent.financial(discountprice * item.dispatch);
				this.order.details[index].linesubtotal = subtotal;
				//this.order.details[index].taxtotal = 0;
				//item tax breakdown
				if (this.order.tax_data.authorities.length > 0) {
					item.taxes.forEach((tax, subindex) => {
						let thistax = this.order.tax_data.authorities.filter(all => all.taxauthid == tax.taxauthid)[0];
						if (thistax) {
							let index = this.order.tax_data.authorities.indexOf(thistax);
							let value = (tax.taxrate / 100 * subtotal);
							if (value) {
								if (this.order.tax_data.authorities[index]) {
									this.order.tax_data.authorities[index].total += (tax.taxrate / 100 * subtotal);
									thistax.total = (tax.taxrate / 100 * subtotal);
									let val = ((tax.taxrate / 100) * subtotal);
									this.order.details[index].taxes[subindex].total = parseFloat(val.toFixed(2));
								}
							}
						}
					});
				}
				//after
				let total_linetax = item.taxes.reduce(function(accumulator, line) {
					return accumulator + ((line.taxrate / 100) * subtotal);
				}, 0);

				this.order.details[index].taxtotal = total_linetax;
				this.order.details[index].linetotal = total_linetax + subtotal;

				totaltax += total_linetax;
			});

			this.totals.subtotal = this.order.details.reduce(function(accumulator, line) {
				//this financial not avail in reduce
				var discountprice = parent.financial(line.unitprice) - parent.financial(line.discountpercent * line.unitprice);
				let subtotal = parent.financial(discountprice * line.dispatch);
				return accumulator + subtotal;
			}, 0);

			this.taxdatatotal = this.order.tax_data.authorities.reduce(function(accumulator, line) {
				return accumulator + (line.total);
			}, 0);

			//total tax includes freight tax
			this.totals.tax = totaltax + freighttax;
			this.totals.freight = this.freight_charge;
			this.totals.total = this.totals.subtotal + this.totals.freight + this.totals.tax + this.totals.discount;

		}
	}

	financial(x) {
		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	santizeNumberValue(input: any) {
		return (Number.isNaN(input) || input == '') ? 0 : this.financial(input);
	}

	back(): void {
		this.location.back()
	}

	loadOrder() {

		this.order = {
			header: [],
			details: [],
			tax_data: {},
		};

		this.orderService.getOrder(this.orderno).subscribe((details) => {

			this.order = details
			this.freight_charge = parseFloat(this.order.header.freightcost)
			this.debtorno = this.order.header.debtorno
			this.branchcode = this.order.header.branchcode
			this.orderno = this.order.header.orderno
			//display on loaded
			this.order.tax_data.authorities.forEach(r => {
				r.taxrate = parseFloat(r.taxrate) * 100
			});

			let data = {
				orderno: this.order.header.orderno,
				debtorno: this.order.header.debtorno,
				branchcode: this.order.header.branchcode,
			}

			this.paymentsService.getOrderPayments(data).subscribe((results: any) => {
				this.payments = results;
				this.setForm();
				this.updateTotals();
			});


		})
	}

	setForm() {

		let cancel_or_bo = (  this.config &&  this.config.env.package == 'beauty' ) ? 'backorder': 'cancel';

		this.documentDetails = this.fb.group({
			invoiceDate: [new Date().toISOString(), Validators.required],
			packages: [1, Validators.required],
			invoiceText: [''],
			email: [this.order.invoiceemailaddress],
			customerref: [this.order.header.customerref],
			terms: [this.order.header.termsindicator, Validators.required],
			salesperson: [this.order.header.salesmancode, Validators.required],
			shipvia: [this.order.header.shipvia, Validators.required],
			document_type: [cancel_or_bo, Validators.required],
		});
	}



	paymentRequired() {

	}

	ngOnChanges(changes: any) {
		if(changes.orderno) {
			this.invoice_result = false;
			this.loadOrder()
		}
	}

	toggleAddingPay() {
		this.addingpay = (this.addingpay) ? false : true;
	}

	addPoItems(supplier: any) {

		let item = [{
			stockid: '',
			description: '',
			line_notes: '',
			qty: '',
			price: '',
		}];

		this.purchasingService.addToOrder(item, supplier).subscribe((result) => {

		})
	}

	ngAfterViewInit(): void {

	}

}
