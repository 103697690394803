import { CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { InventoryService } from 'app/services/inventory.service';
import { PrintService } from 'app/services/print.service';
import { da } from 'date-fns/locale';
import { Paginator } from 'primeng/paginator';

@Component({
  selector: 'app-boms',
  templateUrl: './boms.component.html',
  styleUrls: ['./boms.component.scss']
})


export class BomsComponent implements OnInit, AfterViewInit {

	@ViewChild("detailsModal") detailsModalEle: ElementRef;
	@ViewChild('paginator') paginator: MatPaginator;
	// @ViewChild('componentpaginatorelf') componentpaginator: MatPaginator;

	// @ViewChildren(NgbModal) componentpaginator: QueryList<any>;
	// @ViewChildren('detailsModal') componentpaginator!: QueryList<MatPaginator>;

	// openclosed: any;
	lookup = new UntypedFormControl('');
	componentlookup = new UntypedFormControl('');
	searchsub: any;
	searching: boolean;
	datasource: any = false;
	itemlist: any;
	showlist: boolean;
	noresults: boolean;

	displayedColumns: string[] = [
		"parent",
		"description",
		"effectiveafter",
		"effectiveto",
		"action"
	];

	componentdisplayedColumns: string[] = [
		"stockid",
		"description",
		"mfgpart",
		"action"
	];

	activeBom: any = false;
	bomForm: FormGroup<{}>;
	bomParentEditing: any = false;
	componentitemlist: any = [];
	componentdatasource: any = new MatTableDataSource();
	componentsearching: boolean;
	showcomponentlist: boolean;
	componentnoresults: boolean;
	componentsearchsub: any;
	editing: boolean = false;
	activeParent: any = '';

	constructor(public inventoryService: InventoryService, private globalSearchService: GlobalSearchService, private printService: PrintService, private modalService: NgbModal, public fb: FormBuilder) { }
	ngAfterViewInit(): void {


	}

	ngOnInit(): void {

		this.loadAllBoms();
		// this.componentSearch();
	}


	loadAllBoms() {
		let data = {type: ['boms']}
		this.inventoryService.itemSearch(data).subscribe((result: any) => {
			this.datasource = new MatTableDataSource(result);
			this.datasource.paginator = this.paginator;
			this.itemlist = result;
			this.searching = false;
		});
	}


	lookupVersionEight() {
		if (this.searchsub) {this.searchsub.unsubscribe();}
		this.searching = true;

		if (this.lookup.value != '' ) {
			let data = {};

			if(this.lookup.value != '' ){
				data['stockid'] = this.lookup.value;
			}

			data['type'] = ['boms'];

			this.searchsub = this.inventoryService.itemSearch(data).subscribe((results: any) => {
				this.datasource = new MatTableDataSource(results);
				this.datasource.paginator = this.paginator;
				this.itemlist = results
				this.searching = false;
			});

			} else {
				this.searching = false;
				this.showlist = false;
				this.noresults = false;
				this.itemlist = null;
			}
	}


	openLookup(element){
		this.editing == true ? this.editing = false : this.editing = true;
		this.activeParent = element.stockid;

	}















}
