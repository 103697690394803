<ng-template #quoteDetails let-modal >
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Quote# {{ selectedQuoteDetails.orderno }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">

		<div class="mb-3 table" >

			<div class="mb-3" *ngIf="selectedQuoteDetails">
				<ng-container *ngIf="selectedQuoteDetails.type == 'pdf'">
					<ngx-extended-pdf-viewer [base64Src]="selectedQuoteDetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true"></ngx-extended-pdf-viewer>

				</ng-container>
				<ng-container *ngIf="selectedQuoteDetails.type == undefined">
					<table class="table table-sm">
						<tr>
							<th>Item</th>
							<th>Desc</th>
							<th class="text-center">Qty</th>
							<th  class="text-right">Price</th>
							<th  class="text-center">Discount</th>
							<th  class="text-right">Total</th>
						</tr>
						<tr *ngFor="let item of selectedQuoteDetails.details">
							<td>{{ item.stkcode }}</td>
							<td>{{ item.itemdesc }}</td>
							<td class="text-center">{{ item.quantity }}</td>
							<td class="text-right">{{ item.unitprice |currency}}</td>
							<td class="text-center">{{ item.discountpercent }}%</td>
							<td class="text-right">{{ item.unitprice * item.quantity |currency }}</td>
						</tr>
						<tr *ngFor="let t of selectedQuoteDetails.totals">
							<td></td>
							<td class="text-center"></td>
							<td class="text-center"></td>
							<td class="text-center"></td>
							<td class="text-right">{{ t.title }}:</td>
							<td class="text-right">{{ t.text | currency }}</td>
						</tr>
					</table>
				</ng-container>
			</div>

		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #emailData let-modal> <!-- Email input -->
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Confirm Email Address</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3">
			<mat-form-field appearance="standard">
				<mat-label>Email Address</mat-label>
				<input matInput [(ngModel)]="sendemail" />
			</mat-form-field>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-raised-button color="primary" (click)="pushEmail()">
			<mat-icon>email</mat-icon> Send
		</button>
	</div>
</ng-template>
<table mat-table [dataSource]="openorders" matSort (matSortChange)="announceSortChange($event)">
	<ng-container matColumnDef="orderno">
		<th mat-header-cell *matHeaderCellDef mat-sort-header>Order No</th>
		<td mat-cell *matCellDef="let tran">
			<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
				<mat-icon>more_vert</mat-icon>
			</button>

			<mat-menu #menu="matMenu">
				<button mat-menu-item (click)="printQuote(tran)">
					<mat-icon>printer</mat-icon>
					<span>Print Quote</span>
				</button>
				<button mat-menu-item (click)="viewQuote(tran)">
					<mat-icon>visibility</mat-icon>
					<span>View</span>
				</button>
				<button mat-menu-item (click)="sendEmail(tran)">
					<mat-icon>email</mat-icon>
					<span>Email</span>
				</button>
				<button mat-menu-item (click)="loadQuote(tran)">
					<mat-icon>edit</mat-icon>
					<span>Load</span>
				</button>
				<button mat-menu-item (click)="cancelQuote(tran.orderno)">
					<mat-icon>remove</mat-icon>
					<span>Cancel Quote</span>
				</button>
			</mat-menu>
			{{ tran.orderno }}
		</td>
	</ng-container>
	<!--
	<ng-container matColumnDef="orderno">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> Order No#.</th>
		<td mat-cell *matCellDef="let tran">{{ tran.orderno }} ({{ tran.details.length}})</td>
	</ng-container>
	-->
	<ng-container matColumnDef="deliverydate">
		<th mat-header-cell *matHeaderCellDef mat-sort-header>Delivery Date#</th>
		<td mat-cell *matCellDef="let tran" [innerHTML]="tran.deliverydate"></td>
	</ng-container>
	<ng-container matColumnDef="ponumber">
		<th mat-header-cell *matHeaderCellDef mat-sort-header>PO No#</th>
		<td mat-cell *matCellDef="let tran" [innerHTML]="tran.ponumber"></td>
	</ng-container>
	<ng-container matColumnDef="deliverto">
		<th mat-header-cell *matHeaderCellDef mat-sort-header>Deliver To#</th>
		<td mat-cell *matCellDef="let tran" [innerHTML]="tran.deliverto"></td>
	</ng-container>
	<ng-container matColumnDef="items">
		<th mat-header-cell *matHeaderCellDef mat-sort-header>#Items</th>
		<td mat-cell *matCellDef="let tran" ><span *ngIf="tran.totals">{{ tran.items }}</span></td>
	</ng-container>
	<ng-container matColumnDef="phoneno">
		<th mat-header-cell *matHeaderCellDef mat-sort-header>Phone#</th>
		<td mat-cell *matCellDef="let tran" ><span *ngIf="tran.phoneno">{{ tran.phoneno }}</span></td>
	</ng-container>
	<ng-container matColumnDef="totals">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> Total</th>
		<td mat-cell *matCellDef="let tran" ><span *ngIf="tran.totals">{{ getTotal(tran.totals) | currency }}</span></td>
	</ng-container>
	<!--
	<ng-container matColumnDef="enteredby">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> Entered By</th>
		<td mat-cell *matCellDef="let tran" >{{ tran.enteredby }}</td>
	</ng-container>
-->

	<tr mat-header-row *matHeaderRowDef="headercolumns"></tr>
	<tr mat-row *matRowDef="let row; columns: headercolumns"></tr>
</table>
<mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons aria-label="select page">
</mat-paginator>
