import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { Location } from '@angular/common'

@Component({
	selector: 'app-prodline-markups',
	templateUrl: './prodline-markups.component.html',
	styleUrls: ['./prodline-markups.component.scss']
})
export class ProdlineMarkupsComponent implements OnInit {

	@Input() line_field;
	@Output() updatePorductLines = new EventEmitter < boolean > ();
	plmdata: any = [];
	plvs: any = [];

	constructor(private fb: UntypedFormBuilder, private location: Location, private globalsService: GlobalsService, private globalSearchService: GlobalSearchService) {}

	ngOnInit(): void {

		this.getData()
		this.globalSearchService.all_system_types.subscribe((alltypes: any) => {
			if (alltypes) {
				this.plvs = alltypes.salestypes;
			}
		});
	}

	getFallBack(pline: any, type:any) {
		let substitute = this.plvs.filter(r=> {
			return (r.typeabbrev == type && pline == r.line_field)
		})[0];

		if(substitute) {
			return substitute;
		}

		return false;
	}

	getData() {
		let data = { line_field: this.line_field };
		this.globalsService.getProdLineMarkups(data).subscribe((results: any) => {
			this.plmdata = results;
		});
	}

	addPLM() {
		this.plmdata.push({
			editing: 1,
			id: '',
			line_field: this.line_field,
			typeabbrev: '',
			operator: '',
			amount: '0'
		});
	}

	deletePLM(plm: any) {
		let data = { id: plm.id };
		this.globalsService.deleteProdLineMarkup(data).subscribe((results: any) => {
			if(results.success) {
				this.getData()
			} else {
				alert(results.message)
			}
		});
	}

	savePLM(plm: any) {

		let data = {
			line_field: plm.line_field,
			id: plm.id,
			typeabbrev: plm.typeabbrev,
			operator: plm.operator,
			amount: plm.amount,
			pricebasis: plm.pricebasis
		};

		this.globalsService.saveProdLineMarkup(data).subscribe((results: any) => {
			this.plmdata = results;
			this.updatePorductLines.emit(true);
		});
	}

	cancelPLM() {
		let data = { line_field: this.line_field };
		this.globalsService.getProdLineMarkups(data).subscribe((results: any) => {
			this.getData()
		});
	}

}
