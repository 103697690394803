<!-- modal -->
<ng-template #detailsModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">{{ orderdetails.header.name }} ( {{ orderdetails.header.debtorno }} ) </h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-12">
				<h4>INVOICE: <b>{{ orderdetails.header.orderno  }}</b><br>
				</h4>
			</div>
		</div>

		<div class="table table-responsive">
			<table class="table table-sm">
				<thead>
					<tr>
						<th class="text-left">ITEM</th>
						<th class="text-left">DESC</th>
						<th class="text-center">QTY</th>
						<th class="text-center">PRICE</th>
						<th class="text-right">EXT.</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of orderdetails.details">
						<td class="text-left"><b>{{ item.stkcode }}</b></td>
						<td>{{ item.description }}</td>
						<td class="text-center">{{ item.ordered }} </td>
						<td class="text-center">{{ item.ordered | currency}} </td>
						<td class="text-right">{{ item.unitprice * item.ordered | currency }} </td>
					</tr>
				</tbody>
			</table>
		</div>

	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="row m-0 p-0 ">
			<div class="col-md-12 ">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title pointer">
							<i class="material-icons" (click)="back()">arrow_left</i>ITEM LOOKUP
						</h4>
					</div>
					<div class="card-body  no-overflow">

						<!-- search bar -->
						<mat-form-field appearance="standard" class="">
							<input matInput #searchRef [formControl]="lookup" id="searchRef" value="" class="margin-top" autocomplete="off" placeholder="* Item Search  " (keyup.enter)="$event.stopPropagation();$event.preventDefault();lookupVersionEight();$event.target.blur();">
							<div matSuffix class="text-right">
								<button mat-icon-button type="button" color="white" (click)="$event.stopPropagation();$event.preventDefault();lookupVersionEight()">
									<mat-icon>search</mat-icon>
								</button>
							</div>
						</mat-form-field>
						<!-- multiple tire select list -->
						<div class="table table-responsive text-center searchlist" *ngIf="searching">
							<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
						</div>
						<div class="table table-responsive searchlist" #searhlist *ngIf="itemlist && itemlist.length">
							<table class="table table-sm">
								<thead class="sticky-thead">
									<tr>
										<th></th>
										<th class="text-left">ITEM</th>
										<th class="text-left">DESC <mat-icon style="float: right; color: #e26d6d;" (click)="closeSearch()">cancel</mat-icon>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of itemlist ">
										<td> <a mat-raised-button class="width: 20px" (click)="loadItem(item)">Select</a></td>
										<td class="text-left">
											{{ item.stockid }}
										</td>
										<td class="text-left f12">{{ item.description }}</td>
									</tr>
								</tbody>
							</table>
						</div>



						<!-- item details -->
						<div class="table mt-3 shift-20" *ngIf="itemio.item && !itemlist">
							<ng-container *ngIf="itemio.item">
								<h4 class="text-center mt-0 mb-0"><b style="font-weight: bold">{{ itemio.item.description }}</b></h4>
								<h5 class="text-center m-b-30">( {{ itemio.item.stockid }} )</h5>
								<div class="card">
									<div class="card-header card-header-tabs card-header-{{color}}-2 p-0">
										<div class="nav-tabs-navigation">
											<div class="nav-tabs-wrapper text-nowrap">
												<ul class="nav nav-tabs col-12" data-tabs="tabs">
													<li class="nav-item col-6 mt-0 mb-0 text-center">
														<a mat-button class="nav-link active" href="#info" data-toggle="tab">
															<i class="material-icons">code</i> Receive
															<div class="ripple-container"></div>
														</a>
													</li>
													<li class="nav-item col-6 mt-0 mb-0 text-center">
														<a mat-button class="nav-link" href="#history" data-toggle="tab">
															<i class="material-icons">cloud</i>Purch
															<div class="ripple-container"></div>
														</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div class="card-body">
										<mat-icon style="position: absolute; bottom: 0; right: 0; color: gainsboro;" @flipState (click)="loadItem(itemio.item)">refresh</mat-icon>
										<div class="tab-content">
											<div class="tab-pane active" id="info">
												<div class="row m-0 p-0">

													<div class="col-12">
														<ul class="entry-list">
															<li>
																<div class="spec-label">ON HAND </div>
																<div class="spec-value">{{ itemio.total_qoh }}<span *ngIf="already_scanned">+{{ already_scanned }}</span></div>
															</li>
															<li>
																<div class="spec-label">ON ORDER</div>
																<div class="spec-value">{{ itemio.openpos }}</div>
															</li>
															<li>
																<div class="spec-label">COMMITTED</div>
																<div class="spec-value">{{ itemio.reserved }}</div>
															</li>
															<li>
																<div class="spec-label">AVAILABLE</div>
																<div class="spec-value">{{ itemio.total_qoh - itemio.reserved }}</div>
															</li>
															<li>
																<div class="spec-label">MIN/MAX</div>
																<div class="spec-value">
																	<ng-container *ngIf="itemio.item">
																		{{ itemio.item.minmax.minimum }} / {{ itemio.item.minmax.maximum }}
																	</ng-container>
																</div>
															</li>


															<ng-container *ngIf="config.env.package != 'tires'">
																<ng-container *ngFor="let item of itemio.itemstock">
																	<li>
																		<div class="spec-label dashed"><b>{{ item.locationname }}:</b></div>
																		<div class="spec-value dashed">
																			<b>{{ item.quantity }}</b>
																		</div>
																	</li>
																	<ng-container *ngIf="itemio.bins">
																		<ng-container *ngFor="let loc of binDisplay">
																			<li>
																				<ng-container *ngIf="loc.quantity != '0' && item.loccode == loc.loccode">
																					<div class="spec-label bg-white ">
																						<span *ngIf="loc.bin == ''"><i>N/A</i></span>
																						<span *ngIf="loc.bin != ''"><i>{{ loc.bin }}</i></span>
																					</div>
																					<div class="spec-value ">
																						&nbsp;({{ loc.quantity }})
																					</div>
																				</ng-container>
																			</li>
																		</ng-container>
																	</ng-container>
																</ng-container>
															</ng-container>
															<ng-container *ngIf="config.env.package == 'tires'">
																<li *ngFor="let loc of itemio.bins | keyvalue">
																	<div class="spec-label">DEFAULT LOCATION</div>
																	<div class="overflow-scroll">
																		<ng-container *ngFor="let l of loc.value">
																			<ng-container *ngIf="l.defaultbin == '1'">
																				<div>
																					<div class="spec-value">{{ l.bin }} ({{ l.quantity }})</div>
																				</div>
																			</ng-container>
																		</ng-container>
																	</div>
																</li>
																<li *ngFor="let loc of itemio.bins | keyvalue">
																	<div class="spec-label">LOCATION</div>
																	<div class="overflow-scroll">
																		<ng-container *ngFor="let l of loc.value">
																			<ng-container *ngIf="l.defaultbin != '1' && l.quantity > 0">

																				<div class="spec-value">{{ l.bin }} ({{ l.quantity }})</div>
																			</ng-container>
																		</ng-container>
																	</div>
																</li>
															</ng-container>
														</ul>
													</div>
												</div>
												<div class="row mt-2 mb-2" *ngIf="itemio">
													<div class="col-12 text-center">
														<h6 class="mt-0 mb-0">ORDERS</h6>
													</div>
													<div class="col-12 text-center" *ngIf="!itemio.reserved_orders">
														N/A
													</div>
													<!-- <div class="col-md-12 text-center reserved_orders"  *ngIf="itemio.reserved_orders">
												<span class="ml-auto mr-auto" *ngFor="let order of itemio.reserved_orders">
													<a class="order-details" (click)="loadOrder(order.orderno)">
														<div class="flex-col">
															<b>&nbsp;&nbsp;{{ order.orderno}}&nbsp;&nbsp;</b>
															<span class="f12 mt-10 cb" *ngIf="order.completed == 0">
																<span class="lg">Picked</span>: <span class="lg">{{order.picked}}/</span><b class="dg">{{order.pick}}</b>
															</span>
														</div>
													</a>
												</span>
											</div> -->

													<div class="col-md-12 text-center reserved_orders" *ngIf="itemio.salesordersdetails">
														<span class="ml-auto mr-auto" *ngFor="let orders of itemio.salesordersdetails;">
															<a class="order-details" (click)="loadOrder(orders.orderno)">
																<div class="flex-col">
																	<b style="margin-bottom: 5px; border-bottom: 1px dotted #b1b1b1;">&nbsp;&nbsp;{{ orders.orderno}}&nbsp;&nbsp;</b>
																	<div>
																		<div class="ml-auto mr-auto" *ngFor="let order of itemio.reserved_orders_items[orders.orderno]; let iconIndex = index;">
																			<a class="order-details">
																				<div class="flex-col">
																					<div>
																						<div class="f12 mt-10 cb" style="text-align: left;  display: flex; flex-direction: row; line-height: 13px; margin-bottom: 4px; margin-left: -2px;  flex-wrap: wrap;">
																							<mat-icon [ngClass]="{
																						'f20': true,
																						'icon_border_top': iconIndex == 0,
																						'icon_border_middle': iconIndex > 0,
																						'icon_border_bottom': iconIndex == itemio.reserved_orders_items[orders.orderno].length -1,
																						'zero_staus':order.picked == 0,
																						'partial_status': order.picked > 0,
																						'completed_status': order.completed == 1 }">circle</mat-icon>

																							<div class="lg">{{order.bin}}</div>: <div class="lg" style="padding-left: 5px; text-align: right;">{{order.picked}}/</div><b class="dg">{{order.pick}}</b>

																						</div>
																					</div>
																				</div>
																			</a>
																		</div>
																	</div>
																	<!-- <span class="f12 mt-10 cb" *ngIf="order.completed == 0">
																<span class="lg">Picked</span>: <span class="lg">{{order.picked}}/</span><b class="dg">{{order.pick}}</b>
															</span>	 -->
																</div>
															</a>
														</span>
													</div>

													<div class="col-12 text-center text-md-center">
														<h6 class="mt-0 mb-0">BARCODES</h6>
														<div class="spec-value">{{ item.barcode }}</div>
													</div>
													<div class="col-md-12 text-center ">
														<!-- <ng-container *ngIf="itemio.openpos">
														<span class="ml-auto mr-auto" *ngFor="let po of itemio.openpos">
															<b>Expected {{po.quantityord }} </b>
														</span>
												</ng-container> -->
													</div>
												</div>

											</div>
											<div class="tab-pane" id="history">
												<div class="table text-center" *ngIf="historyloading">
													<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
														<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
													</svg>
												</div>
												<div class="col-12" *ngIf="!history && !historyloading">
													<div class="alert alert-warning">No Purchase History</div>
												</div>
												<table class="table table-sm" *ngIf="history">
													<thead>
														<tr>
															<th class="text-left w-20">SUPP</th>
															<th class="text-left w-20">PO</th>
															<th class="text-left w-20">DATE</th>
															<th class="text-center w-20">QTY</th>
															<th class="text-right w-20">COST</th>
														</tr>
													</thead>
													<tbody>
														<tr *ngFor="let h of history">
															<td class="text-left"> {{h.suppname}}</td>
															<td class="text-left"> {{h.orderno}}</td>
															<td class="text-left"> {{h.orddate}}</td>
															<td class="text-center"> {{h.quantityord}}</td>
															<td class="text-right"> {{h.unitprice | currency}}</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
