<ng-template #runsheet let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Order Details {{ selectedorder.invoice }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="col-12">
				<div class="row">
					<div class="col-9">
						<h5 [innerHTML]="selectedorder.address"></h5>
					</div>
					<div class="col-3 text-right">
						<ul class="entry-list m-0 p-0 bordered">
							<li>
								<div class="spec-label">EST</div>
								<div class="spec-value">{{ selectedorder.timedsp }}</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="border-bottom smaller-font">
			<ng-container *ngFor="let da of viewing_dispatches.dispatched">
				<div class="row" *ngIf="da.ispoint=='0'">
					<div class="col-md-6 ml-0 mr-0 pr-0" [innerHTML]="da.address">
					</div>
					<div class="col-md-6 ml-0 mr-0 pr-0 pl-0 text-right">
						<ul class="entry-list m-0 p-0">
							<li>
								<div class="spec-label">ORDER#</div>
								<div class="spec-value">{{ da.invoice }}</div>
							</li>
							<li>
								<div class="spec-label">PO</div>
								<div class="spec-value">{{ da.po }}</div>
							</li>
							<li>
								<div class="spec-label">Items</div>
								<div class="spec-value">{{ da.qty }}</div>
							</li>
							<li>
								<div class="spec-label">Status</div>
								<div class="spec-value">{{ da.status }}</div>
							</li>
						</ul>
					</div>
				</div>
				<mat-divider></mat-divider>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #orderdetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Order Details {{ selectedorder.invoice }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark top-index">
		<div class="row">
			<div class="col-12">
				<div class="row">
					<div class="col-9">
						<h5 [innerHTML]="selectedorder.address"></h5>
					</div>
					<div class="col-3 text-right">
						<ul class="entry-list m-0 p-0 bordered">
							<li>
								<div class="spec-label">EST</div>
								<div class="spec-value">{{ selectedorder.timedsp }}</div>
							</li>
						</ul>
					</div>
				</div>
				<table class="table table-sm">
					<tr>
						<th>Item#</th>
						<th>Description</th>
						<th>Ordered</th>
						<th>Price </th>
					</tr>
					<tr *ngFor="let item of selectedorder.details">
						<td>{{ item.stkcode }}</td>
						<td>{{ item.description }}</td>
						<td>{{ item.ordered }}</td>
						<td>{{ item.unitprice }}</td>
					</tr>
				</table>
			</div>
			<div class="col-4">
				<button mat-raised-button color="warn" (click)="seleccted_alt = true;">Dropped At Alt. Location</button>
				<div *ngIf="seleccted_alt">
					<mat-form-field appearance="standard" class="ml-2 mr-2">
						<mat-label>Transfer To:</mat-label>
						<mat-select [formControl]="transfertruck">
						<mat-option *ngFor="let truck of trucks"
						[value]="truck.id">
						{{truck.name }}</mat-option>
						</mat-select>
					</mat-form-field>
					<button mat-raised-button color="danger" (click)="altLocation()">Submit</button>
				</div>

			</div>
			<div class="col-4 text-center">
				<button mat-raised-button color="accent" (click)="cancel(selectedorder)">Refused Delivery</button>
			</div>
			<div class="col-4 text-center">
				<button mat-raised-button color="primary" (click)="delivered(selectedorder)">Delivered</button>
			</div>

		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="col-md-12">
			<div class="card">
				<div class="card-header card-header-{{color}}-5">
					<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Active Dispatches</h4>
					<p class="card-category"></p>
					<div class="messages">
					</div>
				</div>
				<div class="card-body" cdkDropListGroup>
					<div class="row">
						<div class="col-8">
							<h5 class="mt-2">Active</h5>
							<ng-container *ngFor="let d of dispatches;trackBy: identify;">
								<mat-accordion  *ngIf="d.dispatched.length">
									<mat-expansion-panel (opened)="zoomTo(d.truck)" >
										<mat-expansion-panel-header>
											<mat-panel-title>
												<div class="mr-2" style="width:10px;height:10px;background: {{ d.truckdetails.color }}">
												</div>
												{{d.truck}} {{ d.truckdetails.name }} ({{d.dispatched.length - 2 }})
												{{ d.timedisp }}
											</mat-panel-title>
											<mat-panel-description>
												{{d.truckdetails.driver}}
											</mat-panel-description>
										</mat-expansion-panel-header>
										<div [cdkDropListData]="d.dispatched" (cdkDropListDropped)="drop($event, d);" cdkDropList>
											<nav class="navbar navbar-expand navbar-light bg-light no-shadow  justify-content-between w-100 ">
												<div class="navbar-nav">

													<!-- <button class="nav-item nav-link" mat-button (click)="toggleLock(d)">
														<mat-icon *ngIf="d.unlocked">lock_open</mat-icon>
														<mat-icon *ngIf="!d.unlocked">lock</mat-icon>
														<span *ngIf="d.unlocked">UnLocked</span>
														<span *ngIf="!d.unlocked">Locked</span>
													</button>
														[(disabled)]="!d.unlocked"

													-->
													<button class="nav-item nav-link" mat-button (click)="getRecalc(d)" >
														<mat-icon>directions_car</mat-icon>
														<span>Optimize</span>
													</button>
													<a class="nav-item nav-link" mat-button href="{{ delsheetlink }}{{d.truck}}">
														<i class="fa fa-solid fa-file-pdf"></i>
														<span>PDF</span>
													</a>
													<button class="nav-item nav-link" data-toggle=" modal" data-target="#detailsModal" mat-button (click)="viewRun(d)">
														<mat-icon>visibility</mat-icon>
														<span>View</span>
													</button>
												</div>
											</nav>
											<div class="scrolling-left" >
												<!-- [cdkDragDisabled]="!d.unlocked" -->
												<div *ngFor="let da of d.dispatched" cdkDrag  >
													<ng-container *ngIf="da.ispoint=='0'">
														<mat-divider></mat-divider>
														<div class="row small-font status-{{da.status_id }}" >
															<div class="col-5 ml-4 mr-0 p-0" [innerHTML]="da.address" (click)="zoomToPoint(da)">
															</div>
															<div class="col-3 ml-0 mr-0 p-0">
																<ul class="entry-list m-0 p-0">
																	<li>
																		<div class="spec-lable">PO</div>
																		<div class="spec-value">{{ da.lines }}</div>
																	</li>
																	<li>
																		<div class="spec-lable">QTY</div>
																		<div class="spec-value">{{ da.qty }}</div>
																	</li>
																	<li>
																		<div class="spec-lable">P-LINES</div>
																		<div class="spec-value">{{ da.lines }}</div>
																	</li>
																</ul>
															</div>
															<div class="col-3 text-right ml-0 mr-0 p-0">
																<ul class="entry-list text-right m-0 p-0">
																	<li>
																		{{ da.invoice }}
																	</li>
																	<li>
																		{{ da.status }}
																	</li>
																	<li>
																		{{ da.timedsp }}
																		<!-- ( {{da.shortesttime }} ) -->
																	</li>
																</ul>
																<button mat-button [matMenuTriggerFor]="menu" aria-label="Menu"
																[cdkDragDisabled]="true">
																	{{ da.sort }}
																	<mat-icon>more_vert</mat-icon>
																</button>
																<mat-menu #menu="matMenu" [cdkDragDisabled]="true">
																	<button data-toggle="modal" data-target="#detailsModal" mat-menu-item (click)="viewOrder(da)">
																		<mat-icon>visibility</mat-icon>
																		<span>View</span>
																	</button>
																	<button data-toggle="modal" data-target="#detailsModal" mat-menu-item (click)="viewOrder(da)">
																		<mat-icon>sync_alt</mat-icon>
																		<span>Update Status</span>
																	</button>

																	<button mat-menu-item (click)="removeDispatch(da)">
																		<mat-icon>remove</mat-icon>
																		<span>Remove From Dispatch</span>
																	</button>
																</mat-menu>
															</div>
														</div>
													</ng-container>
												</div>
											</div>
										</div>
									</mat-expansion-panel>
								</mat-accordion>
							</ng-container>
							<mat-divider></mat-divider>
							<h5 class="mt-2">Inactive</h5>
							<ng-container *ngFor="let d of dispatches">
								<mat-accordion class="" *ngIf="!d.dispatched.length">
									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title>
												<div class="mr-2" style="width:10px;height:10px;background: {{ d.truckdetails.color }}">
												</div>

												{{d.truck}} {{ d.truckdetails.name }} ({{d.dispatched.length}})

												{{ d.timedisp }}
											</mat-panel-title>
											<mat-panel-description>
											</mat-panel-description>
											<div cdkDropList>

											</div>
										</mat-expansion-panel-header>
									</mat-expansion-panel>
								</mat-accordion>
							</ng-container>
						</div>

						<div class="col-4">
							<div class="row">
								<div class="col-6 m-0 p-0">
									<h5 class="mt-2">Map</h5>
								</div>
								<div class="col-6 text-right m-0 ">
									<button mat-raised-button color="warn" (click)="resetZoom()">Reset</button>
								</div>
							</div>

							<div class="mt-0 pt-0 mb-0 pb-0" id="map">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
