import { Component, Input, OnInit } from '@angular/core';
import { InventoryService } from 'app/services/inventory.service';
import { OnDestroy, ViewChild, ChangeDetectorRef, ElementRef, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';

@Component({
  selector: 'app-item-pick-history',
  templateUrl: './item-pick-history.component.html',
  styleUrls: ['./item-pick-history.component.scss']
})
export class ItemPickHistoryComponent implements OnInit, OnChanges {

  @Input() item;
  content: boolean = false;
  itemForm: UntypedFormGroup;
  afterdate = new Date();
  todate = new Date(new Date().setDate(this.afterdate.getDate() - 365));
  locations: any = [];
  results: any = false;
  slidefilteroption: any;
  option: any = true;
  errorHighlight: boolean = false;
  sending: boolean = false;

  constructor(private inventoryService: InventoryService, private fb: UntypedFormBuilder, private globalSearchService: GlobalSearchService) { }

  ngOnInit(): void {
    this.content = true;
    this.globalSearchService.locations.subscribe(async (results: any) => {
      this.locations = results;
      this.setForm()
    });
  }

  ngOnChanges(): void{
    this.setForm();
  }

  setForm() {
    this.itemForm = this.fb.group({
      stockid: [this.item.stockid, Validators.required],
      loccode: [this.locations[0]?.loccode, Validators.required],
      fromdate: [this.todate, Validators.required],
      todate: [this.afterdate, Validators.required],
      option: [this.option]
    });
    this.getPickHistory()
  }


    getPickHistory() {
    let data = this.itemForm.value;
    this.sending = true;
    this.inventoryService.getPickHistory(data).subscribe((result)=>{
      this.results = result;
      this.sending = false;

    });
  }

  toggleErrorHighlight(){ //clicking the document table header cell higlights rows where pick != picked
    this.errorHighlight = this.errorHighlight == true ? false : true;
  }

}
