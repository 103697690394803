import { Component, OnInit, ElementRef , Input } from '@angular/core';
import { ChatService } from '../../services/chat.service';
import { OmsService } from '../../services/oms.service';
import { Message } from '../../classes/message';
import { UsersService } from '../../services/users.service';
import { FormControl, UntypedFormBuilder, FormGroup } from '@angular/forms';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

@Component({
  selector: 'app-chatbox',
  templateUrl: './chatbox.component.html',
  styleUrls: ['./chatbox.component.scss']
})
export class ChatboxComponent implements OnInit {

	SENDER;
	messages = [];
	message;
	CHAT_ROOM = "messages";
	messageForm;
	messagecount = 0;
	notificationcount: number;
	userData: any = [];
	allowed: boolean = false;


	constructor(private chatService: ChatService, private usersService: UsersService , public formBuilder: UntypedFormBuilder,private globalSearchService:GlobalSearchService) { }

	ngOnInit(): void {

		// this.globalSearchService.mesagesallowed.subscribe(a => {
		// 	this.allowed = a
		// })
		this.allowed = false;

		this.messagecount = 0;
		this.messageForm = this.formBuilder.group({
			message: '',
		});

		this.userData = this.usersService.getLocalUser();
		// const token = this.userData.user.userid
		// if (token) {
		// 	//this.chatService.setupSocketConnection(token);
		// 	this.chatService.subscribeToMessages((err, data) => {
		// 		//this.messages = [...this.messages, data];
		// 		this.messages.push(data);
		// 		this.messagecount = this.messagecount+1
		// 		this.globalSearchService.messages.next(this.messagecount);
		// 	});
		// }
	}

	isUser(userid: any) {
		return (userid == this.userData.user.userid) ? true : false;
	}

	submitMessage() {
		const message = {
			content:this.messageForm.get('message').value,
			user: this.userData.user.realname,
			userid: this.userData.user.userid,
		}

		if (message) {

			this.chatService.sendMessage({message, roomName: this.CHAT_ROOM}, cb => {
			});

			var messageobject = {
				name: message.user,
				id: message.userid,
				message,
			}

			this.messages.push(messageobject)
			this.messageForm.reset();
		}
	}

}
