<ng-container>
<form @grow [formGroup]="vendorAddonForm"  (ngSubmit)="onSubmit()"
	class="needs-validation product-add"  >
	<div class="card-header">
		<div class="col-12 text-right">
			<button class="ml-auto mr-0" mat-button
			mat-raised-button [color]="vendorAddonForm.valid ? 'warn': 'accent' "> <i class="material-icons">save</i> Save</button>
		</div>
	</div>
	<div class="row">
		<div class="col-md-6">
			<mat-form-field appearance="standard">
				 <mat-label>telephone</mat-label>
				 <input matInput value="" formControlName="telephone">
			 </mat-form-field>
		</div>
		<div class="col-md-6">
			<mat-form-field appearance="standard">
				 <mat-label>email</mat-label>
				 <input matInput value="" formControlName="email">
			 </mat-form-field>
		</div>
		<div class="col-md-6">
			<mat-form-field appearance="standard">
				 <mat-label>fax</mat-label>
				 <input matInput value="" formControlName="fax">
			 </mat-form-field>
		</div>
		<div class="col-md-6">
			<mat-form-field appearance="standard">
				 <mat-label>short code</mat-label>
				 <input matInput value="" formControlName="bankref">
			 </mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="standard">
				 <mat-label>taxref</mat-label>
				 <input matInput value="" formControlName="taxref">
			 </mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="standard">
				 <mat-label>url</mat-label>
				 <input matInput value="" formControlName="url">
			 </mat-form-field>
		</div>
		<!-- <div class="col-md-6 slide-toggle" >
			<mat-slide-toggle formControlName="supptype">Disabled</mat-slide-toggle>
		</div> -->
		<div class="col-md-6">
			<mat-form-field appearance="standard">
			<mat-label>Supplier Type</mat-label>
			<mat-select formControlName="supptype">
				<mat-option *ngFor="let type of supptypes" [value]="type.typeid">
				{{type.typename}}
				</mat-option>
			</mat-select>
			</mat-form-field>
		</div>
	</div>
</form>
