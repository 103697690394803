<ng-template #paymentSearch let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Allocate another</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="account">
			<div class="row">
				<div class="col-6">
					<h4>{{ account.name }} ({{ account.terms.code }})</h4>
					<h5> Receivables</h5>
				</div>
				<div class="col-6 text-right">
					<mat-slide-toggle class="nav-link" [formControl]="openrec" >
						<mat-label>Show Open Only</mat-label>
					</mat-slide-toggle>
				</div>
			</div>
			<table class="table table-fixed" >
				<thead>
				<tr>
					<th></th>
					<th>DATE</th>
					<th>TYPE</th>
					<th>DOC#</th>
					<th class="text-right bold">TOTAL</th>
					<th class="text-right bold">BAL</th>
				</tr>
				</thead>
				<tbody *ngIf="transactions.length">
				<tr *ngFor="let t of transactions" >
					<td><button mat-raised-button (click)="changeTrans(t.id);modal.close('Ok click');">Select</button></td>
					<td>
						{{t.trandate}}
					</td>
					<td>
						{{t.typename}}
					</td>
					<td>
						{{t.transno}}
					</td>
					<td class="text-right bold">
						{{ t.total | abs | currency }}
					</td>
					<td class="text-right bold">
						{{ t.leftto | abs | currency }}
					</td>
				</tr>
				</tbody>
				<tbody *ngIf="!transactions.length">
					<td class="text-center" colspan="6">
						No Open Transactions
					</td>
				</tbody>
			</table>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-container *ngIf="trandata">
<div class="container-fluid" id="allocdiv" #allocdiv>
	<mat-divider></mat-divider>
	<div class="row mt-2">
		<div class="col-6 text-left mt-2">
			<h5 class="bold" >
				Allocating {{trandata.typename}} {{trandata.transno}}# <button mat-icon-button (click)="changeDocumentModal()" color="accent"><mat-icon>published_with_changes</mat-icon></button>
			</h5>

		</div>
		<div class="col-6 text-right mt-2">
				&nbsp;&nbsp;&nbsp;
				<button mat-raised-button color="accent" (click)="dealllocate()">
					<mat-icon>remove_circle_outline</mat-icon> Deallocate
				</button>
				&nbsp;&nbsp;&nbsp;
				<button mat-raised-button color="accent" (click)="autoAlloc()" *ngIf="type < 20">
					<mat-icon>grading</mat-icon> Auto Alloc
				</button>

		</div>
		<h4 class="col-6 text-right bolder" [ngClass]="trandata.balance == 0 ? 'bg-success white' : ' bg-danger text-white'">TOTAL: {{ trandata.total * -1  | currency }}
		</h4>
		<h4 class="col-6 text-left  bolder" [ngClass]="trandata.balance == 0 ? 'bg-success text-white' : ' bg-danger text-white'"  >
			AVAILABLE: {{ trandata.balance  | currency }}

		</h4>
	</div>
	<div class="row">
		<div class="col-md-6 border-rounded light-border" >
			<h5 class="title m-0  mt-1">Open Transactions ({{trandata.available?.length}})</h5>
			<div class="row">
				<mat-form-field class="full-width text-right col-md-6" appearance="standard" color="accent">
					<mat-label>Search Invoice Avail Date#</mat-label>
					<input type="text" placeholder="Search" aria-label="Text" matInput [formControl]="searchByCurrentInvoicesDate" (input)="currentInvoiceSearchDate($event.target.value)">
				</mat-form-field>
				<mat-form-field class="full-width text-right col-md-6" appearance="standard" color="accent">
					<mat-label>Search Invoice Avail#</mat-label>
					<input type="text" placeholder="Search" aria-label="Text" matInput [formControl]="searchByCurrentInvoices" (input)="currentInvoiceSearch($event.target.value)">
				</mat-form-field>
			</div>
			<div class="border-rounded light-border scrolling-left">

				<div class="table table-sm">

					<table class="table table-sm compressed table-fixed">
						<thead>
							<tr>
								<th class="w-15">TYPE</th>
								<th class="w-15">DOC#</th>
								<!--<th class="w-15">TYPE</th>-->
								<th class="w-15">DATE</th>
								<th class="w-15 text-right">NET</th>
								<th class="w-15 text-right">DUE</th>
								<th class="w-15 text-center">PAY</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let tran of trandata.available;">
								<td class="w-15 text-left">{{ tran.typename }}</td>
								<td class="w-15">{{tran.transno}}</td>
								<!--<td>{{tran.typename}}</td>-->
								<td class="w-15">{{tran.trandate | date:"MM/dd/yy"}}</td>
								<td class="w-15 text-right bold">{{tran.total | currency}}</td>
								<td class="w-15 text-right bold">{{tran.balance | currency}}</td>
								<td class="w-15 text-right" *ngIf="!trandata.viewonly">
									<!--
										<mat-form-field appearance="standard">
											<mat-label>PAYMENT</mat-label>
											<span matPrefix>$</span>
											<input id="amt_{{tran.id}}"  type="text" mat-input class="text-right" placeholder="Allocation Amount" aria-label="Allocation Amount" matInput />
											<span matSuffix>
												<button mat-stroked-button [disabled]="trandata.balance == 0" (click)="addAllocation(tran, trandata)" [ngClass]="trandata.balance == 0 ? 'text-danger': ''"> &gt; &gt; </button>
											</span>
										</mat-form-field>
									-->
									<input id="amt_{{tran.id}}" placeholder="{{ tran.balance | currency }} &nbsp;" mat-input class="form-control text-right border-input alloc" [disabled]="trandata.balance == 0">
								</td>
								<td class="w-15 text-right" *ngIf="!trandata.viewonly">

									<button mat-stroked-button [disabled]="trandata.balance == 0" (click)="addAllocation(tran, trandata)" [ngClass]="trandata.balance == 0 ? 'text-danger': ''"> &gt; &gt; </button>
								</td>
							</tr>
							<tr *ngIf="!trandata.available">
								<td colspan="6" class="text-center">No Transactions Avail</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="col-md-6 border-rounded light-border">
			<h5 class="title m-0 mt-1">Transactions Paid ({{trandata.allocto?.length}})</h5>
			<div class="row">
				<mat-form-field class="full-width text-right col-md-6" appearance="standard" color="accent">
					<mat-label>Search Paid To Date#</mat-label>
					<input type="text" placeholder="Search" aria-label="Text" matInput [formControl]="searchByCurrentPaymentsDate" (input)="currentPaymentsSearchDate($event.target.value)">
				</mat-form-field>
				<mat-form-field class="full-width text-right col-md-6" appearance="standard" color="accent">
					<mat-label>Search Paid To#</mat-label>
					<input type="text" placeholder="Search" aria-label="Text" matInput [formControl]="searchByCurrentPayments" (input)="currentPaymentsSearch($event.target.value)">
				</mat-form-field>
			</div>
			<div class="border-rounded light-border scrolling-left">
				<div class="table table-sm">
					<table class="table table-sm compressed table-fixed" *ngIf="trandata.allocto">
						<thead>
							<tr>
								<th class="w-15 text-left" *ngIf="!trandata.viewonly"></th>
								<th class="w-15 text-left">TYPE</th>
								<th class="w-15 text-left">DOC#</th>
								<th class="w-15 text-left">DATE</th>
								<th class="w-15 text-right">NET</th>
								<th class="w-15 text-right">PAID</th>
								<th class="w-15 text-right">BAL.</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td></td>
								<td class="w-15 text-left">{{trandata.typename}} </td>
								<td class="w-15 text-left">{{trandata.transno}}</td>
								<td>***</td>
								<td class="w-15 text-right ">***</td>
								<td class="w-15 text-right ">***</td>
								<td class="text-right bold">
									{{ trandata.total * -1  | currency }}
								</td>
							</tr>
							<tr *ngFor="let tran of trandata.allocto;">
								<td class="w-15" *ngIf="!trandata.viewonly">
									<button mat-stroked-button color="accent" (click)="removeAllocation(tran, trandata)"> &lt; &lt; </button>
								</td>
								<td class="w-15 text-left">{{ tran.typename }}</td>
								<td class="w-15 text-left">{{tran.transno}} </td>

								<td class="w-15 text-left">{{tran.trandate | date:"MM/dd/yy" }}</td>
								<td class="w-15 text-right">{{tran.total| currency}}</td>
								<td class="w-15 text-right bold">{{tran.amt | currency}}</td>
								<td class="w-15 text-right bold">{{tran.run | abs | currency }}</td>
							</tr>
							<tr *ngIf="!trandata.allocto">
								<td colspan="7" class="text-center">No Transactions Allocated</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<mat-divider></mat-divider>
</div>
</ng-container>
