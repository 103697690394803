import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Location } from '@angular/common'

import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { CustomerService } from '../../services/customer.service';
import { PaymentsService } from '../../services/payments.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
	selector: 'app-statements',
	templateUrl: './statements.component.html',
	styleUrls: ['./statements.component.scss']
})
export class StatementsComponent implements OnInit {
	color: string = 'blue';
	statementForm: UntypedFormGroup;
	statementEmailForm: UntypedFormGroup;
	today = new Date();
	loading: any = false;
	editorConfig: AngularEditorConfig = {
		editable: true,
		  spellcheck: true,
		  height: 'auto',
		  minHeight: '0',
		  maxHeight: 'auto',
		  width: 'auto',
		  minWidth: '0',
		  translate: 'yes',
		  enableToolbar: false,
		  showToolbar: false,
		  defaultParagraphSeparator: '',
		  defaultFontName: '',
		  defaultFontSize: '',
		  fonts: [
			{class: 'arial', name: 'Arial'},
			{class: 'times-new-roman', name: 'Times New Roman'},
			{class: 'calibri', name: 'Calibri'},
			{class: 'comic-sans-ms', name: 'Comic Sans MS'}
		  ],
		  customClasses: [
		  {
			name: 'quote',
			class: 'quote',
		  },
		  {
			name: 'redText',
			class: 'redText'
		  },
		  {
			name: 'titleText',
			class: 'titleText',
			tag: 'h1',
		  },
		],
		sanitize: true,
		toolbarPosition: 'top',
		toolbarHiddenButtons: [
		  ['bold', 'italic'],
		  ['fontSize']
		]
	};

	descriptionHtmlContent: JSON;

	statement_text:any = [];

	defaultTypes = ['credit', 'debit']


	constructor(private ordersService: OrdersService, private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private paymentsService: PaymentsService, private fb: UntypedFormBuilder, private customerService: CustomerService, private route: ActivatedRoute, public router: Router) {
		this.color = this.globalSearchService.getColor();

	}

	ngOnInit(): void {
		var tenthofnext = new Date();
		tenthofnext.setMonth(tenthofnext.getMonth()+1, 1);
		tenthofnext.setDate(10)


		this.customerService.getStatementConfig().subscribe( r=> {
			this.statement_text = r
			this.statementForm = this.fb.group({
				FromCust: ['0', Validators.required],
				ToCust: ['ZZZZZZZZ', Validators.required],
				dateto: [this.today, Validators.required],
				datedue: [tenthofnext, Validators.required],
				blurbs: [this.statement_text],
				printonly: [ true, Validators.required],
				type: [this.defaultTypes]
			});

			this.statementEmailForm = this.fb.group({
				FromCust: ['0', Validators.required],
				ToCust: ['ZZZZZZZZ', Validators.required],
				dateto: [this.today, Validators.required],
				datedue: [tenthofnext, Validators.required],
				job_schedule: [this.today, Validators.required],
				blurbs: [this.statement_text],
				emailonly: [ true, Validators.required],
				type: [this.defaultTypes]
			});

			this.customerService.getStatmentCounts(this.statementEmailForm.value).subscribe( r=> {

			})
		})
	}

	updateValue(event:any, r: any) {
		r.confvalue = event.editor.getContent()
	}

	downloadPdf(base64String, fileName) {
		const source = `data:application/pdf;base64,${base64String}`;
		const link = document.createElement("a");
		link.href = source;
		link.download = `${fileName}`
		link.click();
	}

	saveMessages() {
		let data = this.statement_text
		this.customerService.saveStatementConfig(data).subscribe(r => {
			this.statement_text = r;
			this.globalSearchService.showNotification('Saved','success','bottom','left');
		});
	}

	printStatements() {
		this.loading = true;

		this.customerService.getBatchStatements(this.statementForm.value).subscribe((result: any) => {
			this.downloadPdf(result.pdf, result.filename)
			this.loading = false;
		});
	}

}
