import { Component, OnInit , Input, Output,EventEmitter, ViewChild, AfterViewInit,ElementRef} from '@angular/core';

import { ActivatedRoute, Router} from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, fromEvent } from "rxjs";
import {debounceTime, distinctUntilChanged, startWith, tap, delay} from 'rxjs/operators';
import { Location } from '@angular/common'

import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { CustomerService } from '../../../services/customer.service';
import { OrdersService } from '../../../services/orders.service';


@Component({
	selector: 'app-order-change-customer',
	templateUrl: './order-change-customer.component.html',
	styleUrls: ['./order-change-customer.component.scss']
})
export class OrderChangeCustomerComponent implements OnInit {

	@Input() customer;
	@Output() customer_updated = new EventEmitter<string>();

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild('customerinput') customerinput: ElementRef;

	customers:any = [];
	allcustomers:any = [];
	customersearching:any = false;
	noresults: boolean = true;
	headercolumns: any = [
		  'actions',
		  'name',
		  'billingaddress',
		  'phoneno'
	  ];

	constructor(private globalSearchService: GlobalSearchService, public customerService: CustomerService,public router: Router,private location: Location, private ordersService: OrdersService) { }

	ngOnInit(): void {

	}

	customerSearch(search:any ) {

		if(this.customersearching) {
			this.customersearching.unsubscribe();
		}

		this.customersearching = this.customerService.getOeCustomerSearch(search).subscribe((results:any) => {
			if(results.length) {
				this.noresults = false;
			}
			this.allcustomers = results;
			this.customers = new MatTableDataSource(results);
			this.customers.sort = this.sort;
			this.customers.paginator = this.paginator;
		});

	}

	selectCustomer(customer: any) {

		let data = {to: customer, from: this.customer}
		this.ordersService.changeOrderCustomer(data).subscribe( (results: any) => {
			this.customer_updated.emit(customer);
			//this.router.navigate(['./orders/entry/'+customer.debtorno+'/'+customer.branchcode]);

		});
	}
}
