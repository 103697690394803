import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';

@Component({
	selector: 'app-customer-carts',
	templateUrl: './customer-carts.component.html',
	styleUrls: ['./customer-carts.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotate3d(0, 1, 0, 180deg)',
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(179deg)'
			})),
			transition('active => inactive', animate('500ms ease-out')),
			transition('inactive => active', animate('500ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		]),
		trigger('openClose', [
			state(':enter', style({ height: '*' })),
			state(':leave', style({ height: '0px' })),
			transition('false <=> true', animate(500))
		]),
		trigger('filterAnimation', [
			transition(':enter, * => 0, * => -1', []),
			transition(':increment', [
				query(':enter', [
					style({ opacity: 0, width: 0 }),
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 1, width: '*' })),
					]),
				], { optional: true })
			]),
			transition(':decrement', [
				query(':leave', [
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 0, width: 0 })),
					]),
				])
			]),
		]),
		trigger("grow", [ // Note the trigger name
			transition(":enter", [
				// :enter is alias to 'void => *'
				style({ height: "0", width: "0" }),
				animate(200, style({ height: "*", width: "*" })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(":leave", [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0 })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class CustomerCartsComponent implements OnInit {


	@Input() flip: string = 'inactive';
	@Output() viewData = new EventEmitter < any > ();
	viewing: any = false;

	data: any = false;
	title: string = 'Customer Carts';

	currentcarts: any = [];
	cartagebuckets: any = [];
	cartsettings: any = [];
	@ViewChild("cartDetailsRef") cartDetails: ElementRef;

	cartdetails: any = [];
	constructor(private ordersService: OrdersService, private customerService: CustomerService, private modalService: NgbModal) {}

	ngOnInit(): void {

		this.customerService.getCurrentCarts().subscribe(async(results: any)=> {
			this.data = results.data;

			this.currentcarts = results.data;
			this.cartsettings = {
				settings_ageone: results.settings_ageone,
				settings_agetwo: results.settings_agetwo
			};
			this.cartagebuckets[3] = results.data.filter( c => {
				return c.agegroup == '3'
			})
			this.cartagebuckets[2] = results.data.filter( c => {
				return c.agegroup == '2'
			})
			this.cartagebuckets[1]= results.data.filter( c => {
				return c.agegroup == '1'
			})

		});

	}

	toggleFlip(data: any, title: string) {

		if (!data) {
			this.flip = 'inactive';
		} else {
			this.flip = (this.flip == 'active') ? 'inactive' : 'active';
		}

		if (this.flip == 'active') {
			this.viewing = data;
			this.title = title
			let dashboard_data = { data: data, title: this.title }
			this.viewData.emit(dashboard_data);
		} else {
			this.viewData.emit(false);
		}
	}

	viewCart(debtor: any) {

		let data = {
			customerno: debtor.debtorno,
			branchcode: debtor.branchcode,
		}
		this.customerService.getCartDetails(data).subscribe(results => {
			this.cartdetails = results;
			this.modalService.open(this.cartDetails, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

			}, (reason) => {
				this.cartdetails = false;
			});
		})
	}
}
