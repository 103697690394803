import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GlobalsService } from '../../services/globals.service';
import { ReportsService } from '../../services/reports.service';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent} from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { InventoryService } from 'app/services/inventory.service';
import { PrintService } from 'app/services/print.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-branch-reorder',
	templateUrl: './branch-reorder.component.html',
	styleUrls: ['./branch-reorder.component.scss']
})
export class BranchReorderComponent implements OnInit {

	@ViewChild('print_table') printtable: ElementRef;
	@ViewChild("partSelect") partSelectRef: ElementRef;
	color: any = 'blue';
	locations: any = [];
	searchForm: UntypedFormGroup;
	filename: string = 'breord';
	title: string = 'BREORD - Store Reordering';
	today = new Date();
	results: any = [];
	loading: boolean = false;
	plines: any = [];
	user: any = [];
	items: any = [];
	branches: any = [];
	branch: any = new UntypedFormControl('');
	dateCtrl: any = new UntypedFormControl('');
	transferData: any = [];
	servicelocation: any = '';
	searchVal: any = '';
	//chips
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  prodCtrl = new UntypedFormControl('');
  filteredProds: any = [];
  prods: any = [];
  allProds: any = [];
  @ViewChild('prodInput', {static: false}) prodInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

	constructor(private reportsService: ReportsService, private inventoryService: InventoryService, private printService: PrintService, private globalSearchService: GlobalSearchService, private location: Location, private route: ActivatedRoute, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal) {
	}

	ngOnInit(): void {
		this.color = this.globalSearchService.getColor();
		this.reportsService.getBranches().subscribe((results: any) => {
			this.branches = results;
		});
		this.globalSearchService.user.subscribe(result => {
			this.user = result;
			this.branch.value = this.user.user.defaultlocation.loccode;

		});
		this.inventoryService.getItemProductlines().subscribe((results: any) => {
			this.plines = results;

		})

		this.searchForm = this.fb.group({
			searchVal: ['', Validators.required]
		});

		this.resetReorder();
		this.inventoryService.getItemProductlineDetails().subscribe(results => {
			this.allProds = results;
			this.filteredProds = results;
		});

		this.prodCtrl.valueChanges.subscribe(newValue => {
			this.filter();
		});
		var today = new Date();

		this.dateCtrl.setValue(today);
	}

	getRecomendations() {

			this.loading = true;

			var fromdate = this.dateCtrl.value.toISOString().split('T')[0];
			let data = { plines: this.prods, loccode: this.branch.value, serviceloc: this.servicelocation, datefrom: fromdate };
			this.inventoryService.getReStock(data).subscribe((results: any) => {
				this.results = results
				this.loading = false;
			});

	}
	resetReorder(){
			this.inventoryService.getServiceLocation({ loccode: this.branch.value }).subscribe((results: any) => {
			this.servicelocation = results.loccode;
			});
			this.results = [];
			this.globalSearchService.showNotification('Location changed, report reset', 'warning', 'bottom', 'left');
	}
	updateBuyQuantity(event: any, item: any) {
		let index = this.results.indexOf(item);
		this.results[index].qty_purchase = event.target.value;
	}

	addMore() {
		this.modalService.open(this.partSelectRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}
	itemSearch() {

			let data = { 'keywords': this.searchForm.get('searchVal').value, 'fromstkloc': this.servicelocation, 'tostkloc': this.branch.value };
		this.inventoryService.getTransferItems(data).subscribe((results: any) => {
			if (results) {
				this.items = results;
			}
		});

	}
    removeItem(item: any): void {
    let index = this.results.indexOf(item);
    if (index >= 0) {
      this.results.splice(index, 1);
    }
    }
	decrement(item: any) {
		let index = this.items.indexOf(item);
		if (this.items[index].quantity > 0) {
			this.items[index].quantity -= 1;
		}
	}

	increment(item: any) {
		let index = this.items.indexOf(item);
		this.items[index].quantity += 1;
	}
	updateSearchQuantity(event: any, item: any) {
		let index = this.items.indexOf(item);
		this.items[index].quantity = event.target.value;
	}
	addItems(event) {
	var fromdate = this.dateCtrl.value.toISOString().split('T')[0];
		event.forEach((line: any, index) => {

			if (line.quantity > 0) {
				let data = { stockid: line.stockid, loccode: this.branch.value, datefrom: fromdate, quantity: line.quantity };
				this.inventoryService.getSingleRestock(data).subscribe((result: any) => {
					this.results.push(result);
				});
			}
		});

		this.items = [];
	}
	createTransfer() {

		let data = { 'fromstkloc': this.servicelocation, 'tostkloc': this.branch.value, 'notes': 'Created by Store Reorder' };

		this.inventoryService.createTransfer(data).subscribe((results: any) => {

			if (results.transno != '') {
				this.results.forEach((line: any, index) => {
					line.transno = results.transno;
					line.transferlineno = index;
					let data = { transno: results.transno, transferlineno: index, stockid: line.stockid, transferqty: line.qty_purchase }
					this.inventoryService.addTransferLineRestock(data).subscribe((results: any) => {});
				});
				this.results = [];
				this.globalSearchService.showNotification('Reorder Submitted: Transfer #' + results.transno + ' Created', 'success', 'bottom', 'left');
			} else {
				this.globalSearchService.showNotification('Error in creation process', 'danger', 'bottom', 'left');
			}

		});
	}

	exportPdf() {

		let encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: this.filename,
			title: 'Branch Reordering Report',
			subtitle: 'For Branch '+this.branch.value+ ' and sales history three months back from ' + this.dateCtrl.value.toISOString().split('T')[0],
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}
	exportXls() {
		let encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		let data = {
			content: encoded,
			filename: this.filename,
			title: 'Branch Reordering Report',
			subtitle: 'For Branch '+this.branch.value+ ' and sales history three months back from ' + this.dateCtrl.value.toISOString().split('T')[0],
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

    //chips
	filter() {

	if (this.prodCtrl.value == '' || !this.prodCtrl.value) {
			this.filteredProds = [];
		} else {
			let filterval = this.prodCtrl.value.replace('*','');
			this.filteredProds = this.globalSearchService.filterItem(this.allProds, filterval, 'line_field,line_description')
		}
  }

	add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;
      if (value.includes('*')) {
        this.prods.push(value.trim());
      }
      if (input) {
        input.value = '';
      }
      this.prodCtrl.setValue(null);
    } else {
		if(event.value.includes('*')){
		this.filteredProds.forEach((prod) => {
		this.prods.push({line_field: prod.line_field, line_description: prod.line_description});
		});
		event.input.value = '';
		this.prodCtrl.setValue(null);
		}
	}
  }

  remove(prod: string): void {
    const index = this.prods.indexOf(prod);
    if (index >= 0) {
      this.prods.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.prods.push({line_field: event.option.value, line_description: event.option.viewValue});
    this.prodInput.nativeElement.value = '';
    this.prodCtrl.setValue(null);
  }
	back() {

	}
}