<ng-container>
<form @grow [formGroup]="settingsForm"  (ngSubmit)="onSubmit()"
	class="needs-validation product-add"  >
	<div class="card-header">
		<div class="col-12 text-right">
			<button class="ml-auto mr-0" mat-button
			mat-raised-button [color]="settingsForm.valid ? 'warn': 'accent' "> <i class="material-icons">save</i> Save</button>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<mat-form-field appearance="standard">
				 <mat-label>Margin</mat-label>
				 <input matInput value="" formControlName="gp_margin">
			 </mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="standard">
				 <mat-label>remittance</mat-label>
				 <input matInput value="" formControlName="remittance">
			 </mat-form-field>
		</div>
		<div class="col-md-12">
		<mat-form-field appearance="standard" class="">
			<mat-label>Supplier Type</mat-label>
			<mat-select formControlName="supptype">
			<mat-option *ngFor="let type of types"
			[value]="type.typeid">
			{{type.typename }}</mat-option>
			</mat-select>
		</mat-form-field>
		</div>
	</div>
</form>
