import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { CustomerService } from '../../services/customer.service'
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-customer-groups',
	templateUrl: './customer-groups.component.html',
	styleUrls: ['./customer-groups.component.scss']
})
export class CustomerGroupsComponent implements OnInit {

	color: any = 'blue';
	searchingcgroups: any = false;
	@ViewChild("groupEdit") groupEditRef: ElementRef;

	filteredcgroups: any = [];
	filteredcustomers: any = [];
	cgroups: any = [];
	customers: any = [];
	editinggroup: any = {};
	cgroupsearch = new FormControl('');
	custsearch = new FormControl('');
	viewingglc: any = false;

	constructor(private fb: UntypedFormBuilder, private customerService: CustomerService, private globalSearchService: GlobalSearchService, private modalService: NgbModal) {}

	ngOnInit(): void {
		this.color = this.globalSearchService.getColor();
		this.cgroupsearch.valueChanges.subscribe(newValue => {
			this.filterGroup();
		});
		this.custsearch.valueChanges.subscribe(newValue => {

			this.filterCustomers();
		});
		this.customerService.getCustomers().subscribe(results => {
			this.customers = results;
			this.filteredcustomers = results;
		});
		this.loadGroups();
	}

	filterGroup() {

		if (this.cgroupsearch.value == '') {
			this.searchingcgroups = this.cgroups;
		} else {
			this.searchingcgroups = this.globalSearchService.filterItem(this.cgroups, this.cgroupsearch.value, 'group_name,group_description')
		}

	}
	filterCustomers() {

		if (this.custsearch.value == '') {
			this.filteredcustomers = this.customers;
		} else {
			this.customerService.getCustomerSearch({ 'keywords': this.custsearch.value }).subscribe(results => {
				this.filteredcustomers = results;
			});
		}

	}

	loadGroups() {
		this.customerService.getGroups().subscribe(results => {
			this.cgroups = results;
			this.filteredcgroups = results;
			this.searchingcgroups = results;
		});
	}

	addCustomerGroup() {

		this.editinggroup = { group_id: '', group_name: '', group_description: '', members: [] };
		this.modalService.open(this.groupEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});

	}

	editGroup(group: any) {
		this.editinggroup = group;
		this.editinggroup.members.forEach((member: any, index) => {
			if (member.is_primary == 1) {
				member.is_primary = true;
			} else {
				member.is_primary = false;
			}
			if (member.posflag == 1) {
				member.posflag = true;
			} else {
				member.posflag = false;
			}
		});
		this.modalService.open(this.groupEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}

	addCust(customerno: any) {

		if (this.editinggroup.members.length == 0) {
			this.editinggroup.members.push({ group_id: this.editinggroup.group_id, debtorno: customerno.debtorno, branch_code: customerno.branchcode, name: customerno.name, is_primary: true, posflag: true });
		} else {
			this.editinggroup.members.push({ group_id: this.editinggroup.group_id, debtorno: customerno.debtorno, branch_code: customerno.branchcode, name: customerno.name, is_primary: false, posflag: true });
		}
		this.custsearch.setValue('');
	}
	//So much for two-way binding
	updateGroupName(event: any) {


		this.editinggroup.group_name = event;
	}
	updateGroupDesc(event: any) {

		this.editinggroup.group_description = event;
	}
	updateMember(cust: any, event: any) {

		let index = this.editinggroup.members.indexOf(cust);
		this.editinggroup.members[index].posflag = event;
	}
	removeMember(cust: any) {
		let index = this.editinggroup.members.indexOf(cust);
		this.editinggroup.members.splice(index, 1);
		if (cust.is_primary) {
			this.editinggroup.members[0].is_primary = true;
		}
	}
	setPrimary(cust: any, event: any) {

		let index = this.editinggroup.members.indexOf(cust);
		this.editinggroup.members[index].is_primary = event;
		this.editinggroup.members.forEach((member: any, index2) => {

			if (index2 != index) {
				member.is_primary = false;
			}
		});

	}
	saveGroup() {

		this.customerService.saveGroup(this.editinggroup).subscribe(results => {
			this.cgroups = results;
			this.filteredcgroups = results;
			this.searchingcgroups = results;
			this.editinggroup = {};
			this.modalService.dismissAll();
		});

	}
	removeGroup(group) {
		this.customerService.removeGroup(group.group_id).subscribe(results => {
			this.cgroups = results;
			this.filteredcgroups = results;
			this.searchingcgroups = results;
			this.editinggroup = {};
		});
	}
	displayFn(cust: any): string {
		return cust ? cust.debtorno + '.' + cust.branchcode : '';
	}
	back() {

	}
}