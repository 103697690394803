
import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit} from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-purchasing-inbound',
  templateUrl: './purchasing-inbound.component.html',
  styleUrls: ['./purchasing-inbound.component.scss']
})
export class PurchasingInboundComponent implements OnInit {
	@Input() dataset: any = [];
	@Input() title: any = '';
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	headercolumns: string[] = [
		'file',
		'customerno',
		'ship_date',
		'transno',
		'itemnumber',
		'description',
		'sku',
	];
	statuses: any = [];
	constructor() { }

	ngOnInit(): void {

		this.dataset.sort = this.sort;
		this.dataset.paginator = this.paginator;
	}

	announceSortChange(event: any) {

	}

	getStatus(id: any) {
		return this.statuses.filter((st)=> {
			return st.status_id = id
		})[0];
	}

}
