import { Component, OnDestroy, SimpleChanges, OnChanges,OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform,AfterViewInit, Output,EventEmitter} from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';

import { ActivatedRoute, Router} from '@angular/router';
import { InventoryService } from '../../../services/inventory.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';

import {MatDividerModule} from '@angular/material/divider';
import { Directive, HostListener } from "@angular/core";
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {map, startWith} from 'rxjs/operators';

@Component({
	selector: 'app-stock-master',
	templateUrl: './stock-master.component.html',
	styleUrls: ['./stock-master.component.scss']
})

export class StockMasterComponent implements OnInit {
	@Input() clonestockid: any
	@Input() inputdata: any

	@Output() stockMasterUpdate = new EventEmitter<any>();
	@Output() updateing = new EventEmitter<any>();

	@Output() itemExists = new EventEmitter<any>();

	user: any = [];
	item: any = [];
	itemForm: UntypedFormGroup;
	properties: any = false;
	files: File[] = [];
	stockid_exists = false;
	found_item: any = false;
	config: any = false;
	allglclasses: any = [];
	glclasses: any = [];
	constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute,private inventoryService: InventoryService, private location: Location,public router: Router,public cdr: ChangeDetectorRef,private globalSearchService: GlobalSearchService, private globalsService: GlobalsService) {
		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		})

	}

	ngOnInit(): void {

		this.itemForm = this.fb.group({
			//image: [''],
			stockid: ['', [Validators.required,Validators.maxLength(20)]],
			description: ['', [Validators.required]],
			longdescription: [''],
			categoryid: ['T', [Validators.required]],
			lineid: ['', [Validators.required]],
			mbflag: ['B', [Validators.required]],
			taxcatid: ['2', [Validators.required]],
			units: ['each', [Validators.required]],
			eoq: [0,],
			volume: [0,],
			grossweight: [0,],
			glclass: ['',],
			discontinued: [0,[Validators.required]],
			perishable: [0,[Validators.required]],
			controlled: [0,],
			enabled: [1, [Validators.required]],
			serialised: [0,[Validators.required]],
			additionalfee: [0.00,[Validators.required]],
			barcode: [''],
			sort: [''],
			decimalplaces: [0, [Validators.required]],
			mfgpart: ['', [Validators.required]],
			//fetvalue: [0.00]
		});

		this.itemForm.get('stockid').valueChanges.subscribe(newValue => {
			let search = { stockid: newValue }
			this.inventoryService.getItemByStockid(search).subscribe(r => {
				this.stockid_exists = false;

				if(r.success) {
					this.stockid_exists = true;
					this.found_item = r;
					//this.itemForm.get('stockid').setErrors
					this.itemForm.get('stockid').updateValueAndValidity();
				}

				this.itemExists.emit(this.stockid_exists)
			})
		});

		this.itemForm.valueChanges.subscribe(newValue => {
			this.updateing.emit(this.itemForm);
		});

		this.itemForm.get('mfgpart').valueChanges.subscribe( newValue => {
			var suggested = this.itemForm.get('lineid').value + '-'+newValue

			//todo make this dynamic: break by % % and fill in variable names
			if(this.config.stockidformat) {
				switch(this.config.stockidformat) {
					case '%categoryid%lineid%mfgpart%':
						suggested = this.itemForm.get('categoryid').value + this.itemForm.get('lineid').value + newValue;
					break;
					default:
						suggested = this.itemForm.get('lineid').value + '-'+newValue;
					break;
				}
			}

			this.itemForm.get('stockid').setValue(suggested);
		});

		this.itemForm.get('description').valueChanges.subscribe( newValue => {
			this.itemForm.get('longdescription').setValue(newValue);
		});

		this.itemForm.get('glclass').valueChanges.subscribe( newValue => {
			if(newValue == '') {
				this.glclasses = this.allglclasses;
			} else {
				this.glclasses = this.globalSearchService.filterItem(this.allglclasses,newValue,'name,code')
			}
		});
	}

	updateItem() {
		if(this.itemForm.valid) {
			this.stockMasterUpdate.emit(this.itemForm);
		}
	}

	onSelect(event) {
	  this.files.push(...event.addedFiles);
	}

	onRemove(event) {
	  this.files.splice(this.files.indexOf(event), 1);
	}

	back(): void {
		this.location.back()
	}

	ngAfterViewInit(): void {
		this.globalsService.getItemSetup().subscribe( (results: any) => {
			this.properties = results;
			this.allglclasses = results.gl_classes
			this.glclasses = results.gl_classes
		});

		if(this.clonestockid ){
		   this.inventoryService.getItem({stockid: this.clonestockid}).subscribe((results: any) => {
				this.item = results;
				this.itemForm.controls['description'].setValue(this.item.item.description);
				this.itemForm.controls['longdescription'].setValue(this.item.item.longdescription);
				this.itemForm.controls['categoryid'].setValue(this.item.item.categoryid);
				this.itemForm.controls['glclass'].setValue(this.item.item.glclass);
				this.itemForm.controls['lineid'].setValue(this.item.item.lineid);
				this.itemForm.controls['mbflag'].setValue(this.item.item.mbflag);
				this.itemForm.controls['taxcatid'].setValue(this.item.item.taxcatid);
				this.itemForm.controls['units'].setValue(this.item.item.units);
				this.itemForm.controls['eoq'].setValue(this.item.item.eoq);
				this.itemForm.controls['volume'].setValue(this.item.item.volume);
				this.itemForm.controls['grossweight'].setValue(this.item.item.grossweight);
				this.itemForm.controls['additionalfee'].setValue(this.item.item.additionalfee);
				this.itemForm.controls['barcode'].setValue(this.item.item.barcode);
				this.itemForm.controls['sort'].setValue(this.item.item.sort);
				this.itemForm.controls['decimalplaces'].setValue(this.item.item.decimalplaces);
				this.itemForm.controls['mfgpart'].setValue(this.item.item.mfgpart);


				if(this.item.item.discontinued){
					this.itemForm.controls['discontinued'].setValue(1);
				} else {
					this.itemForm.controls['discontinued'].setValue(0);
				}

				if (this.item.item.perishable){
					this.itemForm.controls['perishable'].setValue(1);
				} else {
					this.itemForm.controls['perishable'].setValue(0);
				}
				if(this.item.item.controlled){
					this.itemForm.controls['controlled'].setValue(1);
				} else {
					this.itemForm.controls['controlled'].setValue(0);
				}
				this.itemForm.controls['enabled'].setValue(1);
				if(this.item.item.serialized){
					this.itemForm.controls['serialised'].setValue(1);
				} else {
					this.itemForm.controls['serialised'].setValue(0);
				}
			});
		}
	}


	ngOnChanges(changes: any) {
		if(changes.inputdata) {
			if(changes.inputdata.currentValue) {

				var name = '';

				// if(this.inputdata.mfg) {
				// 	name += this.inputdata?.mfg
				// }

				if(this.inputdata.name) {
					name += this.inputdata?.name
				}

				if(this.inputdata.size) {
					name += ' '+this.inputdata?.size
				}

				if(this.inputdata.size) {
					name += ' '+this.inputdata?.size
				}

				if(this.inputdata.loadindex) {
					name += ' '+this.inputdata?.loadindex
				}

				if(this.inputdata.speedrating) {
					name += ' '+this.inputdata?.speedrating
				}

				var barcode = '';
				if(this.inputdata.upc && this.inputdata.upc != '') {
					barcode = this.inputdata?.upc
				} else {
					barcode = this.inputdata?.itemcode
				}

				this.itemForm.controls['description'].setValue(name);
				this.itemForm.controls['longdescription'].setValue(name + ' ');
				this.itemForm.controls['barcode'].setValue(barcode);
				this.itemForm.controls['mfgpart'].setValue(this.inputdata?.itemcode);
				this.itemForm.controls['sort'].setValue(this.inputdata?.size);

			}
		}
	}

	displayFn(value): string {
		let f = this.allglclasses.filter(a => {
			return value == a.code
		})[0];
		console.log(f)
		return (f) ? f.code+' - '+f.name : '';
	}
}
