<div class="main-content">
	<div class="container-fluid">
		<div class="col-md-12">
			<div class="card pr-0 pl-0">
				<div class="card-header card-header-{{color}}-3">
					<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Purchase Receiving</h4>
					<p class="card-category"></p>
					<div class="messages row">
					</div>
				</div>
				<div class="card-body" >
					<mat-tab-group #tabPanel mat-align-tabs="start" color="accent" [selectedIndex]="pageindex" class="no-overflow">
						<mat-tab label="Item Scan">
							<ng-template matTabContent class="no-overflow">
								<div class="col-12 m-0 p-0 ">
								<mat-form-field appearance="standard" class="ml-2 mr-2">
									<mat-label>Vendor</mat-label>
									<mat-select [formControl]="vendor" (selectionChange)="filterItems()">
										<mat-option *ngFor="let v of vendors" [value]="v.supplierid">
											{{v.supplierid}} - {{v.suppname}}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
								<div class="col-12 m-0 p-0 ">
									<mat-form-field appearance="standard" class="ml-2 mr-2">
										<mat-label>Scan An Item</mat-label>
										<input #itemCodeRef matInput class="text-right" placeholder="Scan an Item" [formControl]="itemCode" (input)="itemScan()">
									</mat-form-field>
								</div>
							</ng-template>
						</mat-tab>
						<mat-tab label="P.O. Receiving" class="no-overflow">
							<ng-container>
								<form [formGroup]="itemScanForm" class="no-overflow">
									<div class="row " >
										<!--<div class="col-12 col-md-3 ml-0 mr-0">
											<app-vendor-auto-complete (vendor_selected)="filterPoByVendor($event)"></app-vendor-auto-complete>
										</div>
										-->
										<div class="col-12 col-md-4 ml-0 mr-0">
											<mat-form-field appearance="standard" class="ml-2 mr-2">
												<mat-label>Journal</mat-label>
												<mat-select formControlName="journal">
													<mat-option *ngFor="let j of journals" [value]="j">
														#{{j.journal_no }}: {{j.journal_date | date }} {{j.desc1 }}</mat-option>
												</mat-select>
												<span matPrefix (click)="closeOpenNewJournal()">
													<small><i class="material-icons">remove</i></small>
												</span>
											</mat-form-field>
										</div>
										<div class="col-12 col-md-4 ml-0 mr-0">


											<mat-form-field appearance="standard" class="ml-2 mr-2">
												<mat-label>Select A Purchase Order</mat-label>
												<input type="text" placeholder="Select Purchase Order" aria-label="Select Purchase Order" matInput [formControl]="poSearch" [matAutocomplete]="pos">
												<mat-autocomplete #pos="matAutocomplete">
													<mat-option (onSelectionChange)="loadSelectedOrder(o.orderno)" *ngFor="let o of purchaseorders" [value]="o.orderno">
														{{ o.orderno }} {{ o.statusname }} {{ o.suppname }}
													</mat-option>
												</mat-autocomplete>
												<button matSuffix mat-icon-button aria-label="Clear" (click)="loadPos()">
													<mat-icon>close</mat-icon>
												</button>
											</mat-form-field>
										</div>
										<div class="col-12 col-md-4 ml-0 mr-0">
											<mat-form-field appearance="standard" class="ml-2 mr-2">
												<mat-label>Scan An Item</mat-label>
												<input type="text" placeholder="Scan an Item" aria-label="Scan an Item" matInput formControlName="keywords" (input)="itemSearch()">
											</mat-form-field>
										</div>
									</div>
								</form>
							</ng-container>
						</mat-tab>
					</mat-tab-group>
				<div class="row mt-2 mb-2" *ngIf="!mustpick.length">
					<div class="col-12 text-center">
						<span class="alert alert-succes">No Items To Receive, Select a Purchase Order or Scan an Item</span>
					</div>
				</div>
				<div class="row border-bottom mt-2" *ngIf="mustpick.length">
					<div class="col-12 text-right">
						<button mat-raised-button color="accent" aria-label="complete receviing" (click)="completeReceiving()">
							<mat-icon>save</mat-icon> Complete Receiving
						</button>
					</div>
					<div class="col-6 text-left mr-0 pr-0"><b>ITEM/PO</b></div>
					<div class="col-6 text-right ml-0 text-nowrap"><b>DESC/PICK/BIN</b></div>

				</div>
				<ng-container *ngFor="let item of mustpick">
					<div class="row border-bottom pickitem mt-2" >
						<div class="col-12 col-md-6 mt-2 mr-auto ml-0">
							<span class="spec-label-large">ITEM</span>
							&nbsp;&nbsp;{{ item.stockid }}
							<br />
							<span class="spec-label-large">DESC:</span>&nbsp;&nbsp;
							{{ item.description }}
							<br />
							<span class="spec-label-large">QOH</span>&nbsp;&nbsp;
							({{ item.totalqoh }})
							&nbsp;&nbsp;
							<span class="spec-label-large">PO#</span>&nbsp;&nbsp;
							{{ item.orderno }}
							<ng-container *ngIf="item.commited">
								<span class="spec-label-large">COMMITED:</span>&nbsp;&nbsp;
								{{ item.commited.sales_orderno }} x {{ item.commited.quantiy_order }}
							</ng-container>
						</div>
						<div class="col-12 col-md-6 text-right ml-auto mr-0 ">
							<div class="row m-0 p-0 ">
								<div class="col-md-1 " [ngClass]="{'alert-success': item.current_movement > 0, 'alert-danger': item.current_movement < 0 }">
									<mat-icon class="mt-4" *ngIf="item.current_movement > 0">add</mat-icon>
									<mat-icon class="mt-4" *ngIf="item.current_movement < 0">remove</mat-icon>
								</div>
								<div class="col-md-1 mt-3 mr-2" >
									<button mat-icon-button aria-label="split bin" (click)="addBin(item)">
										<mat-icon>call_split</mat-icon>
									</button>
								</div>
								<div class="col-md-9">

									TOTAL: <b>{{ item.quantityrecd }}</b> / <b>{{ item.quantityord }}</b>

									<div class="row m-0 p-0" *ngFor="let b of item.bins">
										<div class="col-md-4 mt-2"  >

											<mat-form-field class="full-width" appearance="standard">
												<mat-label>Bin</mat-label>
												<input matInput type="text" placeholder="Bin" [(value)]="b.bin" (input)="setBin($event.target.value,b)">
												<mat-error></mat-error>
												<span matSuffix *ngIf="item.bins.length > 1">
													<button mat-icon-button aria-label="remove bin" (click)="removeBin(b, item)" *ngIf="item.bins.length > 1">
														<mat-icon>remove</mat-icon>
													</button>
												</span>

											</mat-form-field>
										</div>

										<div class="col-md-8 mt-auto mb-auto" >
											<span class="" [ngClass]="item.quantityrecd < item.quantityord ? 'text-danger': 'text-success'">
												<mat-form-field class="full-width" appearance="standard">

													<span matPrefix >
														<button class=" btn btn-just-icon btn-white btn-fab btn-round btn-sm" (click)="removePick(b, item)">
															<small>
																<i class="material-icons">remove</i>
															</small>
														</button>
													</span>

													<input matInput type="text" class="text-right" type="text" [(value)]="b.qty" [ngClass]="item.quantityrecd < item.quantityord ? 'text-danger': 'text-success'" (blur)="setQty($event, item, b)">
													<span matSuffix> / <b>{{ item.quantityord }}</b></span>
													<span matSuffix>
														<button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-2" (click)="addPick(b, item)">
															<small><i class="material-icons">add</i></small>
														</button>
													</span>


													<span matSuffix>

														<mat-checkbox [checked]='item.quantityrecd == item.quantityord' (change)="setCheckValue($event, item)" class="mat-checkbox mat-accent " data-item="{{ item.podetailitem }}"></mat-checkbox>
													</span>

													<mat-error></mat-error>
												</mat-form-field>
											</span>


										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ng-container>

			</div>
		</div>
	</div>
</div>
</div>
