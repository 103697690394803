<ng-template #popTemplate let-modal>
	<div class="modal-body">
		<div class="form-group">
			<div class="row">
				<div class="col-12 text-center" *ngIf="snoozeForm">
					<form [formGroup]="snoozeForm">
						<label for="numberofdays">Snoozing (Days): {{ snoozeorder }}</label>
						<div class="row">
							<div class="col-4">
								<mat-form-field class="example-full-width" appearance="fill">
									<mat-label>Initials</mat-label>
									<input matInput type="text" placeholder="Initials" [value]="" name="initials" id="initials" formControlName="initials">
									<mat-error>
										<span *ngIf="!snoozeForm.get('initials').valid && snoozeForm.get('initials').touched">
											Enter your Initials!</span>
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col-8">
								<mat-form-field class="full-width" appearance="fill">
									<mat-label>Reason For Snooze</mat-label>
									<input matInput #message maxlength="128" placeholder="Reason for snooze ..." name="message" id="message" formControlName="message" [value]="">
									<mat-hint align="end">{{message.value.length}} / 128 </mat-hint>
									<mat-error>
										<span *ngIf="!snoozeForm.get('message').valid && snoozeForm.get('message').touched">
											Enter a reason!
										</span>
									</mat-error>
								</mat-form-field>
							</div>
						</div>
					</form>

					<div class="row">
						<mat-form-field color="accent" appearance="fill" class="col-5">
							<mat-label>Pick a Day</mat-label>
							<input matInput [matDatepicker]="picker1" (dateInput)="dateChanged($event)" (dateChange)="dateChanged($event)" [(ngModel)]="initaldate">
							<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
							<mat-datepicker #picker1></mat-datepicker>
						</mat-form-field>

						<h6 class="col-2 ml-0 mr-0"><b>OR</b></h6>

						<mat-form-field class="example-full-width" appearance="fill" class="col-5">
							<mat-label>Number Of Days</mat-label>
							<input matInput type="number" placeholder="Number Of Days" [value]="numberofdays" name="numberofdays" id="numberofdays" (input)="setSnooze($event)" restrict-to="[0-9]">
						</mat-form-field>
					</div>

					<button class="btn btn-sm btn-rose" (click)="snooze(snoozeorder)">
						<i class="material-icons">snooze</i>
					</button>

				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #orderDetails let-modal>
	<div class="modal-header">
		<div class="modal-title row m-0 p-0" id="modal-title">
			<h4>Order# {{orderdetails.header.orderno}}</h4>
		</div>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="orderdetails">
			<div class="" *ngIf="orderdetails.dispatch_details && orderdetails.dispatch_details.status_id == '29'">

				<div #mapDiv class="w-100" [ngClass]="{'d-none': !orderdetails.dispatch_details.nextlast}">
					EST: {{orderdetails.dispatch_details.nextlast }}
					<div class="w-100" id="map">
					</div>
				</div>
			</div>

			<div class="row border-bottom">
				<div class="col-md-3 text-center">
					<h5>Ship Via: {{ orderdetails.header.shippername }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Date Wanted: {{ orderdetails.header.datewanted }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Entered By: {{ orderdetails.header.enteredby }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Created: {{ orderdetails.header.date_created }}</h5>
				</div>
			</div>
			<div class="row mt-2 mb-2 border-bottom">
				<div class="col-md-3 ">
					<h5 class="mb-0">Ship To:</h5>
					<span [innerHTML]="orderdetails.header.shipto"></span>
				</div>
				<div class="col-md-3">
					<h5 class="mb-0">Phone:</h5>
					{{orderdetails.header.contactphone}}
					<ng-container *ngIf="orderdetails.header.contactemail && orderdetails.header.contactemail != ''">
						<h5 class="mb-0">Email:</h5>
						{{orderdetails.header.contactemail}}
					</ng-container>
				</div>
				<div class="col-md-3">
					<h5>Notes:</h5>
					{{orderdetails.header.comments}}
				</div>
				<div class="col-md-3">
					<h5>History: </h5>
					<ng-container *ngIf="orderdetails.dispatch_details.dispatch_id">
						<ul>
							<li *ngIf="orderdetails.dispatch_details.truck_name">
								ShipVia: {{ orderdetails.dispatch_details.truck_name }}<br>
								{{ orderdetails.dispatch_details.driveruserid }}
							</li>
							<li *ngIf="orderdetails.dispatch_details.datecreated !='0000-00-00 00:00:00'">Dispatched:
								{{ orderdetails.dispatch_details.datecreated | date:'shortDate' }}
								{{ orderdetails.dispatch_details.datecreated | date:'shortTime' }}

							</li>
							<li *ngIf="orderdetails.dispatch_details.delivery_date !='0000-00-00 00:00:00'">Delivered:
								{{ orderdetails.dispatch_details.delivery_date | date:'shortDate' }}
								{{ orderdetails.dispatch_details.delivery_date | date:'shortTime' }}
							</li>
						</ul>
					</ng-container>
					<ng-container *ngIf="!orderdetails.dispatch_details.dispatch_id">
						N/A
					</ng-container>
				</div>
			</div>
			<div class="table">
				<table class="table table-sm fs-bigger">
					<tr>
						<th>ITEM</th>
						<th>DESC</th>
						<th class="text-center">QOH</th>
						<th class="text-center">ORDERED</th>
						<th class="text-center">INVOICED</th>
						<th class="text-center">OUTSTANDING</th>
						<th class="text-right">PRICE</th>
						<th class="text-right">DISCOUNT</th>
						<th class="text-right">TOTAL</th>
					</tr>
					<tr *ngFor="let item of orderdetails.details">

						<td><a [routerLink]="'/inventory/view/'+item.stkcode" (click)="modal.dismiss('route change')">{{item.stkcode}}</a></td>
						<td>{{item.description}}</td>
						<td class="text-center">{{item.qoh}}</td>
						<td class="text-center">{{item.ordered}}</td>
						<td class="text-center">{{item.qtyinvoiced}}</td>
						<td class="text-center">{{item.ordered - item.qtyinvoiced}}</td>
						<td class="text-right">{{item.unitprice | currency}}</td>
						<td class="text-right">{{item.discountpercent | percent }}</td>
						<td class="text-right">{{item.discountprice * item.ordered | currency}}</td>
					</tr>
					<tr *ngFor="let t of orderdetails.saved_totals">
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center"></td>
						<td class="text-center"></td>
						<td class="text-center"></td>
						<td class="text-right">{{ t.title }}:</td>
						<td class="text-right">{{t.text | currency}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #purchaseOrderDetails let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-title" *ngIf="podetails">
			<a [routerLink]="'/vendors/'+podetails.poheader.supplierno">{{ podetails.poheader.supplierno }}</a> Purchase Order
		</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<mat-tab-group #tabPanel mat-align-tabs="start" color="accent" class="no-overflow">
			<mat-tab label="Purchase Order">
				<div class="mb-3" *ngIf="podetails">
					<h4 class="mt-3">PO #{{ podetails.poheader.orderno }}</h4>
					<div class="table">
						<table class="table table-sm">
							<tr>
								<td>
									{{podetails.poheader.contact}}
									{{podetails.poheader.address}}<br>
									{{podetails.poheader.suppdeladdress1}}<br>
									{{podetails.poheader.suppdeladdress2}}<br>
									{{podetails.poheader.suppdeladdress3}}, {{podetails.poheader.suppdeladdress4}} {{podetails.poheader.suppdeladdress5}}
								</td>
								<td>
									{{podetails.poheader.deladd1}}<br>
									<span *ngIf="podetails.poheader.deladd2 != ''">{{podetails.poheader.deladd2}}<br></span>
									{{podetails.poheader.deladd3}}, {{podetails.poheader.deladd4}} {{podetails.poheader.deladd5}}
								</td>
							</tr>
						</table>
						<div class="card">
							<div class="card-body" [innerHTML]="podetails.poheader.stat_comment"></div>
						</div>
						<table class="table table-sm ">
							<tr>
								<th>ITEM</th>
								<th>DESC</th>
								<th class="text-center">QTY</th>
								<th class="text-center">RCVD</th>
								<th class="text-center">PEND</th>
								<th class="text-right">PRICE</th>
								<th class="text-right">TOTAL</th>
							</tr>
							<tr *ngFor="let item of podetails.polines">
								<td><a [routerLink]="'/inventory/view/'+item.itemcode" (click)="modal.dismiss('route change')">{{item.itemcode}}</a></td>
								<td>{{item.itemdescription}}</td>
								<td class="text-center">{{item.quantityord}}</td>
								<td class="text-center">{{item.quantityrecd}}</td>
								<td class="text-center">{{item.quantityord - item.quantityrecd}}</td>
								<td class="text-right">{{item.unitprice | currency}}</td>
								<td class="text-right">{{item.unitprice * item.quantityord | currency}}</td>
							</tr>
						</table>
					</div>
				</div>
			</mat-tab>
			<mat-tab label="Receiving Details">
				<ng-container *ngIf="podetails.receiving.length">
					<table class="table table-fixed table-sm">
						<tr>
							<th>GRN</th>
							<th>Order#</th>
							<th>Item#</th>
							<th>Description</th>
							<th>Received</th>
							<th>Invoiced</th>
							<th>Date</th>
						</tr>
						<tr *ngFor="let r of podetails.receiving">
							<td>{{ r.grnno }}</td>
							<td>{{ r.poorderno }}</td>
							<td>{{ r.itemcode }}</td>
							<td>{{ r.itemdescription }}</td>
							<td>{{ r.qtyrecd }}</td>
							<td>{{ r.quantityinv }}</td>
							<td>{{ r.update_date }}</td>
						</tr>
					</table>
				</ng-container>
				<ng-container *ngIf="!podetails.receiving.length">
					No Receiving Details
				</ng-container>
			</mat-tab>
			<mat-tab label="Receiving Movements">
				<ng-container *ngIf="podetails.movements.length">
					<table class="table table-fixed table-sm">
						<tr>
							<th>Item#</th>
							<th>Location</th>
							<th>User</th>
							<th>Quantity</th>
							<th>QOH</th>
							<th>Date</th>
						</tr>
						<tr *ngFor="let r of podetails.movements">
							<td>{{ r.stockid }}</td>
							<td>{{ r.loccode }}</td>
							<td>{{ r.userid }}</td>
							<td>{{ r.qty }}</td>
							<td>{{ r.newqoh }}</td>
							<td>{{ r.trandate }}</td>
						</tr>
					</table>
				</ng-container>
				<ng-container *ngIf="!podetails.movements.length">
					No Movements
				</ng-container>
			</mat-tab>
		</mat-tab-group>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="row m-0 p-0">
	<div class="col-md-2">
		<mat-form-field appearance="standard">
			<mat-label>Warehouse</mat-label>
			<mat-select [formControl]="defaultlocation" (selectionChange)="filterWarehouse($event.value)">
				<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
					{{ loc.locationname }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="col-md-2">
		<mat-form-field appearance="standard">
			<mat-label>ShipVia</mat-label>
			<mat-select [formControl]="shipvia" (selectionChange)="filterShipVia()">
				<mat-option [value]="''">All</mat-option>
				<mat-option *ngFor="let loc of shipvias" [value]="loc.shipper_id">
					{{ loc.shippername }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="col-md-2">
		<mat-form-field appearance="standard">
			<mat-label>Status</mat-label>
			<mat-select [formControl]="statusSearch" (selectionChange)="filterItems()">
				<mat-option [value]="''">All</mat-option>
				<mat-option *ngFor="let stat of order_statuses" [value]="stat.order_status_id">
					{{ stat.name }}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<!-- <mat-form-field appearance="standard">
				<mat-label>Status</mat-label>
				<input matInput [formControl]="searchInputItems" (input)="searchItems()" placeholder="Search..." class=:text-right>
				<span matSuffix>
					<button mat-icon-button (click)="searchItems()">
						<mat-icon>search</mat-icon>
					</button>
				</span>
			</mat-form-field> -->
	</div>
	<div class="col-md-3">
		<mat-form-field appearance="standard">
			<mat-label>Item Search</mat-label>
			<input matInput [formControl]="searchInputItems" (input)="searchItems()" placeholder="Search..." class="text-right">
			<span matSuffix>
				<button mat-icon-button (click)="searchItems()">
					<mat-icon>search</mat-icon>
				</button>
			</span>
		</mat-form-field>
	</div>
	<div class="col-md-3">
		<mat-form-field appearance="standard">
			<mat-label>Order Search</mat-label>
			<input matInput [formControl]="searchInput" (input)="onInput(searchInput.value)" placeholder="Search..." class="text-right">
			<span matSuffix>
				<button mat-icon-button (click)="search()">
					<mat-icon>search</mat-icon>
				</button>
			</span>
		</mat-form-field>
	</div>
</div>

<div class="table table-responsive text-center" *ngIf="!openorders">
	<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
		<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
		</circle>
	</svg>
</div>
<div class="row" *ngIf="!openOrderData">
	<div class="col-md-12">
		<h4 class="mt-3 text-center">No Open Quotes</h4>
	</div>
</div>
<div class="table-container w-100">
	<table mat-table [dataSource]="openorders" matSort (matSortChange)="announceSortChange($event)" [ngClass]="openOrderData ? 'w-100 smallertable': 'd-none'">

		<ng-container matColumnDef="orderno">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Quote#</th>
			<td mat-cell *matCellDef="let tran">
				<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">

					<div class="bg-danger text-white w-100" *ngIf="tran.invoiced">
						ORDER HAS BEEN PAID
					</div>

					<button mat-menu-item (click)="viewOrder(tran.orderno, orderDetails)" *ngIf="!tran.invoiced">
						<mat-icon>visibility</mat-icon>
						<span>View</span>
					</button>

					<button mat-menu-item (click)="viewInvoicedOrder(tran.orderno, orderDetails)" *ngIf="tran.invoiced">
						<mat-icon>visibility</mat-icon>
						<span>View</span>
					</button>
					<a href="{{invoicelink}}{{tran.orderno}}" mat-menu-item>
						<mat-icon>print</mat-icon>
						<span>Customer Copy</span>
					</a>
					<mat-divider></mat-divider>
					<button mat-menu-item (click)="loadOrder(tran.orderno)" *ngIf="tran.orderstatusid !='50' && !tran.invoiced">
						<mat-icon>edit</mat-icon>
						<span>Edit Order</span>
					</button>
					<mat-divider></mat-divider>
					<a href="{{pickinglink}}{{tran.orderno}}" mat-menu-item>
						<mat-icon>print</mat-icon>
						<span>Picking PDF</span>
					</a>
					<button mat-menu-item [routerLink]="'/orders/pick/'+tran.orderno" *ngIf="tran.orderstatusid !='50' && !tran.invoiced">
						<mat-icon>get_app</mat-icon>
						<span>Pick</span>
					</button>

					<button mat-menu-item (click)="invoiceOrder(tran.orderno)" *ngIf="tran.orderstatusid !='50' && !tran.invoiced">
						<mat-icon>description</mat-icon>
						<span> Invoice </span>
					</button>
					<mat-divider></mat-divider>
					<button mat-menu-item (click)="updatePick(tran.orderno)" *ngIf="tran.orderstatusid !='50' && !tran.invoiced">
						<mat-icon>done</mat-icon>
						<span>Set Picked</span>
					</button>
					<button mat-menu-item (click)="setSnoozeOrder(tran.orderno)" *ngIf="tran.snoozed != '1' && tran.orderstatusid !='50' && !tran.invoiced">
						<mat-icon>snoozed</mat-icon>
						<span>Snooze</span>
					</button>
					<button mat-menu-item (click)="removeSnooze(tran.orderno)" *ngIf="tran.snoozed == '1' && tran.orderstatusid !='50' && !tran.invoiced">
						<mat-icon>notifications_active</mat-icon>
						<span>Remove Snooze</span>
					</button>
					<!-- <button mat-menu-item (click)="setDelivered(tran.orderno)">
							<mat-icon>local_shipping</mat-icon>
							<span>Set Delivered</span>
						</button> -->
					<button mat-menu-item (click)="cancelOrder(tran.orderno) "  *ngIf="tran.orderstatusid !='50' && !tran.invoiced">
						<mat-icon>remove</mat-icon>
						<span>Cancel</span>
					</button>
				</mat-menu>
			</td>
		</ng-container>
		<ng-container matColumnDef="statusname">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Order#/Status</th>
			<td mat-cell *matCellDef="let tran">


				<!--
						<i class="fa fa-eye" (click)="viewStatus(tran)"></i>
					&nbsp;
					-->
				<b>{{ tran.orderno }}</b>

				<br>
				<span class="status-{{ tran.orderstatusid }}">
					{{ tran.statusname }}
				</span>
				&nbsp;&nbsp;<i class="fa fa-edit" (click)="toggleEdit(tran)" *ngIf="tran.orderstatusid !='50'"></i>

				<ng-container *ngIf="tran.edit">
					<mat-form-field appearance="standard">
						<mat-label>Status</mat-label>
						<mat-select [formControl]="order_status_edit">
							<mat-option *ngFor="let s of order_statuses" [value]="s.order_status_id">
								{{ s.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button class="btn btn-sm btn-danger" (click)="saveStatusUpdate(tran)">
						<i class="fa fa-save"></i>
					</button>
				</ng-container>



			</td>
		</ng-container>
		<ng-container matColumnDef="deliverto">

			<th mat-header-cell *matHeaderCellDef mat-sort-header> Ship to</th>
			<td mat-cell *matCellDef="let tran">
				<div [innerHTML]="tran.shipto + '<pre>\n</pre>' + tran.details.deliverto" (click)="viewCustomer(tran.debtorno)"></div>

			</td>
		</ng-container>
		<ng-container matColumnDef="datecreated">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Wanted/Created</th>
			<td mat-cell *matCellDef="let tran" class="wanted">
				Wanted: {{ tran.deliverydate }} <br>
				Created: {{ tran.date_created | date:'shortDate' }} {{ tran.date_created | date:'shortTime' }}<br>
				<a href="tel://{{ tran.phoneno }}">{{ tran.phoneno }}</a>
			</td>
		</ng-container>

		<!-- <ng-container matColumnDef="phoneno">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Phone#/Email</th>
				<td mat-cell *matCellDef="let tran" class="smallish">

					<a href="mailto://{{ tran.email }}">{{ tran.email }}</a>
				</td>
			</ng-container> -->
		<ng-container matColumnDef="ponumber">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Customer PO#</th>
			<td mat-cell *matCellDef="let tran">{{ tran.ponumber }}</td>
		</ng-container>
		<ng-container matColumnDef="shipvia">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> ShipVia</th>
			<td mat-cell *matCellDef="let tran">{{ getShipVia(tran.shipvia) }}</td>
		</ng-container>
		<ng-container matColumnDef="haspo">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Purchase#</th>
			<td mat-cell *matCellDef="let tran">
				<span [ngClass]="{'po': tran.haspo.length }" *ngFor="let p of tran.haspo">
					<span (click)="viewPurchaseOrder(p.purchase_orderno)" class="pointer">{{ p.purchase_orderno }}</span>
				</span>
				<span *ngIf="tran.suppliers" [ngClass]="{'po': tran.haspo.length }"><br>{{ tran.suppliers }}</span>

			</td>
		</ng-container>
		<ng-container matColumnDef="notes">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Notes</th>
			<td mat-cell *matCellDef="let tran">
				<div [innerHTML]="noNewLines(tran.notes)" class=" comments"></div>
			</td>
		</ng-container>
		<ng-container matColumnDef="comments">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Comments</th>
			<td mat-cell *matCellDef="let tran" >
				<div [innerHTML]="tran.comments" class=" comments"></div>
				<div *ngIf="tran.snoozed == '1' ">
					{{tran.snoozed_details.date_added | date: 'MM/dd/yyyy' }}
					<b>{{tran.snoozed_details.initials}}:</b> {{tran.snoozed_details.comments}}
				</div>

			</td>
		</ng-container>


		<!-- <ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
				<td mat-cell *matCellDef="let tran">{{ tran.email }}</td>
			</ng-container> -->

		<ng-container matColumnDef="takenby">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Taken By </th>
			<td mat-cell *matCellDef="let tran">{{ tran.takenby }}</td>
		</ng-container>
		<ng-container matColumnDef="pickprinted">
			<th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> Printed</th>

			<td mat-cell *matCellDef="let tran" class="text-center smallest">
				<ng-container *ngIf="tran.snoozed == '1' ">
					<mat-icon>notifications_active</mat-icon>
				</ng-container>
				<ng-container *ngIf="tran.snoozed != '1' ">
					<ng-container *ngIf="tran.printedpackingslip == 0">
						<span class="text-danger">N</span>
					</ng-container>
					<ng-container *ngIf="tran.printedpackingslip == 1">
						Y
					</ng-container>
					<ng-container *ngIf="tran.printedpackingslip == ''">

					</ng-container>
				</ng-container>
			</td>

		</ng-container>
		<ng-container matColumnDef="total">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Total</th>
			<td mat-cell *matCellDef="let tran">{{ tran.total | currency}}</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="headercolumns;sticky: true;"></tr>
		<tr mat-row *matRowDef="let row; columns: headercolumns"></tr>
	</table>
	<mat-paginator [ngClass]="openOrderData ? '': 'd-none'" [pageSizeOptions]="[100, 500, 1000]" showFirstLastButtons aria-label="select page">
	</mat-paginator>
</div>
