import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators , ValidationErrors, ValidatorFn, AbstractControl} from '@angular/forms';
import { CustomerService } from '../../services/customer.service';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { DispatchService } from '../../services/dispatch.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatStepper } from '@angular/material/stepper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-customer-create',
	templateUrl: './customer-create.component.html',
	styleUrls: ['./customer-create.component.scss']
})
export class CustomerCreateComponent implements OnInit {
	@ViewChild('stepper', {static:true}) stepper: MatStepper;
	@ViewChild("detailsModal") detailsModalEle: ElementRef;
	debtorFromGroup: UntypedFormGroup;
	branchFormGroup: UntypedFormGroup;
	isEditable = false;
	terms: any = [];
	customertypes: any = [];
	holdreasons: any = [];
	currencies: any = [];
	salestypes: any = [];
	zones: any = [];
	taxes: any = [];
	locations: any = [];
	areas: any = [];
	salesman: any = [];
	shipvia: any = [];
	tirefee: boolean = true;
	disabledbranch: boolean = false;
	customer_created: any = [];
	paymentmethods: any = [];
	phone: any = '';
	place: any = false;
	routes: any = [];
	//todo make this part of configurations or define company variables on login
	defaults = {
		country: 'United States',
		region: 'NY',
		holdreason: '1',
		currency: 'USD',
		terms: 'COD',
		salestype: 'A',
		customertype: '1',
		discount: 0,
		credit: 0,
		email_invoice: false,
		requirepo: false,
		email_statement: false,
		interest: 3,
		warehouse: '01',
		shipvia: '1',
		area: '01',
		territory: '01',
		salesman: '01',
		disabled: false,
		tirefee: true,
		taxauth: '119',
		branchcode: '00',
		default_paymentmehod: '2',
	};
	color: string = '';
	config: any = false;
	index: number = 1;
	duplicatewarning: any;
	modalAction: boolean;


	constructor(public dispatchService: DispatchService, public router: Router, private fb: UntypedFormBuilder, public globalsService: GlobalsService, public customerService: CustomerService, public location: Location, private globalSearchService: GlobalSearchService, private modalService: NgbModal) {
		this.globalSearchService.mesagesallowed.next(true);
	}


	ngOnInit(): void {

		this.globalSearchService.configsubscription.subscribe(c => {
			this.config = c;
		});

		this.color = this.globalSearchService.getColor();

		this.dispatchService.getRoutes().subscribe((r:any) => {
			this.routes = r
		});

		this.globalSearchService.taxgroups.subscribe(async (results: any) => {
			this.taxes = results;
		});

		this.globalSearchService.salespeople.subscribe(async (results: any) => {
			this.salesman = results;
		});

		this.globalSearchService.shipvia.subscribe(async (results: any) => {
			this.shipvia = results;
		});

		this.globalSearchService.terms.subscribe(async (results: any) => {
			this.terms = results;
		});

		this.globalSearchService.zones.subscribe((results: any) => {
			this.zones = results;
		});

		this.globalSearchService.holdreasons.subscribe(async (results: any) => {
			this.holdreasons = results;
		});

		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
		});

		this.globalSearchService.customertypes.subscribe(details => {
			this.customertypes = details;
		});

		this.globalSearchService.currencies.subscribe(details => {
			this.currencies = details;
		});

		this.globalSearchService.salestypes.subscribe(details => {
			this.salestypes = details;
		});

		this.globalSearchService.locations.subscribe(details => {
			this.locations = details;
		});

		this.globalSearchService.areas.subscribe(details => {
			this.areas = details;
		});

		this.globalsService.getPaymentMethods().subscribe(details => {
			this.paymentmethods = details;
		});



		this.debtorFromGroup = this.fb.group({
			name: ['', [Validators.required]],
			address1: ['', [Validators.required]],
			address2: ['', []],
			address3: ['', [Validators.required]],
			address4: [this.defaults.region, [Validators.required]],
			address5: ['', [Validators.required]],
			address6: [this.defaults.country, [Validators.required]],
			creditlimit: [this.defaults.credit, [Validators.required]],
			discount: [this.defaults.discount, [Validators.pattern('[0-9]*')]],
			pymtdiscount: [this.defaults.discount, [Validators.pattern('[0-9]*')]],
			arintrate: [this.defaults.interest],
			paymentterms: [this.defaults.terms, [Validators.required]],
			salestype: [this.defaults.salestype, [Validators.required]],
			typeid: [this.defaults.customertype, [Validators.required]],
			holdreason: [this.defaults.holdreason, [Validators.required]],

			currcode: [this.defaults.currency, [Validators.required]],
			emailstatement: [this.defaults.email_statement, [Validators.required]],
			statementemailaddress: ['', [Validators.email]],
			ediinvoices: [this.defaults.email_invoice, [Validators.required]],
			invoiceemailaddress: ['', [Validators.email]],
			customerpoline: [this.defaults.requirepo, [Validators.required]],

		});

		this.debtorFromGroup.get('paymentterms').valueChanges.subscribe(newValue => {
			let termcode = this.terms.filter( t=> {
				return t.termsindicator == newValue
			})[0];
			if(termcode.collect_payment == '1') {
				this.debtorFromGroup.get('creditlimit').setValue('0.00')
			}
		});

		this.debtorFromGroup.get('emailstatement').valueChanges.subscribe( (newValue: any) => {
			if(newValue) {
				this.debtorFromGroup.get('statementemailaddress').setValidators([Validators.required])
				this.debtorFromGroup.get('statementemailaddress').updateValueAndValidity();
			} else {
				this.debtorFromGroup.get('statementemailaddress').clearValidators()
				this.debtorFromGroup.get('statementemailaddress').updateValueAndValidity();
			}
		})

		this.debtorFromGroup.get('ediinvoices').valueChanges.subscribe( (newValue: any) => {
			if(newValue) {
				this.debtorFromGroup.get('invoiceemailaddress').setValidators([Validators.required])
				this.debtorFromGroup.get('invoiceemailaddress').updateValueAndValidity();
			} else {
				this.debtorFromGroup.get('invoiceemailaddress').clearValidators()
				this.debtorFromGroup.get('invoiceemailaddress').updateValueAndValidity();
			}
		})

		this.updateBranch();
	}

	selectCustomer(event: any) {

		this.customerService.getDebtor(event.debtorno).subscribe(async (results: any) => {

			this.debtorFromGroup.get('name').setValue(results.customer.name)
			this.debtorFromGroup.get('address1').setValue(results.customer.address1)
			this.debtorFromGroup.get('address2').setValue(results.customer.address2)
			this.debtorFromGroup.get('address3').setValue(results.customer.address3)
			this.debtorFromGroup.get('address4').setValue(results.customer.address4)
			this.debtorFromGroup.get('address5').setValue(results.customer.address5)

			this.debtorFromGroup.get('creditlimit').setValue(results.customer.creditlimit)
			this.debtorFromGroup.get('customerpoline').setValue(results.customer.customerpoline)

			this.branchFormGroup.get('brname').setValue(results.customer.brname)
			this.branchFormGroup.get('braddress1').setValue(results.customer.braddress1)
			this.branchFormGroup.get('braddress2').setValue(results.customer.braddress2)
			this.branchFormGroup.get('braddress3').setValue(results.customer.braddress3)
			this.branchFormGroup.get('braddress4').setValue(results.customer.braddress4)
			this.branchFormGroup.get('braddress5').setValue(results.customer.braddress5)
			this.branchFormGroup.get('contactname').setValue(results.customer.contactname)

		});
	}

	updateBranch() {


		this.branchFormGroup = this.fb.group({
			branchcode: [this.defaults.branchcode, [Validators.required]],
			//debtorno: [''],
			brname: [this.debtorFromGroup.controls.name.value, [Validators.required]],
			contactname: ['', [Validators.required]],
			braddress1: [this.debtorFromGroup.controls.address1.value, [Validators.required]],
			braddress2: [this.debtorFromGroup.controls.address2.value],
			braddress3: [this.debtorFromGroup.controls.address3.value, [Validators.required]],
			braddress4: [this.debtorFromGroup.controls.address4.value, [Validators.required]],
			braddress5: [this.debtorFromGroup.controls.address5.value, [Validators.required]],
			braddress6: [this.debtorFromGroup.controls.address6.value, [Validators.required]],
			//estdeliverydays: [''],
			phoneno: [this.phone,[Validators.required]],
			email: ['', [Validators.email, Validators.required]],
			faxno: [''],
			disabletrans: [this.defaults.disabled],
			tirefee: [this.defaults.tirefee],
			defaultlocation: [this.defaults.warehouse, [Validators.required]],
			defaultshipvia: [this.defaults.shipvia, [Validators.required]],
			area: [this.defaults.area, [Validators.required]],
			salesman: [this.defaults.salesman, [Validators.required]],
			tax_ref: [''],
			taxgroupid: [this.defaults.taxauth, [Validators.required]],
			specialinstructions: [''],
			username: [''],
			password: [''],
			lat: [],
			lng: [],
			bypass: false,
			route: [],
			default_paymentmehod: [this.defaults.default_paymentmehod, [Validators.required]],
			//territory_id: [this.defaults.area, [Validators.required]],
			//pricelevel: [''],

		});

		if (this.place) {
			this.branchFormGroup.get('lat').setValue(this.place.geometry.location.lat())
			this.branchFormGroup.get('lng').setValue(this.place.geometry.location.lng())
		}


		this.branchFormGroup.markAllAsTouched();
	}

	back(): void {
		this.location.back()
	}

	updateZones(input: any) {
	}

	getAddress(place: any) {
		this.place = place


		let componenets = place['address_components'];
		let name = place['name'];
		let phone = place['formatted_phone_number'];
		let website = place['website'];

		let address1 = this.parseComponentShortName(componenets, ['street_number', 'route']);
		let address2 = this.parseComponentShortName(componenets, ['subpremise']);
		let address3 = this.parseComponentShortName(componenets, ['locality']);
		let address4 = this.parseComponentShortName(componenets, ['administrative_area_level_1']);
		let address5 = this.parseComponentShortName(componenets, ['postal_code']);
		let address6 = this.parseComponentLongName(componenets, ['country']);

		this.debtorFromGroup.get('address1').setValue(address1)
		this.debtorFromGroup.get('address2').setValue(address2)
		this.debtorFromGroup.get('address3').setValue(address3)
		this.debtorFromGroup.get('address4').setValue(address4)
		this.debtorFromGroup.get('address5').setValue(address5)
		this.debtorFromGroup.get('address6').setValue(address6)

		this.branchFormGroup.get('braddress1').setValue(address1)
		this.branchFormGroup.get('braddress2').setValue(address2)
		this.branchFormGroup.get('braddress3').setValue(address3)
		this.branchFormGroup.get('braddress4').setValue(address4)
		this.branchFormGroup.get('braddress5').setValue(address5)
		this.branchFormGroup.get('braddress6').setValue(address6)

		this.branchFormGroup.get('lat').setValue(this.place.geometry.location.lat())
		this.branchFormGroup.get('lng').setValue(this.place.geometry.location.lng())


		if (name) {
			this.debtorFromGroup.get('name').setValue(name)
			this.branchFormGroup.get('brname').setValue(name)
		}

		if (phone) {
			this.phone = phone;
			this.branchFormGroup.get('phoneno').setValue(phone)
		}

	}

	private parseComponentShortName(componenets: any, types: any) {

		let result = [];
		componenets.forEach((comp: any) => {
			let compa = comp.types.map((local: any) => {
				if (types.includes(local)) {
					let adda = (comp.short_name) ? comp.short_name : '';
					result.push(adda);
				}
			});
		})
		return result.join(' ');
	}

	private parseComponentLongName(componenets: any, types: any) {
		let result = [];
		componenets.forEach((comp: any) => {
			let compa = comp.types.map((local: any) => {
				if (types.includes(local)) {
					let adda = (comp.long_name) ? comp.long_name : '';
					result.push(adda);
				}
			});
		})
		return result.join(' ');
	}


	createCustomer() {

		this.getFormValidationErrors(this.branchFormGroup);
		this.getFormValidationErrors(this.debtorFromGroup);
		if (this.branchFormGroup.valid && this.debtorFromGroup.valid) {
			this.customerService.createCustomer(this.debtorFromGroup.value, this.branchFormGroup.value).subscribe((results: any) => {
				if (results.success) {
					this.customer_created = results.customer;
					this.router.navigate(['./customers/view/' + results.customer.debtorno]);
				} else {
					let bypass = confirm(results.message);
					if (bypass) {
						this.branchFormGroup.get('bypass').setValue(true);
						this.customerService.createCustomer(this.debtorFromGroup.value, this.branchFormGroup.value).subscribe((results: any) => {
							this.customer_created = results.customer;
							this.router.navigate(['./customers/view/' + results.customer.debtorno]);
						});

					}

				}
			});
		}
	}

	getFormValidationErrors(form: any) {
		Object.keys(form.controls).forEach((key, index) => {
			const controlErrors: ValidationErrors = form.get(key).errors;

			if (controlErrors != null) {
				Object.keys(controlErrors).forEach(keyError => {
					//this.customerForm.controls[index].markAsTouched()
					form.markAllAsTouched();
					form.markAsUntouched()

					if(key == 'reference') {
						key = 'PO Number';
					}

					this.globalSearchService.showNotification(key + ' ' + keyError , 'danger', 'bottom', 'right')
				});
			}
		});
	}

	compareAddress() {
		if(!this.debtorFromGroup.valid){return;}
		let a1 = this.debtorFromGroup.get('address1').value;
		let a2 = this.debtorFromGroup.get('address2').value;
		let a3 = this.debtorFromGroup.get('address3').value;
		let cont = false;
		if(a1 != '' || a2 != '' || a3 != ''){
			let data = {add1: a1, add2: a2, add3: a3}
			this.customerService.addressCompare(data).subscribe((result)=>{
				if(result.length){
					this.duplicatewarning = result;
					this.modalService.open(this.detailsModalEle, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', keyboard: false}).result.then((result) => {
				}, (reason) => {

					if(this.modalAction){
						this.updateBranch();
						this.step();
						this.modalAction = undefined;
					} else{
						this.modalAction = undefined;
					}

				});
				}else{
					this.updateBranch();
					this.step();
				}
			});

		}

	}

	isMatch(entered, found){
		if (entered.toLowerCase() == found.toLowerCase()){
			return true;
		}
		return false;
	}

	userSelection(input: boolean){
		this.modalAction = input;
		this.modalService.dismissAll();
	}

	step(){
		document.getElementById('stepperNext').click();
	}




}
