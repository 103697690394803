<ng-template #binSelect let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Select Bins</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i
				class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
	<div  class="row" >
		<div class="col-md-3">
		Item Code
		</div>
		<div class="col-md-3">
		Bin
		</div>
		<div class="col-md-2">
		Total Needed
		</div>
		<div class="col-md-2">
		Qty In Bin
		</div>
		<div class="col-md-2">
		Add
		</div>
	</div>
	<div  class="row" *ngFor="let bin of needbin">
		<div class="col-md-3">
		{{bin.stockid}}
		</div>
		<div class="col-md-3">
		{{bin.bin}}
		</div>
		<div class="col-md-2">
		{{bin.needquantity}}
		</div>
		<div class="col-md-2">
		{{bin.binmax}}
		</div>
		<div class="col-md-2">
		<mat-form-field appearance="standard">
			<mat-label>Qty</mat-label>
			<span matPrefix>#</span>
			<input matInput tabindex="{{ i + 2 }}" type="number" value="{{ bin.quantity | number }}" autofocus (input)="updateBinQuantity($event, bin )" class="text-right">
		</mat-form-field>
		</div>
	</div>
	</div>
	<div class="row">
		<div class="col-md-10">
		</div>
		<div class="col-md-2">
		<button class="btn btn-success" (click)="addBins(needbin)"> Add Items To Transfer </button>
		</div>
	</div>
</ng-template>
<div class="main-content">
<div class="row">
<div class="col-md-9">
<h3>Create Transfer </h3>
</div>
<div class="col-md-3">
<button class="btn btn-success" (click)="submitTransfer()">
			Submit Transfer
			</button>
</div>
</div>
<div class="container-fluid">
		<form [formGroup]="transferForm">
		<div class="row">		
			<div class="col-md-6 card-group">
				<div class="card m-0">
				<div class="card-header">
					From Location: <mat-form-field appearance="standard">
												<mat-label>From Location</mat-label>
												<mat-select formControlName="fromstkloc" (selectionChange)="updateHeader">
												<mat-option value="">
														---- Any ----
													</mat-option>
													<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
														{{loc.locationname }}
													</mat-option>
												</mat-select>
											</mat-form-field>
				</div>
				<div class="card-body" style="white-space: pre-line">
				{{ fromaddress }}
				</div>
				</div>
			</div>
			<div class="col-md-6 card-group">
				<div class="card m-0">
				<div class="card-header">
					To Location: <mat-form-field appearance="standard">
												<mat-label>To Location</mat-label>
												<mat-select formControlName="tostkloc" (selectionChange)="updateHeader()">
												<mat-option value="">
														---- Any ----
													</mat-option>
													<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
														{{loc.locationname }}
													</mat-option>
												</mat-select>
											</mat-form-field>
				</div>
				<div class="card-body" style="white-space: pre-line">
				{{ toaddress }}
				</div>
				</div>
			</div>		
		</div>
		<div class="row">
		<mat-form-field appearance="standard" class="searchbar" >
				<input matInput #itemrec formControlName="searchVal" id="itemrec" class="margin-top" autocomplete="off"
					placeholder="* Item Search  "
					(keyup.enter)="$event.stopPropagation();$event.preventDefault();itemSearch();$event.target.blur();">
				<div matSuffix class="text-right">
					<button mat-icon-button type="button" color="white"
						(click)="$event.stopPropagation();$event.preventDefault();itemSearch()">
						<mat-icon>search</mat-icon>
					</button>
				</div>
			</mat-form-field>
			
		</div>
		<div class="row">
		<table *ngIf="items.length > 0">
		<tr><th>Item #</th><th>Description</th><th>QOH at {{transferData.header.fromstkloc}}</th><th> QOH at {{transferData.header.tostkloc}}</th></tr>
		<tr *ngFor="let part of items">
		<td>
		{{part.stockid}}
		</td>
		<td>
		{{part.description}}
		</td>
		<td>
		{{part.fromqoh}}
		</td>
		<td>
		{{part.toqoh}}
		</td>
		<td>
												<div class="row">
												<div class="col-2 mt-3 ml-0 mr-0 ml-auto">
													<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="decrement(part)">
														<small><i class="material-icons">remove</i></small>
													</button>
												</div>
												<div class="col-6 m-0 ">
													<mat-form-field appearance="standard">
														<mat-label>Qty</mat-label>
														<span matPrefix>#</span>
														<input matInput tabindex="{{ i + 2 }}" type="number" value="{{ part.quantity | number }}" autofocus (input)="updateSearchQuantity($event, part )" class="text-right">
													</mat-form-field>
												</div>
												<div class="col-2 mt-3 ml-0 mr-0 mr-auto ">
													<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="increment(part)">
														<small>
															<i class="material-icons">add</i></small>
													</button>
												</div>
												<div class="col-2 mt-3 ml-0 mr-0 mr-auto" >
												<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2" (click)="addItems(items)">
												<small><i class="material-icons">add_box</i></small>

												</button>
												</div>
												</div>
		</td>
		</tr>
		</table>
		</div>
		<div class="row">
		<table>
		<tr><th>Item Number</th><th>Description</th><th>Bin Location</th><th>Qty to Transfer</th><th></th></tr>
		<tr *ngFor="let line of transferData.lines">
		<td>{{line.stockid}}</td><td>{{line.description}}</td><td>{{line.bincode}}<td>{{line.transferqty}}</td><td><button class="btn-danger btn-just-icon btn-sm btn btn-fab btn-round" (click)="removeItem(line)"><small><i class="material-icons">remove_box</i></small></button></td>
		</tr>
		</table>
		</div>
		</form>
</div>
</div>