import { Component, Input } from '@angular/core';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { OrdersService } from '../../services/orders.service';
import { CardLists,Card } from '../../classes/orders';
import { Router } from '@angular/router';
import { Ng2CompleterModule } from 'ng2-completer';

@Component({
  selector: 'app-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.scss']
})

export class OrderSearchComponent  {
	filterresults: any = [];
	filteredItems: any= [];
	orderData: CardLists;
	customerData: any= [];
	openOrderData: any= [];
	orders: any= [];
	orderSearch: any= [];
	ordersFound: any = [];

	@Input() name: string;

	constructor(private globalSearchService: GlobalSearchService, public router: Router, private ordersService: OrdersService){

		if(!this.openOrderData) {
			this.ordersService.getOrders().subscribe((results:any) => {
				this.globalSearchService.openOrderData.next(results.orders);
			});
		}

		this.globalSearchService.openOrderData.subscribe((openorders: any) => {
			this.openOrderData = openorders;
		});

		this.globalSearchService.orderSearch.subscribe((result: string) => {
			this.orderSearch = result
			if(this.orderSearch !='') {
				this.filterOrders(this.orderSearch)
			}
		});
	}

	public onInput(event: any, keys: string = 'orderno'){

		// this pushes the input value into the service's Observable.

		this.globalSearchService.searchTerm.next(event.target.value);


		let term = event.target.value;
		let allresults = [];
		if(this.openOrderData) {
			this.setFilteredLists(term);
			this.globalSearchService.itemSearch.next(this.ordersFound);
		}


	}

	filterOrders(orders) {

		let allresults = [];

		if(this.openOrderData && this.orderSearch) {
			var openorders = this.filterItem(this.openOrderData, '-', 'ORDER_NUMBER');
			if(openorders) {
				openorders.forEach(function (value) {
					if(orders.includes(value.ORDER_NUMBER)) {

						allresults.push({content: 'Open Order', details: value, link:'/orders/lookup/'+value.ORDER_NUMBER})
					}
				});


			}
		}

		this.setFiltered(allresults)

	}

	setFilteredLists(term) {

		let allresults = [];
		let ordersFound = [];
		if(this.openOrderData) {
			var openorders = this.filterItem(this.openOrderData, term, 'ADDRESS_1,CUST_NAME,ORDER_NUMBER,CUST_ORDER_NO_,DATE_ORDERED,DATE_WANTED,CUST_NUMBER,ITEM_NUMBER');
			if(openorders) {
				openorders.forEach(function (value) {
					allresults.push({content: 'Open Order', details: value, link:'/orders/lookup/'+value.ORDER_NUMBER})
					ordersFound.push(value.ORDER_NUMBER)
				});
			}
		}

		this.ordersFound = ordersFound;


		this.setFiltered(allresults)

	}

	getFiltered() {
		return this.filteredItems;
	}

	setFiltered(results) {
		this.filteredItems = results;
	}

	assignCopy(input){
		this.filteredItems = Object.assign([], input);
	}

	filterItem(input, term , keys ){
		if(!term){
			this.assignCopy(input);
		}
		var result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) &&	new RegExp(term, 'gi').test(item[key])));
		return result
	}

	navTo(link) {

		this.router.navigate([link]);
	}

}
