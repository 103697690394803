<ng-template #cartDetailsRef let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Cart Details</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="col-12">
				<table class="table table-sm smaller-font" *ngIf="cartdetails.length">
					<tr>
						<th>Item</th>
						<th>Desc</th>
						<th>Qty</th>
						<th>Price</th>
						<th>Total</th>
					</tr>
					<tr *ngFor="let i of cartdetails">
						<td>{{ i.stockid }}</td>
						<td>{{ i.name }}</td>
						<td>{{ i.quantity }}</td>
						<td>{{ i.price  | currency}}</td>
						<td>{{ i.price * i.quantity | currency }}</td>
					</tr>
				</table>

			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="container-fluid tp-box" [@flipState]="flip" >
	<div class="row tp-box__side tp-box__back" *ngIf="flip == 'active'">
		<div class="card" >
			<div class="card-header card-header-danger card-header-icon">
				<div class="card-icon pointer" (click)="toggleFlip(false, '')">
					<i class="material-icons">login</i>
				</div>
				<h3 class="card-title">{{ title }} </h3>
			</div>
			<div class="card-body"  *ngIf="viewing">
				<div class="table">
				<table class="table table-sm table-fixed">
					<thead>
					<tr>
						<th>Customer#</th>
						<th>Name</th>
						<th>Address1</th>
						<th>Address2</th>
						<th>Address3</th>
						<th>Address4</th>
						<th>Address5</th>
						<th>Items</th>
						<th>User</th>
					</tr>
					</thead>
					<tbody>
					<tr *ngFor="let d of data" (click)="viewCart(d)">
						<td><a [routerLink]="'/customers/view/'+d.debtorno">{{ d.debtorno}}.{{ d.branchcode }}</a></td>
						<td>{{ d.brname}}</td>
						<td>{{ d.braddress1}}</td>
						<td>{{ d.braddress2}}</td>
						<td>{{ d.braddress3}}</td>
						<td>{{ d.braddress4}}</td>
						<td>{{ d.braddress5 }}</td>
						<td>{{ d.items }} ({{ d.qty }})</td>
						<td>{{ d.userid }}</td>
					</tr>
					</tbody>
				</table>
				</div>
			</div>
		</div>
	</div>
	<div class="row tp-box__side tp-box__front" id="frontside" [ngClass]="flip == 'inactive' ? '' : 'd-none'">
		<div class="card card-stats">
			<div class="card-header card-header-danger card-header-icon">
				<div class="card-icon pointer" (click)="toggleFlip(data, title)">
					<i class="material-icons">shopping_cart</i>
				</div>
				<p class="card-category">{{ title }}</p>
				<h3 class="card-title">
					<span *ngIf="data">
						{{ data.length }}
					</span>
					<span *ngIf="!data">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>
				</h3>
				<small>

					<p class="card-category text-info text-justify mt-3 text-right">
						<span class=" ml-1 mr-1 pointer" (click)="toggleFlip(cartagebuckets[2], 'Customer Carts')" *ngIf="cartagebuckets[2]"> ( {{ cartagebuckets[2].length }} ) {{ cartsettings.settings_ageone }} Day </span>
						<span class=" ml-1 mr-1 pointer" (click)="toggleFlip(cartagebuckets[3], 'Customer Carts')" *ngIf="cartagebuckets[3]"> ( {{ cartagebuckets[3].length }} ) {{ cartsettings.settings_agetwo }} Day </span>
					</p>

				</small>
			</div>
			<div class="card-footer">
				<div class="stats pointer">
					<a (click)="toggleFlip(data, title)">
						<i class="material-icons">content_copy</i>
						View Now</a>
				</div>
				<br />
			</div>
		</div>
	</div>
</div>
