<ng-template #bindetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Bin Items</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body" *ngIf="showbin">
		<div class="row">
			<div class="col-6 ml-2 mr-2">
				<button mat-raised-button (click)="exportExcel('orderdetailsbody', binitems)"><i class="fa fa-file-excel-o"></i> XLS</button>
				&nbsp;&nbsp;
				<button mat-raised-button (click)="exportPdf()"><i class="fa fa-file-pdf-o"></i> PDF </button>
			</div>
			<div class="col-6 text-right ml-auto mr-0">
				<mat-slide-toggle class="example-margin" color="accent" [checked]="onhandonly" (change)="updateItems($event)">
					QOH > 0?
				</mat-slide-toggle>
			</div>
		</div>

		<div #orderdetailsbody id="orderdetailsbody" class="mb-3">
			<!--<h4>Bin Items</h4>-->
			<div class="table table-responsive">
				<table class="table table-sm table-full table-hover">
					<thead>
						<tr>
							<th>LOCATION</th>
							<th>ITEM</th>
							<th class="d-none d-md-table-cell">DESC</th>
							<th class="text-center">QOH</th>
							<th class="text-center">COMMITTED</th>
							<th class="text-center d-none d-md-table-cell">ON ORDER</th>
							<th class="text-center d-none d-md-table-cell">AVAILABLE</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of binitems">
							<td>
								<b><span [innerHTML]="item.bin =='' ? 'NO BIN': item.bin"></span></b>
							</td>
							<td>{{ item.stockid }}</td>
							<td class="d-none d-md-table-cell">{{ item.description }}</td>
							<td class="text-center">{{ item.qoh }} </td>
							<td class="text-center">{{ (item.commited) == '' ? 0 : item.commited }} </td>
							<td class="text-center d-none d-md-table-cell">{{ item.onorder }} </td>
							<td class="text-center d-none d-md-table-cell">{{ item.available }} </td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-body text-center" *ngIf="!showbin"> <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
			<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
		</svg>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="row text-right">
	<div class="col-md-12 text-right mt-3">

		<button mat-raised-button (click)="exportExcel('bins',bin_data)"><i class="fa fa-file-excel-o"></i> XLS</button>
		&nbsp;&nbsp;
		<button mat-raised-button (click)="exportPdf()"><i class="fa fa-file-pdf-o"></i> PDF </button>
		<!--
		<button mat-button [matMenuTriggerFor]="menu">Export</button>
			<mat-menu #menu="matMenu">

			</mat-menu>
	-->
	</div>

	<!--<mat-form-field class="col-md-6 text-right d-none" appearance="standard" *ngIf="bins">
			<mat-label>Item Search</mat-label>
			<input type="text"
				   aria-label="Item"
				   matInput
				   (input)="itemSearch($event)">
	  </mat-form-field>
  -->
	<mat-form-field class="col-md-3 text-right" appearance="standard">
		<mat-label>Active Sku's Greater Than =</mat-label>
		<input matInput [(ngModel)]="greater_than" (keyup)="applyQtyFilter($event.target.value)" placeholder="Filter" restrict-to="[0-9\-]">
	</mat-form-field>
	<mat-form-field class="col-md-3 text-right" appearance="standard">
		<mat-label>Active Sku's Less Than =</mat-label>
		<input matInput [(ngModel)]="less_than" (keyup)="applyQtyFilter($event.target.value)" placeholder="Filter" restrict-to="[0-9\-]">
	</mat-form-field>
	<mat-form-field class="col-md-6 text-right" appearance="standard" *ngIf="bins">
		<mat-label>Bin Search</mat-label>
		<input type="text" #newbarcode aria-label="Bin" matInput (input)="binSearch($event)">
	</mat-form-field>
</div>
<!-- mat-elevation-z8 -->

<div class="d-none">
	<div #printtable id="printtable" class="mb-3">
		<h4>Bins</h4>
		<table class="table table-sm table-full table-hover">
			<thead>
				<tr>
					<th>BIN</th>
					<th>SKU'S</th>
					<th>SKU'S W QOH</th>
					<th>TOTAL UNITS</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let bin of bin_data">
					<td>
						<b>
							<span *ngIf="bin.location ==''"> 'NO BIN'</span>
							<span *ngIf="bin.location != ''">{{ bin.location }}</span>
						</b>
					</td>
					<td>{{ bin.count }}</td>
					<td>{{ bin.active_items }}</td>
					<td> {{ bin.totalitems }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<div class="table table-responsive table-container">
	<mat-table id="bins" class="table table-responsive table-hover" [dataSource]="bins" matSort (matSortChange)="announceSortChange($event)">
		<ng-container matColumnDef="location">
			<mat-header-cell *matHeaderCellDef mat-sort-header> BIN</mat-header-cell>
			<mat-cell *matCellDef="let bin">
				<ng-container *ngIf="!bin.editing">

					<button mat-icon-button aria-label="Export To Excel" color="success" data-toggle="modal" data-target="#detailsModal" (click)="showBinModal(bin.location)">
						<mat-icon>visibility</mat-icon>
					</button>
					&nbsp;&nbsp;
					<button mat-icon-button class="d-none" aria-label="Edit" color="success" (click)="changingBin(bin)">
						<mat-icon>edit</mat-icon>
					</button>
					<b><span [innerHTML]="bin.location =='' ? 'NO BIN': bin.location"></span></b>
				</ng-container>
				<ng-container *ngIf="bin.editing">
					<mat-form-field class="full-width text-left" appearance="standard">
						<mat-label>Bin Change</mat-label>
						<input type="text" aria-label="Bin" matInput [(value)]="bin.location" (keydown.enter)="changeBinName($event, bin)">
					</mat-form-field>
				</ng-container>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="count">
			<mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"> SKU'S</mat-header-cell>
			<mat-cell *matCellDef="let bin" class="text-right"> {{ bin.count }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="active_items" >
			<mat-header-cell *matHeaderCellDef mat-sort-header class="d-none d-md-table-cell text-center">  SKU'S W/QOH
			</mat-header-cell>
			<mat-cell *matCellDef="let bin" class="d-none d-md-table-cell text-center"> {{ bin.active_items }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="capacity">
			<mat-header-cell *matHeaderCellDef mat-sort-header class="d-none d-md-table-cell"> CAPACITY
			</mat-header-cell>
			<mat-cell *matCellDef="let bin" class="d-none d-md-table-cell"> {{ bin.capacity }}</mat-cell>
		</ng-container>

		<ng-container matColumnDef="totalitems">
			<mat-header-cell *matHeaderCellDef mat-sort-header class="text-right" class="d-none d-md-table-cell">
				TOTAL UNITS
			</mat-header-cell>
			<mat-cell *matCellDef="let bin" class="text-left d-none d-md-table-cell">
				<div class="col-10 col-md-11 text-left mr-auto ml-0">
					{{ bin.totalitems }}
				</div>
			</mat-cell>
		</ng-container>
		<mat-header-row *matHeaderRowDef="headercolumns; sticky: true" ></mat-header-row>
		<mat-row *matRowDef="let row; columns: headercolumns;"></mat-row>
	</mat-table>
	<mat-paginator [pageSizeOptions]="[75, 150, 500]" showFirstLastButtons aria-label="select page" [ngClass]="bin_data.length ? '': 'd-none'">
	</mat-paginator>
</div>
