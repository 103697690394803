<div class="main-content" *ngIf="!groupForm">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-5">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Account Groups</h4>
						<p class="card-category">General Ledger Accounts</p>
					</div>
					<div class="card-body">
						<div class="table table-sm ">
							<div class="table table-responsive text-center" *ngIf="!groupsTable">
								<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</div>
							<div class="row">
								<div class="col-4">
									<a [routerLink]="'edit'" mat-stroked-button color="danger">New Group</a>
								</div>
							</div>
							<mat-table [dataSource]="groupsTable" matSort (matSortChange)="announceSortChange($event)">
								<ng-container matColumnDef="actions">
									<mat-header-cell class="d-none d-md-inline" *matHeaderCellDef></mat-header-cell>
									<mat-cell class="d-none d-md-inline" *matCellDef="let group">
										<a [routerLink]="[group.groupname]" mat-stroked-button class="mt-1">Edit</a>
										<button class="btn btn-sm btn-danger " (click)="deleteGroup(group.groupname)"><i class="fa fa-remove"></i></button>
									</mat-cell>
								</ng-container>

								<ng-container matColumnDef="groupname">
									<mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Code">
										Group Name
									</mat-header-cell>
									<mat-cell *matCellDef="let group"> {{group.groupname}} </mat-cell>
								</ng-container>
								<ng-container matColumnDef="sectioninaccounts">
									<mat-header-cell *matHeaderCellDef mat-sort-header> Account Section</mat-header-cell>
									<mat-cell *matCellDef="let group"> {{ group.sectioninaccounts}} </mat-cell>
								</ng-container>
								<ng-container matColumnDef="pandl">
									<mat-header-cell *matHeaderCellDef mat-sort-header> P/L or Balance Sheet</mat-header-cell>
									<mat-cell *matCellDef="let group"> {{ group.pandl}} </mat-cell>
								</ng-container>
								<ng-container matColumnDef="sequenceintb">
									<mat-header-cell *matHeaderCellDef mat-sort-header> Trial Balance Sequence</mat-header-cell>
									<mat-cell *matCellDef="let group"> {{ group.sequenceintb}} </mat-cell>
								</ng-container>
								<ng-container matColumnDef="parentgroupname">
									<mat-header-cell *matHeaderCellDef mat-sort-header> Parent Group</mat-header-cell>
									<mat-cell *matCellDef="let group"> {{ group.parentgroupname}} </mat-cell>
								</ng-container>
								<mat-header-row *matHeaderRowDef="headercolumns"></mat-header-row>
								<mat-row *matRowDef="let row; columns: headercolumns;"></mat-row>


							</mat-table>
							<mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons aria-label="select page">
							</mat-paginator>


						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<div class="main-content" *ngIf="groupForm">

	<form [formGroup]="groupForm" class="text-dark" (submit)="updateGroup()">

		<div class="container-fluid" *ngIf="ld">
			<div class="row">
				<div class="col-md-12">
					<div class="card">
						<div class="card-header card-header-{{color}}-5">
							<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i>Account Groups</h4>
							<p class="card-category"></p>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-md-12">
									<mat-form-field class="full-width" appearance="standard">
										<mat-label>Group Name</mat-label>
										<input matInput required formControlName="groupname" placeholder="Group Name" [value]="ld?.groupname">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field class="full-width" appearance="standard">
										<mat-label>Account Section</mat-label>
										<mat-select formControlName="sectioninaccounts">
											<mat-option *ngFor="let section of sections" [value]="section.sectionid">
												{{ section.sectionname }} </mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field class="full-width" appearance="standard">
										<mat-label>Profit / Loss or Balance Sheet</mat-label>
										<mat-select formControlName="pandl">
											<mat-option value="0">Balance Sheet </mat-option>
											<mat-option value="1">Profit and Loss </mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field class="full-width" appearance="standard">
										<mat-label>Trial Balance Sequence</mat-label>
										<input matInput required formControlName="sequenceintb" placeholder="Trial Balance Sequence" [value]="ld?.sequenceintb">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field class="full-width" appearance="standard">
										<mat-label>Parent Group</mat-label>
										<mat-select formControlName="parentgroupname">
											<mat-option value=""></mat-option>
											<mat-option *ngFor="let group of groups" [value]="group.groupname">
												{{ group.groupname }} </mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>

						</div>
						<div class="row">
							<div class="col-12 ml-auto mr-auto mb-6">
								<button mat-raised-button type="submit" class="btn btn-danger pull-right">Update Account Group</button>
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>