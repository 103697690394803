
<ng-container *ngIf="cardForm">
	<form [formGroup]="cardForm" autocomplete="off">
		<!-- single use card -->
		<mat-slide-toggle [color]="accent" formControlName="savetoprofile" (change)="singleFormSet($event)"> Save To Profile </mat-slide-toggle>
		<ng-container *ngIf="config.features.iframe">
			<app-iframe-view [type]="creditcard" [src]="config.features.iframe"></app-iframe-view>
		</ng-container>
		<ng-container *ngIf="!config.features.iframe">
			<mat-form-field class="full-width text-right" appearance="standard" color="accent">
				<mat-label>Card Name</mat-label>
				<input matInput placeholder="Card Name" type="text" formControlName="name" autocomplete="off">
			</mat-form-field>
			<mat-form-field class="full-width text-right" appearance="standard" color="accent">
				<mat-label>Card Number</mat-label>
				<input matInput placeholder="Card Number" type="tel" classs="text-right" ccNumber #ccNumber="ccNumber" formControlName="ccnumber" #ccnumber autocomplete="off">
				<span matSuffix class="scheme" *ngIf="ccnumber.value != ''" >
					<i class="fa fa-cc-{{ccNumber.resolvedScheme$ | async}}"></i>
				</span>
			</mat-form-field>
			<mat-form-field class="full-width text-right" appearance="standard" color="accent">
				<mat-label>Expiration</mat-label>
				<input matInput id="cc-exp-date" type="tel" autocomplete="cc-exp" formControlName="expiry" ccExp autocomplete="off">
			</mat-form-field>
			<mat-form-field class="full-width text-right" appearance="standard" color="accent">
				<mat-label>CVV</mat-label>
				<input matInput id="cc-cvc" type="tel" autocomplete="off" formControlName="cvv" ccCVC autocomplete="off">
			</mat-form-field>
		</ng-container>
	</form>
	<ng-container *ngIf="type == 'orderentry'">
		<button class="btn btn-sm btn-primary" [ngClass]="cardForm.valid ? '': 'btn-danger'" (click)="addPaymentMethod($event)" *ngIf="!sending"> Add Credit Card</button>
		<span *ngIf="sending"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</span>
	</ng-container>
</ng-container>
