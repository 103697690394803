<div class="row mt-3 p-0 m-0">

	<div class="col-md-12 text-left mt-2 mb-2">
		<button mat-stroked-button (click)="addPurchaseData()" color="accent">
			<mat-icon>add</mat-icon> Add Purchase Data
		</button>
	</div>
	<div class="col-md-12" *ngIf="!adding">
		<div class="table text-fixed w-100">
			<table class="table table-hover table-fixed table-sm ">
				<tr>
					<th></th>
					<th>VendorID</th>
					<th>Name</th>
					<th>Item#</th>
					<th>Part#</th>
					<th>Min Order#</th>
					<th>Max Order#</th>
					<th class="text-right">Purchase Price</th>
					<th></th>
				</tr>
				<tr *ngFor="let p of item.purchdata">
					<td><button mat-icon-button class="m-0 p-0" (click)="removePurchaseData(p)" color="danger">
							<mat-icon>remove</mat-icon>
						</button></td>
					<td>{{ p.supplierno }}</td>
					<td>{{ p.suppname }}</td>
					<td>{{ p.stockid }}</td>
					<td>{{ p.suppliers_partno }}</td>
					<td>{{ p.minorderqty }}</td>
					<td>{{ p.maxorderqty }}</td>
					<td class="text-right">{{ p.price | currency }}</td>
					<td>
						<button mat-icon-button (click)="editPurchaseData(p)" color="accent">
							<mat-icon>edit</mat-icon>
						</button>
					</td>
				</tr>
			</table>
		</div>
	</div>
	<div class="col-md-12 mt-3 card " *ngIf="adding">
		<div class="card-body">
			<form [formGroup]="purchaseForm" (ngSubmit)="onSubmit()">
				<div class="row mt-2">
					<span *ngIf="updating" class="col-12 text-danger">Purchase Information already exists. Changes will overwrite..</span>

					<div class="col-md-6 text-lwdr">
						<button class="" mat-button mat-raised-button (click)="toggleAdding()">
							<i class="material-icons">cancel</i> Cancel</button>
					</div>
					<div class="col-md-6 text-right">
						<button class="ml-auto mr-0" mat-raised-button [color]="purchaseForm.valid ? 'warn': 'accent' "> <i class="material-icons">save</i> Save</button>
					</div>
				</div>
				<div class="row">

					<div class="col-md-6">
						<mat-form-field appearance="standard">
							<mat-label>Vendor</mat-label>
							<input #vendorsearch matInput value="" placeholder="Vendor Search..." (input)="vendorSearch($event.target.value)" (keydown.enter)="$event.preventDefault();" formControlName="supplierno" [matAutocomplete]="vendorLookup">
							<span matSuffix class="ripple-container"><i class="material-icons">search</i></span>
							<mat-autocomplete #vendorLookup="matAutocomplete" [displayWith]="displayFn">
								<mat-option *ngFor="let v of vendorresults" [value]="v">
									{{ v.supplierid }} - {{ v.suppname }}
								</mat-option>
							</mat-autocomplete>
						</mat-form-field>
					</div>
					<div class="col-md-6">

						<mat-form-field appearance="standard">
							<mat-label>Vendor Description</mat-label>
							<input type="text" class="text-let m-0 p-0" formControlName="supplierdescription" matInput />
						</mat-form-field>
					</div>
					<div class="col-md-6">

						<mat-form-field appearance="standard">
							<mat-label>Purchase Price</mat-label>
							<input type="text" class="text-right m-0 p-0" formControlName="price" matInput />
						</mat-form-field>
					</div>
					<div class="col-md-6">
						<mat-form-field appearance="standard">
							<mat-label>Effective From</mat-label>
							<input matInput [matDatepicker]="effectivefrom" formControlName="effectivefrom">
							<mat-datepicker-toggle matSuffix [for]="effectivefrom"></mat-datepicker-toggle>
							<mat-datepicker #effectivefrom></mat-datepicker>
						</mat-form-field>
					</div>


					<div class="col-md-4">
						<mat-form-field appearance="standard">
							<mat-label>Vendor Part#</mat-label>
							<input type="text" class="text-right m-0 p-0" placeholder="Cost" formControlName="suppliers_partno" matInput />
						</mat-form-field>
					</div>
					<div class="col-md-4">
						<mat-form-field appearance="standard">
							<mat-label>Minimum Order Qty</mat-label>
							<input type="text" class="text-right m-0 p-0" formControlName="minorderqty" matInput />
						</mat-form-field>
					</div>
					<div class="col-md-4">
						<mat-form-field appearance="standard">
							<mat-label>Maximum Order Qty</mat-label>
							<input type="text" class="text-right m-0 p-0" formControlName="maxorderqty" matInput />
						</mat-form-field>
					</div>
					<div class="col-md-6">
						<mat-form-field appearance="standard">
							<mat-label>Conversion Factor</mat-label>
							<input type="text" class="text-right m-0 p-0" placeholder="Cost" formControlName="conversionfactor" matInput />
						</mat-form-field>
					</div>
					<div class="col-md-6">
						<mat-form-field appearance="standard" class="">
							<mat-label>Unit of Measure</mat-label>
							<mat-select formControlName="suppliersuom">
								<mat-option *ngFor="let k of properties.uom" [value]="k.value">
									{{ k.name }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
