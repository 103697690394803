<ng-template #storeEdit let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i
				class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<div class="row">
						<div class="col-12">
							<form [formGroup]="storeForm">
							<div class="row mt-2">
								<div class="col-md-6 card-group">
											<div class="card m-0">
												<div class="card-body">
												<div class="row">
														<div class="col-md-12">
															<mat-form-field appearance="standard">
																<mat-label>Store Code</mat-label>
																<input matInput required value="" formControlName="store_code">
															</mat-form-field>
														</div>
													</div>
													<div class="row">
														<div class="col-md-12">
															<mat-form-field appearance="standard">
																<mat-label>Store Name</mat-label>
																<input matInput required value="" formControlName="locationname">
															</mat-form-field>
														</div>
													</div>
													<div class="row">
														<div class="col-md-12">
															<mat-form-field appearance="standard">
																<mat-label>Address 1</mat-label>
																<input matInput required value="" formControlName="deladd1">
															</mat-form-field>
														</div>
													</div>
													<div class="row">
														<div class="col-md-12">
															<mat-form-field appearance="standard">
																<mat-label>Address 2</mat-label>
																<input matInput value="" formControlName="deladd2">
															</mat-form-field>
														</div>
													</div>
													<div class="row">
														<div class="col-md-4">
															<mat-form-field appearance="standard">
																<mat-label>City</mat-label>
																<input matInput required value="" formControlName="city">
															</mat-form-field>
														</div>
														<div class="col-md-4" *ngIf="zones">
															<mat-form-field appearance="standard">
																<mat-label>Region</mat-label>
																<mat-select formControlName="state">
																	<mat-option *ngFor="let zone of zones.zones" [value]="zone.code">
																		{{zone.code }}
																	</mat-option>
																</mat-select>
															</mat-form-field>

														</div>
														<div class="col-md-4">
															<mat-form-field appearance="standard">
																<mat-label>Zip</mat-label>
																<input matInput required value="" formControlName="zip">
															</mat-form-field>
														</div>													
													</div>
													<div class="row">
														<div class="col-md-6">
															<mat-form-field appearance="standard">
																<mat-label>Phone Number</mat-label>
																<input matInput value="" formControlName="phone_num">
															</mat-form-field>
														</div>
														<div class="col-md-6">
															<mat-form-field appearance="standard">
																<mat-label>Email Address</mat-label>
																<input matInput value="" formControlName="email">
															</mat-form-field>
														</div>
													</div>

												</div>
											</div>
										</div>
							<div class="col-md-6 card-group">
								<div class="card m-0">
									<div class="card-body">
									<div class="row">
										<div class="col-md-12">
											<mat-form-field appearance="standard">
												<mat-label>Taxable Items Rate</mat-label>
												<mat-select formControlName="taxgroupid">
													<mat-option *ngFor="let tax of taxgroups" [value]="tax.taxgroupid">
														{{tax.taxgroupdescription }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
									</div>
									<div class="row">
										<div class="col-md-12">
											<mat-form-field appearance="standard">
												<mat-label>Tax Exempt w/Cert Rate</mat-label>
												<mat-select formControlName="taxcertid">
													<mat-option *ngFor="let tax of taxgroups" [value]="tax.taxgroupid">
														{{tax.taxgroupdescription }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
									</div>
									<div class="row">
										<div class="col-md-12">
											<mat-form-field appearance="standard">
												<mat-label>Tax Exempt Rate</mat-label>
												<mat-select formControlName="taxnonid">
													<mat-option *ngFor="let tax of taxgroups" [value]="tax.taxgroupid">
														{{tax.taxgroupdescription }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
									</div>
									<div class="row">
											<div class="col-md-12">
											<mat-form-field appearance="standard">
												<mat-label>CC Merchant ID</mat-label>
												<input matInput value="" formControlName="cc_merchant_id">
											</mat-form-field>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
											<mat-form-field appearance="standard">
												<mat-label>CC Account ID</mat-label>
												<input matInput value="" formControlName="cc_account_id">
											</mat-form-field>
											</div>
										</div>
									
									</div>
								</div>
							</div>
							<div class="col-md-6 card-group">
								<div class="card m-0">
									<div class="card-header">
										Receipt Info
									</div>
									<div class="card-body">
										<div class="row">
											<div class="col-md-12">
											<mat-form-field appearance="standard">
												<mat-label>Receipt Message Ln 1</mat-label>
												<input matInput value="" formControlName="rcpt_mess1">
											</mat-form-field>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
											<mat-form-field appearance="standard">
												<mat-label>Receipt Message Ln 2</mat-label>
												<input matInput value="" formControlName="rcpt_mess2">
											</mat-form-field>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
											<mat-form-field appearance="standard">
												<mat-label>Receipt Message Ln 3</mat-label>
												<input matInput value="" formControlName="rcpt_mess3">
											</mat-form-field>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
											<mat-form-field appearance="standard">
												<mat-label>Receipt Message Ln 4</mat-label>
												<input matInput value="" formControlName="rcpt_mess4">
											</mat-form-field>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
											<mat-form-field appearance="standard">
												<mat-label>Receipt Message Ln 5</mat-label>
												<input matInput value="" formControlName="rcpt_mess5">
											</mat-form-field>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
											<mat-form-field appearance="standard">
												<mat-label>Receipt Message Ln 6</mat-label>
												<input matInput value="" formControlName="rcpt_mess6">
											</mat-form-field>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 card-group">
								<div class="card m-0">
									<div class="card-header">
										Control Info
									</div>
									<div class="card-body">
										<div class="row">
											<div class="col-md-12">
											<mat-form-field appearance="standard">
												<mat-label>Draw Stock From</mat-label>
												<mat-select formControlName="drawfrom">
													<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
														{{loc.locationname }}
													</mat-option>
												</mat-select>
											</mat-form-field>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
											<mat-form-field appearance="standard">
												<mat-label>Sales Area</mat-label>
												<mat-select formControlName="areacode">													
													<mat-option *ngFor="let area of areas" [value]="area.areacode">
														{{area.areadescription }}
													</mat-option>
												</mat-select>
											</mat-form-field>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
											<mat-form-field appearance="standard">
												<mat-label>Price List Override</mat-label>
												<mat-select formControlName="overridesalestype">
													<mat-option value="">
														--- None ---
													</mat-option>
													<mat-option *ngFor="let pl of salestypes" [value]="pl.typeabbrev">
														{{pl.typeabbrev}} - {{pl.sales_type }}
													</mat-option>
												</mat-select>
											</mat-form-field>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
											<mat-form-field appearance="standard">
												<mat-label>Bank Account Override</mat-label>
												<mat-select formControlName="overridesalestype">
													<mat-option value="">
														--- None ---
													</mat-option>
													<mat-option *ngFor="let ba of bankaccounts" [value]="ba.accountcode">
														{{ba.accountcode}} - {{ba.bankaccountname }}
													</mat-option>
												</mat-select>
											</mat-form-field>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
											<mat-form-field appearance="standard">
												<mat-label>Enabled</mat-label>
												<mat-select formControlName="enabled">
													<mat-option value="1">
														Yes
													</mat-option>
													<mat-option value="0">
														No
													</mat-option>
												</mat-select>
											</mat-form-field>
											</div>
										</div>
										
									</div>
								</div>
							</div>
							</div>	
							<div class="row mt-2">
								<button mat-stroked-button color="success" aria-label="" (click)="saveStore()"
									class="mt-2">
									<mat-icon>save</mat-icon> Save
								</button>
							</div>
						
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<button mat-stroked-button color="success" aria-label="" (click)="addStore()"
									class="mt-2 mb-2">
									<mat-icon>add</mat-icon> Add Store
								</button>

								<table class="table table-sm">
									<tr>
										<th>Store#</th>
										<th>Store Name</th>
										<th>Address</th>
										<th>City</th>
										<th>State</th>
										<th>Zip</th>
										<th>Phone</th>
										<th>Stock Location</th>
										<th>Price List</th>
										<th>Tax Code</th>
										<th>Bank Acct</th>
										<th></th>
									</tr>
									<tr *ngFor="let s of stores">

										<td>{{ s.store_code }}</td>
										<td>{{ s.locationname }}</td>
										<td>{{ s.deladd1 }}</td>
										<td>{{ s.deladd3 }}</td>
										<td>{{ s.deladd4 }}</td>
										<td>{{ s.deladd5 }}</td>
										<td>{{ s.phone_num }}</td>
										<td>{{ s.drawfrom }}</td>
										<td>{{ s.overridesalestype }}</td>
										<td>{{ s.taxgroupid }}</td>
										<td>{{ s.bankaccount }}</td>
										<td>
											<button mat-stroked-button color="success" aria-label=""
												(click)="editStore(s)" class="mt-2">
												<mat-icon>edit</mat-icon> Edit
											</button>
										</td>
									</tr>
								</table>