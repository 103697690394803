import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';

@Injectable({
	providedIn: 'root'
})

export class InventoryService {
	key: string = 'UET6DFMPAXW7BKCB';
	config: any;
	user: any = [];
	package: any = '';

	constructor(private http: HttpClient, private httpClient: HttpClient, private _configSvc: RuntimeConfigLoaderService, private globalSearchService: GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r=>{
			this.config = r;
		})
		this.globalSearchService.user.subscribe((result: any) => {
			this.user = result;
		});
	}
	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};




	public getMixItems(data: any = []) {
		var method = 'inventory/mixandmatch/getitems';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getMix(data: any = []) {
		var method = 'inventory/mixandmatch/getmix';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getMixAndMatch(data: any = []) {
		var method = 'inventory/mixandmatch/list';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getPromo(data: any = []) {
		var method = 'inventory/promos';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getPromos(data: any = []) {
		var method = 'inventory/promos/list';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public updateMinMax(data: any) {
		var method = 'inventory/updateminmax';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public changeStockId(data: any) {
		var method = 'globals/inventory/updatestockids';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public setActualCost(data: any) {
		var method = 'inventory/updateactual';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public updateAttribute(data: any) {
		var method = 'inventory/updateattribute';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public addAttribute(data: any) {
		var method = 'inventory/addattribute';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public saveCounts(data: any) {
		var method = 'inventory/stockcounts/save';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public printCountSheets(data: any) {
		let urlvar = $.param(data);
		var method = 'inventory/stockcounts/printsheets&'+urlvar;
		return this.setEndPoint(method);
	}

	public updateItemDescription(data: any) {
		var method = 'inventory/updateitemdescription';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public updateItemFeatures(data: any) {
		var method = 'inventory/updateitemfeatures';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public updateItemBenefits(data: any) {
		var method = 'inventory/updateitembenefits';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}


	public closeCounts(data: any) {
		var method = 'inventory/stockcounts/complete';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public reviewCounts(data: any) {
		var method = 'inventory/stockcounts/review';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getCurrentCounts(data: any) {
		var method = 'inventory/stockcounts/loadonly';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public createLoadSheets(data: any) {
		var method = 'inventory/stockcounts/create';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getInventoryValuation(data: any) {
		var method = 'inventory/valuation';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getItemHazards() {
		var method = 'globals/inventory/hazards';
		return this.http.get(this.setEndPoint(method));
	}

	public getItemRebates() {
		var method = 'globals/inventory/rebates';
		return this.http.get(this.setEndPoint(method));
	}

	public getItemManufacturer() {
		var method = 'globals/inventory/manufacturer';
		return this.http.get(this.setEndPoint(method));
	}


	public saveManufacturer(data: any) {
		var method = 'globals/inventory/savemanufacturer';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public removeManufacturer(data: any) {
		var method = 'globals/inventory/removemanufacturer';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public saveProductLine(data: any) {
		var method = 'globals/inventory/saveproductlines';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public mergeProductLines(data: any) {
		var method = 'globals/inventory/mergeproductlines';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public removeProductLine(data: any) {
		var method = 'globals/inventory/removeproductlines';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getItemProductlines() {
		var method = 'globals/inventory/productlines';
		return this.http.get(this.setEndPoint(method));
	}

	public getItemProductlineDetails() {
		var method = 'globals/inventory/productlinedetails';
		return this.http.get(this.setEndPoint(method));
	}
	public getGLClasses() {
		var method = 'globals/inventory/glclass';
		return this.http.get(this.setEndPoint(method));
	}

	public saveGLClass(data: any) {
		var method = 'globals/inventory/saveglclass';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public removeGLClass(data: any) {
		var method = 'globals/inventory/removeglclass';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public getItemCategories() {
		var method = 'globals/inventory/stockcategories';
		return this.http.get(this.setEndPoint(method));
	}
	public getStockCatTypes() {
		var method = 'globals/inventory/stocktypes';
		return this.http.get(this.setEndPoint(method));
	}
	public saveCategory(data: any) {
		var method = 'globals/inventory/savecategories';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public removeCategory(data: any) {
		var method = 'globals/inventory/removecategories';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public librarySelect(data: any) {
		var method = 'inventory/librarysearch/select';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public librarySearch(data: any) {
		var method = 'inventory/librarysearch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getBrands() {
		var method = 'inventory/properties/brands';
		return this.http.get(this.setEndPoint(method));
	}

	public addRecItem(data: any) {
		var method = 'oms/recitems/recany';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getMovements(data: any) {
		var method = 'inventory/history/movements';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public removeBrandPriorities(data: any) {
		var method = 'inventory/properties/brandprioritiesremove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public updateBrandPriorities(data: any) {
		var method = 'inventory/properties/brandprioritiesupdate';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public getBrandPriorities(id: any) {
		var method = 'inventory/properties/brandpriorities&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public getItemConfig(stockid: any) {
		var method = 'inventory/properties/config&stockid=' + stockid;
		return this.http.get(this.setEndPoint(method));
	}

	public getPurchaseHistory(stockid: any) {
		var method = 'inventory/history/purchasedata&stockid=' + stockid;
		return this.http.get(this.setEndPoint(method));
	}

	public getRemoteNewItems() {
		var method = 'remote/inventory/newitems';
		return this.http.get(this.setEndPoint(method));
	}
	public getMissingPurchaseData() {
		var method = 'inventory/dashboard/missingpurchase';
		return this.http.get(this.setEndPoint(method));
	}

	public getNegative() {
		var method = 'inventory/dashboard/negative';
		return this.http.get(this.setEndPoint(method));
	}

	public getZeroCost() {
		var method = 'inventory/dashboard/zerocost';
		return this.http.get(this.setEndPoint(method));
	}

	public getNeedLocation() {
		var method = 'inventory/dashboard/need';
		return this.http.get(this.setEndPoint(method));
	}

	public getMissingImages() {
		var method = 'inventory/dashboard/missingimages';
		return this.http.get(this.setEndPoint(method));
	}

	public uploadFile(formdata: any, stockid: any) {
		var method = 'inventory/updateimage&stockid=' + stockid;
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}

	public matchItems(data: any) {
		var method = 'inventory/pricebook/matchitems';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public uploadPriceBook(formdata: any) {
		var method = 'inventory/pricebook/upload';
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}

	public uploadSpecials(formdata: any) {
		var method = 'inventory/specials/upload';
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}
	public sendItemSpecials(data: any) {
		var method = 'inventory/specials/batchupdate';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public updatePrices(data: any) {
		var method = 'inventory/updateprices';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public updateStock(data: any) {
		var method = 'inventory/updatestock';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public updateItem(data: any) {

		var method = 'inventory/update';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public addItem(data: any) {
		var method = 'inventory/create';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getItemHistory(stockid: any) {
		var method = 'inventory/history&stockid=' + stockid;
		return this.http.get(this.setEndPoint(method));
	}

	public getItemHistoryByLocation(data: any) {
		var method = 'inventory/history';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getBrandProductlines(data: any) {
		var method = 'globals/inventory/productlinesbybrand';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getProductPrices(lineid: any) {
		var method = 'inventory/prices&lineid=' + lineid;
		return this.http.get(this.setEndPoint(method));
	}

	public getProductPriceMarkup(lineid: any, cost: any) {
		var method = 'inventory/prices&lineid=' + lineid + '&cost=' + cost;
		return this.http.get(this.setEndPoint(method));
	}

	public reverseMovements(id: any) {
		var method = 'inventory/reversebinhistory&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}
	public getBinMovements(data: any) {
		var method = 'inventory/binhistory';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getBinMovementDetails(id: any) {
		var method = 'inventory/binhistorydetails&bin=' + id;
		return this.http.get(this.setEndPoint(method));
	}
	public getItemBins(data: any) {
		var method = 'inventory/itembins';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public itemCleanSearch(keyword: any) {
		var method = 'inventory/rawsearch&keywords=' + encodeURIComponent(keyword);
		return this.http.get(this.setEndPoint(method));
	}

	public itemCleanSearchScan(keyword: any) {
		var method = 'inventory/rawscansearch&keywords=' + encodeURIComponent(keyword);
		return this.http.get(this.setEndPoint(method));
	}

	public addBarcodeClean(data: any) {
		var method = 'inventory/addbarcodeclean';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public addBarcode(data: any) {
		var method = 'inventory/addbarcode';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public removeBarcode(data: any) {
		var method = 'inventory/removebarcode';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public mergeBins(from: any, to: any) {
		var method = 'inventory/mergebins'
		let data = { from: from, to: to }
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	//different structure this needs undone
	public moveItemsErp(items: any, bin: any) {
		var method = 'inventory/moveitemserp'
		let data = { items: items, to: bin }
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public moveItemsToBin(items: any, bin: any) {
		var method = 'inventory/moveitemstobin'
		let data = { items: items, to: bin }
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getHistory(item) {
		var method = 'inventory/history&stockid=' + item;
		return this.http.get(this.setEndPoint(method));
	}
	public getBins() {
		var method = 'inventory/bins';
		return this.http.get(this.setEndPoint(method));
	}

	public changeBin(from: any, to: any) {
		var method = 'inventory/changebin'
		let data = { from: from, to: to }
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);

	}

	public getBinSearch(data: any) {
		var method = 'inventory/binlookup';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getBinItems(bin) {
		var method = 'inventory/binitems&bin=' + bin;
		return this.http.get(this.setEndPoint(method));
	}

	public getItems() {
		var method = 'inventory/allitems';
		return this.http.get(this.setEndPoint(method));
	}

	public getAllItems() {
		var method = 'inventory/allitems';
		return this.http.get(this.setEndPoint(method));
	}

	public loadItem(data) {
		var method = 'inventory/getitem&stockid=' + data;
		return this.http.get(this.setEndPoint(method));
	}

	public getItem(data) {
		var method = 'inventory/getitem'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	getItemByStockid(data) {
		var method = 'inventory/getitembystockid'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getSpecialsData() {
		var method = 'inventory/specials'
		return this.http.get(this.setEndPoint(method));
	}
	public getItemSpecial(data) {
		var method = 'inventory/specials/getspecial'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getStockItemSpecial(data) {
		var method = 'inventory/specials/getitemspecial'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public saveItemSpecial(data) {
		var method = 'inventory/specials/savespecial'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public removeItemSpecial(data) {
		var method = 'inventory/specials/removespecial'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getMfgs() {
		var method = 'inventory/getmfgs'
		return this.http.get(this.setEndPoint(method));
	}

	public lookup(data) {
		var method = 'inventory'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public promolookup(data) {
		var method = 'inventory/promos/search'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public removePromo(data) {
		var method = 'inventory/promos/remove'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public savePromo(data) {
		var method = 'inventory/promos/save'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public removePromoItem(data) {
		var method = 'inventory/promos/removefrompromo'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public addToPromo(data) {
		var method = 'inventory/promos/addtopromo'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public itemSearch(data) {
		var method = 'inventory/itemsearch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public lookupPickingBarcode(data) {
		var method = 'inventory/barcodelookup'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public lookupBarcode(data) {
		var method = 'inventory/barcodelookup'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public explodeBom(data){
		var method = 'inventory/explodebom';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getBreakdown(data){
		var method = 'inventory/breakdown';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public postBreakdown(data){
		var method = 'inventory/postbreakdown';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public renameBom(data){
		var method = 'inventory/renamebom';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public updateBom(data){
		var method = 'inventory/updatebom';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getTireInfo(data) {
		var method = 'inventory/gettireinfo';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public atdSingleSearch(data) {
		var method = 'inventory/singleatdsearch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public tireinfoBrands(data) {
		var method = 'inventory/brandsfromtireinfo';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getSeasons() {
		var method = 'inventory/getseasons';
		return this.http.get(this.setEndPoint(method));
	}

	public getDesignations() {
		var method = 'inventory/getdesignations';
		return this.http.get(this.setEndPoint(method));
	}

	public tlibSingleSearch(data) {
		var method = 'inventory/singletlibsearch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public mfiSingleSearch(data) {
		var method = 'inventory/singlemfisearch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public submitAttributes(data){
		var method = 'inventory/submitattributes';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public absoluteItemSearch(data) {
		var method = 'inventory/absoluteitemsearch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public absoluteKitSearch(data) {
		var method = 'inventory/absolutekitsearch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public recdocReport(data) {
		var method = 'inventory/recdocreport';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public creditItemsReport(data) {
		var method = 'inventory/credititemsreport';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getPickHistory(data){
		var method = 'inventory/getpickhistory';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getIdet(data){
		var method = 'inventory/getidet';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public pickableReport(data) {
		var method = 'inventory/pickablereport';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public needsReport(data) {
		var method = 'inventory/needsreport';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public nsrbReport(data) {
		var method = 'inventory/nsrbreport';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public adjustInventory(data) {
		var method = 'inventory/adjustment';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getTransfers(data){
		var method = 'inventory/transfers';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getTransferData(data){
		var method = 'inventory/transfers/edittransfer';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getTransferItems(data){
		var method = 'inventory/transfers/getparts';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getLocationAddress(data){
		var method = 'inventory/transfers/locaddr';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public createTransfer(data){
	var method = 'inventory/transfers/addtransfer';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public releaseTransfer(data){
	var method = 'inventory/transfers/release';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public receiveTransfer(data){
	var method = 'inventory/transfers/receive';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public addTransferLine(data){
	var method = 'inventory/transfers/addline';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public removeTransferLine(data){
	var method = 'inventory/transfers/removeline';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public updateTransferLine(data){
	var method = 'inventory/transfers/updateline';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public addTransferLineRestock(data){
	var method = 'inventory/transfers/addlinerestock';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getReStock(data: any) {
		var method = 'inventory/transfers/restock';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
		return this.http.get(this.setEndPoint(method));
	}

	public getSingleRestock(data: any){
	var method = 'inventory/transfers/forcerestock';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
		return this.http.get(this.setEndPoint(method));

	}

	public batchDisableProducts(data: any){
		var method = 'inventory/batchdisable';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public batchDiscontinueProducts(data: any){
		var method = 'inventory/batchdiscontinue';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public batchDisableBrand(data: any){
		var method = 'inventory/branddisable';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public batchDiscontinueBrand(data: any){
		var method = 'inventory/branddiscontinue';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getServiceLocation(data: any){
	var method = 'inventory/transfers/servicelocation';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
		return this.http.get(this.setEndPoint(method));

	}
	public saveGroup(data: any) {
		var method = 'inventory/groups/savegroup';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getGroups() {
		var method = 'inventory/groups/getgroups';
		return this.http.get(this.setEndPoint(method));
	}

	public removeGroupMember(id: any) {
		var method = 'inventory/groups/removemember&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public removeGroup(id: any) {
		var method = 'inventory/groups/removegroup&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId + '&userid=' + this.user.user.userid + this.getPackage();
	}

	private getPackage() {
		let p = this.globalSearchService.getSessionItem('package');
		if(p) {
			return '&package='+p;
		} else {
			return '';
		}
	}

	public getKey() {
		return '&apikey=' + this.key;
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			return error;
		};
	}
}
