<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> New Item</h4>
						<p class="card-category text-right"><span *ngIf="clonestockid">Cloning {{ clonestockid }}</span></p>
					</div>
					<div class="card-body" *ngIf="properties">


						<app-inventory-search></app-inventory-search>
						<!-- orientation="vertical" -->
						<div class="col-md-12">
							<mat-form-field appearance="fill">
								<mat-label>Library Search</mat-label>
								<input matInput [formControl]="librarysearch" (keyup.enter)="librarySearch($event.target.value);"
									(input)="librarySearch($event.target.value)">
							</mat-form-field>
							<mat-progress-bar mode="indeterminate" color="accent" *ngIf="librarySearching"></mat-progress-bar>

						</div>
						<ng-container *ngIf="librarySearchResults">
							<div class="container">
								<ul class="list-group">
									<li class="list-group-item cpdata" *ngFor="let option of librarySearchResults">
										<div class="row" (click)="selectLibraryItem(option)">
											<div class="col-2" *ngIf="option.model">
												<img src="{{option.model.make.imageUrl}}" class="img img-responsive img-fluid nonstocklogo" lazy >
											</div>
											<div class="col-2" *ngIf="option.model">
												<img src="{{option.model.imageUrl}}" class="img img-responsive img-fluid nonstockimage" lazy *ngIf="option.model.imageUrl" >
											</div>
											<div class="col-8">
												{{option.model.make.name}} {{option.model.name}} {{ option.displayName }}
											</div>
										</div>
									</li>
								</ul>
							</div>
						</ng-container>

						<mat-stepper linear #stepper [selectedIndex]="selectedStep" [animationDuration]="animation_length">

							<mat-step>
								<ng-template matStepLabel color="purple" >Item Details</ng-template>
								<ng-template matStepContent>
									<ng-container *ngIf="stockid_exists">
										<div class="row">
											<div class="col-md-12">
												<div class="alert alert-danger">
													Item Already Exists
												</div>
											</div>
										</div>
									</ng-container>


									<app-stock-master [inputdata]="lookupitem" [clonestockid]="clonestockid" (stockMasterUpdate)="addStockMaster($event)" (updateing)="pushStockMasterChanges($event)"
									(itemExists)="existingItem($event)"
									></app-stock-master>
								</ng-template>
							</mat-step>
							<mat-step>
								<ng-template matStepLabel color="purple" >Item Attributes</ng-template>
								<ng-template matStepContent>
									<app-inventory-attributes [inputdata]="lookupitem" [itemdetails]="stockmaster" [clonestockid]="clonestockid"
									(updateing)="pushAttributeChanges($event)"
									 (itemAttributesUpdate)="addAttributes($event)" ></app-inventory-attributes>
								</ng-template>
							</mat-step>
							<mat-step>
								<ng-template matStepLabel color="purple">Purchase Info</ng-template>
								<ng-template matStepContent>
								<app-purchdata
								[inputdata]="lookupitem"
								(updateing)="pushPurchData($event)"
								(purchDataUpdate)="addPurchData($event)" [itemdetails]="stockmaster" [clonestockid]="clonestockid">
								</app-purchdata>
								</ng-template>
							</mat-step>
							<mat-step>
								<ng-template matStepLabel color="purple">Prices</ng-template>
								<ng-template matStepContent>
									<app-prices [inputdata]="lookupitem" [purchasedata]="purchdata"
									(updateing)="puchPriceData($event)"
									[itemdetails]="stockmaster" (priceDataUpdate)="addPriceData($event)" [clonestockid]="clonestockid"></app-prices>
								</ng-template>
							</mat-step>
							<mat-step>
								<ng-template matStepLabel color="purple">Review & Complete</ng-template>
								<ng-template matStepContent>
									<h3>{{ stockmaster.stockid}}</h3>
									<h4>{{ stockmaster.description }}</h4>
									<div class="row">
										<div class="col-md-4">
											<h5>Details</h5>
											<ul class="entry-list p-0 m-0">
												<li>
													<div class="spec-label bg-white">
														Category:
													</div>
													<div class="spec-value">
														{{ stockmaster.categoryid }}
													</div>
												</li>
												<li>
													<div class="spec-label bg-white">
														Product Line:
													</div>
													<div class="spec-value">
														{{ stockmaster.lineid }}
													</div>
												</li>
												<li>
													<div class="spec-label bg-white">
														EOQ:
													</div>
													<div class="spec-value">
														{{ stockmaster.eoq }}
													</div>
												</li>
												<li>
													<div class="spec-label bg-white">
														Volume:
													</div>
													<div class="spec-value">
														{{ stockmaster.volume }}
													</div>
												</li>
												<li>
													<div class="spec-label bg-white">
														Weight:
													</div>
													<div class="spec-value">
														{{ stockmaster.grossweight }}
													</div>
												</li>
											</ul>
										</div>
										<div class="col-md-4">
											<h5>Purchase Data</h5>
											<ul class="entry-list p-0 m-0">
												<li>
													<div class="spec-label bg-white">
														Vendor:
													</div>
													<div class="spec-value">
														{{ purchdata.supplierno }}
													</div>
												</li>
												<li>
													<div class="spec-label bg-white">
														Description:
													</div>
													<div class="spec-value">
														{{ purchdata.supplierdescription }}
													</div>
												</li>
												<li>
													<div class="spec-label bg-white">
														Part#:
													</div>
													<div class="spec-value">
														{{ purchdata.suppliers_partno }}
													</div>
												</li>
												<li>
													<div class="spec-label bg-white">
														Cost:
													</div>
													<div class="spec-value">
														{{ purchdata.price | currency }}
													</div>
												</li>
												<li>
													<div class="spec-label bg-white">
														Min:
													</div>
													<div class="spec-value">
														{{ purchdata.minorderqty}}
													</div>
												</li>
												<li>
													<div class="spec-label bg-white">
														Max:
													</div>
													<div class="spec-value">
														{{ purchdata.maxorderqty }}
													</div>
												</li>
												<li>
													<div class="spec-label bg-white">
														Units:
													</div>
													<div class="spec-value">
														{{ purchdata.suppliersuom }}
													</div>
												</li>
											</ul>
										</div>
										<div class="col-md-4">
											<h5>Prices</h5>

											<ul class="entry-list p-0 m-0">
												<li *ngFor="let p of pricedata">
													<div class="spec-label bg-white">
														{{p.sales_type}}:
													</div>
													<div class="spec-value">
														{{ p.price | currency }}
													</div>
												</li>
											</ul>
										</div>
									</div>
									<button mat-raised-button color="accent" (click)="createItem()">Create</button>
								</ng-template>
							</mat-step>
						</mat-stepper>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
